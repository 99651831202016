Ext.define('PagingToolbarRPL', {
    extend: 'Ext.toolbar.Toolbar',
    beforePageText: Lang.strona,
    afterPageText: Lang.z_zero,
    firstText: Lang.pierwsza_strona,
    prevText: Lang.poprzednia_strona,
    nextText: Lang.nastepna_strona,
    lastText: Lang.ostatnia_strona,
    refreshText: Lang.odswiez,
    emptyMsg: Lang.brak_danych_do_wyswietlenia,
    initComponent: function () {
        var pagingItems = [this.first = new Ext.button.Button({
            tooltip: this.firstText,
            overflowText: this.firstText,
            iconCls: 'x-tbar-page-first',
            disabled: true,
            handler: this.moveFirst,
            scope: this
        }), this.prev = new Ext.button.Button({
            tooltip: this.prevText,
            overflowText: this.prevText,
            iconCls: 'x-tbar-page-prev',
            disabled: true,
            handler: this.movePrevious,
            scope: this
        }), '-', this.beforePageText, this.inputItem = new Ext.form.field.Number({
            cls: 'x-tbar-page-number',
            allowDecimals: false,
            allowNegative: false,
            enableKeyEvents: true,
            width: 50,
            selectOnFocus: true,
            hideTrigger: true,
            submitValue: false,
            listeners: {
                scope: this,
                keydown: this.onPagingKeyDown,
                blur: this.onPagingBlur
            }
        }), this.afterTextItem = new Ext.toolbar.TextItem({
            text: Ext.String.format(this.afterPageText, 1)
        }), '-', this.next = new Ext.button.Button({
            tooltip: this.nextText,
            overflowText: this.nextText,
            iconCls: 'x-tbar-page-next',
            disabled: true,
            handler: this.moveNext,
            scope: this
        }), this.last = new Ext.button.Button({
            tooltip: this.lastText,
            overflowText: this.lastText,
            iconCls: 'x-tbar-page-last',
            disabled: true,
            handler: this.moveLast,
            scope: this
        }), '-', this.refresh = new Ext.button.Button({
            tooltip: this.refreshText,
            overflowText: this.refreshText,
            iconCls: 'x-tbar-loading',
            handler: this.doRefresh,
            scope: this
        }), "-", this.progress = new Ext.ProgressBar({
            width: 50
        })];
        var userItems = this.items || this.buttons || [];
        if (this.prependButtons) {
            this.items = userItems.concat(pagingItems);
        } else {
            this.items = pagingItems.concat(userItems);
        }
        delete this.buttons;
        this.callParent();
        this.cursor = 0;
        this.pageCount = 0;
    },
    onPagingFocus: function () {
        this.inputItem.select();
    },
    onPagingBlur: function (e) {
        this.inputItem.setValue((this.cursor + 1));
    },
    onPagingKeyDown: function (field, e) {
        var k = e.getKey(),
            pageNum;
        if (k == e.RETURN) {
            e.stopEvent();
            pageNum = this.readPage();
            if (pageNum !== false) {
                pageNum = Math.min(Math.max(1, pageNum), this.pageCount) - 1;
                this.doLoad(pageNum);
            }
        } else if (k == e.HOME || k == e.END) {
            e.stopEvent();
            pageNum = k == e.HOME ? 1 : d.pages;
            field.setValue(pageNum);
        } else if (k == e.UP || k == e.PAGEUP || k == e.DOWN || k == e.PAGEDOWN) {
            e.stopEvent();
            if ((pageNum = this.readPage(d))) {
                var increment = e.shiftKey ? 10 : 1;
                if (k == e.DOWN || k == e.PAGEDOWN) {
                    increment *= -1;
                }
                pageNum += increment;
                if (pageNum >= 1 & pageNum <= d.pages) {
                    field.setValue(pageNum);
                }
            }
        }
    },
    moveFirst: function () {
        this.doLoad(0);
    },
    movePrevious: function () {
        this.doLoad(this.cursor - 1);
    },
    moveNext: function () {
        this.doLoad(this.cursor + 1);
    },
    moveLast: function () {
        this.doLoad(this.pageCount - 1);
    },
    doRefresh: function () {
        this.doLoad(this.cursor);
    },
    onDestroy: function () {
        this.callParent();
    },
    updateProgress: function (v) {
        if (v >= 0 && v < 1) {
            this.progress.setVisible(true);
            this.progress.updateProgress(v, "", true);
        } else {
            this.progress.setVisible(false);
        }
    },
    readPage: function () {
        var v = this.inputItem.getValue(),
            pageNum;
        if (!v || isNaN(pageNum = parseInt(v, 10))) {
            this.inputItem.setValue((this.cursor + 1));
            return false;
        }
        return pageNum;
    },
    doLoad: function (start) {
        this.fireEvent('beforechange', this);
        this.viewCtrl.loadPageViewTBL(start);
    },
    beforeLoad: function () {
        if (this.rendered && this.refresh) {
            this.refresh.disable();
        }
    },
    onLoadError: function () {
        if (!this.rendered) {
            return;
        }
        this.refresh.enable();
    },
    onLoad: function (cursor) {
        this.cursor = cursor;
        var ap = (this.cursor + 1);
        var ps = this.pageCount;
        this.afterTextItem.setText(Ext.String.format(this.afterPageText, ps));
        this.inputItem.setValue(ap);
        this.first.setDisabled(ap == 1);
        this.prev.setDisabled(ap == 1);
        this.next.setDisabled(ap == ps);
        this.last.setDisabled(ap == ps);
        this.refresh.enable();
        this.fireEvent('change', this);
    },
    setPageCount: function (counte) {
        this.pageCount = counte;
    }
});
