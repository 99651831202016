function User() {
    window.WindowExdMobileDEVICEV = Ext.os.is.Phone && (Config.mobileuidisabled != 1);
    var WindowCloudAccessPropertyViewName = "access.view.cloudcore";
    var UseMobileUI = Ext.os.is.Phone && (Config.mobileuidisabled != 1);

    window.updateCzasRefreshSerwer = function () {
        var performUpdateCzasRefreshSerwer = function () {
            sendAjaxDataJsonRpcL("rest/systemreq/getSystemTime", null, null, function (data) {
                if (data.status) {
                    var czas = data.data;
                    var updateczasserwer = Ext.getCmp("updateczasserwer");
                    AppCommonData.LastServerTime = czas;
                    updateczasserwer.setText(czas);
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        };
        performUpdateCzasRefreshSerwer();
    }

    function handleOverflowingFirmaContextComboName() {
        const ctxBtnInner = document.getElementById('main-ctxFirmaBtnChooser-inner');
        const ctxBtnC = document.getElementById('main-ctxFirmaBtnChooser-c');
        if (!ctxBtnInner || !ctxBtnC)
            return
        const isOverflowing = ctxBtnInner.clientWidth > ctxBtnC.clientWidth;
        if (isOverflowing) {
            ctxBtnInner.style.setProperty('position', 'relative');
        } else {
            ctxBtnInner.style.setProperty('position', 'absolute');
        }
    }

    window.changeContextFirm = function (r) {
        var AppFIRMACTXold = AppFIRMACTX;
        if (r.id != 0) {
            AppFIRMACTX = {
                id: r.id,
                nazwa: r.get("nazwa")
            };
        } else {
            AppFIRMACTX = null;
        }
        sendAjaxDataJsonRpcL("rest/systemreq/changeOperatorContextFirma", {
            id: r.id
        }, null, function (data) {
            if (data.status) {
                if (Ext.getCmp('main-view-sidebar-menu'))
                    Ext.getCmp('main-view-sidebar-menu').setHidden(true);
                data = data.data;
                if (!UseMobileUI) {
                    var b = Ext.getCmp("ctxFirmaBtnChooser");
                    b.setText(AppFIRMACTX == null ? ("*** " + Lang.wszystkie.toUpperCase() + " ***") : AppFIRMACTX.nazwa);
                    Ext.getCmp("ctxFirmaBtnChooser").setHidden(isValidOperatorFirmaRaw() || !Const.ENTERPRISEFLAG);
                } else {
                    Ext.getCmp("WindowCtxFirmaBtnChooser").close();
                }
                AppCommonDataEvents.fireEvent("firmaChangeContext", AppFIRMACTX, AppFIRMACTXold);
                if (Const.CLOUDFLAG) {
                    window.location.reload();
                }
            } else {
                if (UseMobileUI) {
                    Ext.getCmp("WindowCtxFirmaBtnChooser").unmask();
                }
                Ext.MessageBox.alert(Lang.error, data.error);
            }
            handleOverflowingFirmaContextComboName();
        });
    }

    window.updateFirmaListContext = function () {
        sendAjaxDataJsonRpcL("rest/systemreq/getUserFirmaListContext", {showOnlySupported: true}, null, function (data) {
            if (data.status) {
                data = data.data;
                AppFirmaList = data;
                var b = Ext.getCmp("ctxFirmaBtnChooser");
                b.setText(AppFIRMACTX == null ? ("*** " + Lang.wszystkie.toUpperCase() + " ***") : AppFIRMACTX.nazwa);
                Ext.getCmp("ctxFirmaBtnChooser").setHidden(isValidOperatorFirmaRaw() || !Const.ENTERPRISEFLAG);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    }

    window.onInitFirmaContextCombo = function () {
        var b = Ext.getCmp("ctxFirmaBtnChooser");
        b.setText(AppFIRMACTX == null ? ("*** " + Lang.wszystkie.toUpperCase() + " ***") : AppFIRMACTX.nazwa);
        Ext.getCmp("ctxFirmaBtnChooser").setHidden(isValidOperatorFirmaRaw() || !Const.ENTERPRISEFLAG);
        if (!Const.ENTERPRISEFLAG) {
            Ext.getCmp("ctxFirmaBtnChooser").setHidden(true);
        } else {
            handleOverflowingFirmaContextComboName();
        }
    }

    window.getDefFirmaContextCombo = function () {
        return {
            id: "ctxFirmaBtnChooser",
            width: 300,
            xtype: 'box',
            html: {
                tag: "div",
                id: "main-ctxFirmaBtnChooser-c",
                cls: "main-ctxFirmaBtnChooser-c",
                cn: [{
                    tag: "div",
                    id: "main-ctxFirmaBtnChooser-inner",
                    cls: "main-ctxFirmaBtnChooser-inner"
                }]
            },
            disabled: false,
            setText: function (t) {
                this.getEl().child('.main-ctxFirmaBtnChooser-c').child(".main-ctxFirmaBtnChooser-inner").update(t);
            },
            setDisabled: function (s) {
                this.disabled = s;
                if (this.disabled) {
                    this.getEl().addCls("el-disabled");
                } else {
                    this.getEl().removeCls("el-disabled");
                }
            },
            listeners: {
                scope: this,
                afterrender: function (c) {
                    if (this.disabled) {
                        c.getEl().addCls("el-disabled");
                    } else {
                        c.getEl().removeCls("el-disabled");
                    }
                    c.getEl().on("mouseover", function () {
                        c.getEl().addCls("el-over");
                    }, c);
                    c.getEl().on("mouseout", function () {
                        c.getEl().removeCls("el-over");
                    }, c);
                    c.getEl().on("click", function () {
                        if (this.disabled) {
                            return;
                        }
                        var w = Ext.create('WindowCtxFirmaBtnChooser', {
                            dataFS: AppFirmaList,
                            currentObject: (AppFIRMACTX == null ? 0 : AppFIRMACTX.id)
                        });
                        w.showW();
                        w.on("itemselected", function (c, r) {
                            changeContextFirm(r);
                        });
                    }, c);
                }
            }
        };
    }

    window.checkUpdateRequired = function () {
        sendAjaxDataJsonRpcL("rest/systemreq/getUpdateRequired", null, null, function (data) {
            if (data.status) {
                if (data.data) {
                    Ext.getCmp('updaterequired').setText(Lang.wymagane_przeslanie_konfiguracji);
                    Ext.getCmp('updaterequired').setStyle('color', 'red');
                } else {
                    Ext.getCmp('updaterequired').setText(Lang.konfiguracja_aktualna);
                    Ext.getCmp('updaterequired').setStyle('color', 'black');
                }
            } else {
                Ext.getCmp('updaterequired').setText(Lang.konfiguracja_aktualna);
                Ext.getCmp('updaterequired').setStyle('color', 'black');
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
        Ext.Function.defer(checkUpdateRequired, 30000);
    }

    function initMain() {
        function getViewButton(id, text, iconCls, handler, c) {
            return {
                cat: c,
                text: text,
                xtype: 'button',
                iconCls: iconCls,
                iconAlign: 'top',
                scale: 'large',
                enableToggle: false,
                id: "main-view-button-" + id,
                cls: 'main-view',
                focusable: false,
                handler: handler
            };
        }

        function getViewPanelTab(o) {
            var panelHi = Ext.id();
            Ext.each(o.buttons, function (e) {
                e.panelHi = panelHi;
            });
            var panelH = Ext.create('Ext.panel.Panel', {
                id: panelHi,
                border: false,
                collapsible: true,
                scrollable: true,
                bodyCls: "x-main-panel-buttons",
                layout: {
                    type: 'vbox',
                    align: 'stretch'
                },
                title: o.title,
                items: o.buttons,
                listeners: {
                    afterrender: function (c) {
                        var tcc = c.body.child(".x-table-layout");
                        if (tcc != null) {
                            var tccH = tcc.getHeight();
                            var cbodyH = c.body.getHeight();
                            var lccF = tcc.getHeight() > c.body.getHeight();
                            Ext.each(o.buttons, function (v) {
                                var btn = Ext.getCmp(v.id);
                                btn.setWidth('100%');
                            });
                        }
                    },
                    resize: function (c, w, h, rw, rh) {
                        var tcc = c.body.child(".x-table-layout");
                        if (tcc != null) {
                            var tccH = tcc.getHeight();
                            var cbodyH = c.body.getHeight();
                            var lccF = tcc.getHeight() > c.body.getHeight();
                            Ext.each(o.buttons, function (v) {
                                var btn = Ext.getCmp(v.id);
                                btn.setWidth('100%');
                            });
                        }
                    }
                }
            });
            return panelH;
        }

        var views = [];
        if (validPrivilegesOperatorComplex(ViewStany.accessPropertyViewName) && !AppTimeouted) {
            views.push(Ext.create('ViewStany'));
        }
        if (!AppTimeouted)
            if (!Const.SKPFLAG)
                if (!Const.BASICFLAG || Const.CUSTOMMODFLAG == 'swimer')
                    if (!Const.STARTERFLAG)
                        if (!Const.AGGREGATEDCORE)
                            if (validPrivilegesOperatorComplex(ViewPivot.accessPropertyViewName))
                                views.push(Ext.create('ViewPivot'));
        if (!AppTimeouted)
            if (!Const.STARTERFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(ViewReportList.accessPropertyViewName))
                        views.push(Ext.create('ViewReportList'));
        if (!AppTimeouted)
            if (!Const.SKPFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(ViewTankowania.accessPropertyViewName))
                        views.push(Ext.create('ViewTankowania'));
        if (!AppTimeouted)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                if (!Const.STANDARDFLAG)
                    if (!Const.AGGREGATEDCORE)
                        if (validPrivilegesOperatorComplex(ViewSkpPomiary.accessPropertyViewName))
                            views.push(Ext.create('ViewSkpPomiary'));
        if (!AppTimeouted)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                if (!Const.STANDARDFLAG)
                    if (!Const.AGGREGATEDCORE)
                        if (validPrivilegesOperatorComplex(ViewSkpPomiaryAnaliza.accessPropertyViewName))
                            views.push(Ext.create('ViewSkpPomiaryAnaliza'));
        if (!AppTimeouted)
            if (!Const.AGGREGATEDCORE)
                if (validPrivilegesOperatorComplex(ViewDziennik.accessPropertyViewName))
                    views.push(Ext.create('ViewDziennik'));
        if (!AppTimeouted)
            if (!Const.AGGREGATEDCORE)
                if (validPrivilegesOperatorComplex(ViewZaladunki.accessPropertyViewName))
                    views.push(Ext.create('ViewZaladunki'));
        if (!AppTimeouted)
            if (Const.ENTERPRISEFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(ViewZaladunekLlimity.accessPropertyViewName))
                        views.push(Ext.create('ViewZaladunekLlimity'));
        if (!AppTimeouted)
            if (!Const.AGGREGATEDCORE)
                if (validPrivilegesOperatorComplex(ViewKorekta.accessPropertyViewName))
                    views.push(Ext.create('ViewKorekta'));
        if (!AppTimeouted)
            if (!Const.SKPFLAG)
                if (!Const.BASICFLAG && !Const.STARTERFLAG)
                    if (!Const.AGGREGATEDCORE)
                        if (validPrivilegesOperatorComplex(ViewReczne.accessPropertyViewName))
                            views.push(Ext.create('ViewReczne'));
        if (!AppTimeouted)
            if (!Const.SKPFLAG && !Const.STARTERFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(ViewAwaryjne.accessPropertyViewName))
                        views.push(Ext.create('ViewAwaryjne'));
        if (!AppTimeouted)
            if (!Const.SKPFLAG && !Const.STARTERFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(ViewOdbiorcy.accessPropertyViewName))
                        views.push(Ext.create('ViewOdbiorcy'));
        if (!AppTimeouted)
            if (!Const.SKPFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(ViewPojazdy.accessPropertyViewName))
                        views.push(Ext.create('ViewPojazdy'));
        if (!AppTimeouted)
            if (!Const.AGGREGATEDCORE)
                if (validPrivilegesOperatorComplex(ViewOperators.accessPropertyViewName))
                    views.push(Ext.create('ViewOperators'));
        if (!AppTimeouted)
            if (Const.ENTERPRISEFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(ViewFirmas.accessPropertyViewName))
                        views.push(Ext.create('ViewFirmas'));
        if (!AppTimeouted)
            if (!Const.AGGREGATEDCORE)
                if (validPrivilegesOperatorComplex(ViewPlyny.accessPropertyViewName))
                    views.push(Ext.create('ViewPlyny'));
        if (!AppTimeouted)
            if (!Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(ViewZbiorniki.accessPropertyViewName))
                        views.push(Ext.create('ViewZbiorniki'));
        if (!AppTimeouted)
            if ((!Const.BASICFLAG && !Const.STARTERFLAG && !Const.STANDARDFLAG) || Const.AGGREGATEDCORE)
                if (validPrivilegesOperatorComplex(ViewSkps.accessPropertyViewName))
                    views.push(Ext.create('ViewSkps'));
        if (!AppTimeouted)
            if ((!Const.SKPFLAG && !Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG) || Const.AGGREGATEDCORE)
                if (validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyViewName))
                    views.push(Ext.create('ViewSterowniki'));
        if (!AppTimeouted)
            if (!Const.SKPFLAG && !Const.BASICFLAG && !Const.OPTIMALFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(ViewRozszerzenia.accessPropertyViewName))
                        views.push(Ext.create('ViewRozszerzenia'));
        if (!AppTimeouted)
            if (!Const.AGGREGATEDCORE)
                if (validPrivilegesOperatorComplex(ViewBackups.accessPropertyViewName))
                    views.push(Ext.create('ViewBackups'));
        if (!AppTimeouted)
            if (Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG)
                if (validPrivilegesOperatorComplex(ViewSterownikiBasicStd.accessPropertyViewName))
                    if (!Const.AGGREGATEDCORE)
                        views.push(Ext.create('ViewSterownikiBasicStd'));

        var viewsP = [];
        var viewsA = {};
        var viewClasses = {};
        for (var i = 0; i < views.length; i++) {
            var button = {
                xtype: 'button',
                iconAlign: 'top',
                scale: 'large',
                enableToggle: true,
                cls: 'main-view',
                id: views[i].buttonId,
                cat: views[i].cat,
                text: views[i].text,
                iconCls: views[i].buttonIconCls,
                panelView: views[i],
                focusable: false,
                blocked: false
            };
            button.handler = function () {
                if (!this.blocked) {
                    Ext.suspendLayouts();
                    var contenerViews = Ext.getCmp("main-view-contenerviews");
                    if (!this.pressed)
                        this.toggle(true, true);
                    if (AppCommonData.CurrentView != null) {
                        if (AppCommonData.CurrentView.id == this.id)
                            return;
                        else {
                            AppCommonData.CurrentView.toggle(false, true);
                            AppCommonData.CurrentView.panelView.hide();
                            AppCommonData.CurrentView.blocked = false;
                        }
                    }
                    var button = this;
                    this.blocked = true;
                    if (contenerViews.getComponent(button.panelView.id) == null) {
                        if (button.panelView.destroyed) {
                            button.panelView = Ext.create(viewClasses[button.panelView.id]);
                        }
                        contenerViews.add(button.panelView);
                    }
                    contenerViews.getLayout().setActiveItem(button.panelView.id);
                    AppCommonData.CurrentView = button;
                    var tttxxcc = button.text.replace(/&nbsp;/g, "");
                    var textC = tttxxcc.capitalise();
                    Ext.get("main-panel-title-north").update(textC);
                    if (!button.panelView.showedFirst) {
                        button.panelView.showFirst(AppCommonDataEvents);
                        button.panelView.showedFirst = true;
                    }
                    updateGUILocationHash({
                        "uvroute": AppCommonData.CurrentView.panelView.id
                    });
                    Ext.resumeLayouts(true);
                    button.panelView.show();
                } else {
                    if (!this.pressed) {
                        this.toggle(true, true);
                    }
                }
            };
            viewClasses[button.panelView.id] = Ext.getClassName(button.panelView);
            viewsA[button.panelView.id] = button;
            viewsP.push(button);
        }
        if (!AppTimeouted)
            if (Ext.os.deviceType == "Desktop")
                if (!Const.CLOUDFLAG)
                    if (Const.BASICFLAG || Const.STANDARDFLAG || Const.DEBUG || Const.STARTERFLAG) {
                        if (!Const.AGGREGATEDCORE)
                            if (validPrivilegesOperatorComplex(WindowPastylkaZarzadzanie.accessViewProperty))
                                viewsP.push(getViewButton("pastylkazarzadzanie", Lang.komunikacja_reczna, "iconCfgZd", function () {
                                    var w = new WindowPastylkaZarzadzanie();
                                    w.showW();
                                }, "n"));
                    }
        if (!AppTimeouted)
            if (Ext.os.deviceType == "Desktop")
                if (!Const.CLOUDFLAG)
                    if (Const.BASICFLAG || Const.STANDARDFLAG || Const.DEBUG || Const.STARTERFLAG) {
                        if (!Const.AGGREGATEDCORE)
                            if (validPrivilegesOperatorComplex(WindowPastylkaObraz.accessViewProperty))
                                viewsP.push(getViewButton("pastylkaobraz", Lang.serwis_pastylki, "iconServiceZd", function () {
                                    var w = new WindowPastylkaObraz();
                                    w.showW();
                                }, "n"));
                    }
        if (!AppTimeouted)
            if (!Const.CLOUDFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(WindowIdentKodsaccessPropertyName))
                        viewsP.push(getViewButton("import-kodow", Lang.import_kodow, "iconImportKodow", function () {
                            var w = Ext.create('WindowIdentKods');
                            w.showW();
                        }, "n"));
        if (!AppTimeouted)
            if (!Const.CLOUDFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(WindowSysKonfaccessPropertyViewName))
                        viewsP.push(getViewButton("sys-konf", Lang.konfiguracja_systemu, "iconKonfSys", function () {
                            var w = Ext.create('WindowSysKonf');
                            w.showW();
                        }, "k"));
        if (!AppTimeouted)
            if (Const.CLOUDFLAG && AppFIRMACTX != null)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(WindowSysKonfFirmaaccessPropertyViewName))
                        viewsP.push(getViewButton("sys-konf", Lang.konfiguracja_systemu, "iconKonfSys", function () {
                            var w = Ext.create('WindowFirmaCloudDef');
                            w.showW();
                        }, "k"));
        if (!AppTimeouted)
            if (!Const.AGGREGATEDCORE)
                viewsP.push(getViewButton("opc-konf", Lang.zmien_obecnego_operatora, "iconKonfOPC", function () {
                    var w = Ext.create('WindowOperatorCurrentChange');
                    w.showW();
                }, "k"));
        if (!AppTimeouted)
            if (Ext.os.deviceType == "Desktop")
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(WindowCheckIdentCardaccessPropertyViewName))
                        viewsP.push(getViewButton("check-ident", Lang.test_identyfikatora, "iconTestIdent", function () {
                            var w = Ext.create('WindowCheckIdentCard');
                            w.showW();
                        }, "n"));
        if (!AppTimeouted)
            if (Const.CLOUDFLAG)
                if (validPrivilegesOperatorComplex(WindowCloudAccessPropertyViewName) || Const.AGGREGATEDCORE)
                    viewsP.push(getViewButton("cloud-konf", Lang.chmura, "iconCloudLink", function () {
                        window.location = Config.prefixportallink;
                    }, "n"));
        if (!AppTimeouted)
            if (Const.ENABLENEFPROCESSING && !Const.CLOUDFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(WindowNefRaport.accessPropertyViewName))
                        if (validPrivilegesOperatorComplex(ViewTankowania.accessPropertyViewName) || validPrivilegesOperatorComplex(ViewZaladunki.accessPropertyViewName))
                            viewsP.push(getViewButton("NEF", Lang.raport_nef, "iconNef", function () {
                                var w = Ext.create('WindowNefRaport');
                                w.showW();
                            }, "t"));
        viewsP.push(getViewButton("about", Lang.o_programie, "iconAbout", function () {
            var w = Ext.create('WindowAbout');
            w.showW();
        }, "n"));
        var ttls = {
            p: Lang.podglad,
            t: Lang.operacje,
            k: Lang.konfiguracja,
            n: Lang.narzedzia
        };
        var viewsPP = {};
        Ext.each(viewsP, function (e) {
            if (!viewsPP[e.cat]) {
                viewsPP[e.cat] = {};
                viewsPP[e.cat].title = ttls[e.cat].capitalise();
                viewsPP[e.cat].buttons = [];
            }
            e.text = e.text.capitalise();
            if (e.text.length < 18) {
                var ll = 18 - e.text.length;
                ll = ll / 2;
                var ty = "";
                for (var i = 0; i < ll; i++)
                    ty += "&nbsp;";
                e.text = ty + e.text + ty;
            }
            viewsPP[e.cat].buttons.push(e);
        });
        var viewsPPP = [];
        for (var a in viewsPP) {
            viewsPPP.push(getViewPanelTab(viewsPP[a]));
        }
        var viewClsImageTiitle = "";
        if (Const.AGGREGATEDCORE) {
            viewClsImageTiitle = "main-panel-image-title4cloud";
        } else if (Const.SKPFLAG) {
            viewClsImageTiitle = "main-panel-image-title4skp";
        } else if (Const.OPTIMALFLAG) {
            viewClsImageTiitle = "main-panel-image-title4opt";
        } else if (Const.ENTERPRISEFLAG) {
            viewClsImageTiitle = "main-panel-image-title4";
        } else if (Const.BASICFLAG) {
            viewClsImageTiitle = "main-panel-image-title4basic";
        } else if (Const.STARTERFLAG) {
            viewClsImageTiitle = "main-panel-image-title4starter";
        } else if (Const.STANDARDFLAG) {
            viewClsImageTiitle = "main-panel-image-title4std";
        } else if (Const.PROFLAG) {
            viewClsImageTiitle = "main-panel-image-title4pro";
        } else {
            viewClsImageTiitle = "main-panel-image-title4cloud";
        }
        var viewPort = Ext.create('Ext.container.Viewport', {
            minHeight: 500,
            minWidth: 1024,
            layout: 'border',
            border: false,
            items: [{
                id: "main-view-header",
                xtype: 'panel',
                region: 'north',
                bodyCls: "cn-panel main-panel-image-title3",
                height: 50,
                border: false,
                html: {
                    cls: "cn-panel",
                    cn: [{
                        cls: viewClsImageTiitle
                    }, {
                        cls: "main-panel-image-title5"
                    }, {
                        cls: "main-panel-button-header-help",
                        id: "main-panel-button-header-help"
                    }, {
                        cls: "main-panel-button-header-logout",
                        id: "main-panel-button-header-logout"
                    }]
                }
            }, {
                border: false,
                region: 'center',
                xtype: 'panel',
                layout: 'border',
                cls: Ext.os.deviceType != "Desktop" && Ext.os.name != "iOS" && Ext.browser.is("Chrome") ? "mobile-scaling" : null,
                items: [{
                    border: true,
                    xtype: 'panel',
                    region: 'west',
                    multi: false,
                    width: 200,
                    collapsible: true,
                    collapsed: Ext.os.deviceType != "Desktop",
                    titleCollapse: true,
                    layout: {
                        type: 'accordion',
                        titleCollapse: true,
                        sequence: false,
                        animate: true
                    },
                    cls: "main-view-accordion",
                    bodyStyle: 'background-color:#F1F1F1',
                    items: viewsPPP
                }, {
                    xtype: 'panel',
                    border: true,
                    bodyStyle: "border-top:0px;border-right:0px",
                    region: 'center',
                    layout: 'border',
                    items: [{
                        region: 'north',
                        border: false,
                        id: "main-view-northviews",
                        bodyCls: "main-panel-image-title1",
                        xtype: 'panel',
                        layout: 'fit',
                        height: 25,
                        html: {
                            cls: "cn-panel",
                            cn: [{
                                cls: "main-panel-image-title6"
                            }, {
                                cls: "main-panel-title-north",
                                id: "main-panel-title-north"
                            }]
                        }
                    }, {
                        border: true,
                        id: "main-view-contenerviews",
                        xtype: 'panel',
                        style: 'width: 100%;',
                        region: 'center',
                        layout: 'card',
                        layoutConfig: {
                            deferredRender: true
                        },
                        activeItem: 0,
                        items: []
                    }]
                }]
            }, {
                id: "main-view-statusbar",
                xtype: 'toolbar',
                region: 'south',
                height: 35,
                items: [getDefFirmaContextCombo(), {
                    id: "updateloginuser",
                    xtype: 'label',
                    style: 'display:block;margin-left:5px'
                }, "->", {
                    id: 'updaterequired',
                    xtype: 'label',
                    style: 'display: block;',
                    hidden: (!Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG) || Const.CLOUDFLAG
                }, "->", {
                    id: "totalinfo",
                    xtype: 'label',
                    style: 'display:block;margin-right:5px'
                }, {
                    id: "lastbackupuploadingstatus",
                    xtype: 'label',
                    style: 'display:block;margin-right:15px',
                    hidden: Const.ENTERPRISEFLAG && AppOPERATORFIRMA != null
                }, {
                    id: "updateczasserwer",
                    xtype: 'label',
                    style: 'display:block;margin-right:5px'
                }]
            }]
        });
        onInitFirmaContextCombo();
        Ext.get("main-panel-button-header-help").on("click", function () {
            if (Const.CUSTOMMODFLAG == 'swimer') {
                //window.open('https://download.teamviewer.com/download/TeamViewerQS_pl.exe', '_blank');
                window.open('https://smdp.pl/downloads/support/support_swimer.exe', '_blank');
            } else {
                //window.open('https://download.teamviewer.com/download/TeamViewerQS_pl.exe', '_blank');
                window.open('https://smdp.pl/downloads/support/support_arccan.exe', '_blank');
            }
        });
        Ext.get("main-panel-button-header-logout").on("click", function () {
            window.location = "rest/systemreq/userSignOut";
        });
        Ext.defer(() => {
            var updateloginuser = Ext.getCmp("updateloginuser");
            updateloginuser.setText(AppOPERATOR.imie + " " + AppOPERATOR.nazwisko);
            var viewc = AppCLocationParams.uvroute;
            if (!viewc) {
                if (viewsA["stany"]) {
                    viewc = Ext.getCmp(viewsA["stany"].id);
                } else if (viewsA["tankowania"]) {
                    viewc = Ext.getCmp(viewsA["tankowania"].id);
                } else if (viewsA["reportlist"]) {
                    viewc = Ext.getCmp(viewsA["reportlist"].id);
                } else if (viewsA["pivot"]) {
                    viewc = Ext.getCmp(viewsA["pivot"].id);
                } else if (viewsA["skppomiary"]) {
                    viewc = Ext.getCmp(viewsA["skppomiary"].id);
                } else if (viewsA["dziennik"]) {
                    viewc = Ext.getCmp(viewsA["dziennik"].id);
                }
                if (viewc) {
                    viewc.handler.call(viewc);
                }
            } else {
                viewc = Ext.getCmp(viewsA[viewc].id);
                viewc.handler.call(viewc);
                if (Ext.os.deviceType != "Desktop") {
                    var accordion = Ext.getCmp(viewc.panelHi).up();
                    accordion.on("expand", function () {
                        Ext.getCmp(viewc.panelHi).expand(2000);
                    }, null, {
                        single: true
                    });
                } else {
                    Ext.getCmp(viewc.panelHi).expand();
                }
            }
        }, 250);
        if (!Const.CLOUDFLAG) {
            performAjaxLPromise('rest/maintenanceinfo/getUpdateInfo').then(data => {
                if (data) {
                    let today = new Date();
                    let weekAgo = new Date();
                    weekAgo.setDate(today.getDate() - 7);
                    if (data.maintenanceTo != null && (new Date(data.maintenanceTo) < today)) {
                        let lastMaintenanceNotify = Ext.state.Manager.get('lastMaintenanceNotify');
                        if (!lastMaintenanceNotify || (lastMaintenanceNotify && (new Date(lastMaintenanceNotify) <= weekAgo) &&
                            lastMaintenanceNotify != 'doNot')) {
                            if (!(Const.ENTERPRISEFLAG && AppOPERATORFIRMA != null)) {
                                Ext.create('WindowMaintenanceReminder').showW();
                            }
                        }
                    }
                }
            }).catch(err => {
                Ext.MessageBox.alert(Lang.error, err);
            });
        }
        if (Const.CLOUDFLAG && AppFIRMACTX != null && AppOPERATORFIRMA != null && !AppFIRMACTX.agreementaccepted) {
            var w = Ext.create('WindowAgreements');
            w.showW();
        }
    }

    function initMainMobile() {
        function getViewButton(id, text, iconCls, handler, c) {
            return {
                cat: c,
                text: text,
                xtype: 'button',
                iconCls: iconCls,
                iconAlign: 'top',
                scale: 'large',
                enableToggle: false,
                id: "main-view-button-" + id,
                cls: 'main-view',
                focusable: false,
                handler: handler
            };
        }

        function getViewPanelTab(o) {
            var panelHi = Ext.id();
            Ext.each(o.buttons, function (e) {
                e.panelHi = panelHi;
            });
            var panelH = Ext.create('Ext.panel.Panel', {
                id: panelHi,
                border: false,
                collapsible: true,
                scrollable: true,
                bodyCls: "x-main-panel-buttons",
                layout: {
                    type: 'vbox',
                    align: 'stretch'
                },
                plugins: {
                    responsive: true
                },
                title: o.title,
                items: o.buttons,
                listeners: {
                    afterrender: function (c) {
                        var tcc = c.body.child(".x-table-layout");
                        if (tcc != null) {
                            var tccH = tcc.getHeight();
                            var cbodyH = c.body.getHeight();
                            var lccF = tcc.getHeight() > c.body.getHeight();
                            Ext.each(o.buttons, function (v) {
                                var btn = Ext.getCmp(v.id);
                                btn.setWidth('100%');
                            });
                        }
                    },
                    resize: function (c, w, h, rw, rh) {
                        var tcc = c.body.child(".x-table-layout");
                        if (tcc != null) {
                            var tccH = tcc.getHeight();
                            var cbodyH = c.body.getHeight();
                            var lccF = tcc.getHeight() > c.body.getHeight();
                            Ext.each(o.buttons, function (v) {
                                var btn = Ext.getCmp(v.id);
                                btn.setWidth('100%');
                            });
                        }
                    }
                }
            });
            return panelH;
        }

        var views = [];
        if (validPrivilegesOperatorComplex(ViewStany.accessPropertyViewName) && !AppTimeouted) {
            views.push(Ext.create('ViewStanyMobile'));
        }
        if (!AppTimeouted)
            if (!Const.SKPFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(ViewTankowania.accessPropertyViewName))
                        views.push(Ext.create('ViewTankowaniaMobile'));
        if (!AppTimeouted)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                if (!Const.STANDARDFLAG)
                    if (!Const.AGGREGATEDCORE)
                        if (validPrivilegesOperatorComplex(ViewSkpPomiary.accessPropertyViewName))
                            views.push(Ext.create('ViewSkpPomiaryMobile'));
        if (!AppTimeouted)
            if (!Const.AGGREGATEDCORE)
                if (validPrivilegesOperatorComplex(ViewDziennik.accessPropertyViewName))
                    views.push(Ext.create('ViewDziennikMobile'));
        if (!AppTimeouted)
            if (!Const.AGGREGATEDCORE)
                if (validPrivilegesOperatorComplex(ViewZaladunki.accessPropertyViewName))
                    views.push(Ext.create('ViewZaladunkiMobile'));
        if (!AppTimeouted)
            if (Const.ENTERPRISEFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(ViewZaladunekLlimity.accessPropertyViewName))
                        views.push(Ext.create('ViewZaladunekLimityMobile'));
        if (!AppTimeouted)
            if (!Const.AGGREGATEDCORE)
                if (validPrivilegesOperatorComplex(ViewKorekta.accessPropertyViewName))
                    views.push(Ext.create('ViewKorektaMobile'));
        if (!AppTimeouted)
            if (!Const.SKPFLAG)
                if (!Const.BASICFLAG && !Const.STARTERFLAG)
                    if (!Const.AGGREGATEDCORE)
                        if (validPrivilegesOperatorComplex(ViewReczne.accessPropertyViewName))
                            views.push(Ext.create('ViewReczneMobile'));
        if (!AppTimeouted)
            if (!Const.SKPFLAG && !Const.STARTERFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(ViewAwaryjne.accessPropertyViewName))
                        views.push(Ext.create('ViewAwaryjneMobile'));
        if (!AppTimeouted)
            if (!Const.SKPFLAG && !Const.STARTERFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(ViewOdbiorcy.accessPropertyViewName))
                        views.push(Ext.create('ViewOdbiorcyMobile'));
        if (!AppTimeouted)
            if (!Const.SKPFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(ViewPojazdy.accessPropertyViewName))
                        views.push(Ext.create('ViewPojazdyMobile'));
        if (!AppTimeouted)
            if (!Const.AGGREGATEDCORE)
                if (validPrivilegesOperatorComplex(ViewOperators.accessPropertyViewName))
                    views.push(Ext.create('ViewOperatorsMobile'));
        if (!AppTimeouted)
            if (Const.ENTERPRISEFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(ViewFirmas.accessPropertyViewName))
                        views.push(Ext.create('ViewFirmasMobile'));
        if (!AppTimeouted)
            if (!Const.AGGREGATEDCORE)
                if (validPrivilegesOperatorComplex(ViewPlyny.accessPropertyViewName))
                    views.push(Ext.create('ViewPlynyMobile'));
        if (!AppTimeouted)
            if (!Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(ViewZbiorniki.accessPropertyViewName))
                        views.push(Ext.create('ViewZbiornikiMobile'));
        if (!AppTimeouted)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                if (!Const.STANDARDFLAG)
                    if (validPrivilegesOperatorComplex(ViewSkps.accessPropertyViewName))
                        views.push(Ext.create('ViewSkpsMobile'));
        if (!AppTimeouted)
            if (!Const.SKPFLAG && !Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG)
                if (validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyViewName))
                    views.push(Ext.create('ViewSterownikiMobile'));
        if (!AppTimeouted)
            if (!Const.SKPFLAG && !Const.BASICFLAG && !Const.OPTIMALFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(ViewRozszerzenia.accessPropertyViewName))
                        views.push(Ext.create('ViewRozszerzeniaMobile'));
        if (!AppTimeouted)
            if (Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG)
                if (validPrivilegesOperatorComplex(ViewSterownikiBasicStd.accessPropertyViewName))
                    if (!Const.AGGREGATEDCORE) {
                        views.push(Ext.create('ViewSterownikiBasicStdMobile'));
                    } else {
                        views.push(Ext.create('ViewSterownikiMobile'));
                    }

        var viewsP = [];
        var viewsA = {};
        var viewClasses = {};
        for (var i = 0; i < views.length; i++) {
            var button = {
                xtype: 'button',
                iconAlign: 'top',
                scale: 'large',
                enableToggle: true,
                cls: 'main-view',
                id: views[i].buttonId,
                cat: views[i].cat,
                text: views[i].text,
                iconCls: views[i].buttonIconCls,
                panelView: views[i],
                focusable: false,
                blocked: false
            };
            button.handler = function () {
                if (!this.blocked) {
                    Ext.suspendLayouts();
                    var contenerViews = Ext.getCmp("main-view-contenerviews");
                    if (!this.pressed)
                        this.toggle(true, true);
                    if (AppCommonData.CurrentView != null) {
                        if (AppCommonData.CurrentView.id == this.id)
                            return;
                        else {
                            AppCommonData.CurrentView.toggle(false, true);
                            AppCommonData.CurrentView.panelView.hide();
                            AppCommonData.CurrentView.blocked = false;
                        }
                    }
                    var button = this;
                    this.blocked = true;
                    if (contenerViews.getComponent(button.panelView.id) == null) {
                        if (button.panelView.destroyed) {
                            button.panelView = Ext.create(viewClasses[button.panelView.id]);
                        }
                        contenerViews.add(button.panelView);
                    }
                    contenerViews.getLayout().setActiveItem(button.panelView.id);
                    AppCommonData.CurrentView = button;
                    var tttxxcc = button.text.replace(/&nbsp;/g, "");
                    var textC = tttxxcc.capitalise();
                    Ext.get("main-panel-title-north").update(textC);
                    if (!button.panelView.showedFirst) {
                        button.panelView.showFirst(AppCommonDataEvents);
                        button.panelView.showedFirst = true;
                    }
                    updateGUILocationHash({
                        "uvroute": AppCommonData.CurrentView.panelView.id
                    });
                    Ext.resumeLayouts(true);
                    button.panelView.show();
                    if (Ext.getCmp('main-view-sidebar-menu'))
                        Ext.getCmp('main-view-sidebar-menu').setHidden(true);
                } else {
                    if (!this.pressed) {
                        this.toggle(true, true);
                    }
                    if (Ext.getCmp('main-view-sidebar-menu'))
                        Ext.getCmp('main-view-sidebar-menu').setHidden(true);
                }
            };
            viewClasses[button.panelView.id] = Ext.getClassName(button.panelView);
            viewsA[button.panelView.id] = button;
            viewsP.push(button);
        }
        if (!AppTimeouted)
            if (!Const.CLOUDFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(WindowSysKonfaccessPropertyViewName))
                        viewsP.push(getViewButton("sys-konf", Lang.konfiguracja_systemu, "iconKonfSys", function () {
                            var w = Ext.create('WindowSysKonf');
                            w.showW();
                        }, "k"));
        if (!AppTimeouted)
            if (Const.CLOUDFLAG && AppFIRMACTX != null)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(WindowSysKonfFirmaaccessPropertyViewName))
                        viewsP.push(getViewButton("sys-konf", Lang.konfiguracja_systemu, "iconKonfSys", function () {
                            var w = Ext.create('WindowFirmaCloudDef');
                            w.showW();
                        }, "k"));
        if (!AppTimeouted)
            if (!Const.AGGREGATEDCORE)
                viewsP.push(getViewButton("opc-konf", Lang.zmien_obecnego_operatora, "iconKonfOPC", function () {
                    var w = Ext.create('WindowOperatorCurrentChange');
                    w.showW();
                }, "k"));
        if (!(isValidOperatorFirmaRaw() || !Const.ENTERPRISEFLAG)) {
            if (!Const.AGGREGATEDCORE)
                viewsP.push(getViewButton('zmien-context', Lang.zmien_kontekst, 'iconKonfSys', function () {
                    var w = Ext.create('WindowCtxFirmaBtnChooser', {
                        dataFS: AppFirmaList,
                        currentObject: (AppFIRMACTX == null ? 0 : AppFIRMACTX.id)
                    });
                    w.showW();
                    w.on("itemselected", function (c, r) {
                        changeContextFirm(r);
                    });
                }, 'n'));
        }
        if (!AppTimeouted)
            if (Const.ENABLENEFPROCESSING && !Const.CLOUDFLAG)
                if (!Const.AGGREGATEDCORE)
                    if (validPrivilegesOperatorComplex(WindowNefRaport.accessPropertyViewName))
                        if (validPrivilegesOperatorComplex(ViewTankowania.accessPropertyViewName) || validPrivilegesOperatorComplex(ViewZaladunki.accessPropertyViewName))
                            viewsP.push(getViewButton("NEF", Lang.raport_nef, "iconNef", function () {
                                var w = Ext.create('WindowNefRaport');
                                w.showW();
                            }, "t"));
        if (!AppTimeouted)
            if (Const.CLOUDFLAG)
                if (validPrivilegesOperatorComplex(WindowCloudAccessPropertyViewName) || Const.AGGREGATEDCORE)
                    viewsP.push(getViewButton("cloud-konf", Lang.chmura, "iconCloudLink", function () {
                        window.location = Config.prefixportallink;
                    }, "n"));
        viewsP.push(getViewButton("about", Lang.o_programie, "iconAbout", function () {
            var w = Ext.create('WindowAbout');
            w.showW();
        }, "n"));
        var ttls = {
            p: Lang.podglad,
            t: Lang.operacje,
            k: Lang.konfiguracja,
            n: Lang.narzedzia
        };
        var viewsPP = {};
        Ext.each(viewsP, function (e) {
            if (!viewsPP[e.cat]) {
                viewsPP[e.cat] = {};
                viewsPP[e.cat].title = ttls[e.cat].capitalise();
                viewsPP[e.cat].buttons = [];
            }
            e.text = e.text.capitalise();
            if (e.text.length < 18) {
                var ll = 18 - e.text.length;
                ll = ll / 2;
                var ty = "";
                for (var i = 0; i < ll; i++)
                    ty += "&nbsp;";
                e.text = ty + e.text + ty;
            }
            viewsPP[e.cat].buttons.push(e);
        });
        var viewsPPP = [];
        for (var a in viewsPP) {
            viewsPPP.push(getViewPanelTab(viewsPP[a]));
        }
        var viewClsImageTiitle = "";
        var viewClsImageTiitle5 = "";
        if (Const.AGGREGATEDCORE) {
            viewClsImageTiitle = "main-panel-image-title4cloud-mobile";
            viewClsImageTiitle5 = "main-panel-image-title5cloud-mobile";
        } else if (Const.SKPFLAG) {
            viewClsImageTiitle = "main-panel-image-title4skp-mobile";
            viewClsImageTiitle5 = "main-panel-image-title5skp-mobile";
        } else if (Const.OPTIMALFLAG) {
            viewClsImageTiitle = "main-panel-image-title4opt-mobile";
            viewClsImageTiitle5 = "main-panel-image-title5opt-mobile";
        } else if (Const.ENTERPRISEFLAG) {
            viewClsImageTiitle = "main-panel-image-title4-mobile";
            viewClsImageTiitle5 = "main-panel-image-title5-mobile";
        } else if (Const.BASICFLAG) {
            viewClsImageTiitle = "main-panel-image-title4basic-mobile";
            viewClsImageTiitle5 = "main-panel-image-title5basic-mobile";
        } else if (Const.STARTERFLAG) {
            viewClsImageTiitle = "main-panel-image-title4starter-mobile";
            viewClsImageTiitle5 = "main-panel-image-title5starter-mobile";
        } else if (Const.STANDARDFLAG) {
            viewClsImageTiitle = "main-panel-image-title4std-mobile";
            viewClsImageTiitle5 = "main-panel-image-title5std-mobile";
        } else if (Const.PROFLAG) {
            viewClsImageTiitle = "main-panel-image-title4pro-mobile";
            viewClsImageTiitle5 = "main-panel-image-title5pro-mobile";
        } else {
            viewClsImageTiitle = "main-panel-image-title4cloud-mobile";
            viewClsImageTiitle5 = "main-panel-image-title5cloud-mobile";
        }
        const controlHeightEl = document.getElementById('control-height');
        const addressBarHeight = controlHeightEl.clientHeight - window.innerHeight;
        if (Ext.os.is.Android) {
            ExtjsMobileBrowserWindowEvents.on('windowResized', () => {
                const viewPort = Ext.getCmp('main-viewport');
                const addressBarFill = Ext.getCmp('address-bar-fill');
                viewPort.setMinHeight(null);
                if (controlHeightEl.clientHeight == window.innerHeight) {
                    viewPort.setHeight(window.innerHeight);
                    addressBarFill.setHeight(0);
                } else {
                    viewPort.setHeight(window.innerHeight + addressBarHeight);
                    addressBarFill.setHeight(addressBarHeight);
                }
            }, this, {
                buffer: 100
            });
        }
        var viewPort = Ext.create('Ext.container.Viewport', {
            layout: 'border',
            id: 'main-viewport',
            border: false,
            minHeight: Ext.os.is.Android ? Ext.getBody().getViewSize().height + addressBarHeight :
                Ext.getBody().getViewSize().height,
            items: [{
                xtype: 'container',
                height: 50,
                layout: 'border',
                region: 'north',
                items: [{
                    xtype: 'button',
                    iconCls: 'burger-menu-button',
                    width: 50,
                    region: 'west',
                    listeners: {
                        scope: this,
                        click: function () {
                            let menu = Ext.getCmp('main-view-sidebar-menu');
                            if (menu)
                                menu.setHidden(!menu.hidden);
                        }
                    }
                }, {
                    id: "main-view-header",
                    xtype: 'panel',
                    region: 'center',
                    bodyCls: "cn-panel main-panel-image-title3",
                    height: 50,
                    border: false,
                    html: {
                        cls: "cn-panel",
                        cn: [{
                            cls: viewClsImageTiitle
                        }, {
                            cls: viewClsImageTiitle5
                        }, {
                            cls: "main-panel-button-header-help-mobile",
                            id: "main-panel-button-header-help"
                        }, {
                            cls: "main-panel-button-header-logout-mobile",
                            id: "main-panel-button-header-logout"
                        }]
                    }
                }]
            }, {
                border: false,
                region: 'center',
                xtype: 'panel',
                layout: 'border',
                cls: Ext.os.deviceType != "Desktop" && Ext.os.name != "iOS" && Ext.browser.is("Chrome") ? "mobile-scaling" : null,
                items: [{
                    id: 'main-view-sidebar-menu',
                    border: true,
                    xtype: 'panel',
                    region: 'east',
                    multi: false,
                    width: '100%',
                    collapsible: false,
                    hidden: true,
                    layout: {
                        type: 'accordion'
                    },
                    cls: "main-view-accordion",
                    bodyStyle: 'background-color:#F1F1F1',
                    items: viewsPPP
                }, {
                    xtype: 'panel',
                    border: true,
                    bodyStyle: "border-top:0px;border-right:0px",
                    region: 'center',
                    layout: 'border',
                    items: [{
                        region: 'north',
                        border: false,
                        id: "main-view-northviews",
                        bodyCls: "main-panel-image-title1-mobile",
                        xtype: 'panel',
                        layout: 'fit',
                        height: 25,
                        html: {
                            cls: "cn-panel",
                            cn: [{
                                cls: "main-panel-image-title6-mobile"
                            }, {
                                cls: "main-panel-title-north",
                                id: "main-panel-title-north"
                            }]
                        }
                    }, {
                        border: true,
                        id: "main-view-contenerviews",
                        xtype: 'panel',
                        style: 'width: 100%;',
                        region: 'center',
                        layout: 'card',
                        layoutConfig: {
                            deferredRender: true
                        },
                        activeItem: 0,
                        items: []
                    }]
                },
                    Ext.os.is.Android ?
                        {
                            xtype: 'box',
                            id: 'address-bar-fill',
                            region: 'south',
                            height: addressBarHeight
                        } : {
                            hidden: true
                        }]
            }, {
                id: "main-view-statusbar",
                hidden: true,
                xtype: 'toolbar',
                region: 'south',
                height: 35,
                items: [getDefFirmaContextCombo(), {
                    id: "updateloginuser",
                    xtype: 'label',
                    style: 'display:block;margin-left:5px'
                }, "->", {
                    id: 'updaterequired',
                    xtype: 'label',
                    style: 'display: block;',
                    hidden: (!Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG) || Const.CLOUDFLAG
                }, "->", {
                    id: "totalinfo",
                    xtype: 'label',
                    style: 'display:block;margin-right:5px'
                }, {
                    id: "lastbackupuploadingstatus",
                    xtype: 'label',
                    style: 'display:block;margin-right:15px',
                    hidden: Const.ENTERPRISEFLAG && AppOPERATORFIRMA != null
                }, {
                    id: "updateczasserwer",
                    xtype: 'label',
                    style: 'display:block;margin-right:5px'
                }]
            }]
        });
        Ext.get("main-panel-button-header-help").on("click", function () {
            window.open('https://download.teamviewer.com/download/TeamViewerQS_pl.exe', '_blank');
        });
        Ext.get("main-panel-button-header-logout").on("click", function () {
            window.location = "rest/systemreq/userSignOut";
        });
        Ext.defer(() => {
            var updateloginuser = Ext.getCmp("updateloginuser");
            updateloginuser.setText(AppOPERATOR.imie + " " + AppOPERATOR.nazwisko);
            var viewc = AppCLocationParams.uvroute;
            if (!viewc) {
                if (viewsA["stany"]) {
                    viewc = Ext.getCmp(viewsA["stany"].id);
                } else if (viewsA["tankowania"]) {
                    viewc = Ext.getCmp(viewsA["tankowania"].id);
                } else if (viewsA["reportlist"]) {
                    viewc = Ext.getCmp(viewsA["reportlist"].id);
                } else if (viewsA["pivot"]) {
                    viewc = Ext.getCmp(viewsA["pivot"].id);
                } else if (viewsA["skppomiary"]) {
                    viewc = Ext.getCmp(viewsA["skppomiary"].id);
                } else if (viewsA["dziennik"]) {
                    viewc = Ext.getCmp(viewsA["dziennik"].id);
                }
                if (viewc) {
                    viewc.handler.call(viewc);
                }
            } else {
                viewc = Ext.getCmp(viewsA[viewc].id);
                viewc.handler.call(viewc);
                if (Ext.os.deviceType != "Desktop") {
                    var accordion = Ext.getCmp(viewc.panelHi).up();
                    accordion.on("expand", function () {
                        Ext.getCmp(viewc.panelHi).expand(2000);
                    }, null, {
                        single: true
                    });
                } else {
                    Ext.getCmp(viewc.panelHi).expand();
                }
            }
        }, 250);
        if (!Const.CLOUDFLAG) {
            performAjaxLPromise('rest/maintenanceinfo/getUpdateInfo').then(data => {
                if (data) {
                    let today = new Date();
                    let weekAgo = new Date();
                    weekAgo.setDate(today.getDate() - 7);
                    if (data.maintenanceTo != null && (new Date(data.maintenanceTo) < today)) {
                        let lastMaintenanceNotify = Ext.state.Manager.get('lastMaintenanceNotify');
                        if (!lastMaintenanceNotify || (lastMaintenanceNotify && (new Date(lastMaintenanceNotify) <= weekAgo) &&
                            lastMaintenanceNotify != 'doNot')) {
                            Ext.create('WindowMaintenanceReminder', {
                                maximized: true
                            }).showW();
                        }
                    }
                }
            }).catch(err => {
                Ext.MessageBox.alert(Lang.error, err);
            });
        }
        if (Const.CLOUDFLAG && AppFIRMACTX != null && AppOPERATORFIRMA != null && !AppFIRMACTX.agreementaccepted) {
            var w = Ext.create('WindowAgreements');
            w.showW();
        }
    }

    applicationStartExd(UseMobileUI ? "main-45x-mobile" : "main-45x", function () {
        if (UseMobileUI) {
            initMainMobile();
        } else {
            initMain();
        }
        if (!Const.CLOUDFLAG) {
            updateLastBackupUploadingStatus();
        }
        if (Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG)
            checkUpdateRequired();
    });

    function updateLastBackupUploadingStatus() {
        performAjaxLPromise('rest/remotebackup/getStatus').then(data => {
            if (data != "") {
                var lastbackupuploadingstatus = Ext.getCmp("lastbackupuploadingstatus");
                lastbackupuploadingstatus.setText(data);
            }
        }).catch(err => {
            Ext.MessageBox.alert(Lang.error, err);
        });
    }

    return <></>
}

export default User;