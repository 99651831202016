Ext.define('WindowPojazdLicznik', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.edycja_stanu_licznika,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: "fit",
    dane: {},
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'form',
            bodyStyle: {
                background: '#f1f1f1',
                padding: '5px'
            },
            items: [{
                xtype: 'numberfield',
                itemId: "stanlicznika",
                fieldLabel: Lang.stan_licznika,
                labelAlign: "left",
                minValue: 0
            }, {
                xtype: 'displayfield',
                itemId: "lastupdate",
                fieldLabel: Lang.data_ostatniego_tankowania
            }]
        }];
        this.buttons = [{
            text: Lang.zapisz,
            scope: this,
            handler: this.saveLicznikState
        }, {
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    saveLicznikState: function () {
        var stanLicznika = this.down("#stanlicznika").getValue();
        sendAjaxDataJsonRpcL("rest/pojazdy/updatePojazdState", {
            id: this.dane["pojazdid"],
            stanlicznika: stanLicznika
        }, this, function (data) {
            if (data.status) {
                this.fireEvent("wclose", stanLicznika);
                this.close();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    showW: function () {
        this.show();
        this.down("#stanlicznika").setValue(this.dane["stanlicznika"]);
        this.down("#lastupdate").setValue(this.dane["lastupdate"]);
    },
    closeOp: function () {
        this.close();
    }
});
