Ext.define('WindowOdbiorcy', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.odbiorca.capitalise(),
    width: 900,
    height: 785,
    closable: false,
    collapsible: false,
    modal: true,
    scrollable: true,
    resizable: false,
    layout: 'fit',
    initComponent: function (config) {
        this.cid = Ext.id();
        let formpanel = {
            id: this.cid + "formpanel",
            title: Lang.ogolne.capitalise(),
            xtype: 'form',
            frame: true,
            border: false,
            scrollable: true,
            autoWidth: true,
            autoHeight: true,
            height: 535,
            labelWidth: 80,
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0",
                labelWidth: 80
            },
            fileUpload: true,
            items: [{
                anchor: "0",
                layout: 'column',
                frame: false,
                border: false,
                items: [{
                    columnWidth: 1,
                    layout: 'anchor',
                    frame: false,
                    border: false,
                    labelWidth: 130,
                    defaults: {
                        anchor: "0",
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: [{
                        id: this.cid + "imie",
                        name: "imie",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.imie,
                        allowBlank: false,
                        maxLength: 16,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        id: this.cid + "nazwisko",
                        name: "nazwisko",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.nazwisko,
                        allowBlank: false,
                        maxLength: 16,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        id: this.cid + "grupa",
                        name: "grupa",
                        style: "margin-top: 10px",
                        fieldLabel: Lang.grupa,
                        allowBlank: true,
                        xtype: 'comboexd',
                        emptyText: Lang.brak_grupy,
                        store: Ext.create('Ext.data.Store', {
                            fields: [{
                                name: 'id',
                                allowNull: true
                            }, 'nazwa'],
                            proxy: {
                                type: 'ajax',
                                url: 'rest/grupy/getList',
                                actionMethods: {
                                    read: 'POST'
                                },
                                extraParams: {
                                    type: Const.GROUP_TYPE_ODBIORCA
                                },
                                reader: {
                                    type: 'json',
                                    rootProperty: 'data'
                                }
                            },
                            autoLoad: false
                        }),
                        displayField: 'nazwa',
                        valueField: 'id'
                    }, {
                        id: this.cid + "eiid",
                        name: "eiid",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.eiid,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                    }, {
                        xtype: 'radiogroupexd',
                        id: this.cid + "active",
                        baseCls: 'x-radio-column',
                        name: "active",
                        fieldLabel: Lang.active,
                        columns: 2,
                        vertical: false,
                        items: [{
                            boxLabel: Lang.tak.capitalise(),
                            inputValue: true
                        }, {
                            boxLabel: Lang.nie.capitalise(),
                            inputValue: false,
                            value: true
                        }]
                    }, {
                        xtype: 'identyfikatorpanel',
                        anchor: '-50',
                        fieldLabel: Lang.identyfikator,
                        labelAlign: 'left',
                        id: this.cid + "identpanel"
                    }]
                }]
            }]
        };
        let opis = {
            anchor: "0",
            fieldLabel: Lang.opis,
            isFormField: true,
            id: this.cid + "opis",
            name: "opis",
            xtype: 'textarea'
        };
        let panelstanczytnika = {
            id: this.cid + "panelstanczytnika",
            xtype: "panelstanczytnikaexd"
        };
        if (WindowExdMobileDEVICEV)
            formpanel.items[0].items[0].items.push(opis, panelstanczytnika);
        else
            formpanel.items.push({
                anchor: "0",
                layout: 'column',
                frame: false,
                border: false,
                isFormField: false,
                items: [{
                    columnWidth: 0.7,
                    layout: 'anchor',
                    frame: false,
                    border: false,
                    isFormField: false,
                    height: 150,
                    style: "margin-top:10px",
                    defaults: {
                        anchor: "0"
                    },
                    items: [opis]
                }, {
                    style: "margin-left:15px;",
                    columnWidth: .3,
                    xtype: 'container',
                    layout: 'anchor',
                    cls: "x-ident-main-border2",
                    height: 150,
                    id: this.cid + "identpanelct",
                    items: [panelstanczytnika]
                }]
            });
        this.items = [{
            xtype: 'tabpanel',
            autoWidth: true,
            autoHeight: true,
            border: false,
            deferredRender: false,
            activeTab: 0,
            items: [formpanel, {
                id: this.cid + "formpanel2",
                title: Lang.zaawansowane.capitalise(),
                xtype: 'form',
                frame: true,
                border: false,
                autoWidth: true,
                height: 535,
                labelWidth: 150,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0",
                    labelWidth: 150
                },
                fileUpload: true,
                items: [{
                    anchor: "0",
                    layout: 'column',
                    frame: false,
                    border: false,
                    items: [{
                        columnWidth: 1,
                        layout: 'anchor',
                        frame: false,
                        border: false,
                        labelWidth: 150,
                        defaults: {
                            anchor: "0",
                            labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                        },
                        items: [{
                            xtype: 'radiogroupexd',
                            id: this.cid + "canviewtankowanie",
                            name: "canviewtankowanie",
                            baseCls: 'x-radio-column',
                            fieldLabel: Lang.podglad_tankowania,
                            columns: 2,
                            vertical: false,
                            items: [{
                                boxLabel: Lang.tak.capitalise(),
                                inputValue: true,
                                value: true
                            }, {
                                boxLabel: Lang.nie.capitalise(),
                                inputValue: false
                            }]
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "cansetaddpole",
                            name: "cansetaddpole",
                            baseCls: 'x-radio-column',
                            fieldLabel: Lang.pole_dodatkowe_przy_tankowaniu,
                            columns: 2,
                            vertical: false,
                            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                            items: [{
                                boxLabel: Lang.tak.capitalise(),
                                inputValue: true
                            }, {
                                boxLabel: Lang.nie.capitalise(),
                                inputValue: false,
                                value: true
                            }]
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "askforvolumetank",
                            name: "askforvolumetank",
                            baseCls: 'x-radio-column',
                            fieldLabel: Lang.pytanie_o_ilosc_dozatankowania,
                            columns: 2,
                            vertical: false,
                            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                            items: [{
                                boxLabel: Lang.tak,
                                inputValue: true
                            }, {
                                boxLabel: Lang.nie,
                                inputValue: false,
                                value: true
                            }]
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "isdesczaladunek",
                            name: "isdesczaladunek",
                            baseCls: 'x-radio-column',
                            fieldLabel: Lang.zaladunkowy,
                            columns: 2,
                            vertical: false,
                            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                            items: [{
                                boxLabel: Lang.tak.capitalise(),
                                inputValue: true
                            }, {
                                boxLabel: Lang.nie.capitalise(),
                                inputValue: false,
                                value: true
                            }]
                        }]
                    }]
                }]
            }, {
                title: Lang.limity.capitalise(),
                frame: true,
                border: false,
                autoWidth: true,
                scrollable: true,
                height: WindowExdMobileDEVICEV ? '100%' : 535,
                hidden: Const.SKPFLAG || Const.BASICFLAG || Const.STARTERFLAG,
                defaults: {
                    anchor: "0",
                    labelWidth: 80,
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    id: this.cid + 'limitnotice',
                    xtype: 'displayfield',
                    value: Lang.limity_wylacznie_online,
                    fieldStyle: 'color: red; text-align: center;',
                    hidden: !Const.STANDARDFLAG,
                    height: 40
                }, Ext.create('LimitPanelCp', {
                    id: this.cid + "limitpanel",
                    frame: false,
                    border: false,
                    height: WindowExdMobileDEVICEV ? '100%' : 531 - (Const.STANDARDFLAG ? 50 : 0),
                    header: false,
                    parentObj: this
                })]
            }, {
                id: this.cid + "formpanel4",
                title: Lang.ustawienia_indywidualne,
                xtype: 'form',
                frame: true,
                border: false,
                autoWidth: true,
                scrollable: true,
                autoHeight: true,
                height: 535,
                hidden: Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                labelWidth: 80,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0",
                    labelWidth: 80
                },
                items: [{
                    anchor: "0",
                    layout: 'column',
                    frame: false,
                    border: false,
                    items: [{
                        columnWidth: 1,
                        layout: 'anchor',
                        frame: false,
                        border: false,
                        defaults: {
                            anchor: "0",
                            labelWidth: 130,
                            labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                        },
                        items: [{
                            xtype: 'radiogroupexd',
                            id: this.cid + "individualopts",
                            name: "individualopts",
                            fieldLabel: Lang.ustawienia_indywidualne,
                            columns: 2,
                            vertical: false,
                            items: [{
                                boxLabel: Lang.tak,
                                inputValue: true
                            }, {
                                boxLabel: Lang.nie,
                                inputValue: false,
                                value: true
                            }],
                            listeners: {
                                change: function (radiogroup, newValue) {
                                    Ext.getCmp(this.cid + "ident_tryb").setDisabled(!newValue);
                                    Ext.getCmp(this.cid + "ident_pojqq").setDisabled(!newValue);
                                },
                                scope: this
                            },
                            hidden: Const.SKPFLAG
                        }, {
                            id: this.cid + 'ident_tryb',
                            xtype: 'comboexd',
                            store: [[Const.IDENT_PODWOJNA, Lang.podwojna], [Const.IDENT_POJEDYNCZA, Lang.pojedyncza]],
                            triggerAction: 'all',
                            resizable: false,
                            mode: "local",
                            editable: false,
                            disabled: true,
                            fieldLabel: Lang.tryb_identyfikacji,
                            hidden: Const.SKPFLAG
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "ident_pojqq",
                            name: "ident_pojqq",
                            fieldLabel: Lang.pytanie_o_nazwe,
                            columns: 2,
                            vertical: false,
                            disabled: true,
                            items: [{
                                boxLabel: Lang.tak,
                                inputValue: true
                            }, {
                                boxLabel: Lang.nie,
                                inputValue: false,
                                value: true
                            }],
                            hidden: Const.SKPFLAG
                        }]
                    }]
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex("access.manage.odbiorcy")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.obiektid = obiektid;
        this.obiekt = null;
        sendAjaxDataJsonRpcLGroup(["rest/odbiorcy/getObjectForEdit", "rest/firmy/getList"], [{
            id: this.obiektid
        }, {showOnlySupported: true}], this, function (gstatus, data, datad, status) {
            if (gstatus) {
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                this.obiekt = datad[0];
                Ext.getCmp(this.cid + "imie").setValue(null);
                Ext.getCmp(this.cid + "imie").clearInvalid();
                Ext.getCmp(this.cid + "nazwisko").setValue(null);
                Ext.getCmp(this.cid + "nazwisko").clearInvalid();
                Ext.getCmp(this.cid + "eiid").setValue(null);
                Ext.getCmp(this.cid + "eiid").clearInvalid();
                Ext.getCmp(this.cid + "active").setValue2(true);
                Ext.getCmp(this.cid + "opis").setValue(null);
                Ext.getCmp(this.cid + "canviewtankowanie").setValue2(true);
                Ext.getCmp(this.cid + "askforvolumetank").setValue2(true);
                Ext.getCmp(this.cid + "cansetaddpole").setValue2(false);
                Ext.getCmp(this.cid + "isdesczaladunek").setValue2(false);
                if (this.obiekt != null) {
                    this.limitydatas = this.obiekt.limitydatas;
                    Ext.getCmp(this.cid + "limitpanel").setLimitList(this.limitydatas);
                    Ext.getCmp(this.cid + "imie").setValue(this.obiekt.imie);
                    Ext.getCmp(this.cid + "nazwisko").setValue(this.obiekt.nazwisko);
                    Ext.getCmp(this.cid + "eiid").setValue(this.obiekt.eiid);
                    Ext.getCmp(this.cid + "active").setValue2(this.obiekt.active);
                    Ext.getCmp(this.cid + "canviewtankowanie").setValue2(this.obiekt.canviewtankowanie);
                    Ext.getCmp(this.cid + "askforvolumetank").setValue2(this.obiekt.askforvolumetank);
                    Ext.getCmp(this.cid + "cansetaddpole").setValue2(this.obiekt.cansetaddpole);
                    Ext.getCmp(this.cid + "isdesczaladunek").setValue2(this.obiekt.isdesczaladunek);
                    Ext.getCmp(this.cid + "opis").setValue(this.obiekt.opis);
                    Ext.getCmp(this.cid + "grupa").getStore().getProxy().setExtraParam('firma', this.obiekt.idfirma);
                    Ext.getCmp(this.cid + "grupa").setValue(this.obiekt.idgrupa);
                    this.identPanel = Ext.getCmp(this.cid + "identpanel");
                    this.identPanel.init({
                        obiektid: obiektid,
                        objectt: "odbiorca",
                        object: this.obiekt,
                        readerPanel: Ext.getCmp(this.cid + 'panelstanczytnika')
                    });
                } else {
                    var firma = datad[1];
                    if (firma[0] != null) {
                        Ext.getCmp(this.cid + "askforvolumetank").setValue2(firma[0].askforvolumetank);
                    }
                    this.obiekt = this.obiekt || {
                        optlockversion: null,
                        ident_tryb: Const.IDENT_PODWOJNA,
                        ident_pojqq: false,
                        mustsetlicznikstate: false,
                        mustsetlicznikstatecontrol: false,
                        individualopts: false
                    };
                    this.limitydatas = [];
                    Ext.getCmp(this.cid + "limitpanel").setLimitList(this.limitydatas);
                    this.identPanel = Ext.getCmp(this.cid + "identpanel");
                    this.identPanel.init({
                        obiektid: obiektid,
                        objectt: "odbiorca",
                        readerPanel: Ext.getCmp(this.cid + 'panelstanczytnika')
                    });
                }
                Ext.getCmp(this.cid + "ident_tryb").setValue(this.obiekt.ident_tryb);
                Ext.getCmp(this.cid + "ident_pojqq").setValue2(this.obiekt.ident_pojqq);
                var individualOpts = Ext.getCmp(this.cid + "individualopts");
                individualOpts.setValue2(this.obiekt.individualopts);
                individualOpts.fireEvent("change", individualOpts, this.obiekt.individualopts);
                Ext.getCmp(this.cid + "grupa").getStore().load({
                    scope: this,
                    callback: function (records, operation, success) {
                        if (Ext.getCmp(this.cid + "grupa")) {
                            Ext.getCmp(this.cid + "grupa").getStore().insert(0, [{
                                nazwa: Lang.brak_grupy,
                                id: null
                            }]);
                        }
                    }
                });
                var title = Lang.odbiorca.capitalise();
                if (AppFIRMACTX != null)
                    title += " (" + AppFIRMACTX.nazwa + ")";
                this.setTitle(title);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        var canWrite = false;
        if (Ext.getCmp(this.cid + "imie").isValid()) {
            if (Ext.getCmp(this.cid + "nazwisko").isValid()) {
                canWrite = true;
            }
        }
        if (!this.identPanel.isValid()) {
            if (Ext.getCmp(this.cid + "active").getValue2()) {
                Ext.MessageBox.alert(Lang.error, Lang.obiekt_aktywny_musi_miec_przydzielony_identyfikator);
                return;
            }
        }
        var msgggp = this.identPanel.isValidOtherErrorIf();
        if (msgggp != null) {
            Ext.MessageBox.alert(Lang.error, msgggp);
            return;
        }
        var vii = this.identPanel.getIdentData();
        if (canWrite) {
            sendAjaxDataJsonRpcL("rest/odbiorcy/saveObject", {
                id: this.obiektid,
                imie: Ext.getCmp(this.cid + "imie").getValue(),
                nazwisko: Ext.getCmp(this.cid + "nazwisko").getValue(),
                eiid: Ext.getCmp(this.cid + "eiid").getValue(),
                active: Ext.getCmp(this.cid + "active").getValue2(),
                askforvolumetank: Ext.getCmp(this.cid + "askforvolumetank").getValue2(),
                canviewtankowanie: Ext.getCmp(this.cid + "canviewtankowanie").getValue2(),
                cansetaddpole: Ext.getCmp(this.cid + "cansetaddpole").getValue2(),
                isdesczaladunek: Ext.getCmp(this.cid + "isdesczaladunek").getValue2(),
                opis: Ext.getCmp(this.cid + "opis").getValue(),
                ident_tryb: Ext.getCmp(this.cid + "ident_tryb").getValue(),
                ident_pojqq: Ext.getCmp(this.cid + "ident_pojqq").getValue2(),
                mustsetlicznikstate: this.obiekt.mustsetlicznikstate,
                mustsetlicznikstatecontrol: this.obiekt.mustsetlicznikstatecontrol,
                individualopts: Ext.getCmp(this.cid + "individualopts").getValue2(),
                identyfikator: vii.identyfikator,
                identtype: vii.identtype,
                pin: vii.pin,
                grupa: Ext.getCmp(this.cid + "grupa").getValue2NL(),
                optlockversion: this.obiekt == null ? null : this.obiekt.optlockversion,
                data: {
                    limitydatas: Ext.getCmp(this.cid + "limitpanel").getLimitList()
                }
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    this.obiektid = data.id;
                    this.identPanel.close();
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                    if (data.error == Lang.identyfikator_uzywany) {
                        this.identPanel.markIdAsUsed();
                    }
                    return;
                }
                if (this.callbackF)
                    this.callbackF();
            }, 0);
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.identPanel.close();
        this.close();
    }
});
