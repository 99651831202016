window.checkIfHttpsRenderCzytnikFixBtn = () => {
    if (window.location.protocol == 'https:') {
        return `${Lang.czytnik_brak_polaczenia_z_usluga}<button
            onclick="Ext.create('WindowCzytnikCertPanel').showW();">${Lang.rozwiazywanie_problemow}</button>`
    } else {
        return Lang.czytnik_brak_polaczenia_z_usluga
    }
};

window.notEmptyAfterTrim = function (v) {
    return v && v.length && v.trim && v.trim().length > 0
};

window.resolveHtmlDocumentType = function () {
    if (Const.ENTERPRISEFLAG) {
        return "_ent"
    } else if (Const.SKPFLAG) {
        return "_skp"
    } else if (Const.OPTIMALFLAG) {
        return "_opt"
    } else if (Const.STARTERFLAG) {
        return "_starter"
    } else if (Const.BASICFLAG) {
        return "_basic"
    } else if (Const.STANDARDFLAG) {
        return "_std"
    } else if (Const.PROFLAG) {
        return "_pro"
    } else return ""
};

window.resolveHtmlDocumentLang = function () {
    if (Const.CURRENTLANG == "pl") {
        return "_pl"
    } else return "_en"
};
