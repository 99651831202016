Ext.define('ViewRozszerzeniaMobile', {
    extend: 'Ext.panel.Panel',
    grid: null,
    windowObject: null,
    id: "rozszerzenia",
    taskContextMenu: null,
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyName: "access.manage.rozszerzenia",
        accessPropertyViewName: "access.view.rozszerzenia"
    },
    cat: "k",
    text: Lang.rozszerzenia,
    buttonIconCls: "iconRozszerzenie",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            itemId: 'kontekstfirmy-container',
            xtype: 'container',
            region: 'north',
            hidden: !(AppFIRMACTX != null),
            style: 'background-color: white',
            items: [{
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                cls: "kontekstfirmy-text kontekstfirmy-text-high kontekstfirmy-text-mobile"
            }]
        }, {
            region: "north",
            xtype: 'toolbar',
            scrollable: true,
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                scope: this,
                handler: this.dodajObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewRozszerzenia.accessPropertyName + "/add"),
                cls: 'x-btn-mobile'
            }, {
                id: this.id + "bedit3",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.klonowanie.capitalise(),
                hidden: Const.CLOUDFLAG,
                scope: this,
                handler: this.edytujObiekt2,
                disabled: !validPrivilegesOperatorComplex(ViewRozszerzenia.accessPropertyName + "/add"),
                cls: 'x-btn-mobile'
            }, {
                id: this.id + "bedit",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEdit",
                text: Lang.edytuj.capitalise(),
                scope: this,
                handler: this.edytujObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewRozszerzenia.accessPropertyName + "/update"),
                cls: 'x-btn-mobile'
            }, {
                id: this.id + "bdelete",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconDelete",
                text: Lang.usun.capitalise(),
                scope: this,
                handler: this.usunObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewRozszerzenia.accessPropertyName + "/delete"),
                cls: 'x-btn-mobile'
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    onDestroy: function () {
        this.taskContextMenu.destroy();
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        if (AppFIRMACTX != null) {
            kontekstfirmy.setText(AppFIRMACTX.nazwa);
        } else {
            kontekstfirmy.setText("");
        }
        this.down('#kontekstfirmy-container').setHidden(!(AppFIRMACTX != null));
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            var onwer = this.grid.ownerCt;
            Ext.suspendLayouts();
            onwer.remove(this.grid);
            this.grid.destroy();
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
            if (this.grid && this.grid.rendered)
                if (this.grid.getDockedComponent(1))
                    this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
    },
    buildGrid: function (datals) {
        this.grid = new Ext.ux.grid.GridPanelExd({
            features: [{
                ftype: 'grouping'
            }],
            plugins: {
                gridfilters: true,
                rowexpander: {
                    scrollIntoViewOnExpand: false,
                    rowBodyTpl: new Ext.XTemplate(
                        `<div class="mobile-rowexp-wrapper">
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.nazwa}:
                                <span class="mobile-rowexp-value"> {nazwa}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.sterownik}:
                                <span class="mobile-rowexp-value"> {sterownik}</span>
                            </span>
                        </div>
                    
                        <tpl if="wersja != null">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.wersja}:
                                    <span class="mobile-rowexp-value"> {version}</span>
                                </span>
                            </div>
                        </tpl>
                    
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.komunikacja}:</span>
                            {scommstate:this.yesNo}
                        </div>
                        
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.active}:</span>
                            {active:this.yesNo}
                        </div>
                    </div>`,
                        {
                            yesNo: renderBool4
                        }
                    )
                }
            },
            store: new Ext.data.Store({
                proxy: new Ext.data.MemoryProxy(),
                autoLoad: true,
                groupField: 'sterownik',
                sorters: [{
                    property: 'nazwa',
                    direction: "ASC"
                }],
                model: Ext.define(null, {
                    extend: 'Ext.data.Model',
                    idProperty: 'id',
                    fields: [{
                        name: 'id',
                        mapping: 'id'
                    }, {
                        name: 'nazwa',
                        mapping: 'nazwa'
                    }, {
                        name: 'version',
                        mapping: 'version'
                    }, {
                        name: 'devid',
                        mapping: 'devid'
                    }, {
                        name: 'sterownik',
                        mapping: 'sterownik'
                    }, {
                        name: 'active',
                        mapping: 'active'
                    }, {
                        name: 'scommstate',
                        mapping: 'scommstate'
                    }]
                }),
                reader: new Ext.data.ArrayReader()
            }),
            columns: [{
                header: Lang.nazwa,
                flex: 2,
                sortable: true,
                dataIndex: 'nazwa',
                filter: {
                    type: 'string'
                },
                sorter: Ext.bind(customSorterForGrid, this, ["nazwa"], true),
                filterable: true
            }, {
                header: Lang.sterownik,
                flex: 2,
                sortable: true,
                dataIndex: 'sterownik',
                filter: {
                    type: 'string'
                },
                sorter: Ext.bind(customSorterForGrid, this, ["sterownik"], true),
                filterable: true
            }, {
                header2: Lang.active,
                menuText: Lang.active,
                header: Ext.DomHelper.markup({
                    tag: "div",
                    cls: "iconDivCol iconActive",
                    cn: [{
                        tag: "span",
                        html: "&nbsp;"
                    }, {
                        tag: "div",
                        html: Lang.active
                    }]
                }),
                flex: 1,
                sortable: true,
                dataIndex: 'active',
                renderer: renderBool3,
                filter: {
                    type: 'boolean'
                },
                filterable: true
            }, {
                header: Lang.wersja,
                flex: 2,
                sortable: true,
                dataIndex: 'version',
                filter: {
                    type: 'string'
                },
                filterable: true,
                hidden: true
            }, {
                header: Lang.komunikacja,
                flex: 1,
                sortable: true,
                dataIndex: 'scommstate',
                renderer: renderBool3,
                filter: {
                    type: 'boolean'
                },
                filterable: true,
                hidden: true
            }],
            frame: false,
            border: false,
            stateId: "grid-rozsz-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            }
        });
        this.grid.addListener("rowdblclick", function () {
            if (validPrivilegesOperatorComplex(ViewRozszerzenia.accessPropertyName + "/update"))
                this.edytujObiekt();
        }, this);
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
    },
    manageSelect: function () {
        var bedit = Ext.getCmp(this.id + "bedit");
        var bedit3 = Ext.getCmp(this.id + "bedit3");
        var bdelete = Ext.getCmp(this.id + "bdelete");
        if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelection() == null || this.grid.getSelectionModel().getSelection().length == 0) {
            bdelete.setDisabled(true);
            bedit.setDisabled(true);
            bedit3.setDisabled(true);
        } else {
            bdelete.setDisabled(!validPrivilegesOperatorComplex(ViewRozszerzenia.accessPropertyName + "/delete"));
            bedit.setDisabled(!validPrivilegesOperatorComplex(ViewRozszerzenia.accessPropertyName + "/update"));
            bedit3.setDisabled(!validPrivilegesOperatorComplex(ViewRozszerzenia.accessPropertyName + "/add"));
        }
    },
    refreshDatastore: function () {
        updateCzasRefreshSerwer();
        if (this.grid && this.grid.rendered)
            this.grid.mask();
        sendAjaxDataJsonRpcL("rest/rozszerzenia/getList", null, this, function (data) {
            if (this.grid && this.grid.rendered)
                this.grid.unmask();
            if (data.status) {
                this.grid.store.loadData(data.data);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    dodajObiekt: function () {
        var windowObject = Ext.create('WindowRozszerzenia', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(0);
    },
    edytujObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        var windowObject = Ext.create('WindowRozszerzenia', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(id);
    },
    edytujObiekt2: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        var windowObject = Ext.create('WindowRozszerzenia', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(id, true);
    },
    usunObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        Ext.MessageBox.show({
            title: Lang.usuwanie,
            msg: Lang.czy_napewno_usunac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/rozszerzenia/deleteObject", {
                        id: id
                    }, this, function (data) {
                        if (data.status) {
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                        this.refreshDatastore();
                    });
                }
            }
        });
    }
});
