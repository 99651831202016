Ext.define('ViewZaladunkiMobile', {
    extend: 'Ext.panel.Panel',
    grid: null,
    gridpage: null,
    windowObject: null,
    id: "zaladunki",
    taskContextMenu: null,
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyName: "access.manage.zaladunki",
        accessPropertyViewName: "access.view.zaladunki",
        accessPropertyViewNameLimitOnly: "access.view.stany_limit_only"
    },
    cat: 't',
    text: Lang.zaladunki,
    buttonIconCls: "iconZaladunek",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            itemId: 'kontekstfirmy-container',
            xtype: 'container',
            region: 'north',
            hidden: !(AppFIRMACTX != null),
            style: 'background-color: white',
            items: [{
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                cls: "kontekstfirmy-text kontekstfirmy-text-high kontekstfirmy-text-mobile"
            }]
        }, {
            region: "north",
            xtype: 'toolbar',
            scrollable: true,
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                scope: this,
                handler: this.dodajObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewZaladunki.accessPropertyName + "/add"),
                cls: 'x-btn-mobile'
            }, {
                id: this.id + "bedit",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEdit",
                text: Lang.edytuj.capitalise(),
                scope: this,
                handler: this.edytujObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewZaladunki.accessPropertyName + "/update"),
                cls: 'x-btn-mobile'
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
        if (this.grid && this.grid.rendered)
            this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        if (AppFIRMACTX != null) {
            kontekstfirmy.setText(AppFIRMACTX.nazwa);
        } else {
            kontekstfirmy.setText("");
        }
        this.down('#kontekstfirmy-container').setHidden(!(AppFIRMACTX != null));
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            var onwer = this.grid.ownerCt;
            Ext.suspendLayouts();
            onwer.remove(this.grid);
            this.grid.destroy();
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
            if (this.grid && this.grid.rendered)
                this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
    },
    buildGrid: function () {
        var store = Ext.create('Ext.data.Store', {
            autoLoad: false,
            remoteSort: true,
            remoteFilter: true,
            pageSize: 50,
            fields: [{
                name: 'czas',
                mapping: 'czas'
            }, {
                name: 'ile',
                mapping: 'ile',
                type: 'float'
            }, {
                name: 'plyn',
                mapping: 'plyn'
            }, {
                name: 'stanzbiornika',
                mapping: 'stanzbiornika',
                type: 'float'
            }, {
                name: 'zbiornik',
                mapping: 'zbiornik'
            }, {
                name: 'pojazd',
                mapping: 'pojazd'
            }, {
                name: 'odbiorca',
                mapping: 'odbiorca'
            }, {
                name: 'dostawca',
                mapping: 'dostawca'
            }, {
                name: 'gestoscoperacji',
                mapping: 'gestoscoperacji'
            }, {
                name: 'gestoscsrednia',
                mapping: 'gestoscsrednia'
            }, {
                name: "userimienazwisko",
                mapping: "userimienazwisko"
            }, {
                name: 'kosztcalosc',
                mapping: 'kosztcalosc'
            }, {
                name: 'stanzbiornikarealbefore',
                mapping: 'stanzbiornikarealbefore'
            }, {
                name: 'stanzbiornikareal',
                mapping: 'stanzbiornikareal'
            }, {
                name: 'stanzbiornikadelta',
                mapping: 'stanzbiornikadelta'
            }, {
                name: 'kosztlitr',
                mapping: 'kosztlitr'
            }, "opis"],
            proxy: {
                type: "ajax",
                url: "rest/zaladunekkorekty/getList",
                filterParam: "filters",
                actionMethods: {
                    read: 'POST'
                },
                reader: {
                    type: 'json',
                    rootProperty: 'data',
                    totalProperty: 'total',
                    id: 'id'
                },
                extraParams: {
                    iskorekta: false
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            sorters: {
                property: "czas",
                direction: "DESC"
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        var cols = [{
            header: Lang.czas,
            flex: 2,
            sortable: true,
            dataIndex: 'czas',
            filter: {
                type: 'date'
            },
            filterable: true
        }, {
            header: Lang.zbiornik,
            flex: 2,
            sortable: true,
            dataIndex: 'zbiornik',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.plyn,
            flex: 2,
            sortable: true,
            dataIndex: 'plyn',
            filter: {
                type: 'string'
            },
            filterable: true,
            hidden: true
        }, {
            header: Lang.zaladunek.capitalise(),
            flex: 2,
            sortable: true,
            dataIndex: 'ile',
            filter: {
                type: 'numeric'
            },
            filterable: true
        }];
        if (Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG) {
            removeObjectFromArrayById(cols, "pojazd");
            removeObjectFromArrayById(cols, "odbiorca");
        }
        if (Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG) {
            removeObjectFromArrayById(cols, "gestoscoperacji");
            removeObjectFromArrayById(cols, "gestoscsrednia");
            removeObjectFromArrayById(cols, "dostawca");
        }
        if (validPrivilegesOperatorComplex(ViewZaladunki.accessPropertyViewNameLimitOnly) && isValidOperatorFirmaLimit()) {
            removeObjectFromArrayById(cols, "stanzbiornika");
        }
        if (!Const.COSTCOLUMNS) {
            removeObjectFromArrayById(cols, "kosztcalosc");
            removeObjectFromArrayById(cols, "kosztlitr");
        }
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true,
                rowexpander: {
                    rowBodyTpl: new Ext.XTemplate(
                        `<div class="mobile-rowexp-wrapper">
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.czas}:
                                <span class="mobile-rowexp-value"> {czas}</span>
                            </span>
                        </div>
                    
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.zbiornik}:
                                <span class="mobile-rowexp-value"> {zbiornik}</span>
                            </span>
                        </div>
                    
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.plyn}:
                                <span class="mobile-rowexp-value"> {plyn}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.zaladunek.capitalise()}:
                                <span class="mobile-rowexp-value"> {ile:this.ile}</span>
                            </span>
                        </div>
                        
                        ${
                            !(validPrivilegesOperatorComplex(ViewZaladunki.accessPropertyViewNameLimitOnly) && isValidOperatorFirmaLimit()) ?
                                `<div class="mobile-rowexp-row">
                                    <span class="mobile-rowexp-title">${Lang.stan_pozaladunku}:
                                        <span class="mobile-rowexp-value"> {stanzbiornika:this.stanzbiornika}</span>
                                    </span>
                                </div>`
                                :
                                ''
                        }

                        ${
                            Const.OPTIMALFLAG || Const.PROFLAG || Const.ENTERPRISEFLAG ?
                                `<tpl if="gestoscoperacji != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.gestosc}:
                                            <span class="mobile-rowexp-value"> {gestoscoperacji:this.gestoscoperacji}</span>
                                        </span>
                                    </div>
                                </tpl>
                                <tpl if="gestoscsrednia != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.gestosc_srednia}:
                                            <span class="mobile-rowexp-value"> {gestoscsrednia:this.gestoscsrednia}</span>
                                        </span>
                                    </div>
                                </tpl>`
                                :
                                ''
                        }

                        ${
                            Const.COSTCOLUMNS ?
                                `<tpl if="kosztlitr != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.cena_jednostkowa}:
                                            <span class="mobile-rowexp-value"> {kosztlitr:this.kosztlitr}</span>
                                        </span>
                                    </div>
                                </tpl>
                                <tpl if="kosztcalosc != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.koszt}:
                                            <span class="mobile-rowexp-value"> {kosztcalosc:this.kosztcalosc}</span>
                                        </span>
                                    </div>
                                </tpl>`
                                :
                                ''
                        }

                        <tpl if="this.notEmptyAfterTrim(operator)">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.operator}:
                                    <span class="mobile-rowexp-value"> {userimienazwisko}</span>
                                </span>
                            </div>
                        </tpl>

                        ${
                            Const.OPTIMALFLAG || Const.PROFLAG || Const.ENTERPRISEFLAG ?
                                `<tpl if="this.notEmptyAfterTrim(dostawca)">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.dostawca}:
                                            <span class="mobile-rowexp-value"> {dostawca}</span>
                                        </span>
                                    </div>
                                </tpl>`
                                :
                                ''
                        }
                    
                        ${
                            validPrivilegesOperatorComplex(ViewSkpPomiary.accessPropertyViewName) && (Const.OPTIMALFLAG || Const.PROFLAG || Const.ENTERPRISEFLAG) ?
                                `<tpl if="stanzbiornikarealbefore != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.stan_zbiornika_real_before}:
                                            <span class="mobile-rowexp-value"> {stanzbiornikarealbefore:this.stanzbiornikarealbefore}</span>
                                        </span>
                                    </div>
                                </tpl>
                                <tpl if="stanzbiornikareal != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.stan_zbiornika_real}:
                                            <span class="mobile-rowexp-value"> {stanzbiornikareal:this.stanzbiornikareal}</span>
                                        </span>
                                    </div>
                                </tpl>
                                <tpl if="stanzbiornikadelta != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.stan_zbiornika_delta}:
                                            <span class="mobile-rowexp-value"> {stanzbiornikadelta:this.stanzbiornikadelta}</span>
                                        </span>
                                    </div>
                                </tpl>`
                                :
                                ''
                        }
                    
                        <tpl if="this.notEmptyAfterTrim(opis)">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.opis}:
                                    <span class="mobile-rowexp-value"> {opis}</span>
                                </span>
                            </div>
                        </tpl>
                    </div>`,
                        {
                            stanzbiornika: function (v) {
                                return Ext.Number.roundToPrecision(v, 2).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            ile: function (v) {
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            gestoscoperacji: function (v) {
                                return v == null ? '' : Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            gestoscsrednia: function (v) {
                                return v == null ? '' : Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            kosztlitr: function (v) {
                                return v == null ? '' : Ext.Number.toFixed(v, 2).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            kosztcalosc: function (v) {
                                return v == null ? '' : Ext.Number.toFixed(v, 2).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            stanzbiornikareal: function (v) {
                                return v == null ? '' : Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            stanzbiornikarealbefore: function (v) {
                                return v == null ? '' : Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            stanzbiornikadelta: function (v) {
                                return v == null ? '' : Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            notEmptyAfterTrim
                        }
                    )
                }
            },
            store: store,
            columns: cols,
            scrollable: 'y',
            forceFit: true,
            frame: false,
            border: false,
            stateId: "grid-mobile-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            },
            bbar: {
                xtype: 'pagetoolbarown',
                displayInfo: true,
                pagingStore: store,
                doRefresh: this.refreshDatastore.bind(this)
            }
        });
        this.grid.addListener("rowdblclick", function () {
            if (validPrivilegesOperatorComplex(ViewZaladunki.accessPropertyName + "/update"))
                this.edytujObiekt();
        }, this);
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.store.addListener("load", function () {
            updateCzasRefreshSerwer();
        }, this);
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
    },
    manageSelect: function () {
        var bedit = Ext.getCmp(this.id + "bedit");
        if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelection() == null) {
            bedit.setDisabled(true);
        } else {
            bedit.setDisabled(!validPrivilegesOperatorComplex(ViewZaladunki.accessPropertyName + "/update"));
        }
    },
    refreshDatastore: function () {
        updateCzasRefreshSerwer();
        this.grid.store.load();
    },
    dodajObiekt: function () {
        var windowObject = Ext.create('WindowZaladunek', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(0);
    },
    edytujObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        var windowObject = Ext.create('WindowZaladunek', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(id);
    }
});
