Ext.define('WindowMaintenanceReminder', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.koniec_wsparcia,
    width: 600,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    maximized: false,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        if (this.maximized) {
            this.items = [{
                xtype: 'panel',
                layout: 'border',
                bodyStyle: 'padding: 20px;',
                items: [{
                    html: Lang.wsparcie_wygaslo_powiadomienie,
                    bodyStyle: 'font-weight: bold; background-color: transparent;',
                    region: 'north'
                }, {
                    xtype: 'button',
                    text: Lang.dane_opiekuna,
                    scope: this,
                    handler: this.showWindowAbout,
                    region: 'south'
                },]
            }];
            this.buttons = [{
                text: Lang.przypomnij_za_7,
                scope: this,
                handler: this.przypomnij
            }, {
                xtype: 'tbfill'
            }, {
                text: Lang.nie_przypominaj,
                scope: this,
                handler: this.niePrzypominaj
            }];
        } else {
            this.items = [{
                xtype: 'panel',
                layout: 'fit',
                bodyStyle: 'padding: 20px;',
                items: [{
                    html: Lang.wsparcie_wygaslo_powiadomienie,
                    bodyStyle: 'font-weight: bold; background-color: transparent;',
                    region: 'north'
                }]
            }];
            this.buttons = [{
                xtype: 'tbfill'
            }, {
                xtype: 'button',
                text: Lang.dane_opiekuna,
                scope: this,
                handler: this.showWindowAbout,
                region: 'south'
            }, {
                text: Lang.przypomnij_za_7,
                scope: this,
                handler: this.przypomnij
            }, {
                text: Lang.nie_przypominaj,
                scope: this,
                handler: this.niePrzypominaj
            }];
        }
        this.callParent(arguments);
    },
    showW: function () {
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
    },
    przypomnij: function () {
        Ext.state.Manager.set('lastMaintenanceNotify', new Date());
        this.closeOp();
    },
    niePrzypominaj: function () {
        Ext.state.Manager.set('lastMaintenanceNotify', 'doNot');
        this.closeOp();
    },
    closeOp: function () {
        this.close();
    },
    showWindowAbout: function () {
        let windowAbout;
        windowAbout = Ext.create('WindowAbout');
        windowAbout.showW();
    }
});

