Ext.define('WindowCfgProgramFlashAddEdit', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.programowanie_flash.capitalise(),
    width: 600,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    initComponent: function (config) {
        this.cid = Ext.id();
        this.config = config || {};
        this.typeo = "sterownik";
        Ext.apply(this, config);
        this.items = [{
            id: this.cid + "formpanel",
            xtype: 'form',
            frame: true,
            border: false,
            autoWidth: true,
            autoHeight: true,
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0"
            },
            fileUpload: true,
            items: [{
                id: this.cid + "nazwa",
                name: "nazwa",
                xtype: 'textfieldexd',
                fieldLabel: Lang.nazwa,
                allowBlank: false,
                blankText: Lang.pole_nie_moze_byc_puste
            }, {
                xtype: 'filefieldexd',
                id: this.cid + 'file',
                name: 'file',
                fieldLabel: Lang.plik,
                allowBlank: false,
                blankText: Lang.pole_nie_moze_byc_puste,
                ensureExtensions: [".cdd"],
                ensureExtensionsValidErrorMsg: Lang.wymagane_rozszerzenie + " " + [".cdd"].toString() + "!",
                validator: Ext.ux.form.FileUploadField.prototype.defaultValidatorExtension
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid, klonowanie) {
        sendAjaxDataJsonRpcL("rest/flash/getObject", {
            id: this.obiektid
        }, this, function (data) {
            if (data.status) {
                data = data.data;
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                this.obiekt = data;
                data = data || {};
                Ext.getCmp(this.cid + "nazwa").setValue(data.nazwa);
                Ext.getCmp(this.cid + "nazwa").clearInvalid();
                if (this.obiekt != null) {
                    Ext.getCmp(this.cid + "file").setDisabled(true);
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        var fp = Ext.getCmp(this.cid + "formpanel");
        sendAjaxFormJsonRpcL("rest/flash/saveObject", {
            id: this.obiektid,
            nazwa: Ext.getCmp(this.cid + "nazwa").getValue()
        }, fp.getForm(), this, function (data) {
            if (data.status) {
                data = data.data;
                this.fireEvent("wclose");
                this.close();
            } else {
                Ext.MessageBox.alert(Lang.error, data.msg);
            }
        });
    },
    closeOp: function () {
        this.close();
    }
});
