Ext.define('WindowRozszerzeniaSlot', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.rozszerzenie.capitalise(),
    width: 650,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'tabpanel',
            autoWidth: true,
            height: 350,
            border: false,
            activeTab: 0,
            defaults: {
                scrollable: true
            },
            items: [{
                title: Lang.ogolne.capitalise(),
                id: this.cid + "form1",
                xtype: "form",
                frame: false,
                border: false,
                autoWidth: true,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0"
                },
                items: [{
                    anchor: "0",
                    layout: 'column',
                    frame: false,
                    border: false,
                    items: [{
                        columnWidth: 1,
                        layout: 'anchor',
                        frame: false,
                        border: false,
                        labelWidth: 110,
                        defaults: {
                            anchor: "-10",
                            labelWidth: 110,
                            labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                        },
                        items: [{
                            id: this.cid + "slotnumer",
                            name: "slotnumer",
                            xtype: 'numberfieldexd',
                            fieldLabel: Lang.slot,
                            allowBlank: false,
                            blankText: Lang.pole_nie_moze_byc_puste,
                            minValue: 0,
                            maxValue: 9999999,
                            disabled: isValidOperatorFirmaLimit()
                        }, {
                            id: this.cid + "nazwa",
                            name: "nazwa",
                            xtype: 'textfieldexd',
                            fieldLabel: Lang.nazwa,
                            allowBlank: false,
                            maxLength: 16,
                            blankText: Lang.pole_nie_moze_byc_puste,
                            disabled: isValidOperatorFirmaLimit()
                        }, {
                            id: this.cid + "vorder",
                            name: "czast3",
                            xtype: 'numberfieldexd',
                            fieldLabel: Lang.kolejnosc,
                            allowBlank: false,
                            blankText: Lang.pole_nie_moze_byc_puste,
                            minValue: 0,
                            maxValue: 999
                        }, {
                            id: this.cid + "idzbiornik",
                            xtype: 'comboexd',
                            fieldLabel: Lang.zbiornik,
                            valueField: 'id',
                            displayField: 'nazwa',
                            store: new Ext.data.JsonStore({
                                id: "id",
                                root: '',
                                sorters: [{
                                    property: "nazwa",
                                    direction: "ASC"
                                }],
                                fields: [{
                                    name: 'id',
                                    mapping: 'id'
                                }, {
                                    name: 'nazwa',
                                    mapping: 'nazwa'
                                }]
                            }),
                            triggerAction: 'all',
                            resizable: false,
                            editable: false,
                            mode: "local",
                            allowBlank: false,
                            blankText: Lang.pole_nie_moze_byc_puste,
                            disabled: isValidOperatorFirmaLimit()
                        }]
                    }]
                }]
            }, {
                title: Lang.parametry.capitalise(),
                id: this.cid + "form2",
                xtype: "form",
                frame: true,
                autoWidth: true,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: '0',
                    labelWidth: 180,
                    labelAlign: "top"
                },
                items: [{
                    id: this.cid + "czast1",
                    name: "czast1",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.czast1label,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 1,
                    maxValue: 9999999
                }, {
                    id: this.cid + "czast2",
                    name: "czast2",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.czast2label,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 1,
                    maxValue: 9999999
                }, {
                    id: this.cid + "czast3",
                    name: "czast3",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.czast3label,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 1,
                    maxValue: 9999999
                }, {
                    xtype: 'panel',
                    layout: {
                        type: 'hbox',
                        align: 'end'
                    },
                    bodyStyle: 'padding:0px 0px 5px 0px',
                    frame: false,
                    border: false,
                    items: [{
                        flex: 2,
                        id: this.cid + "wsplczynnikkalibracji",
                        name: "wsplczynnikkalibracji",
                        xtype: 'numberfieldexd',
                        fieldLabel: Lang.wspolczynnik_kalibracji + "[ml/imp]",
                        labelWidth: 180,
                        labelAlign: "top",
                        allowBlank: true,
                        decimalPrecision: 5,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        minValue: 0.00001,
                        maxValue: 9999999,
                        disabled: true
                    }, {
                        flex: 1,
                        xtype: "button",
                        text: Lang.zmiana,
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                        handler: function () {
                            var e = Ext.getCmp(this.cid + "wsplczynnikkalibracji");
                            e.setDisabled(false);
                        },
                        scope: this
                    }]
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.closeSaveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function () {
        sendAjaxDataJsonRpcL("rest/zbiorniki/getList", {
            idinclude: this.obiekt != null ? this.obiekt.idzbiornik : 0
        }, this, function (data) {
            if (data.status) {
                var listFnData = data.data;
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                Ext.getCmp(this.cid + "idzbiornik").store.loadData(listFnData);
                if (this.obiekt != null) {
                    Ext.getCmp(this.cid + "nazwa").setValue(this.obiekt.nazwa);
                    Ext.getCmp(this.cid + "slotnumer").setValue(this.obiekt.slotnumer);
                    Ext.getCmp(this.cid + "czast1").setValue(this.obiekt.czast1);
                    Ext.getCmp(this.cid + "czast2").setValue(this.obiekt.czast2);
                    Ext.getCmp(this.cid + "czast3").setValue(this.obiekt.czast3);
                    Ext.getCmp(this.cid + "vorder").setValue(this.obiekt.vorder);
                    Ext.getCmp(this.cid + "wsplczynnikkalibracji").setValue(this.obiekt.wsplczynnikkalibracjiv);
                    Ext.getCmp(this.cid + "idzbiornik").setValue2(this.obiekt.idzbiornik);
                } else {
                    Ext.getCmp(this.cid + "slotnumer").setValue(0);
                    Ext.getCmp(this.cid + "slotnumer").clearInvalid();
                    Ext.getCmp(this.cid + "nazwa").setValue(null);
                    Ext.getCmp(this.cid + "nazwa").clearInvalid();
                    Ext.getCmp(this.cid + "czast1").setValue(30);
                    Ext.getCmp(this.cid + "czast1").clearInvalid();
                    Ext.getCmp(this.cid + "czast2").setValue(30);
                    Ext.getCmp(this.cid + "czast2").clearInvalid();
                    Ext.getCmp(this.cid + "czast3").setValue(60);
                    Ext.getCmp(this.cid + "czast3").clearInvalid();
                    Ext.getCmp(this.cid + "vorder").setValue(0);
                    Ext.getCmp(this.cid + "vorder").clearInvalid();
                    Ext.getCmp(this.cid + "wsplczynnikkalibracji").setValue(null);
                    Ext.getCmp(this.cid + "wsplczynnikkalibracji").clearInvalid();
                    Ext.getCmp(this.cid + "idzbiornik").setValue2(null);
                    Ext.getCmp(this.cid + "idzbiornik").clearInvalid();
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    closeSaveOp: function () {
        var canWrite = false;
        var fp1 = Ext.getCmp(this.cid + "form1");
        var fp2 = Ext.getCmp(this.cid + "form2");
        if (fp1.form.isValid() && fp2.form.isValid()) {
            canWrite = true;
        }
        var sln = Ext.getCmp(this.cid + "slotnumer").getValue();
        for (var i = 0; i < this.slotnumerused.length; i++) {
            if (this.slotnumerused[i] == sln) {
                canWrite = false;
            }
        }
        if (canWrite) {
            if (this.obiekt == null)
                this.obiekt = {};
            this.obiekt.nazwa = Ext.getCmp(this.cid + "nazwa").getValue();
            this.obiekt.slotnumer = Ext.getCmp(this.cid + "slotnumer").getValue();
            this.obiekt.czast1 = Ext.getCmp(this.cid + "czast1").getValue();
            this.obiekt.czast2 = Ext.getCmp(this.cid + "czast2").getValue();
            this.obiekt.czast3 = Ext.getCmp(this.cid + "czast3").getValue();
            this.obiekt.vorder = Ext.getCmp(this.cid + "vorder").getValue();
            if (!Ext.getCmp(this.cid + "wsplczynnikkalibracji").disabled)
                this.obiekt.wsplczynnikkalibracji = Ext.getCmp(this.cid + "wsplczynnikkalibracji").getValue2();
            this.obiekt.idzbiornik = Ext.getCmp(this.cid + "idzbiornik").getValue();
            this.obiekt.zbiornik = Ext.getCmp(this.cid + "idzbiornik").getRow().get("nazwa");
            this.fireEvent("closeok", this);
            this.close();
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});

