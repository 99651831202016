window.WindowIdentKodsaccessPropertyName = "access.manage.identkods";
Ext.define('WindowIdentKods', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.import_kodow.capitalise(),
    accessPropertyName: "access.manage.identkods",
    width: 400,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    initComponent: function (config) {
        this.cid = Ext.id();
        this.config = config || {};
        this.typeo = "sterownik";
        Ext.apply(this, config);
        this.items = [{
            id: this.cid + "formpanel",
            xtype: 'form',
            bodyStyle: 'padding:10px 10px 5px 10px',
            frame: true,
            border: false,
            autoWidth: true,
            autoHeight: true,
            labelWidth: 50,
            layout: 'anchor',
            defaults: {
                anchor: "0",
                labelWidth: 50
            },
            fileUpload: true,
            items: [{
                xtype: 'filefieldexd',
                id: this.cid + 'file',
                anchor: "0",
                name: 'file',
                fieldLabel: Lang.plik,
                allowBlank: false,
                blankText: Lang.pole_nie_moze_byc_puste,
                ensureExtensions: [".cdd"],
                ensureExtensionsValidErrorMsg: Lang.wymagane_rozszerzenie + " " + [".cdd"].toString() + "!",
                validator: Ext.ux.form.FileUploadField.prototype.defaultValidatorExtension
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'getfromweb',
            text: Lang.pobierz_z_serwera,
            handler: this.getFromWebSaveOp,
            scope: this
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid, klonowanie) {
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
        Ext.getCmp(this.cid + "save").setDisabled(!validPrivilegesOperatorComplex(this.accessPropertyName));
    },
    saveOp: function () {
        var canWrite = false;
        var msgw = Lang.dane_niepoprawne;
        var fp = Ext.getCmp(this.cid + "formpanel");
        if (fp.form.isValid()) {
            canWrite = true;
        }
        if (canWrite) {
            Ext.MessageBox.show({
                msg: Lang.wykonywanie_operacji,
                progressText: '...',
                width: 200,
                wait: {
                    interval: 200
                }
            });
            sendAjaxFormJsonRpcL("rest/systemkonf/uploadIdentKods", {}, fp.getForm(), this, function (data) {
                Ext.MessageBox.hide();
                if (data.status) {
                    data = data.data;
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.msg);
                }
                if (this.callbackF)
                    this.callbackF();
            });
        } else {
            Ext.MessageBox.alert(Lang.error, msgw);
        }
    },
    getFromWebSaveOp: function () {
        var canWrite = true;
        if (canWrite) {
            Ext.MessageBox.show({
                msg: Lang.wykonywanie_operacji,
                progressText: '...',
                width: 200,
                wait: {
                    interval: 200
                }
            });
            sendAjaxDataJsonRpcL("rest/systemkonf/uploadIdentKodsWEBAuto", null, this, function (data) {
                Ext.MessageBox.hide();
                if (data.status) {
                    data = data.data;
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        } else {
            Ext.MessageBox.alert(Lang.error, msgw);
        }
    },
    closeOp: function () {
        this.close();
    }
});
