Ext.define('ViewBackups', {
    extend: 'Ext.panel.Panel',
    grid: null,
    id: "backups",
    taskContextMenu: null,
    rowContext: null,
    statics: {
        accessPropertyName: "access.manage.backup",
        accessPropertyViewName: "access.view.backup"
    },
    cat: "n",
    text: Lang.backup,
    buttonIconCls: "iconBackup",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            items: [{
                id: this.id + "bcreate",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                scope: this,
                handler: this.wykonaj_kopie_zapasowa,
                disabled: !validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/add")
            }, {
                id: this.id + "brestore",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRestore",
                text: Lang.odtworz.capitalise(),
                scope: this,
                handler: this.odtworz_kopie_zapasowa,
                disabled: !validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update")
            }, {
                id: this.id + "brestorefile",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRestoreFile",
                text: Lang.upload.capitalise(),
                scope: this,
                handler: this.odtworz_kopie_zapasowa_z_pliku,
                disabled: !validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update")
            }, {
                id: this.id + "bdelete",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconDelete",
                text: Lang.usun.capitalise(),
                scope: this,
                handler: this.usun_kopie_zapasowa,
                disabled: !validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/delete")
            }, {
                id: this.id + "bdownload",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconDownload",
                text: Lang.download.capitalise(),
                scope: this,
                handler: this.download_kopie_zapasowa,
                disabled: !validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update")
            }, {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconKonf",
                text: Lang.konfiguracja.capitalise(),
                scope: this,
                handler: this.konfiguracja_backup,
                disabled: !validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update")
            }, {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconHarmonogram",
                text: Lang.harmonogram.capitalise(),
                scope: this,
                handler: this.konfiguracja_harmonogram,
                disabled: !validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update")
            }, {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconImport",
                text: Lang.import_z_starej_linii,
                scope: this,
                handler: this.dbImport,
                disabled: !validPrivilegesOperatorComplex(WindowDBImport.accessViewProperty),
                hidden: (!Const.BASICFLAG && !Const.STANDARDFLAG && !Const.OPTIMALFLAG && !Const.DEBUG && !Const.STARTERFLAG) || AppOPERATOR.id != 1
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                scope: this,
                text: Lang.odswiez.capitalise(),
                handler: this.refreshDatastore
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
        this.task = {
            run: this.intervalF,
            scope: this,
            interval: 1000
        };
    },
    showFirst: function (eventMain) {
    },

    remove: function () {
        this.callParent(arguments);
    },

    show: function () {
        this.callParent(arguments);
        this.refreshDatastore();
    },
    onDestroy: function () {
        this.taskContextMenu.destroy();
        this.callParent(arguments);
    },
    hide: function () {
        this.callParent(arguments);
    },
    buildGrid: function () {
        var store = Ext.create('Ext.data.Store', {
            autoLoad: false,
            remoteSort: false,
            fields: [{
                name: 'czasOperation',
                mapping: 'czasOperation',
                type: 'date'
            }, {
                name: 'name',
                mapping: 'name'
            }, {
                name: 'opis',
                mapping: 'opis'
            }, {
                name: "outErr",
                mapping: "outErr"
            }, {
                name: "operationPrs",
                mapping: "operationPrs",
                convert: function (v) {
                    return v == null ? "-" : v;
                }
            }, {
                name: "whoImie",
                mapping: "whoImie"
            }, {
                name: "whoNazwisko",
                mapping: "whoNazwisko"
            }, {
                name: "typ",
                mapping: "typ"
            }],
            proxy: {
                type: "ajax",
                url: "rest/backup/getList",
                filterParams: "filters",
                extraParams: {
                    sort: 'czasOperation',
                    dir: 'DESC'
                },
                actionMethods: {
                    read: "POST"
                },
                reader: {
                    type: 'json',
                    id: 'id',
                    rootProperty: 'data'
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true
            },
            store: store,
            columns: [{
                header: Lang.czas,
                flex: 2,
                sortable: true,
                dataIndex: 'czasOperation',
                renderer: Ext.util.Format.dateRenderer(),
                filter: {
                    type: 'date'
                },
                filterable: true
            }, {
                header: Lang.nazwa,
                flex: 2,
                sortable: true,
                dataIndex: 'name',
                filter: {
                    type: 'string'
                },
                filterable: true
            }, {
                header: Lang.typ,
                flex: 2,
                sortable: true,
                dataIndex: 'typ',
                renderer: function (v) {
                    if (v == Const.BACKUP_TYPE_BAKCKUP)
                        return Lang.backup;
                    if (v == Const.BACKUP_TYPE_RESTORE)
                        return Lang.odtworzenie;
                },
                filter: {
                    type: 'list',
                    options: [[Const.BACKUP_TYPE_BAKCKUP, Lang.backup], [Const.BACKUP_TYPE_RESTORE, Lang.odtworzenie]]
                },
                filterable: true
            }, {
                header: Lang.stan,
                flex: 1,
                sortable: true,
                dataIndex: 'operationPrs',
                renderer: function (v) {
                    if (v == "backuping_database")
                        return Lang.trwa_backupowanie;
                    if (v == "restoreing_database")
                        return Lang.trwa_odtwarzanie;
                    if (v == "-") {
                        return Lang.zakonczony;
                    }
                },
                filter: {
                    type: 'list',
                    options: [["backuping_database", Lang.trwa_backupowanie], ["restoreing_database", Lang.trwa_odtwarzanie], ["-", Lang.zakonczony]]
                },
                filterable: true
            }, {
                header: Lang.komunikat,
                flex: 1,
                sortable: true,
                dataIndex: 'outErr',
                filter: {
                    type: 'string'
                },
                filterable: true
            }, {
                header: Lang.imie,
                flex: 2,
                sortable: true,
                dataIndex: 'whoImie',
                filter: {
                    type: 'string'
                },
                filterable: true
            }, {
                header: Lang.nazwisko,
                flex: 2,
                sortable: true,
                dataIndex: 'whoNazwisko',
                filter: {
                    type: 'string'
                },
                filterable: true
            }, {
                header: Lang.opis,
                flex: 2,
                sortable: true,
                dataIndex: 'opis',
                filter: {
                    type: 'string'
                },
                filterable: true
            }],
            frame: false,
            border: false,
            stateId: "grid-" + this.id,
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            }
        });
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.store.addListener("load", function () {
            updateCzasRefreshSerwer();
        });
        this.taskContextMenu = new Ext.menu.Menu();
        this.taskContextMenu.add([{
            id: this.id + "bcreate2",
            iconAlign: 'left',
            iconCls: "iconNew",
            text: Lang.nowy.capitalise(),
            scope: this,
            handler: this.wykonaj_kopie_zapasowa,
            disabled: !validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/add")
        }, {
            id: this.id + "brestore2",
            iconAlign: 'left',
            iconCls: "iconRestore",
            text: Lang.odtworz.capitalise(),
            scope: this,
            handler: this.odtworz_kopie_zapasowa,
            disabled: !validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update")
        }, {
            id: this.id + "brestorefile2",
            iconAlign: 'left',
            iconCls: "iconRestoreFile",
            text: Lang.odtworz_z_pliku.capitalise(),
            scope: this,
            handler: this.odtworz_kopie_zapasowa_z_pliku,
            disabled: !validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update")
        }, {
            id: this.id + "bdelete2",
            iconAlign: 'left',
            iconCls: "iconDelete",
            text: Lang.usun.capitalise(),
            scope: this,
            handler: this.usun_kopie_zapasowa,
            disabled: !validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/delete")
        }, {
            id: this.id + "bdownload2",
            iconAlign: 'left',
            iconCls: "iconDownload",
            text: Lang.download.capitalise(),
            scope: this,
            handler: this.download_kopie_zapasowa,
            disabled: !validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update")
        }, {
            iconAlign: 'left',
            iconCls: "iconKonf",
            text: Lang.konfiguracja.capitalise(),
            scope: this,
            handler: this.konfiguracja_backup,
            disabled: !validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update")
        }, {
            iconAlign: 'left',
            iconCls: "iconHarmonogram",
            text: Lang.harmonogram.capitalise(),
            scope: this,
            handler: this.konfiguracja_harmonogram,
            disabled: !validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update")
        }, "-", {
            iconAlign: 'left',
            iconCls: "iconRefresh",
            text: Lang.odswiez.capitalise(),
            scope: this,
            handler: this.refreshDatastore
        }]);
        this.grid.addListener("rowcontextmenu", function (grid, record, tr, rowIndex, e) {
            this.rowContext = grid.getStore().getAt(rowIndex);
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
        this.taskContextMenu.addListener("hide", function () {
            this.rowContext = null;
        }, this);
        this.grid.addListener("containercontextmenu", function (grid, e) {
            this.rowContext = null;
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
    },
    manageSelect: function (s) {
        var bdownload = Ext.getCmp(this.id + "bdownload");
        var bdelete = Ext.getCmp(this.id + "bdelete");
        var brestorefile = Ext.getCmp(this.id + "brestorefile");
        var brestore = Ext.getCmp(this.id + "brestore");
        var bcreate = Ext.getCmp(this.id + "bcreate");
        var bdownload2 = Ext.getCmp(this.id + "bdownload2");
        var bdelete2 = Ext.getCmp(this.id + "bdelete2");
        var brestorefile2 = Ext.getCmp(this.id + "brestorefile2");
        var brestore2 = Ext.getCmp(this.id + "brestore2");
        var bcreate2 = Ext.getCmp(this.id + "bcreate2");
        if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelection() == null || this.grid.getSelectionModel().getSelection().length == 0) {
            bdownload.setDisabled(true);
            bdelete.setDisabled(true);
            brestorefile.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update"));
            brestore.setDisabled(true);
            bcreate.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/add"));
        } else {
            var row = this.grid.getSelectionModel().getSelection()[0];
            var operationPrs = row.get("operationPrs");
            var typ = row.get("typ");
            var outErr = row.get("outErr");
            if (typ == Const.BACKUP_TYPE_BAKCKUP) {
                bdownload.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update"));
                bdelete.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/delete"));
                brestorefile.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update"));
                brestore.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update"));
                bcreate.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/add"));
            } else if (typ == Const.BACKUP_TYPE_RESTORE) {
                bdownload.setDisabled(true);
                bdelete.setDisabled(true);
                brestorefile.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update"));
                brestore.setDisabled(true);
                bcreate.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/add"));
            }
        }
        if (this.rowContext == null) {
            bdownload2.setDisabled(true);
            bdelete2.setDisabled(true);
            brestorefile2.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update"));
            brestore2.setDisabled(true);
            bcreate2.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/add"));
        } else {
            var row = this.rowContext;
            var operationPrs = row.get("operationPrs");
            var typ = row.get("typ");
            var outErr = row.get("outErr");
            if (typ == Const.BACKUP_TYPE_BAKCKUP) {
                bdownload2.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update"));
                bdelete2.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/delete"));
                brestorefile2.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update"));
                brestore2.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update"));
                bcreate2.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/add"));
            } else if (typ == Const.BACKUP_TYPE_RESTORE) {
                bdownload2.setDisabled(true);
                bdelete2.setDisabled(true);
                brestorefile2.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/update"));
                brestore2.setDisabled(true);
                bcreate2.setDisabled(!validPrivilegesOperatorComplex(ViewBackups.accessPropertyName + "/add"));
            }
        }
    },
    refreshDatastore: function () {
        this.grid.store.load({});
    },
    konfiguracja_backup: function () {
        var w = Ext.create('WindowBackupKonf');
        w.showW();
    },
    konfiguracja_harmonogram: function () {
        var w = Ext.create('WindowBackupHarmonogram');
        w.showW();
    },
    wykonaj_kopie_zapasowa: function () {
        var w = Ext.create('WindowBackupCreate', {
            task: this.task
        });
        w.showW();
    },
    odtworz_kopie_zapasowa: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelected() != null) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        var w = Ext.create('WindowBackupRestore', {
            objectid: id,
            task: this.task
        });
        w.showW();
    },
    odtworz_kopie_zapasowa_z_pliku: function () {
        var w = Ext.create('WindowBackupRestoreFile', {
            task: this.task
        });
        w.showW();
    },
    usun_kopie_zapasowa: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        Ext.MessageBox.show({
            title: Lang.usuwanie,
            message: Lang.czy_napewno_usunac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/backup/deleteObject", {
                        id: id
                    }, this, function (data) {
                        if (data.status) {
                            data = data.data;
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                        this.refreshDatastore();
                    });
                }
            }
        });
    },
    download_kopie_zapasowa: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection()[0] != null) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        postFormAsync("rest/backup/downloadBackupedDatabase", {
            id: id
        }, null);
    },
    dbImport: function () {
        Ext.create('WindowDBImport').showW();
    },
    intervalF: function () {
        sendAjaxDataJsonRpcL("rest/backup/getRestoreActState", null, this, function (data) {
            if (data.status) {
                data = data.data;
                if (data == null) {
                    Ext.TaskManager.stop(this.task);
                    this.refreshDatastore();
                } else {
                    this.refreshDatastore();
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    }
});
