import Login from './components/Login/Login.js';
import User from './components/User/User.js';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/:urlprefix*/login" component={Login}/>
                <Route path="/:urlprefix*/user" component={User}/>
            </Switch>
        </Router>
    );
}

export default App;
