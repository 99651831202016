Ext.define('WindowZbiornikiGraphMap', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.mapka.capitalise(),
    width: 600,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            border: false,
            frame: false,
            width: 600,
            height: 700,
            layout: 'border',
            items: [{
                region: 'north',
                id: this.id + "form1",
                xtype: "form",
                border: true,
                frame: true,
                height: 40,
                fileUpload: true,
                defaults: {
                    anchor: "0"
                },
                items: [{
                    xtype: 'panel',
                    layout: 'hbox',
                    fieldLabel: Lang.plik,
                    isFormField: false,
                    border: false,
                    frame: false,
                    items: [{
                        flex: 1,
                        margin: "0 5 0 0",
                        xtype: 'filefieldexd',
                        id: this.id + 'file',
                        name: 'file',
                        allowBlank: false,
                        isFormField: true,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        ensureExtensions: ["." + "jpg"],
                        ensureExtensionsValidErrorMsg: Lang.wymagane_rozszerzenie + " " + ["." + "jpg"].toString() + "!",
                        validator: Ext.ux.form.FileUploadField.prototype.defaultValidatorExtension
                    }, {
                        xtype: 'button',
                        width: 80,
                        id: this.id + "upload",
                        text: Lang.upload,
                        handler: this.uploadOp,
                        scope: this
                    }]
                }]
            }, {
                region: 'center',
                id: this.id + 'panelcenter',
                xtype: 'panel',
                layout: 'fit',
                border: false,
                frame: false,
                items: [{
                    xtype: 'box',
                    id: this.id + "boxImage",
                    autoEl: {
                        tag: 'div',
                        cls: "mapka-canvas"
                    }
                }]
            }, {
                region: 'east',
                id: this.id + "panelzbiornikigird",
                xtype: "panel",
                layout: 'fit',
                border: true,
                frame: true,
                width: 150,
                defaults: {
                    anchor: "0"
                },
                items: [new Ext.ux.grid.GridPanelExd({
                    store: new Ext.data.Store({
                        autoLoad: false,
                        proxy: new Ext.data.MemoryProxy({}),
                        reader: new Ext.data.JsonReader({}, [{
                            name: 'id',
                            mapping: 'id'
                        }, {
                            name: 'nazwa',
                            mapping: 'nazwa'
                        }, {
                            name: 'renderstate',
                            mapping: 'renderstate'
                        }, {
                            name: 'object',
                            mapping: "object",
                            type: "auto"
                        }])
                    }),
                    columns: [{
                        header: Lang.nazwa,
                        width: 150,
                        sortable: false,
                        dataIndex: 'nazwa',
                        renderer: this.gridRendererCol
                    }],
                    frame: false,
                    border: false,
                    id: this.id + "zbiornikigird",
                    stateId: null,
                    stateful: false,
                    stripeRows: true,
                    enableHdMenu: false,
                    selModel: {
                        selType: 'rowmodel',
                        mode: 'single'
                    },
                    listeners: {
                        scope: this,
                        rowclick: this.rowClickGrid
                    },
                    getSelectedRecord: function () {
                        if (!this.getSelectionModel().hasSelection()) {
                            return null;
                        } else {
                            if (this.getSelectionModel().getSelected() != null) {
                                return this.getSelectionModel().getSelected();
                            }
                        }
                        return null;
                    },
                    selectRecord: function (r) {
                        this.getSelectionModel().select([r]);
                    },
                    clearSelecton: function () {
                        this.getSelectionModel().clearSelections();
                    }
                })]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.closeSaveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    gridRendererCol: function (value, metaData, record, rowIndex, colIndex, store) {
        var rs = record.get("renderstate");
        if (rs == null) {
            metaData.style = metaData.style + "color:red;";
        } else if (rs == "R") {
            metaData.style = metaData.style + "color:green;";
        }
        return value;
    },
    rowClickGrid: function () {
        var selectedRow = this.cgrid.getSelection()[0];
        if (selectedRow == null) {
            if (this.currentElo != null && this.currentElo.el != null) {
                this.currentElo.el.removeCls("mapka-item-selected");
            }
            this.currentRow = null;
            this.currentObject = null;
            this.currentElo = null;
        } else {
            if (this.currentElo != null && this.currentElo.el != null) {
                this.currentElo.el.removeCls("mapka-item-selected");
            }
            this.currentRow = selectedRow;
            this.currentObject = selectedRow.get("object");
            this.currentElo = this.currentObject.elo;
            if (this.currentElo.el != null) {
                this.currentElo.el.addCls("mapka-item-selected");
            }
        }
    },
    canvasBoxelImageClick: function (e, t, o) {
        if (this.currentRow == null || Ext.get(t) != this.canvasBoxelImage)
            return;
        var px = e.getX();
        var py = e.getY();
        var imgww = this.canvasBoxelImage.getWidth();
        var imgwh = this.canvasBoxelImage.getHeight();
        var obbslf = this.canvasBoxelImage.translatePoints(px, py);
        var dx = obbslf.left;
        var dy = obbslf.top;
        if (dx > 0 && dy > 0) {
            this.currentObject.x = dx;
            this.currentObject.y = dy;
            this.currentRow.set("renderstate", "R");
            this.currentObject["renderstate"] = "R";
            if (this.currentElo.el == null)
                this.createElementVisualForObject(this.currentRow, this.currentObject, this.currentElo);
            this.currentElo.el.addCls("mapka-item-selected");
            this.moveElementVisual(this.currentElo, dx, dy);
        }
    },
    canvasBoxelImageElClick: function (e, t, o) {
        if (this.currentElo != null && this.currentElo.el != null) {
            this.currentElo.el.removeCls("mapka-item-selected");
        }
        this.currentRow = o.currentRow;
        this.currentObject = o.currentObject;
        this.currentElo = o.currentElo;
        this.cgrid.selectRecord(this.currentRow);
        if (this.currentElo.el != null) {
            this.currentElo.el.addCls("mapka-item-selected");
        }
    },
    createElementVisualForObject: function (crow, cobject, celo) {
        var mcls = "mapkav-elem-zbiornik-" + WindowZbiornikiHMap.visalElemModCls(cobject);
        var oo = {
            tag: "div",
            cls: "mapka-elem-item " + mcls,
            cn: [{
                tag: 'div',
                cls: "mapkav-elem-item-ct",
                cn: [{
                    tag: 'div',
                    cls: "mapka-elem-item-pointer"
                }, {
                    tag: 'div',
                    cls: "mapka-elem-item-node",
                    cn: [{
                        tag: "span",
                        html: cobject.nazwa
                    }]
                }]
            }]
        };
        var el = this.canvasBoxelImage.createChild(oo, null, false);
        var elc = el.child(".mapkav-elem-item-ct");
        var elp = elc.child(".mapka-elem-item-pointer");
        var eln = elc.child(".mapka-elem-item-node");
        elp.alignTo(el, "tl-tl", [4, 8]);
        eln.alignTo(elp, "l-r", [2, 0]);
        var offsetNToEl = eln.getOffsetsTo(el);
        var onx = offsetNToEl[0];
        var ony = offsetNToEl[1];
        el.setWidth(eln.getWidth() + onx + 4);
        el.setHeight(eln.getHeight() + ony + 4);
        elc.setWidth(eln.getWidth() + onx + 4);
        elc.setHeight(eln.getHeight() + ony + 4);
        celo.el = el;
        celo.elp = elp;
        celo.eln = eln;
        var opts = {
            currentRow: crow,
            currentObject: cobject,
            currentElo: celo
        };
        el.on("click", this.canvasBoxelImageElClick, this, opts);
        el.hover(function (e, t, o) {
            if (o.currentElo.el != null) {
                o.currentElo.el.addCls("mapka-item-hover");
            }
        }, function (e, t, o) {
            if (o.currentElo.el != null) {
                o.currentElo.el.removeCls("mapka-item-hover");
            }
        }, this, opts);
    },
    moveElementVisual: function (currentElo, dx, dy) {
        var el = currentElo.el;
        var elp = currentElo.elp;
        var eln = currentElo.eln;
        var offsetPToEl = elp.getOffsetsTo(el);
        var ox = offsetPToEl[0];
        var oy = offsetPToEl[1];
        var ix = elp.getWidth() / 2;
        var iy = elp.getHeight() / 2;
        var px = dx - ox - ix;
        var py = dy - oy - iy;
        el.setLeft(px);
        el.setTop(py);
    },
    loadObjectMapXY: function () {
        this.canvasBoxel = Ext.getCmp(this.id + "boxImage");
        this.canvasBoxelCanvas = this.canvasBoxel.el;
        if (this.canvasBoxelImage != null)
            this.canvasBoxelImage.remove();
        this.canvasBoxelImage = this.canvasBoxelCanvas.insertFirst({
            tag: "div",
            style: "background-image: url(rest/zbiornikigraph/getJpg);",
            cls: "mapka-canvas-image"
        });
        this.canvasBoxelImage.applyStyles({
            width: this.cwidth + "px",
            height: this.cheight + "px"
        });
        this.canvasBoxelImage.on("click", this.canvasBoxelImageClick, this);
        this.cgrid.clearSelecton();
        this.rowClickGrid();
        var str = Ext.getCmp(this.id + "zbiornikigird").store;
        for (var i = 0; i < str.getCount(); i++) {
            var r = str.getAt(i);
            var currentRow = r;
            var currentObject = r.get("object");
            var currentElo = currentObject.elo;
            var dx = currentObject.x;
            var dy = currentObject.y;
            if (dx > this.cwidth)
                currentObject.x = dx = 0;
            if (dy > this.cheight)
                currentObject.y = dy = 0;
            if (dx > 0 && dy > 0) {
                currentRow.set("renderstate", "R");
                currentObject["renderstate"] = "R";
                this.createElementVisualForObject(currentRow, currentObject, currentElo);
                this.moveElementVisual(currentElo, dx, dy);
            }
        }
    },
    showW: function () {
        sendAjaxDataJsonRpcL("rest/zbiornikigraph/getZbiornikListForGraphMap", null, this, function (data) {
            if (data.status) {
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                this.obiekt = data.data;
                this.zbiornikidatas = this.obiekt.zbiornikidatas;
                this.cwidth = this.obiekt.width;
                this.cheight = this.obiekt.height;
                for (var i = 0; i < this.zbiornikidatas.length; i++) {
                    this.zbiornikidatas[i]["object"] = this.zbiornikidatas[i];
                    this.zbiornikidatas[i]["renderstate"] = null;
                    this.zbiornikidatas[i]["elo"] = {
                        el: null
                    };
                }
                this.cgrid = Ext.getCmp(this.id + "zbiornikigird");
                this.cgrid.store.loadData(this.zbiornikidatas);
                if (this.cwidth > 0 && this.cheight > 0) {
                    this.loadObjectMapXY();
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    closeSaveOp: function () {
        var canWrite = true;
        if (canWrite) {
            var mapidx = {};
            var mapidy = {};
            for (var i = 0; i < this.zbiornikidatas.length; i++) {
                var o = this.zbiornikidatas[i];
                mapidx[o.id] = o.x;
                mapidy[o.id] = o.y;
            }
            sendAjaxDataJsonRpcL("rest/zbiornikigraph/saveMapState", {
                mapidx: mapidx,
                mapidy: mapidy
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    this.fireEvent("closeok", this);
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    uploadOp: function () {
        var canWrite = false;
        var fp1 = Ext.getCmp(this.id + "form1");
        if (fp1.getForm().isValid()) {
            canWrite = true;
        }
        if (canWrite) {
            sendAjaxFormJsonRpcL("rest/zbiornikigraph/uploadJpg", null, fp1.form, this, function (data) {
                if (data.status) {
                    data = data.data;
                    Ext.MessageBox.alert(Lang.operacja, Lang.operacja_zakonczona_poprawnie);
                    sendAjaxDataJsonRpcL("rest/zbiornikigraph/getZbiornikListForGraphMap", null, this, function (data) {
                        if (data.status) {
                            this.obiekt = data.data;
                            this.zbiornikidatas = this.obiekt.zbiornikidatas;
                            this.cwidth = this.obiekt.width;
                            this.cheight = this.obiekt.height;
                            for (var i = 0; i < this.zbiornikidatas.length; i++) {
                                this.zbiornikidatas[i]["object"] = this.zbiornikidatas[i];
                                this.zbiornikidatas[i]["renderstate"] = null;
                                this.zbiornikidatas[i]["elo"] = {
                                    el: null
                                };
                            }
                            this.cgrid = Ext.getCmp(this.id + "zbiornikigird");
                            this.cgrid.store.loadData(this.zbiornikidatas);
                            if (this.cwidth > 0 && this.cheight > 0) {
                                this.loadObjectMapXY();
                            }
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                    });
                } else {
                    Ext.MessageBox.alert(Lang.error, data.msg);
                }
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});

