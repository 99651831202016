Ext.define('PanelTestIdentyfikatora', {
    extend: 'PanelStanCzytnikaExd',
    alias: 'widget.testidentyfikatorpanel',
    onOk: function () {
        this.callParent();
    },
    onCommError: function () {
        this.callParent();
    },
    onNoService: function () {
        this.callParent();
    },
    onIncorrectVersion: function () {
        this.callParent();
    },
    onNotOpen: function () {
        this.callParent();
    },
    setCommunicationState: function (ikonIdentCls, message) {
        this.down("#pastylkaident").setUserCls(ikonIdentCls);
        if (message !== null) {
            var komunikat = this.down("#komunikatybox").setValue(message);
        }
    },
    onIdentPut: function (czytnik, karta) {
        sendAjaxDataJsonRpcL("rest/systemkonf/getObjectWithIdentOwner", {
            identyfikator: karta.ident,
            identtypeisreczna: false
        }, this, function (data) {
            if (data.status) {
                data = data.data;
                objectId = 0;
                objectt = null;
                if (data == null) {
                    data = {};
                    data.operator = null;
                    data.pojazd = null;
                    data.odbiorca = null;
                }
                data.ops = data.operator;
                data.pjs = data.pojazd;
                data.ods = data.odbiorca;
                if (Config.enablemanualassignidentyfiaktor == 1) {
                    data.identyfikator = karta.ident;
                }
                if (data.ops != null || data.ods != null || data.pjs != null) {
                    this.setCommunicationState("x-czytnik-ikonLogin", '');
                    this.fireEvent('cardrecognized', data);
                } else {
                    this.setCommunicationState("x-czytnik-iconLoginError", Lang.identyfikator_nieznany);
                    this.fireEvent('cardnotrecognized', data);
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
                this.setCommunicationState("x-czytnik-iconLoginError");
            }
        });
    },
    onIdentPull: function () {
        Ext.defer(function () {
        }, 1000);
        this.onOk();
        this.fireEvent('identpull');
    },
    init: function () {
        managerCzytnikPastylka.init();
        managerCzytnikPastylka.on({
            scope: this,
            ok: this.onOk,
            commerror: this.onCommError,
            identput: this.onIdentPut,
            identpull: this.onIdentPull,
            noservice: this.onNoService,
            incorrectversion: this.onIncorrectVersion,
            notopen: this.onNotOpen
        });
    },
    close: function () {
        managerCzytnikPastylka.close();
    }
});
