Ext.define('ViewZbiorniki', {
    extend: 'Ext.panel.Panel',
    grid: null,
    windowObject: null,
    id: "zbiorniki",
    taskContextMenu: null,
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyViewName: "access.view.zbiorniki"
    },
    accessPropertyName: "access.manage.zbiorniki",
    accessPropertyViewNameLimitOnly: "access.view.stany_limit_only",
    cat: "k",
    text: Lang.zbiorniki,
    buttonIconCls: "iconZbiornik",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                scope: this,
                handler: this.dodajObiekt,
                disabled: !validPrivilegesOperatorComplex(this.accessPropertyName + "/add")
            }, {
                id: this.id + "bedit3",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.klonowanie.capitalise(),
                hidden: Const.CLOUDFLAG,
                scope: this,
                handler: this.edytujObiekt2,
                disabled: !validPrivilegesOperatorComplex(this.accessPropertyName + "/add")
            }, {
                id: this.id + "bedit",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEdit",
                text: Lang.edytuj.capitalise(),
                scope: this,
                handler: this.edytujObiekt,
                disabled: !validPrivilegesOperatorComplex(this.accessPropertyName + "/update")
            }, {
                id: this.id + "bdelete",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconDelete",
                text: Lang.usun.capitalise(),
                scope: this,
                handler: this.usunObiekt,
                disabled: !validPrivilegesOperatorComplex(this.accessPropertyName + "/delete")
            }, {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEdit",
                text: Lang.mapka.capitalise(),
                scope: this,
                handler: this.mapkaObiekt,
                disabled: !validPrivilegesOperatorComplex(this.accessPropertyName + "/update")
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconPDF",
                text: Lang.pdf.capitalise(),
                scope: this,
                handler: this.getDownloadLsPdf
            }, {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEXCEL",
                text: Lang.excel.capitalise(),
                scope: this,
                handler: this.getDownloadLsExcel
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }, {
                xtype: "box",
                id: this.id + "kontekstfirmybox",
                cls: "kontekstfirmy-box"
            }, {
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                style: 'display:block;margin-right:5px',
                cls: "kontekstfirmy-text"
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    onDestroy: function () {
        this.taskContextMenu.destroy();
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        var kontekstfirmybox = Ext.getCmp(this.id + "kontekstfirmybox");
        if (AppFIRMACTX != null && !Const.AGGREGATEDCORE) {
            kontekstfirmybox.update("<div class='appSetColourRedSplit'></div>");
            kontekstfirmy.setText(Lang.pracujesz_w_kontekscie + AppFIRMACTX.nazwa);
        } else {
            kontekstfirmybox.update("");
            kontekstfirmy.setText("");
        }
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            var onwer = this.grid.ownerCt;
            Ext.suspendLayouts();
            onwer.remove(this.grid);
            this.grid.destroy();
            this.taskContextMenu.destroy();
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
    },
    buildGrid: function (datals) {
        var cols = [{
            header: "ID",
            width: 145,
            sortable: true,
            dataIndex: 'id',
            filter: {
                type: 'numeric'
            },
            filterable: true,
            hidden: true
        }, {
            header: Lang.nazwa,
            flex: 2,
            sortable: true,
            dataIndex: 'nazwa',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["nazwa"], true),
            filterable: true
        }, {
            header: Lang.plyn,
            flex: 2,
            sortable: true,
            dataIndex: 'plyn',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["plyn"], true),
            filterable: true
        }, {
            header: Lang.opis,
            flex: 2,
            sortable: true,
            dataIndex: 'opis',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["opis"], true),
            filterable: true
        }, {
            header: Lang.stanzbiornika,
            flex: 1,
            sortable: true,
            dataIndex: 'stanzbiornika',
            filter: {
                type: 'numeric'
            },
            filterable: true,
            renderer: function (v) {
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            }
        }, {
            header: Lang.pojemnosc,
            flex: 1,
            sortable: true,
            dataIndex: 'pojemnosc',
            filter: {
                type: 'numeric'
            },
            filterable: true,
            renderer: function (v) {
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            }
        }, {
            header: Lang.stanalarmowy,
            flex: 1,
            sortable: true,
            dataIndex: 'stanalarmowy',
            filter: {
                type: 'numeric'
            },
            filterable: true,
            renderer: function (v) {
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            }
        }, {
            header: Lang.stanblokady,
            flex: 1,
            sortable: true,
            dataIndex: 'stanblokady',
            filter: {
                type: 'numeric'
            },
            filterable: true,
            renderer: function (v) {
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            }
        }];
        if (validPrivilegesOperatorComplex(this.accessPropertyViewNameLimitOnly) && isValidOperatorFirmaLimit()) {
            removeObjectFromArrayById(cols, "stanzbiornika");
        }
        this.grid = new Ext.ux.grid.GridPanelExd({
            features: [{
                ftype: 'grouping'
            }],
            plugins: {
                gridfilters: true
            },
            store: new Ext.data.Store({
                proxy: new Ext.data.MemoryProxy(),
                autoLoad: true,
                groupField: 'plyn',
                sorters: [{
                    property: 'nazwa',
                    direction: "ASC"
                }],
                model: Ext.define(null, {
                    extend: 'Ext.data.Model',
                    idProperty: 'id',
                    fields: [{
                        name: 'id',
                        mapping: 'id'
                    }, {
                        name: 'nazwa',
                        mapping: 'nazwa'
                    }, {
                        name: 'plyn',
                        mapping: 'plyn'
                    }, {
                        name: 'stanzbiornika',
                        type: 'float',
                        mapping: 'stanzbiornika'
                    }, {
                        name: 'pojemnosc',
                        type: 'float',
                        mapping: 'pojemnosc'
                    }, {
                        name: 'stanalarmowy',
                        type: 'float',
                        mapping: 'stanalarmowy'
                    }, {
                        name: 'stanblokady',
                        type: 'float',
                        mapping: 'stanblokady'
                    }, {
                        name: 'opis',
                        mapping: 'opis'
                    }]
                }),
                reader: new Ext.data.ArrayReader()
            }),
            columns: cols,
            frame: false,
            border: false,
            stateId: "grid-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            }
        });
        this.grid.addListener("rowdblclick", function () {
            if (validPrivilegesOperatorComplex(this.accessPropertyName + "/update"))
                this.edytujObiekt();
        }, this);
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.taskContextMenu = new Ext.menu.Menu();
        this.taskContextMenu.add([{
            iconAlign: 'left',
            iconCls: "iconNew",
            text: Lang.nowy.capitalise(),
            scope: this,
            handler: this.dodajObiekt,
            disabled: !validPrivilegesOperatorComplex(this.accessPropertyName + "/add")
        }, {
            id: this.id + "bedit4",
            iconAlign: 'left',
            iconCls: "iconNew",
            text: Lang.klonowanie.capitalise(),
            hidden: Const.CLOUDFLAG,
            scope: this,
            handler: this.edytujObiekt2,
            disabled: !validPrivilegesOperatorComplex(this.accessPropertyName + "/add")
        }, {
            id: this.id + "bedit2",
            iconAlign: 'left',
            iconCls: "iconEdit",
            text: Lang.edytuj.capitalise(),
            scope: this,
            handler: this.edytujObiekt,
            disabled: !validPrivilegesOperatorComplex(this.accessPropertyName + "/update")
        }, {
            id: this.id + "bdelete2",
            iconAlign: 'left',
            iconCls: "iconDelete",
            text: Lang.usun.capitalise(),
            scope: this,
            handler: this.usunObiekt,
            disabled: !validPrivilegesOperatorComplex(this.accessPropertyName + "/delete")
        }, "-", {
            iconAlign: 'left',
            iconCls: "iconPDF",
            text: Lang.pdf.capitalise(),
            scope: this,
            handler: this.getDownloadLsPdf
        }, {
            iconAlign: 'left',
            iconCls: "iconEXCEL",
            text: Lang.excel.capitalise(),
            scope: this,
            handler: this.getDownloadLsExcel
        }, "-", {
            iconAlign: 'left',
            iconCls: "iconRefresh",
            text: Lang.odswiez.capitalise(),
            scope: this,
            handler: this.refreshDatastore
        }]);
        this.grid.addListener("rowcontextmenu", function (grid, record, tr, rowIndex, e) {
            this.rowContext = grid.getStore().getAt(rowIndex);
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
        this.taskContextMenu.addListener("hide", function () {
            this.rowContext = null;
        }, this);
        this.grid.addListener("containercontextmenu", function (grid, e) {
            this.rowContext = null;
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
    },
    manageSelect: function () {
        var bedit = Ext.getCmp(this.id + "bedit");
        var bedit3 = Ext.getCmp(this.id + "bedit3");
        var bdelete = Ext.getCmp(this.id + "bdelete");
        var bedit2 = Ext.getCmp(this.id + "bedit2");
        var bedit4 = Ext.getCmp(this.id + "bedit4");
        var bdelete2 = Ext.getCmp(this.id + "bdelete2");
        if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelection() == null || this.grid.getSelectionModel().getSelection().length == 0) {
            bdelete.setDisabled(true);
            bedit.setDisabled(true);
            bedit3.setDisabled(true);
        } else {
            var row = this.grid.getSelectionModel().getSelection()[0];
            bdelete.setDisabled(!validPrivilegesOperatorComplex(this.accessPropertyName + "/delete"));
            bedit.setDisabled(!validPrivilegesOperatorComplex(this.accessPropertyName + "/update"));
            bedit3.setDisabled(!validPrivilegesOperatorComplex(this.accessPropertyName + "/add"));
        }
        if (this.rowContext == null) {
            bdelete2.setDisabled(true);
            bedit2.setDisabled(true);
            bedit4.setDisabled(true);
        } else {
            bdelete2.setDisabled(!validPrivilegesOperatorComplex(this.accessPropertyName + "/delete"));
            bedit2.setDisabled(!validPrivilegesOperatorComplex(this.accessPropertyName + "/update"));
            bedit4.setDisabled(!validPrivilegesOperatorComplex(this.accessPropertyName + "/add"));
        }
    },
    getDownloadLsPdf: function () {
        postFormAsync("rest/pdfexcelexport/downloadExport", {
            format: "pdf",
            name: Lang.zbiorniki,
            defd: Ext.encode(this.grid.getDefGridDataCSF({
                colsEx: [],
                colsIn: [],
                useRenderer: true,
                fnd: function (v, c, metaData, record, rowIndex, colIndex, store) {
                    return v;
                }
            }))
        }, null);
    },
    getDownloadLsExcel: function () {
        postFormAsync("rest/pdfexcelexport/downloadExport", {
            format: "excel",
            name: Lang.zbiorniki,
            defd: Ext.encode(this.grid.getDefGridDataCSF({
                colsEx: [],
                colsIn: [],
                useRenderer: true,
                fnd: function (v, c, metaData, record, rowIndex, colIndex, store) {
                    return v;
                }
            }))
        }, null);
    },
    refreshDatastore: function () {
        updateCzasRefreshSerwer();
        if (this.grid && this.grid.rendered)
            this.grid.mask();
        sendAjaxDataJsonRpcL("rest/zbiorniki/getList", null, this, function (data) {
            this.grid.unmask();
            if (data.status) {
                this.grid.store.loadData(data.data);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    dodajObiekt: function () {
        this.windowObject = Ext.create('WindowZbiorniki', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        this.windowObject.showW(0);
    },
    edytujObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        this.windowObject = Ext.create('WindowZbiorniki', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        this.windowObject.showW(id);
    },
    edytujObiekt2: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        this.windowObject = Ext.create('WindowZbiorniki', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        this.windowObject.showW(id, true);
    },
    mapkaObiekt: function () {
        sendAjaxDataJsonRpcL("rest/systemkonf/readParamsLessSecure", null, this, function (data) {
            if (data.status) {
                data = data.data;
                var zbMapType = data.zb_map_type;
                if (Const.CLOUDFLAG) {
                    zbMapType = Const.MAP_TYPE_USE_HMAP;
                }
                if (zbMapType == Const.MAP_TYPE_USE_IMAGE) {
                    var www = new WindowZbiornikiGraphMap({});
                    www.showW();
                }
                if (zbMapType == Const.MAP_TYPE_USE_HMAP) {
                    var www = new WindowZbiornikiHMap({});
                    www.showW();
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    usunObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        Ext.MessageBox.show({
            title: Lang.usuwanie,
            message: Lang.czy_napewno_usunac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/zbiorniki/deleteObject", {
                        id: id
                    }, this, function (data) {
                        if (data.status) {
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                        this.refreshDatastore();
                    });
                }
            }
        });
    }
});
