Ext.define('WindowGroups', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.grupy,
    width: 400,
    closable: false,
    collapsible: false,
    modal: true,
    scrollable: true,
    resizable: false,
    statics: {
        accessPropertyName: "access.manage.grupy"
    },
    layout: 'fit',
    initComponent: function (config) {
        this.cid = Ext.id();
        this.config = config || {};
        Ext.apply(this, config);
        var cols = [{
            dataIndex: 'id',
            hidden: true
        }, {
            header: Lang.nazwa,
            flex: 2,
            sortable: true,
            dataIndex: 'nazwa'
        }];
        if (Const.ENTERPRISEFLAG && AppFIRMACTX == null)
            cols.splice(cols.length, 0, {
                header: Lang.firma,
                flex: 2,
                sortable: true,
                dataIndex: 'firma'
            });
        this.items = [{
            xtype: 'panel',
            frame: false,
            border: false,
            height: 400,
            layout: 'border',
            items: [{
                region: "north",
                xtype: 'toolbar',
                items: [{
                    xtype: 'button',
                    id: this.cid + 'bnew',
                    iconAlign: 'left',
                    iconCls: "iconNew",
                    text: Lang.dodaj.capitalise(),
                    handler: this.dodajObiekt,
                    scope: this
                }, {
                    xtype: 'button',
                    iconAlign: 'left',
                    iconCls: "iconEdit",
                    text: Lang.edytuj.capitalise(),
                    handler: this.edytujObiekt,
                    scope: this
                }, {
                    xtype: 'button',
                    iconAlign: 'left',
                    iconCls: "iconDelete",
                    text: Lang.usun.capitalise(),
                    handler: this.usunObiekt,
                    scope: this
                }]
            }, {
                xtype: "grid",
                id: this.cid + "grid",
                store: Ext.create('Ext.data.Store', {
                    fields: ['id', 'nazwa', 'firma'],
                    proxy: {
                        type: 'ajax',
                        url: 'rest/grupy/getList',
                        actionMethods: {
                            read: 'POST'
                        },
                        extraParams: {
                            type: this.type
                        },
                        listeners: {
                            scope: this,
                            exception: function (proxy, response, operation, eventOptions) {
                                var data = handlGridLoadExceptionJsonRpcL(response, operation, eventOptions);
                                if (!data.data.status) {
                                    Ext.MessageBox.alert(Lang.error, data.data.error);
                                }
                            }
                        },
                        reader: {
                            type: 'json',
                            rootProperty: 'data'
                        }
                    },
                    autoLoad: false
                }),
                columns: cols,
                region: "center",
                frame: false,
                border: false,
                stateId: null,
                stateful: false,
                stripeRows: true,
                enableHdMenu: true,
                selModel: {
                    selType: 'rowmodel',
                    mode: 'MULTI'
                },
                listeners: {
                    scope: this,
                    rowdblclick: function () {
                        this.edytujObiekt();
                    }
                }
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'close',
            text: Lang.zamknij,
            scope: this,
            handler: this.closeOp
        }];
        if (this.type == Const.GROUP_TYPE_ODBIORCA) {
            this.title = Lang.grupy + ' (' + Lang.odbiorcy.capitalise();
            if (AppFIRMACTX != null)
                this.title += ' - ' + AppFIRMACTX.nazwa;
            this.title += ')';
        } else if (this.type == Const.GROUP_TYPE_POJAZD) {
            this.title = Lang.grupy + ' (' + Lang.pojazdy.capitalise();
            if (AppFIRMACTX != null)
                this.title += ' - ' + AppFIRMACTX.nazwa;
            this.title += ')';
        }
        this.callParent(arguments);
    },
    showW: function () {
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
        if (Const.ENTERPRISEFLAG) {
            Ext.getCmp(this.cid + "bnew").setDisabled(AppFIRMACTX == null || !validPrivilegesOperatorComplex(WindowGroups.accessPropertyName + "/add"));
        } else {
            Ext.getCmp(this.cid + "bnew").setDisabled(!validPrivilegesOperatorComplex(WindowGroups.accessPropertyName + "/add"));
        }
        this.refreshDatastore();
    },
    refreshDatastore: function () {
        Ext.getCmp(this.cid + "grid").store.load();
    },
    dodajObiekt: function () {
        var windowObject = Ext.create('WindowGroupsAddEdit', {
            type: this.type
        });
        windowObject.showW(0);
        windowObject.on("wclose", this.refreshDatastore, this);
    },
    edytujObiekt: function () {
        var grid = Ext.getCmp(this.cid + "grid");
        var id = 0;
        if (!grid.getSelectionModel().hasSelection()) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (grid.getSelection() != null) {
                var test = grid.getSelection();
                id = grid.getSelection()[0].id;
            }
        }
        var windowObject = Ext.create('WindowGroupsAddEdit', {
            type: this.type
        });
        windowObject.showW(id);
        windowObject.on("wclose", this.refreshDatastore, this);
    },
    usunObiekt: function () {
        var grid = Ext.getCmp(this.cid + "grid");
        var ids = [];
        if (!grid.getSelectionModel().hasSelection()) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (grid.getSelectionModel().getSelection() != null && grid.getSelectionModel().getSelection().length > 0) {
                Ext.each(grid.getSelectionModel().getSelection(), function (v) {
                    ids.push(v.id);
                });
            }
        }
        Ext.MessageBox.show({
            title: Lang.usuwanie,
            msg: Lang.czy_napewno_usunac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/grupy/deleteObject", {
                        ids: ids
                    }, this, function (data) {
                        if (data.status) {
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                        this.refreshDatastore();
                    });
                }
            }
        });
    },
    closeOp: function () {
        this.close();
        if (this.callbackF)
            this.callbackF();
    }
});
