Ext.define('WindowBackupKonf', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.konfiguracja.capitalise(),
    width: 600,
    closable: false,
    collapsible: false,
    scrollable: true,
    modal: true,
    resizable: false,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.panelBackupUploadSettings = Ext.create('PanelBackupUploadSettings', {
            bodyStyle: 'padding:5px 5px 5px 5px',
            id: this.cid + 'backupuploadconfig'
        });
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:5px 5px 5px 5px',
                scrollable: true,
                defaults: {
                    labelWidth: 170
                },
                items: [{
                    id: this.cid + 'dirtobackup',
                    name: "dirtobackup",
                    xtype: 'textfieldexd',
                    anchor: "0",
                    fieldLabel: Lang.katalog_dla_backup
                }, this.panelBackupUploadSettings]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex("access.manage.backup")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        sendAjaxDataJsonRpcL("rest/backup/getCfgBackupDatabase", null, this, function (data) {
            if (data.status) {
                data = data.data;
                Ext.getCmp(this.cid + "dirtobackup").setValue(data.dirtobackup);
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        if (!Ext.getCmp(this.cid + "formpanel").isValid()) {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        if (!Const.CLOUDFLAG && this.panelBackupUploadSettings.validate()) {
            this.panelBackupUploadSettings.save()
                .then(result => {
                    performAjaxLPromise("rest/backup/saveCfgBackupDatabase", {
                        dirtobackup: Ext.getCmp(this.cid + "dirtobackup").getValue()
                    }).then(res => {
                        this.closeOp()
                    }).catch(e => {
                        handleCommonCallBackAjaxRPCError(e);
                    })
                })
                .catch(e => {
                    handleCommonCallBackAjaxRPCError(e);
                });
        }
    },
    closeOp: function () {
        this.close();
    }
});
