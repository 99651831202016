import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';

// import('raw-loader!./external/lib/lib.js').then(rawModule => eval.call(null, rawModule.default));

function importAll(r) {
    r.keys().forEach(r);
};

importAll(require.context('./libs/'));
importAll(require.context('./components/'));

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
