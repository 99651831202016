Ext.define('ViewZaladunekLimityMobile', {
    extend: 'Ext.panel.Panel',
    grid: null,
    gridpage: null,
    windowObject: null,
    id: "zaladunkilimity",
    taskContextMenu: null,
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyViewName: "access.view.zaladunkilimity"
    },
    accessPropertyName: "access.manage.zaladunkilimity",
    accessPropertyViewNameLimitOnly: "access.view.stany_limit_only",
    cat: "t",
    text: Lang.zaladunki_limity,
    buttonIconCls: "iconZaladunekLimity",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            itemId: 'kontekstfirmy-container',
            xtype: 'container',
            region: 'north',
            hidden: !(AppFIRMACTX != null),
            style: 'background-color: white',
            items: [{
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                cls: "kontekstfirmy-text kontekstfirmy-text-high kontekstfirmy-text-mobile"
            }]
        }, {
            region: "north",
            xtype: 'toolbar',
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                scope: this,
                handler: this.dodajObiekt,
                disabled: !validPrivilegesOperatorComplex(this.accessPropertyName + "/add"),
                cls: 'x-btn-mobile'
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
        if (this.grid && this.grid.rendered)
            this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        if (AppFIRMACTX != null) {
            kontekstfirmy.setText(AppFIRMACTX.nazwa);
        } else {
            kontekstfirmy.setText("");
        }
        this.down('#kontekstfirmy-container').setHidden(!(AppFIRMACTX != null));
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            var onwer = this.grid.ownerCt;
            Ext.suspendLayouts();
            onwer.remove(this.grid);
            this.grid.destroy();
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
            if (this.grid && this.grid.rendered)
                this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
    },
    onDestroy: function () {
        this.callParent(arguments);
    },
    buildGrid: function () {
        var store = Ext.create('Ext.data.Store', {
            autoLoad: false,
            remoteSort: true,
            remoteFilter: true,
            pageSize: 50,
            fields: [{
                name: 'czas',
                mapping: 'czas',
                type: 'date'
            }, {
                name: 'ile',
                mapping: 'ile',
                type: 'float'
            }, {
                name: 'firma',
                mapping: 'firma'
            }, {
                name: 'plyn',
                mapping: 'plyn'
            }, {
                name: "stanlimitu",
                mapping: "stanlimitu"
            }, {

                name: "przedprzydzialem",
                mapping: "przedprzydzialem"
            }, {
                name: "userimienazwisko",
                mapping: "userimienazwisko"
            }, "opis"],
            proxy: {
                type: "ajax",
                url: "rest/zaladuneklimity/getList",
                actionMethods: {
                    read: 'POST'
                },
                reader: {
                    type: 'json',
                    rootProperty: 'data',
                    totalProperty: 'total',
                    id: 'id'
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            sorters: {
                property: "czas",
                direction: "DESC"
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        var cols = [{
            header: Lang.czas,
            flex: 3,
            sortable: true,
            dataIndex: 'czas',
            renderer: Ext.util.Format.dateRenderer(),
            filter: {
                type: 'date'
            },
            filterable: true
        }, {
            header: Lang.firma,
            flex: 2,
            sortable: true,
            dataIndex: 'firma',
            filter: {
                type: 'string'
            },
            hidden: false,
            filterable: true
        }, {
            header: Lang.plyn,
            flex: 2,
            sortable: true,
            dataIndex: 'plyn',
            filter: {
                type: 'string'
            },
            hidden: false,
            filterable: true
        }, {
            header: Lang.wartosc_przydzialu.capitalise(),
            flex: 1,
            sortable: true,
            dataIndex: 'ile',
            renderer: function (v) {
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            filterable: true
        }];
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true,
                rowexpander: {
                    rowBodyTpl: new Ext.XTemplate(
                        `<div class="mobile-rowexp-wrapper">
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.czas}:
                                <span class="mobile-rowexp-value"> {czas:this.czas}</span>
                            </span>
                        </div>
                    
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.firma}:
                                <span class="mobile-rowexp-value"> {firma}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.plyn}:
                                <span class="mobile-rowexp-value"> {plyn}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.wartosc_przydzialu.capitalise()}:
                                <span class="mobile-rowexp-value"> {ile:this.ile}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.stan_po_przydziale}:
                                <span class="mobile-rowexp-value"> {stanlimitu:this.stanlimitu}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.stan_przed_przydzialem}:
                                <span class="mobile-rowexp-value"> {przedprzydzialem:this.przedprzydzialem}</span>
                            </span>
                        </div>

                        <tpl if="this.notEmptyAfterTrim(opis)">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.opis}:
                                    <span class="mobile-rowexp-value"> {opis}</span>
                                </span>
                            </div>
                        </tpl>
                        
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.operator}:
                                <span class="mobile-rowexp-value"> {userimienazwisko}</span>
                            </span>
                        </div>
                        
                    </div>`,
                        {
                            ile: function (v) {
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            stanlimitu: function (v) {
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            przedprzydzialem: function (v) {
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            czas: Ext.util.Format.dateRenderer(),
                            notEmptyAfterTrim
                        }
                    )
                }
            },
            store: store,
            columns: cols,
            frame: false,
            border: false,
            stateId: "grid-" + this.id + (AppFIRMACTX == null ? "-all-mobile" : "-mobile"),
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            forceFit: true,
            scrollable: 'y',
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            },
            bbar: {
                xtype: 'pagetoolbarown',
                displayInfo: true,
                pagingStore: store
            }
        });
        this.grid.store.addListener("load", function () {
            updateCzasRefreshSerwer();
        }, this);
    },
    refreshDatastore: function () {
        this.grid.store.load();
    },
    dodajObiekt: function () {
        var windowObject = Ext.create('WindowZaladunekLimity', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(0);
    }
});
