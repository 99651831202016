Ext.define('WindowPastylkaObraz', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.serwis_pastylki,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    width: 480,
    layout: "fit",
    statics: {
        accessViewProperty: "access.manage.pastylka_format_obraz"
    },
    initalBlockReadRq: 32,
    initComponent: function () {
        this.cid = Ext.id();
        managerCzytnikPastylka.init();
        managerCzytnikPastylka.on({
            scope: this,
            ok: this.onOk,
            COMPLETE: this.onComplete,
            commerror: this.onCommError,
            identput: this.onIdentPut,
            identpull: this.onIdentPull,
            progress: this.onProgress,
            readdata: this.onReadData,
            noservice: this.onNoService,
            incorrectversion: this.onIncorrectVersion,
            notopen: this.onNotOpen
        });
        this.items = [{
            xtype: 'container',
            layout: {
                type: "vbox",
                align: "stretch"
            },
            style: {
                background: '#f1f1f1'
            },
            padding: 10,
            items: [{
                id: this.cid + "panelprzylozonapastylka",
                xtype: "panelprzylozonapastylka"
            }, {
                id: this.cid + "panelstanczytnika",
                xtype: "panelstanczytnika"
            }, {
                id: this.cid + "progresspastylka",
                xtype: 'progressbar',
                frame: true,
                border: true
            }]
        }];
        this.buttons = [{
            id: this.cid + "formatowanie",
            text: Lang.formatowanie,
            handler: this.formatowanie,
            scope: this,
            disabled: true
        }, {
            id: this.cid + "zapis",
            text: Lang.zapisz_na_pastylke,
            handler: this.writeToPastylka,
            scope: this,
            disabled: true
        }, {
            id: this.cid + "odczyt",
            text: Lang.odczytaj_z_pastylki,
            handler: this.readFromPastylka,
            scope: this,
            disabled: true
        }, {
            text: Lang.zamknij,
            scope: this,
            handler: this.abort
        }];
        sendAjaxDataJsonRpcL("rest/pastylka/formatData", null, this, function (data) {
            if (data.status) {
                this.erasingData = data.data.erasingdata;
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
                this.close();
            }
        });
        this.callParent(arguments);
    },
    enableButons: function (v, v2, v3) {
        if (Ext.getCmp(this.cid + "formatowanie")) {
            Ext.getCmp(this.cid + "formatowanie").setDisabled(!v);
        }
        if (Ext.getCmp(this.cid + "zapis")) {
            Ext.getCmp(this.cid + "zapis").setDisabled(!v2);
        }
        if (Ext.getCmp(this.cid + "odczyt")) {
            Ext.getCmp(this.cid + "odczyt").setDisabled(!v3);
        }
    },
    sendSaveReq: function () {
        this.write = true;
        this.enableButons(false, false, false);
        var file = new App.WebSocketFileIO({
            czytnik: managerCzytnikPastylka
        });
        file.readFileContent(function (c, dataToWrite) {
            if (dataToWrite != null) {
                managerCzytnikPastylka.setBlockDataToWrite(dataToWrite, 0);
                this.setCommunicationState("x-czytnik-iconLoginWait", Lang.zapisywanie_danych_na_pastylce);
            } else {
                Ext.MessageBox.alert(Lang.pastylka, Lang.czytnik_read_write_error);
            }
        }, this);
    },
    sendFormatReq: function () {
        this.isFormatting = true;
        this.enableButons(false, false, false);
        managerCzytnikPastylka.setBlockDataToWrite(this.erasingData, 0);
        this.setCommunicationState("x-czytnik-iconLoginWait", Lang.zapisywanie_danych_na_pastylce);
    },
    writeToPastylka: function () {
        if (this.pastylkaInfo && this.pastylkaInfo.typ == "zdarzenia" && !this.pastylkaInfo.eventimported) {
            Ext.Msg.show({
                title: Lang.pastylka,
                message: Lang.nadpisac_niezapisane_w_bazie_zdarzenia,
                buttons: Ext.Msg.YESNO,
                scope: this,
                fn: function (btn) {
                    if (btn === 'yes') {
                        this.sendSaveReq();
                    }
                }
            });
        } else {
            this.sendSaveReq();
        }
    },
    readFromPastylka: function () {
        this.read = true;
        this.enableButons(false, false, false);
        managerCzytnikPastylka.setBlockDataToRead(this.rwSize, 0);
        this.setCommunicationState("x-czytnik-iconLoginWait", null);
    },
    formatowanie: function () {
        if (this.pastylkaInfo && this.pastylkaInfo.typ == "zdarzenia" && !this.pastylkaInfo.eventimported) {
            Ext.Msg.show({
                title: Lang.pastylka,
                message: Lang.nadpisac_niezapisane_w_bazie_zdarzenia,
                buttons: Ext.Msg.YESNO,
                scope: this,
                fn: function (btn) {
                    if (btn === 'yes') {
                        this.sendFormatReq();
                    }
                }
            });
        } else {
            Ext.Msg.show({
                title: Lang.pastylka,
                message: Lang.czy_napewno_formatowac_pastylke,
                buttons: Ext.Msg.YESNO,
                scope: this,
                fn: function (btn) {
                    if (btn === 'yes') {
                        this.sendFormatReq();
                    }
                }
            });
        }
    },
    abort: function () {
        managerCzytnikPastylka.close();
        this.close();
    },
    handleBrakDanych: function () {
        this.setCommunicationState("x-czytnik-ikonLogin", Lang.brak_danych_na_pastylce);
        Ext.getCmp(this.cid + "panelprzylozonapastylka").setVisible(false);
    },
    showW: function () {
        this.show();
    },
    setCommunicationState: function (ikonIdentCls, message) {
        if (Ext.getCmp(this.cid + "panelstanczytnika")) {
            Ext.getCmp(this.cid + "panelstanczytnika").setCommunicationState(ikonIdentCls, message);
        }
    },
    onOk: function () {
        this.setCommunicationState("x-czytnik-ikonCard", Lang.prosze_przylozyc_karte);
    },
    onComplete: function () {
        this.setCommunicationState("x-czytnik-ikonLogin", "");
        this.enableButons(true, true, true);
        if (this.write) {
            Ext.MessageBox.alert(Lang.pastylka, Lang.operacja_zakonczona);
            this.write = false;
            managerCzytnikPastylka.setBlockDataToRead(this.initalBlockReadRq, 0);
        }
        if (this.isFormatting) {
            Ext.MessageBox.alert(Lang.pastylka, Lang.operacja_zakonczona);
            this.isFormatting = false;
            Ext.getCmp(this.cid + "panelprzylozonapastylka").setVisible(false);
            managerCzytnikPastylka.setBlockDataToRead(this.initalBlockReadRq, 0);
        }
    },
    onCommError: function () {
        this.setCommunicationState("x-czytnik-iconCzytnikError", Lang.czytnik_error_comm);
    },
    onIdentPut: function (czytnik, pastylka) {
        if (pastylka.isrw) {
            this.setCommunicationState("x-czytnik-iconLoginWait", null);
            managerCzytnikPastylka.setBlockDataToRead(this.initalBlockReadRq, 0);
            this.rwSize = pastylka.rwsize;
        } else {
            this.setCommunicationState("x-czytnik-iconLoginError", Lang.identyfikator_tylko_do_odczytu);
        }
    },
    onIdentPull: function (czytnik) {
        this.setCommunicationState("x-czytnik-ikonCard", Lang.prosze_przylozyc_karte);
        this.enableButons(false, false, false);
        this.onProgress(czytnik, 0);
        Ext.getCmp(this.cid + "panelprzylozonapastylka").setVisible(false);
        this.write = false;
        this.read = false;
    },
    onProgress: function (czytnik, value) {
        var progressBar = Ext.getCmp(this.cid + "progresspastylka");
        progressBar.updateProgress(value / 100, value + "%", true);
    },
    onReadData: function (czytnik, readedData) {
        if (this.read) {
            this.read = false;
            var file = new App.WebSocketFileIO({
                czytnik: managerCzytnikPastylka
            });
            file.writeFileContent(readedData, function (c, success) {
                if (success)
                    Ext.MessageBox.alert(Lang.pastylka, Lang.operacja_zakonczona);
                else
                    Ext.MessageBox.alert(Lang.pastylka, Lang.czytnik_read_write_error);
                this.setCommunicationState("x-czytnik-ikonLogin", "");
            }, this);
        } else {
            sendAjaxDataJsonRpcL("rest/pastylka/checkIdentCfgBlock", {
                block: readedData
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    if (data) {
                        data = data.info;
                        this.setCommunicationState("x-czytnik-ikonLogin", "");
                        this.onProgress(czytnik, 0);
                        this.enableButons(true, true, true);
                        var panelInfo = Ext.getCmp(this.cid + "panelprzylozonapastylka");
                        panelInfo.setValues(data["typ"], data["datautworzenia"], data["operatorimie"], data["operatornazwisko"], data["eventimported"]);
                        panelInfo.setVisible(true);
                        this.pastylkaInfo = data;
                    } else {
                        this.pastylkaInfo = null;
                        this.enableButons(true, true, false);
                        this.setCommunicationState("x-czytnik-ikonLogin", Lang.brak_danych_na_pastylce);
                    }
                } else {
                    Ext.MessageBox.alert(Lang.pastylka, data.error);
                }
            });
        }
    },
    onNoService: function () {
        this.setCommunicationState("x-czytnik-iconCzytnikError", checkIfHttpsRenderCzytnikFixBtn());
    },
    onIncorrectVersion: function () {
        this.setCommunicationState("x-czytnik-iconCzytnikError", Lang.czytnik_zla_wersja_oprogramowania);
    },
    onNotOpen: function () {
        this.setCommunicationState("x-czytnik-iconCzytnikError", Lang.czytnik_open_error);
    }
});
