Ext.define('WindowCzytnikCertPanel', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.rozwiazywanie_problemow,
    closable: true,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    width: 600,
    height: 300,
    layout: "fit",
    currPage: 0,
    initComponent: function () {
        this.items = [{
            xtype: 'panel',
            layout: 'fit',
            items: [{
                itemId: 'card-0',
                xtype: 'panel',
                bodyStyle: 'padding: 20px;',
                items: [{
                    html: Lang.cert_czytnik_wykonaj_kroki
                }]
            }, {
                itemId: 'card-1',
                xtype: 'panel',
                layout: 'fit',
                items: [],
                hidden: true
            }, {
                itemId: 'card-2',
                xtype: 'panel',
                bodyStyle: 'padding: 20px;',
                items: [{
                    html: Lang.sukces
                }],
                hidde: true
            }]
        }];
        this.buttons = [{
            itemId: 'wroc-btn',
            text: Lang.wroc,
            scope: this,
            handler: this.wroc,
            hidden: true
        }, {
            itemId: 'dalej-btn',
            text: Lang.dalej,
            scope: this,
            handler: this.dalej
        }, {
            itenId: 'zamknij-btn',
            text: Lang.zamknij,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function () {
        this.show();
    },
    closeOp: function () {
        this.close();
    },
    wroc: function () {
        this.currPage -= 1;
        if (this.currPage == 1) {
            this.checkConnection();
        }
        this.down(`#card-${this.currPage + 1}`).hide();
        this.down(`#card-${this.currPage}`).show();
        if (this.currPage == 0) {
            this.down('#wroc-btn').setHidden(true);
            this.down('#dalej-btn').setHidden(false);
        }
    },
    dalej: function () {
        this.currPage += 1;
        this.down(`#card-${this.currPage - 1}`).hide();
        if (this.currPage == 1) {
            this.currPage == 1 && this.down('#wroc-btn').setHidden(false);
            this.checkConnection();
        }
        this.down(`#card-${this.currPage}`).show();
        this.currPage == 1 && this.down('#dalej-btn').setHidden(true);
    },
    checkConnection: function () {
        if (this.down('#conn-failed-box')) {
            this.down('#conn-failed-box').destroy();
        }
        this.mask(Lang.ladowanie);
        fetch('https://127.0.0.1:9875', {
            method: 'GET'
        }).then(data => {
            this.currPage = 2;
            this.down('#card-1').hide();
            this.down('#card-2').show();
            this.down('#wroc-btn').setHidden(true);
        }).catch(err => {
            console.log(err);
            this.down('#card-1').add({
                itemId: 'conn-failed-box',
                bodyStyle: 'padding: 20px;',
                html: Lang.cert_czytnik_problem_nadal_wystepuje
            });
        }).finally(() => {
            this.unmask();
        });
    }
});
