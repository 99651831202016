Ext.define('PanelWizardFilterCzas', {
    extend: 'Ext.panel.Panel',
    initComponent: function () {
        this.cid = Ext.id();
        Ext.apply(this, {
            layout: "fit",
            frame: false,
            border: false,
            autoWidth: false,
            autoHeight: false,
            items: [{
                xtype: 'form',
                frame: false,
                border: false,
                autoWidth: true,
                autoHeight: true,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0"
                },
                id: this.cid + "formpanel",
                labelWidth: 100,
                items: [{
                    anchor: "0",
                    layout: 'column',
                    frame: false,
                    border: false,
                    items: [{
                        columnWidth: 1.0,
                        layout: 'form',
                        labelWidth: 100,
                        defaults: {
                            anchor: "0"
                        },
                        frame: false,
                        border: false,
                        items: [{
                            id: this.cid + "okrestyp",
                            fieldLabel: Lang.okres,
                            hideLabel: false,
                            xtype: 'comboexd',
                            store: new Ext.data.ArrayStore({
                                fields: ["value", "text"]
                            }),
                            valueField: 'value',
                            displayField: 'text',
                            triggerAction: 'all',
                            resizable: false,
                            mode: "local",
                            editable: false
                        }, {
                            id: this.cid + "okresod",
                            fieldLabel: Lang.dod,
                            hideLabel: false,
                            xtype: 'datefieldexdmod'
                        }, {
                            id: this.cid + "okresdo",
                            fieldLabel: Lang.ddo,
                            hideLabel: false,
                            xtype: 'datefieldexdmoddo'
                        }]
                    }]
                }]
            }]
        });
        this.callParent(arguments);
    },
    callAfterShow: function () {
        var okrestyp = Ext.getCmp(this.cid + "okrestyp");
        var okresod = Ext.getCmp(this.cid + "okresod");
        var okresdo = Ext.getCmp(this.cid + "okresdo");
        if (!this.calledCallAfterShow) {
            this.calledCallAfterShow = true;
            okrestyp.store.loadData([["custom", Lang.custom], ["prev_day", Lang.prev_day], ["prev_week", Lang.prev_week], ["prev_month", Lang.prev_month], ["prev_year", Lang.prev_year], ["current_day", Lang.current_day], ["current_week", Lang.current_week], ["current_month", Lang.current_month], ["current_year", Lang.current_year]]);
            okrestyp.setValue2("custom");
            okrestyp.on("select", this.reloadSelectokrestyp, this);
        }
        okrestyp.setValue(this.datad.mainCtrl.repDef.typeCzas || "custom");
        okresod.setValue(this.datad.mainCtrl.repDef.fromDate);
        okresdo.setValue(this.datad.mainCtrl.repDef.toDate);
        this.reloadSelectokrestyp();
        this.callAfterShowCalled = true;
    },
    callAfterHide: function () {
        if (this.callAfterShowCalled) {
            var okrestyp = Ext.getCmp(this.cid + "okrestyp");
            var okresod = Ext.getCmp(this.cid + "okresod");
            var okresdo = Ext.getCmp(this.cid + "okresdo");
            this.datad.mainCtrl.repDef.typeCzas = okrestyp.getValue();
            this.datad.mainCtrl.repDef.fromDate = okresod.getValue2();
            this.datad.mainCtrl.repDef.toDate = okresdo.getValue2();
        }
        return null;
    },
    reloadSelectokrestyp: function () {
        var okrestyp = Ext.getCmp(this.cid + "okrestyp");
        var okresod = Ext.getCmp(this.cid + "okresod");
        var okresdo = Ext.getCmp(this.cid + "okresdo");
        if (okrestyp.getValue() == "custom") {
            okresod.setDisabled(false);
            okresdo.setDisabled(false);
        } else {
            okresod.setDisabled(true);
            okresdo.setDisabled(true);
        }
    },
    setData: function (datad) {
        this.datad = datad;
    }
});
