Ext.define('PanelReportListViewer', {
    extend: 'Ext.panel.Panel',
    pagesize: 100,
    initComponent: function () {
        this.initPanelContent();
        this.callParent();
    },
    startReportGen: function (reportDef, nazwa, AppFIRMACTX) {
        this.pageing.beforeLoad();
        this.pageing.setPageCount(0);
        this.pageing.onLoad(0);
        this.fireEvent('beforeload', this);
        this.reportDef = reportDef;
        this.nazwa = nazwa;
        sendAjaxDataJsonRpcL("rest/reportlistprocesor/initProces", {
            zawartosc: Ext.encode(this.reportDef),
            idfirma: AppFIRMACTX,
            nazwa: this.nazwa,
            pagesize: this.pagesize
        }, this, function (data) {
            if (data.status) {
                var data = data.data;
                this.pageing.beforeLoad();
                if (this.task) {
                    try {
                        Ext.TaskManager.stop(this.task);
                    } catch (e) {
                    }
                    this.task = null;
                }
                this.task = {
                    run: this.taskfuncInterval,
                    scope: this,
                    interval: 1000
                };
                Ext.TaskManager.start(this.task);
                this.pageing.updateProgress(0);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    newReportRPL: function () {
        this.pageing.beforeLoad();
        this.pageing.setPageCount(0);
        this.pageing.onLoad(0);
        this.fireEvent('beforeload', this);
        sendAjaxDataJsonRpcL("rest/reportlistprocesor/cancelClearGeneratedProcesStatus", null, this, function (data) {
            if (data.status) {
                var data = data.data;
                this.pageing.beforeLoad();
                if (this.task) {
                    try {
                        Ext.TaskManager.stop(this.task);
                    } catch (e) {
                    }
                    this.task = null;
                }
                this.task = {
                    run: this.taskfuncInterval,
                    scope: this,
                    interval: 1000
                };
                Ext.TaskManager.start(this.task);
                if (data.canceled) {
                    var mainbody = Ext.getCmp(this.id + "mainbody");
                    mainbody.body.update("");
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    startRunningShow: function () {
        this.fireEvent('beforeload', this);
        this.pageing.beforeLoad();
        if (this.task) {
            try {
                Ext.TaskManager.stop(this.task);
            } catch (e) {
            }
            this.task = null;
        }
        this.task = {
            run: this.taskfuncInterval,
            scope: this,
            interval: 1000
        };
        Ext.TaskManager.start(this.task);
    },
    stopRunningShow: function () {
        if (this.task) {
            try {
                Ext.TaskManager.stop(this.task);
            } catch (e) {
            }
            this.task = null;
        }
    },
    taskfuncInterval: function () {
        sendAjaxDataJsonRpcL("rest/reportlistprocesor/clearStatusGeneratedProcesStatus", null, this, function (data) {
            if (data.status) {
                var data = data.data;
                if (data.state == "dead") {
                    this.fireEvent('loadederror', this);
                    if (this.task) {
                        Ext.TaskManager.stop(this.task);
                        this.task = null;
                    }
                    this.pageing.updateProgress(1);
                    this.pageing.onLoadError();
                } else if (data.state == "running") {
                    this.pageing.updateProgress(data.percent / 100);
                } else if (data.state == "endok") {
                    this.fireEvent('loaded', this);
                    if (this.task) {
                        Ext.TaskManager.stop(this.task);
                        this.task = null;
                    }
                    this.pageing.updateProgress(1);
                    this.loadPagesInfoData();
                } else if (data.state == "pending") {
                    this.pageing.updateProgress(0);
                } else if (data.state == "enderror") {
                    this.fireEvent('loadederror', this);
                    if (this.task) {
                        Ext.TaskManager.stop(this.task);
                        this.task = null;
                    }
                    this.pageing.updateProgress(1);
                    this.pageing.onLoadError();
                    Ext.MessageBox.alert(Lang.error, data.msg);
                }
            } else {
                this.fireEvent('loadederror', this);
                if (this.task) {
                    Ext.TaskManager.stop(this.task);
                    this.task = null;
                }
                this.pageing.updateProgress(1);
                this.pageing.onLoadError();
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    loadPageViewTBL: function (pageIndex) {
        this.pageing.beforeLoad();
        this.loadPageData(pageIndex);
    },
    loadPageData: function (pageIndex) {
        if (this.pagecount && this.pagecount > pageIndex) {
            sendAjaxDataJsonRpcL("rest/reportlistprocesor/returnPageGeneratedProces", {
                pageindex: pageIndex
            }, this, function (data) {
                if (data.status) {
                    var data = data.data;
                    var page = data.page;
                    var mainbody = Ext.getCmp(this.id + "mainbody");
                    mainbody.body.update(page);
                    this.pageing.onLoad(pageIndex);
                } else {
                    this.pageing.onLoadError();
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        } else {
            this.pageing.onLoadError();
        }
    },
    loadPagesInfoData: function () {
        sendAjaxDataJsonRpcL("rest/reportlistprocesor/returnPagePropGeneratedProces", null, this, function (data) {
            if (data.status) {
                var data = data.data;
                this.pagesize = data.pagesize;
                this.pagecount = data.pagecount;
                this.pageing.setPageCount(this.pagecount);
                this.loadPageData(0);
            } else {
                this.pageing.onLoadError();
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    initReportGetPdf: function () {
        postFormAsync("rest/reportlistprocesor/downloadExportPdfGeneratedProces", null, null);
    },
    initReportGetXls: function () {
        postFormAsync("rest/reportlistprocesor/downloadExcelCsvGeneratedProces", null, null);
    },
    initPanelContent: function () {
        this.pageing = Ext.create('PagingToolbarRPL', {
            viewCtrl: this
        });
        Ext.apply(this, {
            layout: "fit",
            cls: 'x-reportpanel-body',
            frame: false,
            border: false,
            autoWidth: false,
            autoHeight: false,
            items: [{
                xtype: 'panel',
                id: this.id + "mainbody",
                bodyCls: 'x-reportpanel-body',
                frame: false,
                border: false,
                layout: 'fit',
                scrollable: true,
                items: []
            }],
            bbar: this.pageing
        });
    }
});
