ObservableCanalMap = Ext.extend(Ext.util.Observable, {
    constructor: function (config) {
        this.listeners = config.listeners;
        ObservableCanalMap.superclass.constructor.call(this, config);
    }
});
Ext.define('ViewStanyMobile', {
    extend: 'Ext.panel.Panel',
    grid: null,
    id: "stany",
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    showBaterie: false,
    scrollable: false,
    statics: {
        accessPropertyViewName: "access.view.stany",
        accessPropertyViewNameLimitOnly: "access.view.stany_limit_only",
        accessPropertyViewNameSKPsPomiary: "access.view.skpspomiary",
        getOpisCKPFlags: function (record) {
            var laststateflagssckpstate = record.staterealflagsckpstate;
            var laststateflagststate = record.staterealflagststate;
            var laststateflagsskpstate = record.staterealflagsskpstate;
            var sopis = [];
            if (laststateflagssckpstate != null) {
                /*
                 * 0x00 - brak przecieku
                 * 0x01 - błąd pomiaru
                 * 0x02 - przeciek
                 * 0x03 - brak lub uszkodzone CKP
                 * 0x04 - brak CKP w konfiguracji
                 */
                if (laststateflagssckpstate == "01") {
                    sopis.push(Lang.blad_pomiaru_ckp);
                }
                if (laststateflagssckpstate == "02") {
                    sopis.push(Lang.przeciek);
                }
                if (laststateflagssckpstate == "03") {
                    sopis.push(Lang.brak_ckp);
                }
                if (laststateflagssckpstate == "04") {
                    sopis.push("-");
                }
            }
            if (laststateflagststate != null) {
                /*
                 * 0x00 - pomiar prawidłowy
                 * 0x01 - pomiar nieprawidłowy
                 * 0x02 - pomiar nieobsługiwany
                 * 0x03 - brak lub błąd przetwornika pomiarowego
                 */
                if (laststateflagststate == "01") {
                    sopis.push(Lang.pomiar_temperatury_nieprawidlowy);
                }
                if (laststateflagststate == "03") {
                    sopis.push(Lang.pomiar_temperatury_brak_blad_pomiaru);
                }
            }
            if (laststateflagsskpstate != null) {
                /*
                 * 0x00 - pomiar poprawny
                 * 0x01 - SKP100 w trakcie inicjalizacji
                 * 0x02 - przekroczenie górnego progu pomiarowego
                 * 0x03 - przekroczenie dolnego progu pomiarowego
                 * 0x04 - brak lub błąd przetwornika pomiarowego
                 * 0x05 - brak lub błędna kalibracja
                 * 0x06 - w trakcie kalibracji lub wgrywania konfiguracji
                 */
                if (laststateflagsskpstate == "01") {
                    sopis.push(Lang.skp_w_trakcie_inicjalizacji);
                }
                if (laststateflagsskpstate == "02") {
                    sopis.push(Lang.skp_przekroczenie_gornego_progu_pomiarowego);
                }
                if (laststateflagsskpstate == "03") {
                    sopis.push(Lang.skp_przekroczenie_dolnego_progu_pomiarowego);
                }
                if (laststateflagsskpstate == "04") {
                    sopis.push(Lang.skp_brak_blad_pomiaru);
                }
                if (laststateflagsskpstate == "05") {
                    sopis.push(Lang.skp_brak_blad_kalibracji);
                }
                if (laststateflagsskpstate == "06") {
                    sopis.push(Lang.skp_during_kalibracji);
                }
            }
            if (sopis.length == 0) {
                sopis.push(Lang.ok);
            }
            return sopis.join(",<br>");
        },
        rendererCKPFlags: function (value, meta, record, rowIndex, colIndex, store) {
            var staterealflagsckpstate = record.get("staterealflagsckpstate");
            var staterealflagststate = record.get("staterealflagststate");
            var staterealflagsskpstate = record.get("staterealflagsskpstate");
            var sstyle = "";
            if (staterealflagsckpstate != null) {
                if (staterealflagsckpstate == "01") {
                    sstyle = "color:red";
                }
                if (staterealflagsckpstate == "02") {
                    sstyle = "color:red";
                }
                if (staterealflagsckpstate == "03") {
                    sstyle = "color:red";
                }
                if (staterealflagsckpstate == "04") {
                    sstyle = "";
                }
            }
            if (staterealflagststate != null) {
                if (staterealflagststate == "01") {
                    sstyle = "color:red";
                }
                if (staterealflagststate == "03") {
                    sstyle = "color:red";
                }
            }
            if (staterealflagsskpstate != null) {
                if (staterealflagsskpstate == "01") {
                    sstyle = "color:red";
                }
                if (staterealflagsskpstate == "02") {
                    sstyle = "color:red";
                }
                if (staterealflagsskpstate == "03") {
                    sstyle = "color:red";
                }
                if (staterealflagsskpstate == "04") {
                    sstyle = "color:red";
                }
                if (staterealflagsskpstate == "05") {
                    sstyle = "color:red";
                }
                if (staterealflagsskpstate == "06") {
                    sstyle = "color:red";
                }
            }
            //renderWrapQtip
            value = "<span style='" + sstyle + "'>" + value + "</span>";
            return '<div ' + 'data-qtip="' + value + '"' + ' class="x-gridexd-col-wrap">' + value + '</div>';
        }
    },
    cat: "p",
    text: Lang.stan,
    buttonIconCls: "iconStany",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            itemId: 'kontekstfirmy-container',
            xtype: 'container',
            region: 'north',
            hidden: !(AppFIRMACTX != null),
            style: 'background-color: white',
            items: [{
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                cls: "kontekstfirmy-text kontekstfirmy-text-high kontekstfirmy-text-mobile"
            }]
        }, {
            region: "north",
            xtype: 'toolbar',
            scrollable: true,
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }, {
                xtype: "checkbox",
                id: this.id + "autorefresh",
                boxLabel: Lang.autorefresh_short,
                listeners: {
                    scope: this,
                    change: this.onautoRefreshDataStoreC
                }
            }, {
                xtype: "checkbox",
                id: this.id + "showbaterie",
                boxLabel: Lang.pokazuj_jako_baterie,
                stateId: "showbaterie-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
                stateful: true,
                stateEvents: ["change"],
                hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.OPTIMALFLAG || Const.STARTERFLAG || Const.AGGREGATEDCORE,
                getState: function () {
                    return {
                        value: this.getValue()
                    };
                },
                applyState: function (state) {
                    if (state) {
                        this.setValue(state.value);
                    }
                },
                listeners: {
                    scope: this,
                    change: this.onShowBaterieChange
                }
            }]
        }, {
            id: this.id + "splitcttrmapka",
            region: "center",
            xtype: 'panel',
            layout: 'border',
            frame: false,
            border: false,
            stateId: "split-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            items: [{
                id: this.id + "panelgridmgr",
                region: "center",
                collapsible: false,
                collapsed: false,
                frame: false,
                border: false,
                layout: {
                    type: 'vbox',
                    align: 'stretch'
                },
                items: [{
                    flex: 2,
                    xtype: 'panel',
                    frame: false,
                    border: false,
                    layout: 'fit',
                    hidden: validPrivilegesOperatorComplex(ViewStany.accessPropertyViewNameLimitOnly) && isValidOperatorFirmaLimit(),
                    items: [this.grid]
                }]
            }]
        }];
        this.callParent(arguments);
        this.task = new Ext.util.DelayedTask(function () {
            if (this.showState) {
                var autorefresh = Ext.getCmp(this.id + "autorefresh").getValue();
                if (autorefresh) {
                    this.task.delay(30000);
                    this.refreshDatastore();
                }
            }
        }, this);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
        if (!Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG) {
            sendAjaxDataJsonRpcL("rest/skp/getList", {}, this, function (data) {
                if (data.status) {
                    if (data.data.length > 0) {
                        var zapelnienierealCol = Ext.Array.findBy(this.grid.getColumns(), function (item, index) {
                            return item.initialConfig.header == Lang.zapelnienie_rzecz;
                        });
                        if (zapelnienierealCol)
                            zapelnienierealCol.setHidden(false);
                        var stanzbiornikarealCol = Ext.Array.findBy(this.grid.getColumns(), function (item, index) {
                            return item.initialConfig.dataIndex == 'stanzbiornikareal';
                        });
                        if (stanzbiornikarealCol)
                            stanzbiornikarealCol.setHidden(false);
                    }
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        }
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        var autorefresh = Ext.state.Manager.get(this.id + "-checkbox-autorefresh");
        if (autorefresh != null) {
            if (Ext.getCmp(this.id + "autorefresh"))
                Ext.getCmp(this.id + "autorefresh").setValue(autorefresh.checked);
        }
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        if (AppFIRMACTX != null && !Const.AGGREGATEDCORE) {
            kontekstfirmy.setText(AppFIRMACTX.nazwa);
        } else {
            kontekstfirmy.setText("");
        }
        this.down('#kontekstfirmy-container').setHidden(!(AppFIRMACTX != null));
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            var onwer = this.grid.ownerCt;
            Ext.suspendLayouts();
            onwer.remove(this.grid);
            this.grid.destroy();
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
    },
    onautoRefreshDataStoreC: function () {
        this.autoRefreshDataStore();
        Ext.state.Manager.set(this.id + "-checkbox-autorefresh", {
            checked: Ext.getCmp(this.id + "autorefresh").getValue()
        });
    },
    autoRefreshDataStore: function () {
        if (this.showState) {
            var autorefresh = Ext.getCmp(this.id + "autorefresh").getValue();
            if (autorefresh) {
                this.task.delay(30000);
            }
        }
    },
    buildGrid: function () {
        var store = Ext.create('Ext.data.ArrayStore', {
            sorters: [{
                property: 'nazwa',
                direction: 'ASC'
            }],
            fields: [{
                name: 'id',
                mapping: 'id'
            }, {
                name: 'nazwa',
                mapping: 'nazwa'
            }, {
                name: 'active',
                mapping: 'active'
            }, {
                name: 'stanzbiornika',
                type: 'float',
                mapping: 'stanzbiornika'
            }, {
                name: 'stanzbiornikareal',
                type: 'float',
                allowNull: true,
                mapping: 'stanzbiornikareal'
            }, {
                name: 'stanzbiornikareal2',
                type: 'float',
                allowNull: true,
                calculate: function (data) {
                    return data.stanzbiornikareal;
                }
            }, {
                name: 'zapelnieniezbiornikareal',
                type: 'float',
                calculate: function (data) {
                    return data.stanzbiornikareal / data.pojemnosc;
                }
            }, {
                name: 'stanzbiornikareal3',
                type: 'float',
                allowNull: true,
                calculate: function (data) {
                    return data.stanzbiornikareal;
                }
            }, {
                name: 'stanzbiornikarealtms',
                mapping: 'stanzbiornikarealtms',
                type: 'date'
            }, {
                name: 'stanzbiornika2',
                type: 'float',
                calculate: function (data) {
                    return data.stanzbiornika;
                }
            }, {
                name: 'zapelnieniezbiornika',
                type: 'float',
                calculate: function (data) {
                    return data.stanzbiornika / data.pojemnosc;
                }
            }, {
                name: 'pojemnosc',
                type: 'float',
                mapping: 'pojemnosc'
            }, {
                name: 'stanalarmowy',
                type: 'float',
                mapping: 'stanalarmowy'
            }, {
                name: 'stanblokady',
                type: 'float',
                mapping: 'stanblokady'
            }, {
                name: 'plyn',
                mapping: 'plyn'
            }, {
                name: 'staterealflags',
                mapping: 'staterealflags'
            }, {
                name: 'staterealflagsckpstate',
                mapping: 'staterealflagsckpstate'
            }, {
                name: 'staterealflagststate',
                mapping: 'staterealflagststate'
            }, {
                name: 'staterealflagsskpstate',
                mapping: 'staterealflagsskpstate'
            }, {
                name: "zbiornikstatecontroltype",
                mapping: "zbiornikstatecontroltype"
            }, {
                name: 'opis',
                mapping: 'opis'
            }, {
                name: 'firmasAsString',
                mapping: 'firmasAsString'
            }, {
                name: 'stanzbiornikarealmeasuretimestate',
                calculate: function (record) {
                    let value = record.stanzbiornikareal;
                    let sstyleopis = ViewStanyMobile.getOpisCKPFlags(record);
                    if (value == null && sstyleopis == "OK")
                        return null;
                    let stanzbiornikarealtms = "";
                    if (record.stanzbiornikarealtms != null)
                        stanzbiornikarealtms = Ext.Date.format(record.stanzbiornikarealtms, Date.patterns.ISO8601Long);
                    if (value != null)
                        value = Ext.Number.roundToPrecision(value, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR) + " ( " + stanzbiornikarealtms + ", " + sstyleopis + " )";
                    else {
                        value = sstyleopis + ", " + stanzbiornikarealtms;
                    }
                    return value
                }
            }],
            groupField: 'plyn',
            proxy: {
                type: 'memory',
                reader: {
                    type: 'json',
                    rootProperty: ""
                }
            }
        });
        var cols = [{
            header: Lang.nazwa,
            flex: 1,
            cellWrap: true,
            sortable: true,
            dataIndex: 'nazwa',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["nazwa"], true),
            filterable: true,
            renderer: function (value, meta, record, rowIndex, colIndex, store) {
                return ViewStany.rendererCKPFlags(value, meta, record, rowIndex, colIndex, store);
            }
        }, {
            header: Lang.plyn,
            flex: 1,
            sortable: true,
            dataIndex: 'plyn',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["plyn"], true),
            filterable: true,
            hidden: true
        }, {
            header: Lang.firma,
            flex: 2,
            sortable: true,
            dataIndex: 'firmasAsString',
            filter: {
                type: 'string'
            },
            hidden: !Const.AGGREGATEDCORE
        }, {
            groupable: false,
            filterable: false,
            header: Lang.zapelnienie,
            dataIndex: 'zapelnieniezbiornika',
            flex: 1,
            align: 'center',
            xtype: 'widgetcolumn',
            widget: {
                xtype: 'progressexdstany'
            }
        }, {
            groupable: false,
            filterable: false,
            header: Lang.zapelnienie_rzecz,
            dataIndex: 'zapelnieniezbiornikareal',
            flex: 1,
            align: 'center',
            xtype: 'widgetcolumn',
            widget: {
                xtype: 'progressexdstany'
            }
        }, {
            header: Lang.stanzbiornika,
            flex: 1,
            sortable: true,
            dataIndex: 'stanzbiornika',
            renderer: function (value, meta, record, rowIndex, colIndex, store) {
                value = Ext.Number.roundToPrecision(value, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                return ViewStany.rendererCKPFlags(value, meta, record, rowIndex, colIndex, store);
            },
            filter: 'numeric',
            filterable: true,
            hidden: true
        }, {
            header: Lang.stanzbiornikareal,
            flex: 1,
            sortable: true,
            dataIndex: 'stanzbiornikareal',
            filter: 'numeric',
            filterable: true,
            resizable: true,
            hidden: true
        }];
        if (Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG ||
            !validPrivilegesOperatorComplex(ViewStany.accessPropertyViewNameSKPsPomiary)) {
            removeObjectFromArrayById(cols, 'zapelnieniezbiornikareal');
            removeObjectFromArrayById(cols, 'stanzbiornikareal');
        }
        if (!Const.AGGREGATEDCORE) {
            removeObjectFromArrayById(cols, "firmasAsString");
        }
        this.grid = new Ext.ux.grid.GridPanelExd({
            forceFit: true,
            store: store,
            features: [{
                ftype: 'grouping'
            }],
            plugins: {
                gridfilters: true,
                rowexpander: {
                    scrollIntoViewOnExpand: false,
                    rowBodyTpl: new Ext.XTemplate(
                        `<div class="mobile-rowexp-wrapper">
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.plyn}:
                                <span class="mobile-rowexp-value"> {plyn}</span>
                            </span>
                        </div>

                        <tpl if="this.notEmptyAfterTrim(opis)">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.opis}:
                                    <span class="mobile-rowexp-value"> {opis}</span>
                                </span>
                            </div>
                        </tpl>
                        
                        <tpl if="stanzbiornika != null">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.stanzbiornika}:
                                    <span class="mobile-rowexp-value"> {stanzbiornika}</span>
                                </span>
                            </div>
                        </tpl>

                        ${(Const.PROFLAG || Const.ENTERPRISEFLAG || Const.OPTIMALFLAG) &&
                        validPrivilegesOperatorComplex(ViewStany.accessPropertyViewNameSKPsPomiary) ?
                            `<tpl if="stanzbiornikarealmeasuretimestate != null">
                                <div class="mobile-rowexp-row">
                                    <span class="mobile-rowexp-title">${Lang.stanzbiornikareal}:
                                        <span class="mobile-rowexp-value"> {stanzbiornikarealmeasuretimestate}</span>
                                    </span>
                                </div>
                            </tpl>`
                            :
                            ''
                        }
                    
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.pojemnosc}:
                                <span class="mobile-rowexp-value"> {pojemnosc}</span>
                            </span>
                        </div>
                    
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.stanalarmowy}:
                                <span class="mobile-rowexp-value"> {stanalarmowy}</span>
                            </span>
                        </div>
                    
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.stanblokady}:
                                <span class="mobile-rowexp-value"> {stanblokady}</span>
                            </span>
                        </div>
                    </div>`,
                        {
                            notEmptyAfterTrim
                        }
                    )
                }
            },
            columns: cols,
            scrollable: 'y',
            frame: false,
            border: false,
            stateId: "grid-mobile" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            disableSelection: true
        });
    },
    onShowBaterieChange: function () {
        var showBaterieCheckbox = Ext.getCmp(this.id + "showbaterie");
        this.showBaterie = showBaterieCheckbox.getValue();
        this.refreshDatastore();
    },
    refreshDatastore: function () {
        updateCzasRefreshSerwer();
        if (this.grid && this.grid.rendered)
            this.grid.mask();
        sendAjaxDataJsonRpcL("rest/zbiorniki/getListForStany", {
            showbaterie: this.showBaterie
        }, this, function (data) {
            if (this.grid && this.grid.rendered)
                this.grid.unmask();
            if (data.status) {
                this.grid.store.loadData(data.data);
                this.refreshPanelFirmaInfo();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    refreshPanelFirmaInfo: function () {
        if (validPrivilegesOperatorComplex(ViewStany.accessPropertyViewNameLimitOnly) && isValidOperatorFirmaLimit()) {
            var plcc = Ext.getCmp(this.id + "panelgridmgr");
            var limitPanelStanyCp = Ext.getCmp(this.id + "panelfirmastatus2");
            if (limitPanelStanyCp == null) {
                plcc.insert(0, {
                    height: '100%',
                    xtype: 'panel',
                    border: false,
                    frame: false,
                    layout: {
                        type: 'vbox',
                        align: 'stretch'
                    },
                    items: [Ext.create('LimitPanelStanyCp', {
                        height: '100%',
                        id: this.id + "panelfirmastatus2",
                        title: Lang.podsumowanie_limitow_firmy,
                        frame: false,
                        border: false
                    })]
                });
                limitPanelStanyCp = Ext.getCmp(this.id + "panelfirmastatus2");
            }
            limitPanelStanyCp.updateData();
        }
    }
});
