window.WindowSysKonfaccessPropertyName = "access.manage.syskonf";
window.WindowSysKonfaccessPropertyViewName = "access.view.syskonf";
window.WindowSysKonffirmaaccessPropertyName = "access.manage.syskonffirma";
window.WindowSysKonffirmaaccessPropertyViewName = "access.view.syskonffirma";
Ext.define('WindowCfgAutoImportUserTank', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.konfiguracja_importu_tankowan_uzytkownika.capitalise(),
    width: 700,
    closable: false,
    collapsible: false,
    modal: true,
    scrollable: true,
    resizable: false,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            scrollable: WindowExdMobileDEVICEV,
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                scrollable: true,
                defaults: {
                    anchor: '0',
                    labelWidth: 250,
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    id: this.cid + 'typimportu',
                    xtype: 'comboexd',
                    store: new Ext.data.ArrayStore({
                        data: [[Const.USER_TANK_IMPORT_FROM_FILE_TYP_IMPORTU_CUSTOM, Lang.custom]],
                        fields: ["value", "text"]
                    }),
                    displayField: "text",
                    valueField: "value",
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    fieldLabel: Lang.typ_importu,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    xtype: 'comboexd',
                    id: this.cid + "serchpojby",
                    name: "serchpojby",
                    fieldLabel: Lang.szukaj_pojazdu_po,
                    store: new Ext.data.ArrayStore({
                        data: [[Const.USER_TANK_IMPORT_FROM_FILE_SERCH_POJAZD_BY_NAZWA, Lang.nazwa], [Const.USER_TANK_IMPORT_FROM_FILE_SERCH_POJAZD_BY_EIID, Lang.eiid], [Const.USER_TANK_IMPORT_FROM_FILE_SERCH_POJAZD_BY_IDENT_FLOTA, Lang.ident_flota]],
                        fields: ["value", "text"]
                    }),
                    displayField: "text",
                    valueField: "value",
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    xtype: 'comboexd',
                    id: this.cid + "ignoreerrors",
                    name: "ignoreerrors",
                    fieldLabel: Lang.zachowanie_gdy_blad,
                    store: new Ext.data.ArrayStore({
                        data: [[true, Lang.ignoruj_bledne], [false, Lang.wszstkie_albo_nic]],
                        fields: ["value", "text"]
                    }),
                    displayField: "text",
                    valueField: "value",
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "colplyn",
                    name: "colplyn",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.kolumna_plyn,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "colilosc",
                    name: "colilosc",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.kolumna_ilosc,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "coldate",
                    name: "coldate",
                    xtype: 'textfield',
                    fieldLabel: Lang.kolumna_data,
                    allowBlank: false,
                    maxLength: 16,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "colselectorpoj",
                    name: "colselectorpoj",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.kolumna_selektor_pojazdu,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "colodbiorca",
                    name: "colodbiorca",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.kolumna_odbiorca,
                    allowBlank: true,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "collicznikstate",
                    name: "collicznikstate",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.kolumna_licznik_state,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "addpojazdifnone",
                    name: "addpojazdifnone",
                    fieldLabel: Lang.automatyczne_tworzenie_pojazdu,
                    columns: 2,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.tak,
                        inputValue: true
                    }, {
                        boxLabel: Lang.nie,
                        inputValue: false,
                        value: true
                    }],
                    listeners: {
                        change: function (radiogroup, newValue) {
                            var colpojname = Ext.getCmp(this.cid + "colpojname");
                            colpojname.setHidden(!newValue);
                            colpojname.allowBlank = !newValue;
                            var colidentflotowy = Ext.getCmp(this.cid + "colidentflotowy")
                            colidentflotowy.setHidden(!newValue)
                            colidentflotowy.allowBlank = !newValue
                        },
                        scope: this
                    }
                }, {
                    id: this.cid + "colpojname",
                    name: "colpojname",
                    xtype: 'textfield',
                    fieldLabel: Lang.kolumna_nazwa_nowego_pojazdu,
                    allowBlank: false,
                    maxLength: 16,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    hidden: true
                }, {
                    id: this.cid + "colidentflotowy",
                    name: "colidentflotowy",
                    xtype: 'textfield',
                    fieldLabel: Lang.kolumna_ident_flota,
                    allowBlank: false,
                    maxLength: 16,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    hidden: true
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            disabled: !validPrivilegesOperatorComplex(Const.CLOUDFLAG ? WindowSysKonffirmaaccessPropertyName : WindowSysKonfaccessPropertyName),
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function () {
        if (!validPrivilegesOperatorComplex(Const.CLOUDFLAG ? WindowSysKonffirmaaccessPropertyViewName : WindowSysKonfaccessPropertyViewName)) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.obiekt = null;
        sendAjaxDataJsonRpcL("rest/importtankfromfile/readAutoImportUserTankowanParams", {}, this, function (data) {
            if (data.status) {
                data = data.data;
                this.obiekt = data;
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                Ext.getCmp(this.cid + "typimportu").setValue2(Const.USER_TANK_IMPORT_FROM_FILE_TYP_IMPORTU_CUSTOM);
                Ext.getCmp(this.cid + "serchpojby").setValue2(Const.USER_TANK_IMPORT_FROM_FILE_SERCH_POJAZD_BY_NAZWA);
                Ext.getCmp(this.cid + "ignoreerrors").setValue2(false);
                Ext.getCmp(this.cid + "colpojname").clearInvalid();
                Ext.getCmp(this.cid + "colpojname").setValue(null);
                Ext.getCmp(this.cid + "colilosc").clearInvalid();
                Ext.getCmp(this.cid + "colilosc").setValue(null);
                Ext.getCmp(this.cid + "coldate").clearInvalid();
                Ext.getCmp(this.cid + "coldate").setValue(null);
                Ext.getCmp(this.cid + "colodbiorca").clearInvalid();
                Ext.getCmp(this.cid + "colodbiorca").setValue(null);
                Ext.getCmp(this.cid + "colselectorpoj").clearInvalid();
                Ext.getCmp(this.cid + "colselectorpoj").setValue(null);
                Ext.getCmp(this.cid + "colidentflotowy").clearInvalid();
                Ext.getCmp(this.cid + "colidentflotowy").setValue(null);
                Ext.getCmp(this.cid + "collicznikstate").clearInvalid();
                Ext.getCmp(this.cid + "collicznikstate").setValue(null);
                Ext.getCmp(this.cid + "addpojazdifnone").setValue2(false);
                Ext.getCmp(this.cid + "colplyn").clearInvalid();
                Ext.getCmp(this.cid + "colplyn").setValue(null);
                if (this.obiekt != null) {
                    Ext.getCmp(this.cid + "typimportu").setValue2(this.obiekt.typimportu);
                    Ext.getCmp(this.cid + "serchpojby").setValue2(this.obiekt.serchpojby);
                    Ext.getCmp(this.cid + "ignoreerrors").setValue2(this.obiekt.ignoreerrors);
                    Ext.getCmp(this.cid + "colpojname").setValue(this.obiekt.colPojName);
                    Ext.getCmp(this.cid + "colilosc").setValue(this.obiekt.colIlosc);
                    Ext.getCmp(this.cid + "coldate").setValue(this.obiekt.colDate);
                    Ext.getCmp(this.cid + "colodbiorca").setValue(this.obiekt.colOdbiorca);
                    Ext.getCmp(this.cid + "colselectorpoj").setValue(this.obiekt.colSelectorPoj);
                    Ext.getCmp(this.cid + "colidentflotowy").setValue(this.obiekt.colIdentFlotowy);
                    Ext.getCmp(this.cid + "collicznikstate").setValue(this.obiekt.colLicznikState);
                    Ext.getCmp(this.cid + "addpojazdifnone").setValue2(this.obiekt.addPojazdIfNone);
                    Ext.getCmp(this.cid + "colplyn").setValue(this.obiekt.colPlyn);
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        if (!validPrivilegesOperatorComplex(Const.CLOUDFLAG ? WindowSysKonffirmaaccessPropertyName : WindowSysKonfaccessPropertyName)) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        var canWrite = false;
        if (Ext.getCmp(this.cid + 'formpanel').isValid()) {
            canWrite = true;
        }
        if (canWrite) {
            var addPojIfNone = Ext.getCmp(this.cid + "addpojazdifnone").getValue();
            sendAjaxDataJsonRpcL("rest/importtankfromfile/saveAutoImportUserTankowanParams", {
                typimportu: Ext.getCmp(this.cid + "typimportu").getValue2(),
                serchpojby: Ext.getCmp(this.cid + "serchpojby").getValue2(),
                ignoreerrors: Ext.getCmp(this.cid + "ignoreerrors").getValue2(),
                colPojName: addPojIfNone ? Ext.getCmp(this.cid + "colpojname").getValue() : null,
                colIlosc: Ext.getCmp(this.cid + "colilosc").getValue(),
                colDate: Ext.getCmp(this.cid + "coldate").getValue(),
                colOdbiorca: Ext.getCmp(this.cid + "colodbiorca").getValue(),
                colSelectorPoj: Ext.getCmp(this.cid + "colselectorpoj").getValue(),
                colIdentFlotowy: addPojIfNone ? Ext.getCmp(this.cid + "colidentflotowy").getValue() : null,
                colLicznikState: Ext.getCmp(this.cid + "collicznikstate").getValue(),
                addPojazdIfNone: addPojIfNone,
                colPlyn: Ext.getCmp(this.cid + "colplyn").getValue()
            }, this, function (data) {
                if (data.status) {
                    this.hide();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                    return;
                }
                if (this.callbackF) {
                    this.callbackF();
                }
            }, 0);
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});
