Ext.define('PanelWizardViewNameChoose', {
    extend: 'Ext.panel.Panel',
    initComponent: function () {
        this.cid = Ext.id();
        Ext.apply(this, {
            layout: "fit",
            frame: false,
            height: 457,
            title: Lang.rplrplprop,
            border: false,
            autoWidth: false,
            autoHeight: false,
            items: [{
                xtype: 'form',
                frame: false,
                border: false,
                autoWidth: true,
                autoHeight: true,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0"
                },
                id: this.cid + "formpanel",
                labelWidth: 100,
                items: [{
                    anchor: "0",
                    layout: 'column',
                    frame: false,
                    border: false,
                    items: [{
                        columnWidth: 1.0,
                        layout: 'form',
                        labelWidth: 100,
                        defaults: {
                            anchor: "0"
                        },
                        frame: false,
                        border: false,
                        items: [{
                            id: this.cid + "nazwa",
                            name: "nazwa",
                            xtype: 'textfield',
                            fieldLabel: Lang.nazwa,
                            allowBlank: false,
                            maxLength: 16,
                            autoCreate: {
                                tag: 'input',
                                type: 'text',
                                autocomplete: 'off',
                                maxlength: '16'
                            },
                            blankText: Lang.pole_nie_moze_byc_puste
                        }, {
                            xtype: "checkboxexd",
                            fieldLabel: Lang.klonowanie,
                            id: this.cid + "klonowanie"
                        }]
                    }]
                }]
            }]
        });
        this.callParent(arguments);
    },
    callAfterShow: function () {
        Ext.getCmp(this.cid + "nazwa").setValue(this.datad.mainCtrl.repNazwa || "default");
        if (!this.datad.mainCtrl.repId) {
            Ext.getCmp(this.cid + 'klonowanie').setHidden(true);
        }
    },
    reParseReportValidate: function () {
        this.datad.repNazwa = this.datad.repNazwa || "default";
        if (Ext.getCmp(this.cid + "klonowanie").getValue()) {
            this.datad.mainCtrl.repId = null;
        }
    },
    validateShowLess: function () {
        return this.validateShow();
    },
    validateShow: function () {
        var formp = Ext.getCmp(this.cid + "formpanel");
        var validd = formp.getForm().isValid() ? null : Lang.pole_nie_moze_byc_puste;
        if (validd == null)
            this.datad.mainCtrl.repNazwa = Ext.getCmp(this.cid + "nazwa").getValue();
        if (Ext.getCmp(this.cid + "klonowanie").getValue()) {
            this.datad.mainCtrl.repId = null;
        }
        return validd;
    },
    setData: function (datad) {
        this.datad = datad;
    }
});
