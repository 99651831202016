Ext.define('WindowPastylkaZarzadzanie', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.komunikacja_reczna,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    width: 650,
    layout: "fit",
    statics: {
        accessViewProperty: "access.manage.pastylka_zarzadanie"
    },
    initalBlockReadRq: 32,
    minProgress: 0,
    generalProgress: 0,
    initComponent: function () {
        this.cid = Ext.id();
        managerCzytnikPastylka.init();
        managerCzytnikPastylka.on({
            scope: this,
            ok: this.onOk,
            COMPLETE: this.onComplete,
            WRITE_BLOCK_ERROR: this.onWriteBlockError,
            commerror: this.onCommError,
            identput: this.onIdentPut,
            identpull: this.onIdentPull,
            progress: this.onProgress,
            readdata: this.onReadData,
            noservice: this.onNoService,
            incorrectversion: this.onIncorrectVersion,
            notopen: this.onNotOpen
        });
        this.items = [{
            xtype: 'container',
            layout: {
                type: "vbox",
                align: "stretch"
            },
            style: {
                background: '#f1f1f1'
            },
            padding: 10,
            items: [{
                xtype: 'container',
                layout: {
                    type: "hbox",
                    align: 'stretch'
                },
                items: [{
                    id: this.cid + "panelpastylkakonfinfo",
                    xtype: "panelpastylkakonfinfo",
                    margin: "0 10 0 0"
                }, {
                    id: this.cid + "panelprzylozonapastylka",
                    xtype: "panelprzylozonapastylka"
                }]
            }, {
                id: this.cid + "panelstanczytnika",
                xtype: "panelstanczytnika"
            }, {
                id: this.cid + "progresspastylka",
                xtype: 'progressbar',
                frame: true,
                border: true
            }, {
                id: this.cid + "progressgeneral",
                style: 'margin-top: 5px',
                xtype: 'progressbar',
                frame: true,
                border: true,
                hidden: true
            }]
        }];
        this.buttons = [{
            id: this.cid + "exportcfg",
            text: Lang.export_konfiguracji,
            handler: this.exportKonfiguracji,
            scope: this,
            disabled: true
        }, {
            id: this.cid + "importzd",
            text: Lang.import_zdarzen,
            handler: this.importZdarzen,
            scope: this,
            disabled: true
        }, {
            text: Lang.zamknij,
            scope: this,
            handler: this.abort
        }];
        this.loadCfgGen();
        this.callParent(arguments);
    },
    loadCfgGen: function () {
        sendAjaxDataJsonRpcL("rest/pastylka/generateCfg", null, this, function (data) {
            if (data.status) {
                data = data.data;
                var konfInfoPanel = Ext.getCmp(this.cid + "panelpastylkakonfinfo");
                if (konfInfoPanel != null) {
                    konfInfoPanel.setValues(data.operatorimie, data.operatornazwisko, data.datautworzenia, data.configs.length);
                }
                this.configs = data.configs;
                var generalProgressBar = Ext.getCmp(this.cid + 'progressgeneral');
                if (generalProgressBar != null) {
                    if (this.configs.length > 1) {
                        generalProgressBar.setHidden(false);
                    } else {
                        generalProgressBar.setHidden(true);
                    }
                }
                this.currentConfig = 0;
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
                this.close();
            }
        });
    },
    enableButons: function (v, v2) {
        if (Ext.getCmp(this.cid + "exportcfg")) {
            Ext.getCmp(this.cid + "exportcfg").setDisabled(!validPrivilegesOperatorComplex("access.manage.pastylka_eksport") || !v);
        }
        if (Ext.getCmp(this.cid + "importzd")) {
            Ext.getCmp(this.cid + "importzd").setDisabled(!v2);
        }
    },
    sendSaveReq: function () {
        this.exportKonf = true;
        this.enableButons(false, false);
        managerCzytnikPastylka.setBlockDataToWrite(this.configs[this.currentConfig], 0);
        this.currentConfig++;
        this.generalProgress = this.currentConfig / this.configs.length;
        this.minProgress = 0;
        if (this.currentConfig >= this.configs.length) {
            this.continueExport = false;
        }
        if (this.currentConfig > 1) {
            this.minProgress = ((this.currentConfig - 1) / this.configs.length) * 100;
            this.generalProgress = (this.currentConfig - 1) / this.configs.length;
        }
        this.setCommunicationState("x-czytnik-iconLoginWait", Lang.zapisywanie_danych_na_pastylce + " " + this.currentConfig + " / " + this.configs.length);
    },
    exportKonfiguracji: function () {
        if (this.pastylkaInfo && this.pastylkaInfo.typ == "zdarzenia" && !this.pastylkaInfo.eventimported) {
            return;
        }
        this.sendSaveReq();
        if ((Const.BASICFLAG || Const.STANDARDFLAG || Const.OPTIMALFLAG || Const.STARTERFLAG) && this.currentConfig == 1) {
            sendAjaxDataJsonRpcL("rest/systemreq/setUpdateRequired", {
                updaterequired: false
            }, this, function (data) {
                if (data.status) {
                    checkUpdateRequired();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        }
    },
    importZdarzen: function () {
        this.importZdarzenRead = true;
        this.enableButons(false, false);
        managerCzytnikPastylka.setBlockDataToRead(this.pastylkaInfo.length, 0);
        this.setCommunicationState("x-czytnik-iconLoginWait", null);
    },
    abort: function () {
        managerCzytnikPastylka.close();
        this.close();
    },
    handleBrakDanych: function () {
        this.setCommunicationState("x-czytnik-ikonLogin", Lang.brak_danych_na_pastylce);
        Ext.getCmp(this.cid + "panelprzylozonapastylka").setVisible(false);
    },
    showW: function () {
        this.show();
        this.center();
    },
    setCommunicationState: function (ikonIdentCls, message) {
        if (Ext.getCmp(this.cid + "panelstanczytnika")) {
            Ext.getCmp(this.cid + "panelstanczytnika").setCommunicationState(ikonIdentCls, message);
        }
    },
    onOk: function () {
        this.setCommunicationState("x-czytnik-ikonCard", Lang.prosze_przylozyc_karte);
    },
    onComplete: function () {
        this.setCommunicationState("x-czytnik-ikonLogin", "");
        if (this.importZdarzenWrite) {
            Ext.MessageBox.alert(Lang.pastylka, Lang.operacja_zakonczona);
            this.importZdarzenWrite = false;
            managerCzytnikPastylka.setBlockDataToRead(this.initalBlockReadRq, 0);
        }
        if (this.exportKonf) {
            if (this.currentConfig < this.configs.length) {
                managerCzytnikPastylka.suspendEvents(false);
                this.exportKonf = false;
                Ext.MessageBox.alert(Lang.pastylka, Lang.prosze_przylozyc_nastepna_pastylke, function (btn) {
                    if (btn == "ok") {
                        managerCzytnikPastylka.resumeEvents();
                        this.continueExport = true;
                        managerCzytnikPastylka.setBlockDataToRead(this.initalBlockReadRq, 0);
                        Ext.defer(function () {
                            if (this.continueExport) {
                                if (!Ext.getCmp(this.cid + "exportcfg").isDisabled()) {
                                    this.exportKonfiguracji();
                                } else {
                                    Ext.MessageBox.alert(Lang.error, Lang.czytnik_read_write_error, function () {
                                        this.currentConfig = 0;
                                        this.minProgress = 0;
                                        this.generalProgress = 0;
                                        this.exportKonf = false;
                                        this.onIdentPull();
                                    }, this);
                                }
                            }
                        }, 500, this);
                    }
                }, this);
            } else if (!this.continueExport) {
                Ext.MessageBox.alert(Lang.pastylka, Lang.operacja_zakonczona);
                this.currentConfig = 0;
                this.minProgress = 0;
                this.generalProgress = 0;
                this.exportKonf = false;
                managerCzytnikPastylka.setBlockDataToRead(this.initalBlockReadRq, 0);
            }
        }
    },
    onCommError: function () {
        this.setCommunicationState("x-czytnik-iconCzytnikError", Lang.czytnik_error_comm);
    },
    onWriteBlockError: function () {
        this.setCommunicationState("x-czytnik-iconLoginError", Lang.czytnik_read_write_error);
        managerCzytnikPastylka.suspendEvents(true);
        Ext.defer(function () {
            this.currentConfig = 0;
            this.minProgress = 0;
            this.generalProgress = 0;
            this.exportKonf = false;
            managerCzytnikPastylka.resumeEvents();
        }, 1000, this);
    },
    onIdentPut: function (czytnik, pastylka) {
        if (pastylka.isrw) {
            this.setCommunicationState("x-czytnik-iconLoginWait", null);
            managerCzytnikPastylka.setBlockDataToRead(this.initalBlockReadRq, 0);
        } else {
            this.setCommunicationState("x-czytnik-iconLoginError", Lang.identyfikator_tylko_do_odczytu);
        }
    },
    onIdentPull: function (czytnik) {
        if (this.currentConfig > 0 && this.currentConfig < this.configs.length) {
            this.setCommunicationState("x-czytnik-ikonCard", Lang.prosze_przylozyc_pastylke + " " + (this.currentConfig + 1) + " / " + this.configs.length);
        } else {
            this.setCommunicationState("x-czytnik-ikonCard", Lang.prosze_przylozyc_karte);
        }
        this.enableButons(false, false);
        this.onProgress(czytnik, 0);
        Ext.getCmp(this.cid + "panelprzylozonapastylka").setVisible(false);
        this.importZdarzenRead = false;
        this.importZdarzenWrite = false;
        this.exportKonf = false;
    },
    onProgress: function (czytnik, value) {
        if ((this.importZdarzenRead && value > 90) || (this.importZdarzenWrite && value < 100)) {
            return;
        }
        var progressBar = Ext.getCmp(this.cid + "progresspastylka");
        progressBar.updateProgress(value / 100, value + "%", true);
        if (this.configs && this.configs.length > 1) {
            var currentProgress = this.minProgress + this.generalProgress * value;
            if (currentProgress > 100)
                currentProgress = 100;
            Ext.getCmp(this.cid + 'progressgeneral').updateProgress(currentProgress / 100, currentProgress + "%", true);
        }
    },
    onReadData: function (czytnik, readedData) {
        if (this.importZdarzenRead) {
            this.importZdarzenRead = false;
            sendAjaxDataJsonRpcL("rest/pastylka/importDane", {
                block: readedData
            }, this, function (data) {
                if (data.status) {
                    this.importZdarzenWrite = true;
                    var newheader = data.data.newheader;
                    managerCzytnikPastylka.setBlockDataToWrite(newheader, 0);
                    this.loadCfgGen();
                } else {
                    Ext.MessageBox.alert(Lang.pastylka, data.error);
                    this.setCommunicationState("x-czytnik-ikonLogin", "");
                    this.loadCfgGen();
                }
            });
        } else {
            sendAjaxDataJsonRpcL("rest/pastylka/checkIdentCfgBlock", {
                block: readedData
            }, this, function (data) {
                if (data.status) {
                    if (data.data) {
                        data = data.data.info;
                        this.setCommunicationState("x-czytnik-ikonLogin", "");
                        this.onProgress(czytnik, 0);
                        if (data.typ == "konfiguracja") {
                            this.enableButons(true, false);
                        }
                        if (data.typ == "zdarzenia") {
                            if (data.eventimported) {
                                this.enableButons(true, true);
                            } else {
                                this.enableButons(false, true);
                            }
                        }
                        var panelInfo = Ext.getCmp(this.cid + "panelprzylozonapastylka");
                        panelInfo.setValues(data["typ"], data["datautworzenia"], data["operatorimie"], data["operatornazwisko"], data["eventimported"]);
                        panelInfo.setVisible(true);
                        this.pastylkaInfo = data;
                    } else {
                        this.pastylkaInfo = null;
                        this.enableButons(true, false);
                        this.setCommunicationState("x-czytnik-ikonLogin", Lang.brak_danych_na_pastylce);
                    }
                } else {
                    Ext.MessageBox.alert(Lang.pastylka, data.error);
                }
            });
        }
    },
    onNoService: function () {
        this.setCommunicationState("x-czytnik-iconCzytnikError", checkIfHttpsRenderCzytnikFixBtn());
    },
    onIncorrectVersion: function () {
        this.setCommunicationState("x-czytnik-iconCzytnikError", Lang.czytnik_zla_wersja_oprogramowania);
    },
    onNotOpen: function () {
        this.setCommunicationState("x-czytnik-iconCzytnikError", Lang.czytnik_open_error);
    }
});

Ext.define('PanelKonfiguracjaInfo', {
    extend: 'Ext.panel.Panel',
    alias: "widget.panelpastylkakonfinfo",
    layout: "fit",
    border: false,
    bodyStyle: {
        background: '#f1f1f1'
    },
    items: [{
        xtype: 'fieldset',
        title: Lang.aktualna_konfiguracja,
        defaults: {
            anchor: '100%',
            xtype: 'textfield',
            readOnly: true
        },
        items: [{
            itemId: "operatorimie",
            fieldLabel: Lang.imie
        }, {
            itemId: "operatornazwisko",
            fieldLabel: Lang.nazwisko
        }, {
            itemId: "datautworzenia",
            fieldLabel: Lang.data_utworzenia
        }, {
            itemId: "liczbapastylek",
            fieldLabel: Lang.liczba_pastylek
        }]
    }],
    setValues: function (imie, nazwisko, data_utworzenia, liczba_pastylek) {
        this.down("#operatorimie").setValue(imie);
        this.down("#operatornazwisko").setValue(nazwisko);
        this.down("#datautworzenia").setValue(data_utworzenia);
        this.down("#liczbapastylek").setValue(liczba_pastylek);
    }
});

Ext.define('PanelPrzylozonaPastylka', {
    extend: 'Ext.panel.Panel',
    alias: "widget.panelprzylozonapastylka",
    layout: "fit",
    hidden: true,
    border: false,
    bodyStyle: {
        background: '#f1f1f1'
    },
    items: [{
        xtype: 'fieldset',
        title: Lang.informacje_o_przylozonej_pastylce,
        defaults: {
            anchor: '100%',
            xtype: 'textfield',
            readOnly: true
        },
        items: [{
            itemId: "typ",
            fieldLabel: Lang.typ_pastylki
        }, {
            xtype: 'checkbox',
            itemId: "eventimported",
            fieldLabel: Lang.zdarzenia_zaimportowane
        }, {
            itemId: "datautworzenia",
            fieldLabel: Lang.data_utworzenia
        }, {
            itemId: "operatorimie",
            fieldLabel: Lang.imie
        }, {
            itemId: "operatornazwisko",
            fieldLabel: Lang.nazwisko
        }]
    }],
    setValues: function (typ, datautworzenia, imie, nazwisko, eventimported) {
        this.down("#datautworzenia").setValue(datautworzenia);
        this.down("#operatorimie").setValue(imie);
        this.down("#operatornazwisko").setValue(nazwisko);
        var eventImported = this.down("#eventimported");
        if (typ == "konfiguracja") {
            this.down("#typ").setValue(Lang.konfiguracja);
            eventImported.setHidden(true);
        } else {
            this.down("#typ").setValue(Lang.zdarzenia);
            eventImported.setHidden(false);
            eventImported.setValue(eventimported);
        }
    }
});

Ext.define('PanelStanCzytnika', {
    extend: 'Ext.panel.Panel',
    alias: "widget.panelstanczytnika",
    layout: {
        type: 'hbox',
        align: 'center'
    },
    bodyStyle: {
        background: '#f1f1f1',
        padding: '5px'
    },
    border: true,
    items: [{
        xtype: 'displayfield',
        itemId: "komunikatybox",
        value: "",
        flex: 1,
        fieldStyle: "text-align:center"
    }, {
        xtype: "box",
        itemId: "pastylkaident",
        cls: "x-czytnik-card-panel-card",
        userCls: 'x-czytnik-iconCzytnikError'
    }],
    setCommunicationState: function (ikonIdentCls, message) {
        this.down("#pastylkaident").setUserCls(ikonIdentCls);
        if (message !== null) {
            var komunikat = this.down("#komunikatybox").setValue(message);
        }
    }
});

