Ext.define('OperatorZbiornikiUprawnieniaDefCmp', {
    extend: 'Ext.panel.Panel',
    frame: false,
    border: false,
    autoHeight: false,
    autoWidth: true,
    height: 600,
    gridHeight: 450,
    layout: {
        type: 'vbox',
        align: 'stretch'
    },
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            frame: false,
            border: false,
            autoWidth: true,
            layout: {
                type: 'hbox',
                align: 'stretch'
            },
            bodyStyle: 'padding-left: 10px; padding-bottom: 10px;',
            items: [{
                xtype: 'checkboxexd',
                boxLabel: Lang.wszystkie,
                id: this.cid + 'wszystkie',
                listeners: {
                    scope: this,
                    change: function (component, newValue) {
                        var grid = Ext.getCmp(this.cid + "object_from");
                        var grid2 = Ext.getCmp(this.cid + "object_to");
                        if (newValue) {
                            grid.store.add(grid2.store.getRange());
                            grid2.store.removeAll();
                        }
                        grid.setDisabled(newValue);
                        grid2.setDisabled(newValue);
                        Ext.getCmp(this.cid + 'bselect').setDisabled(newValue);
                        Ext.getCmp(this.cid + 'bselectall').setDisabled(newValue);
                        Ext.getCmp(this.cid + 'bremove').setDisabled(newValue);
                        Ext.getCmp(this.cid + 'bremoveall').setDisabled(newValue);
                    }
                }
            }]
        }, {
            xtype: 'panel',
            frame: false,
            border: false,
            autoWidth: true,
            layout: 'hbox',
            layoutConfig: {
                align: "stretch"
            },
            items: [new Ext.ux.grid.GridPanelExd({
                store: new Ext.data.Store({
                    autoLoad: false,
                    proxy: new Ext.data.MemoryProxy({}),
                    reader: new Ext.data.JsonReader({
                        id: "id",
                        rootProperty: ''
                    }, [{
                        name: 'nazwa',
                        mapping: 'nazwa'
                    }])
                }),
                columns: [{
                    header: Lang.nazwa,
                    flex: 1,
                    width: 145,
                    sortable: true,
                    dataIndex: 'nazwa'
                }],
                title: Lang.dostepne.capitalise(),
                height: this.gridHeight,
                flex: 1,
                frame: true,
                border: false,
                id: this.cid + "object_from",
                stateId: null,
                stateful: false,
                stripeRows: true,
                enableHdMenu: true,
                selModel: {
                    selType: 'rowmodel',
                    mode: 'SINGLE'
                },
                listeners: {
                    scope: this,
                    rowdblclick: function () {
                        var grid = Ext.getCmp(this.cid + "object_from");
                        var grid2 = Ext.getCmp(this.cid + "object_to");
                        var r = grid.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid.store.remove(selectedItem);
                        grid2.store.add(selectedItem);
                    }
                },
                getSelR: function () {
                    var gridd = this;
                    if (!gridd.getSelectionModel().hasSelection()) {
                        return null;
                    } else {
                        if (gridd.getSelectionModel().getSelected() != null) {
                            return gridd.getSelectionModel().getSelected();
                        }
                    }
                    return null;
                }
            }), {
                width: 55,
                xtype: 'panel',
                frame: false,
                border: false,
                bodyStyle: 'padding:5px 5px 5px 5px',
                height: this.gridHeight,
                layout: {
                    type: 'vbox',
                    pack: "center",
                    align: "stretch"
                },
                items: [{
                    xtype: 'button',
                    text: ">",
                    id: this.cid + 'bselect',
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = Ext.getCmp(this.cid + "object_from");
                        var grid2 = Ext.getCmp(this.cid + "object_to");
                        var r = grid.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid.store.remove(selectedItem);
                        grid2.store.add(selectedItem);
                    }
                }, {
                    xtype: 'button',
                    text: ">>",
                    id: this.cid + 'bselectall',
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = Ext.getCmp(this.cid + "object_from");
                        var grid2 = Ext.getCmp(this.cid + "object_to");
                        grid2.store.add(grid.store.getRange());
                        grid.store.removeAll();
                    }
                }, {
                    xtype: 'button',
                    text: "<",
                    id: this.cid + 'bremove',
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = Ext.getCmp(this.cid + "object_from");
                        var grid2 = Ext.getCmp(this.cid + "object_to");
                        var r = grid2.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid2.store.remove(selectedItem);
                        grid.store.add(selectedItem);
                    }
                }, {
                    xtype: 'button',
                    text: "<<",
                    id: this.cid + 'bremoveall',
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = Ext.getCmp(this.cid + "object_from");
                        var grid2 = Ext.getCmp(this.cid + "object_to");
                        grid.store.add(grid2.store.getRange());
                        grid2.store.removeAll();
                    }
                }]
            }, new Ext.ux.grid.GridPanelExd({
                store: new Ext.data.Store({
                    autoLoad: false,
                    proxy: new Ext.data.MemoryProxy({}),
                    reader: new Ext.data.JsonReader({
                        id: "id",
                        rootProperty: ''
                    }, [{
                        name: 'nazwa',
                        mapping: 'nazwa'
                    }])
                }),
                columns: [{
                    header: Lang.nazwa,
                    flex: 1,
                    width: 145,
                    sortable: true,
                    dataIndex: 'nazwa'
                }],
                title: Lang.wybrane.capitalise(),
                height: this.gridHeight,
                flex: 1,
                frame: true,
                border: false,
                id: this.cid + "object_to",
                stateId: null,
                stateful: false,
                stripeRows: true,
                enableHdMenu: true,
                selModel: {
                    selType: 'rowmodel',
                    mode: 'SINGLE'
                },
                listeners: {
                    scope: this,
                    rowdblclick: function () {
                        var grid = Ext.getCmp(this.cid + "object_from");
                        var grid2 = Ext.getCmp(this.cid + "object_to");
                        var r = grid2.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid2.store.remove(selectedItem);
                        grid.store.add(selectedItem);
                    }
                },
                getSelR: function () {
                    var gridd = this;
                    if (!gridd.getSelectionModel().hasSelection()) {
                        return null;
                    } else {
                        if (gridd.getSelectionModel().getSelected() != null) {
                            return gridd.getSelectionModel().getSelected();
                        }
                    }
                    return null;
                }
            })]
        }];
        this.callParent(arguments);
    },
    loadData: function (zbiornikData, selectedZbiorniks, block) {
        var grid = Ext.getCmp(this.cid + "object_from");
        var grid2 = Ext.getCmp(this.cid + "object_to");
        var obss = generateDiffCollection(zbiornikData, selectedZbiorniks, "id");
        grid.store.loadData(obss.restls);
        grid2.store.loadData(obss.containsls);
        if (grid2.getStore().count() == 0) {
            Ext.getCmp(this.cid + 'wszystkie').setValue(true);
        }
        if (block) {
            Ext.getCmp(this.cid + 'wszystkie').setValue(false);
            Ext.getCmp(this.cid + 'wszystkie').setDisabled(true);
        }
    },
    getSelectedZbiorniks: function () {
        var grid2 = Ext.getCmp(this.cid + "object_to");
        return grid2.store.collect("id");
    },
    validateZbiorniks: function () {
        var grid2 = Ext.getCmp(this.cid + "object_to");
        return grid2.getStore().count() > 0 || Ext.getCmp(this.cid + 'wszystkie').getValue();
    }
});
