Ext.define('ViewOperators', {
    extend: 'Ext.panel.Panel',
    grid: null,
    windowObject: null,
    id: "operatorzy",
    taskContextMenu: null,
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyName: "access.manage.operatorzy",
        accessPropertyViewName: "access.view.operatorzy"
    },
    cat: "k",
    text: Lang.operatorzy,
    buttonIconCls: "iconOperator",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                scope: this,
                handler: this.dodajObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewOperators.accessPropertyName + "/add")
            }, {
                id: this.id + "bedit",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEdit",
                text: Lang.edytuj.capitalise(),
                scope: this,
                handler: this.edytujObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewOperators.accessPropertyName + "/update")
            }, {
                id: this.id + "bdelete",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconDelete",
                text: Lang.usun.capitalise(),
                scope: this,
                handler: this.usunObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewOperators.accessPropertyName + "/delete")
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconPDF",
                text: Lang.pdf.capitalise(),
                scope: this,
                handler: this.getDownloadLsPdf
            }, {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEXCEL",
                text: Lang.excel.capitalise(),
                scope: this,
                handler: this.getDownloadLsExcel
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }, {
                xtype: "box",
                id: this.id + "kontekstfirmybox",
                cls: "kontekstfirmy-box"
            }, {
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                style: 'display:block;margin-right:5px',
                cls: "kontekstfirmy-text"
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    onDestroy: function () {
        this.taskContextMenu.destroy();
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        var kontekstfirmybox = Ext.getCmp(this.id + "kontekstfirmybox");
        if (AppFIRMACTX != null && !Const.AGGREGATEDCORE) {
            kontekstfirmybox.update("<div class='appSetColourRedSplit'></div>");
            kontekstfirmy.setText(Lang.pracujesz_w_kontekscie + AppFIRMACTX.nazwa);
        } else {
            kontekstfirmybox.update("");
            kontekstfirmy.setText("");
        }
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            var onwer = this.grid.ownerCt;
            Ext.suspendLayouts();
            onwer.remove(this.grid);
            this.grid.destroy();
            this.taskContextMenu.destroy();
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
    },
    scrollToView: function (id) {
        this.grid.selectRowScroll("id", id);
    },
    buildGrid: function () {
        var cols = [{
            header: "ID",
            width: 145,
            sortable: true,
            dataIndex: 'id',
            filter: {
                type: 'numeric'
            },
            filterable: true,
            hidden: true
        }, {
            header: Lang.imie,
            flex: 2,
            sortable: true,
            dataIndex: 'imie',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["imie"], true),
            filterable: true
        }, {
            header: Lang.nazwisko,
            flex: 2,
            sortable: true,
            dataIndex: 'nazwisko',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["nazwisko"], true),
            filterable: true
        }, {
            header: Lang.login,
            flex: 2,
            sortable: true,
            dataIndex: 'login',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["login"], true),
            filterable: true
        }, {
            header: Lang.firma,
            flex: 2,
            sortable: true,
            dataIndex: 'firma',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["firma"], true),
            filterable: true,
            hidden: AppFIRMACTX != null
        }, {
            header: Lang.opis,
            flex: 2,
            sortable: true,
            dataIndex: 'opis',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["opis"], true),
            filterable: true,
            hidden: Ext.getViewportWidth() <= 1366
        }, {
            header: Lang.dostawca,
            flex: 2,
            sortable: true,
            dataIndex: 'dostawca',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["dostawca"], true),
            filterable: true,
            hidden: !Const.ENABLELOADINGPROVIDER
        }, {
            header: Lang.zalogowany,
            flex: 1,
            sortable: true,
            dataIndex: 'slogonstate',
            renderer: renderBool2,
            filter: {
                type: 'boolean'
            },
            filterable: true
        }, {
            header: Lang.ostatnia_aktywnosc,
            flex: 2,
            sortable: true,
            dataIndex: 'slastactivity',
            filter: {
                type: 'date'
            },
            filterable: true
        }, {
            header: Lang.serwisant,
            flex: 1,
            sortable: true,
            dataIndex: 'role',
            renderer: renderBoolIsSerwisant,
            filter: {
                type: 'boolean'
            },
            filterable: true,
            hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
        }, {
            header2: Lang.active,
            menuText: Lang.active,
            header: Ext.DomHelper.markup({
                tag: "div",
                cls: "iconDivCol iconActive",
                cn: [{
                    tag: "span",
                    html: "&nbsp;"
                }, {
                    tag: "div",
                    html: Lang.active
                }]
            }),
            flex: 1,
            sortable: true,
            dataIndex: 'active',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            filterable: true
        }];
        if (!Const.ENTERPRISEFLAG) {
            var cfi = -1;
            for (var i = 0; i < cols.length; i++) {
                if (cols[i].dataIndex == "firma") {
                    cfi = i;
                    break;
                }
            }
            if (cfi > -1) {
                cols.splice(cfi, 1);
            }
        }
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true
            },
            store: new Ext.data.Store({
                proxy: new Ext.data.MemoryProxy(),
                autoLoad: false,
                model: Ext.define(null, {
                    extend: 'Ext.data.Model',
                    idProperty: "id",
                    fields: [{
                        name: 'id',
                        mapping: 'id'
                    }, {
                        name: 'imie',
                        mapping: 'imie'
                    }, {
                        name: 'nazwisko',
                        mapping: 'nazwisko'
                    }, {
                        name: 'active',
                        mapping: 'active'
                    }, {
                        name: 'login',
                        mapping: 'login'
                    }, {
                        name: 'slastactivity',
                        mapping: 'slastactivity',
                        sortType: function (value) {
                            if (value == null)
                                return '-';
                            return value;
                        }
                    }, {
                        name: 'slogonstate',
                        mapping: 'slogonstate'
                    }, {
                        name: 'firma',
                        mapping: 'firma',
                        sortType: function (value) {
                            if (value == null)
                                return '-';
                            return value;
                        }
                    }, {
                        name: 'dostawca',
                        mapping: 'dostawca'
                    }, {
                        name: 'opis',
                        mapping: 'opis'
                    }]
                }),
                reader: new Ext.data.ArrayReader(),
                sorters: [{
                    property: "imie",
                    direction: "ASC"
                }]
            }),
            columns: cols,
            frame: false,
            border: false,
            stateId: "grid-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            }
        });
        this.grid.addListener("rowdblclick", function () {
            if (validPrivilegesOperatorComplex(ViewOperators.accessPropertyName + "/update"))
                this.edytujObiekt();
        }, this);
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.taskContextMenu = new Ext.menu.Menu();
        this.taskContextMenu.add([{
            iconAlign: 'left',
            iconCls: "iconNew",
            text: Lang.nowy.capitalise(),
            scope: this,
            handler: this.dodajObiekt,
            disabled: !validPrivilegesOperatorComplex(ViewOperators.accessPropertyName + "/add")
        }, {
            id: this.id + "bedit2",
            iconAlign: 'left',
            iconCls: "iconEdit",
            text: Lang.edytuj.capitalise(),
            scope: this,
            handler: this.edytujObiekt,
            disabled: !validPrivilegesOperatorComplex(ViewOperators.accessPropertyName + "/update")
        }, {
            id: this.id + "bdelete2",
            iconAlign: 'left',
            iconCls: "iconDelete",
            text: Lang.usun.capitalise(),
            scope: this,
            handler: this.usunObiekt,
            disabled: !validPrivilegesOperatorComplex(ViewOperators.accessPropertyName + "/delete")
        }, "-", {
            iconAlign: 'left',
            iconCls: "iconPDF",
            text: Lang.pdf.capitalise(),
            scope: this,
            handler: this.getDownloadLsPdf
        }, {
            iconAlign: 'left',
            iconCls: "iconEXCEL",
            text: Lang.excel.capitalise(),
            scope: this,
            handler: this.getDownloadLsExcel
        }, "-", {
            iconAlign: 'left',
            iconCls: "iconRefresh",
            text: Lang.odswiez.capitalise(),
            scope: this,
            handler: this.refreshDatastore
        }]);
        this.grid.addListener("rowcontextmenu", function (grid, record, tr, rowIndex, e) {
            this.rowContext = grid.getStore().getAt(rowIndex);
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
        this.taskContextMenu.addListener("hide", function () {
            this.rowContext = null;
        }, this);
        this.grid.addListener("containercontextmenu", function (grid, e) {
            this.rowContext = null;
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
    },
    manageSelect: function () {
        var bedit = Ext.getCmp(this.id + "bedit");
        var bdelete = Ext.getCmp(this.id + "bdelete");
        var bedit2 = Ext.getCmp(this.id + "bedit2");
        var bdelete2 = Ext.getCmp(this.id + "bdelete2");
        if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelection() == null || this.grid.getSelectionModel().getSelection().length == 0) {
            bdelete.setDisabled(true);
            bedit.setDisabled(true);
        } else {
            var row = this.grid.getSelectionModel().getSelection()[0];
            bdelete.setDisabled(!validPrivilegesOperatorComplex(ViewOperators.accessPropertyName + "/delete"));
            bedit.setDisabled(!validPrivilegesOperatorComplex(ViewOperators.accessPropertyName + "/update"));
        }
        if (this.rowContext == null) {
            bdelete2.setDisabled(true);
            bedit2.setDisabled(true);
        } else {
            bdelete2.setDisabled(!validPrivilegesOperatorComplex(ViewOperators.accessPropertyName + "/delete"));
            bedit2.setDisabled(!validPrivilegesOperatorComplex(ViewOperators.accessPropertyName + "/update"));
        }
    },
    getDownloadLsPdf: function () {
        postFormAsync("rest/pdfexcelexport/downloadExport", {
            format: "pdf",
            name: Lang.operatorzy,
            defd: Ext.encode(this.grid.getDefGridDataCSF({
                colsEx: [],
                colsIn: [],
                useRenderer: true,
                fnd: function (v, c, metaData, record, rowIndex, colIndex, store) {
                    if (c == "active" || c == "slogonstate") {
                        if (v == 'true')
                            v = Lang.tak;
                        else
                            v = Lang.nie;
                    }
                    if (c == "role") {
                        if (v == 0)
                            v = Lang.tak;
                        else
                            v = Lang.nie;
                    }
                    return v;
                }
            }))
        }, null);
    },
    getDownloadLsExcel: function () {
        postFormAsync("rest/pdfexcelexport/downloadExport", {
            format: "excel",
            name: Lang.operatorzy,
            defd: Ext.encode(this.grid.getDefGridDataCSF({
                colsEx: [],
                colsIn: [],
                useRenderer: true,
                fnd: function (v, c, metaData, record, rowIndex, colIndex, store) {
                    if (c == "active" || c == "slogonstate") {
                        if (v == 'true')
                            v = Lang.tak;
                        else
                            v = Lang.nie;
                    }
                    if (c == "role") {
                        if (v == 0)
                            v = Lang.tak;
                        else
                            v = Lang.nie;
                    }
                    return v;
                }
            }))
        }, null);
    },
    refreshDatastore: function () {
        updateCzasRefreshSerwer();
        if (this.grid && this.grid.rendered)
            this.grid.mask();
        sendAjaxDataJsonRpcL("rest/operators/getList", null, this, function (data) {
            if (this.grid && this.grid.rendered)
                this.grid.unmask();
            if (data.status) {
                data = data.data;
                this.grid.store.loadData(data);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    dodajObiekt: function () {
        var windowObject = Ext.create('WindowOperator', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(0);
    },
    edytujObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length == 1) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        var windowObject = Ext.create('WindowOperator', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(id);
    },
    usunObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        Ext.MessageBox.show({
            title: Lang.usuwanie,
            msg: Lang.czy_napewno_usunac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/operators/deleteObject", {
                        id: id
                    }, this, function (data) {
                        if (data.status) {
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                        this.refreshDatastore();
                    });
                }
            }
        });
    }
});
