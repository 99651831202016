Ext.define('WActivate', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.licencja.capitalise(),
    width: 600,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    autoHeight: true,
    scrollable: true,
    bodyStyle: 'padding:10px 10px 10px 10px',
    initComponent: function () {
        this.cid = Ext.id();
        var fieldActivatedLabel = AppActivated ? {
            xtype: 'box',
            cls: "text-box-decorated",
            style: "margin-bottom:5px;",
            isFormField: false,
            anchor: 0,
            html: Lang.ten_produkt_jest_juz_aktywowany
        } : {};
        this.panelBackupUploadSettings = Ext.create('PanelBackupUploadSettings', {
            bodyStyle: 'padding:5px 5px 5px 5px',
            id: this.cid + 'backupuploadconfig'
        });
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                scrollable: true,
                defaults: {
                    labelWidth: 150
                },
                items: [fieldActivatedLabel, {
                    xtype: 'labelexd',
                    id: this.cid + "hardwareKey",
                    name: "hardwareKey",
                    labelWidth: 150,
                    style: "position:relative;top:3px",
                    fieldLabel: Lang.klucz_sprzetowy,
                    hidden: true,
                    hideLabel: true
                }, {
                    xtype: 'panel',
                    layout: 'hbox',
                    border: false,
                    isFormField: false,
                    items: [{
                        flex: 1,
                        id: this.cid + "productKey",
                        name: "productKey",
                        xtype: 'textfieldexd',
                        labelWidth: 150,
                        pack: 'start',
                        isFormField: true,
                        fieldLabel: Lang.klucz_produktu,
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        margin: "3 0 0 2",
                        id: this.cid + "productKey2",
                        name: "productKey2",
                        xtype: 'box',
                        pack: 'end',
                        isFormField: false,
                        cls: 'x-icon-div2',
                        width: 16,
                        height: 16,
                        html: {
                            cls: "iconDiv iconCancel",
                            tag: "div"
                        }
                    }]
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "activateinternet",
                    name: "activateinternet",
                    fieldLabel: Lang.aktywacja,
                    columns: 2,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.przez_internet,
                        inputValue: true
                    }, {
                        boxLabel: Lang.aktywacja_reczna,
                        inputValue: false,
                        value: true
                    }],
                    listeners: {
                        change: function () {
                            var activateinternet = Ext.getCmp(this.cid + "activateinternet").getValue2();
                            Ext.getCmp(this.cid + "activateKey").setDisabled(activateinternet);
                        },
                        scope: this
                    }
                }, {
                    id: this.cid + "hardwarePrdActKey",
                    name: "hardwarePrdActKey",
                    xtype: 'displayexd',
                    hidden: false,
                    isFormField: true,
                    style: "position:relative;top:3px",
                    fieldLabel: Lang.numer_aktywacji,
                    readOnly: true,
                    cls: "x-item-disabled-sec2"
                }, {
                    xtype: 'panel',
                    layout: 'hbox',
                    border: false,
                    isFormField: false,
                    bodyStyle: 'padding:0px 0px 5px 0px',
                    items: [{
                        flex: 1,
                        id: this.cid + "activateKey",
                        name: "activateKey",
                        pack: 'start',
                        xtype: 'textfieldexd',
                        allowBlank: false,
                        isFormField: true,
                        fieldLabel: Lang.klucz_aktywacji,
                        labelWidth: 150,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        margin: "3 0 0 2",
                        id: this.cid + "activateKey2",
                        name: "activateKey2",
                        xtype: 'box',
                        pack: 'end',
                        isFormField: false,
                        width: 16,
                        height: 16,
                        cls: 'x-icon-div',
                        html: {
                            cls: "iconDiv iconCancel",
                            tag: "div"
                        }
                    }]
                }, {
                    xtype: 'panel',
                    layout: 'hbox',
                    border: false,
                    isFormField: false,
                    bodyStyle: 'padding:0px 0px 5px 0px',
                    items: [{
                        flex: 1,
                        id: this.cid + "firmName",
                        name: "firmName",
                        xtype: 'textfieldexd',
                        allowBlank: false,
                        isFormField: true,
                        fieldLabel: Lang.firma,
                        labelWidth: 150,
                        maxLength: 200,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        margin: "3 0 0 2",
                        id: this.cid + "firmName2",
                        name: "firmName2",
                        isFormField: false,
                        xtype: 'box',
                        width: 16,
                        height: 16
                    }]
                }, {
                    xtype: 'panel',
                    layout: 'hbox',
                    border: false,
                    isformField: false,
                    bodyStyle: 'padding:0px 0px 5px 0px',
                    items: [{
                        flex: 1,
                        id: this.cid + "nip",
                        name: "nip",
                        isFormField: true,
                        fieldLabel: Lang.nip,
                        labelWidth: 150,
                        maxLength: 200,
                        xtype: 'textfieldexd',
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        margin: "3 0 0 2",
                        id: this.cid + "nip2",
                        name: "nip2",
                        isFormField: false,
                        xtype: 'box',
                        width: 16,
                        height: 16
                    }]
                }, {
                    xtype: 'panel',
                    layout: 'hbox',
                    border: false,
                    isformField: false,
                    items: [{
                        flex: 1,
                        id: this.cid + "address",
                        name: "address",
                        isFormField: true,
                        fieldLabel: Lang.adres,
                        labelWidth: 150,
                        maxLength: 200,
                        xtype: 'textfieldexd',
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        margin: "3 0 0 2",
                        id: this.cid + "address2",
                        name: "address2",
                        isFormField: false,
                        xtype: 'box',
                        width: 16,
                        height: 16
                    }]
                }, {
                    margin: "10 0 10 0",
                    xtype: "checkboxexd",
                    id: this.cid + "termsofthelicense",
                    boxLabel: new Ext.Template(Lang.akceptuje_warunki_licencji).apply({typ: resolveHtmlDocumentType(), lang: resolveHtmlDocumentLang()}),
                    hidden: AppActivated
                }]
            }]
        }, {
            title: Lang.ustawienia_zdalnego_przechowywania_kopii_zapasowych,
            layout: 'fit',
            hidden: AppActivated && !Const.CLOUDFLAG,
            id: this.cid + 'backupuploadtab',
            items: [this.panelBackupUploadSettings]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'ok',
            text: Lang.aktywacja.capitalise(),
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.zamknij.capitalise(),
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    validateFieldsTask: function () {
        var productKey2 = Ext.getCmp(this.cid + "productKey2");
        var activateKey2 = Ext.getCmp(this.cid + "activateKey2");
        var activateinternet = Ext.getCmp(this.cid + "activateinternet").getValue2();
        var productKey = Ext.getCmp(this.cid + "productKey").getValue2();
        var activateKey = Ext.getCmp(this.cid + "activateKey").getValue2();
        var btnokDsb = true;
        if (productKey != null) {
            sendAjaxDataJsonRpcL("rest/activationmgr/validateObjectDataPrdKey", {
                productKey: Ext.getCmp(this.cid + "productKey").getValue2()
            }, this, function (data) {
                if (data.status) {
                    var data = data.data;
                    if (data.status) {
                        Ext.getCmp(this.cid + "productKey").clearInvalid();
                        btnokDsb = false;
                        productKey2.getEl().first().removeCls("iconCancel");
                        productKey2.getEl().first().removeCls("iconAccpted");
                        productKey2.getEl().first().addCls("iconAccpted");
                        if (Ext.getCmp(this.cid + "hardwarePrdActKey").getValue() != data.msg)
                            Ext.getCmp(this.cid + "hardwarePrdActKey").setValue(data.msg);
                    } else {
                        productKey2.getEl().first().removeCls("iconCancel");
                        productKey2.getEl().first().removeCls("iconAccpted");
                        productKey2.getEl().first().addCls("iconCancel");
                        Ext.getCmp(this.cid + "productKey").markInvalid(data.msg);
                        btnokDsb = true;
                        Ext.getCmp(this.cid + "hardwarePrdActKey").setValue(null);
                    }
                } else {
                    productKey2.getEl().first().removeCls("iconCancel");
                    productKey2.getEl().first().removeCls("iconAccpted");
                    productKey2.getEl().first().addCls("iconCancel");
                    Ext.getCmp(this.cid + "productKey").markInvalid(data.msg);
                    btnokDsb = true;
                    Ext.getCmp(this.cid + "hardwarePrdActKey").setValue(null);
                }
                if (!btnokDsb) {
                    if (activateKey != null) {
                        sendAjaxDataJsonRpcL("rest/activationmgr/validateObjectDataActivationKey", {
                            productKey: Ext.getCmp(this.cid + "productKey").getValue2(),
                            activateKey: Ext.getCmp(this.cid + "activateKey").getValue2()
                        }, this, function (data) {
                            if (data.status) {
                                var data = data.data;
                                if (data.status) {
                                    activateKey2.getEl().first().removeCls("iconCancel");
                                    activateKey2.getEl().first().removeCls("iconAccpted");
                                    activateKey2.getEl().first().addCls("iconAccpted");
                                    Ext.getCmp(this.cid + "activateKey").clearInvalid();
                                    btnokDsb = false;
                                } else {
                                    activateKey2.getEl().first().removeCls("iconCancel");
                                    activateKey2.getEl().first().removeCls("iconAccpted");
                                    activateKey2.getEl().first().addCls("iconCancel");
                                    Ext.getCmp(this.cid + "activateKey").markInvalid(data.msg);
                                    btnokDsb = true;
                                }
                            } else {
                                activateKey2.getEl().first().removeCls("iconCancel");
                                activateKey2.getEl().first().removeCls("iconAccpted");
                                activateKey2.getEl().first().addCls("iconCancel");
                                Ext.getCmp(this.cid + "activateKey").markInvalid(data.msg);
                                btnokDsb = true;
                            }
                        });
                    } else {
                        if (!activateinternet) {
                            activateKey2.getEl().first().removeCls("iconCancel");
                            activateKey2.getEl().first().removeCls("iconAccpted");
                            activateKey2.getEl().first().addCls("iconCancel");
                            btnokDsb = true;
                        }
                    }
                }
                Ext.getCmp(this.cid + "ok").setDisabled(btnokDsb || AppActivated);
            });
        } else {
            productKey2.getEl().first().removeCls("iconCancel");
            productKey2.getEl().first().removeCls("iconAccpted");
            productKey2.getEl().first().addCls("iconCancel");
            activateKey2.getEl().first().removeCls("iconCancel");
            activateKey2.getEl().first().removeCls("iconAccpted");
            activateKey2.getEl().first().addCls("iconCancel");
            btnokDsb = true;
            Ext.getCmp(this.cid + "hardwarePrdActKey").setValue(null);
            Ext.getCmp(this.cid + "ok").setDisabled(btnokDsb || AppActivated);
        }
    },
    showW: function () {
        if (!validPrivilegesOperatorComplex("access.manage.activation")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        sendAjaxDataJsonRpcL("rest/activationmgr/getObjectData", null, this, function (data) {
            if (data.status) {
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                this.obiekt = data.data || {};
                Ext.getCmp(this.cid + "productKey").setValue(this.obiekt.productKey);
                Ext.getCmp(this.cid + "hardwareKey").setValue(this.obiekt.hardwareKey);
                Ext.getCmp(this.cid + "hardwarePrdActKey").setValue(this.obiekt.hardwarePrdActKey);
                Ext.getCmp(this.cid + "activateKey").setValue(this.obiekt.activateKey);
                Ext.getCmp(this.cid + "firmName").setValue(this.obiekt.firmName);
                Ext.getCmp(this.cid + "nip").setValue(this.obiekt.nip);
                Ext.getCmp(this.cid + "address").setValue(this.obiekt.address);
                Ext.getCmp(this.cid + "productKey").clearInvalid();
                Ext.getCmp(this.cid + "hardwareKey").clearInvalid();
                Ext.getCmp(this.cid + "hardwarePrdActKey").clearInvalid();
                Ext.getCmp(this.cid + "activateKey").clearInvalid();
                Ext.getCmp(this.cid + "firmName").clearInvalid();
                Ext.getCmp(this.cid + "nip").clearInvalid();
                Ext.getCmp(this.cid + "address").clearInvalid();
                Ext.getCmp(this.cid + "ok").setDisabled(true);
                this.task = {
                    run: this.validateFieldsTask,
                    scope: this,
                    interval: 1000
                };
                Ext.TaskManager.start(this.task);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        if (!Ext.getCmp(this.cid + 'formpanel').form.isValid()) {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        if (!Const.CLOUDFLAG && (!Ext.getCmp(this.cid + "termsofthelicense").getValue() || !this.panelBackupUploadSettings.validate())) {
            Ext.MessageBox.alert(Lang.error, Lang.zaznacz_wszystkie_zgody);
            return;
        }
        var activateinternet = Ext.getCmp(this.cid + "activateinternet").getValue2();
        if (activateinternet) {
            var myMask = new Ext.LoadMask({
                target: this
            });
            myMask.show();
        }
        sendAjaxDataJsonRpcL("rest/activationmgr/activateObjectData", {
            activateinternet: Ext.getCmp(this.cid + "activateinternet").getValue2(),
            productKey: Ext.getCmp(this.cid + "productKey").getValue2(),
            activateKey: Ext.getCmp(this.cid + "activateKey").getValue2(),
            firmName: Ext.getCmp(this.cid + "firmName").getValue2(),
            nip: Ext.getCmp(this.cid + "nip").getValue2(),
            address: Ext.getCmp(this.cid + "address").getValue2()
        }, this, function (data) {
            if (activateinternet) {
                myMask.hide();
            }
            if (data.status) {
                var data = data.data;
                if (data.status) {
                    Ext.TaskManager.stop(this.task);
                    var changed = data.changed;
                    if (changed) {
                        this.panelBackupUploadSettings.save()
                            .then(result => {

                            })
                            .catch(e => {
                                handleCommonCallBackAjaxRPCError(e);
                            })
                            .finally(() => {
                                Ext.MessageBox.alert(Lang.licencja, Lang.produkt_zostal_aktywowany_pomyslnie, function () {
                                    this.close();
                                    this.fireEvent("wclose");
                                    window.location = _HandleRedirectDispatcherURL;
                                }, this);
                            })
                    } else {
                        this.close();
                        this.fireEvent("wclose");
                    }
                } else {
                    Ext.MessageBox.alert(Lang.error, data.msg);
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    closeOp: function () {
        Ext.TaskManager.stop(this.task);
        this.close();
    }
});

