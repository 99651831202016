Ext.define('WindowPobierz', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.pobierz.capitalise(),
    width: 600,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = {
            xtype: 'panel',
            frame: true,
            bodyStyle: 'padding: 10px 10px 10px 10px',
            autoHeight: true,
            autoWidth: true,
            html: [{
                tag: "div",
                style: "margin-top:10px;margin-bottom:2px",
                cn: [{
                    tag: "div",
                    cls: "x-pobierz-text-yeel-text",
                    html: Lang.about_opis_text
                }, {
                    tag: "table",
                    cls: "x-pobierz-text-yeel-table",
                    cn: [{
                        tag: "tr",
                        cn: [{
                            tag: "td",
                            cls: "x-pobierz-text-yeel-cell2",
                            cn: [{
                                tag: "div",
                                cls: "x-pobierz-text-yeel-header",
                                html: Lang.plik
                            }]
                        }, {
                            tag: "td",
                            cls: "x-pobierz-text-yeel-cell2",
                            cn: [{
                                tag: "div",
                                cls: "x-pobierz-text-yeel-header",
                                html: Lang.rozmiar
                            }]
                        }, {
                            tag: "td",
                            cls: "x-pobierz-text-yeel-cell2",
                            cn: [{
                                tag: "div",
                                cls: "x-pobierz-text-yeel-header",
                                html: Lang.opis
                            }]
                        }]
                    }, {
                        tag: "tr",
                        cn: [{
                            tag: "td",
                            cls: "x-pobierz-text-yeel-cell",
                            cn: [{
                                tag: "a",
                                cls: "iconDownload x-pobierz-text-yeel-e-icon x-pobierz-text-yeel-e",
                                href: "resources/appstore/deviceclient.exe",
                                html: Lang.sterowniki_do_czytnika + " (Microsoft Windows)"
                            }]
                        }, {
                            tag: "td",
                            cls: "x-pobierz-text-yeel-cell",
                            cn: [{
                                tag: "div",
                                cls: "x-pobierz-text-yeel-rozmiar",
                                html: "~250MB"
                            }]
                        }, {
                            tag: "td",
                            cls: "x-pobierz-text-yeel-cell",
                            cn: [{
                                tag: "div",
                                cls: "x-pobierz-text-yeel-opis",
                                html: Lang.about_opis_text_ftdijre
                            }]
                        }]
                    }, {
                        tag: "tr",
                        cn: [{
                            tag: "td",
                            cls: "x-pobierz-text-yeel-cell",
                            cn: [{
                                tag: "a",
                                cls: "iconDownload x-pobierz-text-yeel-e-icon x-pobierz-text-yeel-e",
                                href: "resources/appstore/ArccanSmdpRfdService.pkg",
                                html: Lang.sterowniki_do_czytnika + " (Apple macOS)"
                            }]
                        }, {
                            tag: "td",
                            cls: "x-pobierz-text-yeel-cell",
                            cn: [{
                                tag: "div",
                                cls: "x-pobierz-text-yeel-rozmiar",
                                html: "~150MB"
                            }]
                        }, {
                            tag: "td",
                            cls: "x-pobierz-text-yeel-cell",
                            cn: [{
                                tag: "div",
                                cls: "x-pobierz-text-yeel-opis",
                                html: Lang.about_opis_text_ftdijre
                            }]
                        }]
                    }]
                }]
            }]
        };
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'cancel',
            text: Lang.zamknij.capitalise(),
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function () {
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
    },
    closeOp: function () {
        this.close();
    }
});

