Ext.define('PanelWizardFilterChoose', {
    extend: 'Ext.panel.Panel',
    initComponent: function () {
        this.cid = Ext.id();
        Ext.apply(this, {
            layout: "fit",
            title: Lang.rplfiltrowanie,
            frame: false,
            border: false,
            autoWidth: false,
            autoHeight: false,
            height: 457,
            items: [{
                xtype: 'panel',
                layout: "hbox",
                layoutConfig: {
                    align: "stretch"
                },
                items: [{
                    width: 180,
                    xtype: 'dataview',
                    id: this.cid + "kolumnyView",
                    singleSelect: true,
                    scrollable: true,
                    height: 410,
                    store: new Ext.data.JsonStore({
                        id: "id",
                        rootProperty: '',
                        fields: [{
                            name: 'id',
                            mapping: 'id'
                        }, {
                            name: 'nazwa',
                            mapping: 'nazwa'
                        }]
                    }),
                    tpl: '<tpl for="."><div class="x-rlist-filtr-item">{' + "nazwa" + '}</div></tpl>',
                    scrollable: true,
                    itemSelector: ".x-rlist-filtr-item",
                    listeners: {
                        select: this.clickSelectCol,
                        scope: this
                    }
                }, {
                    flex: 1,
                    xtype: "panel",
                    id: this.cid + "kolumnyViewC",
                    layout: "fit",
                    frame: false,
                    border: false,
                    autoWidth: false,
                    autoHeight: false,
                    items: []
                }, {
                    cls: "x-hidden",
                    width: 1,
                    xtype: "panel",
                    id: this.cid + "kolumnyViewC2",
                    frame: false,
                    border: false,
                    autoWidth: false,
                    autoHeight: false,
                    items: []
                }]
            }]
        });
        this.callParent(arguments);
    },
    callAfterShow: function () {
        if (!this.callAfterShowCalled) {
            this.panelFCzas = Ext.create('PanelWizardFilterCzas');
            this.panelFTypyT = Ext.create('PanelWizardFilterTypyTankowan');
            this.panelFiltrSterownik = Ext.create('PanelWizardFilterFiltrLista', {
                col: "sterownik"
            });
            this.panelFiltrRozszerzenie = Ext.create('PanelWizardFilterFiltrLista', {
                col: "rozszerzeniester"
            });
            this.panelFiltrZbiornik = Ext.create('PanelWizardFilterFiltrLista', {
                col: "zbiornik"
            });
            this.panelFiltrDostawca = Ext.create('PanelWizardFilterFiltrLista', {
                col: "dostawca"
            });
            this.panelFiltrZdopis = Ext.create('PanelWizardFilterFiltrLista', {
                col: "zdopis"
            });
            this.panelFiltrPlyn = Ext.create('PanelWizardFilterFiltrLista', {
                col: "plyn"
            });
            this.panelFiltrPlynEIID = Ext.create('PanelWizardFilterFiltrLista', {
                col: "plynEIID"
            });
            this.panelFiltrFirma = Ext.create('PanelWizardFilterFiltrLista', {
                col: "firma"
            });
            this.panelFiltrOperator = Ext.create('PanelWizardFilterFiltrLista', {
                col: "operator"
            });
            this.arrayPanel = [this.panelFCzas, this.panelFTypyT, this.panelFiltrSterownik, this.panelFiltrRozszerzenie, this.panelFiltrZbiornik, this.panelFiltrDostawca, this.panelFiltrZdopis, this.panelFiltrPlyn, this.panelFiltrPlynEIID, this.panelFiltrFirma, this.panelFiltrOperator];
            if (this.datad.mainCtrl.repFirma == null) {
                this.panelFiltrPojazdTRW = Ext.create('PanelWizardFilterFiltrLista', {
                    col: "pojazdTRW"
                });
                this.panelFiltrOdbiorca = Ext.create('PanelWizardFilterFiltrLista', {
                    col: "odbiorca"
                });
                if (!Const.OPTIMALFLAG && !Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG)
                    this.panelFiltrPojazdTRWRok = Ext.create('PanelWizardFilterFiltrLista', {
                        col: "pojazdTRWRok"
                    });
                this.panelFiltrPojazdTRWIE = Ext.create('PanelWizardFilterFiltrLista', {
                    col: "pojazdTRWIE"
                });
                this.panelFiltrPojazdTRWEIID = Ext.create('PanelWizardFilterFiltrLista', {
                    col: "pojazdTRWEIID"
                });
                this.panelFiltrOdbiorcaEIID = Ext.create('PanelWizardFilterFiltrLista', {
                    col: "odbiorcaEIID"
                });
                this.arrayPanel.push(this.panelFiltrPojazdTRW);
                this.arrayPanel.push(this.panelFiltrOdbiorca);
                if (!Const.OPTIMALFLAG && !Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG)
                    this.arrayPanel.push(this.panelFiltrPojazdTRWRok);
                this.arrayPanel.push(this.panelFiltrPojazdTRWIE);
                this.arrayPanel.push(this.panelFiltrPojazdTRWEIID);
                this.arrayPanel.push(this.panelFiltrOdbiorcaEIID);
            } else {
                this.panelFiltrPojazdTRW = Ext.create('PanelWizardFilterFiltrLista', {
                    col: "pojazdTRW"
                });
                this.panelFiltrOdbiorca = Ext.create('PanelWizardFilterFiltrLista', {
                    col: "odbiorca"
                });
                if (!Const.OPTIMALFLAG && !Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG)
                    this.panelFiltrPojazdTRWRok = Ext.create('PanelWizardFilterFiltrLista', {
                        col: "pojazdTRWRok"
                    });
                this.panelFiltrPojazdTRWIE = Ext.create('PanelWizardFilterFiltrLista', {
                    col: "pojazdTRWIE"
                });
                this.panelFiltrPojazdTRWEIID = Ext.create('PanelWizardFilterFiltrLista', {
                    col: "pojazdTRWEIID"
                });
                this.panelFiltrOdbiorcaEIID = Ext.create('PanelWizardFilterFiltrLista', {
                    col: "odbiorcaEIID"
                });
                this.arrayPanel.push(this.panelFiltrPojazdTRW);
                this.arrayPanel.push(this.panelFiltrOdbiorca);
                if (!Const.OPTIMALFLAG && !Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG)
                    this.arrayPanel.push(this.panelFiltrPojazdTRWRok);
                this.arrayPanel.push(this.panelFiltrPojazdTRWIE);
                this.arrayPanel.push(this.panelFiltrPojazdTRWEIID);
                this.arrayPanel.push(this.panelFiltrOdbiorcaEIID);
            }
            this.panelFiltrGrupaOdbiorca = Ext.create('PanelWizardFilterFiltrLista', {
                col: 'grupa_odbiorca'
            });
            this.panelFiltrGrupaPojazd = Ext.create('PanelWizardFilterFiltrLista', {
                col: 'grupa_pojazd'
            });
            this.arrayPanel.push(this.panelFiltrGrupaOdbiorca);
            this.arrayPanel.push(this.panelFiltrGrupaPojazd);
            this.mapPanelId = {
                czas: this.panelFCzas,
                typ_tankowania: this.panelFTypyT,
                sterownik: this.panelFiltrSterownik,
                rozszerzeniester: this.panelFiltrRozszerzenie,
                zbiornik: this.panelFiltrZbiornik,
                dostawca: this.panelFiltrDostawca,
                zdopis: this.panelFiltrZdopis,
                plyn: this.panelFiltrPlyn,
                plynEIID: this.panelFiltrPlynEIID,
                firma: this.panelFiltrFirma,
                operator: this.panelFiltrOperator,
                pojazdTRW: this.panelFiltrPojazdTRW,
                odbiorca: this.panelFiltrOdbiorca,
                pojazdTRWRok: this.panelFiltrPojazdTRWRok,
                pojazdTRWIE: this.panelFiltrPojazdTRWIE,
                pojazdTRWEIID: this.panelFiltrPojazdTRWEIID,
                odbiorcaEIID: this.panelFiltrOdbiorcaEIID,
                grupa_odbiorca: this.panelFiltrGrupaOdbiorca,
                grupa_pojazd: this.panelFiltrGrupaPojazd
            };
            Ext.each(this.arrayPanel, function (v) {
                v.setData(Ext.apply(this.datad, {
                    mainCtrl2: this
                }));
            }, this);
        }
        var langmap = this.datad.mainCtrl.getKoolumnNazwaLangMap();
        var kolumnyyD = [];
        kolumnyyD.push({
            id: "czas",
            nazwa: langmap["czas"]
        });
        if (Const.ENTERPRISEFLAG)
            kolumnyyD.push({
                id: "firma",
                nazwa: langmap["firma"]
            });
        kolumnyyD.push({
            id: "plyn",
            nazwa: langmap["plyn"]
        });
        kolumnyyD.push({
            id: "zbiornik",
            nazwa: langmap["zbiornik"]
        });
        kolumnyyD.push({
            id: "typ_tankowania",
            nazwa: langmap["typ_tankowania"]
        });

        if (this.datad.mainCtrl.repFirma == null) {
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorca",
                    nazwa: langmap["odbiorca"]
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRW",
                    nazwa: langmap["pojazdTRW"]
                });
        } else {
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorca",
                    nazwa: langmap["odbiorca"]
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRW",
                    nazwa: langmap["pojazdTRW"]
                });
        }
        if (this.datad.mainCtrl.repFirma == null) {
            if (!Const.SKPFLAG)
                if (!Const.OPTIMALFLAG)
                    if (!Const.BASICFLAG && !Const.STARTERFLAG)
                        if (!Const.STANDARDFLAG)
                            kolumnyyD.push({
                                id: "pojazdTRWRok",
                                nazwa: langmap["pojazdTRWRok"]
                            });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWIE",
                    nazwa: langmap["pojazdTRWIE"]
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorcaEIID",
                    nazwa: langmap["odbiorcaEIID"]
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWEIID",
                    nazwa: langmap["pojazdTRWEIID"]
                });
        } else {
            if (!Const.SKPFLAG)
                if (!Const.OPTIMALFLAG)
                    if (!Const.BASICFLAG && !Const.STARTERFLAG)
                        if (!Const.STANDARDFLAG)
                            kolumnyyD.push({
                                id: "pojazdTRWRok",
                                nazwa: langmap["pojazdTRWRok"]
                            });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWIE",
                    nazwa: langmap["pojazdTRWIE"]
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorcaEIID",
                    nazwa: langmap["odbiorcaEIID"]
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWEIID",
                    nazwa: langmap["pojazdTRWEIID"]
                });
        }
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "zdopis",
                nazwa: langmap["zdopis"]
            });
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "sterownik",
                nazwa: langmap["sterownik"]
            });
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "rozszerzeniester",
                nazwa: langmap["rozszerzeniester"]
            });
        kolumnyyD.push({
            id: "operator",
            nazwa: langmap["operator"]
        });
        kolumnyyD.push({
            id: "dostawca",
            nazwa: langmap["dostawca"]
        });

        kolumnyyD.push({
            id: "plynEIID",
            nazwa: langmap["plynEIID"]
        });
        kolumnyyD.push({
            id: "grupa_odbiorca",
            nazwa: langmap["grupa_odbiorca"]
        });
        kolumnyyD.push({
            id: "grupa_pojazd",
            nazwa: langmap["grupa_pojazd"]
        });
        kolumnyyD = generateDiffCollection(kolumnyyD, this.datad.mainCtrl.repDef.columnsInReport, "id");
        kolumnyyD = kolumnyyD.containsls;
        var kolumnyView = Ext.getCmp(this.cid + "kolumnyView");
        kolumnyView.store.loadData(kolumnyyD);
        var kolumnyView = Ext.getCmp(this.cid + "kolumnyView");
        var kolumnyViewC = Ext.getCmp(this.cid + "kolumnyViewC");
        var kolumnyViewC2 = Ext.getCmp(this.cid + "kolumnyViewC2");
        Ext.suspendLayouts();
        if (this.ccurentPanel != undefined) {
            kolumnyViewC2.add(this.mapPanelId[this.ccurentPanel]);
            this.ccurentPanel = undefined;
        }
        kolumnyView.select(null);
        if (kolumnyyD.length > 0) {
            kolumnyViewC.body.update("");
            this.ccurentPanel = kolumnyyD[0].id;
            kolumnyViewC.add(this.mapPanelId[this.ccurentPanel]);
            kolumnyView.select(0);
            this.mapPanelId[this.ccurentPanel].callAfterShow();
        } else {
            kolumnyViewC.body.update(Lang.brak_dostepnych_kolumn_filtrowanie);
        }
        Ext.resumeLayouts(true);
        this.callAfterShowCalled = true;
    },
    callAfterHide: function () {
        if (this.callAfterShowCalled) {
            var okrestyp = Ext.getCmp(this.cid + "okrestyp");
            var okresod = Ext.getCmp(this.cid + "okresod");
            var okresdo = Ext.getCmp(this.cid + "okresdo");
            this.datad.mainCtrl.repDef.typeCzas = okrestyp.getValue();
            this.datad.mainCtrl.repDef.fromDate = okresod.getValue2();
            this.datad.mainCtrl.repDef.toDate = okresdo.getValue2();
        }
        return null;
    },
    clickSelectCol: function (c, r, i, eOptions) {
        var id = r.get("id");
        var kolumnyView = Ext.getCmp(this.cid + "kolumnyView");
        var kolumnyViewC = Ext.getCmp(this.cid + "kolumnyViewC");
        var kolumnyViewC2 = Ext.getCmp(this.cid + "kolumnyViewC2");
        Ext.suspendLayouts();
        this.mapPanelId[this.ccurentPanel].callAfterHide();
        kolumnyViewC2.add(this.mapPanelId[this.ccurentPanel]);
        this.ccurentPanel = id;
        kolumnyViewC.add(this.mapPanelId[this.ccurentPanel]);
        kolumnyView.select(i);
        Ext.resumeLayouts(true);
        this.mapPanelId[this.ccurentPanel].callAfterShow();
    },
    reParseReportValidate: function () {
    },
    validateShowLess: function () {
        return this.validateShow();
    },
    validateShow: function () {
        Ext.each(this.arrayPanel, function (v) {
            v.callAfterHide();
        }, this);
        return null;
    },
    setData: function (datad) {
        this.datad = datad;
    }
});
