Ext.define('RPivotFiltrWindow', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.filtrowanie.capitalise(),
    width: 600,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            frame: false,
            border: false,
            autoWidth: false,
            autoHeight: false,
            layout: 'hbox',
            layoutConfig: {
                align: "stretch"
            },
            items: [new Ext.ux.grid.GridPanelExd({
                store: new Ext.data.Store({
                    autoLoad: false,
                    sortOnLoad: true,
                    sorters: [{propertyName: 'text', sorterFn: customSorterForGrid}],
                    proxy: new Ext.data.MemoryProxy({}),
                    reader: new Ext.data.JsonReader({
                        id: "id",
                        rootProperty: ''
                    }, [{
                        name: 'value',
                        mapping: 'value'
                    }, {
                        name: 'text',
                        mapping: 'text'
                    }])
                }),
                columns: [{
                    header: Lang.nazwa,
                    flex: 1,
                    width: 145,
                    sortable: true,
                    dataIndex: 'text',
                    sortable: false
                }],
                title: Lang.dostepne,
                height: 480,
                flex: 1,
                frame: true,
                border: true,
                id: this.cid + "columns_from",
                stateId: null,
                stateful: false,
                stripeRows: true,
                enableHdMenu: false,
                selModel: {
                    selType: 'rowmodel',
                    mode: 'single'
                },
                listeners: {
                    scope: this,
                    rowdblclick: function () {
                        var grid = Ext.getCmp(this.cid + "columns_from");
                        var grid2 = Ext.getCmp(this.cid + "columns_to");
                        var r = grid.getSelR();
                        if (r == null)
                            return;
                        grid.store.remove(r);
                        grid2.store.add(r);
                    }
                },
                getSelR: function () {
                    var gridd = this;
                    if (!gridd.getSelectionModel().hasSelection()) {
                        return null;
                    } else {
                        if (gridd.getSelection() != null && gridd.getSelection().length > 0) {
                            return gridd.getSelection()[0];
                        }
                    }
                    return null;
                }
            }), {
                width: 50,
                xtype: 'panel',
                frame: false,
                border: false,
                layout: {
                    type: 'vbox',
                    align: 'middle',
                    pack: 'center'
                },
                height: 480,
                items: [{
                    xtype: 'button',
                    text: ">",
                    width: 50,
                    scope: this,
                    handler: function () {
                        var grid = Ext.getCmp(this.cid + "columns_from");
                        var grid2 = Ext.getCmp(this.cid + "columns_to");
                        var r = grid.getSelR();
                        if (r == null)
                            return;
                        grid.store.remove(r);
                        grid2.store.add(r);
                    }
                }, {
                    xtype: 'button',
                    width: 50,
                    text: ">>",
                    scope: this,
                    handler: function () {
                        var grid = Ext.getCmp(this.cid + "columns_from");
                        var grid2 = Ext.getCmp(this.cid + "columns_to");
                        grid2.store.add(grid.store.getRange());
                        grid.store.removeAll();
                    }
                }, {
                    xtype: 'button',
                    text: "<",
                    width: 50,
                    scope: this,
                    handler: function () {
                        var grid = Ext.getCmp(this.cid + "columns_from");
                        var grid2 = Ext.getCmp(this.cid + "columns_to");
                        var r = grid2.getSelR();
                        if (r == null)
                            return;
                        grid2.store.remove(r);
                        grid.store.add(r);
                    }
                }, {
                    xtype: 'button',
                    text: "<<",
                    width: 50,
                    scope: this,
                    handler: function () {
                        var grid = Ext.getCmp(this.cid + "columns_from");
                        var grid2 = Ext.getCmp(this.cid + "columns_to");
                        grid.store.add(grid2.store.getRange());
                        grid2.store.removeAll();
                    }
                }]
            }, new Ext.ux.grid.GridPanelExd({
                store: new Ext.data.Store({
                    autoLoad: false,
                    sortOnLoad: true,
                    sorters: [{propertyName: 'text', sorterFn: customSorterForGrid}],
                    proxy: new Ext.data.MemoryProxy({}),
                    reader: new Ext.data.JsonReader({
                        id: "id",
                        rootProperty: ''
                    }, [{
                        name: 'value',
                        mapping: 'value'
                    }, {
                        name: 'text',
                        mapping: 'text'
                    }])
                }),
                columns: [{
                    header: Lang.nazwa,
                    flex: 1,
                    width: 145,
                    sortable: true,
                    dataIndex: 'text',
                    sortable: false
                }],
                title: Lang.wybrane,
                height: 480,
                flex: 1,
                frame: true,
                border: true,
                id: this.cid + "columns_to",
                stateId: null,
                stateful: false,
                stripeRows: true,
                enableHdMenu: false,
                selModel: {
                    selType: 'rowmodel',
                    mode: 'single'
                },
                listeners: {
                    scope: this,
                    rowdblclick: function () {
                        var grid = Ext.getCmp(this.cid + "columns_from");
                        var grid2 = Ext.getCmp(this.cid + "columns_to");
                        var r = grid2.getSelR();
                        if (r == null)
                            return;
                        grid2.store.remove(r);
                        grid.store.add(r);
                    }
                },
                getSelR: function () {
                    var gridd = this;
                    if (!gridd.getSelectionModel().hasSelection()) {
                        return null;
                    } else {
                        if (gridd.getSelection() != null && gridd.getSelection().length > 0) {
                            return gridd.getSelection()[0];
                        }
                    }
                    return null;
                }
            })]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.closeSaveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function () {
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
        this.getEl().mask();
        sendAjaxDataJsonRpcL("rest/reportpivot/loadGetUniqueValues", {
            idfirma: this.idfirma,
            typeCzas: this.typeCzas,
            fromDate: this.fromDate,
            toDate: this.toDate,
            cols: [this.rn]
        }, this, function (data) {
            this.getEl().unmask();
            if (data.status) {
                var data = data.data;
                this.lc.puv[this.rn] = [];
                for (var e in data) {
                    this.lc.puv[e] = data[e];
                }
                var fromData = [];
                var toData = [];
                for (var i = 0; i < this.lc.puv[this.rn].value.length; i++) {
                    var checkd = true;
                    if (this.lc.puf[this.rn].length > 0) {
                        var checkd = this.lc.puf[this.rn].indexOf(this.lc.puv[this.rn].value[i]) >= 0 || this.lc.puf[this.rn].indexOf(this.lc.puv[this.rn].value[i].toString()) >= 0;
                    }
                    if (checkd) {
                        toData.push({
                            value: this.lc.puv[this.rn].value[i],
                            text: this.lc.puv[this.rn].text[i]
                        });
                    } else {
                        fromData.push({
                            value: this.lc.puv[this.rn].value[i],
                            text: this.lc.puv[this.rn].text[i]
                        });
                    }
                }
                Ext.getCmp(this.cid + "columns_from").store.loadData(fromData);
                Ext.getCmp(this.cid + "columns_to").store.loadData(toData);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    closeSaveOp: function () {
        this.hide();
        var selectAllValues = Ext.getCmp(this.cid + "columns_to").store.collect("value");
        selectAllValues = (selectAllValues.length == this.lc.puv[this.rn].value.length ? [] : selectAllValues);
        this.lc.puf[this.rn] = selectAllValues;
        this.callbackOk();
        this.close();
    },
    closeOp: function () {
        this.callbackAnuluj();
        this.close();
    }
});

