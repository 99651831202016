Ext.define('PanelBackupUploadSettings', {
    extend: "Ext.panel.Panel",
    layout: 'fit',
    frame: false,
    border: false,
    autoWidth: true,
    autoHeight: true,
    scrollable: false,
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            items: [{
                xtype: "checkboxexd",
                id: this.cid + "uploadenabled",
                boxLabel: Lang.przechowywanie_kopii_zapasowych_w_chmurze,
                listeners: {
                    change: function (el, newValue) {
                        this.reloadAcceptFlags(newValue)
                    },
                    scope: this
                }
            }, {
                xtype: "checkboxexd",
                id: this.cid + "processingaccepted1",
                boxLabel: new Ext.Template(Lang.wyrazam_zgode_na_regulamin).apply({cloud: Const.CLOUDFLAG ? "_cloud" : "", lang: resolveHtmlDocumentLang()}),
                hidden: true
            }, {
                xtype: "checkboxexd",
                id: this.cid + "processingaccepted2",
                boxLabel: new Ext.Template(Lang.wyrazam_zgode_na_przetwarzanie).apply({
                    cloud: Const.CLOUDFLAG ? "_cloud" : "",
                    lang: resolveHtmlDocumentLang()
                }),
                hidden: true
            }, {
                xtype: "checkboxexd",
                id: this.cid + "processingaccepted3",
                boxLabel: Lang.wyrazam_zgode_na_przekazywanie,
                hidden: true
            }]
        }]
        this.callParent(arguments);
        this.showW();
    },
    showW: function () {
        this.obiekt = null;
        sendAjaxDataJsonRpcL("rest/remotebackup/getSettings", null, this, function (data) {
            this.obiekt = data.data || {};
            Ext.getCmp(this.cid + "uploadenabled").setValue(this.obiekt.uploadEnabled);
            Ext.getCmp(this.cid + "processingaccepted1").setValue(this.obiekt.processingAccepted);
            Ext.getCmp(this.cid + "processingaccepted2").setValue(this.obiekt.processingAccepted);
            Ext.getCmp(this.cid + "processingaccepted3").setValue(this.obiekt.processingAccepted);
        });
    },
    save: function () {
        this.validate();
        var processingAccepted = (Ext.getCmp(this.cid + "processingaccepted1").getValue() && Ext.getCmp(this.cid + "processingaccepted2").getValue() && Ext.getCmp(this.cid + "processingaccepted3").getValue());
        return performAjaxLPromise("rest/remotebackup/saveSettings", {
            processingAccepted: processingAccepted,
            uploadEnabled: Ext.getCmp(this.cid + "uploadenabled").getValue()
        })
    },
    validate: function () {
        var uploadEnabled = Ext.getCmp(this.cid + "uploadenabled").getValue();
        var processingAccepted = (Ext.getCmp(this.cid + "processingaccepted1").getValue() && Ext.getCmp(this.cid + "processingaccepted2").getValue() && Ext.getCmp(this.cid + "processingaccepted3").getValue());
        if (uploadEnabled == null || processingAccepted == null || (uploadEnabled && !processingAccepted)) {
            Ext.MessageBox.alert(Lang.error, Lang.zaznacz_wszystkie_zgody);
            return false;
        } else {
            return true;
        }
    },
    reloadAcceptFlags: function (newValue) {
        Ext.getCmp(this.cid + "processingaccepted1").setVisible(newValue);
        Ext.getCmp(this.cid + "processingaccepted2").setVisible(newValue);
        Ext.getCmp(this.cid + "processingaccepted3").setVisible(newValue);
        if (!newValue) {
            Ext.getCmp(this.cid + "processingaccepted1").setValue(newValue);
            Ext.getCmp(this.cid + "processingaccepted2").setValue(newValue);
            Ext.getCmp(this.cid + "processingaccepted3").setValue(newValue);
        }
    }
});
