Ext.define('ViewSkpPomiaryAnaliza', {
    extend: 'Ext.panel.Panel',
    skppomiaryanalizapanel: null,
    id: "skppomiaryanaliza",
    showState: false,
    statics: {
        accessPropertyViewName: "access.view.skpspomiary"
    },
    cat: "p",
    text: Lang.pomiary_zbiornika_analiza,
    buttonIconCls: "iconSkpAnaliza",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.items = [{
            region: "north",
            hidden: true,
            xtype: 'toolbar',
            items: [{
                xtype: "box",
                id: this.id + "kontekstfirmybox",
                cls: "kontekstfirmy-box"
            }, {
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                style: 'display:block;margin-right:5px',
                cls: "kontekstfirmy-text"
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [{
                xtype: 'panelskppomiaryanaliza',
                id: this.id + "boxskppomiaryanaliza"
            }],
            listeners: {
                scope: this,
                resize: this.onResize
            }
        }];
        this.callParent(arguments);
        this.skppomiaryanalizapanel = Ext.getCmp(this.id + "boxskppomiaryanaliza");
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
        this.skppomiaryanalizapanel.loadZbiornikiCombo();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    changeContextRebuild: function (eFIRMACTX, eFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        if (AppFIRMACTX != null && !Const.AGGREGATEDCORE) {
            kontekstfirmy.setText(Lang.pracujesz_w_kontekscie + AppFIRMACTX.nazwa);
        } else {
            kontekstfirmy.setText("");
        }
    },
    changeContext: function (eFIRMACTX, eFIRMACTXold) {
        if (this.showState) {
            this.changeContextRebuild(null, null);
        }
        this.skppomiaryanalizapanel.loadZbiornikiCombo();
        this.skppomiaryanalizapanel.clearAll();
    },
    onResize: function (elem, width, height, oldWidth, oldHeight) {
        if (this.skppomiaryanalizapanel != null) {
            this.skppomiaryanalizapanel.setSize(width, height);
        }
    }
});
