Ext.define('WindowServiceSterownik', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.serwis_sterownika.capitalise(),
    bodyStyle: "padding:10px",
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    border: true,
    width: 500,
    height: 300,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: "panelcardexd",
            itemId: "panelcardexdown",
            items: [{
                xtype: "panel",
                layout: "fit",
                itemId: "startForm",
                flex: 1,
                items: [{
                    xtype: "form",
                    scrollable: Ext.platformTags.touch,
                    defaults: {
                        labelAlign: "top"
                    },
                    flex: 1,
                    columnWidth: 1,
                    bodyStyle: 'padding:10px 10px 100px 10px',
                    scrollable: true,
                    items: [{
                        xtype: 'textfieldexd',
                        id: this.cid + "serialnumber",
                        fieldLabel: Lang.numer_seryjny.capitalise(),
                        anchor: '0',
                        allowBlank: false,
                        maxLength: 64,
                        blankText: Lang.pole_nie_moze_byc_puste.capitalise(),
                        // maskRe: /[0-9]/,
                        // validator: function(v) {
                        //     return /^-?[0-9]*$/.test(v)? true : 'Only int format allowed!';
                        // }
                    }],
                    buttons: [{
                        text: Lang.dalej.capitalise(),
                        clickEvent: "click",
                        scope: this,
                        handler: this.performNextStep
                    }, {
                        text: Lang.zamknij.capitalise(),
                        clickEvent: "click",
                        scope: this,
                        handler: this.close
                    }]
                }]
            }, {
                xtype: "panel",
                layout: "fit",
                itemId: "resultForm",
                items: [{
                    xtype: "form",
                    scrollable: Ext.platformTags.touch,
                    defaults: {
                        labelAlign: "top"
                    },
                    columnWidth: 1,
                    bodyStyle: 'padding:10px 10px 145px 10px',
                    scrollable: true,
                    items: [{
                        xtype: 'label',
                        id: this.cid + "resultText",
                        html: ''
                    }],
                    buttons: [{
                        text: Lang.zamknij.capitalise(),
                        clickEvent: "click",
                        scope: this,
                        handler: this.close
                    }]
                }]
            }]
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex("access.manage.sterowniki") || !Const.CLOUDFLAG || AppOPERATORROLA != Const.OPERATOR_P_SERWIS) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.show();
        this.center();
        this.obiektid = obiektid;
    },
    show: function () {
        this.callParent(arguments);
    },
    afterRender: function () {
        this.callParent(arguments);
    },
    onDestroy: function () {
        this.callParent(arguments);
    },
    performNextStep: function () {
        var serialnumber = Ext.getCmp(this.cid + "serialnumber").getValue();
        var actualSterId = this.obiektid;
        if (serialnumber == null || serialnumber == '') {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        var resultText = Ext.getCmp(this.cid + "resultText");
        this.mask(Lang.przetwarzanie_danych);
        performAjaxLPromise("rest/sterowniki/isObjectAvailable", {serialnumber: serialnumber})
            .then(d => {
                if (d) {
                    return new Promise(function (resolve, reject) {
                        performAjaxLPromise("rest/sterowniki/replaceSerialNumber", {
                            id: actualSterId,
                            serialnumber: serialnumber
                        }).then(r => {
                            d = r;
                        }).catch(e => {
                            handleCommonCallBackAjaxRPCError(e);
                            d = "replaceerror";
                        }).finally(() => {
                            resolve(d);
                        });
                    });
                } else {
                    return "notexist";
                }
            })
            .then(d => {
                if (d == "notexist") {
                    resultText.setText(Lang.nie_istnieje_sterownik_o_takim_numerze_seryjnym.capitalise());
                } else if (d == "replaceerror") {
                    resultText.setText(Lang.blad_podmiana_numer_seryjny.capitalise());
                } else if (d) {
                    resultText.setText(Lang.operacja_zakonczona_poprawnie.capitalise());
                } else {
                    resultText.setText(Lang.blad.capitalise());
                }
                var layout = this.down("#panelcardexdown").getLayout();
                layout.setActiveItem("resultForm");
            })
            .catch(e => {
                handleCommonCallBackAjaxRPCError(e);
            })
            .finally(() => {
                this.unmask();
                if (this.callbackF)
                    this.callbackF();
            });
    },
    close: function () {
        this.callParent();
    }
});
