Ext.define('PanelWizardFilterTypyTankowan', {
    extend: 'Ext.panel.Panel',
    initComponent: function () {
        this.cid = Ext.id();
        Ext.apply(this, {
            layout: "fit",
            frame: false,
            border: false,
            autoWidth: false,
            autoHeight: false,
            items: [{
                xtype: 'form',
                frame: false,
                border: false,
                autoWidth: true,
                autoHeight: true,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0"
                },
                id: this.cid + "formpanel",
                labelWidth: 100,
                items: [{
                    anchor: "0",
                    layout: 'column',
                    frame: false,
                    border: false,
                    items: [{
                        columnWidth: 1.0,
                        layout: 'form',
                        labelWidth: 100,
                        defaults: {
                            anchor: "0"
                        },
                        frame: false,
                        border: false,
                        items: [{
                            id: this.cid + "TANKOWANIE_ZWYKLE",
                            value: true,
                            xtype: 'checkboxexd',
                            hideLabel: true,
                            boxLabel: LangComp.typy_tankowan[0].capitalise()
                        }, {
                            id: this.cid + "TANKOWANIE_ZALADUNEK",
                            value: true,
                            xtype: 'checkboxexd',
                            hideLabel: true,
                            hidden: !validPrivilegesOperatorComplex("access.view.zaladunki"),
                            boxLabel: LangComp.typy_tankowan[1].capitalise()
                        }, {
                            id: this.cid + "TANKOWANIE_KOREKTA",
                            value: true,
                            xtype: 'checkboxexd',
                            hideLabel: true,
                            hidden: !validPrivilegesOperatorComplex("access.view.korekty"),
                            boxLabel: LangComp.typy_tankowan[2].capitalise()
                        }, {
                            id: this.cid + "TANKOWANIE_AWARYJNE",
                            value: true,
                            xtype: 'checkboxexd',
                            hideLabel: true,
                            boxLabel: LangComp.typy_tankowan[3].capitalise()
                        }, {
                            id: this.cid + "TANKOWANIE_RECZNE",
                            value: true,
                            xtype: 'checkboxexd',
                            hideLabel: true,
                            hidden: Const.BASICFLAG || Const.STARTERFLAG,
                            boxLabel: LangComp.typy_tankowan[4].capitalise()
                        }, {
                            id: this.cid + "TANKOWANIE_USER",
                            value: true,
                            xtype: 'checkboxexd',
                            hideLabel: true,
                            boxLabel: LangComp.typy_tankowan[6].capitalise()
                        }]
                    }]
                }]
            }]
        });
        this.callParent(arguments);
    },
    callAfterShow: function () {
        var t0 = Ext.getCmp(this.cid + "TANKOWANIE_ZWYKLE");
        var t1 = Ext.getCmp(this.cid + "TANKOWANIE_ZALADUNEK");
        var t2 = Ext.getCmp(this.cid + "TANKOWANIE_KOREKTA");
        var t3 = Ext.getCmp(this.cid + "TANKOWANIE_AWARYJNE");
        var t4 = Ext.getCmp(this.cid + "TANKOWANIE_RECZNE");
        var t5 = Ext.getCmp(this.cid + "TANKOWANIE_USER");
        var bbem = this.datad.mainCtrl.repDef.typyTankowan.length == 0;
        t0.setValue(Ext.Array.contains(this.datad.mainCtrl.repDef.typyTankowan, Const.TANKOWANIE_ZWYKLE) || bbem);
        t1.setValue(Ext.Array.contains(this.datad.mainCtrl.repDef.typyTankowan, Const.TANKOWANIE_ZALADUNEK) || bbem);
        t2.setValue(Ext.Array.contains(this.datad.mainCtrl.repDef.typyTankowan, Const.TANKOWANIE_KOREKTA) || bbem);
        t3.setValue(Ext.Array.contains(this.datad.mainCtrl.repDef.typyTankowan, Const.TANKOWANIE_AWARYJNE) || bbem);
        t4.setValue(Ext.Array.contains(this.datad.mainCtrl.repDef.typyTankowan, Const.TANKOWANIE_RECZNE) || bbem);
        t5.setValue(Ext.Array.contains(this.datad.mainCtrl.repDef.typyTankowan, Const.TANKOWANIE_USER) || bbem);
        this.callAfterShowCalled = true;
    },
    callAfterHide: function () {
        if (this.callAfterShowCalled) {
            var t0 = Ext.getCmp(this.cid + "TANKOWANIE_ZWYKLE");
            var t1 = Ext.getCmp(this.cid + "TANKOWANIE_ZALADUNEK");
            var t2 = Ext.getCmp(this.cid + "TANKOWANIE_KOREKTA");
            var t3 = Ext.getCmp(this.cid + "TANKOWANIE_AWARYJNE");
            var t4 = Ext.getCmp(this.cid + "TANKOWANIE_RECZNE");
            var t5 = Ext.getCmp(this.cid + "TANKOWANIE_USER");
            this.datad.mainCtrl.repDef.typyTankowan = [];
            if (t0.getValue())
                this.datad.mainCtrl.repDef.typyTankowan.push(Const.TANKOWANIE_ZWYKLE);
            if (t1.getValue() && validPrivilegesOperatorComplex("access.view.zaladunki"))
                this.datad.mainCtrl.repDef.typyTankowan.push(Const.TANKOWANIE_ZALADUNEK);
            if (t2.getValue() && validPrivilegesOperatorComplex("access.view.korekty"))
                this.datad.mainCtrl.repDef.typyTankowan.push(Const.TANKOWANIE_KOREKTA);
            if (t3.getValue())
                this.datad.mainCtrl.repDef.typyTankowan.push(Const.TANKOWANIE_AWARYJNE);
            if (t4.getValue())
                this.datad.mainCtrl.repDef.typyTankowan.push(Const.TANKOWANIE_RECZNE);
            if (t5.getValue())
                this.datad.mainCtrl.repDef.typyTankowan.push(Const.TANKOWANIE_USER);
        }
        return null;
    },
    setData: function (datad) {
        this.datad = datad;
    }
});
