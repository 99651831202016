Ext.define('ViewKorektaMobile', {
    extend: 'Ext.panel.Panel',
    grid: null,
    gridpage: null,
    windowObject: null,
    id: "korekty",
    taskContextMenu: null,
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyName: "access.manage.korekty",
        accessPropertyViewName: "access.view.korekty",
        accessPropertyViewNameLimitOnly: "access.view.stany_limit_only"
    },
    cat: "t",
    text: Lang.korekty,
    buttonIconCls: "iconKorekta",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            itemId: 'kontekstfirmy-container',
            xtype: 'container',
            region: 'north',
            hidden: !(AppFIRMACTX != null),
            style: 'background-color: white',
            items: [{
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                cls: "kontekstfirmy-text kontekstfirmy-text-high kontekstfirmy-text-mobile"
            }]
        }, {
            region: "north",
            xtype: 'toolbar',
            scrollable: true,
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                scope: this,
                handler: this.dodajObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewKorekta.accessPropertyName + "/add"),
                cls: 'x-btn-mobile'
            }, {
                id: this.id + "bedit",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEdit",
                text: Lang.edytuj.capitalise(),
                scope: this,
                handler: this.edytujObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewKorekta.accessPropertyName + "/update"),
                cls: 'x-btn-mobile'
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
        if (this.grid && this.grid.rendered)
            this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        if (AppFIRMACTX != null) {
            kontekstfirmy.setText(AppFIRMACTX.nazwa);
        } else {
            kontekstfirmy.setText("");
        }
        this.down('#kontekstfirmy-container').setHidden(!(AppFIRMACTX != null));
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            var onwer = this.grid.ownerCt;
            Ext.suspendLayouts();
            onwer.remove(this.grid);
            this.grid.destroy();
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
            if (this.grid && this.grid.rendered)
                this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
    },
    buildGrid: function () {
        var store = Ext.create('Ext.data.Store', {
            autoLoad: false,
            remoteSort: true,
            remoteFilter: true,
            pageSize: 50,
            fields: [{
                name: 'czas',
                mapping: 'czas'
            }, {
                name: 'ile',
                mapping: 'ile',
                type: 'float'
            }, {
                name: 'plyn',
                mapping: 'plyn'
            }, {
                name: 'stanzbiornika',
                mapping: 'stanzbiornika',
                type: 'float'
            }, {
                name: 'zbiornik',
                mapping: 'zbiornik'
            }, {
                name: "userimienazwisko",
                mapping: "userimienazwisko"
            }, "opis"],
            proxy: {
                type: "ajax",
                url: "rest/zaladunekkorekty/getList",
                filterParam: "filters",
                actionMethods: {
                    read: 'POST'
                },
                extraParams: {
                    iskorekta: true
                },
                reader: {
                    type: 'json',
                    rootProperty: 'data',
                    totalProperty: 'total',
                    id: 'id'
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            sorters: {
                property: 'czas',
                direction: 'DESC'
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        var cols = [{
            header: Lang.czas,
            flex: 2,
            sortable: true,
            dataIndex: 'czas',
            filter: {
                type: 'date'
            },
            filterable: true
        }, {
            header: Lang.zbiornik,
            flex: 2,
            sortable: true,
            dataIndex: 'zbiornik',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.plyn,
            flex: 2,
            sortable: true,
            dataIndex: 'plyn',
            filter: {
                type: 'string'
            },
            filterable: true,
            hidden: true
        }, {
            header: Lang.korekta,
            flex: 2,
            sortable: true,
            dataIndex: 'ile',
            filter: {
                type: 'numeric'
            },
            filterable: true
        }];
        if (validPrivilegesOperatorComplex(ViewKorekta.accessPropertyViewNameLimitOnly) && isValidOperatorFirmaLimit()) {
            removeObjectFromArrayById(cols, "stanzbiornika");
        }
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true,
                rowexpander: {
                    rowBodyTpl: new Ext.XTemplate(
                        `<div class="mobile-rowexp-wrapper">
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.czas}:
                                <span class="mobile-rowexp-value"> {czas}</span>
                            </span>
                        </div>
                    
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.zbiornik}:
                                <span class="mobile-rowexp-value"> {zbiornik}</span>
                            </span>
                        </div>
                    
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.plyn}:
                                <span class="mobile-rowexp-value"> {plyn}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.korekta.capitalise()}:
                                <span class="mobile-rowexp-value"> {ile:this.ile}</span>
                            </span>
                        </div>
                        
                        ${
                            !(validPrivilegesOperatorComplex(ViewKorekta.accessPropertyViewNameLimitOnly) && isValidOperatorFirmaLimit()) ?
                                `<div class="mobile-rowexp-row">
                                    <span class="mobile-rowexp-title">${Lang.stan_pokorekcie}:
                                        <span class="mobile-rowexp-value"> {stanzbiornika:this.stanzbiornika}</span>
                                    </span>
                                </div>`
                                :
                                ''
                        }
                        
                    
                        <tpl if="this.notEmptyAfterTrim(userimienazwisko)">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.operator}:
                                    <span class="mobile-rowexp-value"> {userimienazwisko}</span>
                                </span>
                            </div>
                        </tpl>

                    
                        <tpl if="this.notEmptyAfterTrim(opis)">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.opis}:
                                    <span class="mobile-rowexp-value"> {opis}</span>
                                </span>
                            </div>
                        </tpl>
                    </div>`,
                        {
                            stanzbiornika: function (v) {
                                return Ext.Number.roundToPrecision(v, 2).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            ile: function (v) {
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            notEmptyAfterTrim
                        }
                    )
                }
            },
            store: store,
            columns: cols,
            forceFit: true,
            scrollable: 'y',
            frame: false,
            border: false,
            stateId: "grid-mobile" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single'
            },
            bbar: {
                xtype: 'pagetoolbarown',
                displayInfo: true,
                pagingStore: store,
                doRefresh: this.refreshDatastore.bind(this)
            }
        });
        this.grid.addListener("rowdblclick", function () {
            if (validPrivilegesOperatorComplex(ViewKorekta.accessPropertyName + "/update"))
                this.edytujObiekt();
        }, this);
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.store.addListener("load", function () {
            updateCzasRefreshSerwer();
        }, this);
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
    },
    manageSelect: function () {
        var bedit = Ext.getCmp(this.id + "bedit");
        if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelection() == null || this.grid.getSelectionModel().getSelection().length == 0) {
            bedit.setDisabled(true);
        } else {
            bedit.setDisabled(!validPrivilegesOperatorComplex(ViewKorekta.accessPropertyName + "/update"));
        }
    },
    refreshDatastore: function () {
        updateCzasRefreshSerwer();
        this.grid.store.load();
    },
    dodajObiekt: function () {
        windowObject = Ext.create('WindowKorekta', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(0);
    },
    edytujObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        var windowObject = Ext.create('WindowKorekta', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(id);
    }
});
