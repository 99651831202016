Ext.define('WindowSupercodeSterownik', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.kod_superoperatora.capitalise(),
    width: 550,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                scrollable: true,
                defaults: {
                    labelWidth: 150,
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left',
                    anchor: "0"
                },
                items: [{
                    xtype: 'radiogroupexd',
                    id: this.cid + "typeofsupercode",
                    name: "typeofsupercode",
                    baseCls: 'x-radio-column',
                    fieldLabel: Lang.generowanie_kodu.capitalise(),
                    columns: 2,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.automatyczne.capitalise(),
                        inputValue: true,
                        value: true
                    }, {
                        boxLabel: Lang.reczne.capitalise(),
                        inputValue: false
                    }],
                    listeners: {
                        scope: this,
                        change: function (radiogroup, newValue) {
                            var generujbtn = Ext.getCmp(this.cid + 'generuj');
                            generujbtn.setHidden(!newValue);
                            var newSupercodeStringField = Ext.getCmp(this.cid + 'supercodekdpstringnew');
                            newSupercodeStringField.setEditable(!newValue);
                            newSupercodeStringField.setDisabled(newValue);
                            var zapiszbtn = Ext.getCmp(this.cid + 'zapisz');
                            zapiszbtn.setHidden(newValue);
                        }
                    }
                }, {
                    id: this.cid + "supercodekdpstring",
                    name: "supercodekdpstring",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.obecny_kod,
                    allowBlank: false,
                    editable: false,
                    disabled: true,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "supercodekdpstringnew",
                    name: "supercodekdpstringnew",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.nowy_kod,
                    allowBlank: false,
                    editable: false,
                    minLength: 6,
                    maxLength: 6,
                    disabled: true,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    maskRe: /[0-9]/,
                    validator: function (v) {
                        return /^-?[0-9]*$/.test(v) ? true : 'Only int format allowed!';
                    }
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'generuj',
            text: Lang.generuj,
            scope: this,
            handler: this.generateOp
        }, {
            id: this.cid + 'zapisz',
            text: Lang.zapisz,
            scope: this,
            hidden: true,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex("access.manage.sterowniki")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.show();
        this.center();
        this.obiektid = obiektid;
        sendAjaxDataJsonRpcL("rest/sterowniki/getSupercodeKDP", {
            id: this.obiektid
        }, this, function (data) {
            if (data.status) {
                data = data.data;
                if (data != null) {
                    var supercodestring = data.supercodekdp == null ? '' : data.supercodekdp;
                    Ext.getCmp(this.cid + "supercodekdpstring").setValue(supercodestring);
                    if (data.supercodekdpnew != null && data.supercodekdpnew != '') {
                        Ext.getCmp(this.cid + "supercodekdpstringnew").setValue(data.supercodekdpnew);
                    }
                }

            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    generateOp: function () {
        sendAjaxDataJsonRpcL("rest/sterowniki/generateSupercodeKDP", {
            id: this.obiektid
        }, this, function (data) {
            if (data.status) {
                this.showW(this.obiektid);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        if (Ext.getCmp(this.cid + 'generuj').getValue()) {
            return;
        }
        if (!Ext.getCmp(this.cid + "formpanel").form.isValid()) {
            return;
        }
        sendAjaxDataJsonRpcL("rest/sterowniki/saveSupercodeKDP", {
            id: this.obiektid,
            code: Ext.getCmp(this.cid + 'supercodekdpstringnew').getValue()
        }, this, function (data) {
            if (data.status) {
                this.showW(this.obiektid);
                Ext.MessageBox.alert(Lang.operacja, Lang.operacja_zakonczona_poprawnie);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    closeOp: function () {
        this.close();
    }
});
