Ext.define('WAddEditUserTankowanieWindow', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.tankowanie.capitalise(),
    width: 450,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            scrollable: true,
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    labelWidth: 100,
                    anchor: "0",
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    id: this.cid + "odbiorca",
                    name: "odbiorca",
                    fieldLabel: Lang.odbiorca,
                    allowBlank: true,
                    xtype: "comboexd",
                    minChars: 1,
                    editable: true,
                    store: Ext.create('Ext.data.Store', {
                        fields: ['id', 'nazwa'],
                        proxy: {
                            type: 'ajax',
                            url: 'rest/odbiorcy/getForAddEditTankowanieList',
                            actionMethods: {
                                read: 'POST'
                            },
                            reader: {
                                type: 'json',
                                rootProperty: 'data'
                            }
                        },
                        autoLoad: false
                    }),
                    listeners: {
                        scope: this,
                        blur: function () {
                            if (this.identTryb == Const.IDENT_POJEDYNCZA) {
                                Ext.getCmp(this.cid + "pojazd").clearInvalid();
                            }
                        }
                    },
                    displayField: 'nazwa',
                    valueField: 'id',
                    forceSelection: true,
                    emptyText: '...'
                }, {
                    id: this.cid + "pojazd",
                    name: "pojazd",
                    fieldLabel: Lang.pojazd,
                    allowBlank: true,
                    xtype: "comboexd",
                    minChars: 1,
                    editable: true,
                    store: Ext.create('Ext.data.Store', {
                        fields: ['id', 'nazwa'],
                        proxy: {
                            type: 'ajax',
                            url: 'rest/pojazdy/getForAddEditTankowanieList',
                            actionMethods: {
                                read: 'POST'
                            },
                            reader: {
                                type: 'json',
                                rootProperty: 'data'
                            }
                        },
                        autoLoad: false
                    }),
                    listeners: {
                        scope: this,
                        blur: function () {
                            if (this.identTryb == Const.IDENT_POJEDYNCZA) {
                                Ext.getCmp(this.cid + "odbiorca").clearInvalid();
                            }
                        }
                    },
                    displayField: 'nazwa',
                    valueField: 'id',
                    forceSelection: true,
                    emptyText: '...'
                }, {
                    id: this.cid + "plyn",
                    name: "plyn",
                    fieldLabel: Lang.plyn,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    xtype: 'comboexd',
                    store: Ext.create('Ext.data.Store', {
                        fields: ['id', 'nazwa'],
                        proxy: {
                            type: 'ajax',
                            url: 'rest/plyny/getForAddEditTankowanieList',
                            actionMethods: {
                                read: 'POST'
                            },
                            reader: {
                                type: 'json',
                                rootProperty: 'data'
                            }
                        },
                        autoLoad: true
                    }),
                    displayField: 'nazwa',
                    valueField: 'id',
                    typeAheadDelay: 200,
                    forceSelection: true,
                    queryMode: 'remote',
                    triggerAction: 'all',
                    emptyText: '...'
                }, {
                    xtype: 'numberfieldexd',
                    id: this.cid + "ile",
                    name: "ile",
                    allowNegative: false,
                    decimalPrecision: 3,
                    fieldLabel: Lang.ile,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    xtype: 'numberfieldexd',
                    id: this.cid + "licznik",
                    name: "licznikexd",
                    allowDecimal: true,
                    allowNegative: false,
                    decimalPrecision: 3,
                    fieldLabel: Lang.stan_licznika,
                    allowBlank: true,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    xtype: 'numberfieldexd',
                    id: this.cid + "motogodziny",
                    name: "motogodziny",
                    allowDecimal: true,
                    allowNegative: false,
                    decimalPrecision: 3,
                    fieldLabel: Lang.motogodziny,
                    hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                    allowBlank: true,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "czas",
                    name: "czas",
                    xtype: 'datefieldexdmod',
                    fieldLabel: Lang.czas,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "opis",
                    name: "opis",
                    xtype: 'textareaexd',
                    height: 120,
                    fieldLabel: Lang.opis,
                    allowBlank: true,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    xtype: 'numberfieldexd',
                    id: this.cid + "kosztcalosc",
                    name: "kosztcalosc",
                    allowNegative: false,
                    decimalPrecision: 3,
                    fieldLabel: Lang.koszt,
                    allowBlank: true,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    hidden: !Const.COSTCOLUMNS
                }, {
                    xtype: 'numberfieldexd',
                    id: this.cid + "kosztlitr",
                    name: "kosztlitr",
                    allowNegative: false,
                    decimalPrecision: 3,
                    fieldLabel: Lang.cena_jednostkowa,
                    allowBlank: true,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    hidden: !Const.COSTCOLUMNS
                }, {
                    layout: {
                        type: 'hbox'
                    },
                    items: [{
                        xtype: "checkboxexd",
                        boxLabel: Lang.exported,
                        id: this.cid + "exported",
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                    }, {
                        xtype: "checkboxexd",
                        style: {
                            marginLeft: '15px'
                        },
                        boxLabel: Lang.isdolewkadopelna,
                        id: this.cid + "isdolewka",
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                    }]
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex('access.manage.tankowania')) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.obiektid = obiektid;
        this.obiekt = null;
        this.cidentTryb = 0;
        var parent = this;
        sendAjaxDataJsonRpcLGroup(["rest/tankowania/getObjectForEdit", "rest/systemkonf/readSysIdentContextObjectParams"], [{
            id: this.obiektid
        }, null], this, function (gstatus, data, datad, status) {
            if (gstatus) {
                this.obiekt = datad[0] || {};
                parent.identTryb = datad[1].ident_tryb;
                Ext.getCmp(this.cid + "czas").setValue(this.obiekt.czas);
                Ext.getCmp(this.cid + "plyn").setValue(this.obiekt.idplyn);
                Ext.getCmp(this.cid + "plyn").valueNotFoundText = this.obiekt.plyn;
                Ext.getCmp(this.cid + "ile").setValue(this.obiekt.ile);
                Ext.getCmp(this.cid + "licznik").setValue(this.obiekt.licznik);
                Ext.getCmp(this.cid + "motogodziny").setValue(this.obiekt.motogodziny);
                var odbiorcaStore = Ext.getCmp(this.cid + "odbiorca").getStore();
                odbiorcaStore.getProxy().setExtraParams({
                    idfirma: this.obiekt.idfirma
                });
                odbiorcaStore.load();
                Ext.getCmp(this.cid + "odbiorca").setValue(this.obiekt.idodbiorca);
                Ext.getCmp(this.cid + "odbiorca").valueNotFoundText = this.obiekt.odbiorca;
                var pojazdStore = Ext.getCmp(this.cid + "pojazd").getStore();
                pojazdStore.getProxy().setExtraParams({
                    idfirma: this.obiekt.idfirma
                });
                pojazdStore.load();
                Ext.getCmp(this.cid + "pojazd").setValue(this.obiekt.idpojazd);
                Ext.getCmp(this.cid + "pojazd").valueNotFoundText = this.obiekt.pojazd;
                Ext.getCmp(this.cid + "opis").setValue(this.obiekt.opis);
                Ext.getCmp(this.cid + "exported").setValue(this.obiekt.exported);
                Ext.getCmp(this.cid + "isdolewka").setValue(!this.obiekt.isdolewka);
                Ext.getCmp(this.cid + "kosztcalosc").setValue(this.obiekt.kosztcalosc);
                Ext.getCmp(this.cid + "kosztlitr").setValue(this.obiekt.kosztlitr);
                Ext.getCmp(this.cid + "czas").setDisabled(false);
                Ext.getCmp(this.cid + "plyn").setDisabled(false);
                Ext.getCmp(this.cid + "ile").setDisabled(false);
                Ext.getCmp(this.cid + "odbiorca").setDisabled(false);
                Ext.getCmp(this.cid + "pojazd").setDisabled(false);
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                Ext.getCmp(this.cid + "plyn").clearInvalid();
                Ext.getCmp(this.cid + "ile").clearInvalid();
                Ext.getCmp(this.cid + "czas").clearInvalid();
                Ext.getCmp(this.cid + "odbiorca").clearInvalid();
                Ext.getCmp(this.cid + "pojazd").clearInvalid();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        var pojazd = Ext.getCmp(this.cid + "pojazd").getValue2NL();
        var odbiorca = Ext.getCmp(this.cid + "odbiorca").getValue2NL();
        if (!Ext.getCmp(this.cid + 'formpanel').form.isValid() || (this.identTryb == Const.IDENT_PODWOJNA && (pojazd == null || odbiorca == null)) || (this.identTryb == Const.IDENT_POJEDYNCZA && (pojazd == null && odbiorca == null))) {
            if (odbiorca == null) {
                if (this.identTryb == Const.IDENT_PODWOJNA || (this.identTryb == Const.IDENT_POJEDYNCZA && pojazd == null))
                    Ext.getCmp(this.cid + "odbiorca").markInvalid(Lang.pole_nie_moze_byc_puste);
            }
            if (pojazd == null) {
                if (this.identTryb == Const.IDENT_PODWOJNA || (this.identTryb == Const.IDENT_POJEDYNCZA && odbiorca == null))
                    Ext.getCmp(this.cid + "pojazd").markInvalid(Lang.pole_nie_moze_byc_puste);
            }
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        this.mask();
        sendAjaxDataJsonRpcL("rest/tankowania/saveTankowanieUserObject", {
            id: this.obiektid,
            licznik: Ext.getCmp(this.cid + "licznik").getValue2(),
            motogodziny: Ext.getCmp(this.cid + "motogodziny").getValue2(),
            plyn: Ext.getCmp(this.cid + "plyn").getValue2NL(),
            ile: Ext.getCmp(this.cid + "ile").getValue(),
            czas: Ext.getCmp(this.cid + "czas").getValue2(),
            odbiorca: odbiorca,
            pojazd: pojazd,
            opis: Ext.getCmp(this.cid + "opis").getValue(),
            exported: Ext.getCmp(this.cid + "exported").getValue(),
            isdolewka: !Ext.getCmp(this.cid + "isdolewka").getValue(),
            kosztcalosc: Ext.getCmp(this.cid + "kosztcalosc").getValue2(),
            kosztlitr: Ext.getCmp(this.cid + "kosztlitr").getValue2()
        }, this, function (data) {
            this.unmask();
            if (data.status) {
                var data = data.data;
                this.fireEvent("wclose");
                this.closeOp();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        }, 0);
    },
    closeOp: function () {
        this.close();
    }
});

