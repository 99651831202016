Ext.define('WindowRozszerzenia', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.rozszerzenie.capitalise(),
    width: 800,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: 'fit',
    changeSelectrozszerzenie_wewnetrzne: function () {
        Ext.getCmp(this.cid + "adres").setDisabled(Ext.getCmp(this.cid + "rozszerzenie_wewnetrzne").getValue2());
        Ext.getCmp(this.cid + "tryb_baterii").setDisabled(Ext.getCmp(this.cid + "rozszerzenie_wewnetrzne").getValue2());
        if (Ext.getCmp(this.cid + "rozszerzenie_wewnetrzne").getValue2() && Ext.getCmp(this.cid + "tryb_baterii").getValue2()) {
            Ext.getCmp(this.cid + "tryb_baterii").setValue2(false);
        }
    },
    initComponent: function () {
        this.cid = Ext.id();
        let formpanel = {
            title: Lang.ogolne.capitalise(),
            xtype: 'form',
            id: this.cid + 'formpanel1',
            bodyStyle: 'padding:10px 10px 10px 10px',
            frame: true,
            autoWidth: true,
            scrollable: true,
            defaults: {
                anchor: "0"
            },
            items: [{
                anchor: "0",
                layout: 'column',
                border: false,
                items: [{
                    columnWidth: WindowExdMobileDEVICEV ? 1 : .5,
                    border: false,
                    layout: 'anchor',
                    labelWidth: 110,
                    defaults: {
                        anchor: "-10",
                        labelWidth: 200,
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: [{
                        id: this.cid + "nazwa",
                        name: "nazwa",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.nazwa,
                        allowBlank: false,
                        maxLength: 16,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        disabled: isValidOperatorFirmaLimit()
                    }, {
                        id: this.cid + "idsterownik",
                        xtype: 'comboexd',
                        fieldLabel: Lang.sterownik,
                        valueField: 'id',
                        displayField: 'nazwadsp',
                        store: new Ext.data.JsonStore({
                            id: "id",
                            root: '',
                            sorters: [{
                                property: "nazwadsp",
                                direction: "ASC"
                            }],
                            fields: [{
                                name: 'id',
                                mapping: 'id'
                            }, {
                                name: 'nazwa',
                                mapping: 'nazwa'
                            }, {
                                name: 'nazwadsp',
                                mapping: 'nazwadsp'
                            }]
                        }),
                        triggerAction: 'all',
                        resizable: false,
                        editable: false,
                        mode: "local",
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        disabled: isValidOperatorFirmaLimit()
                    }, {
                        xtype: 'radiogroupexd',
                        id: this.cid + "active",
                        name: "active",
                        baseCls: 'x-radio-column',
                        fieldLabel: Lang.active,
                        vertical: false,
                        items: [{
                            boxLabel: Lang.tak.capitalise(),
                            inputValue: true,
                            value: true
                        }, {
                            boxLabel: Lang.nie.capitalise(),
                            inputValue: false
                        }]
                    }, {
                        xtype: 'radiogroupexd',
                        id: this.cid + "tryb_baterii",
                        name: "tryb_baterii",
                        baseCls: 'x-radio-column',
                        fieldLabel: Lang.praca_w_trybie_baterii_zbiornikow,
                        labelStyle: 'word-break: break-all; overflow-wrap: break-word;',
                        vertical: false,
                        hidden: Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                        items: [{
                            boxLabel: Lang.tak.capitalise(),
                            inputValue: true
                        }, {
                            boxLabel: Lang.nie.capitalise(),
                            inputValue: false,
                            value: true
                        }]
                    }]
                }]
            }]
        };
        let formitems = [{
            xtype: 'radiogroupexd',
            id: this.cid + "rozszerzenie_wewnetrzne",
            name: "rozszerzenie_wewnetrzne",
            baseCls: 'x-radio-column',
            fieldLabel: Lang.rozszerzenie_wewnetrzne,
            labelStyle: 'word-break: break-all; overflow-wrap: break-word;',
            vertical: false,
            hidden: Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
            items: [{
                boxLabel: Lang.tak.capitalise(),
                inputValue: true,
                value: true
            }, {
                boxLabel: Lang.nie.capitalise(),
                inputValue: false
            }],
            listeners: {
                scope: this,
                change: this.changeSelectrozszerzenie_wewnetrzne
            }
        }, {
            id: this.cid + "adres",
            name: "adres",
            xtype: 'numberfieldexd',
            minValue: 1,
            maxValue: 62,
            fieldLabel: Lang.adres_can,
            allowBlank: false,
            blankText: Lang.pole_nie_moze_byc_puste
        }, {
            id: this.cid + "devid",
            name: "devid",
            xtype: 'textfieldexd',
            fieldLabel: Lang.typ,
            disabled: true,
            cls: "x-item-disabled-sec"
        }, {
            id: this.cid + "version",
            name: "version",
            xtype: 'textfieldexd',
            fieldLabel: Lang.wersja,
            disabled: true,
            cls: "x-item-disabled-sec"
        }];
        if (WindowExdMobileDEVICEV)
            formpanel.items[0].items[0].items = [
                ...formpanel.items[0].items[0].items,
                ...formitems
            ];
        else
            formpanel.items[0].items.push({
                columnWidth: .5,
                layout: 'anchor',
                labelWidth: 110,
                border: false,
                defaults: {
                    anchor: "0",
                    labelWidth: 200
                },
                items: [...formitems]
            });
        this.items = [{
            xtype: 'tabpanel',
            autoWidth: true,
            height: 260,
            border: false,
            activeTab: 0,
            items: [formpanel, {
                title: Lang.sloty.capitalise(),
                id: this.cid + "panel2",
                xtype: "panel",
                layout: 'fit',
                frame: false,
                autoWidth: true,
                border: false,
                items: [{
                    xtype: 'gridpanelexd',
                    border: false,
                    autoWidth: true,
                    tbar: [{
                        text: Lang.nowy,
                        id: this.cid + "nowy",
                        iconCls: 'iconNew',
                        handler: function () {
                            var slotnumerused = [];
                            var store = Ext.getCmp(this.cid + "slotygrid").store;
                            for (var i = 0; i < store.getCount(); i++) {
                                var r = store.getAt(i);
                                var slotnumer = r.get("slotnumer");
                                slotnumerused.push(slotnumer);
                            }
                            var www = Ext.create('WindowRozszerzeniaSlot', {
                                obiekt: null,
                                slotnumerused: slotnumerused,
                                parentObj: this
                            });
                            www.showW();
                            www.on("closeok", function () {
                                var selROn = www.obiekt;
                                var slotnumer = www.obiekt.slotnumer;
                                this.slotydatas[slotnumer] = selROn;
                                var grid = Ext.getCmp(this.cid + "slotygrid");
                                var rpp = {
                                    slotnumer: www.obiekt.slotnumer,
                                    nazwa: www.obiekt.nazwa,
                                    zbiornik: www.obiekt.zbiornik,
                                    vorder: www.obiekt.vorder
                                };
                                grid.store.add(rpp);
                                if (Const.BASICFLAG || Const.STANDARDFLAG || Const.OPTIMALFLAG || Const.STARTERFLAG) {
                                    var grid = Ext.getCmp(this.cid + "slotygrid");
                                    Ext.getCmp(this.cid + "nowy").setDisabled(grid.store.getCount() >= 1);
                                }
                            }, this);
                        },
                        scope: this,
                        disabled: isValidOperatorFirmaLimit()
                    }, {
                        text: Lang.edytuj,
                        iconCls: 'iconEdit',
                        handler: function () {
                            var selR = Ext.getCmp(this.cid + "slotygrid").getSelR();
                            if (selR != null) {
                                var slotnumerOld = selR.get("slotnumer");
                                var slotnumerused = [];
                                var store = Ext.getCmp(this.cid + "slotygrid").store;
                                for (var i = 0; i < store.getCount(); i++) {
                                    var r = store.getAt(i);
                                    var slotnumer = r.get("slotnumer");
                                    if (slotnumer != slotnumerOld)
                                        slotnumerused.push(slotnumer);
                                }
                                var ooos = this.slotydatas[slotnumerOld];
                                ooos.slotnumer = slotnumerOld;
                                var www = Ext.create('WindowRozszerzeniaSlot', {
                                    obiekt: ooos,
                                    slotnumerused: slotnumerused,
                                    parentObj: this
                                });
                                www.showW();
                                www.on("closeok", function () {
                                    delete this.slotydatas[slotnumerOld];
                                    var selROn = www.obiekt;
                                    var slotnumer = www.obiekt.slotnumer;
                                    this.slotydatas[slotnumer] = selROn;
                                    selR.set("zbiornik", www.obiekt.zbiornik);
                                    selR.set("nazwa", www.obiekt.nazwa);
                                    selR.set("slotnumer", www.obiekt.slotnumer);
                                    selR.set("vorder", www.obiekt.vorder);
                                }, this);
                            }
                        },
                        scope: this
                    }, {
                        text: Lang.usun,
                        iconCls: 'iconDelete',
                        handler: function () {
                            var selR = Ext.getCmp(this.cid + "slotygrid").getSelR();
                            if (selR != null) {
                                Ext.getCmp(this.cid + "slotygrid").store.remove(selR);
                                var slotnumer = selR.get("slotnumer");
                                delete this.slotydatas[slotnumer];
                                if (Const.BASICFLAG || Const.STANDARDFLAG || Const.OPTIMALFLAG || Const.STARTERFLAG) {
                                    var grid = Ext.getCmp(this.cid + "slotygrid");
                                    Ext.getCmp(this.cid + "nowy").setDisabled(grid.store.getCount() >= 1);
                                }
                            }
                        },
                        scope: this,
                        disabled: isValidOperatorFirmaLimit()
                    }],
                    store: Ext.create('Ext.data.JsonStore', {
                        autoLoad: false,
                        proxy: {
                            type: 'memory',
                            reader: {
                                type: 'json'
                            }
                        },
                        fields: ['slotnumer', 'nazwa', 'zbiornik', 'vorder']
                    }),
                    columns: [{
                        header: Lang.slot,
                        flex: 1,
                        sortable: false,
                        dataIndex: 'slotnumer'
                    }, {
                        header: Lang.nazwa,
                        flex: 2,
                        sortable: false,
                        dataIndex: 'nazwa'
                    }, {
                        header: Lang.zbiornik,
                        flex: 2,
                        sortable: false,
                        dataIndex: 'zbiornik'
                    }, {
                        header: Lang.kolejnosc,
                        flex: 2,
                        sortable: false,
                        dataIndex: 'vorder'
                    }],
                    frame: true,
                    border: true,
                    id: this.cid + "slotygrid",
                    stateId: null,
                    stateful: false,
                    stripeRows: true,
                    enableHdMenu: false,
                    selModel: {
                        selType: 'rowmodel',
                        mode: 'multi'
                    },
                    listeners: {
                        scope: this,
                        rowdblclick: function (component, record, element, rowIndex, event, eventOptions) {
                            var selR = record;
                            if (selR == null)
                                return;
                            var slotnumerOld = selR.get("slotnumer");
                            var slotnumerused = [];
                            var store = Ext.getCmp(this.cid + "slotygrid").store;
                            for (var i = 0; i < store.getCount(); i++) {
                                var r = store.getAt(i);
                                var slotnumer = r.get("slotnumer");
                                if (slotnumer != slotnumerOld)
                                    slotnumerused.push(slotnumer);
                            }
                            var ooos = this.slotydatas[slotnumerOld];
                            ooos.slotnumer = slotnumerOld;
                            var www = Ext.create('WindowRozszerzeniaSlot', {
                                obiekt: ooos,
                                slotnumerused: slotnumerused,
                                parentObj: this
                            });
                            www.showW();
                            www.on("closeok", function () {
                                delete this.slotydatas[slotnumerOld];
                                var selROn = www.obiekt;
                                var slotnumer = www.obiekt.slotnumer;
                                this.slotydatas[slotnumer] = selROn;
                                selR.set("zbiornik", www.obiekt.zbiornik);
                                selR.set("nazwa", www.obiekt.nazwa);
                                selR.set("slotnumer", www.obiekt.slotnumer);
                                selR.set("vorder", www.obiekt.vorder);
                                Ext.getCmp(this.cid + 'slotygrid').getStore().commitChanges();
                            }, this);
                        }
                    },
                    getSelR: function () {
                        var gridd = this;
                        if (!gridd.getSelectionModel().hasSelection()) {
                            return null;
                        } else {
                            if (gridd.getSelectionModel().getSelected() != null) {
                                return gridd.getSelection()[0];
                            }
                        }
                        return null;
                    }
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid, klonowanie) {
        if (!validPrivilegesOperatorComplex("access.manage.rozszerzenia")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.klonowanie = klonowanie || false;
        this.obiektid = obiektid;
        this.obiekt = null;
        sendAjaxDataJsonRpcL("rest/rozszerzenia/getObjectForEdit", {
            id: this.obiektid
        }, this, function (data) {
            if (data.status) {
                this.obiekt = data.data;
                sendAjaxDataJsonRpcL("rest/sterowniki/getList", {
                    idinclude: this.obiekt != null ? this.obiekt.idsterownik : 0
                }, this, function (data) {
                    if (data.status) {
                        var listFnData = data.data;
                        this.show();
                        this.center();
                        this.setKeyMap({
                            'ESC': {
                                scope: this,
                                handler: this.closeOp
                            }
                        });
                        Ext.getCmp(this.cid + "idsterownik").getStore().loadData(listFnData);
                        if (this.obiekt != null) {
                            if (this.klonowanie) {
                                this.obiekt.nazwa += (this.obiektid + 1);
                            }
                            this.slotydatas = this.obiekt.slotydatas;
                            var grid = Ext.getCmp(this.cid + "slotygrid");
                            var aslotydatas = [];
                            for (var slotnumer in this.slotydatas) {
                                aslotydatas.push({
                                    slotnumer: slotnumer,
                                    nazwa: this.slotydatas[slotnumer].nazwa,
                                    zbiornik: this.slotydatas[slotnumer].zbiornik,
                                    vorder: this.slotydatas[slotnumer].vorder
                                });
                            }
                            grid.store.loadData(aslotydatas);
                            Ext.getCmp(this.cid + "nazwa").setValue(this.obiekt.nazwa);
                            Ext.getCmp(this.cid + "active").setValue2(this.obiekt.active);
                            Ext.getCmp(this.cid + "adres").setValue(this.obiekt.adres);
                            Ext.getCmp(this.cid + "rozszerzenie_wewnetrzne").setValue2(this.obiekt.adres == 0);
                            Ext.getCmp(this.cid + "tryb_baterii").setValue2(this.obiekt.trybbateria);
                            Ext.getCmp(this.cid + "adres").clearInvalid();
                            this.changeSelectrozszerzenie_wewnetrzne();
                            Ext.getCmp(this.cid + "version").setValue(this.obiekt.version);
                            Ext.getCmp(this.cid + "devid").setValue(this.obiekt.devid);
                            Ext.getCmp(this.cid + "idsterownik").setValue2(this.obiekt.idsterownik);
                            if (this.klonowanie) {
                                this.obiektid = 0;
                                this.obiekt = null;
                            }
                        } else {
                            this.slotydatas = {};
                            Ext.getCmp(this.cid + "slotygrid").store.removeAll();
                            Ext.getCmp(this.cid + "active").setValue2(true);
                            Ext.getCmp(this.cid + "active").clearInvalid();
                            Ext.getCmp(this.cid + "nazwa").setValue(null);
                            Ext.getCmp(this.cid + "nazwa").clearInvalid();
                            Ext.getCmp(this.cid + "adres").setValue(1);
                            Ext.getCmp(this.cid + "adres").clearInvalid();
                            Ext.getCmp(this.cid + "tryb_baterii").setValue2(false);
                            Ext.getCmp(this.cid + "rozszerzenie_wewnetrzne").setValue2(true);
                            Ext.getCmp(this.cid + "version").setValue(null);
                            Ext.getCmp(this.cid + "version").clearInvalid();
                            Ext.getCmp(this.cid + "devid").setValue(null);
                            Ext.getCmp(this.cid + "devid").clearInvalid();
                            Ext.getCmp(this.cid + "idsterownik").setValue2(null);
                            Ext.getCmp(this.cid + "idsterownik").clearInvalid();
                            this.changeSelectrozszerzenie_wewnetrzne();
                            if (Const.BASICFLAG || Const.STANDARDFLAG || Const.OPTIMALFLAG || Const.STARTERFLAG) {
                                var grid = Ext.getCmp(this.cid + "slotygrid");
                                Ext.getCmp(this.cid + "nowy").setDisabled(grid.store.getCount() >= 1);
                            }
                        }
                    } else {
                        Ext.MessageBox.alert(Lang.error, data.error);
                    }
                });
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        var canWrite = false;
        var slotydatas = {};
        var str = Ext.getCmp(this.cid + "slotygrid").store;
        for (var i = 0; i < str.getCount(); i++) {
            var r = str.getAt(i);
            var slotnumer = r.get("slotnumer");
            slotydatas[slotnumer] = this.slotydatas[slotnumer];
        }
        if (Ext.getCmp(this.cid + 'formpanel1').form.isValid() && str.getCount() > 0) {
            canWrite = true;
        }
        if (canWrite) {
            sendAjaxDataJsonRpcL("rest/rozszerzenia/saveObject", {
                id: this.obiektid,
                nazwa: Ext.getCmp(this.cid + "nazwa").getValue(),
                adres: Ext.getCmp(this.cid + "rozszerzenie_wewnetrzne").getValue2() ? 0 : Ext.getCmp(this.cid + "adres").getValue2(),
                active: Ext.getCmp(this.cid + "active").getValue2(),
                idsterownik: Ext.getCmp(this.cid + "idsterownik").getValue(),
                trybbateria: Ext.getCmp(this.cid + "tryb_baterii").getValue(),
                data: {
                    slotydatas: slotydatas
                },
                optlockversion: this.obiekt == null ? null : this.obiekt.optlockversion
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    this.obiektid = data.id;
                    this.closeOp();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
                if (this.callbackF)
                    this.callbackF();
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});

