window.renderBool2 = (val) => {
    return '' + '<div style="text-align:center;height: 18px;width: 18px;overflow:visible;display: block;margin-right: auto;margin-left: auto;"' + (val ? ' class="x-form-cb-checked"' : "") + '> <div class="x-form-checkbox-default"/>' + '</div>';
};

window.renderBool3 = (val) => {
    return '' + '<div class="iconDivColValueRenderBool' + (val ? " x-form-cb-checked" : "") + '"> <div class="x-form-checkbox-default"/>' + '</div>';
};

window.renderBool4 = (val) => {
    return '' + '<span class="iconDivColValueRenderBool mobile' + (val ? " x-form-cb-checked" : "") + '"> <span class="x-form-checkbox-default"/>' + '</span>';
};

window.renderBoolIsSerwisant = (val) => {
    var res = val == 0 ? true : false;
    return '' + '<div class="iconDivColValueRenderBool' + (res ? " x-form-cb-checked" : "") + '"> <div class="x-form-checkbox-default"/>' + '</div>';
};

window.isValidOperatorFirmaLimit = () => {
    return (AppOPERATORFIRMA != null && !Const.CLOUDFLAG)
}

window.isValidOperatorFirmaRaw = () => {
    return (AppOPERATORFIRMA != null)
}

window.validPrivilegesOperatorComplex = (accessNameRecordOpFull) => {
    return AppOPERATOR.privileges[accessNameRecordOpFull] == null ? false : AppOPERATOR.privileges[accessNameRecordOpFull];
}
