Ext.define('WindowGroupsAddEdit', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.grupa.capitalise(),
    width: 400,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                scrollable: true,
                defaults: {
                    labelWidth: 80,
                    anchor: "0"
                },
                items: [{
                    id: this.cid + "nazwa",
                    name: "nazwa",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.nazwa,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        if (this.type == Const.GROUP_TYPE_ODBIORCA) {
            this.title = Lang.grupa + ' (' + Lang.odbiorcy.capitalise() + ')';
        } else if (this.type == Const.GROUP_TYPE_POJAZD) {
            this.title = Lang.grupa + ' (' + Lang.pojazdy.capitalise() + ')';
        }
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex('access.manage.grupy')) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.obiektid = obiektid;
        this.obiekt = null;
        sendAjaxDataJsonRpcL("rest/grupy/getObjectForEdit", {
            id: obiektid
        }, this, function (data) {
            if (data.status) {
                this.obiekt = data.data;
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                if (this.obiekt != null) {
                    Ext.getCmp(this.cid + "nazwa").setValue(this.obiekt.nazwa);
                }
            }
        });
    },
    saveOp: function () {
        if (!Ext.getCmp(this.cid + 'formpanel').form.isValid()) {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        sendAjaxDataJsonRpcL("rest/grupy/saveObject", {
            id: this.obiektid,
            nazwa: Ext.getCmp(this.cid + "nazwa").getValue(),
            optlockversion: this.obiekt != null ? this.obiekt.optlockversion : null,
            type: this.type
        }, this, function (data) {
            if (data.status) {
                var data = data.data;
                this.fireEvent("wclose");
                this.closeOp();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    closeOp: function () {
        this.close();
    }
});

