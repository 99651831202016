Ext.define('ViewTankowaniaMobile', {
    extend: 'Ext.panel.Panel',
    grid: null,
    gridpage: null,
    id: "tankowania",
    taskContextMenu: null,
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyName: "access.manage.tankowania",
        accessPropertyViewName: "access.view.tankowania",
        accessPropertyViewNameLimitOnly: "access.view.stany_limit_only",
        accessPropertyViewNameSterowniki: "access.view.sterowniki"
    },
    cat: "p",
    text: Lang.tankowania,
    buttonIconCls: "iconTankowanie",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            itemId: 'kontekstfirmy-container',
            xtype: 'container',
            region: 'north',
            hidden: !(AppFIRMACTX != null),
            style: 'background-color: white',
            items: [{
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                cls: "kontekstfirmy-text kontekstfirmy-text-high kontekstfirmy-text-mobile"
            }]
        }, {
            region: "north",
            xtype: 'toolbar',
            scrollable: true,
            items: [{
                id: this.id + "bnew",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                scope: this,
                handler: this.dodajObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/add"),
                hidden: Const.STARTERFLAG,
                cls: 'x-btn-mobile'
            }, {
                id: this.id + "bedit",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEdit",
                text: Lang.edytuj.capitalise(),
                scope: this,
                handler: this.edytujObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/update"),
                hidden: Const.STARTERFLAG,
                cls: 'x-btn-mobile'
            }, {
                id: this.id + "bdelete",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconDelete",
                text: Lang.usun.capitalise(),
                scope: this,
                handler: this.usunObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/delete"),
                cls: 'x-btn-mobile'
            }, '-', {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.callParent(arguments);
        this.showState = true;
        this.changeContextRebuild(null, null);
        if (this.grid && this.grid.rendered)
            if (this.grid.getDockedComponent(1))
                this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        if (AppFIRMACTX != null) {
            kontekstfirmy.setText(AppFIRMACTX.nazwa);
        } else {
            kontekstfirmy.setText("");
        }
        this.down('#kontekstfirmy-container').setHidden(!(AppFIRMACTX != null));
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            var onwer = this.grid.ownerCt;
            Ext.suspendLayouts();
            onwer.remove(this.grid);
            this.grid.destroy();
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
            if (this.grid && this.grid.rendered)
                if (this.grid.getDockedComponent(1))
                    this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
    },
    dodajObiekt: function () {
        windowObject = Ext.create('WAddEditUserTankowanieWindow');
        windowObject.on("wclose", function () {
            this.refreshDatastore();
        }, this);
        windowObject.showW(0);
    },
    edytujObiekt: function () {
        var id = 0;
        var typ = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
                typ = this.rowContext.get("typ");
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
                typ = this.grid.getSelectionModel().getSelection()[0].get("typ");
            }
        }
        if (typ != 6) {
            var windowObject = Ext.create('WEditLicznikStateTankowanieWindow');
            windowObject.on("wclose", function () {
                this.refreshDatastore();
            }, this);
            windowObject.showW(id);
        } else {
            windowObject = Ext.create('WAddEditUserTankowanieWindow');
            windowObject.on("wclose", function () {
                this.refreshDatastore();
            }, this);
            windowObject.showW(id);
        }
    },
    usunObiekt: function () {
        var id = 0;
        var typ = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
                typ = this.rowContext.get("typ");
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
                typ = this.grid.getSelectionModel().getSelection()[0].get("typ");
            }
        }
        Ext.MessageBox.show({
            title: Lang.usuwanie,
            message: Lang.czy_napewno_usunac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/tankowania/deleteObject", {
                        id: id
                    }, this, function (data) {
                        if (data.status) {
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                        this.refreshDatastore();
                    });
                }
            }
        });
    },
    refreshDatastore: function () {
        updateCzasRefreshSerwer();
        this.grid.store.load();
    },
    buildGrid: function (datals) {
        var store = Ext.create('Ext.data.ArrayStore', {
            autoLoad: false,
            remoteSort: true,
            remoteFilter: true,
            pageSize: 50,
            fields: [{
                name: 'czas',
                mapping: 'czas'
            }, {
                name: 'ile',
                mapping: 'ile',
                type: 'float'
            }, {
                name: 'gestoscoperacji',
                mapping: 'gestoscoperacji'
            }, {
                name: 'gestoscsrednia',
                mapping: 'gestoscsrednia'
            }, {
                name: 'temperaturasrednia',
                mapping: 'temperaturasrednia'
            }, {
                name: 'ilebezkompensacji',
                mapping: 'ilebezkompensacji'
            }, {
                name: 'licznik',
                mapping: 'licznik'
            }, {
                name: 'motogodziny',
                mapping: 'motogodziny'
            }, {
                name: 'licznikorg',
                mapping: 'licznikorg'
            }, {
                name: 'motogodzinyorg',
                mapping: 'motogodzinyorg'
            }, {
                name: 'sterownik',
                mapping: 'sterownik'
            }, {
                name: 'plyn',
                mapping: 'plyn'
            }, {
                name: 'zbiornik',
                mapping: 'zbiornik'
            }, {
                name: 'pojazd',
                mapping: 'pojazd'
            }, {
                name: 'firma',
                mapping: 'firma'
            }, {
                name: 'odbiorca',
                mapping: 'odbiorca'
            }, {
                name: 'kodawaryjnereczne',
                mapping: 'kodawaryjnereczne'
            }, {
                name: 'user',
                mapping: 'user'
            }, {
                name: 'typ',
                mapping: 'typ'
            }, {
                name: 'dystanssum',
                mapping: 'dystanssum'
            }, {
                name: 'czaspracysum',
                mapping: 'czaspracysum'
            }, {
                name: 'zuzycie100',
                mapping: 'zuzycie100'
            }, {
                name: 'zuzycie1h',
                mapping: 'zuzycie1h'
            }, {
                name: 'typ',
                mapping: 'typ'
            }, {
                name: 'srctyp',
                mapping: 'srctyp'
            }, {
                name: 'opis',
                mapping: 'opis'
            }, {
                name: 'stanzbiornika',
                mapping: 'stanzbiornika',
                useNull: true,
                type: 'float'
            }, {
                name: 'kosztcalosc',
                mapping: 'kosztcalosc'
            }, {
                name: 'kosztlitr',
                mapping: 'kosztlitr'
            }, {
                name: 'rzeczubytek',
                mapping: 'rzeczubytek'
            }, {
                name: 'liczniktotal',
                mapping: 'liczniktotal'
            }, {
                name: 'odbiorcagrupa',
                mapping: 'odbiorcagrupa'
            }, {
                name: 'pojazdgrupa',
                mapping: 'pojazdgrupa'
            }, "exported", "isdolewka", "id", "stateflags"],
            proxy: {
                type: "ajax",
                url: "rest/tankowania/getList",
                filterParam: "filters",
                actionMethods: {
                    read: 'POST'
                },
                reader: {
                    type: 'json',
                    rootProperty: 'data',
                    totalProperty: 'total',
                    id: 'id'
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            sorters: {
                property: 'czas',
                direction: 'DESC'
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        var cols = [{
            header: Lang.czas,
            flex: 2,
            sortable: true,
            dataIndex: 'czas',
            filter: {
                type: 'date'
            },
            filterable: true
        }, {
            header: Lang.plyn,
            flex: 1,
            sortable: true,
            dataIndex: 'plyn',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.firma,
            flex: 2,
            sortable: true,
            dataIndex: 'firma',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.zbiornik,
            flex: 1,
            sortable: true,
            hidden: true,
            dataIndex: 'zbiornik',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.pojazd,
            flex: 1,
            sortable: true,
            hidden: true,
            dataIndex: 'pojazd',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.odbiorca,
            flex: 1,
            sortable: true,
            hidden: true,
            dataIndex: 'odbiorca',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.ile,
            flex: 1,
            sortable: true,
            dataIndex: 'ile',
            renderer: function (v) {
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            filterable: true
        }];
        if (Const.STARTERFLAG) {
            removeObjectFromArrayById(cols, "user");
            removeObjectFromArrayById(cols, "typ");
            removeObjectFromArrayById(cols, "odbiorca");
            removeObjectFromArrayById(cols, "licznik");
        }
        if (!Const.ENTERPRISEFLAG) {
            removeObjectFromArrayById(cols, 'firma');
        }
        if (Const.OPTIMALFLAG) {
            removeObjectFromArrayById(cols, "stateflags");
        }
        if (Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG) {
            removeObjectFromArrayById(cols, "czaspracysum");
            removeObjectFromArrayById(cols, "zuzycie1h");
            removeObjectFromArrayById(cols, "motogodziny");
            removeObjectFromArrayById(cols, "temperaturasrednia");
            removeObjectFromArrayById(cols, "ilebezkompensacji");
            removeObjectFromArrayById(cols, "stateflags");
            removeObjectFromArrayById(cols, "exported");
            removeObjectFromArrayById(cols, "isdolewka");
        }
        if ((Const.BASICFLAG && Const.CUSTOMMODFLAG != 'swimer') || Const.STARTERFLAG) {
            removeObjectFromArrayById(cols, "dystanssum");
            removeObjectFromArrayById(cols, "zuzycie100");
        }
        if (validPrivilegesOperatorComplex(ViewTankowania.accessPropertyViewNameLimitOnly) && isValidOperatorFirmaLimit()) {
            removeObjectFromArrayById(cols, "stanzbiornika");
        }
        if (!validPrivilegesOperatorComplex(ViewTankowania.accessPropertyViewNameSterowniki)) {
            removeObjectFromArrayById(cols, "sterownik");
        }
        if (!Const.COSTCOLUMNS) {
            removeObjectFromArrayById(cols, "kosztcalosc");
            removeObjectFromArrayById(cols, "kosztlitr");
        }
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true,
                rowexpander: {
                    rowBodyTpl: new Ext.XTemplate(
                        `<div class="mobile-rowexp-wrapper">

                        ${
                            Const.ENTERPRISEFLAG ?
                                `<div class="mobile-rowexp-row">
                                    <span class="mobile-rowexp-title">${Lang.firma}:
                                        <span class="mobile-rowexp-value"> {firma}</span>
                                    </span>
                                </div>
                                
                                <div class="mobile-rowexp-row">
                                    <span class="mobile-rowexp-title">${Lang.czas}:
                                        <span class="mobile-rowexp-value"> {czas}</span>
                                    </span>
                                </div>`
                                :
                                `<div class="mobile-rowexp-row">
                                    <span class="mobile-rowexp-title">${Lang.czas}:
                                        <span class="mobile-rowexp-value"> {czas}</span>
                                    </span>
                                </div>`
                        }

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.typ}:
                                <span class="mobile-rowexp-value"> {typ:this.typ}</span>
                            </span>
                        </div>
                    
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.plyn}:
                                <span class="mobile-rowexp-value"> {plyn}</span>
                            </span>
                        </div>

                        <tpl if="zbiornik != null">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.zbiornik}:
                                    <span class="mobile-rowexp-value"> {zbiornik}</span>
                                </span>
                            </div>
                        </tpl>

                        ${
                            Const.OPTIMALFLAG || Const.PROFLAG || Const.ENTERPRISEFLAG ?
                                `<tpl if="sterownik != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.sterownik}:
                                            <span class="mobile-rowexp-value"> {sterownik}</span>
                                        </span>
                                    </div>
                                </tpl>`
                                :
                                ''
                        }
                    
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.ile}:
                                <span class="mobile-rowexp-value"> {ile:this.ile}</span>
                            </span>
                        </div>

                        ${
                            Const.OPTIMALFLAG || Const.PROFLAG || Const.ENTERPRISEFLAG ?
                                `<tpl if="ilebezkompensacji != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.ilosc_nieskompensowana}:
                                            <span class="mobile-rowexp-value"> {ilebezkompensacji:this.ilebezkompensacji}</span>
                                        </span>
                                    </div>
                                </tpl>
                                
                                <tpl if="temperaturasrednia != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.temperatura_srednia}:
                                            <span class="mobile-rowexp-value"> {temperaturasrednia:this.temperaturasrednia}</span>
                                        </span>
                                    </div>
                                </tpl>`
                                :
                                ''
                        }

                        <tpl if="this.notEmptyAfterTrim(odbiorca)">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.odbiorca}:
                                    <span class="mobile-rowexp-value"> {odbiorca}</span>
                                </span>
                            </div>
                        </tpl>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.pojazd}:
                                <span class="mobile-rowexp-value"> {pojazd}</span>
                            </span>
                        </div>

                        <tpl if="this.notEmptyAfterTrim(opis)">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.opis}:
                                    <span class="mobile-rowexp-value"> {opis}</span>
                                </span>
                            </div>
                        </tpl>

                        ${
                            !(validPrivilegesOperatorComplex(ViewTankowania.accessPropertyViewNameLimitOnly) && isValidOperatorFirmaLimit()) ?
                                `<div class="mobile-rowexp-row">
                                    <span class="mobile-rowexp-title">${Lang.stanzbiornika}:
                                        <span class="mobile-rowexp-value"> {stanzbiornika:this.stanzbiornika}</span>
                                    </span>
                                </div>`
                                :
                                ''
                        }

                        ${
                            (Const.BASICFLAG && Const.CUSTOMMODFLAG != 'swimer') || Const.STARTERFLAG ?
                                ''
                                :
                                `<tpl if="dystanssum != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.dystans}:
                                            <span class="mobile-rowexp-value"> {dystanssum:this.dystanssum}</span>
                                        </span>
                                    </div>
                                </tpl>`
                        }

                        <tpl if="licznik != null">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.licznik}:
                                    <span class="mobile-rowexp-value"> {licznik:this.licznik}</span>
                                </span>
                            </div>
                        </tpl>

                        ${
                            validPrivilegesOperatorComplex(ViewSkpPomiary.accessPropertyViewName) && (Const.OPTIMALFLAG || Const.PROFLAG || Const.ENTERPRISEFLAG) ?
                                `<tpl if="rzeczubytek != null">
                                <div class="mobile-rowexp-row">
                                    <span class="mobile-rowexp-title">${Lang.rzeczywisty_ubytek}:
                                        <span class="mobile-rowexp-value"> {rzeczubytek:this.rzeczubytek}</span>
                                    </span>
                                </div>
                            </tpl>` : ''
                        }

                        ${
                            AppOPERATORROLA == Const.OPERATOR_P_SERWIS && (Const.OPTIMALFLAG || Const.PROFLAG || Const.ENTERPRISEFLAG) ?
                                `<tpl if="liczniktotal != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.licznik_total}:
                                            <span class="mobile-rowexp-value"> {liczniktotal:this.liczniktotal}</span>
                                        </span>
                                    </div>
                            </tpl>` : ''
                        }

                        <tpl if="odbiorcagrupa != null && this.notEmptyAfterTrim(odbiorcagrupa)">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.odbiorca_grupa}:
                                    <span class="mobile-rowexp-value"> {odbiorcagrupa}</span>
                                </span>
                            </div>
                        </tpl>
                        
                        <tpl if="pojazdgrupa != null && this.notEmptyAfterTrim(pojazdgrupa)">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.pojazd_grupa}:
                                    <span class="mobile-rowexp-value"> {pojazdgrupa}</span>
                                </span>
                            </div>
                        </tpl>
                        
                        ${
                            (Const.BASICFLAG && Const.CUSTOMMODFLAG != 'swimer') || Const.STARTERFLAG ?
                                ''
                                :
                                `<tpl if="zuzycie100 != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.zuzycie100}:
                                            <span class="mobile-rowexp-value"> {zuzycie100:this.zuzycie100}</span>
                                        </span>
                                    </div>
                                </tpl>`
                        }

                        ${
                            Const.OPTIMALFLAG || Const.PROFLAG || Const.ENTERPRISEFLAG ?
                                `<tpl if="zuzycie1h != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.zuzycie1h}:
                                            <span class="mobile-rowexp-value"> {zuzycie1h:this.zuzycie1h}</span>
                                        </span>
                                    </div>
                                </tpl>
                                
                                <tpl if="czaspracysum != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.czaspracy}:
                                            <span class="mobile-rowexp-value"> {czaspracysum:this.czaspracysum}</span>
                                        </span>
                                    </div>
                                </tpl>`
                                :
                                ''
                        }

                        ${
                            Const.OPTIMALFLAG || Const.PROFLAG || Const.ENTERPRISEFLAG ?
                                `<tpl if="motogodziny != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.motogodziny}:
                                            <span class="mobile-rowexp-value"> {motogodziny:this.motogodziny}</span>
                                        </span>
                                    </div>
                                </tpl>`
                                :
                                ''
                        }

                        ${
                            Const.COSTCOLUMNS ?
                                `<tpl if="kosztlitr != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.cena_jednostkowa}:
                                            <span class="mobile-rowexp-value"> {kosztlitr:this.kosztlitr}</span>
                                        </span>
                                    </div>
                                </tpl>
                                <tpl if="kosztcalosc != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.koszt}:
                                            <span class="mobile-rowexp-value"> {kosztcalosc:this.kosztcalosc}</span>
                                        </span>
                                    </div>
                                </tpl>`
                                :
                                ''
                        }   

                        ${
                            Const.PROFLAG || Const.ENTERPRISEFLAG ?
                                `<tpl if="stateflags != null">
                                    <div class="mobile-rowexp-row">
                                        <span class="mobile-rowexp-title">${Lang.stan_tankowania}:
                                            <span class="mobile-rowexp-value"> {stateflags}</span>
                                        </span>
                                    </div>
                                </tpl>`
                                :
                                ''

                        }

                        ${
                            Const.OPTIMALFLAG || Const.PROFLAG || Const.ENTERPRISEFLAG ?
                                `<div class="mobile-rowexp-row">
                                    <span class="mobile-rowexp-title">${Lang.isdolewkadopelna}:</span>
                                    {isdolewka:this.isdolewka}
                                </div>`
                                :
                                ''
                        }

                    </div>`,
                        {
                            typ: function (v) {
                                return LangComp.typy_tankowan[v].capitalise()
                            },
                            stanzbiornika: function (v) {
                                return Ext.Number.roundToPrecision(v, 2).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            ile: function (v) {
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            ilebezkompensacji: function (v) {
                                if (v == null)
                                    return null;
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            temperaturasrednia: function (v) {
                                if (v == null)
                                    return null;
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            licznik: function (v) {
                                if (v == null)
                                    return null;
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            liczniktotal: function (v) {
                                if (v == null)
                                    return null;
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            rzeczubytek: function (v) {
                                if (v == null)
                                    return null;
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            dystanssum: function (v) {
                                if (v == null)
                                    return null;
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            motogodziny: function (v) {
                                if (v == null)
                                    return null;
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            czaspracysum: function (v) {
                                if (v == null)
                                    return null;
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            isdolewka: renderBool4,
                            zuzycie100: function (v) {
                                return v == null ? "" : Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            zuzycie1h: function (v) {
                                return v == null ? "" : Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            kosztlitr: function (v) {
                                return v == null ? '' : Ext.Number.toFixed(v, 2).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            kosztcalosc: function (v) {
                                return v == null ? '' : Ext.Number.toFixed(v, 2).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            },
                            notEmptyAfterTrim
                        }
                    )
                }
            },
            store: store,
            columns: cols,
            forceFit: true,
            scrollable: 'y',
            frame: false,
            border: false,
            stateId: "grid-mobile" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            disableSelection: false,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            },
            bbar: {
                xtype: 'pagetoolbarown',
                displayInfo: true,
                pagingStore: store,
                doRefresh: this.refreshDatastore.bind(this)
            }
        });
        this.grid.addListener("rowdblclick", function () {
            if (validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/update"))
                this.edytujObiekt();
        }, this);
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.store.addListener("load", function () {
            updateCzasRefreshSerwer();
        }, this);
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
    },
    manageSelect: function () {
        var bedit = Ext.getCmp(this.id + "bedit");
        var bdelete = Ext.getCmp(this.id + "bdelete");
        if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelection() == null) {
            bedit.setDisabled(true);
            bdelete.setDisabled(true);
        } else {
            var row = this.grid.getSelectionModel().getSelection()[0];
            bedit.setDisabled(!validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/update"));
            bdelete.setDisabled(!validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/delete"));
        }
        var roww = row || this.rowContext;
        var bnew = Ext.getCmp(this.id + "bnew");
        bnew.setDisabled(((Const.ENTERPRISEFLAG || Const.CLOUDFLAG) && AppFIRMACTX == null) || !validPrivilegesOperatorComplex(ViewTankowania.accessPropertyName + "/add"));
        if (roww != null) {
            var typ = roww.get("typ");
            if (typ == Const.TANKOWANIE_USER) {
                bdelete.setDisabled(bdelete.disabled || false);
            } else {
                bdelete.setDisabled(true);
            }
        }
        if (Const.STARTERFLAG) {
            bnew.setVisible(false);
            bedit.setVisible(false);
            bdelete.setVisible(false);
        }
    }
});
