window.WindowCheckIdentCardaccessPropertyViewName = "access.view.testident";
Ext.define('WindowCheckIdentCard', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.test_identyfikatora.capitalise(),
    width: 400,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: {
        type: 'vbox',
        align: 'stretch'
    },
    initComponent: function (config) {
        this.cid = Ext.id();
        this.items = [{
            id: this.cid + 'identpanel',
            xtype: 'panel',
            bodyStyle: {
                padding: '10px 0 10px 20px',
                background: 'rgb(241, 241, 241)'
            },
            frame: false,
            border: false,
            height: 40,
            width: '100%',
            hidden: true,
            items: [{
                xtype: 'displayfield',
                id: this.cid + "identyfikator",
                fieldLabel: Lang.identyfikator
            }]
        }, {
            xtype: 'panel',
            layout: 'column',
            bodyStyle: {
                padding: '10px 10px 10px 10px',
                background: 'rgb(241, 241, 241)'
            },
            frame: false,
            border: false,
            height: 240,
            width: '100%',
            items: [{
                columnWidth: 0.85,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: {
                    padding: '10px 10px 10px 10px',
                    background: 'transparent'
                },
                frame: false,
                border: false,
                items: [{
                    xtype: 'panel',
                    frame: false,
                    border: false,
                    id: this.cid + "header",
                    bodyStyle: {
                        background: 'transparent'
                    }
                }, {
                    anchor: "0",
                    layout: "column",
                    border: false,
                    frame: false,
                    defaults: {
                        anchor: "0"
                    },
                    bodyStyle: {
                        background: 'transparent'
                    },
                    items: [{
                        columnWidth: 1,
                        border: false,
                        frame: false,
                        layout: "form",
                        defaults: {
                            anchor: "-10",
                            labelCls: 'x-checkident-label-extendedmargin'
                        },
                        bodyStyle: {
                            background: 'transparent'
                        },
                        items: [{
                            xtype: 'displayfield',
                            id: this.cid + "field1"
                        }, {
                            xtype: 'displayfield',
                            id: this.cid + "field2"
                        }, {
                            xtype: 'displayfield',
                            id: this.cid + "field3"
                        }, {
                            xtype: 'displayfield',
                            id: this.cid + "field4"
                        }]
                    }]
                }]
            }, {
                columnWidth: 0.15,
                xtype: 'panel',
                bodyStyle: {
                    background: 'transparent'
                },
                id: this.cid + 'icon',
                height: 48,
                frame: false,
                border: false
            }]
        }, {
            xtype: 'testidentyfikatorpanel',
            frame: false,
            border: false,
            id: this.cid + 'readerpanel'
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.idzdo,
            scope: this,
            handler: this.goToOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex("access.view.testident")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.readerPanel = Ext.getCmp(this.cid + 'readerpanel');
        sendAjaxDataJsonRpcL("rest/systemkonf/readSysIdentContextObjectParams", null, this, function (data) {
            if (data.status) {
                data = data.data;
                ident_pojazd = data.ident_pojazd;
                ident_odbiorca = data.ident_odbiorca;
                ident_operator = data.ident_operator;
                var addFkod = ident_operator == Const.IDENT_KODRECZNY || ident_odbiorca == Const.IDENT_KODRECZNY || ident_pojazd == Const.IDENT_KODRECZNY;
                if (addFkod) {
                    this.add({
                        xtype: 'panel',
                        layout: 'form',
                        frame: false,
                        border: false,
                        bodyStyle: {
                            padding: '10px 10px 10px 10px',
                            background: 'rgb(241, 241, 241)'
                        },
                        items: [{
                            id: this.cid + 'fkod',
                            fieldLabel: Lang.kod,
                            xtype: 'textfieldexd',
                            listeners: {
                                scope: this,
                                change: this.onFkodChange
                            }
                        }]
                    });
                }
                if (addFkod)
                    Ext.getCmp(this.cid + 'fkod').setValue(null);
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                Ext.getCmp(this.cid + "icon").body.setStyle({
                    width: '48px',
                    backgroundRepeat: 'no-repeat'
                });
                this.readerPanel.init();
                this.readerPanel.on('cardrecognized', this.loadObjectData, this);
                this.readerPanel.on('cardnotrecognized', this.loadIdentyfikator, this);
                this.readerPanel.on('identpull', this.onIdentPull, this);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    loadIdentyfikator: function (data) {
        if (Config.enablemanualassignidentyfiaktor == 1) {
            Ext.getCmp(this.cid + 'identpanel').show();
            Ext.getCmp(this.cid + 'identyfikator').setValue(data.identyfikator);
        }
    },
    loadObjectData: function (data) {
        if (data.goto) {
            Ext.getCmp(this.cid + 'save').show();
        } else {
            Ext.getCmp(this.cid + 'save').hide()
        }
        if (Config.enablemanualassignidentyfiaktor == 1) {
            Ext.getCmp(this.cid + 'identpanel').show();
            Ext.getCmp(this.cid + 'identyfikator').setValue(data.identyfikator);
        }
        if (data.ops != null) {
            Ext.getCmp(this.cid + "icon").body.setCls('iconOperator');
            Ext.getCmp(this.cid + "header").setHtml({
                cls: "x-checkident-card-title-panel-exd",
                html: Lang.operator.capitalise()
            });
            Ext.getCmp(this.cid + "field1").setFieldLabel(Lang.imie);
            Ext.getCmp(this.cid + 'field1').setValue(data.ops.imie);
            Ext.getCmp(this.cid + "field2").setFieldLabel(Lang.nazwisko);
            Ext.getCmp(this.cid + 'field2').setValue(data.ops.nazwisko);
            Ext.getCmp(this.cid + "field3").setFieldLabel(Lang.login);
            Ext.getCmp(this.cid + 'field3').setValue(data.ops.login);
            Ext.getCmp(this.cid + "field1").show();
            Ext.getCmp(this.cid + 'field2').show();
            Ext.getCmp(this.cid + "field3").show();
            if (Const.ENTERPRISEFLAG) {
                Ext.getCmp(this.cid + "field4").setFieldLabel(Lang.firma);
                Ext.getCmp(this.cid + 'field4').setValue(data.ops.firma);
                Ext.getCmp(this.cid + "field4").show();
            }
            this.objectId = data.ops.id;
            this.objectt = 'operator';
        } else if (data.ods != null) {
            Ext.getCmp(this.cid + "icon").body.setCls('iconOdbiorca');
            Ext.getCmp(this.cid + "header").setHtml({
                cls: "x-checkident-card-title-panel-exd",
                html: Lang.odbiorca.capitalise()
            });
            Ext.getCmp(this.cid + "field1").setFieldLabel(Lang.imie);
            Ext.getCmp(this.cid + 'field1').setValue(data.ods.imie);
            Ext.getCmp(this.cid + "field2").setFieldLabel(Lang.nazwisko);
            Ext.getCmp(this.cid + 'field2').setValue(data.ods.nazwisko);
            Ext.getCmp(this.cid + "field1").show();
            Ext.getCmp(this.cid + 'field2').show();
            if (Const.ENTERPRISEFLAG) {
                Ext.getCmp(this.cid + "field3").setFieldLabel(Lang.firma);
                Ext.getCmp(this.cid + 'field3').setValue(data.ods.firma);
                Ext.getCmp(this.cid + "field3").show();
            }
            this.objectId = data.ods.id;
            this.objectt = 'odbiorca';
        } else if (data.pjs != null) {
            Ext.getCmp(this.cid + "icon").body.setCls('iconPojazd');
            Ext.getCmp(this.cid + "header").setHtml({
                cls: "x-checkident-card-title-panel-exd",
                html: Lang.pojazd.capitalise()
            });
            Ext.getCmp(this.cid + "field1").setFieldLabel(Lang.nazwa);
            Ext.getCmp(this.cid + 'field1').setValue(data.pjs.nazwa);
            Ext.getCmp(this.cid + "field1").show();
            if (Const.ENTERPRISEFLAG) {
                Ext.getCmp(this.cid + "field2").setFieldLabel(Lang.firma);
                Ext.getCmp(this.cid + 'field2').setValue(data.pjs.firma);
                Ext.getCmp(this.cid + "field2").show();
            }
            this.objectId = data.pjs.id;
            this.objectt = 'pojazd';
        }
    },
    onIdentPull: function () {
        Ext.getCmp(this.cid + "icon").body.setCls('');
        Ext.getCmp(this.cid + "header").setHtml('');
        Ext.getCmp(this.cid + "field1").hide();
        Ext.getCmp(this.cid + "field2").hide();
        Ext.getCmp(this.cid + "field3").hide();
        Ext.getCmp(this.cid + "field4").hide();
        Ext.getCmp(this.cid + "identpanel").hide();
    },
    onFkodChange: function (component, newValue, oldValue) {
        this.readerPanel.onIdentPull();
        if (!newValue || newValue.trim().length == 0)
            return;
        sendAjaxDataJsonRpcL("rest/systemkonf/getObjectWithIdentOwner", {
            identyfikator: newValue,
            identtypeisreczna: true
        }, this, function (data) {
            if (data.status) {
                data = data.data;
                objectId = 0;
                objectt = null;
                if (data == null) {
                    data = {};
                    data.operator = null;
                    data.pojazd = null;
                    data.odbiorca = null;
                }
                data.ops = data.operator;
                data.pjs = data.pojazd;
                data.ods = data.odbiorca;
                if (data.ops != null || data.ods != null || data.pjs != null) {
                    this.readerPanel.setCommunicationState("x-czytnik-ikonLogin", '');
                    this.readerPanel.fireEvent('cardrecognized', data);
                } else {
                    this.readerPanel.setCommunicationState("x-czytnik-iconLoginError", Lang.identyfikator_nieznany);
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
                this.readerPanel.setCommunicationState("x-czytnik-iconLoginError");
            }
        });
    },
    goToOp: function () {
        this.readerPanel.close();
        this.hide();
        if (this.objectt == 'operator') {
            var windowObject = Ext.create('WindowOperator');
            windowObject.showW(this.objectId);
        }
        if (this.objectt == 'odbiorca') {
            var windowObject = Ext.create('WindowOdbiorcy');
            windowObject.showW(this.objectId);
        }
        if (this.objectt == 'pojazd') {
            var windowObject = Ext.create('WindowPojazdy');
            windowObject.showW(this.objectId);
        }
        this.closeOp();
    },
    closeOp: function () {
        this.readerPanel.close();
        this.close();
    }
});
