Ext.define('WindowBackupRestoreFile', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.odtworz_kopie_zapasowa_z_pliku.capitalise(),
    width: 350,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    scrollable: true,
    initComponent: function (config) {
        this.cid = Ext.id();
        this.items = [{
            id: this.cid + "formpanel",
            xtype: 'form',
            frame: true,
            border: false,
            autoWidth: true,
            autoHeight: true,
            labelWidth: 80,
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0",
                labelWidth: 80
            },
            fileUpload: true,
            items: [{
                xtype: 'filefieldexd',
                id: this.cid + 'file',
                name: 'file',
                fieldLabel: Lang.plik,
                allowBlank: false,
                blankText: Lang.pole_nie_moze_byc_puste,
                ensureExtensions: ["." + "bkp"],
                ensureExtensionsValidErrorMsg: Lang.wymagane_rozszerzenie + " " + ["." + "bkp"].toString() + "!",
                validator: Ext.ux.form.FileUploadField.prototype.defaultValidatorExtension
            }, {
                id: this.cid + "name",
                name: "name",
                xtype: 'textfield',
                fieldLabel: Lang.nazwa,
                allowBlank: false,
                blankText: Lang.pole_nie_moze_byc_puste
            }, {
                id: this.cid + "opis",
                name: "opis",
                xtype: 'textarea',
                height: 120,
                fieldLabel: Lang.opis
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid, klonowanie) {
        if (!validPrivilegesOperatorComplex("access.manage.backup")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
    },
    saveOp: function () {
        var canWrite = false;
        if (Ext.getCmp(this.cid + "formpanel").form.isValid()) {
            canWrite = true;
        }
        if (canWrite) {
            Ext.getCmp(this.cid + 'save').setDisabled(true);
            sendAjaxFormJsonRpcL("rest/backup/doUpload", {
                name: Ext.getCmp(this.cid + "name").getValue(),
                opis: Ext.getCmp(this.cid + "opis").getValue()
            }, Ext.getCmp(this.cid + "formpanel").form, this, function (data) {
                Ext.getCmp(this.cid + 'save').setDisabled(false);
                if (data.status) {
                    data = data.data;
                    Ext.TaskManager.start(this.task);
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.msg);
                }
            });
        } else {
            Ext.getCmp(this.cid + 'save').setDisabled(false);
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});
