Ext.define('WindowOperatorCurrentChange', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.operator.capitalise(),
    width: 900,
    height: 625,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: !WindowExdMobileDEVICEV,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'tabpanel',
            autoWidth: true,
            border: false,
            deferredRender: false,
            anchor: '0',
            activeTab: 0,
            scrollable: !WindowExdMobileDEVICEV,
            defaults: {
                scrollable: true
            },
            items: [{
                title: Lang.dane_dostepu,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    labelWidth: 150,
                    anchor: "0",
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    id: this.cid + "currpass",
                    name: "currpass",
                    xtype: 'textfield',
                    inputType: 'password',
                    allowBlank: true,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    fieldLabel: Lang.obecne_haslo
                }, {
                    id: this.cid + "changepassword",
                    xtype: "checkboxexd",
                    checked: false,
                    hideLabel: true,
                    boxLabel: Lang.zmien_haslo,
                    itemCls: "operatorcurrentchange-small-top-margin",
                    listeners: {
                        change: function (el, newValue, oldValue, options) {
                            Ext.getCmp(this.cid + "pass").setDisabled(!newValue);
                            Ext.getCmp(this.cid + "pass2").setDisabled(!newValue);
                            var currPassCmp = Ext.getCmp(this.cid + "currpass");
                            currPassCmp.allowBlank = !newValue && !Ext.getCmp(this.cid + "changelogin").getValue2();
                            currPassCmp.validate();
                        },
                        scope: this
                    }
                }, {
                    id: this.cid + "pass",
                    name: "pass",
                    xtype: 'textfield',
                    inputType: 'password',
                    allowBlank: false,
                    disabled: true,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    fieldLabel: Lang.haslo,
                    cls: "x-item-disabled-sec"
                }, {
                    id: this.cid + "pass2",
                    name: "pass2",
                    xtype: 'textfield',
                    inputType: 'password',
                    allowBlank: false,
                    disabled: true,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    fieldLabel: Lang.powtorz_haslo,
                    cls: "x-item-disabled-sec"
                }, {
                    id: this.cid + "changelogin",
                    xtype: "checkboxexd",
                    hideLabel: true,
                    boxLabel: Const.CLOUDFLAG ? Lang.zmien_email : Lang.zmien_login,
                    itemCls: "operatorcurrentchange-small-top-margin",
                    listeners: {
                        change: function (el, newValue, oldValue, options) {
                            Ext.getCmp(this.cid + "login").setDisabled(!newValue);
                            var currPassCmp = Ext.getCmp(this.cid + "currpass");
                            currPassCmp.allowBlank = !newValue && !Ext.getCmp(this.cid + "changepassword").getValue2();
                            currPassCmp.validate();
                        },
                        scope: this
                    }
                }, {
                    id: this.cid + "login",
                    name: "login",
                    xtype: 'textfield',
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    fieldLabel: Const.CLOUDFLAG ? Lang.email : Lang.login,
                    disabled: true,
                    cls: "x-item-disabled-sec",
                    regex: Const.CLOUDFLAG ? /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ : null,
                    invalidText: Const.CLOUDFLAG ? Lang.niepoprawny_email : null
                }]
            }, {
                title: Lang.powiadomienia_email,
                frame: false,
                border: false,
                autoWidth: true,
                scrollable: true,
                autoHeight: false,
                autoDestroy: false,
                layout: 'fit',
                hidden: Const.CLOUDFLAG && AppOPERATORFIRMA == null,
                id: this.cid + 'emailtab',
                items: [Ext.create('PanelOperatorEmailConfig', {
                    bodyStyle: 'padding:10px 10px 10px 10px',
                    id: this.cid + 'emailconfig'
                })]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function () {
        Ext.getCmp(this.cid + "login").setValue(AppOPERATOR.login);
        sendAjaxDataJsonRpcL("rest/operators/getCurrentLogged", null, this, function (data) {
            this.show();
            this.center();
            this.setKeyMap({
                'ESC': {
                    scope: this,
                    handler: this.closeOp
                }
            });
            var obiekt = data.data;
            var firma = null;
            if (obiekt && obiekt.firma) {
                firma = obiekt.firma;
            }
            sendAjaxDataJsonRpcL("rest/zbiorniki/getList", {
                firma: firma
            }, this, function (zbdata) {
                if (zbdata.status) {
                    if (obiekt && obiekt.notificationConfig) {
                        Ext.getCmp(this.cid + "emailconfig").loadNotificationConfig(obiekt.notificationConfig, zbdata.data);
                    } else {
                        Ext.getCmp(this.cid + "emailconfig").loadZbiornikData(zbdata.data, []);
                    }
                } else {
                    Ext.MessageBox.alert(Lang.error, zbdata.error);
                }
            });
            if (obiekt.notificationParams) {
                Ext.getCmp(this.cid + "emailconfig").loadNotificationParams(obiekt.notificationParams);
            }
        });
    },
    saveOp: function () {
        if (!Ext.getCmp(this.cid + 'formpanel').form.isValid()) {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        var pass = null,
            pass2 = null,
            login = null;
        var currpass = Ext.getCmp(this.cid + "currpass").getValue().trim();
        if (currpass == "" && (Ext.getCmp(this.cid + "changepassword").getValue() || Ext.getCmp(this.cid + "changelogin").getValue())) {
            Ext.getCmp(this.cid + "currpass").markInvalid(Lang.pole_nie_moze_byc_puste);
            return;
        }
        if (Ext.getCmp(this.cid + "changepassword").getValue()) {
            pass = Ext.getCmp(this.cid + "pass").getValue().trim();
            pass2 = Ext.getCmp(this.cid + "pass2").getValue().trim();
            if (pass == "" || pass2 == "" || pass != pass2) {
                Ext.getCmp(this.cid + "pass").markInvalid(Lang.puste_haslo_lub_bledna_weryfikacja);
                Ext.getCmp(this.cid + "pass2").markInvalid(Lang.puste_haslo_lub_bledna_weryfikacja);
                return;
            }
        }
        if (Ext.getCmp(this.cid + "changelogin").getValue()) {
            login = Ext.getCmp(this.cid + "login").getValue().trim();
            if (login == "") {
                Ext.getCmp(this.cid + "login").markInvalid(Lang.pole_nie_moze_byc_puste);
                return;
            }
        }
        var emailconfig = Ext.getCmp(this.cid + "emailconfig").generateNotificationConfig();
        if (typeof (emailconfig) == "string") {
            Ext.MessageBox.alert(Lang.error, emailconfig);
            return;
        }
        sendAjaxDataJsonRpcL("rest/operators/saveCurrentLogged", {
            pass: pass,
            currpass: currpass,
            login: login,
            emailconfig: emailconfig
        }, this, function (data) {
            if (data.status) {
                data = data.data;
                AppOPERATOR.login = data;
                this.closeOp();
                Ext.MessageBox.alert("", Lang.operacjawykonana);

            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }

        });
    },
    closeOp: function () {
        this.close();
    }
});

