Ext.define('PanelStanCzytnikaExd', {
    extend: 'Ext.panel.Panel',
    alias: "widget.panelstanczytnikaexd",
    layout: {
        type: 'hbox',
        align: 'center'
    },
    bodyStyle: {
        background: '#f1f1f1',
        padding: '5px'
    },
    frame: true,
    items: [{
        xtype: 'displayfield',
        itemId: "komunikatybox",
        value: "",
        flex: 1,
        fieldStyle: "text-align:center"
    }, {
        xtype: "box",
        itemId: "pastylkaident",
        cls: "x-czytnik-card-panel-card",
        userCls: 'x-czytnik-iconCzytnikError'
    }],
    setCommunicationState: function (ikonIdentCls, message) {
        if (this && this.items && this.down("#pastylkaident")) {
            this.down("#pastylkaident").setUserCls(ikonIdentCls);
            if (message !== null) {
                var komunikat = this.down("#komunikatybox").setValue(message);
            }
        }
    },
    onOk: function () {
        this.setCommunicationState("x-czytnik-ikonCard", Lang.prosze_przylozyc_karte);
    },
    onCommError: function () {
        this.setCommunicationState("x-czytnik-iconCzytnikError", Lang.czytnik_error_comm);
    },
    onIdentPut: function (czytnik, karta) {
        if (karta.ident != null) {
            sendAjaxDataJsonRpcL("rest/systemkonf/checkIsCanAssignIdentToObject", {
                id: this.obiektid,
                objecttype: this.objectt,
                identyfikator: karta.ident,
                identtype: this.oidenttype
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    if (data.canassign) {
                        if (data.haskod) {
                            this.setCommunicationState("x-czytnik-ikonLogin", Lang.identyfikator_przydzielony_poprawnie);
                            this.oident = karta.ident;
                            //managerCzytnikPastylka.un("identput", this.onIdentPut, this);
                            //managerCzytnikPastylka.un("identpull", this.onIdentPull, this);
                            this.fireEvent('iassign', karta.ident);
                            return;
                        }
                    } else {
                        this.setCommunicationState("x-czytnik-iconLoginError", Lang.identyfikator_nie_moze_zostac_przydzielony);
                        return;
                    }

                    function resultwindowKod(btn, text) {
                        if (btn == "ok") {
                            sendAjaxDataJsonRpcL("rest/systemkonf/checkIdentKodObject", {
                                identyfikator: karta.ident,
                                kod: text
                            }, this, function (data) {
                                if (data.status) {
                                    data = data.data;
                                    if (data) {
                                        this.setCommunicationState("x-czytnik-ikonLogin", Lang.identyfikator_przydzielony_poprawnie);
                                        this.oident = karta.ident;
                                        //managerCzytnikPastylka.un("identput", this.onIdentPut);
                                        //managerCzytnikPastylka.un("identpull", this.onIdentPull);
                                        this.fireEvent('iassign', karta.ident);

                                    }
                                } else {
                                    Ext.MessageBox.alert(Lang.error, data.error);
                                    this.setCommunicationState("x-czytnik-iconLoginError", null);

                                }
                            });
                        } else if (btn == "yes") {
                            Ext.create('WindowIdentKods').showW();
                        } else {
                            this.setCommunicationState("x-czytnik-iconLoginError", Lang.identyfikator_nie_moze_zostac_przydzielony);

                        }
                    }

                    if (data.msg) {
                        Ext.MessageBox.alert(Lang.identyfikator, data.msg, function () {
                            Ext.MessageBox.show({
                                title: Lang.identyfikator,
                                message: Lang.wprowadz_kod_dla_identyfikatora + " (" + karta.ident + ")" + ":",
                                buttons: Ext.MessageBox.OK + Ext.MessageBox.YES + Ext.MessageBox.CANCEL,
                                buttonText: {
                                    yes: Lang.aktualizuj_baze_identyfikatorow
                                },
                                fn: resultwindowKod,
                                scope: this,
                                prompt: true
                            });
                        }, this);
                    } else {
                        Ext.MessageBox.show({
                            title: Lang.identyfikator,
                            message: Lang.wprowadz_kod_dla_identyfikatora + " (" + karta.ident + ")" + ":",
                            buttons: Ext.MessageBox.OK + Ext.MessageBox.YES + Ext.MessageBox.CANCEL,
                            buttonText: {
                                yes: Lang.aktualizuj_baze_identyfikatorow
                            },
                            fn: resultwindowKod,
                            scope: this,
                            prompt: true
                        });
                    }
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                    this.setCommunicationState("x-czytnik-iconLoginError", Lang.identyfikator_nie_moze_zostac_przydzielony);
                }
            });
        }
    },
    onIdentPull: function (czytnik) {
        this.setCommunicationState("x-czytnik-ikonCard", Lang.prosze_przylozyc_karte);
    },
    onNoService: function () {
        this.setCommunicationState("x-czytnik-iconCzytnikError", checkIfHttpsRenderCzytnikFixBtn());
    },
    onIncorrectVersion: function () {
        this.setCommunicationState("x-czytnik-iconCzytnikError", Lang.czytnik_zla_wersja_oprogramowania);
    },
    onNotOpen: function () {
        this.setCommunicationState("x-czytnik-iconCzytnikError", Lang.czytnik_open_error);
    },
    getIdentifier: function () {
        return this.oident;
    },
    initSocket: function (params) {
        if (params.oidenttype == null) {
            switch (this.objectt) {
                case 'operator':
                    this.ident_object = params.sysidentparams.ident_operator;
                    break;
                case 'odbiorca':
                    this.ident_object = params.sysidentparams.ident_odbiorca;
                    break;
                case 'pojazd':
                    this.ident_object = params.sysidentparams.ident_pojazd;
                    break;
            }
            if (this.ident_object == Const.IDENT_ELEKTRONICZNA || this.ident_object == Const.IDENT_ELEKTRONICZNAPIN) {
                managerCzytnikPastylka.init();
                managerCzytnikPastylka.on({
                    scope: this,
                    ok: this.onOk,
                    commerror: this.onCommError,
                    identput: this.onIdentPut,
                    identpull: this.onIdentPull,
                    noservice: this.onNoService,
                    incorrectversion: this.onIncorrectVersion,
                    notopen: this.onNotOpen
                });
                this.setCommunicationState('x-czytnik-ikonCard', Lang.czytnik_oczekiwanie_na_polaczenie);
            }
            if (this.ident_object == Const.IDENT_ELEKTRONICZNA || this.ident_object == Const.IDENT_KODRECZNY)
                this.opin = null;
            if (this.oidenttype == null)
                this.oidenttype = Const.IDENT_ELEKTRONICZNA;
            if ((this.ident_object == Const.IDENT_ELEKTRONICZNA || this.ident_object == Const.IDENT_ELEKTRONICZNAPIN) && this.oidenttype == Const.IDENT_KODRECZNY) {
                oident = null;
            }
            if ((this.oidenttype == Const.IDENT_ELEKTRONICZNA || this.oidenttype == Const.IDENT_ELEKTRONICZNAPIN) && this.ident_object == Const.IDENT_KODRECZNY) {
                oident = null;
            }
            this.oidenttype = this.ident_object;
            if (this.oidenttype == Const.IDENT_KODRECZNY && params.identautogenvalue && this.oident == null) {
                this.oident = params.identautogenvalue;
            }
        } else {
            this.oidenttype = params.oidenttype;
        }
    },
    init: function (params) {
        Ext.apply(this, params);
        Ext.apply(this.params, params);
        sendAjaxDataJsonRpcL("rest/systemkonf/readSysIdentContextObjectParams", {
            id: params.obiektid,
            objectt: params.objectt
        }, this, function (data) {
            if (data.status) {
                data = data.data;
                params.sysidentparams = data;
                if (Const.CLOUDFLAG || data.ident_autohandgen) {
                    sendAjaxDataJsonRpcL("rest/systemkonf/obtainAutoGeneratedIdentKodReczny", null, this, function (data) {
                        if (data.status) {
                            data = data.data;
                            params.identautogenvalue = data;
                            this.initSocket(params);
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                    });
                } else {
                    this.initSocket(params);
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    close: function () {
        managerCzytnikPastylka.close();
    }
});
