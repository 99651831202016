Ext.define('PanelWizardFilterFiltrLista', {
    extend: 'Ext.panel.Panel',
    initComponent: function () {
        this.cid = Ext.id();
        Ext.apply(this, {
            layout: "fit",
            frame: false,
            height: 400,
            border: false,
            autoWidth: false,
            autoHeight: false,
            items: [{
                xtype: 'panel',
                frame: false,
                border: false,
                autoWidth: false,
                autoHeight: false,
                layout: 'hbox',
                layoutConfig: {
                    align: "stretch"
                },
                items: [new Ext.ux.grid.GridPanelExd({
                    store: new Ext.data.Store({
                        autoLoad: false,
                        sortOnLoad: true,
                        sorters: [{propertyName: 'nazwa', sorterFn: customSorterForGrid}],
                        proxy: new Ext.data.MemoryProxy({}),
                        reader: new Ext.data.JsonReader({
                            id: "id",
                            rootProperty: ''
                        }, [{
                            name: 'id',
                            mapping: 'id'
                        }, {
                            name: 'nazwa',
                            mapping: 'nazwa'
                        }])
                    }),
                    columns: [{
                        header: Lang.nazwa,
                        flex: 1,
                        width: 145,
                        sortable: true,
                        dataIndex: 'nazwa',
                        sortable: false
                    }],
                    title: Lang.dostepne,
                    height: 400,
                    flex: 1,
                    frame: true,
                    border: true,
                    id: this.cid + "firmas_from",
                    stateId: null,
                    stateful: false,
                    stripeRows: true,
                    enableHdMenu: false,
                    selModel: {
                        selType: 'rowmodel',
                        mode: 'single'
                    },
                    listeners: {
                        scope: this,
                        rowdblclick: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            var r = grid.getSelR();
                            if (r == null)
                                return;
                            grid.store.remove(r);
                            grid2.store.add(r);
                        }
                    },
                    getSelR: function () {
                        var gridd = this;
                        if (!gridd.getSelectionModel().hasSelection()) {
                            return null;
                        } else {
                            if (gridd.getSelection() != null && gridd.getSelection().length > 0) {
                                return gridd.getSelection()[0];
                            }
                        }
                        return null;
                    }
                }), {
                    width: 50,
                    height: 400,
                    xtype: 'panel',
                    frame: false,
                    border: false,
                    layout: {
                        type: 'vbox',
                        pack: "center",
                        align: "stretch"
                    },
                    items: [{
                        xtype: 'button',
                        text: ">",
                        width: 50,
                        scope: this,
                        handler: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            var r = grid.getSelR();
                            if (r == null)
                                return;
                            grid.store.remove(r);
                            grid2.store.add(r);
                        }
                    }, {
                        xtype: 'button',
                        text: ">>",
                        width: 50,
                        scope: this,
                        handler: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            grid2.store.add(grid.store.getRange());
                            grid.store.removeAll();
                        }
                    }, {
                        xtype: 'button',
                        text: "<",
                        width: 50,
                        scope: this,
                        handler: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            var r = grid2.getSelR();
                            if (r == null)
                                return;
                            grid2.store.remove(r);
                            grid.store.add(r);
                        }
                    }, {
                        xtype: 'button',
                        text: "<<",
                        width: 50,
                        scope: this,
                        handler: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            grid.store.add(grid2.store.getRange());
                            grid2.store.removeAll();
                        }
                    }]
                }, new Ext.ux.grid.GridPanelExd({
                    store: new Ext.data.Store({
                        sortOnLoad: true,
                        sorters: [{propertyName: 'nazwa', sorterFn: customSorterForGrid}],
                        autoLoad: false,
                        proxy: new Ext.data.MemoryProxy({}),
                        reader: new Ext.data.JsonReader({
                            id: "id",
                            rootProperty: ''
                        }, [{
                            name: 'id',
                            mapping: 'id'
                        }, {
                            name: 'nazwa',
                            mapping: 'nazwa'
                        }])
                    }),
                    columns: [{
                        header: Lang.nazwa,
                        flex: 1,
                        width: 145,
                        sortable: true,
                        dataIndex: 'nazwa',
                        sortable: false
                    }],
                    title: Lang.wybrane,
                    height: 400,
                    flex: 1,
                    frame: true,
                    border: true,
                    id: this.cid + "firmas_to",
                    stateId: null,
                    stateful: false,
                    stripeRows: true,
                    enableHdMenu: false,
                    selModel: {
                        selType: 'rowmodel',
                        mode: 'single'
                    },
                    listeners: {
                        scope: this,
                        rowdblclick: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            var r = grid2.getSelR();
                            if (r == null)
                                return;
                            grid2.store.remove(r);
                            grid.store.add(r);
                        }
                    },
                    getSelR: function () {
                        var gridd = this;
                        if (!gridd.getSelectionModel().hasSelection()) {
                            return null;
                        } else {
                            if (gridd.getSelection() != null && gridd.getSelection().length > 0) {
                                return gridd.getSelection()[0];
                            }
                        }
                        return null;
                    }
                })]
            }]
        });
        this.callParent(arguments);
    },
    callAfterShow: function () {
        var grid = Ext.getCmp(this.cid + "firmas_from");
        var grid2 = Ext.getCmp(this.cid + "firmas_to");
        if (!this.callAfterShowCalled) {
            var ddd = this.datad.mainCtrl;
            this.datad.mainCtrl.mainpanel.mask();
            sendAjaxDataJsonRpcL("rest/reportlist/loadUniqueList", {
                idfirma: this.datad.mainCtrl.repFirma,
                col: this.col
            }, this, function (data) {
                this.datad.mainCtrl.mainpanel.unmask();
                if (data.status) {
                    data = data.data;
                    this.fulllist = data;
                    var eld = this.datad.mainCtrl.repDef.filters[this.col];
                    var tabls = [];
                    if (eld) {
                        for (var i = 0; i < eld.objectsToFilterIn.length; i++) {
                            eld.objectsToFilterIn[i] = Number(eld.objectsToFilterIn[i]);
                        }
                        tabls = eld.objectsToFilterIn || [];
                    }
                    var obss = tabls.length == 0 ? {
                        restls: [],
                        containsls: data
                    } : generateDiffCollection(data, tabls, "id");
                    grid.store.loadData(obss.restls);
                    grid2.store.loadData(obss.containsls);
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        }
        this.callAfterShowCalled = true;
    },
    callAfterHide: function () {
        if (this.callAfterShowCalled) {
            if (!this.fulllist)
                this.fulllist = [];
            var grid = Ext.getCmp(this.cid + "firmas_from");
            var grid2 = Ext.getCmp(this.cid + "firmas_to");
            var colsNext = grid2.store.collect("id");
            this.datad.mainCtrl.repDef.filters[this.col] = {
                objectsToFilterIn: this.fulllist.length == colsNext.length ? [] : colsNext
            };
        }
        return null;
    },
    setData: function (datad) {
        this.datad = datad;
    }
});
