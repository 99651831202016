Ext.define('PanelIdentyfikatora', {
    extend: 'Ext.form.FieldContainer',
    alias: "widget.identyfikatorpanel",
    baseCls: 'x-ident-panel',
    labelCls: 'x-ident-panel-label',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'displayexd',
            baseBodyCls: 'x-ident-ikonNone x-ident-panel-body',
            id: this.cid + 'iconpanel'
        }];
        this.layout = 'anchor';
        this.defaults = {
            layout: '100%'
        };
        this.frame = false;
        this.border = false;
        this.validateFkodB = Ext.Function.createBuffered(this.validateFkod, 200, this);
        this.callParent(arguments);
    },
    init: function (params) {
        Ext.apply(this, params);
        Ext.apply(this.params, params);
        Ext.suspendLayouts();
        sendAjaxDataJsonRpcL("rest/systemkonf/readSysIdentContextObjectParams", {
            id: params.obiektid,
            objectt: params.objectt
        }, this, function (data) {
            if (data.status) {
                data = data.data;
                params.sysidentparams = data;
                if (Const.CLOUDFLAG || data.ident_autohandgen) {
                    sendAjaxDataJsonRpcL("rest/systemkonf/obtainAutoGeneratedIdentKodReczny", null, this, function (data) {
                        if (data.status) {
                            data = data.data;
                            params.identautogenvalue = data;
                            this.processConfig(params);
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                    });
                } else {
                    this.processConfig(params);
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
        Ext.resumeLayouts(true);
    },
    getIdentData: function () {
        var pinn = null;
        if (this.oidenttype == Const.IDENT_ELEKTRONICZNAPIN)
            pinn = Ext.getCmp(this.cid + 'pin1').getValue();
        if (this.oidenttype == Const.IDENT_KODRECZNY)
            this.oident = Ext.getCmp(this.cid + 'fkod').getValue();
        return {
            identyfikator: this.oident,
            identtype: this.oidenttype,
            pin: pinn
        };
    },
    isValid: function () {
        var iconPanel = Ext.getCmp(this.cid + 'iconpanel');
        if (this.oidenttype == Const.IDENT_ELEKTRONICZNA || this.oidenttype == Const.IDENT_ELEKTRONICZNAPIN) {
            if (this.oident != null) {
                if (this.oidenttype == Const.IDENT_ELEKTRONICZNAPIN) {
                    return Ext.getCmp(this.cid + 'pin1').getValue() != null && Ext.getCmp(this.cid + 'pin1').getValue() != '';
                } else
                    return true;
            } else
                return false;
        } else if (this.oidenttype == Const.IDENT_KODRECZNY && iconPanel && iconPanel.bodyEl) {
            return Ext.getCmp(this.cid + 'fkod').getValue() != null && Ext.getCmp(this.cid + 'fkod').getValue() != '' && iconPanel.bodyEl.hasCls('x-ident-ikonOk');
        }
    },
    isValidOtherErrorIf: function () {
        if (this.oidenttype == Const.IDENT_ELEKTRONICZNAPIN) {
            var pin1 = Ext.getCmp(this.cid + 'pin1');
            if (this.oidenttype == Const.IDENT_ELEKTRONICZNAPIN && (pin1.getValue().trim() == "" || !pin1.isValid())) {
                pin1.markInvalid(Lang.kod_pin_musi_miec_n_cyfr);
                var iconPanel = Ext.getCmp(this.cid + 'iconpanel');
                if (iconPanel && iconPanel.bodyEl)
                    iconPanel.bodyEl.replaceCls('x-ident-ikonOk', 'x-ident-ikonNone');
                return Lang.kod_pin_musi_miec_n_cyfr;
            }
            return null;
        } else if (this.oidenttype == Const.IDENT_KODRECZNY) {
            var fkod = Ext.getCmp(this.cid + 'fkod');
            if (fkod.getValue().trim() == "" || !fkod.isValid()) {
                if (fkod.getValue().trim() == "" && this.objectt == 'operator')
                    return null;
                fkod.markInvalid(Lang.ident_kod_check_valid_msg);
                var iconPanel = Ext.getCmp(this.cid + 'iconpanel');
                if (iconPanel && iconPanel.bodyEl)
                    iconPanel.bodyEl.replaceCls('x-ident-ikonOk', 'x-ident-ikonNone');
                return Lang.ident_kod_check_valid_msg;
            }
        } else
            return null;
    },
    onPinValidityChange: function (component, isValid, eventOptions) {
        var iconPanel = Ext.getCmp(this.cid + 'iconpanel');
        if (isValid) {
            if (this.oident != null && iconPanel && iconPanel.bodyEl) {
                iconPanel.bodyEl.replaceCls('x-ident-ikonNone', 'x-ident-ikonOk');
            } else if (iconPanel && iconPanel.bodyEl) {
                iconPanel.bodyEl.replaceCls('x-ident-ikonOk', 'x-ident-ikonNone');
            }
        } else if (iconPanel && iconPanel.bodyEl) {
            iconPanel.bodyEl.replaceCls('x-ident-ikonOk', 'x-ident-ikonNone');
        }
    },
    processConfig: function (params) {
        this.oident = params.object != null ? params.object.identyfikator : null;
        this.oidenttype = params.object != null ? params.object.identtype : null;
        switch (this.objectt) {
            case 'operator':
                this.ident_object = params.sysidentparams.ident_operator;
                break;
            case 'odbiorca':
                this.ident_object = params.sysidentparams.ident_odbiorca;
                break;
            case 'pojazd':
                this.ident_object = params.sysidentparams.ident_pojazd;
                break;
        }
        if (this.ident_object == Const.IDENT_ELEKTRONICZNA || this.ident_object == Const.IDENT_ELEKTRONICZNAPIN) {
            this.readerPanel.init({
                obiektid: this.obiektid,
                objectt: this.objectt,
                ident_object: this.ident_object
            });
            this.readerPanel.on('iassign', function (id) {
                this.oident = id;
                var iconPanel = Ext.getCmp(this.cid + 'iconpanel');
                if (Config.enablemanualassignidentyfiaktor == 1) {
                    Ext.getCmp(this.cid + 'fkod').setValue(this.oident);
                }
                if (this.ident_object == Const.IDENT_ELEKTRONICZNA && iconPanel && iconPanel.bodyEl) {
                    iconPanel.bodyEl.replaceCls('x-ident-ikonNone', 'x-ident-ikonOk');
                } else if (this.ident_object == Const.IDENT_ELEKTRONICZNAPIN) {
                    if (Ext.getCmp(this.cid + "pin1").isValid() && iconPanel && iconPanel.bodyEl) {
                        iconPanel.bodyEl.replaceCls('x-ident-ikonNone', 'x-ident-ikonOk');
                    }
                }
            }, this);
        }
        if (this.ident_object == Const.IDENT_ELEKTRONICZNA || this.ident_object == Const.IDENT_KODRECZNY)
            this.opin = null;
        if (this.oidenttype == null)
            this.oidenttype = Const.IDENT_ELEKTRONICZNA;
        if ((this.ident_object == Const.IDENT_ELEKTRONICZNA || this.ident_object == Const.IDENT_ELEKTRONICZNAPIN) && this.oidenttype == Const.IDENT_KODRECZNY) {
            oident = null;
        }
        if ((this.oidenttype == Const.IDENT_ELEKTRONICZNA || this.oidenttype == Const.IDENT_ELEKTRONICZNAPIN) && this.ident_object == Const.IDENT_KODRECZNY) {
            oident = null;
        }
        this.oidenttype = this.ident_object;
        if (this.oidenttype == Const.IDENT_KODRECZNY && params.identautogenvalue && this.oident == null) {
            this.oident = params.identautogenvalue;
        }
        var iconPanel = Ext.getCmp(this.cid + 'iconpanel');
        if (this.ident_object == Const.IDENT_ELEKTRONICZNAPIN) {
            this.add({
                id: this.cid + "pin1",
                fieldLabel: Lang.pin,
                labelAlign: 'top',
                xtype: 'textfieldexd',
                value: '0000',
                width: 120,
                regex: new RegExp("^[0-9]{4,4}$"),
                regexText: Lang.kod_pin_musi_miec_n_cyfr,
                allowBlank: false,
                blankText: Lang.kod_pin_musi_miec_n_cyfr,
                listeners: {
                    scope: this,
                    validitychange: this.onPinValidityChange
                }
            });
            if (this.object) {
                if (this.object.pin == null || this.object.pin == "") {
                    this.object.pin = '0000';
                }
                Ext.getCmp(this.cid + "pin1").setValue(this.object.pin);
            }
        }
        if (this.oidenttype == Const.IDENT_ELEKTRONICZNA || this.oidenttype == Const.IDENT_ELEKTRONICZNAPIN) {
            if (Config.enablemanualassignidentyfiaktor == 1) {
                this.add({
                    id: this.cid + "fkod",
                    fieldLabel: Lang.identyfikator,
                    labelAlign: 'top',
                    width: 120,
                    xtype: 'textfieldexd',
                    disabled: true,
                    editable: false
                }, {
                    xtype: 'button',
                    text: Lang.przypisz_identyfikator_recznie,
                    width: 120,
                    scope: this,
                    handler: this.manuallyAssignIdent
                });
                if (this.oident != null && iconPanel && iconPanel.bodyEl) {
                    Ext.getCmp(this.cid + 'fkod').setValue(this.oident);
                }
            }
            if (this.oident != null && iconPanel && iconPanel.bodyEl) {
                iconPanel.bodyEl.replaceCls('x-ident-ikonNone', 'x-ident-ikonOk');
            }
        }
        if (this.ident_object == Const.IDENT_KODRECZNY) {
            this.add({
                id: this.cid + "fkod",
                fieldLabel: Lang.kod,
                labelAlign: 'top',
                width: 120,
                xtype: 'textfieldexd',
                allowBlank: false,
                blankText: Lang.pole_nie_moze_byc_puste,
                regex: new RegExp("^[1-9][0-9]{0,7}$"),
                regexText: Lang.ident_kod_check_valid_msg
            }, {
                xtype: 'button',
                text: Lang.generuj_nowy_kod,
                scope: this,
                hidden: !params.identautogenvalue,
                handler: this.generateKod
            });
            if (this.oident != null) {
                Ext.getCmp(this.cid + 'fkod').setValue(this.oident);
                if (iconPanel && iconPanel.bodyEl)
                    iconPanel.bodyEl.replaceCls('x-ident-ikonNone', 'x-ident-ikonOk');
            }
            if (params.identautogenvalue) {
                Ext.getCmp(this.cid + 'fkod').setDisabled(params.identautogenvalue);
                Ext.getCmp(this.cid + 'fkod').addCls(params.identautogenvalue ? "x-item-disabled-sec" : "");
                if (iconPanel && iconPanel.bodyEl)
                    iconPanel.bodyEl.replaceCls('x-ident-ikonNone', 'x-ident-ikonOk');
            } else {
                Ext.getCmp(this.cid + 'fkod').on("validitychange", function (textField, isValid) {
                    if (!isValid && iconPanel && iconPanel.bodyEl) {
                        iconPanel.bodyEl.replaceCls('x-ident-ikonOk', 'x-ident-ikonNone');
                    }
                }, this);
                Ext.getCmp(this.cid + 'fkod').on("change", function (component) {
                    this.validateFkodB(component);
                }, this);
            }
        }

    },
    markIdAsUsed: function () {
        var iconPanel = Ext.getCmp(this.cid + 'iconpanel');
        if (iconPanel && iconPanel.bodyEl)
            iconPanel.bodyEl.replaceCls('x-ident-ikonOk', 'x-ident-ikonNone');
        if (this.ident_object == Const.IDENT_KODRECZNY) {
            if (!Ext.getCmp(this.cid + 'fkod').isDisabled()) {
                Ext.getCmp(this.cid + 'fkod').markInvalid(Lang.identyfikator_uzywany);
            } else {
                this.generateKod();
            }
        } else if (this.ident_object == Const.IDENT_ELEKTRONICZNAPIN) {
            Ext.getCmp(this.cid + 'pin1').markInvalid(Lang.identyfikator_uzywany);
            this.oident = null;
        } else {
            this.oident = null;
        }
    },
    validateFkod: function (component) {
        if (component.isValid()) {
            var iconPanel = Ext.getCmp(this.cid + 'iconpanel');
            sendAjaxDataJsonRpcL("rest/systemkonf/checkIsCanAssignIdentToObject", {
                id: this.obiektid,
                objecttype: this.objectt,
                identyfikator: component.getValue(),
                identtype: this.oidenttype
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    if (data.canassign && iconPanel && iconPanel.bodyEl) {
                        iconPanel.bodyEl.replaceCls('x-ident-ikonNone', 'x-ident-ikonOk');
                    } else {
                        if (component)
                            component.markInvalid(Lang.identyfikator_uzywany);
                        if (iconPanel && iconPanel.bodyEl)
                            iconPanel.bodyEl.replaceCls('x-ident-ikonOk', 'x-ident-ikonNone');

                    }
                }
            });
        }
    },
    generateKod: function () {
        sendAjaxDataJsonRpcL("rest/systemkonf/obtainAutoGeneratedIdentKodReczny", null, this, function (data) {
            if (data.status) {
                Ext.getCmp(this.cid + 'fkod').setValue(data.data);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    manuallyAssignIdent: function () {
        var manualIdentyfikatorWindow = Ext.create('WindowManualIdentyfikator');
        manualIdentyfikatorWindow.showW(this.obiektid, this.oidenttype, this.objectt);
        manualIdentyfikatorWindow.on("wsuccessclose", function (ident) {
            var iconPanel = Ext.getCmp(this.cid + 'iconpanel');
            if (this.ident_object == Const.IDENT_ELEKTRONICZNA && iconPanel && iconPanel.bodyEl) {
                iconPanel.bodyEl.replaceCls('x-ident-ikonNone', 'x-ident-ikonOk');
            } else if (this.ident_object == Const.IDENT_ELEKTRONICZNAPIN) {
                if (Ext.getCmp(this.cid + "pin1").isValid() && iconPanel && iconPanel.bodyEl) {
                    iconPanel.bodyEl.replaceCls('x-ident-ikonNone', 'x-ident-ikonOk');
                }
            }
            this.oident = ident;
            Ext.getCmp(this.cid + 'fkod').setValue(this.oident);
        }, this);
    },
    close: function () {
        if (this.readerPanel != null) {
            this.readerPanel.close();
        }
    }
});
