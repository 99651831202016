Ext.define('ViewOdbiorcy', {
    extend: 'Ext.panel.Panel',
    grid: null,
    gridpage: null,
    windowObject: null,
    id: "odbiorcy",
    taskContextMenu: null,
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyName: "access.manage.odbiorcy",
        accessPropertyViewName: "access.view.odbiorcy"
    },
    cat: "k",
    text: Lang.odbiorcy,
    buttonIconCls: "iconOdbiorca",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            items: [{
                id: this.id + "bnew",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                scope: this,
                handler: this.dodajObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewOdbiorcy.accessPropertyName + "/add")
            }, {
                id: this.id + "bedit",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEdit",
                text: Lang.edytuj.capitalise(),
                scope: this,
                handler: this.edytujObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewOdbiorcy.accessPropertyName + "/update")
            }, {
                id: this.id + "bdelete",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconDelete",
                text: Lang.usun.capitalise(),
                scope: this,
                handler: this.usunObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewOdbiorcy.accessPropertyName + "/delete")
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconPDF",
                text: Lang.pdf.capitalise(),
                scope: this,
                handler: this.getDownloadLsPdf
            }, {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEXCEL",
                text: Lang.excel.capitalise(),
                scope: this,
                handler: this.getDownloadLsExcel
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }, {
                xtype: 'button',
                id: this.id + "grupybtn",
                iconAlign: 'left',
                iconCls: 'iconGroup',
                text: Lang.grupy,
                scope: this,
                handler: this.showGrupy,
                hidden: !validPrivilegesOperatorComplex(WindowGroups.accessPropertyName)
            }, {
                xtype: "box",
                id: this.id + "kontekstfirmybox",
                cls: "kontekstfirmy-box"
            }, {
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                style: 'display:block;margin-right:5px',
                cls: "kontekstfirmy-text"
            }, {
                xtype: 'tbseparator',
                hidden: (!Const.BASICFLAG && !Const.STANDARDFLAG) || Const.LICENCE.SERVER_LIMITOFF_KDPANDID
            }, {
                xtype: 'label',
                hidden: (!Const.BASICFLAG && !Const.STANDARDFLAG) || Const.LICENCE.SERVER_LIMITOFF_KDPANDID,
                id: this.id + 'idcount-text'
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
        if (this.grid && this.grid.rendered)
            this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    onDestroy: function () {
        this.taskContextMenu.destroy();
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        var kontekstfirmybox = Ext.getCmp(this.id + "kontekstfirmybox");
        if (AppFIRMACTX != null && !Const.AGGREGATEDCORE) {
            kontekstfirmybox.update("<div class='appSetColourRedSplit'></div>");
            kontekstfirmy.setText(Lang.pracujesz_w_kontekscie + AppFIRMACTX.nazwa);
        } else {
            kontekstfirmybox.update("");
            kontekstfirmy.setText("");
        }
        Ext.getCmp(this.id + "grupybtn").setDisabled(Const.ENTERPRISEFLAG && AppFIRMACTX == null)
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            Ext.suspendLayouts();
            var onwer = this.grid.ownerCt;
            onwer.remove(this.grid);
            this.taskContextMenu.destroy();
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
            if (this.grid && this.grid.rendered)
                this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
        this.manageSelect();
    },
    scrollToView: function (id) {
        this.grid.selectRowScroll("id", id);
    },
    buildGrid: function () {
        var store = Ext.create('Ext.data.Store', {
            autoLoad: false,
            remoteSort: true,
            remoteFilter: true,
            pageSize: 50,
            model: Ext.define(null, {
                extend: 'Ext.data.Model',
                idProperty: 'id',
                fields: [{
                    name: 'id',
                    mapping: 'id'
                }, {
                    name: 'imie',
                    mapping: 'imie'
                }, {
                    name: 'nazwisko',
                    mapping: 'nazwisko'
                }, {
                    name: 'active',
                    mapping: 'active'
                }, {
                    name: 'identyfikatorassigned',
                    mapping: 'identyfikatorassigned'
                }, {
                    name: 'firma',
                    mapping: 'firma'
                }, {
                    name: 'opis',
                    mapping: 'opis'
                }, {
                    name: "individualopts",
                    mapping: "individualopts"
                }, {
                    name: "eiid",
                    mapping: "eiid"
                }, {
                    name: "canviewtankowanie",
                    mapping: "canviewtankowanie"
                }, {
                    name: "askforvolumetank",
                    mapping: "askforvolumetank"
                }, {
                    name: "cansetaddpole",
                    mapping: "cansetaddpole"
                }, {
                    name: "grupa",
                    mapping: "grupa"
                }]
            }),
            proxy: {
                type: 'ajax',
                url: 'rest/odbiorcy/getList',
                filterParam: "filters",
                actionMethods: {
                    read: 'POST'
                },
                reader: {
                    type: 'json',
                    rootProperty: 'data',
                    totalProperty: 'total',
                    id: 'id'
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        store.on("load", function (store) {
            Ext.defer(function () {
                var count = store.getTotalCount();
                var pageSize = store.getPageSize();
                var pageCount = Math.ceil(count / pageSize);
                var currentPage = store.currentPage;
                if (currentPage > pageCount && pageCount > 0) {
                    --store.currentPage;
                }
            }, 1000, this);
        }, this);
        var cols = [{
            header: "ID",
            width: 145,
            sortable: true,
            dataIndex: 'id',
            filter: {
                type: 'numeric'
            },
            filterable: true,
            hidden: true
        }, {
            header: Lang.imie,
            flex: 2,
            sortable: true,
            dataIndex: 'imie',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.nazwisko,
            flex: 2,
            sortable: true,
            dataIndex: 'nazwisko',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.firma,
            flex: 2,
            sortable: true,
            dataIndex: 'firma',
            filter: {
                type: 'string'
            },
            filterable: true,
            hidden: AppFIRMACTX != null
        }, {
            header: Lang.opis,
            flex: 2,
            sortable: true,
            dataIndex: 'opis',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.grupa,
            id: this.id + 'grupacol',
            flex: 2,
            sortable: true,
            dataIndex: 'grupa',
            filter: {
                type: 'list',
                store: Ext.create('Ext.data.Store', {
                    fields: [{
                        name: 'id',
                        mapping: 'id'
                    }, {
                        name: 'nazwa',
                        mapping: 'nazwa'
                    }],
                    proxy: {
                        type: "ajax",
                        url: "rest/grupy/getList",
                        actionMethods: {
                            read: "POST"
                        },
                        extraParams: {
                            type: Const.GROUP_TYPE_ODBIORCA
                        },
                        listeners: {
                            scope: this,
                            exception: handlGridLoadExceptionJsonRpcL
                        }
                    },
                    listeners: {
                        load: function () {
                            this.insert(0, [{
                                id: 0,
                                nazwa: Lang.brak_grupy
                            }]);
                        }
                    }
                }),
                idField: 'id',
                labelField: 'nazwa'
            },
            filterable: true,
            hidden: false
        }, {
            header: Lang.eiid,
            flex: 2,
            sortable: true,
            dataIndex: 'eiid',
            filter: {
                type: 'string'
            },
            filterable: true,
            hidden: Const.BASICFLAG || Const.STANDARDFLAG || Ext.getViewportWidth() <= 1366
        }, {
            header: Lang.pole_dodatkowe_przy_tankowaniu,
            flex: 1,
            sortable: true,
            dataIndex: 'cansetaddpole',
            renderer: renderBool2,
            filter: {
                type: 'boolean'
            },
            filterable: true
        }, {
            header: Lang.podglad_tankowania,
            flex: 1,
            sortable: true,
            dataIndex: 'canviewtankowanie',
            renderer: renderBool2,
            filter: {
                type: 'boolean'
            },
            filterable: true
        }, {
            header: Lang.pytanie_o_ilosc_dozatankowania,
            flex: 1,
            sortable: true,
            dataIndex: 'askforvolumetank',
            renderer: renderBool2,
            filter: {
                type: 'boolean'
            },
            filterable: true
        }, {
            header: Lang.ustawienia_indywidualne,
            flex: 1,
            sortable: true,
            dataIndex: 'individualopts',
            renderer: renderBool2,
            filter: {
                type: 'boolean'
            },
            filterable: true
        }, {
            header: Lang.identyfikator,
            flex: 1,
            sortable: true,
            dataIndex: 'identyfikatorassigned',
            renderer: renderBool2,
            filter: {
                type: 'boolean'
            },
            filterable: true
        }, {
            header2: Lang.active,
            menuText: Lang.active,
            header: Ext.DomHelper.markup({
                tag: "div",
                cls: "iconDivCol iconActive",
                cn: [{
                    tag: "span",
                    html: "&nbsp;"
                }, {
                    tag: "div",
                    html: Lang.active
                }]
            }),
            flex: 1,
            sortable: true,
            dataIndex: 'active',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            filterable: true
        }];
        if (!Const.ENTERPRISEFLAG) {
            removeObjectFromArrayById(cols, "firma");
        }
        if (Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG) {
            removeObjectFromArrayById(cols, "cansetaddpole");
            removeObjectFromArrayById(cols, "askforvolumetank");
            removeObjectFromArrayById(cols, "individualopts");
        }
        if (Const.BASICFLAG || Const.STANDARDFLAG)
            removeObjectFromArrayById(cols, "eiid");
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true
            },
            store: store,
            columns: cols,
            frame: false,
            border: false,
            stateId: "this.grid-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'multi'
            },
            bbar: {
                xtype: 'pagingtoolbarexd',
                displayInfo: true,
                pagingStore: store
            }
        });
        this.grid.addListener("rowdblclick", function () {
            if (validPrivilegesOperatorComplex(ViewOdbiorcy.accessPropertyName + "/update"))
                this.edytujObiekt();
        }, this);
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.store.addListener("load", function () {
            updateCzasRefreshSerwer();
        }, this);
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.taskContextMenu = new Ext.menu.Menu();
        this.taskContextMenu.add([{
            id: this.id + "bnew2",
            iconAlign: 'left',
            iconCls: "iconNew",
            text: Lang.nowy.capitalise(),
            scope: this,
            handler: this.dodajObiekt,
            disabled: !validPrivilegesOperatorComplex(ViewOdbiorcy.accessPropertyName + "/add")
        }, {
            id: this.id + "bedit2",
            iconAlign: 'left',
            iconCls: "iconEdit",
            text: Lang.edytuj.capitalise(),
            scope: this,
            handler: this.edytujObiekt,
            disabled: !validPrivilegesOperatorComplex(ViewOdbiorcy.accessPropertyName + "/update")
        }, {
            id: this.id + "bdelete2",
            iconAlign: 'left',
            iconCls: "iconDelete",
            text: Lang.usun.capitalise(),
            scope: this,
            handler: this.usunObiekt,
            disabled: !validPrivilegesOperatorComplex(ViewOdbiorcy.accessPropertyName + "/delete")
        }, "-", {
            iconAlign: 'left',
            iconCls: "iconPDF",
            text: Lang.pdf.capitalise(),
            scope: this,
            handler: this.getDownloadLsPdf
        }, {
            iconAlign: 'left',
            iconCls: "iconEXCEL",
            text: Lang.excel.capitalise(),
            scope: this,
            handler: this.getDownloadLsExcel
        }, "-", {
            iconAlign: 'left',
            iconCls: "iconRefresh",
            text: Lang.odswiez.capitalise(),
            scope: this,
            handler: this.refreshDatastore
        }]);
        this.grid.addListener("rowcontextmenu", function (grid, record, tr, rowIndex, e) {
            this.rowContext = this.grid.getStore().getAt(rowIndex);
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
        this.taskContextMenu.addListener("hide", function () {
            this.rowContext = null;
        }, this);
        this.grid.addListener("containercontextmenu", function (grid, e) {
            this.rowContext = null;
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
    },
    manageSelect: function () {
        var bedit = Ext.getCmp(this.id + "bedit");
        var bdelete = Ext.getCmp(this.id + "bdelete");
        var bedit2 = Ext.getCmp(this.id + "bedit2");
        var bdelete2 = Ext.getCmp(this.id + "bdelete2");
        if (bedit != null) {
            if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelection() == null || this.grid.getSelectionModel().getSelection().length == 0) {
                bdelete.setDisabled(true);
                bedit.setDisabled(true);
            } else {
                var row = this.grid.getSelectionModel().getSelection()[0];
                bdelete.setDisabled(!validPrivilegesOperatorComplex(ViewOdbiorcy.accessPropertyName + "/delete"));
                bedit.setDisabled(!validPrivilegesOperatorComplex(ViewOdbiorcy.accessPropertyName + "/update"));
            }
            if (this.rowContext == null) {
                bdelete2.setDisabled(true);
                bedit2.setDisabled(true);
            } else {
                bdelete2.setDisabled(!validPrivilegesOperatorComplex(ViewOdbiorcy.accessPropertyName + "/delete"));
                bedit2.setDisabled(!validPrivilegesOperatorComplex(ViewOdbiorcy.accessPropertyName + "/update"));
            }
            var bnew = Ext.getCmp(this.id + "bnew");
            var bnew2 = Ext.getCmp(this.id + "bnew2");
            if (Const.ENTERPRISEFLAG) {
                bnew.setDisabled(AppFIRMACTX == null || !validPrivilegesOperatorComplex(ViewOdbiorcy.accessPropertyName + "/add"));
                bnew2.setDisabled(AppFIRMACTX == null || !validPrivilegesOperatorComplex(ViewOdbiorcy.accessPropertyName + "/add"));
            } else {
                bnew.setDisabled(!validPrivilegesOperatorComplex(ViewOdbiorcy.accessPropertyName + "/add"));
                bnew2.setDisabled(!validPrivilegesOperatorComplex(ViewOdbiorcy.accessPropertyName + "/add"));
            }
        }
    },
    getDownloadLsPdf: function () {
        var defd = this.grid.getDefGridQueryCSF();
        postFormAsync("rest/odbiorcy/downloadExportPdfOtherList", {
            format: "pdf",
            name: Lang.odbiorcy,
            filters: defd.filters,
            sort: defd.sorters,
            defd: Ext.encode(defd)
        }, null);
    },
    getDownloadLsExcel: function () {
        var defd = this.grid.getDefGridQueryCSF();
        postFormAsync("rest/odbiorcy/downloadExportPdfOtherList", {
            format: "excel",
            name: Lang.odbiorcy,
            filters: defd.filters,
            sort: defd.sorters,
            defd: Ext.encode(defd)
        }, null);
    },
    refreshDatastore: function () {
        this.grid.store.load();
        Ext.getCmp(this.id + 'grupacol').filter.store.load();
        if (Const.BASICFLAG || Const.STANDARDFLAG) {
            sendAjaxDataJsonRpcL("rest/systemreq/getActiveIDCount", null, this, function (data) {
                if (data.status) {
                    var tpl = Lang.liczba_aktywnych_identyfikatorow_z_dostepnych;
                    var idCountLabel = Ext.getCmp(this.id + 'idcount-text');
                    idCountLabel.setText(tpl.replace('{0}', data.data));
                    if (data.data >= 140) {
                        idCountLabel.addCls('idcount-text-warning');
                    } else {
                        idCountLabel.removeCls('idcount-text-warning');
                    }
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        }
    },
    dodajObiekt: function () {
        var windowObject = Ext.create('WindowOdbiorcy', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(0);
    },
    edytujObiekt: function (e) {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length == 1) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        var windowObject = Ext.create('WindowOdbiorcy', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(id);
    },
    showGrupy: function () {
        var window = Ext.create('WindowGroups', {
            type: Const.GROUP_TYPE_ODBIORCA,
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        window.showW();
    },
    usunObiekt: function () {
        var ids = [];
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                ids = [this.rowContext.id];
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                Ext.each(this.grid.getSelectionModel().getSelection(), function (v) {
                    ids.push(v.id);
                });
            }
        }
        Ext.MessageBox.show({
            title: Lang.usuwanie,
            msg: Lang.czy_napewno_usunac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/odbiorcy/deleteObject", {
                        ids: ids
                    }, this, function (data) {
                        if (data.status) {
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                        this.refreshDatastore();
                    });
                }
            }
        });
    }
});
