Ext.define('WindowCtxFirmaBtnChooser', {
    extend: 'Ext.ux.window.WindowExd',
    cls: "app-WindowCtxFirmaBtnChooser",
    title: Lang.firmy,
    width: 600,
    height: 600,
    closable: false,
    collapsible: false,
    modal: true,
    scrollable: false,
    resizable: false,
    layout: {
        type: "vbox",
        align: "stretch"
    },
    id: 'WindowCtxFirmaBtnChooser',
    dataFS: [],
    x: 0,
    initComponent: function () {
        this.cid = Ext.id();
        this.bbar = {
            xtype: 'toolbar',
            items: [{
                xtype: 'tbfill'
            }, {
                xtype: 'button',
                text: Lang.zamknij,
                scope: this,
                handler: this.closeOp
            }]
        };
        var store = Ext.create('Ext.data.Store', {
            type: 'store',
            autoLoad: false,
            remoteSort: false,
            remoteFilter: false,
            model: Ext.define(null, {
                extend: 'Ext.data.Model',
                idProperty: "id",
                fields: ["nazwa", "opis", "id"]
            }),
            sorters: [{
                property: "nazwa",
                direction: "ASC"
            }],
            proxy: {
                type: 'memory',
                reader: {
                    type: "json"
                }
            }
        });
        var grid = {
            xtype: 'grid',
            id: this.cid + "grid",
            plugins: [{
                ptype: 'gridfilters'
            }],
            store: store,
            scrollable: true,
            flex: 1,
            stateful: true,
            stateId: "WindowCtxFirmaBtnChooser" + "grid",
            columns: [{
                header: "ID",
                width: 145,
                sortable: true,
                dataIndex: 'id',
                filter: {
                    type: 'numeric'
                },
                hidden: true
            }, {
                header: Lang.nazwa,
                flex: 1,
                width: 145,
                sortable: true,
                dataIndex: 'nazwa',
                filter: {
                    type: 'string'
                },
                sorter: Ext.bind(customLocaleSort, this, ["nazwa"], true)
            }, {
                header: Lang.opis,
                flex: 1,
                width: 145,
                sortable: true,
                dataIndex: 'opis',
                filter: {
                    type: 'string'
                }
            }],
            listeners: {
                scope: this,
                itemdblclick: function (c, r) {
                    if (WindowExdMobileDEVICEV)
                        this.mask(Lang.ladowanie);
                    this.fireEvent("itemselected", this, r);
                    if (!WindowExdMobileDEVICEV)
                        this.close();
                },
                itemclick: function (c, r) {
                    if (WindowExdMobileDEVICEV)
                        this.mask(Lang.ladowanie);
                    this.fireEvent("itemselected", this, r);
                    if (!WindowExdMobileDEVICEV)
                        this.close();
                }
            }
        };
        var checkboxContainer = {
            xtype: "container",
            layout: "hbox",
            items: [{
                xtype: "checkboxfield",
                boxLabel: Lang.wszystkie,
                id: this.cid + "checkbox",
                checked: true,
                padding: "0 0 0 5px",
                boxLabelCls: "kontekstfirmy-checkbox-label",
                listeners: {
                    scope: this,
                    change: function (newVal, oldVal) {
                        if (oldVal == true) {
                            var r = {};
                            r.id = 0;
                            if (WindowExdMobileDEVICEV)
                                this.mask(Lang.ladowanie);
                            changeContextFirm(r);
                            if (!WindowExdMobileDEVICEV) {
                                this.hide();
                                Ext.defer(function () {
                                    this.close();
                                }, 10, this);
                            }
                        } else {
                            var grid = Ext.getCmp(this.cid + "grid").setDisabled(false);
                            var rs = grid.store.findExact("id", this.currentObject);
                            if (rs >= 0) {
                                grid.getSelectionModel().select(rs);
                            }

                        }
                    }
                }
            }]
        };
        this.items = [checkboxContainer, grid];
        this.callParent(arguments);
    },
    showW: function () {
        this.show();
        if (!WindowExdMobileDEVICEV)
            this.setY(Ext.dom.Element.getViewportHeight() - 600 - 25);
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
        var grid = Ext.getCmp(this.cid + "grid");
        grid.store.loadData(this.dataFS);
        var rs = grid.store.findExact("id", this.currentObject);
        if (rs < 0) {
            grid.setDisabled(true);
        } else {
            grid.getSelectionModel().select(rs);
            Ext.getCmp(this.cid + "checkbox").setValue(false);
        }
    },
    closeOp: function () {
        this.close();
    }
});
