Ext.define('WindowLitrazowanieGridAddEdit', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.litrazowanie.capitalise(),
    width: 350,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'fit',
            items: [{
                title: Lang.ogolne.capitalise(),
                id: this.cid + "formpanel1",
                xtype: "form",
                frame: false,
                border: false,
                autoWidth: true,
                autoHeight: true,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0",
                    labelWidth: 100,
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    id: this.cid + "przetwornik",
                    name: "przetwornik",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.przetwornik + "[j]",
                    allowBlank: false,
                    decimalPrecision: 3,
                    allowDecimals: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 0.000,
                    maxValue: 9999999
                }, {
                    id: this.cid + "ilosccieczy",
                    name: "ilosccieczy",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.ilosc_cieczy + Lang.jednostka_text,
                    allowBlank: false,
                    decimalPrecision: 3,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 0.000,
                    maxValue: 9999999
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.closeSaveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (record, grid) {
        this.record = record;
        this.grid = grid;
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
        if (this.record == null) {
            Ext.getCmp(this.cid + "przetwornik").setValue(null);
            Ext.getCmp(this.cid + "ilosccieczy").setValue(null);
            Ext.getCmp(this.cid + "przetwornik").clearInvalid();
            Ext.getCmp(this.cid + "ilosccieczy").clearInvalid();
        } else {
            Ext.getCmp(this.cid + "przetwornik").setValue(this.record.get("przetwornik"));
            Ext.getCmp(this.cid + "ilosccieczy").setValue(this.record.get("ilosccieczy"));
        }
    },
    closeSaveOp: function () {
        var canWrite = false;
        if (Ext.getCmp(this.cid + "przetwornik").isValid() && Ext.getCmp(this.cid + "ilosccieczy").isValid()) {
            canWrite = true;
        }
        if (canWrite) {
            var przetwornik = Ext.getCmp(this.cid + "przetwornik").getValue();
            var ilosccieczy = Ext.getCmp(this.cid + "ilosccieczy").getValue();
            if (this.record == null) {
                this.grid.store.add({
                    przetwornik: przetwornik,
                    ilosccieczy: ilosccieczy
                });
                this.grid.store.sort("przetwornik", "ASC");
            } else {
                this.record.set("przetwornik", przetwornik);
                this.record.set("ilosccieczy", ilosccieczy);
            }
            this.grid.store.commitChanges();
            this.close();
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});

