Ext.define('ProgressBarExdStany', {
    extend: 'Ext.ProgressBar',
    alias: 'widget.progressexdstany',
    colorCls: null,
    renderTpl: [
        '<tpl if="internalText">',
        '<div class="{baseCls}-text {baseCls}-text-back" role="presentation">{text}</div>',
        '</tpl>',
        '<div id="{id}-bar" data-ref="bar" class="{baseCls}-bar {baseCls}-bar-{ui}" role="presentation" style="width:{percentage}%">',
        '<tpl if="internalText">',
        '<div class="{baseCls}-text" role="presentation">',
        '<div role="presentation">{text}</div>',
        '</div>',
        '</tpl>',
        '</div>'
    ],
    onRender: function () {
        this.colorCls = this.getColorCls();
        this.callParent(arguments);
        if (this.colorCls) {
            this.addCls(this.colorCls);
        }
    },
    updateProgress: function () {
        if (this.colorCls) this.removeCls(this.colorCls);
        this.colorCls = this.getColorCls();
        this.callParent(arguments);
        if (this.colorCls) {
            this.addCls(this.colorCls);
        }
    },
    getColorCls: function (record) {
        var column = null;
        record = null;
        if (this.getWidgetColumn) column = this.getWidgetColumn();
        if (this.getWidgetRecord) record = this.getWidgetRecord();
        if (record && column.dataIndex == "zapelnieniezbiornika") {
            if (record.get('stanzbiornika') < record.get("stanblokady"))
                return this.baseCls + "-low";
            else if (record.get('stanzbiornika') < record.get("stanalarmowy"))
                return this.baseCls + "-medium";
            else
                return this.baseCls + "-high";
        } else if (record && column.dataIndex == "zapelnieniezbiornikareal") {
            if (record.get('stanzbiornikareal') < record.get("stanblokady"))
                return this.baseCls + "-low";
            else if (record.get('stanzbiornikareal') < record.get("stanalarmowy"))
                return this.baseCls + "-medium";
            else
                return this.baseCls + "-high";
        } else {
            return null;
        }
    }
});