Ext.define('WindowCfgProgramFlash', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.programowanie_flash.capitalise(),
    width: 750,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    initComponent: function (config) {
        this.cid = Ext.id();
        this.config = config || {};
        Ext.apply(this, config);
        this.items = [{
            xtype: 'panel',
            frame: false,
            border: false,
            height: 250,
            layout: 'border',
            items: [{
                region: "north",
                xtype: 'toolbar',
                items: [{
                    xtype: 'button',
                    iconAlign: 'left',
                    iconCls: "iconNew",
                    text: Lang.dodaj.capitalise(),
                    handler: this.dodajObiekt,
                    scope: this
                }, {
                    xtype: 'button',
                    iconAlign: 'left',
                    iconCls: "iconEdit",
                    text: Lang.edytuj.capitalise(),
                    handler: this.edytujObiekt,
                    scope: this
                }, {
                    xtype: 'button',
                    iconAlign: 'left',
                    iconCls: "iconDelete",
                    text: Lang.usun.capitalise(),
                    handler: this.usunObiekt,
                    scope: this
                }, {
                    xtype: 'button',
                    iconAlign: 'left',
                    iconCls: "iconEdit",
                    text: Lang.wybor_urzadzen_i_zaprogramowanie.capitalise(),
                    handler: this.edytujObiekt2,
                    scope: this
                }, {
                    xtype: 'button',
                    iconAlign: 'left',
                    iconCls: "iconEdit",
                    text: Lang.anulowanie_oczekujacych_operacji_programowania.capitalise(),
                    handler: this.cancelAllOperation,
                    scope: this
                }]
            }, {
                xtype: "grid",
                id: this.cid + "grid",
                store: Ext.create('Ext.data.Store', {
                    fields: ['id', 'nazwa', 'version', 'devids', {
                        name: 'tmscreated',
                        type: 'date'
                    }, {
                        name: 'tmscompiled',
                        type: 'date'
                    }, {
                        name: 'tmsadded',
                        type: 'date'
                    }],
                    proxy: {
                        type: 'ajax',
                        url: 'rest/flash/getList',
                        actionMethods: {
                            read: 'POST'
                        },
                        extraParams: {
                            typeo: this.typeo
                        },
                        listeners: {
                            scope: this,
                            exception: function (proxy, response, operation, eventOptions) {
                                var data = handlGridLoadExceptionJsonRpcL(response, operation, eventOptions);
                                if (!data.data.status) {
                                    Ext.MessageBox.alert(Lang.error, data.data.error);
                                }
                            }
                        },
                        reader: {
                            type: 'json',
                            rootProperty: 'data'
                        }
                    },
                    autoLoad: false
                }),
                columns: [{
                    dataIndex: 'id',
                    hidden: true
                }, {
                    header: Lang.nazwa,
                    flex: 2,
                    sortable: true,
                    dataIndex: 'nazwa'
                }, {
                    header: Lang.typy_flash_urzadzenia,
                    flex: 2,
                    sortable: true,
                    dataIndex: 'devids'
                }, {
                    header: Lang.data_kompilacji,
                    flex: 3,
                    sortable: true,
                    dataIndex: 'tmscompiled',
                    renderer: Ext.util.Format.dateRenderer()
                }, {
                    header: Lang.data_dodania,
                    flex: 3,
                    sortable: true,
                    dataIndex: 'tmsadded',
                    renderer: Ext.util.Format.dateRenderer()
                }],
                region: "center",
                frame: false,
                border: false,
                stateId: null,
                stateful: false,
                stripeRows: true,
                enableHdMenu: true,
                selModel: {
                    selType: 'rowmodel',
                    mode: 'SINGLE'
                },
                listeners: {
                    scope: this,
                    rowdblclick: function () {
                        var grid = Ext.getCmp(this.cid + "grid");
                        var id = 0;
                        if (!grid.getSelectionModel().hasSelection()) {
                            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
                            return;
                        } else {
                            if (grid.getSelectionModel().getSelected() != null) {
                                id = grid.getSelectionModel().getSelected().id;
                            }
                        }
                        var windowObject = Ext.create('WindowCfgProgramFlashAddEdit', {
                            obiektid: id
                        });
                        windowObject.showW();
                        windowObject.on("wclose", this.refreshDatastore, this);
                    }
                }
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'close',
            text: Lang.zamknij,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid, klonowanie) {
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
        this.refreshDatastore();
    },
    refreshDatastore: function () {
        Ext.getCmp(this.cid + "grid").store.load({});
    },
    dodajObiekt: function () {
        var windowObject = Ext.create('WindowCfgProgramFlashAddEdit', {
            obiektid: 0
        });
        windowObject.showW();
        windowObject.on("wclose", this.refreshDatastore, this);
    },
    edytujObiekt: function () {
        var grid = Ext.getCmp(this.cid + "grid");
        var id = 0;
        if (!grid.getSelectionModel().hasSelection()) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (grid.getSelection() != null) {
                var test = grid.getSelection();
                id = grid.getSelection()[0].id;
            }
        }
        var windowObject = Ext.create('WindowCfgProgramFlashAddEdit', {
            obiektid: id
        });
        windowObject.showW();
        windowObject.on("wclose", this.refreshDatastore, this);
    },
    edytujObiekt2: function () {
        var grid = Ext.getCmp(this.cid + "grid");
        var id = 0;
        if (!grid.getSelectionModel().hasSelection()) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (grid.getSelection() != null) {
                var test = grid.getSelection();
                id = grid.getSelection()[0].id;
            }
        }
        var windowObject = Ext.create('WindowCfgProgramFlashDoTask', {
            obiektid: id,
            typeo: this.typeo,
            addwarninfo: this.addwarninfo
        });
        windowObject.showW();
        windowObject.on("wclose", this.refreshDatastore, this);
    },
    usunObiekt: function () {
        var grid = Ext.getCmp(this.cid + "grid");
        var id = 0;
        if (!grid.getSelectionModel().hasSelection()) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (grid.getSelection() != null) {
                var test = grid.getSelection();
                id = grid.getSelection()[0].id;
            }
        }
        Ext.MessageBox.show({
            title: Lang.usuwanie,
            message: Lang.czy_napewno_usunac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/flash/deleteObject", {
                        id: id
                    }, this, function (data) {
                        if (data.status) {
                            Ext.MessageBox.alert(Lang.operacja, Lang.operacja_zakonczona_poprawnie);
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                        this.refreshDatastore();
                    });
                }
            },
            scope: this
        });
    },
    cancelAllOperation: function () {
        Ext.MessageBox.show({
            title: Lang.operacja,
            message: Lang.czy_napewno_anulowac_operacje,
            buttons: Ext.MessageBox.YESNO,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/flash/cancelDoTask", {
                        typeo: this.typeo
                    }, this, function (data) {
                        if (data.status) {
                            Ext.MessageBox.alert(Lang.operacja, Lang.operacja_zakonczona_poprawnie);
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                        this.refreshDatastore();
                    });
                }
            },
            scope: this
        });
    },
    closeOp: function () {
        this.close();
    }
});
