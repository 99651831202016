Ext.define('WindowManualIdentyfikator', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.reczne_prypisywanie_identyfikatora.capitalise(),
    width: 300,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                scrollable: false,
                defaults: {
                    anchor: "0",
                    labelWidth: 80
                },
                items: [{
                    id: this.cid + "identyfikatorm",
                    name: "identyfikatorm",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.identyfikator,
                    allowBlank: false,
                    editable: true,
                    minLength: 12,
                    maxLength: 12,
                    blankText: Lang.pole_nie_moze_byc_puste
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'zapisz',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid, identtype, objecttype) {
        this.objectId = obiektid;
        this.identType = identtype;
        this.objectType = objecttype;
        this.show();
        this.center();
    },
    saveOp: function () {
        if (Ext.getCmp(this.cid + "identyfikatorm").isValid()) {
            sendAjaxDataJsonRpcL("rest/systemkonf/checkIsCanAssignIdentToObject", {
                id: this.objectId,
                identyfikator: Ext.getCmp(this.cid + "identyfikatorm").getValue(),
                identtype: this.identType,
                objecttype: this.objectType
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    if (data.canassign && data.haskod) {
                        this.fireEvent("wsuccessclose", Ext.getCmp(this.cid + "identyfikatorm").getValue());
                        Ext.MessageBox.alert(Lang.reczne_prypisywanie_identyfikatora, Lang.identyfikator_przydzielony_poprawnie);
                        this.close();
                    } else if (!data.haskod) {
                        Ext.MessageBox.alert(Lang.reczne_prypisywanie_identyfikatora, Lang.identyfikator_nieautoryzowany);
                    } else {
                        Ext.MessageBox.alert(Lang.reczne_prypisywanie_identyfikatora, Lang.identyfikator_nie_moze_zostac_przydzielony);
                    }
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});
