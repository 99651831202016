Ext.define('ViewPivot', {
    extend: 'Ext.panel.Panel',
    id: "pivot",
    lc: {},
    statics: {
        accessPropertyViewName: "access.view.pivot"
    },
    cat: "p",
    text: Lang.malarz_raportow,
    buttonIconCls: "iconMalarzRap",
    layout: 'border',
    frame: false,
    border: false,
    showFirstSucess: false,
    ignoreNextShow: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        Ext.apply(this.lc, this.params);
        this.items = [{
            id: this.id + "pivotctrl",
            region: "west",
            xtype: 'panel',
            width: 304 + Ext.getScrollbarSize().width,
            collapsible: true,
            border: false,
            frame: true,
            scrollable: true,
            layout: 'fit',
            items: [{
                id: this.id + "formctrl",
                xtype: 'form',
                labelAlign: 'top',
                autoHeight: true,
                scrollable: true,
                defaults: {
                    anchor: "0"
                },
                items: [{
                    id: this.id + "rapls",
                    fieldLabel: Lang.nazwa,
                    xtype: 'comboexd',
                    store: new Ext.data.JsonStore({
                        id: id,
                        fields: [{
                            name: 'id',
                            mapping: 'id'
                        }, {
                            name: 'nazwa',
                            mapping: 'nazwa'
                        }]
                    }),
                    valueField: 'id',
                    displayField: 'nazwa',
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    style: {
                        marginLeft: '10px',
                        marginRight: '7px'
                    },
                    editable: true
                }, {
                    xtype: "panel",
                    layout: "hbox",
                    style: {
                        marginLeft: '10px',
                        marginRight: '7px'
                    },
                    items: [{
                        id: this.id + "bnowy",
                        margin: "0 2 5 0",
                        flex: 1,
                        cls: 'x-pivot-btn',
                        xtype: "button",
                        text: Lang.nowy.capitalise()
                    }, {
                        id: this.id + "bedytuj",
                        margin: "0 2 5 0",
                        flex: 1,
                        xtype: "button",
                        cls: 'x-pivot-btn',
                        text: Lang.zapisz.capitalise()
                    }, {
                        id: this.id + "busun",
                        margin: "0 2 5 0",
                        flex: 1,
                        xtype: "button",
                        cls: 'x-pivot-btn',
                        text: Lang.usun.capitalise()
                    }]
                }, {
                    xtype: "panel",
                    layout: "hbox",
                    style: {
                        marginLeft: '10px',
                        marginRight: '7px'
                    },
                    items: [{
                        id: this.id + "bdrukuj",
                        margin: "0 2 5 0",
                        flex: 1,
                        xtype: "button",
                        cls: 'x-pivot-btn',
                        text: Lang.drukuj.capitalise()
                    }, {
                        id: this.id + "bexcel",
                        margin: "0 2 5 0",
                        flex: 1,
                        xtype: "button",
                        cls: 'x-pivot-btn',
                        text: Lang.excel.capitalise()
                    }, {
                        id: this.id + "brefresh",
                        margin: "0 2 5 0",
                        flex: 1,
                        xtype: "button",
                        cls: 'x-pivot-btn',
                        text: Lang.odswiez.capitalise()
                    }]
                }, {
                    id: this.id + "okrestyp",
                    fieldLabel: Lang.okres,
                    style: {
                        marginLeft: '10px',
                        marginRight: '7px'
                    },
                    hideLabel: false,
                    xtype: 'comboexd',
                    store: new Ext.data.ArrayStore({
                        fields: ["value", "text"]
                    }),
                    valueField: 'value',
                    displayField: 'text',
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false
                }, {
                    id: this.id + "okresod",
                    fieldLabel: Lang.dod,
                    style: {
                        marginLeft: '10px',
                        marginRight: '7px'
                    },
                    hideLabel: false,
                    xtype: 'datefieldexdmod'
                }, {
                    id: this.id + "okresdo",
                    fieldLabel: Lang.ddo,
                    style: {
                        marginLeft: '10px',
                        marginRight: '7px'
                    },
                    hideLabel: false,
                    xtype: 'datefieldexdmoddo'
                }, {
                    id: this.id + "pavb",
                    xtype: 'panel',
                    titleCollapse: true,
                    style: 'margin-bottom:5px',
                    title: Lang.dostepne.capitalise(),
                    frame: true,
                    border: true,
                    height: 250,
                    collapsible: true,
                    layout: "fit",
                    listeners: {
                        scope: this,
                        collapse: function () {
                            this.eventReziseCtrlPanel();
                        },
                        expand: function () {
                            this.eventReziseCtrlPanel();
                        }
                    }
                }, {
                    id: this.id + "prows",
                    xtype: 'panel',
                    titleCollapse: true,
                    style: 'margin-bottom:5px',
                    title: Lang.wiersze.capitalise(),
                    frame: true,
                    border: true,
                    height: 180,
                    collapsible: true,
                    layout: "fit",
                    listeners: {
                        scope: this,
                        collapse: function () {
                            this.eventReziseCtrlPanel();
                        },
                        expand: function () {
                            this.eventReziseCtrlPanel();
                        }
                    }
                }, {
                    id: this.id + "pcols",
                    xtype: 'panel',
                    style: 'margin-bottom:5px',
                    titleCollapse: true,
                    title: Lang.kolumny.capitalise(),
                    frame: true,
                    border: true,
                    height: 180,
                    collapsible: true,
                    layout: "fit",
                    listeners: {
                        scope: this,
                        collapse: function () {
                            eventReziseCtrlPanel();
                        },
                        expand: function () {
                            eventReziseCtrlPanel();
                        }
                    }
                }, {
                    id: this.id + "pvals",
                    xtype: 'panel',
                    style: 'margin-bottom:5px',
                    defaults: {
                        margin: '0px 7px 0px 7px'
                    },
                    titleCollapse: true,
                    title: Lang.wartosci.capitalise(),
                    frame: true,
                    border: true,
                    scrollable: true,
                    collapsible: true,
                    listeners: {
                        scope: this,
                        collapse: function () {
                            this.eventReziseCtrlPanel();
                        },
                        expand: function () {
                            this.eventReziseCtrlPanel();
                        }
                    }
                }]
            }]
        }, {
            id: this.id + "pivotbd",
            region: "center",
            xtype: 'panel',
            frame: true,
            border: false,
            bodyStyle: "padding:5px",
            border: false,
            layout: 'fit',
            items: []
        }];
        this.callParent(arguments);
    },
    remove: function () {
        this.showFirstSucess = false;
        this.callParent(arguments);
    },
    hide: function () {
        this.callParent(arguments);
    },
    onDestroy: function () {
        this.lc.pivot.destroy();
        this.lc.pivot = null;
        this.showFirstSucess = false;
        this.callParent(arguments);
    },
    eventReziseCtrlPanel: function () {
        var eerrvBars = Ext.getScrollbarSize().width + 5;
        var pivotctrl = Ext.getCmp(this.id + "pivotctrl");
        var pivotctrlContentBox = pivotctrl.getBox(true, false);
        var formctrl = Ext.getCmp(this.id + "formctrl");
        formctrl.setWidth(pivotctrlContentBox.width - ((formctrl.getHeight() > pivotctrlContentBox.height) ? eerrvBars : 0));
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.lc.currentIdFirma = AppFIRMACTX == null ? null : AppFIRMACTX.id;
        this.lc.currentIdFirmaNazwa = AppFIRMACTX == null ? null : AppFIRMACTX.nazwa;
        this.lc.currentIdFirma2 = !isValidOperatorFirmaLimit() ? null : AppOPERATORFIRMA.id;
        this.lc.currentIdFirmaNazwa2 = !isValidOperatorFirmaLimit() ? null : AppOPERATORFIRMA.nazwa;
        this.updateFirmName();
        if (this.showFirstSucess) {
            this.loadCube();
        }
    },
    updateFirmName: function () {
        var ppl = Ext.getCmp(this.id + "pivotctrl");
        if (ppl != null) {
            if (AppFIRMACTX == null) {
                ppl.setTitle(null);
                if (ppl.getHeader() != null) {
                    ppl.getHeader().addCls('x-rpivotctrl-header-small');
                    ppl.updateLayout();
                }
            } else {
                ppl.setTitle(this.lc.currentIdFirmaNazwa3 == null ? this.lc.currentIdFirmaNazwa : this.lc.currentIdFirmaNazwa3);
                if (ppl.getHeader() != null) {
                    ppl.getHeader().removeCls('x-rpivotctrl-header-small');
                    ppl.updateLayout();
                }
            }
        }
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
        this.lc.currentIdFirma = AppFIRMACTX == null ? null : AppFIRMACTX.id;
        this.lc.currentIdFirmaNazwa = AppFIRMACTX == null ? null : AppFIRMACTX.nazwa;
        this.lc.currentIdFirma2 = !isValidOperatorFirmaLimit() ? null : AppOPERATORFIRMA.id;
        this.lc.currentIdFirmaNazwa2 = !isValidOperatorFirmaLimit() ? null : AppOPERATORFIRMA.nazwa;
        this.lc.currentIdFirma3 = null;
        this.lc.currentIdFirmaNazwa3 = null;
        var rapls = Ext.getCmp(this.id + "rapls");
        var pivotctrl = Ext.getCmp(this.id + "pivotctrl");
        var formctrl = Ext.getCmp(this.id + "formctrl");
        pivotctrl.on("resize", function (c, aw, ah, w, h) {
            this.eventReziseCtrlPanel();
        }, this);
        pivotctrl.on("afterlayout", this.eventReziseCtrlPanel, this);
        this.eventReziseCtrlPanel();
        var pivotbd = Ext.getCmp(this.id + "pivotbd");
        var pavb = Ext.getCmp(this.id + "pavb");
        var prows = Ext.getCmp(this.id + "prows");
        var pcols = Ext.getCmp(this.id + "pcols");
        var pvals = Ext.getCmp(this.id + "pvals");
        pavb.dd = new Ext.dd.DDTarget(pavb.body, id + 'reportpivot');
        prows.dd = new Ext.dd.DDTarget(prows.body, id + 'reportpivot');
        pcols.dd = new Ext.dd.DDTarget(pcols.body, id + 'reportpivot');
        var pivotctrl = Ext.getCmp(this.id + "pivotctrl");
        var formctrl = Ext.getCmp(this.id + "formctrl");
        var pavbstore = new Ext.data.JsonStore({
            fields: [{
                name: 'value',
                mapping: 'value'
            }, {
                name: 'text',
                mapping: 'text'
            }]
        });
        var prowsstore = new Ext.data.JsonStore({
            fields: [{
                name: 'value',
                mapping: 'value'
            }, {
                name: 'text',
                mapping: 'text'
            }]
        });
        var pcolsstore = new Ext.data.JsonStore({
            fields: [{
                name: 'value',
                mapping: 'value'
            }, {
                name: 'text',
                mapping: 'text'
            }]
        });
        var tplEl = '<tpl for="."><div class="x-rpivot-ddview-item">{' + "text" + '}<div class="x-rpivot-ddview-item-button-filter"></div></div></tpl>';
        Ext.suspendLayouts();
        var pavbview = new Ext.ux.form.MultiSelectExd({
            id: this.id + "pavbview",
            multiSelect: true,
            store: pavbstore,
            listConfig: {
                itemTpl: tplEl
            },
            dragText: "{0} " + Lang.elementy,
            autoHeight: true,
            scrollable: true,
            dragGroup: "x-rpivot-ddgroup",
            dropGroup: "x-rpivot-ddgroup"
        });
        pavb.add(pavbview);
        var prowsview = new Ext.ux.form.MultiSelectExd({
            id: this.id + "prowsview",
            multiSelect: true,
            store: prowsstore,
            listConfig: {
                itemTpl: tplEl
            },
            dragText: "{0} " + Lang.elementy,
            autoHeight: true,
            scrollable: true,
            dragGroup: "x-rpivot-ddgroup",
            dropGroup: "x-rpivot-ddgroup"
        });
        prows.add(prowsview);
        var pcolsview = new Ext.ux.form.MultiSelectExd({
            id: this.id + "pcolsview",
            multiSelect: true,
            autoHeight: true,
            store: pcolsstore,
            listConfig: {
                itemTpl: tplEl
            },
            dragText: "{0} " + Lang.elementy,
            scrollable: true,
            dragGroup: "x-rpivot-ddgroup",
            dropGroup: "x-rpivot-ddgroup"
        });
        pcols.add(pcolsview);
        Ext.resumeLayouts(true);
        var okrestyp = Ext.getCmp(this.id + "okrestyp");
        okrestyp.store.loadData([["custom", Lang.custom], ["prev_day", Lang.prev_day], ["prev_week", Lang.prev_week], ["prev_month", Lang.prev_month], ["prev_year", Lang.prev_year], ["current_day", Lang.current_day], ["current_week", Lang.current_week], ["current_month", Lang.current_month], ["current_year", Lang.current_year]]);
        rapls.on("select", this.reloadSelectRp, this);
        var rrswq = [];
        rrswq.push({
            value: "year",
            text: Lang.rok
        });
        rrswq.push({
            value: "month",
            text: Lang.miesiac
        });
        rrswq.push({
            value: "day",
            text: Lang.dzien
        });
        rrswq.push({
            value: "weekday",
            text: Lang.dzien_tygodnia
        });
        rrswq.push({
            value: "hour",
            text: Lang.godzina
        });
        rrswq.push({
            value: "typ",
            text: Lang.typ
        });
        rrswq.push({
            value: "odbiorca",
            text: Lang.odbiorca
        });
        rrswq.push({
            value: "pojazd",
            text: Lang.pojazd
        });
        if (Const.ENTERPRISEFLAG)
            rrswq.push({
                value: "firma",
                text: Lang.firma
            });
        rrswq.push({
            value: "kod",
            text: Lang.kod
        });
        rrswq.push({
            value: "sterownik",
            text: Lang.sterownik
        });
        if (!Const.BASICFLAG && !Const.STANDARDFLAG && !Const.OPTIMALFLAG && !Const.STARTERFLAG) {
            rrswq.push({
                value: "rozszerzeniester",
                text: Lang.rozszerzenie
            });
        }
        if (!Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG) {
            rrswq.push({
                value: "zbiornik",
                text: Lang.zbiornik
            });
        }
        rrswq.push({
            value: "grupa_odbiorca",
            text: Lang.odbiorca + ' (' + Lang.grupa.capitalise() + ')'
        });
        rrswq.push({
            value: "grupa_pojazd",
            text: Lang.pojazd + ' (' + Lang.grupa.capitalise() + ')'
        });
        pavbview.store.loadData(rrswq);
        okrestyp.setValue2(null);
        okrestyp.on("select", this.reloadSelectokrestyp, this);
        var bnowy = Ext.getCmp(this.id + "bnowy");
        var bedytuj = Ext.getCmp(this.id + "bedytuj");
        var busun = Ext.getCmp(this.id + "busun");
        var bdrukuj = Ext.getCmp(this.id + "bdrukuj");
        var bexcel = Ext.getCmp(this.id + "bexcel");
        bnowy.on("click", this.newraport2, this);
        bedytuj.on("click", this.saveraport, this);
        busun.on("click", this.deleteraport, this);
        bdrukuj.on("click", this.drukujraport, this);
        bexcel.on("click", this.excelraport, this);
        var brefresh = Ext.getCmp(this.id + "brefresh");
        brefresh.on("click", this.brefreshH, this);
        this.lc.puv = {};
        this.lc.puf = {};
        pavbview.store.each(function (n) {
            this.lc.puv[n.get("value")] = [];
            this.lc.puf[n.get("value")] = [];
            return true;
        }, this);
        prowsview.store.each(function (n) {
            this.lc.puv[n.get("value")] = [];
            this.lc.puf[n.get("value")] = [];
            return true;
        }, this);
        pcolsview.store.each(function (n) {
            this.lc.puv[n.get("value")] = [];
            this.lc.puf[n.get("value")] = [];
            return true;
        }, this);
        pavbview.store.on("add", function () {
            this.reEventElements();
        }, this);
        prowsview.store.on("add", function () {
            this.reEventElements();
        }, this);
        pcolsview.store.on("add", function () {
            this.reEventElements();
        }, this);
        pavbview.store.on("remove", function () {
            this.reEventElements();
        }, this);
        prowsview.store.on("remove", function () {
            this.reEventElements();
        }, this);
        pcolsview.store.on("remove", function () {
            this.reEventElements();
        }, this);
        pavbview.store.on("clear", function () {
            this.reEventElements();
        }, this);
        prowsview.store.on("clear", function () {
            this.reEventElements();
        }, this);
        pcolsview.store.on("clear", function () {
            this.reEventElements();
        }, this);
    },
    show: function () {
        var pvals = Ext.getCmp(this.id + "pvals");
        if (this.ignoreNextShow) {
            this.ignoreNextShow = false;
            return;
        }
        this.ignoreNextShow = true;
        if (!this.showFirstSucess) {
            sendAjaxDataJsonRpcL("rest/reportpivot/loadGetUniqueValues", {
                idfirma: null,
                typeCzas: "custom",
                fromDate: null,
                toDate: null,
                cols: ["plyn"]
            }, this, function (data) {
                if (data.status) {
                    var data = data.data;
                    var ls = data["plyn"];
                    pvals.removeAll(true);
                    Ext.suspendLayouts();
                    for (var i = 0; i < ls.value.length; i++) {
                        pvals.add({
                            rcType: {
                                plyn: ls.value[i],
                                typeFunction: 0
                            },
                            xtype: 'checkbox',
                            boxLabel: ls.text[i] + "(" + Lang.tankowanie + ")"
                        });
                        pvals.add({
                            rcType: {
                                plyn: ls.value[i],
                                typeFunction: 1
                            },
                            xtype: 'checkbox',
                            boxLabel: ls.text[i] + "(" + Lang.zuzycie100 + ")"
                        });
                        pvals.add({
                            rcType: {
                                plyn: ls.value[i],
                                typeFunction: 2
                            },
                            xtype: 'checkbox',
                            boxLabel: ls.text[i] + "(" + Lang.dystans + ")"
                        });
                        if (!Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG) {
                            pvals.add({
                                rcType: {
                                    plyn: ls.value[i],
                                    typeFunction: 3
                                },
                                xtype: 'checkbox',
                                boxLabel: ls.text[i] + "(" + Lang.zuzycie1h + ")"
                            });
                            pvals.add({
                                rcType: {
                                    plyn: ls.value[i],
                                    typeFunction: 4
                                },
                                xtype: 'checkbox',
                                boxLabel: ls.text[i] + "(" + Lang.czaspracy + ")"
                            });
                        }
                    }
                    Ext.resumeLayouts(true);
                    this.newraport();
                    this.showFirstSucess = true;
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
                this.eventReziseCtrlPanel();
            });
        } else {
            sendAjaxDataJsonRpcL("rest/reportpivot/loadGetUniqueValues", {
                idfirma: null,
                typeCzas: "custom",
                fromDate: null,
                toDate: null,
                cols: ["plyn"]
            }, this, function (data) {
                if (data.status) {
                    var data = data.data;
                    var ls = data["plyn"];
                    var rrasd = [];
                    Ext.suspendLayouts();
                    Ext.each(pvals.items.items, function (v) {
                        if (v.getValue())
                            rrasd.push(v.rcType);
                    }, this);
                    pvals.removeAll(true);
                    for (var i = 0; i < ls.value.length; i++) {
                        pvals.add({
                            rcType: {
                                plyn: ls.value[i],
                                typeFunction: 0
                            },
                            xtype: 'checkbox',
                            boxLabel: ls.text[i] + "(" + Lang.tankowanie + ")"
                        });
                        pvals.add({
                            rcType: {
                                plyn: ls.value[i],
                                typeFunction: 1
                            },
                            xtype: 'checkbox',
                            boxLabel: ls.text[i] + "(" + Lang.zuzycie100 + ")"
                        });
                        pvals.add({
                            rcType: {
                                plyn: ls.value[i],
                                typeFunction: 2
                            },
                            xtype: 'checkbox',
                            boxLabel: ls.text[i] + "(" + Lang.dystans + ")"
                        });
                        if (!Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG) {
                            pvals.add({
                                rcType: {
                                    plyn: ls.value[i],
                                    typeFunction: 3
                                },
                                xtype: 'checkbox',
                                boxLabel: ls.text[i] + "(" + Lang.zuzycie1h + ")"
                            });
                            pvals.add({
                                rcType: {
                                    plyn: ls.value[i],
                                    typeFunction: 4
                                },
                                xtype: 'checkbox',
                                boxLabel: ls.text[i] + "(" + Lang.czaspracy + ")"
                            });
                        }
                    }
                    Ext.resumeLayouts(true);
                    Ext.each(pvals.items.items, function (v) {
                        Ext.each(rrasd, function (v2) {
                            if (v.rcType.typeFunction == v2.typeFunction && v.rcType.plyn == v2.plyn) {
                                v.setValue(true);
                            }
                        });
                    });
                    this.loadCube();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
                this.eventReziseCtrlPanel();
            });
        }
        this.callParent(arguments);
    },
    reEventElements: function () {
        var pavbview = Ext.getCmp(this.id + "pavbview");
        var pavbviewBoundList = pavbview.boundList;
        var prowsview = Ext.getCmp(this.id + "prowsview");
        var prowsviewBoundList = prowsview.boundList;
        var pcolsview = Ext.getCmp(this.id + "pcolsview");
        var pcolsviewBoundList = pcolsview.boundList;
        if (pavbview == null || prowsview == null || pcolsview == null) {
            return;
        }
        Ext.each(pavbviewBoundList.getNodes(), function (n) {
            var r = pavbviewBoundList.getRecord(n);

            var en = Ext.get(n);
            var btneee = en.down(".x-rpivot-ddview-item-button-filter");
            if (!btneee.tt) {
                var tt = Ext.Function.bind(this.btneeeClick, this, [btneee, pavbview, r]);
                btneee.tt = tt;
                btneee.on("click", tt);
            }
            if (this.lc.puf[r.get("value")] && this.lc.puf[r.get("value")].length != 0)
                btneee.addCls("x-rpivot-ddview-item-button-filter-changed");
            else
                btneee.removeCls("x-rpivot-ddview-item-button-filter-changed");
        }, this);
        Ext.each(prowsviewBoundList.getNodes(), function (n) {
            var r = prowsviewBoundList.getRecord(n);
            var en = Ext.get(n);
            var btneee = en.down(".x-rpivot-ddview-item-button-filter");
            if (!btneee.tt) {
                var tt = Ext.Function.bind(this.btneeeClick, this, [btneee, prowsview, r]);
                btneee.tt = tt;
                btneee.on("click", tt);
            }
            if (this.lc.puf[r.get("value")] && this.lc.puf[r.get("value")].length != 0)
                btneee.addCls("x-rpivot-ddview-item-button-filter-changed");
            else
                btneee.removeCls("x-rpivot-ddview-item-button-filter-changed");
        }, this);
        Ext.each(pcolsviewBoundList.getNodes(), function (n) {
            var r = pcolsviewBoundList.getRecord(n);
            var en = Ext.get(n);
            var btneee = en.down(".x-rpivot-ddview-item-button-filter");
            if (!btneee.tt) {
                var tt = Ext.Function.bind(this.btneeeClick, this, [btneee, pcolsview, r]);
                btneee.tt = tt;
                btneee.on("click", tt);
            }
            if (this.lc.puf[r.get("value")] && this.lc.puf[r.get("value")].length != 0)
                btneee.addCls("x-rpivot-ddview-item-button-filter-changed");
            else
                btneee.removeCls("x-rpivot-ddview-item-button-filter-changed");
        }, this);
    },
    btneeedbclk: function (v, i, n, e) {
        var r = v.getRecord(n);
        var en = Ext.get(n);
        var btneee = en.child(".x-rpivot-ddview-item-button-filter");
        this.btneeeClick(btneee, v, r);
    },
    reloadRpLs: function () {
        var rapls = Ext.getCmp(this.id + "rapls");
        sendAjaxDataJsonRpcL("rest/reportpivot/getList", null, null, function (data) {
            if (data.status) {
                var data = data.data;
                if (rapls && rapls.store) {
                    rapls.store.loadData(data);
                    rapls.setValue(rapls.getValue());
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    brefreshH: function () {
        this.loadCube();
    },
    reloadSelectRp: function () {
        this.loadraport();
    },
    reloadSelectokrestyp: function () {
        var okrestyp = Ext.getCmp(this.id + "okrestyp");
        var okresod = Ext.getCmp(this.id + "okresod");
        var okresdo = Ext.getCmp(this.id + "okresdo");
        if (okrestyp.getValue() == "custom") {
            okresod.setDisabled(false);
            okresdo.setDisabled(false);
        } else {
            okresod.setDisabled(true);
            okresdo.setDisabled(true);
        }
    },
    btneeeClick: function (btneee, pavbview, r) {
        var okrestyp = Ext.getCmp(this.id + "okrestyp");
        var okresod = Ext.getCmp(this.id + "okresod");
        var okresdo = Ext.getCmp(this.id + "okresdo");
        var wf = Ext.create('RPivotFiltrWindow', {
            lc: this.lc,
            rc: r,
            rn: r.get("value"),
            idfirma: this.lc.currentIdFirma3 == null ? this.lc.currentIdFirma : this.lc.currentIdFirma3,
            typeCzas: okrestyp.getValue(),
            fromDate: okresod.getValue2(),
            toDate: okresdo.getValue2(),
            callbackOk: Ext.Function.bind(function () {
                if (this.lc.puf[r.get("value")].length != 0)
                    btneee.addCls("x-rpivot-ddview-item-button-filter-changed");
                else
                    btneee.removeCls("x-rpivot-ddview-item-button-filter-changed");
                this.loadCube();
            }, this),
            callbackAnuluj: function () {
                if (this.lc.puf[r.get("value")].length != 0)
                    btneee.addCls("x-rpivot-ddview-item-button-filter-changed");
                else
                    btneee.removeCls("x-rpivot-ddview-item-button-filter-changed");
            }
        });
        wf.showW();
    },
    getRaportDefinition: function () {
        var pvals = Ext.getCmp(this.id + "pvals");
        var pavbview = Ext.getCmp(this.id + "pavbview");
        var prowsview = Ext.getCmp(this.id + "prowsview");
        var pcolsview = Ext.getCmp(this.id + "pcolsview");
        var okrestyp = Ext.getCmp(this.id + "okrestyp");
        var okresod = Ext.getCmp(this.id + "okresod");
        var okresdo = Ext.getCmp(this.id + "okresdo");
        var rdf = {
            typeCzas: okrestyp.getValue(),
            fromDate: okresod.getValue2(),
            toDate: okresdo.getValue2(),
            rows: [],
            cols: [],
            rest: [],
            values: []
        };
        pavbview.store.each(function (n) {
            rdf.rest.push({
                name: n.get("value"),
                filters: this.lc.puf[n.get("value")]
            });
            return true;
        }, this);
        prowsview.store.each(function (n) {
            rdf.rows.push({
                name: n.get("value"),
                filters: this.lc.puf[n.get("value")]
            });
            return true;
        }, this);
        pcolsview.store.each(function (n) {
            rdf.cols.push({
                name: n.get("value"),
                filters: this.lc.puf[n.get("value")]
            });
            return true;
        }, this);
        Ext.each(pvals.items.items, function (v) {
            if (v.getValue())
                rdf.values.push(v.rcType);
        }, this);
        return rdf;
    },
    saveraport: function () {
        var rapls = Ext.getCmp(this.id + "rapls");
        var value = rapls.getValue();
        var value2 = rapls.getValue2NL();
        var rvalue = rapls.getRawValue();
        var hasRow = rapls.isRow();
        var rrvww = (rvalue === value);
        var valueOnListSelected = false;
        if (Ext.isNumber(value2)) {
            valueOnListSelected = true;
        }
        var idi = valueOnListSelected ? value2 : 0;
        if (rvalue.trim() == "")
            rvalue = "default";
        var rdf = this.getRaportDefinition();
        var rdf2 = this.lc.pivot.retriveVEX();
        Ext.apply(rdf, rdf2);
        sendAjaxDataJsonRpcL("rest/reportpivot/saveDef", {
            id: idi,
            nazwa: rvalue,
            idfirma: this.lc.currentIdFirma3 == null ? this.lc.currentIdFirma : this.lc.currentIdFirma3,
            reportdef: Ext.encode(rdf)
        }, this, function (data) {
            if (data.status) {
                var data = data.data;
                rapls.setValue(data);
                this.reloadRpLs();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    deleteraport: function () {
        var rapls = Ext.getCmp(this.id + "rapls");
        var value = rapls.getValue();
        var value2 = rapls.getValue2NL();
        var rvalue = rapls.getRawValue();
        var hasRow = rapls.isRow();
        var rrvww = (rvalue === value);
        var valueOnListSelected = false;
        if (Ext.isNumber(value2)) {
            valueOnListSelected = true;
        }
        var idi = valueOnListSelected ? value2 : 0;
        sendAjaxDataJsonRpcL("rest/reportpivot/deleteDef", {
            id: idi
        }, this, function (data) {
            if (data.status) {
                var data = data.data;
                this.newraport();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    newraport: function () {
        this.lc.currentIdFirma = AppFIRMACTX == null ? null : AppFIRMACTX.id;
        this.lc.currentIdFirmaNazwa = AppFIRMACTX == null ? null : AppFIRMACTX.nazwa;
        this.lc.currentIdFirma2 = !isValidOperatorFirmaLimit() ? null : AppOPERATORFIRMA.id;
        this.lc.currentIdFirmaNazwa2 = !isValidOperatorFirmaLimit() ? null : AppOPERATORFIRMA.nazwa;
        this.lc.currentIdFirma3 = null;
        this.lc.currentIdFirmaNazwa3 = null;
        var rapls = Ext.getCmp(this.id + "rapls");
        var pvals = Ext.getCmp(this.id + "pvals");
        var okrestyp = Ext.getCmp(this.id + "okrestyp");
        var okresod = Ext.getCmp(this.id + "okresod");
        var okresdo = Ext.getCmp(this.id + "okresdo");
        var pavbview = Ext.getCmp(this.id + "pavbview");
        var prowsview = Ext.getCmp(this.id + "prowsview");
        var pcolsview = Ext.getCmp(this.id + "pcolsview");
        rapls.setValue(null);
        okrestyp.setValue("custom");
        okresod.setValue(null);
        okresdo.setValue(null);
        this.reloadSelectokrestyp();
        pavbview.store.add(prowsview.store.getRange());
        prowsview.store.removeAll();
        pavbview.store.add(pcolsview.store.getRange());
        pcolsview.store.removeAll();
        pavbview.store.each(function (n) {
            this.lc.puf[n.get("value")] = [];
            return true;
        }, this);
        prowsview.store.each(function (n) {
            this.lc.puf[n.get("value")] = [];
            return true;
        }, this);
        pcolsview.store.each(function (n) {
            this.lc.puf[n.get("value")] = [];
            return true;
        }, this);
        var firstedUsed = false;
        Ext.each(pvals.items.items, function (v) {
            if (firstedUsed)
                v.setValue(!firstedUsed);
            else
                v.setValue(firstedUsed = true);
        });
        this.reloadRpLs();
        this.loadCube();
        this.reEventElements();
        this.updateFirmName();
    },
    newraport2: function () {
        this.lc.currentIdFirma = AppFIRMACTX == null ? null : AppFIRMACTX.id;
        this.lc.currentIdFirmaNazwa = AppFIRMACTX == null ? null : AppFIRMACTX.nazwa;
        this.lc.currentIdFirma2 = !isValidOperatorFirmaLimit() ? null : AppOPERATORFIRMA.id;
        this.lc.currentIdFirmaNazwa2 = !isValidOperatorFirmaLimit() ? null : AppOPERATORFIRMA.nazwa;
        this.lc.currentIdFirma3 = null;
        this.lc.currentIdFirmaNazwa3 = null;
        var rapls = Ext.getCmp(this.id + "rapls");
        var pvals = Ext.getCmp(this.id + "pvals");
        var okrestyp = Ext.getCmp(this.id + "okrestyp");
        var okresod = Ext.getCmp(this.id + "okresod");
        var okresdo = Ext.getCmp(this.id + "okresdo");
        var pavbview = Ext.getCmp(this.id + "pavbview");
        var prowsview = Ext.getCmp(this.id + "prowsview");
        var pcolsview = Ext.getCmp(this.id + "pcolsview");
        rapls.setValue(null);
        okrestyp.setValue("custom");
        okresod.setValue(null);
        okresdo.setValue(null);
        this.reloadSelectokrestyp();
        pavbview.store.add(prowsview.store.getRange());
        prowsview.store.removeAll();
        pavbview.store.add(pcolsview.store.getRange());
        pcolsview.store.removeAll();
        pavbview.store.each(function (n) {
            this.lc.puf[n.get("value")] = [];
            return true;
        }, this);
        prowsview.store.each(function (n) {
            this.lc.puf[n.get("value")] = [];
            return true;
        }, this);
        pcolsview.store.each(function (n) {
            this.lc.puf[n.get("value")] = [];
            return true;
        }, this);
        var firstedUsed = false;
        Ext.each(pvals.items.items, function (v) {
            if (firstedUsed)
                v.setValue(!firstedUsed);
            else
                v.setValue(firstedUsed = true);
        });
        this.reloadRpLs();
        this.loadCube();
        this.reEventElements();
        this.updateFirmName();
    },
    loadraport: function () {
        var rapls = Ext.getCmp(this.id + "rapls");
        var value = rapls.getValue();
        var value2 = rapls.getValue2NL();
        var rvalue = rapls.getRawValue();
        var hasRow = rapls.isRow();
        var rrvww = (rvalue === value);
        var valueOnListSelected = false;
        if (Ext.isNumber(value2)) {
            valueOnListSelected = true;
        }
        var idi = valueOnListSelected ? value2 : 0;
        if (idi > 0) {
            sendAjaxDataJsonRpcL("rest/reportpivot/loadDef", {
                id: idi
            }, this, function (data) {
                if (data.status) {
                    var data = data.data;
                    if (data != null) {
                        var zawartosc = data.zawartosc;
                        this.lc.currentIdFirma = data.idfirma;
                        this.lc.currentIdFirmaNazwa = data.firmanazwa;
                        this.lc.currentIdFirma2 = data.idfirmacreator;
                        this.lc.currentIdFirmaNazwa2 = data.firmacreatornazwa;
                        this.lc.currentIdFirma3 = data.idfirma;
                        this.lc.currentIdFirmaNazwa3 = data.firmanazwa;
                        var pvals = Ext.getCmp(this.id + "pvals");
                        var pavbview = Ext.getCmp(this.id + "pavbview");
                        var prowsview = Ext.getCmp(this.id + "prowsview");
                        var pcolsview = Ext.getCmp(this.id + "pcolsview");
                        var okrestyp = Ext.getCmp(this.id + "okrestyp");
                        var okresod = Ext.getCmp(this.id + "okresod");
                        var okresdo = Ext.getCmp(this.id + "okresdo");
                        okrestyp.setValue(zawartosc.typeCzas);
                        okresod.setValue(zawartosc.fromDate);
                        okresdo.setValue(zawartosc.toDate);
                        this.reloadSelectokrestyp();
                        pavbview.store.add(prowsview.store.getRange());
                        prowsview.store.removeAll();
                        pavbview.store.add(pcolsview.store.getRange());
                        pcolsview.store.removeAll();
                        Ext.each(pvals.items.items, function (v) {
                            v.setValue(false);
                        });
                        Ext.each(pvals.items.items, function (v) {
                            Ext.each(zawartosc.values, function (v2) {
                                if (v.rcType.typeFunction == v2.typeFunction && v.rcType.plyn == v2.plyn) {
                                    v.setValue(true);
                                }
                            });
                        });
                        var prowsviewR = [];
                        var pcolsviewR = [];
                        Ext.each(zawartosc.rows, function (v) {
                            var ri = pavbview.store.find("value", v.name);
                            if (ri >= 0) {
                                var r = pavbview.store.getAt(ri);
                                prowsviewR.push(r);
                            }
                        });
                        Ext.each(zawartosc.cols, function (v) {
                            var ri = pavbview.store.find("value", v.name);
                            if (ri >= 0) {
                                var r = pavbview.store.getAt(ri);
                                pcolsviewR.push(r);
                            }
                        });
                        prowsview.store.add(prowsviewR);
                        pavbview.store.remove(prowsviewR);
                        pcolsview.store.add(pcolsviewR);
                        pavbview.store.remove(pcolsviewR);
                        pavbview.store.each(function (n) {
                            Ext.each(zawartosc.rest, function (v) {
                                if (v.name == n.get("value"))
                                    this.lc.puf[n.get("value")] = v.filters;
                            }, this);
                            return true;
                        }, this);
                        prowsview.store.each(function (n) {
                            Ext.each(zawartosc.rows, function (v) {
                                if (v.name == n.get("value"))
                                    this.lc.puf[n.get("value")] = v.filters;
                            }, this);
                            return true;
                        }, this);
                        pcolsview.store.each(function (n) {
                            Ext.each(zawartosc.cols, function (v) {
                                if (v.name == n.get("value"))
                                    this.lc.puf[n.get("value")] = v.filters;
                            }, this);
                            return true;
                        }, this);
                        this.lc.lastLoadedZawartosc = zawartosc;
                        this.loadCube();
                        this.reEventElements();
                        this.updateFirmName();
                    }
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        }
    },
    drukujraport: function () {
        if (this.lc.pivot) {
            this.lc.pivot.drukuj();
        }
    },
    excelraport: function () {
        var rapls = Ext.getCmp(this.id + "rapls");
        var rvalue = rapls.getRawValue();
        if (rvalue.trim() == "")
            rvalue = "default";
        if (this.lc.pivot) {
            var data = this.lc.pivot.getAsHtml();
            if (data != null) {
                postFormAsync("rest/reportpivot/downloadExportPdfOther", {
                    format: "excel",
                    name: rvalue,
                    data: data
                }, null);
            }
        }
    },
    loadCube: function () {
        var pivotbd = Ext.getCmp(this.id + "pivotbd");
        if (!this.lc.pivot) {
            this.lc.pivot = Ext.create('RPivot');
            this.lc.pivot.init({}, pivotbd);
        }
        var rdf = this.getRaportDefinition();
        var rdf2 = this.lc.pivot.retriveVEX();
        if (this.lc.lastLoadedZawartosc) {
            rdf2.valuesViewCol = this.lc.lastLoadedZawartosc.valuesViewCol;
            rdf2.valuesViewRow = this.lc.lastLoadedZawartosc.valuesViewRow;
            rdf2.valuesExpandCol = this.lc.lastLoadedZawartosc.valuesExpandCol;
            rdf2.valuesExpandRow = this.lc.lastLoadedZawartosc.valuesExpandRow;
        }
        this.lc.lastLoadedZawartosc = null;
        Ext.apply(rdf, rdf2);
        this.lc.pivot.beforeGenerate();
        if (rdf.values.length == 0) {
            return;
        }
        sendAjaxDataJsonRpcL("rest/reportpivot/loadData", {
            idfirma: this.lc.currentIdFirma3 == null ? this.lc.currentIdFirma : this.lc.currentIdFirma3,
            reportdef: Ext.encode(rdf)
        }, this, function (data) {
            if (data.status) {
                var data = data.data;
                var rows = [];
                var cols = [];
                Ext.each(rdf.rows, function (v) {
                    rows.push(v.name);
                });
                Ext.each(rdf.cols, function (v) {
                    cols.push(v.name);
                });
                if (this.lc && this.lc.pivot)
                    this.lc.pivot.show(rows, cols, rdf.values, data);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    }
});
