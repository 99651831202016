Ext.define('WindowBackupHarmonogram', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.harmonogram.capitalise(),
    width: 300,
    scrollable: true,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    scrollable: true,
    initComponent: function () {
        this.cid = Ext.id();
        this.monthdaysss = [];
        for (var i = 0; i < 31; i++) {
            this.monthdaysss.push([i + 1, i + 1]);
        }

        this.monthdaysss.push([Const.TIME_DAY_MONTH_LAST, Lang.ostatni_dzien_miesiaca]);
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:5px 5px 5px 5px',
                scrollable: true,
                defaults: {
                    labelWidth: 100
                },
                items: [{
                    id: this.cid + "active",
                    name: "active",
                    xtype: 'checkboxexd',
                    fieldLabel: Lang.active,
                    listeners: {
                        change: this.monitorSSDD,
                        scope: this
                    }
                }, {
                    name: "type",
                    id: this.cid + 'type',
                    xtype: 'comboexd',
                    store: [[Const.BACKUP_CZAS_TYPE_DAY, Lang.dzienny], [Const.BACKUP_CZAS_TYPE_WEEK, Lang.tygodniowy], [Const.BACKUP_CZAS_TYPE_DAY_MONTH, Lang.miesieczny]],
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    fieldLabel: Lang.typ,
                    listeners: {
                        select: this.monitorSSDD,
                        scope: this
                    }
                }, {
                    name: "weekdays",
                    id: this.cid + 'weekdays',
                    xtype: 'comboexd',
                    store: [[Const.TIME_WEEK_DAY_NIEDZIELA, Lang.niedziela], [Const.TIME_WEEK_DAY_PONIEDZIALEK, Lang.poniedzialek], [Const.TIME_WEEK_DAY_WTOREK, Lang.wtorek], [Const.TIME_WEEK_DAY_SRODA, Lang.sroda], [Const.TIME_WEEK_DAY_CZWARTEK, Lang.czwartek], [Const.TIME_WEEK_DAY_PIATEK, Lang.piatek], [Const.TIME_WEEK_DAY_SOBOTA, Lang.sobota]],
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    fieldLabel: Lang.dzien_tygodnia,
                    listeners: {
                        select: this.monitorSSDD,
                        scope: this
                    },
                    value: Const.TIME_WEEK_DAY_NIEDZIELA_ENG
                }, {
                    name: "monthdays",
                    id: this.cid + 'monthdays',
                    xtype: 'comboexd',
                    store: this.monthdaysss,
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    fieldLabel: Lang.dzien_miesiaca,
                    listeners: {
                        select: this.monitorSSDD,
                        scope: this
                    }
                }, {
                    id: this.cid + "czas",
                    name: "czas",
                    xtype: 'datefieldexdmod',
                    fieldLabel: Lang.godzina,
                    format: "H:i:s",
                    allowBlank: false,
                    hideTrigger: true,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    value: Ext.Date.parse("2000-01-01 00:00:00", Date.patterns.ISO8601Long)
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    monitorSSDD: function () {
        if (Ext.getCmp(this.cid + 'active').getValue()) {
            Ext.getCmp(this.cid + 'type').setDisabled(false);
            Ext.getCmp(this.cid + 'weekdays').setDisabled(false);
            Ext.getCmp(this.cid + 'monthdays').setDisabled(false);
            Ext.getCmp(this.cid + 'czas').setDisabled(false);
            if (Ext.getCmp(this.cid + 'type').getValue() == Const.BACKUP_CZAS_TYPE_DAY) {
                Ext.getCmp(this.cid + 'weekdays').setDisabled(true);
                Ext.getCmp(this.cid + 'monthdays').setDisabled(true);
            }
            if (Ext.getCmp(this.cid + 'type').getValue() == Const.BACKUP_CZAS_TYPE_WEEK) {
                Ext.getCmp(this.cid + 'weekdays').setDisabled(false);
                Ext.getCmp(this.cid + 'monthdays').setDisabled(true);
            }
            if (Ext.getCmp(this.cid + 'type').getValue() == Const.BACKUP_CZAS_TYPE_DAY_MONTH) {
                Ext.getCmp(this.cid + 'weekdays').setDisabled(true);
                Ext.getCmp(this.cid + 'monthdays').setDisabled(false);
            }
        } else {
            Ext.getCmp(this.cid + 'type').setDisabled(true);
            Ext.getCmp(this.cid + 'weekdays').setDisabled(true);
            Ext.getCmp(this.cid + 'monthdays').setDisabled(true);
            Ext.getCmp(this.cid + 'czas').setDisabled(true);
        }
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex("access.manage.backup")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        sendAjaxDataJsonRpcL("rest/backup/getHarmonogram", null, this, function (data) {
            if (data.status) {
                data = data.data;
                if (data.type != undefined) {
                    Ext.getCmp(this.cid + 'type').setValue(data.type);
                }
                if (data.weekdays != undefined) {
                    Ext.getCmp(this.cid + 'weekdays').setValue(data.weekdays);
                }
                Ext.getCmp(this.cid + 'monthdays').setValue(data.monthdays != undefined ? data.monthdays : 0);
                Ext.getCmp(this.cid + 'active').setValue(data.active);
                Ext.getCmp(this.cid + 'czas').setValue2(data.czas == null ? Ext.Date.parse("2000-01-01 00:00:00", Date.patterns.ISO8601Long) : Ext.Date.parse(data.czas, Date.patterns.ISO8601Long));
                this.monitorSSDD();
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        if (!Ext.getCmp(this.cid + "formpanel").form.isValid()) {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        sendAjaxDataJsonRpcL("rest/backup/saveHarmonogram", {
            type: Ext.getCmp(this.cid + 'type').getValue(),
            weekdays: Ext.getCmp(this.cid + 'weekdays').getValue(),
            monthdays: Ext.getCmp(this.cid + 'monthdays').getValue(),
            czas: Ext.getCmp(this.cid + 'czas').getValue2(),
            active: Ext.getCmp(this.cid + 'active').getValue()
        }, this, function (data) {
            if (data.status) {
                data = data.data;
                this.close();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    closeOp: function () {
        this.close();
    }
});
