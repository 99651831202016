Ext.define('ViewSkpPomiaryMobile', {
    extend: 'Ext.panel.Panel',
    grid: null,
    gridpage: null,
    id: "skppomiary",
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyViewName: "access.view.skpspomiary"
    },
    cat: "p",
    text: Lang.pomiary_zbiornika,
    buttonIconCls: "iconSkpPomiary",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            itemId: 'kontekstfirmy-container',
            xtype: 'container',
            region: 'north',
            hidden: !(AppFIRMACTX != null),
            style: 'background-color: white',
            items: [{
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                cls: "kontekstfirmy-text kontekstfirmy-text-high kontekstfirmy-text-mobile"
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
        if (this.grid && this.grid.rendered)
            this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        if (AppFIRMACTX != null) {
            kontekstfirmy.setText(AppFIRMACTX.nazwa);
        } else {
            kontekstfirmy.setText("");
        }
        this.down('#kontekstfirmy-container').setHidden(!(AppFIRMACTX != null));
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            var onwer = this.grid.ownerCt;
            Ext.suspendLayouts();
            onwer.remove(this.grid);
            this.grid.destroy();
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
            if (this.grid && this.grid.rendered)
                if (this.grid.getDockedComponent(1))
                    this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
    },
    buildGrid: function (datals) {
        var store = Ext.create('Ext.data.Store', {
            autoLoad: false,
            remoteSort: true,
            remoteFilter: true,
            pageSize: 50,
            fields: [{
                name: 'czas',
                mapping: 'czas',
                type: 'date'
            }, {
                name: 'skp',
                mapping: 'skp'
            }, {
                name: 'sterownik',
                mapping: 'sterownik'
            }, {
                name: 'plyn',
                mapping: 'plyn'
            }, {
                name: 'zbiornik',
                mapping: 'zbiornik'
            }, {
                name: 'addinfo',
                mapping: 'addinfo'
            }, {
                name: 'lastpomiar',
                mapping: 'lastpomiar'
            }, {
                name: 'lastpomiartemp',
                mapping: 'lastpomiartemp'
            }, {
                name: 'laststateflags',
                mapping: 'laststateflags'
            }, {
                name: 'laststateflagssckpstate',
                mapping: 'laststateflagssckpstate'
            }, {
                name: 'laststateflagststate',
                mapping: 'laststateflagststate'
            }, {
                name: 'laststateflagsskpstate',
                mapping: 'laststateflagsskpstate'
            }, {
                name: 'wartoscprzetwornika',
                mapping: 'wartoscprzetwornika'
            }],
            proxy: {
                type: "ajax",
                url: "rest/skppom/getList",
                filterParams: "filters",
                actionMethods: {
                    read: "POST"
                },
                reader: {
                    type: 'json',
                    id: 'id',
                    rootProperty: 'data',
                    totalProperty: 'total'
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            sorters: {
                property: 'czas',
                direction: 'DESC'
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        var rendererCKPFlags = function (value, meta, record, rowIndex, colIndex, store) {
            var sstyle = "";
            if (record.get("iserror")) {
                sstyle = "color:red";
            }
            return "<span style='" + sstyle + "'>" + value + "</span>";

        };
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true,
                rowexpander: {
                    scrollIntoViewOnExpand: false,
                    rowBodyTpl: new Ext.XTemplate(
                        `<div class="mobile-rowexp-wrapper">
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.czas}:
                                <span class="mobile-rowexp-value"> {czas:this.czas}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.zbiornik}:
                                <span class="mobile-rowexp-value"> {zbiornik}</span>
                            </span>
                        </div>
                        
                        <tpl if="plyn != null">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.plyn}:
                                    <span class="mobile-rowexp-value"> {plyn}</span>
                                </span>
                            </div>
                        </tpl>
                    
                        <tpl if="lastpomiar != null">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.pomiar_zbiornika}:
                                    <span class="mobile-rowexp-value"> {lastpomiar}</span>
                                </span>
                            </div>
                        </tpl>
                    
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.stan_pomiaru}:
                                <span class="mobile-rowexp-value"> {stateinfo}</span>
                            </span>
                        </div>
                    
                        <tpl if="lastpomiarbezkompensacji != null">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.wartosc_nieskompensowana}:
                                    <span class="mobile-rowexp-value"> {lastpomiarbezkompensacji}</span>
                                </span>
                            </div>
                        </tpl>
                    
                        <tpl if="lastpomiartemp != null">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.pomiar_temperatury}:
                                    <span class="mobile-rowexp-value"> {lastpomiartemp}</span>
                                </span>
                            </div>
                        </tpl>
                    
                        <tpl if="skp != null">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.skp}:
                                    <span class="mobile-rowexp-value"> {skp}</span>
                                </span>
                            </div>
                        </tpl>
                    
                        <tpl if="wartoscprzetwornika != null">
                            <div class="mobile-rowexp-row" style="display: none">
                                <span class="mobile-rowexp-title">${Lang.wartosc_przetwornika}:
                                    <span class="mobile-rowexp-value"> {wartoscprzetwornika}</span>
                                </span>
                            </div>
                        </tpl>
                    
                        <tpl if="this.notEmptyAfterTrim(sterownik)">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.sterownik}:
                                    <span class="mobile-rowexp-value"> {sterownik}</span>
                                </span>
                            </div>
                        </tpl>
                    </div>`,
                        {
                            czas: Ext.util.Format.dateRenderer(),
                            notEmptyAfterTrim
                        }
                    )
                }
            },
            store: store,
            columns: [{
                header: Lang.czas,
                flex: 3,
                sortable: true,
                dataIndex: 'czas',
                renderer: Ext.util.Format.dateRenderer(),
                filter: {
                    type: 'date'
                },
                filterable: true
            }, {
                header: Lang.zbiornik,
                flex: 2,
                sortable: true,
                dataIndex: 'zbiornik',
                filter: {
                    type: 'string'
                },
                hidden: false,
                filterable: true,
                renderer: function (value, meta, record, rowIndex, colIndex, store) {
                    return rendererCKPFlags(value, meta, record, rowIndex, colIndex, store);
                }
            }, {
                header: Lang.pomiar_zbiornika,
                flex: 1,
                sortable: true,
                dataIndex: 'lastpomiar',
                renderer: function (value, meta, record, rowIndex, colIndex, store) {
                    if (value == null)
                        return null;
                    value = Ext.Number.roundToPrecision(value, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                    return rendererCKPFlags(value, meta, record, rowIndex, colIndex, store);
                },
                filter: {
                    type: 'numeric'
                },
                filterable: true
            }, {
                header: Lang.stan_pomiaru,
                flex: 2,
                sortable: true,
                dataIndex: 'stateinfo',
                // filter: {
                //     type: 'string'
                // },
                hidden: false,
                filterable: true,
                renderer: function (value, meta, record, rowIndex, colIndex, store) {
                    return rendererCKPFlags(value, meta, record, rowIndex, colIndex, store);
                }
            }, {
                header: Lang.pomiar_temperatury,
                wflex: 1,
                sortable: true,
                dataIndex: 'lastpomiartemp',
                renderer: function (value, meta, record, rowIndex, colIndex, store) {
                    if (value == null)
                        return null;
                    value = Ext.Number.roundToPrecision(value, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                    return rendererCKPFlags(value, meta, record, rowIndex, colIndex, store);
                },
                filter: {
                    type: 'numeric'
                },
                filterable: true,
                hidden: true
            }, {
                header: Lang.plyn,
                flex: 2,
                sortable: true,
                dataIndex: 'plyn',
                filter: {
                    type: 'string'
                },
                hidden: true,
                filterable: true,
                renderer: function (value, meta, record, rowIndex, colIndex, store) {
                    return rendererCKPFlags(value, meta, record, rowIndex, colIndex, store);
                }
            }],
            frame: false,
            border: false,
            stateId: "grid-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            },
            bbar: {
                xtype: 'pagetoolbarown',
                displayInfo: true,
                pagingStore: store
            }
        });
        this.grid.store.addListener("load", function () {
            updateCzasRefreshSerwer();
        });
    },
    refreshDatastore: function () {
        this.grid.store.load();
    }
});
