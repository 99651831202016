Ext.define('WindowAbout', {
    extend: 'Ext.ux.window.WindowExd',
    width: 370,
    height: 238,
    frame: false,
    border: false,
    header: false,
    closable: false,
    plain: true,
    style: "background-color: transparent; border-style: none;",
    shadow: false,
    collapsible: false,
    modal: true,
    resizable: false,
    constrain: false,
    constrainHeader: false,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        var leftTxtDays = new Ext.XTemplate(Lang.pozostalo_n_dni).apply({
            n: AppLeftdays
        });
        var viewClsImageTiitle1 = "";
        if (Const.SKPFLAG) {
            viewClsImageTiitle1 = "resources/main-app/public/about/about1skp.png";
        } else if (Const.OPTIMALFLAG) {
            viewClsImageTiitle1 = "resources/main-app/public/about/about1opt.png";
        } else if (Const.ENTERPRISEFLAG) {
            viewClsImageTiitle1 = "resources/main-app/public/about/about1.png";
        } else if (Const.BASICFLAG) {
            if (Const.CUSTOMMODFLAG == 'swimer') {
                viewClsImageTiitle1 = "resources/main-app/public/about/about1swimer.png";
            } else {
                viewClsImageTiitle1 = "resources/main-app/public/about/about1basic.png";
            }

        } else if (Const.STARTERFLAG) {
            viewClsImageTiitle1 = "resources/main-app/public/about/about1starter.png";
        } else if (Const.STANDARDFLAG) {
            viewClsImageTiitle1 = "resources/main-app/public/about/about1std.png";
        } else {
            viewClsImageTiitle1 = "resources/main-app/public/about/about1pro.png";
        }
        var viewClsImageTiitle2 = "";
        if (Const.SKPFLAG) {
            viewClsImageTiitle2 = "resources/main-app/public/about/about3skp.png";
        } else if (Const.OPTIMALFLAG) {
            viewClsImageTiitle2 = "resources/main-app/public/about/about3opt.png";
        } else if (Const.ENTERPRISEFLAG) {
            viewClsImageTiitle2 = "resources/main-app/public/about/about3.png";
        } else if (Const.BASICFLAG) {
            if (Const.CUSTOMMODFLAG == 'swimer') {
                viewClsImageTiitle2 = "resources/main-app/public/about/about3swimer.png";
            } else {
                viewClsImageTiitle2 = "resources/main-app/public/about/about3basic.png";
            }
        } else if (Const.STARTERFLAG) {
            viewClsImageTiitle2 = "resources/main-app/public/about/about3starter.png";
        } else if (Const.STANDARDFLAG) {
            viewClsImageTiitle2 = "resources/main-app/public/about/about3std.png";
            // } else if (Const.CLOUDFLAG) {
            //     viewClsImageTiitle2 = "resources/main-app/public/about/about3cloud.png";
        } else {
            viewClsImageTiitle2 = "resources/main-app/public/about/about3pro.png";
        }
        this.items = [{
            xtype: 'panel',
            bodyStyle: 'background-color: #e4e4e4;',
            defaults: {
                bodyStyle: 'background-color: #e4e4e4;',
                style: 'background-color: #e4e4e4;'
            },
            items: [{
                xtype: 'box',
                border: false,
                frame: false,
                width: '100%',
                html: [{
                    tag: "img",
                    src: true ? viewClsImageTiitle2 : viewClsImageTiitle1,
                    style: "z-index:1; object-fit: contain; width: 100%; height: 100%;"
                }, {
                    tag: "div",
                    style: "position:absolute;top:3px;left:15px;z-index:2;color:black;font: bold 11px arial, tahoma, verdana, sans-serif",
                    html: Lang.o_programie.capitalise()
                }, {
                    id: this.cid + "arccan",
                    tag: "div",
                    style: `position:absolute;top:${WindowExdMobileDEVICEV && Ext.getBody().getViewSize().width < 340 ? '55' : '63'}px;left:20px;z-index:2;color:#dc5b00;font: bold 9px arial, tahoma, verdana, sans-serif;cursor:pointer`,
                    html: "www.arccan.eu"
                }]
            }, {
                xtype: 'panel',
                items: [{
                    xtype: 'box',
                    html: 'Copyright &copy; ' + new Date().getFullYear(),
                    style: 'float: right; padding: 4px 20px 0 0; font: bold 12px arial, tahoma, verdana, sans-serif;'
                }]
            }, {
                xtype: 'panel',
                items: [{
                    layout: 'hbox',
                    itemId: 'productKey-panel',
                    hidden: true,
                    style: 'padding: 10px 0 0 70px;background-color: #e4e4e4;',
                    items: [{
                        html: Lang.klucz_produktu + ":",
                        bodyStyle: 'color:#dc5b00;font: bold 12px arial, tahoma, verdana, sans-serif;background-color: #e4e4e4;'
                    }, {
                        itemId: 'productKey-box',
                        width: '100%',
                        bodyStyle: 'font: bold 12px arial, tahoma, verdana, sans-serif; padding-left: 15px;background-color: #e4e4e4;'
                    }]
                }, {
                    layout: 'hbox',
                    itemId: 'maintenanceTo-panel',
                    hidden: true,
                    style: 'padding: 10px 0 0 70px;background-color: #e4e4e4;',
                    items: [{
                        html: Lang.wsparcie_do + ":",
                        bodyStyle: 'color:#dc5b00;font: bold 12px arial, tahoma, verdana, sans-serif;background-color: #e4e4e4;'
                    }, {
                        itemId: 'maintenanceTo-box',
                        width: '100%',
                        bodyStyle: 'font: bold 12px arial, tahoma, verdana, sans-serif; padding-left: 15px;background-color: #e4e4e4;'
                    }]
                }, {
                    xtype: 'box',
                    hidden: AppActivated,
                    html: AppActivated ? "" : Lang.ten_produkt_nie_jest_jeszcze_aktywowany,
                    style: 'padding: 10px 0 0 70px; color:#dc5b00;font: bold 12px arial, tahoma, verdana, sans-serif;'
                }, {
                    xtype: 'box',
                    hidden: AppActivated,
                    html: AppActivated ? "" : leftTxtDays,
                    style: 'padding: 10px 0 0 70px; color:#dc5b00;font: bold 12px arial, tahoma, verdana, sans-serif;'
                }, {
                    xtype: 'panel',
                    items: [{
                        xtype: 'box',
                        itemId: 'opiekun-panel',
                        hidden: true,
                        html: Lang.opiekun.toUpperCase(),
                        style: 'text-align: center; color: #dc5b00;font: bold 12px arial, tahoma, verdana, sans-serif;background-color: #e4e4e4; padding-top: 20px;'
                    }, {
                        xtype: 'box',
                        itemId: 'opiekun-box',
                        style: 'text-align: center; font: bold 12px arial, tahoma, verdana, sans-serif;background-color: #e4e4e4; padding-top: 5px;'
                    }]
                }, {
                    layout: 'hbox',
                    style: 'padding: 20px 0 0 70px;background-color: #e4e4e4;',
                    items: [{
                        html: Lang.wersja.capitalise() + ":",
                        bodyStyle: 'color:#dc5b00;font: bold 12px arial, tahoma, verdana, sans-serif;background-color: #e4e4e4;'
                    }, {
                        html: Const.VERSIONEND,
                        width: '100%',
                        bodyStyle: 'font: bold 12px arial, tahoma, verdana, sans-serif; padding-left: 15px;background-color: #e4e4e4;'
                    }]
                }, {
                    xtype: 'box',
                    itemId: 'is-update-panel',
                    hidden: true,
                    html: Lang.aktualizacja_dostepna.toUpperCase(),
                    style: 'text-align: center; color: #dc5b00;font: bold 12px arial, tahoma, verdana, sans-serif;background-color: #e4e4e4; padding-top: 10px;'
                }, {
                    layout: 'hbox',
                    itemId: 'newest-version-panel',
                    hidden: true,
                    style: 'padding: 10px 0 0 70px;background-color: #e4e4e4;',
                    items: [{
                        html: Lang.najnowsza_wersja + ":",
                        bodyStyle: 'color:#dc5b00;font: bold 12px arial, tahoma, verdana, sans-serif;background-color: #e4e4e4;'
                    }, {
                        itemId: 'newest-version-box',
                        html: '',
                        width: '100%',
                        bodyStyle: 'font: bold 12px arial, tahoma, verdana, sans-serif; padding-left: 15px;background-color: #e4e4e4;'
                    }]
                }, Ext.create('Ext.panel.Panel', {
                    id: this.cid + 'btnpanel',
                    style: 'margin-top: 20px;',
                    cls: 'about-btnpanel',
                    layout: {
                        type: 'hbox',
                        align: 'stretch',
                        pack: 'center'
                    },
                    items: [{
                        xtype: 'button',
                        id: this.cid + "okb",
                        text: Lang.ok.capitalise(),
                        height: 20,
                        scale: 'small',
                        style: 'margin-right: 10px',
                        width: 65,
                        listeners: {
                            scope: this,
                            click: function () {
                                this.closeOp();
                            }
                        }
                    }, {
                        xtype: 'button',
                        id: this.cid + "pobierzb",
                        text: Lang.pobierz.capitalise(),
                        height: 20,
                        scale: 'small',
                        style: 'margin-right: 10px',
                        hidden: WindowExdMobileDEVICEV,
                        listeners: {
                            scope: this,
                            click: function () {
                                var w = Ext.create('WindowPobierz');
                                w.showW();
                            }
                        }
                    }, {
                        xtype: 'button',
                        id: this.cid + "licencjab",
                        text: Lang.licencja.capitalise(),
                        height: 20,
                        scale: 'small',
                        listeners: {
                            scope: this,
                            click: function () {
                                var w = Ext.create('WActivate');
                                w.showW();
                            }
                        }
                    }]
                })]
            }]
        }];
        this.callParent(arguments);
    },
    showW: function () {
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
        Ext.get(this.cid + "arccan").on("click", function () {
            window.location = "http://www.arccan.eu";
        }, this);
        Ext.get(this.cid + "arccan").on("mouseover", function (e, t, o) {
            Ext.get(t).applyStyles("border:1px solid #dc5b00;padding:1px;position:absolute;top:61px;left:18px");
        }, this);
        Ext.get(this.cid + "arccan").on("mouseout", function (e, t, o) {
            Ext.get(t).applyStyles("border:0px solid #dc5b00;padding:0px;position:absolute;top:63px;left:20px");
        }, this);
        if (!validPrivilegesOperatorComplex("access.manage.activation")) {
            Ext.get(this.cid + "okb").applyStyles({
                left: "105px"
            });
            if (!WindowExdMobileDEVICEV)
                Ext.get(this.cid + "pobierzb").applyStyles({
                    left: "185px"
                });
            Ext.get(this.cid + "licencjab").applyStyles({
                display: "none"
            });
        }
        if (!Const.CLOUDFLAG) {
            this.mask(Lang.ladowanie);
            performAjaxLPromise('rest/activationmgr/getObjectData').then(data => {
                if (data && data.productKey) {
                    this.down('#productKey-panel').setHidden(false);
                    this.down('#productKey-box').setHtml(data.productKey);
                    this.addHeight(24);
                }
            }).catch(err => {
                Ext.MessageBox.alert(Lang.error, err);
            });
            performAjaxLPromise('rest/maintenanceinfo/getUpdateInfo').then(data => {
                if (data) {
                    let heightSum = 0;
                    if (data.actualizationAvailable) {
                        heightSum += 45;
                        this.down('#is-update-panel').setHidden(false);
                        this.down('#newest-version-panel').setHidden(false);
                        this.down('#newest-version-box').setHtml(data.latestAvailableActualizationVersion);
                    }
                    if (data.opiekun) {
                        this.down('#opiekun-panel').setHidden(false);
                        this.down('#opiekun-box').setHtml(`${data.opiekun.name} ${data.opiekun.surname}<br>${data.opiekun.firma}<br>
                            ${data.opiekun.email}<br>${data.opiekun.telefon}`);
                        heightSum += 68;
                    }
                    if (data.maintenanceTo) {
                        this.down('#maintenanceTo-panel').setHidden(false);
                        this.down('#maintenanceTo-box').setHtml(data.maintenanceTo);
                    }
                    this.addHeight(heightSum);
                }
            }).catch(err => {
                Ext.MessageBox.alert(Lang.error, err);
            }).finally(() => {
                this.unmask();
            });
        }
        if (!AppActivated) {
            this.addHeight(44);
        }
    },
    closeOp: function () {
        this.close();
    },
    addHeight: function (height) {
        this.setHeight(this.getHeight() + height);
        this.center();
    }
});
