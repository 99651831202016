Ext.define('WindowZaladunek', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.zaladunek.capitalise(),
    width: 750,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: 'fit',
    initComponent: function (config) {
        this.cid = Ext.id();
        let formpanel = {
            id: this.cid + "formpanel",
            xtype: 'form',
            frame: true,
            border: false,
            autoWidth: true,
            autoHeight: true,
            scrollable: true,
            labelWidth: 80,
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0",
                labelWidth: 80
            },
            layout: {
                type: 'vbox',
                align: 'stretch'
            },
            fileUpload: true,
            items: [{
                anchor: "0",
                layout: 'column',
                frame: false,
                border: false,
                items: [{
                    columnWidth: WindowExdMobileDEVICEV ? 1 : .5,
                    layout: 'anchor',
                    frame: false,
                    border: false,
                    labelWidth: 120,
                    style: 'padding-left: 10px; padding-top: 10px;',
                    defaults: {
                        anchor: "0",
                        labelWidth: 120,
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: [{
                        id: this.cid + 'idzbiornik',
                        xtype: 'comboexd',
                        valueField: 'id',
                        displayField: 'nazwa',
                        store: new Ext.data.JsonStore({
                            id: "id",
                            rootProperty: '',
                            sorters: [{
                                property: "nazwa",
                                direction: "ASC"
                            }],
                            fields: [{
                                name: 'id',
                                mapping: 'id'
                            }, {
                                name: 'nazwa',
                                mapping: 'nazwa'
                            }, {
                                name: 'nazwa',
                                mapping: 'nazwa'
                            }, {
                                name: 'stanzbiornika',
                                mapping: 'stanzbiornika',
                                type: 'float'
                            }, {
                                name: 'stanzbiornikareal',
                                mapping: 'stanzbiornikareal',
                                useNull: true,
                                type: 'float'
                            }, {
                                name: 'stanzbiornikarealbefore',
                                mapping: 'stanzbiornikarealbefore'
                            }, {
                                name: 'stanzbiornikarealafter',
                                mapping: 'stanzbiornikareal'
                            }, {
                                name: 'stanzbiornikadelta',
                                mapping: 'stanzbiornikadelta'
                            }, {
                                name: 'pojemnosc',
                                mapping: 'pojemnosc',
                                type: 'float'
                            }, {
                                name: 'stanalarmowy',
                                mapping: 'stanalarmowy',
                                type: 'float'
                            }, {
                                name: 'stanblokady',
                                mapping: 'stanblokady',
                                type: 'float'
                            }]
                        }),
                        triggerAction: 'all',
                        resizable: false,
                        editable: false,
                        mode: "local",
                        fieldLabel: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG ? Lang.sterownik : Lang.zbiornik,
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        tpl: new Ext.XTemplate('<tpl for="."><li role="option" unselectable="on" class="x-boundlist-item" tabindex="-1" data-recordindex="{#}" data-recordid="{#+1}" data-boundview=""">{nazwa} - <i>{[this.ffrdv(values.stanzbiornika)]}</i></li></tpl>', {
                            ffrdv: function (v) {
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            }
                        }),
                        listeners: {
                            scope: this,
                            select: function (c, r, i) {
                                Ext.getCmp(this.cid + "stan").setValue(r.get('stanzbiornika'));
                                Ext.getCmp(this.cid + "stanzbiornikareal").setValue(r.get('stanzbiornikareal'));
                                Ext.getCmp(this.cid + "stan2").setValue(r.get('stanzbiornika'));
                                Ext.getCmp(this.cid + "pojemnosc").setValue(r.get('pojemnosc'));
                                Ext.getCmp(this.cid + "stanalarmowy").setValue(r.get('stanalarmowy'));
                                Ext.getCmp(this.cid + "stanblokady").setValue(r.get('stanblokady'));
                                Ext.getCmp(this.cid + "gestoscoperacji").allowBlank = !r.get("gestoscwymagana");
                                this.oldGestoscsrednia = r.get('gestoscsrednia');
                                Ext.getCmp(this.cid + "gestoscsrednia").setValue(this.oldGestoscsrednia);
                                if (Const.ENABLENEFPROCESSING) {
                                    var disableEnvironmentalParams = !r.get('environmentalPlyn');
                                    Ext.getCmp(this.cid + "so2").setDisabled(disableEnvironmentalParams);
                                    Ext.getCmp(this.cid + "no2").setDisabled(disableEnvironmentalParams);
                                    Ext.getCmp(this.cid + "aromater").setDisabled(disableEnvironmentalParams);
                                    Ext.getCmp(this.cid + "dust").setDisabled(disableEnvironmentalParams);
                                    Ext.getCmp(this.cid + "ggr").setDisabled(disableEnvironmentalParams);
                                    Ext.getCmp(this.cid + "fillnef").setDisabled(disableEnvironmentalParams);
                                }
                            }
                        },
                        cls: "x-item-disabled-sec"
                    }, {
                        xtype: 'numberfieldexd',
                        id: this.cid + "pojemnosc",
                        name: "pojemnosc",
                        decimalPrecision: 3,
                        fieldLabel: Lang.pojemnosc + Lang.jednostka_text,
                        disabled: true,
                        cls: "x-item-disabled-sec"
                    }, {
                        xtype: 'numberfieldexd',
                        id: this.cid + "stanalarmowy",
                        name: "stanalarmowy",
                        decimalPrecision: 3,
                        fieldLabel: Lang.stanalarmowy + Lang.jednostka_text,
                        disabled: true,
                        cls: "x-item-disabled-sec"
                    }, {
                        xtype: 'numberfieldexd',
                        id: this.cid + "stanblokady",
                        name: "stanblokady",
                        decimalPrecision: 3,
                        fieldLabel: Lang.stanblokady + Lang.jednostka_text,
                        disabled: true,
                        cls: "x-item-disabled-sec"
                    }, {
                        id: this.cid + "gestoscsrednia",
                        name: "gestoscsrednia",
                        xtype: 'numberfieldexd',
                        fieldLabel: Lang.gestosc_srednia + "[kg/m^3]",
                        allowBlank: true,
                        allowNegative: false,
                        decimalPrecision: 3,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        minValue: 0.000,
                        maxValue: 9999999,
                        validator: Ext.Function.bind(function (v) {
                            return this.validIle;
                        }, this, []),
                        cls: "x-item-disabled-sec",
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                    }, {
                        xtype: 'numberfieldexd',
                        id: this.cid + "kosztcalosc",
                        name: "kosztcalosc",
                        allowNegative: false,
                        decimalPrecision: 3,
                        fieldLabel: Lang.koszt,
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        disabled: true,
                        hidden: true //hidden: !Const.COSTCOLUMNS
                    }, {
                        xtype: 'numberfieldexd',
                        id: this.cid + "so2",
                        name: "so2",
                        allowNegative: false,
                        decimalPrecision: 3,
                        fieldLabel: Lang.so2 + "[mg/kWh]",
                        // allowBlank: false,
                        minValue: 0.000,
                        maxValue: 9999999,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        hidden: !Const.ENABLENEFPROCESSING
                    }, {
                        xtype: 'numberfieldexd',
                        id: this.cid + "no2",
                        name: "no2",
                        allowNegative: false,
                        decimalPrecision: 3,
                        fieldLabel: Lang.no2 + "[mg/kWh]",
                        // allowBlank: false,
                        minValue: 0.000,
                        maxValue: 9999999,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        hidden: !Const.ENABLENEFPROCESSING
                    }, {
                        xtype: 'numberfieldexd',
                        id: this.cid + "aromater",
                        name: "aromater",
                        allowNegative: false,
                        decimalPrecision: 3,
                        fieldLabel: Lang.aromater + "[mg/kWh]",
                        // allowBlank: false,
                        minValue: 0.000,
                        maxValue: 9999999,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        hidden: !Const.ENABLENEFPROCESSING
                    }]
                }]
            }]
        };
        let formitems = [{
            xtype: 'numberfieldexd',
            id: this.cid + "stan",
            name: "stan",
            decimalPrecision: 3,
            fieldLabel: Lang.stan_obecny + Lang.jednostka_text,
            disabled: true,
            cls: "x-item-disabled-sec",
            hidden: Const.SKPFLAG
        }, {
            xtype: 'numberfieldexd',
            id: this.cid + "stanzbiornikareal",
            name: "stanzbiornikareal",
            decimalPrecision: 3,
            fieldLabel: Lang.stanzbiornikareal + Lang.jednostka_text,
            disabled: true,
            hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
            cls: "x-item-disabled-sec"
        }, {
            xtype: 'numberfieldexd',
            id: this.cid + "stanzbiornikarealbefore",
            name: "stanzbiornikarealbefore",
            decimalPrecision: 3,
            fieldLabel: Lang.stan_zbiornika_real_before + Lang.jednostka_text,
            disabled: true,
            hidden: !validPrivilegesOperatorComplex(ViewSkpPomiary.accessPropertyViewName) || !(Const.OPTIMALFLAG || Const.PROFLAG || Const.ENTERPRISEFLAG),
            cls: "x-item-disabled-sec"
        }, {
            xtype: 'numberfieldexd',
            id: this.cid + "stanzbiornikarealafter",
            name: "stanzbiornikarealafter",
            decimalPrecision: 3,
            fieldLabel: Lang.stan_zbiornika_real + Lang.jednostka_text,
            disabled: true,
            hidden: !validPrivilegesOperatorComplex(ViewSkpPomiary.accessPropertyViewName) || !(Const.OPTIMALFLAG || Const.PROFLAG || Const.ENTERPRISEFLAG),
            cls: "x-item-disabled-sec"
        }, {
            xtype: 'numberfieldexd',
            id: this.cid + "stanzbiornikadelta",
            name: "stanzbiornikadelta",
            decimalPrecision: 3,
            fieldLabel: Lang.stan_zbiornika_delta + Lang.jednostka_text,
            disabled: true,
            hidden: !validPrivilegesOperatorComplex(ViewSkpPomiary.accessPropertyViewName) || !(Const.OPTIMALFLAG || Const.PROFLAG || Const.ENTERPRISEFLAG),
            cls: "x-item-disabled-sec"
        }, {
            xtype: 'numberfieldexd',
            id: this.cid + "stan2",
            name: "stan2",
            decimalPrecision: 3,
            fieldLabel: Lang.stan_pozaladunku + Lang.jednostka_text,
            disabled: true,
            cls: "x-item-disabled-sec"
        }, {
            id: this.cid + "ile",
            name: "ile",
            xtype: 'numberfieldexd',
            fieldLabel: Lang.wartosc + Lang.jednostka_text,
            allowBlank: false,
            allowNegative: false,
            decimalPrecision: 3,
            blankText: Lang.pole_nie_moze_byc_puste,
            minValue: 0.001,
            maxValue: 9999999,
            validator: Ext.Function.bind(function (v) {
                return this.validIle;
            }, this, []),
            cls: "x-item-disabled-sec"
        }, {
            id: this.cid + "gestoscoperacji",
            name: "gestoscoperacji",
            xtype: 'numberfieldexd',
            fieldLabel: Lang.gestosc + "[kg/m^3]",
            allowBlank: true,
            allowNegative: false,
            decimalPrecision: 3,
            blankText: Lang.pole_nie_moze_byc_puste,
            minValue: 600,
            maxValue: 1100,
            validator: Ext.Function.bind(function (v) {
                return this.validIle;
            }, this, []),
            cls: "x-item-disabled-sec",
            hidden: Const.SKPFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
        }, {
            xtype: 'numberfieldexd',
            id: this.cid + "kosztlitr",
            name: "kosztlitr",
            allowNegative: false,
            minValue: 0.001,
            maxValue: 9999999,
            decimalPrecision: 3,
            fieldLabel: Lang.cena_jednostkowa,
            // allowBlank: false,
            blankText: Lang.pole_nie_moze_byc_puste,
            hidden: !Const.COSTCOLUMNS
        }, {
            xtype: 'numberfieldexd',
            id: this.cid + "dust",
            name: "dust",
            allowNegative: false,
            decimalPrecision: 3,
            fieldLabel: Lang.dust + "[mg/kWh]",
            // allowBlank: false,
            minValue: 0.000,
            maxValue: 9999999,
            blankText: Lang.pole_nie_moze_byc_puste,
            hidden: !Const.ENABLENEFPROCESSING
        }, {
            xtype: 'numberfieldexd',
            id: this.cid + "ggr",
            name: "ggr",
            // allowNegative: false,
            minValue: 0,
            maxValue: 100,
            decimalPrecision: 3,
            fieldLabel: Lang.ggr + "[%]",
            allowBlank: false,
            minValue: 0.000,
            maxValue: 100,
            blankText: Lang.pole_nie_moze_byc_puste,
            hidden: !Const.ENABLENEFPROCESSING
        }, {
            xtype: 'button',
            id: this.cid + 'fillnef',
            text: Lang.fill_nef,
            scope: this,
            handler: this.fillNef,
            hidden: !Const.ENABLENEFPROCESSING
        }];
        let dostawcaiopis = [{
            anchor: "0",
            id: this.cid + "dostawca",
            name: "dostawca",
            xtype: 'textfieldexd',
            fieldLabel: Lang.dostawca,
            allowBlank: true,
            blankText: Lang.pole_nie_moze_byc_puste,
            hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
            editable: AppOPERATOR.dostawca == null
        }, {
            anchor: '0',
            id: this.cid + "opis",
            name: "opis",
            xtype: 'textareaexd',
            height: 120,
            fieldLabel: Lang.opis
        }];
        if (WindowExdMobileDEVICEV)
            formpanel.items[0].items[0].items.push(...formitems, ...dostawcaiopis);
        else {
            formpanel.items[0].items.push({
                columnWidth: .5,
                layout: 'anchor',
                frame: false,
                border: false,
                bodyStyle: 'padding-left:10px; padding-right: 10px; padding-top: 10px;',
                labelWidth: 120,
                defaults: {
                    anchor: "0",
                    labelWidth: 120
                },
                items: [...formitems]
            });
            formpanel.items.push({
                anchor: "0",
                xtype: 'form',
                frame: false,
                border: false,
                bodyStyle: 'padding:10px 10px 10px 10px',
                layout: 'anchor',
                defaults: {
                    anchor: '0',
                    labelWidth: 120
                },
                items: [...dostawcaiopis]
            });
        }
        this.items = [{
            xtype: 'panel',
            layout: 'fit',
            items: [formpanel]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    validateNefParams: function () {
        var presentSo2 = Ext.getCmp(this.cid + "so2").getValue() != null;
        var presentNo2 = Ext.getCmp(this.cid + "no2").getValue() != null;
        var presentAromater = Ext.getCmp(this.cid + "aromater").getValue() != null;
        var presentDust = Ext.getCmp(this.cid + "dust").getValue() != null;
        var presentGgr = Ext.getCmp(this.cid + "ggr").getValue() != null;
        return (presentSo2 == presentNo2) && (presentNo2 == presentAromater) && (presentAromater == presentDust) && (presentDust == presentGgr);
    },
    updateStan: function () {
        var stan = Ext.getCmp(this.cid + (Const.SKPFLAG ? 'stanzbiornikareal' : 'stan')).getValue2();
        var pojemnosc = Ext.getCmp(this.cid + "pojemnosc").getValue();
        var ile = Ext.getCmp(this.cid + "ile").getValue2();
        if (ile != null && stan != null) {
            Ext.getCmp(this.cid + "stan2").setValue(Ext.Number.roundToPrecision((stan + ile), 2));
            if (pojemnosc < stan + ile) {
                this.validIle = Lang.przekroczona_pojemnosc_zbiornika;
            } else {
                this.validIle = true;
            }
            Ext.getCmp(this.cid + "ile").validate();
            this.updateGestoscsrednia();
        }
    },
    updateGestoscsrednia: function () {
        var gestoscoperacji = Ext.getCmp(this.cid + "gestoscoperacji").getValue2();
        if (gestoscoperacji != null) {
            var newgestoscsrednia;
            if (this.oldGestoscsrednia == null) {
                newgestoscsrednia = gestoscoperacji;
            } else {
                var ile = Ext.getCmp(this.cid + "ile").getValue2();
                var oldStanZbionika = Ext.getCmp(this.cid + (Const.SKPFLAG ? 'stanzbiornikareal' : 'stan')).getValue2();
                newgestoscsrednia = (ile * gestoscoperacji + oldStanZbionika * this.oldGestoscsrednia) / (ile + oldStanZbionika);
            }
            Ext.getCmp(this.cid + "gestoscsrednia").setValue(newgestoscsrednia);
        } else {
            Ext.getCmp(this.cid + "gestoscsrednia").setValue(this.oldGestoscsrednia);
        }
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex("access.manage.zaladunki")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.obiektid = obiektid;
        this.obiekt = null;
        sendAjaxDataJsonRpcL("rest/zaladunekkorekty/getObjectForEdit", {
            id: this.obiektid
        }, this, function (data) {
            if (data.status) {
                this.obiekt = data.data;
                sendAjaxDataJsonRpcL("rest/zbiorniki/getList", {
                    idinclude: this.obiekt != null ? this.obiekt.idzbiornik : 0
                }, this, function (data) {
                    if (data.status) {
                        var listFnData = data.data;
                        this.show();
                        this.center();
                        this.setKeyMap({
                            'ESC': {
                                scope: this,
                                handler: this.closeOp
                            }
                        });
                        Ext.getCmp(this.cid + 'idzbiornik').store.loadData(listFnData);
                        if (this.obiekt == null) {
                            Ext.getCmp(this.cid + 'stanzbiornikarealafter').hide();
                            Ext.getCmp(this.cid + 'stanzbiornikarealbefore').hide();
                            Ext.getCmp(this.cid + 'stanzbiornikadelta').hide();
                            Ext.getCmp(this.cid + 'idzbiornik').setValue2Default(null);
                            if (Ext.getCmp(this.cid + 'idzbiornik').isRow()) {
                                var row = Ext.getCmp(this.cid + 'idzbiornik').getRow();
                                Ext.getCmp(this.cid + "stan").setValue(!row ? null : row.get('stanzbiornika'));
                                Ext.getCmp(this.cid + "stan2").setValue(!row ? null : row.get('stanzbiornika'));
                                Ext.getCmp(this.cid + "pojemnosc").setValue(!row ? null : row.get('pojemnosc'));
                                Ext.getCmp(this.cid + "stanalarmowy").setValue(!row ? null : row.get('stanalarmowy'));
                                Ext.getCmp(this.cid + "stanblokady").setValue(!row ? null : row.get('stanblokady'));
                                Ext.getCmp(this.cid + "stanzbiornikareal").setValue(!row ? null : row.get('stanzbiornikareal'));
                                Ext.getCmp(this.cid + "gestoscoperacji").allowBlank = !row ? true : !row.get("gestoscwymagana");
                                this.oldGestoscsrednia = !row ? null : row.get('gestoscsrednia');
                                Ext.getCmp(this.cid + "gestoscsrednia").setValue(this.oldGestoscsrednia);
                            } else {
                                Ext.getCmp(this.cid + "fillnef").hide();
                            }
                            Ext.getCmp(this.cid + "ile").setValue(null);
                            Ext.getCmp(this.cid + "ile").clearInvalid();
                            Ext.getCmp(this.cid + "idzbiornik").setDisabled(false);
                            Ext.getCmp(this.cid + "ile").setDisabled(false);
                            Ext.getCmp(this.cid + "opis").setValue("");
                            Ext.getCmp(this.cid + "gestoscoperacji").setValue(null);
                            Ext.getCmp(this.cid + "gestoscoperacji").setDisabled(false);
                            Ext.getCmp(this.cid + "gestoscsrednia").setDisabled(true);
                            Ext.getCmp(this.cid + "kosztcalosc").clearInvalid();
                            if (AppOPERATOR.dostawca != null) {
                                Ext.getCmp(this.cid + "dostawca").setValue(AppOPERATOR.dostawca);
                            }
                            var disableEnvironmentalParams = row == null || !row.get('environmentalPlyn');
                            Ext.getCmp(this.cid + "so2").clearInvalid();
                            Ext.getCmp(this.cid + "so2").setDisabled(disableEnvironmentalParams);
                            Ext.getCmp(this.cid + "no2").clearInvalid();
                            Ext.getCmp(this.cid + "no2").setDisabled(disableEnvironmentalParams);
                            Ext.getCmp(this.cid + "aromater").clearInvalid();
                            Ext.getCmp(this.cid + "aromater").setDisabled(disableEnvironmentalParams);
                            Ext.getCmp(this.cid + "dust").clearInvalid();
                            Ext.getCmp(this.cid + "dust").setDisabled(disableEnvironmentalParams);
                            Ext.getCmp(this.cid + "ggr").clearInvalid();
                            Ext.getCmp(this.cid + "ggr").setDisabled(disableEnvironmentalParams);
                            Ext.getCmp(this.cid + "kosztlitr").clearInvalid();
                            Ext.getCmp(this.cid + "fillnef").setDisabled(disableEnvironmentalParams);
                            this.validIle = true;
                            this.task = {
                                run: this.updateStan,
                                scope: this,
                                interval: 1000
                            };
                            Ext.TaskManager.start(this.task);
                        } else {
                            Ext.getCmp(this.cid + 'stanzbiornikareal').hide();
                            Ext.getCmp(this.cid + 'idzbiornik').setValue2(this.obiekt.idzbiornik);
                            var row = Ext.getCmp(this.cid + 'idzbiornik').getRow();
                            Ext.getCmp(this.cid + "stan").setValue(this.obiekt.stanzbiornika - this.obiekt.ile);
                            Ext.getCmp(this.cid + "stan2").setValue(this.obiekt.stanzbiornika);
                            Ext.getCmp(this.cid + "pojemnosc").setValue(!row ? null : row.get('pojemnosc'));
                            Ext.getCmp(this.cid + "stanalarmowy").setValue(!row ? null : row.get('stanalarmowy'));
                            Ext.getCmp(this.cid + "stanblokady").setValue(!row ? null : row.get('stanblokady'));
                            Ext.getCmp(this.cid + "opis").setValue(this.obiekt.opis);
                            Ext.getCmp(this.cid + "dostawca").setValue(this.obiekt.dostawca);
                            Ext.getCmp(this.cid + "gestoscoperacji").setValue(this.obiekt.gestoscoperacji);
                            Ext.getCmp(this.cid + "gestoscoperacji").setDisabled(true);
                            Ext.getCmp(this.cid + "ile").setValue(this.obiekt.ile);
                            Ext.getCmp(this.cid + "ile").setDisabled(true);
                            Ext.getCmp(this.cid + "idzbiornik").setDisabled(true);
                            Ext.getCmp(this.cid + "gestoscsrednia").setValue(this.obiekt.gestoscsrednia);
                            Ext.getCmp(this.cid + "gestoscsrednia").setDisabled(true);
                            Ext.getCmp(this.cid + "gestoscsrednia").setHidden(Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG);
                            Ext.getCmp(this.cid + "kosztcalosc").setValue(this.obiekt.kosztcalosc);
                            Ext.getCmp(this.cid + "stanzbiornikarealbefore").setValue(this.obiekt.stanzbiornikarealbefore);
                            Ext.getCmp(this.cid + "stanzbiornikarealafter").setValue(this.obiekt.stanzbiornikareal);
                            Ext.getCmp(this.cid + "stanzbiornikadelta").setValue(this.obiekt.stanzbiornikadelta);
                            if (this.obiekt.nefWorkingParameters != null) {
                                Ext.getCmp(this.cid + "so2").setValue(this.obiekt.nefWorkingParameters.so2);
                                Ext.getCmp(this.cid + "no2").setValue(this.obiekt.nefWorkingParameters.no2);
                                Ext.getCmp(this.cid + "aromater").setValue(this.obiekt.nefWorkingParameters.aromater);
                                Ext.getCmp(this.cid + "dust").setValue(this.obiekt.nefWorkingParameters.dust);
                                Ext.getCmp(this.cid + "ggr").setValue(this.obiekt.nefWorkingParameters.ggr);
                                var disableEnvironmentalParams = !row.get('environmentalPlyn');
                                Ext.getCmp(this.cid + "so2").setDisabled(disableEnvironmentalParams);
                                Ext.getCmp(this.cid + "no2").setDisabled(disableEnvironmentalParams);
                                Ext.getCmp(this.cid + "aromater").setDisabled(disableEnvironmentalParams);
                                Ext.getCmp(this.cid + "dust").setDisabled(disableEnvironmentalParams);
                                Ext.getCmp(this.cid + "ggr").setDisabled(disableEnvironmentalParams);
                            }
                            Ext.getCmp(this.cid + "kosztlitr").setValue(this.obiekt.kosztlitr);
                            this.validIle = true;
                        }
                    } else {
                        Ext.MessageBox.alert(Lang.error, data.error);
                    }
                });
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        var canWrite = false;
        var nefCanWrite = true;
        if (!this.obiektid) {
            if (Ext.getCmp(this.cid + "ile").isValid() && Ext.getCmp(this.cid + "idzbiornik").isValid() && Ext.getCmp(this.cid + "gestoscoperacji").isValid()) {
                canWrite = true;
            }
        } else
            canWrite = true;
        if (Const.ENABLENEFPROCESSING) {
            nefCanWrite = this.validateNefParams();
        }
        if (canWrite) {
            if (nefCanWrite) {
                sendAjaxDataJsonRpcL("rest/zaladunekkorekty/saveObject", {
                    id: this.obiektid,
                    idzbiornik: Ext.getCmp(this.cid + "idzbiornik").getValue2(),
                    ile: Ext.getCmp(this.cid + "ile").getValue(),
                    stan: Ext.getCmp(this.cid + "stan").getValue(),
                    stan2: Ext.getCmp(this.cid + "stan2").getValue(),
                    opis: Ext.getCmp(this.cid + "opis").getValue(),
                    dostawca: Ext.getCmp(this.cid + "dostawca").getValue(),
                    gestoscoperacji: Ext.getCmp(this.cid + "gestoscoperacji").getValue2(),
                    optlockversion: this.obiekt == null ? null : this.obiekt.optlockversion,
                    iskorekta: false,
                    kosztcalosc: Ext.getCmp(this.cid + "kosztcalosc").getValue2(),
                    so2: Ext.getCmp(this.cid + "so2").getValue2(),
                    no2: Ext.getCmp(this.cid + "no2").getValue2(),
                    aromater: Ext.getCmp(this.cid + "aromater").getValue2(),
                    dust: Ext.getCmp(this.cid + "dust").getValue2(),
                    ggr: Ext.getCmp(this.cid + "ggr").getValue2(),
                    kosztlitr: Ext.getCmp(this.cid + "kosztlitr").getValue2()
                }, this, function (data) {
                    if (data.status) {
                        data = data.data;
                        this.obiektid = data.id;
                        this.closeOp();
                    } else {
                        Ext.MessageBox.alert(Lang.error, data.error);
                    }
                    if (this.callbackF)
                        this.callbackF();
                });
            } else {
                Ext.MessageBox.alert(Lang.error, Lang.wszystkie_parametry_nef_musza_byc_wypelnione_albo_zaden);
            }
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    fillNef: function () {
        var row = Ext.getCmp(this.cid + 'idzbiornik').getRow();
        sendAjaxDataJsonRpcL("rest/zaladunekkorekty/getPreviousNef", {idzbiornik: row.id, zalid: this.obiektid}, this, function (data) {
            if (data.status) {
                if (data.data == null) {
                    Ext.MessageBox.alert(Lang.error, Lang.nie_znaleziono_zapisanych_parametrow_nef_dla_danego_zbiornika);
                } else {
                    Ext.getCmp(this.cid + "so2").setValue(data.data.so2);
                    Ext.getCmp(this.cid + "no2").setValue(data.data.no2);
                    Ext.getCmp(this.cid + "aromater").setValue(data.data.aromater);
                    Ext.getCmp(this.cid + "dust").setValue(data.data.dust);
                    Ext.getCmp(this.cid + "ggr").setValue(data.data.ggr);
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
            if (this.callbackF)
                this.callbackF();
        });
    },
    closeOp: function () {
        if (this.task != undefined) {
            Ext.TaskManager.stop(this.task);
        }
        this.close();
    }
});
