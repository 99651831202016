Ext.define('WEditLicznikStateTankowanieWindow', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.tankowanie.capitalise(),
    width: 400,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            scrollable: true,
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                scrollable: true,
                defaults: {
                    labelWidth: 150,
                    anchor: "0",
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [
                    {
                        id: this.cid + "odbiorca",
                        name: "odbiorca",
                        xtype: 'comboexd',
                        fieldLabel: Lang.odbiorca,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        store: Ext.create('Ext.data.Store', {
                            fields: ['id', 'nazwa'],
                            proxy: {
                                type: 'ajax',
                                url: 'rest/odbiorcy/getForAddEditTankowanieList',
                                actionMethods: {
                                    read: 'POST'
                                },
                                reader: {
                                    type: 'json',
                                    rootProperty: 'data'
                                }
                            },
                            autoLoad: false
                        }),
                        displayField: 'nazwa',
                        valueField: 'id',
                        forceSelection: true,
                        emptyText: '...'
                    },
                    {
                        id: this.cid + "pojazd",
                        name: "pojazd",
                        fieldLabel: Lang.pojazd,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        xtype: 'comboexd',
                        store: Ext.create('Ext.data.Store', {
                            fields: ['id', 'nazwa'],
                            proxy: {
                                type: 'ajax',
                                url: 'rest/pojazdy/getForAddEditTankowanieList',
                                actionMethods: {
                                    read: 'POST'
                                },
                                reader: {
                                    type: 'json',
                                    rootProperty: 'data'
                                }
                            },
                            autoLoad: false
                        }),
                        displayField: 'nazwa',
                        valueField: 'id',
                        forceSelection: true,
                        emptyText: '...'
                    }, {
                        id: this.cid + "plyn",
                        name: "plyn",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.plyn,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        cls: "x-item-disabled-sec"
                    }, {
                        xtype: 'numberfieldexd',
                        id: this.cid + "ile",
                        name: "ile",
                        allowNegative: false,
                        decimalPrecision: 3,
                        fieldLabel: Lang.ile,
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        cls: "x-item-disabled-sec"
                    }, {
                        xtype: 'numberfieldexd',
                        id: this.cid + "licznik",
                        name: "licznik",
                        allowDecimal: false,
                        allowNegative: false,
                        decimalPrecision: 3,
                        fieldLabel: Lang.stan_licznika,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        cls: "x-item-disabled-sec"
                    }, {
                        xtype: 'numberfieldexd',
                        id: this.cid + "licznikorg",
                        name: "licznikorg",
                        allowDecimal: false,
                        allowNegative: false,
                        decimalPrecision: 3,
                        fieldLabel: Lang.stan_licznika + "(org)",
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        cls: "x-item-disabled-sec"
                    }, {
                        xtype: 'numberfieldexd',
                        id: this.cid + "motogodziny",
                        name: "motogodziny",
                        allowDecimal: false,
                        allowNegative: false,
                        decimalPrecision: 3,
                        fieldLabel: Lang.motogodziny,
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        cls: "x-item-disabled-sec"
                    }, {
                        xtype: 'numberfieldexd',
                        id: this.cid + "motogodzinyorg",
                        name: "motogodzinyorg",
                        allowDecimal: false,
                        allowNegative: false,
                        decimalPrecision: 3,
                        fieldLabel: Lang.motogodziny + "(org)",
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        cls: "x-item-disabled-sec"
                    }, {
                        id: this.cid + "czas",
                        name: "czas",
                        xtype: 'datefieldexdmod',
                        fieldLabel: Lang.czas,
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        cls: "x-item-disabled-sec"
                    }, {
                        xtype: 'numberfieldexd',
                        id: this.cid + "kosztcalosc",
                        name: "kosztcalosc",
                        allowNegative: false,
                        decimalPrecision: 3,
                        fieldLabel: Lang.koszt,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        hidden: !Const.COSTCOLUMNS,
                        disabled: true
                    }, {
                        xtype: 'numberfieldexd',
                        id: this.cid + "kosztlitr",
                        name: "kosztlitr",
                        allowNegative: false,
                        decimalPrecision: 3,
                        fieldLabel: Lang.cena_jednostkowa,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        hidden: !Const.COSTCOLUMNS,
                        disabled: true
                    }, {
                        id: this.cid + "opis",
                        name: "opis",
                        xtype: 'textareaexd',
                        height: 150,
                        fieldLabel: Lang.opis,
                        cls: "x-item-disabled-sec"
                    }, {
                        xtype: "checkboxexd",
                        boxLabel: Lang.exported,
                        id: this.cid + "exported",
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                    }, {
                        xtype: "checkboxexd",
                        boxLabel: Lang.isdolewkadopelna,
                        id: this.cid + "isdolewka",
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                    }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex('access.manage.tankowania')) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.obiektid = obiektid;
        this.obiekt = null;
        sendAjaxDataJsonRpcL("rest/tankowania/getObjectForEdit", {
            id: this.obiektid
        }, this, function (data) {
            if (data.status) {
                this.obiekt = data.data || {};
                if (this.obiekt.id != null && (this.obiekt.typ == Const.TANKOWANIE_AWARYJNE || this.obiekt.typ == Const.TANKOWANIE_RECZNE)) {
                    sendAjaxDataJsonRpcL("rest/firmy/getFirmaIdentTryb?idfirma=" + this.obiekt.idfirma, {}, this, function (fdata) {
                        if (fdata.status) {
                            this.identTryb = fdata.data;
                        }
                    })
                }
                Ext.getCmp(this.cid + "czas").setValue(this.obiekt.czas);
                Ext.getCmp(this.cid + "plyn").setValue(this.obiekt.plyn);
                Ext.getCmp(this.cid + "ile").setValue(this.obiekt.ile);
                Ext.getCmp(this.cid + "licznik").setValue(this.obiekt.licznik);
                Ext.getCmp(this.cid + "licznikorg").setValue(this.obiekt.licznikorg);
                Ext.getCmp(this.cid + "motogodziny").setValue(this.obiekt.motogodziny);
                Ext.getCmp(this.cid + "motogodzinyorg").setValue(this.obiekt.motogodzinyorg);
                Ext.getCmp(this.cid + "odbiorca").setValue(this.obiekt.odbiorca);
                Ext.getCmp(this.cid + "pojazd").setValue(this.obiekt.pojazd);
                Ext.getCmp(this.cid + "opis").setValue(this.obiekt.opis);
                Ext.getCmp(this.cid + "exported").setValue(this.obiekt.exported);
                Ext.getCmp(this.cid + "isdolewka").setValue(!this.obiekt.isdolewka);
                Ext.getCmp(this.cid + "czas").setDisabled(true);
                Ext.getCmp(this.cid + "plyn").setDisabled(true);
                Ext.getCmp(this.cid + "ile").setDisabled(true);
                var odbiorcaStore = Ext.getCmp(this.cid + "odbiorca").getStore();
                odbiorcaStore.getProxy().setExtraParams({
                    idfirma: this.obiekt.idfirma
                });
                odbiorcaStore.load();
                Ext.getCmp(this.cid + "odbiorca").setValue(this.obiekt.idodbiorca);
                Ext.getCmp(this.cid + "odbiorca").valueNotFoundText = this.obiekt.odbiorca;
                var odbiorcaDisableF = true;
                if (this.obiekt.typ == Const.TANKOWANIE_AWARYJNE && (/*AppOPERATORROLA == Const.OPERATOR_P_SERWIS ||*/ this.obiekt.idodbiorca == null))
                    odbiorcaDisableF = false;
                if (this.obiekt.typ == Const.TANKOWANIE_RECZNE && (/*AppOPERATORROLA == Const.OPERATOR_P_SERWIS ||*/ this.obiekt.idodbiorca == null))
                    odbiorcaDisableF = false;
                Ext.getCmp(this.cid + "odbiorca").setDisabled(odbiorcaDisableF);

                var pojazdStore = Ext.getCmp(this.cid + "pojazd").getStore();
                pojazdStore.getProxy().setExtraParams({
                    idfirma: this.obiekt.idfirma
                });
                pojazdStore.load();
                Ext.getCmp(this.cid + "pojazd").setValue(this.obiekt.idpojazd);
                Ext.getCmp(this.cid + "pojazd").valueNotFoundText = this.obiekt.pojazd;
                var pojazdDisableF = true;
                if (this.obiekt.typ == Const.TANKOWANIE_AWARYJNE && (/*AppOPERATORROLA == Const.OPERATOR_P_SERWIS ||*/ this.obiekt.idpojazd == null))
                    pojazdDisableF = false;
                if (this.obiekt.typ == Const.TANKOWANIE_RECZNE && (/*AppOPERATORROLA == Const.OPERATOR_P_SERWIS ||*/ this.obiekt.idpojazd == null))
                    pojazdDisableF = false;
                Ext.getCmp(this.cid + "pojazd").setDisabled(pojazdDisableF);
                Ext.getCmp(this.cid + "licznikorg").setDisabled(true);
                Ext.getCmp(this.cid + "motogodzinyorg").setDisabled(true);
                Ext.getCmp(this.cid + "kosztcalosc").setValue(this.obiekt.kosztcalosc);
                Ext.getCmp(this.cid + "kosztlitr").setValue(this.obiekt.kosztlitr);
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                Ext.getCmp(this.cid + "licznik").clearInvalid();
                Ext.getCmp(this.cid + "licznikorg").clearInvalid();
                Ext.getCmp(this.cid + "motogodziny").clearInvalid();
                Ext.getCmp(this.cid + "motogodzinyorg").clearInvalid();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        if (this.obiekt.id != null && this.obiekt.typ == Const.TANKOWANIE_RECZNE || this.obiekt.typ == Const.TANKOWANIE_AWARYJNE) {
            var pojFilled = Ext.getCmp(this.cid + "pojazd").getValue2NL() != null;
            var odbFilled = Ext.getCmp(this.cid + "odbiorca").getValue2NL() != null;
            if (this.identTryb == Const.IDENT_POJEDYNCZA && !pojFilled && !odbFilled) {
                Ext.MessageBox.alert(Lang.error, Lang.pola_pojazd_lub_odbiorca_wymagane_w_pojedynczym_trybie_identyfikacji);
                return;
            }
            if (this.identTryb == Const.IDENT_PODWOJNA && (!pojFilled || !odbFilled)) {
                Ext.MessageBox.alert(Lang.error, Lang.pola_pojazd_odbiorca_wymagane_w_podwojnym_trybie_identyfikacji);
                return;
            }
        }
        if (!Ext.getCmp(this.cid + 'formpanel').form.isValid()) {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        sendAjaxDataJsonRpcL("rest/tankowania/saveObject", {
            id: this.obiektid,
            licznik: Ext.getCmp(this.cid + "licznik").getValue2(),
            opis: Ext.getCmp(this.cid + "opis").getValue(),
            motogodziny: Ext.getCmp(this.cid + "motogodziny").getValue2(),
            pojazd2: Ext.getCmp(this.cid + "pojazd").getValue2NL(),
            odbiorca2: Ext.getCmp(this.cid + "odbiorca").getValue2NL(),
            exported: Ext.getCmp(this.cid + "exported").getValue(),
            isdolewka: !Ext.getCmp(this.cid + "isdolewka").getValue(),
            kosztcalosc: Ext.getCmp(this.cid + "kosztcalosc").getValue2(),
            kosztlitr: Ext.getCmp(this.cid + "kosztlitr").getValue2()
        }, this, function (data) {
            if (data.status) {
                var data = data.data;
                this.fireEvent("wclose");
                this.close();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    closeOp: function () {
        this.close();
    }
});

