Ext.define('PanelLogin', {
    extend: 'PanelStanCzytnikaExd',
    alias: 'widget.loginidentpanel',
    onOk: function () {
        this.callParent();
    },
    onCommError: function () {
        this.callParent();
    },
    onNoService: function () {
        this.callParent();
    },
    onIncorrectVersion: function () {
        this.callParent();
    },
    onNotOpen: function () {
        this.callParent();
    },
    setCommunicationState: function (ikonIdentCls, message) {
        this.down("#pastylkaident").setUserCls(ikonIdentCls);
        if (message !== null) {
            var komunikat = this.down("#komunikatybox").setValue(message);
        }
    },
    onIdentPut: function (czytnik, karta) {
        sendAjaxDataJsonRpcL("rest/systemkonf/isObjectReqPinWithIdentOwner", {
            identyfikator: karta.ident
        }, this, function (data) {
            if (data.status) {
                data = data.data;
                if (data == null) {
                    this.setCommunicationState("iconIdentLoginError", Lang.karta_niepoprawna);

                } else {
                    var identReqPin = data;
                    if (identReqPin) {
                        function resultwindowKod(btn, text) {
                            if (btn == "ok") {
                                sendAjaxDataJsonRpcL("rest/systemreq/performSignIdent", {
                                    _CancelAutoRedirect: true,
                                    identyfikator: karta.ident,
                                    pin: text
                                }, this, function (data) {
                                    if (data.status && data.data != null) {
                                        var data = data.data;
                                        this.identPanel.setCommunicationState("ikonIdentLogin", Lang.weryfikacja_poprawna);
                                        managerCzytnikPastylka.un("identput", this.identPanel.onIdentPut);
                                        managerCzytnikPastylka.un("identpull", this.identPanel.onIdentPull);
                                        this.identPanel.fireEvent('cardrecognized', data);

                                    } else {
                                        this.identPanel.setCommunicationState("iconIdentLoginError", Lang.karta_niepoprawna);

                                    }
                                });
                            } else {
                                this.identPanel.setCommunicationState("iconIdentLoginError", Lang.karta_niepoprawna);

                            }
                        }

                        var dialogid = Ext.id();
                        var dialog = new Ext.window.Window({
                            width: 250,
                            closable: false,
                            collapsible: false,
                            plain: true,
                            modal: true,
                            resizable: false,
                            stateId: null,
                            stateful: false,
                            layout: 'fit',
                            loginPanelId: this.id,
                            items: new Ext.form.Panel({
                                frame: true,
                                autoWidth: true,
                                autoHeight: true,
                                labelWidth: 100,
                                defaults: {
                                    anchor: '0'
                                },
                                items: [{
                                    id: dialogid + "pin",
                                    name: "pin",
                                    xtype: 'textfield',
                                    inputType: "password",
                                    fieldLabel: Lang.wprowadz_pin,
                                    regex: new RegExp("^[0-9]{4,4}$"),
                                    regexText: Lang.dane_niepoprawne
                                }]
                            }),
                            title: Lang.identyfikator,
                            buttons: [{
                                id: dialogid + "save",
                                text: Lang.ok,
                                listeners: {
                                    scope: this,
                                    click: function () {
                                        resultwindowKod("ok", Ext.getCmp(dialogid + "pin").getValue());
                                        dialog.close();
                                    }
                                }
                            }, {
                                id: dialogid + "anuluj",
                                text: Lang.anuluj,
                                listeners: {
                                    scope: this,
                                    click: function () {
                                        var test = Ext.getCmp(dialogid + 'pin');
                                        resultwindowKod("anuluj", Ext.getCmp(dialogid + "pin").getValue());
                                        dialog.close();
                                    }
                                }
                            }]
                        });
                        dialog.show();
                        dialog.center();
                        dialog.setKeyMap({
                            'ESC': {
                                scope: this,
                                handler: this.closeOp
                            }
                        });
                    } else {
                        sendAjaxDataJsonRpcL("rest/systemreq/performSignIdent", {
                            _CancelAutoRedirect: true,
                            identyfikator: karta.ident,
                            pin: null
                        }, this, function (data) {
                            if (data.status && data.data != null) {
                                var data = data.data;
                                this.setCommunicationState("ikonIdentLogin", Lang.weryfikacja_poprawna);
                                managerCzytnikPastylka.un("identput", this.onIdentPut);
                                managerCzytnikPastylka.un("identpull", this.onIdentPull);
                                this.fireEvent('cardrecognized', data);

                            } else {
                                this.setCommunicationState("iconIdentLoginError", Lang.karta_niepoprawna);

                            }
                        });
                    }
                }
            }
        });
    },
    onIdentPull: function () {
        this.onOk();
    },
    init: function () {
        managerCzytnikPastylka.init();
        managerCzytnikPastylka.on({
            scope: this,
            ok: this.onOk,
            commerror: this.onCommError,
            identput: this.onIdentPut,
            identpull: this.onIdentPull,
            noservice: this.onNoService,
            incorrectversion: this.onIncorrectVersion,
            notopen: this.onNotOpen
        });
        this.setCommunicationState('x-czytnik-ikonCard', Lang.czytnik_oczekiwanie_na_polaczenie);
    },
    close: function () {
        managerCzytnikPastylka.close();
    }
});
