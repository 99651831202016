Ext.define('WindowSkps', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.skp.capitalise(),
    width: 630,
    height: 700,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'tabpanel',
            autoWidth: true,
            height: 300,
            border: false,
            activeTab: 0,
            items: [{
                title: Lang.ogolne.capitalise(),
                xtype: 'form',
                id: this.cid + 'formpanel1',
                bodyStyle: 'padding:10px 10px 10px 10px',
                frame: true,
                autoWidth: true,
                scrollable: 'y',
                defaults: {
                    anchor: "0"
                },
                items: [{
                    anchor: "0",
                    layout: 'column',
                    border: false,
                    items: [{
                        columnWidth: 1,
                        border: false,
                        layout: 'anchor',
                        labelWidth: 210,
                        defaults: {
                            anchor: "-10",
                            labelWidth: 210,
                            labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                        },
                        items: [{
                            id: this.cid + "nazwa",
                            name: "nazwa",
                            xtype: 'textfieldexd',
                            fieldLabel: Lang.nazwa,
                            allowBlank: false,
                            maxLength: 16,
                            blankText: Lang.pole_nie_moze_byc_puste,
                            disabled: isValidOperatorFirmaLimit()
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "standalonedevice",
                            name: "standalonedevice",
                            baseCls: 'x-radio-column',
                            fieldLabel: Lang.skp_samodzielne,
                            vertical: false,
                            hidden: Const.OPTIMALFLAG || Const.SKPFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || Const.CLOUDFLAG,
                            items: [{
                                boxLabel: Lang.tak.capitalise(),
                                inputValue: true,
                                value: true
                            }, {
                                boxLabel: Lang.nie.capitalise(),
                                inputValue: false
                            }]
                        }, {
                            id: this.cid + "adres",
                            name: "adres",
                            xtype: 'numberfieldexd',
                            minValue: 1,
                            maxValue: 62,
                            fieldLabel: Lang.adres_can,
                            allowBlank: false,
                            blankText: Lang.pole_nie_moze_byc_puste,
                            hidden: Const.SKPFLAG
                        }, {
                            id: this.cid + "idzbiornik",
                            xtype: 'comboexd',
                            fieldLabel: Lang.zbiornik,
                            valueField: 'id',
                            displayField: 'nazwa',
                            store: new Ext.data.JsonStore({
                                id: "id",
                                rootProperty: '',
                                sorters: [{
                                    property: "nazwa",
                                    direction: "ASC"
                                }],
                                fields: [{
                                    name: 'id',
                                    mapping: 'id'
                                }, {
                                    name: 'nazwa',
                                    mapping: 'nazwa'
                                }]
                            }),
                            triggerAction: 'all',
                            resizable: false,
                            editable: false,
                            mode: "local",
                            allowBlank: false,
                            blankText: Lang.pole_nie_moze_byc_puste,
                            disabled: isValidOperatorFirmaLimit()
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "active",
                            name: "active",
                            baseCls: 'x-radio-column',
                            fieldLabel: Lang.active,
                            vertical: false,
                            items: [{
                                boxLabel: Lang.tak.capitalise(),
                                inputValue: true,
                                value: true
                            }, {
                                boxLabel: Lang.nie.capitalise(),
                                inputValue: false
                            }]
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "intervalgetstates",
                            name: "intervalgetstates",
                            baseCls: 'x-radio-column',
                            fieldLabel: Lang.zbieranie_pomiarow,
                            vertical: false,
                            items: [{
                                boxLabel: Lang.wlaczone.capitalise(),
                                inputValue: true,
                                value: true
                            }, {
                                boxLabel: Lang.wylaczone.capitalise(),
                                inputValue: false
                            }]
                        }, {
                            xtype: "checkboxexd",
                            boxLabel: Lang.forceupdate,
                            id: this.cid + "forceupdate"
                        }]
                    }]
                }]
            }, {
                title: Lang.skp_samodzielne.capitalise(),
                xtype: 'form',
                id: this.cid + 'formpanel1b',
                bodyStyle: 'padding:10px 10px 10px 10px',
                frame: true,
                autoWidth: true,
                scrollable: 'y',
                defaults: {
                    anchor: "0",
                    labelWidth: 210,
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                hidden: Const.OPTIMALFLAG || Const.CLOUDFLAG,
                items: [{
                    id: this.cid + "tmsintervalquery",
                    name: "tmsintervalquery",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.okres_polaczenia + "[min]",
                    allowBlank: false,
                    allowDecimal: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 1,
                    maxValue: 99999
                }, {
                    id: this.cid + "tmsintervalqueryerror",
                    name: "tmsintervalqueryerror",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.okres_polaczenia_gdy_blad + "[min]",
                    allowBlank: false,
                    allowDecimal: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 1,
                    maxValue: 99999
                }, {
                    id: this.cid + "tmsintervalqueryerrorresetmodem",
                    name: "tmsintervalqueryerrorresetmodem",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.okres_polaczenia_gdy_blad_modem_reset + "[min]",
                    allowBlank: false,
                    allowDecimal: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 1,
                    maxValue: 99999,
                    validator: Ext.Function.bind(function (v) {
                        var tmsintervalquery = Ext.getCmp(this.cid + "tmsintervalquery").getValue();
                        var tmsintervalqueryerror = Ext.getCmp(this.cid + "tmsintervalqueryerror").getValue();
                        var tmsintervalqueryerrorresetmodem = Ext.getCmp(this.cid + "tmsintervalqueryerrorresetmodem").getValue();
                        return (tmsintervalqueryerrorresetmodem > tmsintervalqueryerror && tmsintervalqueryerrorresetmodem > tmsintervalquery) ? true : Lang.okres_resetu_ma_byc_wiekszy_od_okresow_polaczenia;
                    }, this, [])
                }, {
                    id: this.cid + "intervalgetskpstates",
                    name: "intervalgetskpstates",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.okres_zbierania_pomiarow_skp + "[min]",
                    allowBlank: false,
                    allowDecimal: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 1,
                    maxValue: 99999,
                    hidden: Const.CLOUDFLAG
                }]
            }, {
                title: Lang.kalibracja.capitalise(),
                xtype: 'form',
                id: this.cid + 'formpanel2',
                bodyStyle: 'padding:10px 10px 10px 10px',
                frame: true,
                scrollable: true,
                autoWidth: true,
                defaults: {
                    anchor: "0",
                    labelWidth: 210,
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    id: this.cid + "gestosccieczy",
                    name: "gestosccieczy",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.gestosc + "[kg/m^3]",
                    allowBlank: false,
                    decimalPrecision: 3,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 0,
                    maxValue: 9999999
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "pomiartemperatury",
                    name: "pomiartemperatury",
                    baseCls: 'x-radio-column',
                    fieldLabel: Lang.pomiar_temperatury,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.tak.capitalise(),
                        inputValue: true
                    }, {
                        boxLabel: Lang.nie.capitalise(),
                        inputValue: false,
                        value: true
                    }],
                    listeners: {
                        change: function (radiogroup, newValue) {
                            var kompensacjaRadioBtn = Ext.getCmp(this.cid + "kompensacjatemperaturowa");
                            kompensacjaRadioBtn.setDisabled(!newValue);
                            if (!newValue) {
                                kompensacjaRadioBtn.setValue2(false);
                            }
                        },
                        scope: this
                    }
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "kompensacjatemperaturowa",
                    name: "kompensacjatemperaturowa",
                    baseCls: 'x-radio-column',
                    fieldLabel: Lang.kompensacja_temperaturowa,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.tak.capitalise(),
                        inputValue: true
                    }, {
                        boxLabel: Lang.nie.capitalise(),
                        inputValue: false,
                        value: true
                    }]
                }, {
                    id: this.cid + 'typoutrelay1',
                    xtype: 'comboexd',
                    store: new Ext.data.ArrayStore({
                        data: [[Const.SKP_RELAY_OUT_OFF, Lang.wyjscie_wylaczone], [Const.SKP_RELAY_OUT_TRIGGER_DOWN, Lang.przekroczenie_w_dol], [Const.SKP_RELAY_OUT_TRIGGER_UP, Lang.przekroczenie_w_gore]],
                        fields: ["value", "text"]
                    }),
                    hidden: Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                    displayField: "text",
                    valueField: "value",
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    fieldLabel: Lang.typ_wyjscia_1
                }, {
                    id: this.cid + "progoutrelay1",
                    name: "progoutrelay1",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.prog_dla_wyjscia_1 + Lang.jednostka_text,
                    hidden: Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                    allowBlank: false,
                    decimalPrecision: 3,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 0.000,
                    maxValue: 9999999
                }, {
                    id: this.cid + "hstoutrelay1",
                    name: "hstoutrelay1",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.histereza_dla_wyjscia_1 + Lang.jednostka_text,
                    hidden: Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                    allowBlank: false,
                    decimalPrecision: 3,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 0.000,
                    maxValue: 9999999
                }, {
                    id: this.cid + "bladpomiaruwyjscie1",
                    xtype: 'comboexd',
                    store: new Ext.data.ArrayStore({
                        data: [[false, Lang.utrzymuj_stan_przekaznika_sprzed_awarii], [true, Lang.wymus_wylaczenie_przekaznika]],
                        fields: ["value", "text"]
                    }),
                    displayField: "text",
                    valueField: "value",
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    fieldLabel: Lang.blad_pomiaru_wyjscie_1
                }, {
                    id: this.cid + 'typoutrelay2',
                    xtype: 'comboexd',
                    store: new Ext.data.ArrayStore({
                        data: [[Const.SKP_RELAY_OUT_OFF, Lang.wyjscie_wylaczone], [Const.SKP_RELAY_OUT_TRIGGER_DOWN, Lang.przekroczenie_w_dol], [Const.SKP_RELAY_OUT_TRIGGER_UP, Lang.przekroczenie_w_gore]],
                        fields: ["value", "text"]
                    }),
                    hidden: Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                    displayField: "text",
                    valueField: "value",
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    fieldLabel: Lang.typ_wyjscia_2
                }, {
                    id: this.cid + "progoutrelay2",
                    name: "progoutrelay1",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.prog_dla_wyjscia_2 + Lang.jednostka_text,
                    hidden: Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                    allowBlank: false,
                    decimalPrecision: 3,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 0.000,
                    maxValue: 9999999
                }, {
                    id: this.cid + "hstoutrelay2",
                    name: "hstoutrelay1",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.histereza_dla_wyjscia_2 + Lang.jednostka_text,
                    hidden: Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                    allowBlank: false,
                    decimalPrecision: 3,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 0.000,
                    maxValue: 9999999
                }, {
                    id: this.cid + "bladpomiaruwyjscie2",
                    xtype: 'comboexd',
                    store: new Ext.data.ArrayStore({
                        data: [[false, Lang.utrzymuj_stan_przekaznika_sprzed_awarii], [true, Lang.wymus_wylaczenie_przekaznika]],
                        fields: ["value", "text"]
                    }),
                    displayField: "text",
                    valueField: "value",
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    fieldLabel: Lang.blad_pomiaru_wyjscie_2
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "ckpenabled",
                    name: "ckpenabled",
                    fieldLabel: Lang.ckp_podlaczone,
                    baseCls: 'x-radio-column',
                    vertical: false,
                    items: [{
                        boxLabel: Lang.tak.capitalise(),
                        inputValue: true
                    }, {
                        boxLabel: Lang.nie.capitalise(),
                        inputValue: false,
                        value: true
                    }]
                }, {
                    id: this.cid + 'typzbiornika',
                    xtype: 'comboexd',
                    store: new Ext.data.ArrayStore({
                        data: [[Const.SKP_GEOMETRY_WALEC_STOJACY, Lang.walec_stojacy], [Const.SKP_GEOMETRY_PROSTOPADLOSCIAN, Lang.prostopadloscian], [Const.SKP_GEOMETRY_WALEC_LEZACY, Lang.walec_lezacy], [Const.SKP_GEOMETRY_WALEC_LITRAZOWANIE, Lang.nieokreslony]],
                        fields: ["value", "text"]
                    }),
                    displayField: "text",
                    valueField: "value",
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    fieldLabel: Lang.typ_zbiornika
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "typkalibracji",
                    name: "typkalibracji",
                    baseCls: 'x-radio-column',
                    fieldLabel: Lang.typ_kalibracji,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.litrazowanie.capitalise(),
                        inputValue: Const.SKP_POMIAR_LITRAZOWANIE,
                        value: true
                    }, {
                        boxLabel: Lang.geometria.capitalise(),
                        inputValue: Const.SKP_POMIAR_GEOMETRY_OFFSET
                    }],
                    listeners: {
                        change: function () {
                            var typkalibracji = Ext.getCmp(this.cid + "typkalibracji");
                            var panelgeometria = Ext.getCmp(this.cid + "panelgeometria");
                            var panellitrazowanie = Ext.getCmp(this.cid + "panellitrazowanie");
                            panellitrazowanie.setVisible(typkalibracji.getValue2() == Const.SKP_POMIAR_LITRAZOWANIE);
                            panelgeometria.setVisible(typkalibracji.getValue2() == Const.SKP_POMIAR_GEOMETRY_OFFSET);
                        },
                        scope: this
                    }
                }, {
                    id: this.cid + "panellitrazowanie",
                    xtype: 'panel',
                    layout: 'container',
                    isFormField: false,
                    autoWidth: true,
                    hidden: true,
                    defaults: {
                        anchor: "0",
                        labelWidth: 210
                    },
                    items: [{
                        xtype: 'panel',
                        layout: 'form',
                        items: [{
                            id: this.cid + "gestosccieczylitrazowanie",
                            name: "gestosccieczylitrazowanie",
                            xtype: 'numberfieldexd',
                            fieldLabel: Lang.gestosc_cieczy_litrazowanie + "[kg/m^3]",
                            allowBlank: false,
                            decimalPrecision: 3,
                            blankText: Lang.pole_nie_moze_byc_puste,
                            minValue: 0,
                            maxValue: 9999999
                        }]
                    }, new Ext.ux.grid.GridPanelExd({
                        tbar: [{
                            text: Lang.nowy,
                            handler: function () {
                                var www = Ext.create('WindowLitrazowanieGridAddEdit', {
                                    parentObj: this
                                });
                                www.showW(null, Ext.getCmp(this.cid + "litrazowaniegrid"));
                            },
                            scope: this
                        }, {
                            text: Lang.edytuj,
                            handler: function () {
                                var selR = Ext.getCmp(this.cid + "litrazowaniegrid").getSelR();
                                if (selR != null) {
                                    var www = Ext.create('WindowLitrazowanieGridAddEdit', {
                                        parentObj: this
                                    });
                                    www.showW(selR, Ext.getCmp(this.cid + "litrazowaniegrid"));
                                }
                            },
                            scope: this
                        }, {
                            text: Lang.usun,
                            handler: function () {
                                var selR = Ext.getCmp(this.cid + "litrazowaniegrid").getSelR();
                                if (selR != null) {
                                    Ext.getCmp(this.cid + "litrazowaniegrid").store.remove(selR);
                                }
                            },
                            scope: this
                        }],
                        store: new Ext.data.Store({
                            autoLoad: false,
                            proxy: new Ext.data.MemoryProxy({}),
                            reader: new Ext.data.JsonReader({
                                id: "przetwornik",
                                rootProperty: ''
                            }, [{
                                name: 'przetwornik',
                                mapping: 'przetwornik'
                            }, {
                                name: 'ilosccieczy',
                                mapping: 'ilosccieczy'
                            }])
                        }),
                        columns: [{
                            header: Lang.przetwornik + "[j]",
                            flex: 2,
                            sortable: false,
                            dataIndex: 'przetwornik',
                            renderer: function (v) {
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            }
                        }, {
                            header: Lang.ilosc_cieczy + Lang.jednostka_text,
                            flex: 2,
                            sortable: false,
                            dataIndex: 'ilosccieczy',
                            renderer: function (v) {
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            }
                        }],
                        height: 275,
                        scrollable: true,
                        frame: false,
                        border: true,
                        id: this.cid + "litrazowaniegrid",
                        stateId: null,
                        stateful: false,
                        stripeRows: true,
                        enableHdMenu: false,
                        selModel: {
                            selType: 'rowmodel',
                            mode: 'SINGLE'
                        },
                        listeners: {
                            scope: this,
                            rowdblclick: function (c) {
                                c = c.up("grid");
                                var r = c.getSelR();
                                if (r == null)
                                    return;
                                var www = Ext.create('WindowLitrazowanieGridAddEdit', {
                                    parentObj: this
                                });
                                www.showW(r, c);
                            }
                        },
                        getSelR: function () {
                            var gridd = this;
                            if (!gridd.getSelectionModel().hasSelection()) {
                                return null;
                            } else {
                                if (gridd.getSelection() != null && gridd.getSelection().length != 0) {
                                    return gridd.getSelection()[0];
                                }
                            }
                            return null;
                        }
                    })]
                }, {
                    id: this.cid + "panelgeometria",
                    xtype: 'panel',
                    layout: 'form',
                    isFormField: false,
                    labelWidth: 150,
                    autoWidth: true,
                    autoHeight: true,
                    hidden: true,
                    defaults: {
                        anchor: "-20"
                    },
                    items: [{
                        id: this.cid + "zakresprzetwornik",
                        name: "zakresprzetwornik",
                        xtype: 'numberfieldexd',
                        fieldLabel: Lang.zakres_przetwornik + "[m]",
                        allowBlank: false,
                        decimalPrecision: 3,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        minValue: 0.001,
                        maxValue: 9999999
                    }, {
                        id: this.cid + "wysokosczbiornik",
                        name: "wysokosczbiornik",
                        xtype: 'numberfieldexd',
                        fieldLabel: Lang.wysokosc_zbiornik + "[m]",
                        allowBlank: false,
                        decimalPrecision: 3,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        minValue: 0.001,
                        maxValue: 9999999
                    }, {
                        id: this.cid + "polepodstawyzbiornik",
                        name: "polepodstawyzbiornik",
                        xtype: 'numberfieldexd',
                        fieldLabel: Lang.pole_podstawy_zbiornik + "[m^2]",
                        allowBlank: false,
                        decimalPrecision: 3,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        minValue: 0.001,
                        maxValue: 9999999
                    }, {
                        id: this.cid + "przesuniecieczujnik",
                        name: "przesuniecieczujnik",
                        xtype: 'numberfieldexd',
                        fieldLabel: Lang.przesuniecie_czujnik + "[m]",
                        allowBlank: false,
                        decimalPrecision: 3,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        minValue: -9999999,
                        maxValue: 9999999
                    }]
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp,
            disabled: Const.AGGREGATEDCORE
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid, klonowanie) {
        if (!validPrivilegesOperatorComplex("access.manage.skps")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.klonowanie = klonowanie || false;
        this.obiektid = obiektid;
        this.obiekt = null;
        sendAjaxDataJsonRpcL("rest/skp/getObjectForEdit", {
            id: this.obiektid
        }, this, function (data) {
            if (data.status) {
                this.obiekt = data.data;
                sendAjaxDataJsonRpcL("rest/zbiorniki/getShortList", {
                    idinclude: this.obiekt != null ? this.obiekt.idplyn : 0
                }, this, function (data) {
                    if (data.status) {
                        var listFnData = data.data;
                        this.show();
                        this.center();
                        this.setKeyMap({
                            'ESC': {
                                scope: this,
                                handler: this.closeOp
                            }
                        });
                        Ext.getCmp(this.cid + "idzbiornik").store.loadData(listFnData);
                        if (this.obiekt != null) {
                            if (this.klonowanie) {
                                this.obiekt.nazwa += (this.obiektid + 1);
                            }
                            if (this.obiekt.litrazowaniedatas != null) {
                                Ext.getCmp(this.cid + "litrazowaniegrid").store.loadData(this.obiekt.litrazowaniedatas);
                            }
                            Ext.getCmp(this.cid + "forceupdate").setValue(false);
                            Ext.getCmp(this.cid + "nazwa").setValue(this.obiekt.nazwa);
                            Ext.getCmp(this.cid + "adres").setValue(this.obiekt.adres);
                            Ext.getCmp(this.cid + "active").setValue2(this.obiekt.active);
                            Ext.getCmp(this.cid + "intervalgetstates").setValue2(this.obiekt.intervalgetstates);
                            Ext.getCmp(this.cid + "bladpomiaruwyjscie1").setValue2(this.obiekt.bladpomiaruwyjscie1);
                            Ext.getCmp(this.cid + "bladpomiaruwyjscie2").setValue2(this.obiekt.bladpomiaruwyjscie2);
                            Ext.getCmp(this.cid + "standalonedevice").setValue2(this.obiekt.standalonedevice);
                            Ext.getCmp(this.cid + "idzbiornik").setValue2(this.obiekt.idzbiornik);
                            Ext.getCmp(this.cid + "typkalibracji").setValue2(this.obiekt.typkalibracji);
                            Ext.getCmp(this.cid + "gestosccieczy").setValue(this.obiekt.gestosccieczy);
                            var pomiartemperybool = this.obiekt.pomiartemperatury;
                            if (!pomiartemperybool) {
                                Ext.getCmp(this.cid + "pomiartemperatury").setValue2(false);
                                Ext.getCmp(this.cid + "kompensacjatemperaturowa").setValue2(false);
                                Ext.getCmp(this.cid + "kompensacjatemperaturowa").setDisabled(true);
                            } else {
                                Ext.getCmp(this.cid + "pomiartemperatury").setValue2(true);
                                Ext.getCmp(this.cid + "kompensacjatemperaturowa").setValue2(this.obiekt.kompensacjatemperaturowa);
                            }
                            Ext.getCmp(this.cid + "zakresprzetwornik").setValue(this.obiekt.zakresprzetwornik);
                            Ext.getCmp(this.cid + "zakresprzetwornik").clearInvalid();
                            Ext.getCmp(this.cid + "wysokosczbiornik").setValue(this.obiekt.wysokosczbiornik);
                            Ext.getCmp(this.cid + "wysokosczbiornik").clearInvalid();
                            Ext.getCmp(this.cid + "polepodstawyzbiornik").setValue(this.obiekt.polepodstawyzbiornik);
                            Ext.getCmp(this.cid + "polepodstawyzbiornik").clearInvalid();
                            Ext.getCmp(this.cid + "przesuniecieczujnik").setValue(this.obiekt.przesuniecieczujnik);
                            Ext.getCmp(this.cid + "przesuniecieczujnik").clearInvalid();
                            Ext.getCmp(this.cid + "tmsintervalquery").setValue(this.obiekt.tmsintervalquery);
                            Ext.getCmp(this.cid + "tmsintervalqueryerror").setValue(this.obiekt.tmsintervalqueryerror);
                            Ext.getCmp(this.cid + "tmsintervalqueryerrorresetmodem").setValue(this.obiekt.tmsintervalqueryerrorresetmodem);
                            Ext.getCmp(this.cid + "intervalgetskpstates").setValue(this.obiekt.intervalgetskpstates);
                            Ext.getCmp(this.cid + "progoutrelay1").setValue(this.obiekt.progoutrelay1);
                            Ext.getCmp(this.cid + "hstoutrelay1").setValue(this.obiekt.hstoutrelay1);
                            Ext.getCmp(this.cid + "progoutrelay2").setValue(this.obiekt.progoutrelay2);
                            Ext.getCmp(this.cid + "hstoutrelay2").setValue(this.obiekt.hstoutrelay2);
                            Ext.getCmp(this.cid + "ckpenabled").setValue2(this.obiekt.ckpenabled);
                            Ext.getCmp(this.cid + "typoutrelay1").setValue2(this.obiekt.typoutrelay1);
                            Ext.getCmp(this.cid + "typoutrelay2").setValue2(this.obiekt.typoutrelay2);
                            Ext.getCmp(this.cid + "typzbiornika").setValue2(this.obiekt.typzbiornika);
                            Ext.getCmp(this.cid + "gestosccieczylitrazowanie").setValue(this.obiekt.gestosccieczylitrazowanie);
                            Ext.getCmp(this.cid + "gestosccieczylitrazowanie").clearInvalid();
                            if (this.klonowanie) {
                                this.obiektid = 0;
                                this.obiekt = null;
                            }
                        } else {
                            Ext.getCmp(this.cid + "forceupdate").setValue(false);
                            Ext.getCmp(this.cid + "litrazowaniegrid").store.loadData([]);
                            Ext.getCmp(this.cid + "active").setValue2(true);
                            Ext.getCmp(this.cid + "active").clearInvalid();
                            Ext.getCmp(this.cid + "intervalgetstates").setValue2(false);
                            Ext.getCmp(this.cid + "intervalgetstates").clearInvalid();
                            Ext.getCmp(this.cid + "bladpomiaruwyjscie1").setValue2(false);
                            Ext.getCmp(this.cid + "bladpomiaruwyjscie1").clearInvalid();
                            Ext.getCmp(this.cid + "bladpomiaruwyjscie2").setValue2(false);
                            Ext.getCmp(this.cid + "bladpomiaruwyjscie2").clearInvalid();
                            Ext.getCmp(this.cid + "standalonedevice").setValue2(false);
                            Ext.getCmp(this.cid + "standalonedevice").clearInvalid();
                            Ext.getCmp(this.cid + "nazwa").setValue(null);
                            Ext.getCmp(this.cid + "nazwa").clearInvalid();
                            Ext.getCmp(this.cid + "adres").setValue(1);
                            Ext.getCmp(this.cid + "adres").clearInvalid();
                            Ext.getCmp(this.cid + "idzbiornik").setValue2(null);
                            Ext.getCmp(this.cid + "idzbiornik").clearInvalid();
                            Ext.getCmp(this.cid + "typkalibracji").setValue2(Const.SKP_POMIAR_LITRAZOWANIE);
                            Ext.getCmp(this.cid + "gestosccieczy").setValue(0);
                            Ext.getCmp(this.cid + "pomiartemperatury").setValue2(false);
                            Ext.getCmp(this.cid + "kompensacjatemperaturowa").setValue2(false);
                            Ext.getCmp(this.cid + "kompensacjatemperaturowa").setDisabled(true);
                            Ext.getCmp(this.cid + "zakresprzetwornik").setValue(10);
                            Ext.getCmp(this.cid + "zakresprzetwornik").clearInvalid();
                            Ext.getCmp(this.cid + "wysokosczbiornik").setValue(1);
                            Ext.getCmp(this.cid + "wysokosczbiornik").clearInvalid();
                            Ext.getCmp(this.cid + "polepodstawyzbiornik").setValue(1);
                            Ext.getCmp(this.cid + "polepodstawyzbiornik").clearInvalid();
                            Ext.getCmp(this.cid + "przesuniecieczujnik").setValue(0);
                            Ext.getCmp(this.cid + "przesuniecieczujnik").clearInvalid();
                            Ext.getCmp(this.cid + "tmsintervalquery").setValue(1);
                            Ext.getCmp(this.cid + "tmsintervalquery").clearInvalid();
                            Ext.getCmp(this.cid + "tmsintervalqueryerror").setValue(1);
                            Ext.getCmp(this.cid + "tmsintervalqueryerror").clearInvalid();
                            Ext.getCmp(this.cid + "tmsintervalqueryerrorresetmodem").setValue(5);
                            Ext.getCmp(this.cid + "tmsintervalqueryerrorresetmodem").clearInvalid();
                            Ext.getCmp(this.cid + "intervalgetskpstates").setValue(15);
                            Ext.getCmp(this.cid + "intervalgetskpstates").clearInvalid();
                            Ext.getCmp(this.cid + "progoutrelay1").setValue(0);
                            Ext.getCmp(this.cid + "progoutrelay1").clearInvalid();
                            Ext.getCmp(this.cid + "hstoutrelay1").setValue(30);
                            Ext.getCmp(this.cid + "hstoutrelay1").clearInvalid();
                            Ext.getCmp(this.cid + "progoutrelay2").setValue(0);
                            Ext.getCmp(this.cid + "progoutrelay2").clearInvalid();
                            Ext.getCmp(this.cid + "hstoutrelay2").setValue(30);
                            Ext.getCmp(this.cid + "hstoutrelay2").clearInvalid();
                            Ext.getCmp(this.cid + "ckpenabled").setValue2(false);
                            Ext.getCmp(this.cid + "typoutrelay1").setValue2(Const.SKP_RELAY_OUT_OFF);
                            Ext.getCmp(this.cid + "typoutrelay2").setValue2(Const.SKP_RELAY_OUT_OFF);
                            Ext.getCmp(this.cid + "typzbiornika").setValue2(Const.SKP_GEOMETRY_WALEC_STOJACY);
                            Ext.getCmp(this.cid + "gestosccieczylitrazowanie").setValue(0);
                            Ext.getCmp(this.cid + "gestosccieczylitrazowanie").clearInvalid();
                        }
                        var typkalibracji = Ext.getCmp(this.cid + "typkalibracji");
                        var panelgeometria = Ext.getCmp(this.cid + "panelgeometria");
                        var panellitrazowanie = Ext.getCmp(this.cid + "panellitrazowanie");
                        panellitrazowanie.setVisible(typkalibracji.getValue2() == Const.SKP_POMIAR_LITRAZOWANIE);
                        panelgeometria.setVisible(typkalibracji.getValue2() == Const.SKP_POMIAR_GEOMETRY_OFFSET);
                    } else {
                        Ext.MessageBox.alert(Lang.error, data.error);
                    }
                });
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        var canWrite = false;
        var fp1 = Ext.getCmp(this.cid + "formpanel1");
        var formValid = true;
        formValid = formValid && Ext.getCmp(this.cid + "nazwa").isValid();
        formValid = formValid && Ext.getCmp(this.cid + "idzbiornik").isValid();
        formValid = formValid && Ext.getCmp(this.cid + "active").isValid();
        formValid = formValid && Ext.getCmp(this.cid + "typkalibracji").isValid();
        formValid = formValid && Ext.getCmp(this.cid + "gestosccieczy").isValid();
        formValid = formValid && Ext.getCmp(this.cid + "pomiartemperatury").isValid();
        formValid = formValid && Ext.getCmp(this.cid + "kompensacjatemperaturowa").isValid();
        if (Ext.getCmp(this.cid + "typkalibracji").getValue2() == Const.SKP_POMIAR_GEOMETRY_OFFSET)
            formValid = formValid && Ext.getCmp(this.cid + "zakresprzetwornik").isValid();
        if (Ext.getCmp(this.cid + "typkalibracji").getValue2() == Const.SKP_POMIAR_GEOMETRY_OFFSET)
            formValid = formValid && Ext.getCmp(this.cid + "wysokosczbiornik").isValid();
        if (Ext.getCmp(this.cid + "typkalibracji").getValue2() == Const.SKP_POMIAR_GEOMETRY_OFFSET)
            formValid = formValid && Ext.getCmp(this.cid + "polepodstawyzbiornik").isValid();
        if (Ext.getCmp(this.cid + "typkalibracji").getValue2() == Const.SKP_POMIAR_GEOMETRY_OFFSET)
            formValid = formValid && Ext.getCmp(this.cid + "przesuniecieczujnik").isValid();
        if (Ext.getCmp(this.cid + "typkalibracji").getValue2() == Const.SKP_POMIAR_LITRAZOWANIE)
            formValid = formValid && Ext.getCmp(this.cid + "gestosccieczylitrazowanie").isValid();
        formValid = formValid && Ext.getCmp(this.cid + "tmsintervalqueryerrorresetmodem").validate();
        var litrazowaniedatas = [];
        var str = Ext.getCmp(this.cid + "litrazowaniegrid").store;
        for (var i = 0; i < str.getCount(); i++) {
            var r = str.getAt(i);
            var przetwornik = r.get("przetwornik");
            var ilosccieczy = r.get("ilosccieczy");
            litrazowaniedatas.push({
                przetwornik: przetwornik,
                ilosccieczy: ilosccieczy
            });
        }
        if (formValid) {
            canWrite = true;
        }
        if (canWrite) {
            sendAjaxDataJsonRpcL("rest/skp/saveObject", {
                id: this.obiektid,
                nazwa: Ext.getCmp(this.cid + "nazwa").getValue(),
                adres: Ext.getCmp(this.cid + "adres").getValue2(),
                zakresprzetwornik: Ext.getCmp(this.cid + "zakresprzetwornik").getValue(),
                wysokosczbiornik: Ext.getCmp(this.cid + "wysokosczbiornik").getValue(),
                polepodstawyzbiornik: Ext.getCmp(this.cid + "polepodstawyzbiornik").getValue(),
                przesuniecieczujnik: Ext.getCmp(this.cid + "przesuniecieczujnik").getValue(),
                gestosccieczy: Ext.getCmp(this.cid + "gestosccieczy").getValue(),
                pomiartemperatury: Ext.getCmp(this.cid + "pomiartemperatury").getValue2(),
                kompensacjatemperaturowa: Ext.getCmp(this.cid + "kompensacjatemperaturowa").getValue2(),
                typkalibracji: Ext.getCmp(this.cid + "typkalibracji").getValue2(),
                active: Ext.getCmp(this.cid + "active").getValue2(),
                intervalgetstates: Ext.getCmp(this.cid + "intervalgetstates").getValue2(),
                bladpomiaruwyjscie1: Ext.getCmp(this.cid + "bladpomiaruwyjscie1").getValue2(),
                bladpomiaruwyjscie2: Ext.getCmp(this.cid + "bladpomiaruwyjscie2").getValue2(),
                standalonedevice: Const.SKPFLAG ? true : Ext.getCmp(this.cid + "standalonedevice").getValue2(),
                tmsintervalqueryerror: (Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG) ? 1 : Ext.getCmp(this.cid + "tmsintervalqueryerror").getValue(),
                tmsintervalqueryerrorresetmodem: (Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG) ? 5 : Ext.getCmp(this.cid + "tmsintervalqueryerrorresetmodem").getValue(),
                tmsintervalquery: (Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG) ? 1 : Ext.getCmp(this.cid + "tmsintervalquery").getValue(),
                intervalgetskpstates: (Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG) ? 1 : Ext.getCmp(this.cid + "intervalgetskpstates").getValue(),
                progoutrelay1: Ext.getCmp(this.cid + "progoutrelay1").getValue(),
                hstoutrelay1: Ext.getCmp(this.cid + "hstoutrelay1").getValue(),
                progoutrelay2: Ext.getCmp(this.cid + "progoutrelay2").getValue(),
                hstoutrelay2: Ext.getCmp(this.cid + "hstoutrelay2").getValue(),
                forceupdate: Ext.getCmp(this.cid + "forceupdate").getValue(),
                ckpenabled: Ext.getCmp(this.cid + "ckpenabled").getValue2(),
                typoutrelay1: Ext.getCmp(this.cid + "typoutrelay1").getValue2(),
                typoutrelay2: Ext.getCmp(this.cid + "typoutrelay2").getValue2(),
                typzbiornika: Ext.getCmp(this.cid + "typzbiornika").getValue2(),
                idzbiornik: Ext.getCmp(this.cid + "idzbiornik").getValue(),
                gestosccieczylitrazowanie: Ext.getCmp(this.cid + "gestosccieczylitrazowanie").getValue(),
                data: {
                    litrazowaniedatas: litrazowaniedatas
                },
                optlockversion: this.obiekt == null ? null : this.obiekt.optlockversion
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    this.obiektid = data.id;
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
                if (this.callbackF)
                    this.callbackF();
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});