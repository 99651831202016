window.WindowSysKonfaccessPropertyName = "access.manage.syskonf";
window.WindowSysKonfaccessPropertyViewName = "access.view.syskonf";
Ext.define('WindowSysKonf', {
    extend: 'Ext.ux.window.WindowExd',
    accessPropertyName: "access.manage.syskonf",
    accessPropertyViewName: "access.view.syskonf",
    title: Lang.konfiguracja_systemu.capitalise(),
    width: 500,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'fit',
            scrollable: true,
            items: [{
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                scrollable: true,
                defaults: {
                    labelWidth: 150,
                    anchor: "0",
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    xtype: 'radiogroupexd',
                    overCls: 'x6-test-radio-over',
                    id: this.cid + "ident_autohandgen",
                    name: "ident_autohandgen",
                    labelStyle: 'word-break: break-all; overflow-wrap: break-word;',
                    fieldLabel: Lang.kod_reczny,
                    columns: 2,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.generowany,
                        inputValue: true
                    }, {
                        boxLabel: Lang.wpisywany,
                        inputValue: false,
                        value: false
                    }],
                    hidden: Const.SKPFLAG
                }, {
                    id: this.cid + 'ident_operator',
                    xtype: 'comboexd',
                    autoSelect: true,
                    store: [[Const.IDENT_ELEKTRONICZNA, Lang.elektroniczna], [Const.IDENT_ELEKTRONICZNAPIN, Lang.elektroniczna_pin], [Const.IDENT_KODRECZNY, Lang.kodem_recznym]],
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    forceSelection: true,
                    value: [Const.IDENT_ELEKTRONICZNA, Lang.elektroniczna],
                    fieldLabel: Lang.identyfikacja_operatora
                }, {
                    id: this.cid + 'zb_map_type',
                    xtype: 'comboexd',
                    store: [[Const.MAP_TYPE_USE_IMAGE, Lang.mapa_graficzna], [Const.MAP_TYPE_USE_HMAP, Lang.mapa_here]],
                    typeAhead: false,
                    triggerAction: 'all',
                    selectOnFocus: false,
                    resizable: false,
                    mode: "local",
                    editable: false,
                    value: [Const.MAP_TYPE_USE_IMAGE, Lang.mapa_graficzna],
                    hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                    fieldLabel: Lang.sposb_prezentacji_graficznej_zbiornikow
                }, {
                    id: this.cid + 'lang_set',
                    xtype: 'comboexd',
                    valueField: 'value',
                    displayField: 'text',
                    store: Ext.create('Ext.data.JsonStore', {
                        fields: [{
                            name: 'value',
                            mapping: 'value'
                        }, {
                            name: 'text',
                            mapping: 'text'
                        }]
                    }),
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    fieldLabel: Lang.jezyk
                }, {
                    id: this.cid + "sys_auth_dev_code",
                    name: "sys_auth_dev_code",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.klucz_autoryzujacy_dev,
                    regex: new RegExp("^[0-9]{6,6}$"),
                    regexText: Lang.dane_niepoprawne,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    hidden: true
                }, {
                    id: this.cid + "sys_aeskey",
                    name: "sys_aeskey",
                    xtype: 'textfield',
                    fieldLabel: Lang.klucz_szyfrujacy_aes,
                    regex: new RegExp("^[0-9a-zA-Z]{16,16}$"),
                    regexText: Lang.dane_niepoprawne,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    isFormField: false,
                    xtype: 'button',
                    text: Lang.konfiguracja_domyslnej_ustawien,
                    disabled: !validPrivilegesOperatorComplex(this.accessPropertyViewName),
                    scope: this,
                    handler: this.editFirmaDefault,
                    hidden: Const.ENTERPRISEFLAG || Const.SKPFLAG
                }, {
                    isFormField: false,
                    xtype: 'button',
                    style: 'margin-top: 10px',
                    text: Lang.konfiguracja_importu_tankowan_uzytkownika,
                    disabled: !validPrivilegesOperatorComplex(this.accessPropertyViewName),
                    scope: this,
                    handler: this.editAutoImportUserTankConfiguration,
                    hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || !Const.ENABLEMANUALIMPORTUSERTANK
                }, {
                    isFormField: false,
                    xtype: 'button',
                    style: 'margin-top: 10px',
                    text: Lang.ustawienia_dodatkowe,
                    scope: this,
                    handler: this.editExtraConfiguration,
                    hidden: Const.CLOUDFLAG || !AppOPERATORROLA == Const.OPERATOR_P_SERWIS
                }, {
                    isFormField: false,
                    xtype: 'button',
                    style: 'margin-top: 10px',
                    text: Lang.restart,
                    scope: this,
                    handler: this.restartServer,
                    hidden: Const.CLOUDFLAG || !AppOPERATORROLA == Const.OPERATOR_P_SERWIS
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function () {
        if (!validPrivilegesOperatorComplex(this.accessPropertyViewName)) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        sendAjaxDataJsonRpcL("rest/systemkonf/readParams", null, this, function (data) {
            if (data.status) {
                data = data.data;
                sendAjaxDataJsonRpcL("rest/systemkonf/readLangList", null, this, function (data2) {
                    if (data2.status) {
                        data2 = data2.data;
                        Ext.getCmp(this.cid + "lang_set").getStore().loadData(data2);
                        Ext.getCmp(this.cid + "ident_operator").setValue(data.ident_operator);
                        Ext.getCmp(this.cid + "zb_map_type").setValue(data.zb_map_type);
                        Ext.getCmp(this.cid + "ident_autohandgen").setValue2(data.ident_autohandgen);
                        Ext.getCmp(this.cid + "sys_aeskey").setValue(data.sys_aeskey);
                        Ext.getCmp(this.cid + "sys_auth_dev_code").setValue(data.sys_auth_dev_code);
                        Ext.getCmp(this.cid + "lang_set").setValue2(data.lang_set);
                        Ext.getCmp(this.cid + "save").setDisabled(!validPrivilegesOperatorComplex(WindowSysKonfaccessPropertyName));
                        this.show();
                        this.center();
                        this.setKeyMap({
                            'ESC': {
                                scope: this,
                                handler: this.closeOp
                            }
                        });
                    } else {
                        Ext.MessageBox.alert(Lang.error, data2.error);
                    }
                });
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    editFirmaDefault: function () {
        var editFirmaWindow = Ext.create('WindowFirmaProDef', {
            parentObj: this
        });
        editFirmaWindow.showW();
    },
    editAutoImportUserTankConfiguration: function () {
        var editAutoImportUserTankConfigurationWindow = Ext.create('WindowCfgAutoImportUserTank', {
            parentObj: this
        });
        editAutoImportUserTankConfigurationWindow.showW();
    },
    editExtraConfiguration: function () {
        var editExtraConfigurationWindow = Ext.create('WindowExtraCfg', {
            parentObj: this
        });
        editExtraConfigurationWindow.showW();
    },
    restartServer: function () {
        let headerMessage = Lang.restart;
        let message = Lang.czy_dokonac_restart;
        Ext.create('Ext.ux.window.MessageBoxMobileExd').confirm(headerMessage, message, btn => {
            if (btn.toLowerCase() == 'yes') {
                performAjaxLPromise('rest/systemreq/asyncRestart', {}).then(() => {
                }).catch(error => {
                    handleCommonCallBackAjaxRPCError(error);
                }).finally(() => {
                    this.unmask();
                });
            }
        })
    },
    saveOp: function () {
        if (!validPrivilegesOperatorComplex(this.accessPropertyName)) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        if (!Ext.getCmp(this.cid + 'formpanel').form.isValid()) {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        sendAjaxDataJsonRpcL("rest/systemkonf/saveParams", {
            ident_operator: Ext.getCmp(this.cid + "ident_operator").getValue(),
            zb_map_type: Ext.getCmp(this.cid + "zb_map_type").getValue(),
            ident_autohandgen: Ext.getCmp(this.cid + "ident_autohandgen").getValue2(),
            sys_aeskey: Ext.getCmp(this.cid + "sys_aeskey").getValue(),
            sys_auth_dev_code: Ext.getCmp(this.cid + "sys_auth_dev_code").getValue(),
            lang_set: Ext.getCmp(this.cid + "lang_set").getValue()
        }, this, function (data) {
            if (data.status) {
                data = data.data;
                this.closeOp();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    closeOp: function () {
        this.close();
    }
});

