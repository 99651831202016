Ext.define('WindowPojazdy', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.pojazd.capitalise(),
    width: 950,
    closable: false,
    collapsible: false,
    scrollable: true,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: 'fit',
    initComponent: function (config) {
        this.cid = Ext.id();
        let formpanel1 = {
            id: this.cid + "formpanel1",
            title: Lang.ogolne.capitalise(),
            xtype: 'form',
            frame: true,
            border: false,
            autoWidth: true,
            scrollable: true,
            autoHeight: true,
            labelWidth: 80,
            bodyStyle: 'padding:10px 10px 0px 10px',
            defaults: {
                anchor: "0",
                labelWidth: 80
            },
            items: [{
                anchor: "0",
                layout: 'column',
                frame: false,
                border: false,
                items: [{
                    columnWidth: WindowExdMobileDEVICEV ? 1 : 0.7,
                    layout: 'anchor',
                    frame: false,
                    border: false,
                    defaults: {
                        anchor: "0",
                        labelWidth: 130,
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: [{
                        id: this.cid + "nazwa",
                        name: "nazwa",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.nazwa,
                        allowBlank: false,
                        maxLength: 16,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        id: this.cid + "eiid",
                        name: "eiid",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.eiid,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                    }, {
                        id: this.cid + "rokprodukcji",
                        name: "rokprodukcji",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.rok_produkcji,
                        hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        id: this.cid + "identeuro",
                        name: "identeuro",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.ident_euro,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                    }, {
                        id: this.cid + "grupa",
                        name: "grupa",
                        style: "margin-top: 10px",
                        fieldLabel: Lang.grupa,
                        allowBlank: true,
                        xtype: 'comboexd',
                        emptyText: Lang.brak_grupy,
                        store: Ext.create('Ext.data.Store', {
                            fields: [{
                                name: 'id',
                                allowNull: true
                            }, 'nazwa'],
                            proxy: {
                                type: 'ajax',
                                url: 'rest/grupy/getList',
                                actionMethods: {
                                    read: 'POST'
                                },
                                extraParams: {
                                    type: Const.GROUP_TYPE_POJAZD
                                },
                                reader: {
                                    type: 'json',
                                    rootProperty: 'data'
                                }
                            },
                            autoLoad: false
                        }),
                        displayField: 'nazwa',
                        valueField: 'id',
                        hidden: Const.STARTERFLAG
                    }, {
                        id: this.cid + "identflota",
                        name: "identflota",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.ident_flota,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        xtype: 'radiogroupexd',
                        id: this.cid + "active",
                        name: "active",
                        baseCls: 'x-radio-column',
                        fieldLabel: Lang.active,
                        columns: 2,
                        vertical: false,
                        items: [{
                            boxLabel: Lang.tak.capitalise(),
                            inputValue: true,
                            value: true
                        }, {
                            boxLabel: Lang.nie.capitalise(),
                            inputValue: false
                        }]
                    }, {
                        xtype: 'radiogroupexd',
                        id: this.cid + "ismotogodzinynotkilometry",
                        name: "ismotogodzinynotkilometry",
                        baseCls: 'x-radio-column',
                        fieldLabel: Lang.typ_pracy,
                        columns: 2,
                        vertical: false,
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                        items: [{
                            boxLabel: Lang.motogodziny.capitalise(),
                            inputValue: true
                        }, {
                            boxLabel: Lang.kilometry.capitalise(),
                            inputValue: false,
                            value: true
                        }],
                        listeners: {
                            change: function (radiogroup, newValue) {
                                if (this.defaultMaxMileageDiff != null) {
                                    if (newValue)
                                        Ext.getCmp(this.cid + "maxmileagediff").setValue(this.defaultMaxMileageDiff.rg);
                                    else
                                        Ext.getCmp(this.cid + "maxmileagediff").setValue(this.defaultMaxMileageDiff.km);
                                }
                            },
                            scope: this
                        }
                    }, {
                        id: this.cid + 'stanlicznika',
                        isFormField: false,
                        xtype: 'button',
                        text: Lang.popraw_ostatni_stan_licznika,
                        scope: this,
                        handler: this.showStanLicznika,
                        hidden: Const.STARTERFLAG
                    }]
                }]
            }]
        };
        let identyfikatorpanel = {
            xtype: 'identyfikatorpanel',
            fieldLabel: Lang.identyfikator,
            labelAlign: 'left',
            id: this.cid + "identpanel"
        };
        let opis = {
            anchor: "0",
            fieldLabel: Lang.opis,
            isFormField: true,
            id: this.cid + "opis",
            name: "opis",
            xtype: 'textarea'
        };
        let panelstanczytnika = {
            id: this.cid + "panelstanczytnika",
            xtype: "panelstanczytnikaexd"
        };
        if (WindowExdMobileDEVICEV)
            formpanel1.items[0].items[0].items.push(identyfikatorpanel, opis, panelstanczytnika);
        else {
            formpanel1.items[0].items.push({
                columnWidth: 0.3,
                layout: 'anchor',
                frame: false,
                border: false,
                defaults: {
                    anchor: "0",
                    labelWidth: 130
                },
                bodyStyle: 'padding-left: 15px; padding-top: 7.5px',
                items: [identyfikatorpanel]
            });
            formpanel1.items.push({
                anchor: "0",
                layout: 'column',
                frame: false,
                border: false,
                isFormField: false,
                items: [{
                    columnWidth: 0.7,
                    layout: 'anchor',
                    frame: false,
                    border: false,
                    isFormField: false,
                    height: 150,
                    style: "margin-top:10px",
                    defaults: {
                        anchor: "0"
                    },
                    items: [opis]
                }, {
                    style: "margin-left:15px;",
                    columnWidth: .3,
                    xtype: 'container',
                    layout: 'anchor',
                    cls: "x-ident-main-border2",
                    height: 150,
                    id: this.cid + "identpanelct",
                    items: [panelstanczytnika]
                }]
            });
        }
        this.items = [{
            xtype: 'tabpanel',
            autoWidth: true,
            height: 555,
            border: false,
            deferredRender: false,
            activeTab: 0,
            items: [formpanel1, {
                id: this.cid + "formpanel2",
                title: Lang.zaawansowane,
                xtype: 'form',
                frame: true,
                border: false,
                autoWidth: true,
                scrollable: true,
                autoHeight: true,
                hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                labelWidth: 80,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0",
                    labelWidth: 80
                },
                items: [{
                    anchor: "0",
                    layout: 'column',
                    frame: false,
                    border: false,
                    items: [{
                        columnWidth: 1,
                        layout: 'anchor',
                        frame: false,
                        border: false,
                        defaults: {
                            anchor: "0",
                            labelWidth: 130,
                            labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                        },
                        items: [{
                            id: this.cid + "fueltanklimit",
                            name: "fueltanklimit",
                            xtype: 'numberfieldexd',
                            fieldLabel: Lang.pojemnosc_baku_paliwa,
                            decimalPrecision: 2,
                            minValue: 0,
                            // allowBlank: false,
                            // blankText: Lang.pole_nie_moze_byc_puste,
                            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "controlfueltanklimit",
                            name: "controlfueltanklimit",
                            baseCls: 'x-radio-column',
                            fieldLabel: Lang.limit_pojemnosci_baku,
                            columns: 2,
                            vertical: false,
                            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                            items: [{
                                boxLabel: Lang.tak.capitalise(),
                                inputValue: true,
                                value: true
                            }, {
                                boxLabel: Lang.nie.capitalise(),
                                inputValue: false
                            }]
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "canviewtankowanie",
                            name: "canviewtankowanie",
                            baseCls: 'x-radio-column',
                            fieldLabel: Lang.podglad_tankowania,
                            columns: 2,
                            vertical: false,
                            items: [{
                                boxLabel: Lang.tak.capitalise(),
                                inputValue: true,
                                value: true
                            }, {
                                boxLabel: Lang.nie.capitalise(),
                                inputValue: false
                            }],
                            hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "cansetaddpole",
                            name: "cansetaddpole",
                            fieldLabel: Lang.pole_dodatkowe_przy_tankowaniu,
                            columns: 2,
                            vertical: false,
                            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                            items: [{
                                boxLabel: Lang.tak.capitalise(),
                                inputValue: true
                            }, {
                                boxLabel: Lang.nie.capitalise(),
                                inputValue: false,
                                value: true
                            }]
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "askforvolumetank",
                            name: "askforvolumetank",
                            baseCls: 'x-radio-column',
                            fieldLabel: Lang.pytanie_o_ilosc_dozatankowania,
                            columns: 2,
                            vertical: false,
                            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                            items: [{
                                boxLabel: Lang.tak,
                                inputValue: true
                            }, {
                                boxLabel: Lang.nie,
                                inputValue: false,
                                value: true
                            }]
                        }]
                    }]
                }]
            }, {
                title: Lang.limity.capitalise(),
                frame: true,
                border: false,
                autoWidth: true,
                scrollable: true,
                autoHeight: true,
                hidden: Const.SKPFLAG || Const.BASICFLAG || Const.STARTERFLAG,
                defaults: {
                    anchor: "0",
                    labelWidth: 80
                },
                items: [{
                    id: this.cid + 'limitnotice',
                    xtype: 'displayfield',
                    value: Lang.limity_wylacznie_online,
                    fieldStyle: 'color: red; text-align: center;',
                    hidden: !Const.STANDARDFLAG,
                    height: 40
                }, Ext.create('LimitPanelCp', {
                    hidden: Const.SKPFLAG || (Const.BASICFLAG && Const.CUSTOMMODFLAG != 'swimer') || Const.STARTERFLAG,
                    frame: false,
                    border: false,
                    id: this.cid + "limitpanel",
                    height: WindowExdMobileDEVICEV ? '100%' : 497 - (Const.STANDARDFLAG ? 50 : 0),
                    parentObj: this,
                    header: false
                })]
            }, {
                id: this.cid + "formpanel3",
                title: Lang.normy_zuzycia.capitalise(),
                xtype: 'form',
                frame: true,
                border: false,
                autoWidth: true,
                scrollable: true,
                autoHeight: true,
                labelWidth: 80,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0",
                    labelWidth: 80
                },
                hidden: (Const.BASICFLAG && Const.CUSTOMMODFLAG != 'swimer') || Const.STARTERFLAG,
                items: [{
                    anchor: "0",
                    xtype: 'panel',
                    id: this.cid + "normypanel",
                    layout: 'form'
                }]
            }, {
                id: this.cid + "formpanel4",
                title: Lang.ustawienia_indywidualne,
                xtype: 'form',
                frame: true,
                border: false,
                autoWidth: true,
                scrollable: true,
                autoHeight: true,
                hidden: Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                labelWidth: 80,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0",
                    labelWidth: 80
                },
                items: [{
                    anchor: "0",
                    layout: 'column',
                    frame: false,
                    border: false,
                    items: [{
                        columnWidth: 1,
                        layout: 'anchor',
                        frame: false,
                        border: false,
                        defaults: {
                            anchor: "0",
                            labelWidth: 130,
                            labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                        },
                        items: [{
                            xtype: 'radiogroupexd',
                            id: this.cid + "individualopts",
                            name: "individualopts",
                            fieldLabel: Lang.ustawienia_indywidualne,
                            columns: 2,
                            vertical: false,
                            items: [{
                                boxLabel: Lang.tak,
                                inputValue: true
                            }, {
                                boxLabel: Lang.nie,
                                inputValue: false,
                                value: true
                            }],
                            listeners: {
                                change: function (radiogroup, newValue) {
                                    Ext.getCmp(this.cid + "ident_tryb").setDisabled(!newValue);
                                    Ext.getCmp(this.cid + "ident_pojqq").setDisabled(!newValue);
                                    var mustsetlicznikstate = Ext.getCmp(this.cid + "mustsetlicznikstate");
                                    var mustsetlicznikstatecontrol = Ext.getCmp(this.cid + "mustsetlicznikstatecontrol");
                                    mustsetlicznikstate.setDisabled(!newValue);
                                    if (!newValue) {
                                        mustsetlicznikstate.setValue(false);
                                        mustsetlicznikstate.fireEvent("change", mustsetlicznikstate, mustsetlicznikstate.getValue2());
                                        mustsetlicznikstatecontrol.setValue(false);
                                        mustsetlicznikstatecontrol.fireEvent("change", mustsetlicznikstatecontrol, mustsetlicznikstatecontrol.getValue2());
                                    }
                                    Ext.getCmp(this.cid + "controlmileagediff").setDisabled(!newValue || !mustsetlicznikstatecontrol.getValue2());
                                    Ext.getCmp(this.cid + "maxmileagediff").setDisabled(!Ext.getCmp(this.cid + "controlmileagediff").getValue2() || !newValue);
                                },
                                scope: this
                            },
                            hidden: Const.SKPFLAG
                        }, {
                            id: this.cid + 'ident_tryb',
                            xtype: 'comboexd',
                            store: [[Const.IDENT_PODWOJNA, Lang.podwojna], [Const.IDENT_POJEDYNCZA, Lang.pojedyncza]],
                            triggerAction: 'all',
                            resizable: false,
                            mode: "local",
                            editable: false,
                            disabled: true,
                            fieldLabel: Lang.tryb_identyfikacji,
                            hidden: Const.SKPFLAG
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "ident_pojqq",
                            name: "ident_pojqq",
                            fieldLabel: Lang.pytanie_o_nazwe,
                            columns: 2,
                            vertical: false,
                            disabled: true,
                            items: [{
                                boxLabel: Lang.tak,
                                inputValue: true
                            }, {
                                boxLabel: Lang.nie,
                                inputValue: false,
                                value: true
                            }],
                            hidden: Const.SKPFLAG
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "mustsetlicznikstate",
                            name: "mustsetlicznikstate",
                            fieldLabel: Lang.wymagalnosc_stanu_licznika,
                            columns: 2,
                            vertical: false,
                            disabled: true,
                            items: [{
                                boxLabel: Lang.tak,
                                inputValue: true
                            }, {
                                boxLabel: Lang.nie,
                                inputValue: false,
                                value: true
                            }],
                            listeners: {
                                scope: this,
                                change: function (radiogroup, newValue) {
                                    var mustsetlicznikstatecontrol = Ext.getCmp(this.cid + "mustsetlicznikstatecontrol");
                                    if (!newValue) {
                                        mustsetlicznikstatecontrol.setValue(false);
                                        mustsetlicznikstatecontrol.fireEvent("change", mustsetlicznikstatecontrol, mustsetlicznikstatecontrol.getValue2());
                                    }
                                    mustsetlicznikstatecontrol.setDisabled(!newValue);
                                }
                            },
                            hidden: Const.SKPFLAG
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "mustsetlicznikstatecontrol",
                            name: "mustsetlicznikstatecontrol",
                            fieldLabel: Lang.kontrola_stanu_licznika,
                            columns: 2,
                            vertical: false,
                            disabled: true,
                            items: [{
                                boxLabel: Lang.tak,
                                inputValue: true
                            }, {
                                boxLabel: Lang.nie,
                                inputValue: false,
                                value: true
                            }],
                            listeners: {
                                scope: this,
                                change: function (radiogroup, newValue) {
                                    var controlmileagediff = Ext.getCmp(this.cid + "controlmileagediff");
                                    if (!newValue) {
                                        controlmileagediff.setValue(false);
                                        controlmileagediff.fireEvent("change", controlmileagediff, controlmileagediff.getValue2());
                                    }
                                    controlmileagediff.setDisabled(!newValue);
                                }
                            },
                            hidden: Const.SKPFLAG
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "controlmileagediff",
                            name: "controlmileagediff",
                            fieldLabel: Lang.kontrola_roznicy_przebiegu,
                            columns: 2,
                            vertical: false,
                            disabled: true,
                            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || this.vvtype == "odbiorca",
                            items: [{
                                boxLabel: Lang.tak.capitalise(),
                                inputValue: true
                            }, {
                                boxLabel: Lang.nie.capitalise(),
                                inputValue: false,
                                value: true
                            }],
                            listeners: {
                                change: function (radiogroup, newValue) {
                                    var maxmileagediff = Ext.getCmp(this.cid + "maxmileagediff");
                                    if (!newValue) {
                                        maxmileagediff.setValue(null);
                                    }
                                    maxmileagediff.setDisabled(!newValue);
                                },
                                scope: this
                            }
                        }, {
                            id: this.cid + "maxmileagediff",
                            name: "maxmileagediff",
                            xtype: 'numberfieldexd',
                            fieldLabel: Lang.maksymalna_roznica_przebiegu,
                            allowBlank: false,
                            disabled: true,
                            decimalPrecision: 2,
                            minValue: 0,
                            cls: "x-item-disabled-sec",
                            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                        }]
                    }]
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showStanLicznika: function () {
        var windowPojazdLicznik = Ext.create('WindowPojazdLicznik', {
            dane: {
                pojazdid: this.obiektid,
                stanlicznika: this.obiekt["stanlicznika"],
                lastupdate: this.obiekt["stanlicznikalastupdate"]
            },
            parentObj: this
        });
        windowPojazdLicznik.showW();
        windowPojazdLicznik.on("wclose", function (stanLicznika) {
            this.obiekt["stanlicznika"] = stanLicznika;
        }, this);
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex("access.manage.pojazdy")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.obiektid = obiektid;
        this.obiekt = null;
        this.defaultMaxMileageDiff = null;
        sendAjaxDataJsonRpcLGroup(["rest/pojazdy/getObjectForEdit", "rest/plyny/getShortList"], [{
            id: this.obiektid
        }, null], this, function (gstatus, data, datad, status) {
            if (gstatus) {
                this.obiekt = datad[0];
                sendAjaxDataJsonRpcL("rest/firmy/getObjectForEdit", {
                    id: this.obiekt != null ? this.obiekt.idfirma : (AppFIRMACTX != null) ? AppFIRMACTX.id : 1
                }, this, function (firmadata) {
                    if (firmadata.status) {
                        this.show();
                        this.center();
                        this.setKeyMap({
                            'ESC': {
                                scope: this,
                                handler: this.closeOp
                            }
                        });
                        Ext.getCmp(this.cid + "nazwa").setValue(null);
                        Ext.getCmp(this.cid + "nazwa").clearInvalid();
                        Ext.getCmp(this.cid + "eiid").setValue(null);
                        Ext.getCmp(this.cid + "eiid").clearInvalid();
                        Ext.getCmp(this.cid + "rokprodukcji").setValue(null);
                        Ext.getCmp(this.cid + "rokprodukcji").clearInvalid();
                        Ext.getCmp(this.cid + "identeuro").setValue(null);
                        Ext.getCmp(this.cid + "identeuro").clearInvalid();
                        Ext.getCmp(this.cid + "active").setValue2(true);
                        Ext.getCmp(this.cid + "opis").setValue(null);
                        Ext.getCmp(this.cid + "canviewtankowanie").setValue2(true);
                        Ext.getCmp(this.cid + "cansetaddpole").setValue2(false);
                        Ext.getCmp(this.cid + "askforvolumetank").setValue2(true);
                        Ext.getCmp(this.cid + "ismotogodzinynotkilometry").setValue2(false);
                        Ext.getCmp(this.cid + "fueltanklimit").setValue(null);
                        Ext.getCmp(this.cid + "fueltanklimit").clearInvalid();
                        Ext.getCmp(this.cid + "controlfueltanklimit").setValue2(false);
                        Ext.getCmp(this.cid + "identflota").setValue(null);
                        Ext.getCmp(this.cid + "identflota").clearInvalid();
                        this.normals = [];
                        Ext.getCmp(this.cid + 'stanlicznika').setVisible(false);
                        var firma = firmadata.data;
                        if (firma != null) {
                            if (firma.maxmileagediffkm > 0 || firma.maxmileagediffrg > 0) {
                                this.defaultMaxMileageDiff = {
                                    km: firma.maxmileagediffkm,
                                    rg: firma.maxmileagediffrg
                                };
                            }
                        }
                        this.plynls = datad[1];
                        if (this.obiekt != null) {
                            this.limitydatas = this.obiekt.limitydatas;
                            Ext.getCmp(this.cid + 'limitpanel').setLimitList(this.limitydatas);
                            this.normals = this.obiekt.normals;
                            Ext.getCmp(this.cid + "nazwa").setValue(this.obiekt.nazwa);
                            Ext.getCmp(this.cid + "eiid").setValue(this.obiekt.eiid);
                            Ext.getCmp(this.cid + "rokprodukcji").setValue(this.obiekt.rokprodukcji);
                            Ext.getCmp(this.cid + "identeuro").setValue(this.obiekt.identeuro);
                            Ext.getCmp(this.cid + "active").setValue2(this.obiekt.active);
                            Ext.getCmp(this.cid + "opis").setValue(this.obiekt.opis);
                            Ext.getCmp(this.cid + "askforvolumetank").setValue2(this.obiekt.askforvolumetank);
                            Ext.getCmp(this.cid + "canviewtankowanie").setValue2(this.obiekt.canviewtankowanie);
                            Ext.getCmp(this.cid + "cansetaddpole").setValue2(this.obiekt.cansetaddpole);
                            Ext.getCmp(this.cid + "ismotogodzinynotkilometry").setValue2(this.obiekt.ismotogodzinynotkilometry);
                            Ext.getCmp(this.cid + "controlfueltanklimit").setValue2(this.obiekt.controlfueltanklimit);
                            Ext.getCmp(this.cid + "grupa").getStore().getProxy().setExtraParam('firma', this.obiekt.idfirma);
                            Ext.getCmp(this.cid + "grupa").setValue(this.obiekt.idgrupa);
                            Ext.getCmp(this.cid + "fueltanklimit").setValue(this.obiekt.fueltanklimit);
                            Ext.getCmp(this.cid + "ident_tryb").setValue(this.obiekt.ident_tryb);
                            Ext.getCmp(this.cid + "ident_pojqq").setValue2(this.obiekt.ident_pojqq);
                            Ext.getCmp(this.cid + "mustsetlicznikstate").setValue2(this.obiekt.mustsetlicznikstate);
                            Ext.getCmp(this.cid + "mustsetlicznikstatecontrol").setValue2(this.obiekt.mustsetlicznikstatecontrol);
                            Ext.getCmp(this.cid + "identflota").setValue(this.obiekt.identflota);
                            if (this.obiekt.maxmileagediff > 0) {
                                Ext.getCmp(this.cid + "maxmileagediff").setValue(this.obiekt.maxmileagediff);
                                Ext.getCmp(this.cid + "controlmileagediff").setValue2(true);
                            }
                            this.identPanel = Ext.getCmp(this.cid + "identpanel");
                            this.identPanel.init({
                                obiektid: obiektid,
                                objectt: "pojazd",
                                object: this.obiekt,
                                readerPanel: Ext.getCmp(this.cid + 'panelstanczytnika')
                            });
                            Ext.getCmp(this.cid + 'stanlicznika').setVisible(!Const.STARTERFLAG);
                        } else {
                            if (firma != null) {
                                Ext.getCmp(this.cid + "askforvolumetank").setValue2(firma.askforvolumetank);
                            }
                            this.obiekt = this.obiekt || {
                                optlockversion: null,
                                ident_tryb: Const.IDENT_PODWOJNA,
                                ident_pojqq: false,
                                mustsetlicznikstate: false,
                                mustsetlicznikstatecontrol: false,
                                individualopts: false
                            };
                            var ismotogodzinynotkilometry = Ext.getCmp(this.cid + "ismotogodzinynotkilometry");
                            ismotogodzinynotkilometry.fireEvent("change", ismotogodzinynotkilometry, ismotogodzinynotkilometry.getValue2());
                            this.limitydatas = [];
                            this.identPanel = Ext.getCmp(this.cid + "identpanel");
                            this.identPanel.init({
                                obiektid: obiektid,
                                objectt: "pojazd",
                                readerPanel: Ext.getCmp(this.cid + 'panelstanczytnika')
                            });
                        }
                        Ext.getCmp(this.cid + 'limitpanel').setLimitList(this.limitydatas);
                        Ext.getCmp(this.cid + "ident_tryb").setValue(this.obiekt.ident_tryb);
                        Ext.getCmp(this.cid + "ident_pojqq").setValue2(this.obiekt.ident_pojqq);
                        Ext.getCmp(this.cid + "mustsetlicznikstate").setValue2(this.obiekt.mustsetlicznikstate);
                        Ext.getCmp(this.cid + "mustsetlicznikstatecontrol").setValue2(this.obiekt.mustsetlicznikstatecontrol || (!this.obiekt.individualopts && firma.mustsetlicznikstatecontrol));
                        Ext.getCmp(this.cid + "mustsetlicznikstatecontrol").fireEvent("change", Ext.getCmp(this.cid + "mustsetlicznikstatecontrol"), this.obiekt.mustsetlicznikstatecontrol || (!this.obiekt.individualopts && firma.mustsetlicznikstatecontrol));
                        var individualOpts = Ext.getCmp(this.cid + "individualopts");
                        individualOpts.setValue2(this.obiekt.individualopts);
                        individualOpts.fireEvent("change", individualOpts, this.obiekt.individualopts);
                        Ext.getCmp(this.cid + "grupa").getStore().load({
                            scope: this,
                            callback: function (records, operation, success) {
                                if (Ext.getCmp(this.cid + "grupa")) {
                                    Ext.getCmp(this.cid + "grupa").getStore().insert(0, [{
                                        nazwa: Lang.brak_grupy,
                                        id: null
                                    }]);
                                }
                            }
                        });
                        Ext.suspendLayouts();
                        for (var i = 0; i < this.plynls.length; i++) {
                            var pl = this.plynls[i];
                            var iddl = Ext.id();
                            Ext.getCmp(this.cid + "normypanel").add({
                                anchor: "0",
                                xtype: 'panel',
                                frame: false,
                                border: false,
                                layout: {
                                    type: WindowExdMobileDEVICEV ? 'vbox' : 'hbox',
                                    align: 'stretch'
                                },
                                id: iddl + "panel",
                                bodyStyle: 'padding:0px 0px 5px 0px',
                                items: [{
                                    flex: 1,
                                    margin: "0 5 0 0",
                                    id: iddl + 'plyn',
                                    name: "plyn",
                                    frame: false,
                                    border: false,
                                    xtype: 'displayexd'
                                }, {
                                    flex: 1,
                                    frame: false,
                                    border: false,
                                    margin: "0 5 0 0",
                                    id: iddl + "wartosc",
                                    name: "wartosc",
                                    xtype: 'numberfieldexd',
                                    fieldLabel: Lang.wartosc,
                                    allowBlank: true,
                                    decimalPrecision: 2,
                                    blankText: Lang.pole_nie_moze_byc_puste,
                                    minValue: 0,
                                    maxValue: 9999999
                                }]
                            });
                            var plyn = Ext.getCmp(iddl + "plyn");
                            var wartosc = Ext.getCmp(iddl + "wartosc");
                            plyn.setValue(pl.nazwa);
                            var nn = null;
                            for (var k = 0; k < this.normals.length; k++) {
                                if (this.normals[k].idplyn == pl.id) {
                                    nn = this.normals[k];
                                    nn.elcwartosc = wartosc;
                                    wartosc.setValue(nn.wartosczuzycie1001h);
                                }
                            }
                            if (nn == null) {
                                nn = {
                                    wartosczuzycie1001h: null,
                                    idplyn: pl.id,
                                    elcwartosc: wartosc
                                };
                                this.normals.push(nn);
                            }
                        }
                        Ext.resumeLayouts(true);
                        var title = Lang.pojazd.capitalise();
                        if (AppFIRMACTX != null)
                            title += " (" + AppFIRMACTX.nazwa + ")";
                        this.setTitle(title);
                    } else {
                        Ext.MessageBox.alert(Lang.error, firmadata.error);
                    }
                });
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        var canWrite = false;
        if (Ext.getCmp(this.cid + "nazwa").isValid()) {
            canWrite = true;
        }
        if (!this.identPanel.isValid()) {
            if (Ext.getCmp(this.cid + "active").getValue2()) {
                Ext.MessageBox.alert(Lang.error, Lang.obiekt_aktywny_musi_miec_przydzielony_identyfikator);
                return;
            }
        }
        if (!Ext.getCmp(this.cid + 'formpanel1').form.isValid() || !Ext.getCmp(this.cid + 'formpanel2').form.isValid() || !Ext.getCmp(this.cid + 'formpanel3').form.isValid() || !Ext.getCmp(this.cid + 'formpanel4').form.isValid()) {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        var msgggp = this.identPanel.isValidOtherErrorIf();
        if (msgggp != null) {
            Ext.MessageBox.alert(Lang.error, msgggp);
            return;
        }
        var vii = this.identPanel.getIdentData();
        if (canWrite) {
            var maxMileageDiff = 0;
            if (Ext.getCmp(this.cid + "individualopts").getValue2()) {
                maxMileageDiff = Ext.getCmp(this.cid + "maxmileagediff").getValue();
            } else if (this.defaultMaxMileageDiff) {
                if (Ext.getCmp(this.cid + "ismotogodzinynotkilometry").getValue2()) {
                    maxMileageDiff = this.defaultMaxMileageDiff.rg;
                } else {
                    maxMileageDiff = this.defaultMaxMileageDiff.km;
                }
            }
            for (var k = 0; k < this.normals.length; k++) {
                var nn = this.normals[k];
                nn.wartosczuzycie1001h = nn.elcwartosc.getValue2();
                delete nn.elcwartosc;
            }
            sendAjaxDataJsonRpcL("rest/pojazdy/saveObject", {
                id: this.obiektid,
                nazwa: Ext.getCmp(this.cid + "nazwa").getValue(),
                eiid: Ext.getCmp(this.cid + "eiid").getValue(),
                rokprodukcji: Ext.getCmp(this.cid + "rokprodukcji").getValue(),
                identeuro: Ext.getCmp(this.cid + "identeuro").getValue(),
                active: Ext.getCmp(this.cid + "active").getValue2(),
                ismotogodzinynotkilometry: Ext.getCmp(this.cid + "ismotogodzinynotkilometry").getValue2(),
                opis: Ext.getCmp(this.cid + "opis").getValue(),
                askforvolumetank: Ext.getCmp(this.cid + "askforvolumetank").getValue2(),
                canviewtankowanie: Ext.getCmp(this.cid + "canviewtankowanie").getValue2(),
                cansetaddpole: Ext.getCmp(this.cid + "cansetaddpole").getValue2(),
                controlfueltanklimit: Ext.getCmp(this.cid + "controlfueltanklimit").getValue2(),
                fueltanklimit: Ext.getCmp(this.cid + "fueltanklimit").getValue(),
                maxmileagediff: maxMileageDiff,
                ident_tryb: Ext.getCmp(this.cid + "ident_tryb").getValue(),
                ident_pojqq: Ext.getCmp(this.cid + "ident_pojqq").getValue2(),
                mustsetlicznikstate: Ext.getCmp(this.cid + "mustsetlicznikstate").getValue2(),
                mustsetlicznikstatecontrol: Ext.getCmp(this.cid + "mustsetlicznikstatecontrol").getValue2(),
                individualopts: Ext.getCmp(this.cid + "individualopts").getValue2(),
                identyfikator: vii.identyfikator,
                identtype: vii.identtype,
                pin: vii.pin,
                grupa: Ext.getCmp(this.cid + "grupa").getValue2NL(),
                optlockversion: this.obiekt == null ? null : this.obiekt.optlockversion,
                identflota: Ext.getCmp(this.cid + "identflota").getValue(),
                data: {
                    limitydatas: Ext.getCmp(this.cid + 'limitpanel').getLimitList(),
                    normals: this.normals
                }
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    this.obiektid = data.id;
                    this.identPanel.close();
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                    if (data.error == Lang.identyfikator_uzywany) {
                        this.identPanel.markIdAsUsed();
                    }
                    return;
                }
                if (this.callbackF)
                    this.callbackF();
            }, 0);
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.identPanel.close();
        this.close();
    }
});
