Ext.define('WindowZaladunekLimity', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.zaladunki_limity.capitalise(),
    width: 650,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: 'fit',
    initComponent: function (config) {
        this.cid = Ext.id();
        let formpanel = {
            id: this.cid + "formpanel",
            xtype: 'form',
            frame: true,
            border: false,
            autoWidth: true,
            autoHeight: true,
            labelWidth: 130,
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0",
                labelWidth: 130
            },
            scrollable: 'y',
            fileUpload: true,
            items: [{
                anchor: "0",
                layout: 'column',
                frame: false,
                border: false,
                items: [{
                    columnWidth: WindowExdMobileDEVICEV ? 1 : .4,
                    layout: 'anchor',
                    style: 'padding-left: 10px; padding-top: 10px;',
                    labelWidth: 110,
                    frame: false,
                    border: false,
                    defaults: {
                        anchor: "0",
                        labelWidth: 110,
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: [{
                        id: this.cid + 'idfirma',
                        xtype: 'comboexd',
                        valueField: 'id',
                        displayField: 'nazwa',
                        store: new Ext.data.JsonStore({
                            id: "id",
                            rootProperty: '',
                            sorters: [{
                                property: "nazwa",
                                direction: "ASC"
                            }],
                            fields: [{
                                name: 'id',
                                mapping: 'id'
                            }, {
                                name: 'nazwa',
                                mapping: 'nazwa'
                            }]
                        }),
                        triggerAction: 'all',
                        resizable: false,
                        editable: false,
                        mode: "local",
                        fieldLabel: Lang.firma,
                        labelWidth: 45,
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        listeners: {
                            scope: this
                        },
                        cls: "x-item-disabled-sec"
                    }, {
                        id: this.cid + 'idplyn',
                        xtype: 'comboexd',
                        valueField: 'id',
                        displayField: 'nazwa',
                        store: new Ext.data.JsonStore({
                            id: "id",
                            rootProperty: '',
                            sorters: [{
                                property: "nazwa",
                                direction: "ASC"
                            }],
                            fields: [{
                                name: 'id',
                                mapping: 'id'
                            }, {
                                name: 'nazwa',
                                mapping: 'nazwa'
                            }]
                        }),
                        triggerAction: 'all',
                        resizable: false,
                        editable: false,
                        mode: "local",
                        fieldLabel: Lang.plyn,
                        labelWidth: 45,
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        listeners: {
                            scope: this
                        },
                        cls: "x-item-disabled-sec"
                    }]
                }]
            }]
        };
        let formfields = [{
            id: this.cid + "ile",
            name: "ile",
            xtype: 'numberfieldexd',
            fieldLabel: Lang.wartosc_przydzialu + Lang.jednostka_text,
            allowBlank: false,
            allowNegative: false,
            decimalPrecision: 3,
            blankText: Lang.pole_nie_moze_byc_puste,
            minValue: 0.001,
            maxValue: 9999999,
            cls: "x-item-disabled-sec"
        }, {
            id: this.cid + "stanlimitu",
            name: "stanlimitu",
            xtype: 'numberfieldexd',
            fieldLabel: Lang.stan_po_korekcie + Lang.jednostka_text,
            allowBlank: false,
            allowNegative: false,
            decimalPrecision: 3,
            blankText: Lang.pole_nie_moze_byc_puste,
            minValue: 0.001,
            maxValue: 9999999,
            cls: "x-item-disabled-sec"
        }];
        let korektaiopis = [{
            xtype: 'checkboxexd',
            id: this.cid + "iskorekta",
            name: "iskorekta",
            fieldLabel: Lang.korekta_przydzialu,
            labelAlign: 'left',
            listeners: {
                scope: this,
                change: this.changeSelectKorekta
            }
        }, {
            id: this.cid + "opis",
            name: "opis",
            xtype: 'textareaexd',
            fieldLabel: Lang.opis
        }];
        if (WindowExdMobileDEVICEV)
            formpanel.items[0].items[0].items.push(...formfields, ...korektaiopis);
        else {
            formpanel.items[0].items.push({
                columnWidth: .6,
                layout: 'anchor',
                labelWidth: 160,
                style: 'padding-left: 10px; padding-right: 10px; padding-top: 10px;',
                frame: false,
                border: false,
                defaults: {
                    anchor: "0",
                    labelWidth: 160
                },
                items: [...formfields]
            });
            formpanel.items.push({
                anchor: "0",
                layout: 'column',
                frame: false,
                border: false,
                items: [{
                    columnWidth: 1,
                    layout: 'form',
                    frame: false,
                    border: false,
                    defaults: {
                        anchor: "0"
                    },
                    items: [...korektaiopis]
                }]
            });
        }
        this.items = [{
            xtype: 'panel',
            layout: 'fit',
            items: [formpanel]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    updateStan: function () {
        var ile = Ext.getCmp(this.cid + "ile").getValue() == "" ? 0 : Ext.getCmp(this.cid + "ile").getValue();
        if (this.obiekt == null) {
            var idfirmaprev = Ext.getCmp(this.cid + "idfirma").getValue2();
            var idplynprev = Ext.getCmp(this.cid + "idplyn").getValue2();
            if (this.cidfirmaprev != idfirmaprev || this.cidplynprev != idplynprev) {
                sendAjaxDataJsonRpcL("rest/zaladuneklimity/getObjectForEditInfoStan", {
                    idfirma: idfirmaprev,
                    idplyn: idplynprev
                }, this, function (data) {
                    if (data.status && data.data != null) {
                        this.stanlimituold = data.data;
                        Ext.getCmp(this.cid + "stanlimitu").setValue(data.data + ile);
                    } else {
                        this.stanlimituold = null;
                        Ext.getCmp(this.cid + "stanlimitu").setValue(null);
                    }
                });
            } else {
                if (!Ext.getCmp(this.cid + "iskorekta").getValue2()) {
                    Ext.getCmp(this.cid + "stanlimitu").setValue(ile + this.stanlimituold);
                }
            }
            this.cidfirmaprev = idfirmaprev;
            this.cidplynprev = idplynprev;
        } else {
            if (!Ext.getCmp(this.cid + "iskorekta").getValue2()) {
                Ext.getCmp(this.cid + "stanlimitu").setValue(ile + this.stanlimituold);
            }
        }
    },
    changeSelectKorekta: function () {
        Ext.getCmp(this.cid + "ile").setDisabled(Ext.getCmp(this.cid + "iskorekta").getValue2());
        Ext.getCmp(this.cid + "stanlimitu").setDisabled(!Ext.getCmp(this.cid + "iskorekta").getValue2());
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex("access.manage.zaladunkilimity")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.obiektid = obiektid;
        this.obiekt = null;
        Promise.all([
            performAjaxLPromise("rest/plyny/getList", {
                idinclude: this.obiekt != null ? this.obiekt.idplyn : 0
            }),
            performAjaxLPromise("rest/firmy/getList", {
                idinclude: this.obiekt != null ? this.obiekt.idfirma : 0, showOnlySupported: true
            }),
            performAjaxLPromise("rest/zaladuneklimity/getObjectForEdit", {
                id: this.obiektid
            })
        ]).then(Ext.bind(function (datad) {
            if (datad[2]) {
                this.obiekt = datad[2];
            }
            if (datad[0]) {
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                Ext.getCmp(this.cid + 'idplyn').store.loadData(datad[0]);
                Ext.getCmp(this.cid + 'idfirma').store.loadData(datad[1]);
                if (this.obiekt == null) {
                    Ext.getCmp(this.cid + 'idplyn').setValue2Default(null);
                    Ext.getCmp(this.cid + "idplyn").setDisabled(false);
                    Ext.getCmp(this.cid + 'idfirma').setValue2Default(null);
                    Ext.getCmp(this.cid + 'iskorekta').getValue();
                    Ext.getCmp(this.cid + "idfirma").setDisabled(false);
                    Ext.getCmp(this.cid + "ile").setValue(null);
                    Ext.getCmp(this.cid + "ile").clearInvalid();
                    Ext.getCmp(this.cid + "ile").setDisabled(false);
                    Ext.getCmp(this.cid + "stanlimitu").setValue(null);
                    Ext.getCmp(this.cid + "stanlimitu").clearInvalid();
                    Ext.getCmp(this.cid + "stanlimitu").setDisabled(false);
                    Ext.getCmp(this.cid + "opis").setValue("");
                    Ext.getCmp(this.cid + "iskorekta").setValue2(false);
                    Ext.getCmp(this.cid + "iskorekta").setDisabled(false);
                    this.changeSelectKorekta();
                    this.cidfirmaprev = null;
                    this.cidplynprev = null;
                    this.task = {
                        run: this.updateStan,
                        scope: this,
                        interval: 1000
                    };
                    Ext.TaskManager.start(this.task);
                } else {
                    Ext.getCmp(this.cid + "iskorekta").setDisabled(true);
                    Ext.getCmp(this.cid + "iskorekta").setValue2(this.obiekt.iskorekta);
                    Ext.getCmp(this.cid + 'idplyn').setValue2(this.obiekt.idplyn);
                    Ext.getCmp(this.cid + "idplyn").setDisabled(true);
                    Ext.getCmp(this.cid + 'idfirma').setValue2(this.obiekt.idfirma);
                    Ext.getCmp(this.cid + "idfirma").setDisabled(true);
                    Ext.getCmp(this.cid + "opis").setValue(this.obiekt.opis);
                    Ext.getCmp(this.cid + "ile").setValue(this.obiekt.ile);
                    Ext.getCmp(this.cid + "ile").setDisabled(true);
                    Ext.getCmp(this.cid + "stanlimitu").setValue(this.obiekt.stanlimitu);
                    Ext.getCmp(this.cid + "stanlimitu").setDisabled(true);
                }
            }
        }, this), handleCommonCallBackAjaxRPCError);
    },
    saveOp: function () {
        var canWrite = false;
        if (!this.obiektid) {
            if (Ext.getCmp(this.cid + "ile").isValid() && Ext.getCmp(this.cid + "idfirma").isValid() && Ext.getCmp(this.cid + "stanlimitu").isValid()) {
                canWrite = true;
            }
        } else
            canWrite = true;
        if (canWrite) {
            sendAjaxDataJsonRpcL("rest/zaladuneklimity/saveObject", {
                id: this.obiektid,
                idfirma: Ext.getCmp(this.cid + "idfirma").getValue2(),
                idplyn: Ext.getCmp(this.cid + "idplyn").getValue2(),
                ile: Ext.getCmp(this.cid + "ile").getValue() == "" ? 0 : Ext.getCmp(this.cid + "ile").getValue(),
                stanlimitu: Ext.getCmp(this.cid + "stanlimitu").getValue() == "" ? 0 : Ext.getCmp(this.cid + "stanlimitu").getValue(),
                opis: Ext.getCmp(this.cid + "opis").getValue(),
                optlockversion: this.obiekt == null ? null : this.obiekt.optlockversion,
                iskorekta: Ext.getCmp(this.cid + "iskorekta").getValue2()
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    this.obiektid = data.id;
                    if (this.task != undefined) {
                        Ext.TaskManager.stop(this.task);
                    }
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
                if (this.callbackF)
                    this.callbackF();
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        if (this.task != undefined) {
            Ext.TaskManager.stop(this.task);
        }
        this.close();
    }
});
