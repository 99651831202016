Ext.define('NotificationType', {
    extend: 'Ext.data.Model',
    fields: [{
        name: 'key',
        mapping: 'key'
    }, {
        name: 'name',
        mapping: 'name'
    }, {
        name: "value",
        mapping: 'value'
    }, {
        name: "disabled",
        mapping: "disabled"
    }, {
        name: "hidden",
        mapping: "hidden"
    }],
    idProperty: 'key'
});
Ext.define('PanelOperatorEmailConfig', {
    extend: "Ext.panel.Panel",
    layout: {
        type: 'accordion',
        titleCollapse: 'true',
        animate: 'true',
        multi: false
    },
    frame: false,
    border: false,
    autoWidth: false,
    autoHeight: false,
    operatorId: null,
    initComponent: function (config) {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'form',
            id: this.cid + "config",
            title: Lang.konfiguracja,
            collapsible: true,
            collapsed: false,
            scrollable: true,
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0"
            },
            items: [{
                anchor: "0",
                layout: "anchor",
                frame: false,
                border: false,
                autoWidth: true,
                labelWidth: 160,
                defaults: {
                    labelWidth: 160,
                    anchor: "0"
                },
                items: [{
                    xtype: "textfield",
                    fieldLabel: Lang.adresy_email,
                    allowBlank: true,
                    regex: /^([\w!#$%&'\*\+\?\^~`\|\{\}\._=/-]+@[\w-]+(\.[\w-]+)*\.[A-Za-z]+[,;]?)+$/,
                    regexText: Lang.adresy_email_formatowanie,
                    labelAlign: 'top',
                    id: this.cid + "emailaddresses"
                }, {
                    xtype: "numberfield",
                    fieldLabel: Lang.maksymalna_procentowa_roznica_ubytku_paliwa,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    id: this.cid + "percentdifference",
                    labelAlign: 'top',
                    value: 7,
                    hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || !validPrivilegesOperatorComplex("access.view.stany")
                        || !validPrivilegesOperatorComplex("access.view.skpspomiary") || (!AppOPERATORROLA == Const.OPERATOR_P_SERWIS && validPrivilegesOperatorComplex("access.view.stany_limit_only"))
                }, {
                    xtype: "numberfield",
                    fieldLabel: Lang.min_ilosc_tankowan_zerowych,
                    id: this.cid + "zerorefuelings",
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    labelAlign: 'top',
                    value: 10,
                    hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || !validPrivilegesOperatorComplex("access.view.tankowania")
                }, {
                    xtype: "numberfield",
                    fieldLabel: Lang.liczba_dozwolonych_godzin_awaria_skp,
                    id: this.cid + "hourspassed",
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    labelAlign: 'top',
                    value: 4,
                    hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || !validPrivilegesOperatorComplex("access.view.skpspomiary")
                }, {
                    xtype: "numberfield",
                    fieldLabel: Lang.tolerancja_stan_zbiornika,
                    id: this.cid + "tankleveltolerance",
                    allowDecimals: true,
                    step: 0.5,
                    value: 5,
                    allowBlank: false,
                    labelAlign: 'top',
                    blankText: Lang.pole_nie_moze_byc_puste,
                    hidden: !validPrivilegesOperatorComplex("access.view.stany") || (!AppOPERATORROLA == Const.OPERATOR_P_SERWIS && validPrivilegesOperatorComplex("access.view.stany_limit_only"))
                }, {
                    xtype: "numberfield",
                    fieldLabel: Lang.prog_wykricia_zaladunkow,
                    id: this.cid + "loadingthreshold",
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    labelAlign: 'top',
                    value: 100,
                    hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || !validPrivilegesOperatorComplex("access.view.stany")
                        || !validPrivilegesOperatorComplex("access.view.skpspomiary") || (!AppOPERATORROLA == Const.OPERATOR_P_SERWIS && validPrivilegesOperatorComplex("access.view.stany_limit_only"))
                }, {
                    xtype: "numberfield",
                    fieldLabel: Lang.szacowany_czas_zaladunku,
                    id: this.cid + "loadingduration",
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    labelAlign: 'top',
                    value: 30,
                    hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || !validPrivilegesOperatorComplex("access.view.stany")
                        || !validPrivilegesOperatorComplex("access.view.skpspomiary") || (!AppOPERATORROLA == Const.OPERATOR_P_SERWIS && validPrivilegesOperatorComplex("access.view.stany_limit_only"))
                }]
            }]
        }, {
            xtype: 'form',
            id: this.cid + "config2",
            title: Lang.powiadomienia_email,
            collapsible: true,
            collapsed: true,
            scrollable: true,
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0"
            },
            items: [{
                xtype: 'grid',
                anchor: '0',
                scrollable: true,
                id: this.cid + "typegrid",
                tbar: [{
                    text: Lang.zaznacz_wszystko,
                    listeners: {
                        scope: this,
                        click: function () {
                            var editgridpanelstore = Ext.getCmp(this.cid + "typegrid").getStore();
                            for (var i = 0; i < editgridpanelstore.getCount(); i++) {
                                var r = editgridpanelstore.getAt(i).set("value", true);
                            }
                            editgridpanelstore.commitChanges();
                        }
                    }
                }, {
                    text: Lang.odznacz_wszystko,
                    listeners: {
                        scope: this,
                        click: function () {
                            var editgridpanelstore = Ext.getCmp(this.cid + "typegrid").getStore();
                            for (var i = 0; i < editgridpanelstore.getCount(); i++) {
                                var r = editgridpanelstore.getAt(i).set("value", false);
                            }
                            editgridpanelstore.commitChanges();
                        }
                    }
                }],
                store: Ext.create('Ext.data.Store', {
                    autoLoad: true,
                    model: 'NotificationType',
                    filters: [{
                        property: "disabled",
                        value: false
                    }, {
                        property: "hidden",
                        value: false
                    }],
                    proxy: {
                        type: 'memory',
                        reader: {
                            type: 'array'
                        }
                    },
                    data: [{
                        name: Lang.raportowanie_kazdego_tankowania,
                        key: "EACH_REFUELING",
                        hidden: Const.STARTERFLAG || !validPrivilegesOperatorComplex("access.view.tankowania"),
                        disabled: false
                    }, {
                        name: Lang.stanalarmowy_arytmetyczny_powiadomienie_nazwa,
                        key: "REACHING_ALARM_LEVEL_ARITHMETIC",
                        hidden: !validPrivilegesOperatorComplex("access.view.stany") || (!AppOPERATORROLA == Const.OPERATOR_P_SERWIS && validPrivilegesOperatorComplex("access.view.stany_limit_only")),
                        disabled: false
                    }, {
                        name: Lang.stanalarmowy_rzecz_powiadomienie_nazwa,
                        key: "REACHING_ALARM_LEVEL_REAL",
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || !validPrivilegesOperatorComplex("access.view.stany")
                            || !validPrivilegesOperatorComplex("access.view.skpspomiary") || (!AppOPERATORROLA == Const.OPERATOR_P_SERWIS && validPrivilegesOperatorComplex("access.view.stany_limit_only")),
                        disabled: false
                    }, {
                        name: Lang.stanblokady_arytmetyczny_powiadomienie_nazwa,
                        key: "REACHING_BLOCK_LEVEL_ARITHMETIC",
                        hidden: !validPrivilegesOperatorComplex("access.view.stany") || (!AppOPERATORROLA == Const.OPERATOR_P_SERWIS && validPrivilegesOperatorComplex("access.view.stany_limit_only")),
                        disabled: false
                    }, {
                        name: Lang.stanblokady_rzecz_powiadomienie_nazwa,
                        key: "REACHING_BLOCK_LEVEL_REAL",
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || !validPrivilegesOperatorComplex("access.view.stany")
                            || !validPrivilegesOperatorComplex("access.view.skpspomiary") || (!AppOPERATORROLA == Const.OPERATOR_P_SERWIS && validPrivilegesOperatorComplex("access.view.stany_limit_only")),
                        disabled: false
                    }, {
                        name: Lang.kalibracja_powiadomienie_raportowania_nazwa,
                        key: "CALIBRATION",
                        hidden: Const.STARTERFLAG || !validPrivilegesOperatorComplex("access.view.dziennik"),
                        disabled: false
                    }, {
                        name: Lang.duzy_ubytek_paliwa_powiadomienie_nazwa,
                        key: "SIGNIFICANT_FUEL_LEVEL_DEVIATION",
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || !validPrivilegesOperatorComplex("access.view.stany")
                            || !validPrivilegesOperatorComplex("access.view.skpspomiary") || (!AppOPERATORROLA == Const.OPERATOR_P_SERWIS && validPrivilegesOperatorComplex("access.view.stany_limit_only")),
                        disabled: false
                    }, {
                        name: Lang.raportowanie_zaladunkow_zbiornika_powiadomienie_nazwa,
                        key: "LOADING",
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || !validPrivilegesOperatorComplex("access.view.stany")
                            || !validPrivilegesOperatorComplex("access.view.skpspomiary") || (!AppOPERATORROLA == Const.OPERATOR_P_SERWIS && validPrivilegesOperatorComplex("access.view.stany_limit_only")),
                        disabled: false
                    }, {
                        name: Lang.przeciek_powiadomienie_nazwa,
                        key: "TANK_LEAK",
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || !validPrivilegesOperatorComplex("access.view.stany")
                            || !validPrivilegesOperatorComplex("access.view.skpspomiary") || (!AppOPERATORROLA == Const.OPERATOR_P_SERWIS && validPrivilegesOperatorComplex("access.view.stany_limit_only")),
                        disabled: false
                    }, {
                        name: Lang.przekroczenie_gornej_dopuszczalnej_temp_plynu,
                        key: "HIGHER_FUEL_TEMP_LIMIT_EXCEEDED",
                        disabled: true,
                        hidden: true
                    }, {
                        name: Lang.przekroczenie_dolnej_dopuszczalnej_temp_plynu,
                        key: "LOWER_FUEL_TEMP_LIMIT_EXCEEDED",
                        disabled: true,
                        hidden: true
                    }, {
                        name: Lang.kilka_tankowan_zerowych_z_rzedu_powiadomienie_nazwa,
                        key: "FEW_ZERO_REFUELINGS",
                        disabled: false,
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || !validPrivilegesOperatorComplex("access.view.tankowania")
                    }, {
                        name: Lang.awaria_skp_powiadomienie_nazwa,
                        key: "SKP_ERROR",
                        disabled: false,
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || !validPrivilegesOperatorComplex("access.view.skpspomiary")
                    }]
                }),
                columns: [{
                    header: Lang.rodzaj_powiadomienia,
                    flex: 3,
                    sortable: 3,
                    dataIndex: "name",
                    editable: false,
                    cellWrap: true
                }, {
                    header: Lang.active,
                    xtype: 'checkcolumn',
                    dataIndex: 'value',
                    flex: 1,
                    overCls: 'x6-grid-checkcolumn-over',
                    listeners: {
                        scope: this,
                        checkchange: function () {
                            var store = Ext.getCmp(this.cid + "typegrid").getStore();
                            store.commitChanges();
                        }
                    }
                }]
            }]
        },
            Ext.create("OperatorZbiornikiUprawnieniaDefCmp", {
                height: 400,
                gridHeight: 260,
                collapsible: true,
                collapsed: true,
                scrollable: true,
                title: Lang.zbiorniki,
                id: this.cid + "zbiorniki",
                bodyStyle: 'padding:10px 10px 10px 10px'
            }), {
                xtype: "panel",
                title: Lang.stan,
                collapsible: true,
                collapsed: true,
                scrollable: true,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0"
                },
                items: [{
                    xtype: "component",
                    id: this.cid + "statusCmp",
                    html: '-'
                }]
            }];
        this.callParent(arguments);
    },
    getEnabledNotifications: function () {
        var store = Ext.getCmp(this.cid + "typegrid").getStore();
        var test = store.query("value", true);
        return test.collect("key", "data");
    },
    setEnabledNotifications: function (enabledNotifications) {
        if (enabledNotifications == null) return;
        var store = Ext.getCmp(this.cid + "typegrid").getStore();
        for (var i = 0; i < enabledNotifications.length; i++) {
            store.getById(enabledNotifications[i]).set("value", true);
        }
        store.commitChanges();
    },
    loadNotificationConfig: function (config, zbiornikData) {
        if (config == null) return;
        Ext.getCmp(this.cid + "emailaddresses").setValue(config.emailAddresses);
        Ext.getCmp(this.cid + "percentdifference").setValue(config.fuelDifferencePercentTrigger);
        Ext.getCmp(this.cid + "zerorefuelings").setValue(config.zeroRefuelingsNumberTrigger);
        Ext.getCmp(this.cid + "hourspassed").setValue(config.hoursPassedTrigger);
        Ext.getCmp(this.cid + "tankleveltolerance").setValue(config.tankLevelTolerance);
        Ext.getCmp(this.cid + "loadingthreshold").setValue(config.loadingThreshold);
        Ext.getCmp(this.cid + "loadingduration").setValue(config.loadingDuration);
        this.loadZbiornikData(zbiornikData, (config.selectedZbiorniks != null ? config.selectedZbiorniks : []));
        this.setEnabledNotifications(config.enabledNotificationTypes);
    },
    generateNotificationConfig: function () {
        var emailAddressesCmp = Ext.getCmp(this.cid + "emailaddresses");
        var enabledNotifications = this.getEnabledNotifications();
        if (!emailAddressesCmp.isValid() || !Ext.getCmp(this.cid + "percentdifference").isValid() ||
            !Ext.getCmp(this.cid + "zerorefuelings").isValid() || !Ext.getCmp(this.cid + "hourspassed").isValid()) {
            return Lang.dane_niepoprawne;
        }
        if (emailAddressesCmp.getValue().length == 0 && enabledNotifications.length > 0) {
            return Lang.brak_adresow_mailowych;
        }
        return {
            emailAddresses: Ext.getCmp(this.cid + "emailaddresses").getValue(),
            fuelDifferencePercentTrigger: Ext.getCmp(this.cid + "percentdifference").getValue(),
            zeroRefuelingsNumberTrigger: Ext.getCmp(this.cid + "zerorefuelings").getValue(),
            hoursPassedTrigger: Ext.getCmp(this.cid + "hourspassed").getValue(),
            tankLevelTolerance: Ext.getCmp(this.cid + "tankleveltolerance").getValue(),
            loadingThreshold: Ext.getCmp(this.cid + "loadingthreshold").getValue(),
            loadingDuration: Ext.getCmp(this.cid + "loadingduration").getValue(),
            enabledNotificationTypes: enabledNotifications,
            selectedZbiorniks: Ext.getCmp(this.cid + "zbiorniki").getSelectedZbiorniks()
        };
    },
    loadZbiornikData: function (zbiorniks, selectedZbiorniks) {
        Ext.getCmp(this.cid + "zbiorniki").loadData(zbiorniks, selectedZbiorniks);
    },
    loadNotificationParams: function (params) {
        if (params == null) return;
        if (params.status) {
            var statusHtml = params.status;
            statusHtml = "<p>" + statusHtml + "</p>";
            statusHtml = statusHtml.replace("\n", "</p><p>");
            Ext.getCmp(this.cid + "statusCmp").setHtml(statusHtml);
        }
    }
});