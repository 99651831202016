Ext.define('WindowZbiorniki', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.zbiornik.capitalise(),
    width: 630,
    height: 550,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        var tabs = [{
            title: Lang.ogolne.capitalise(),
            xtype: 'form',
            id: this.cid + 'formpanel1',
            bodyStyle: 'padding:10px 10px 10px 10px',
            frame: true,
            scrollable: true,
            autoWidth: true,
            defaults: {
                anchor: "0"
            },
            items: [{
                anchor: "0",
                layout: 'column',
                scrollable: true,
                border: false,
                items: [{
                    columnWidth: 1,
                    border: false,
                    layout: 'anchor',
                    defaults: {
                        anchor: "-10",
                        labelWidth: 210,
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: [{
                        id: this.cid + "nazwa",
                        name: "nazwa",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.nazwa,
                        allowBlank: false,
                        maxLength: 16,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        id: this.cid + "eiid",
                        name: "eiid",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.eiid,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        id: this.cid + "idplyn",
                        xtype: 'comboexd',
                        fieldLabel: Lang.plyn,
                        valueField: 'id',
                        displayField: 'nazwa',
                        store: new Ext.data.JsonStore({
                            id: "id",
                            rootProperty: '',
                            sorters: [{
                                property: "nazwa",
                                direction: "ASC"
                            }],
                            fields: [{
                                name: 'id',
                                mapping: 'id'
                            }, {
                                name: 'nazwa',
                                mapping: 'nazwa'
                            }]
                        }),
                        triggerAction: 'all',
                        resizable: false,
                        editable: false,
                        mode: "local",
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        id: this.cid + "pojemnosc",
                        name: "pojemnosc",
                        xtype: 'numberfieldexd',
                        fieldLabel: Lang.pojemnosc + Lang.jednostka_text,
                        allowBlank: false,
                        decimalPrecision: 3,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        minValue: 0.001,
                        maxValue: 9999999
                    }, {
                        id: this.cid + "stanalarmowy",
                        name: "stanalarmowy",
                        xtype: 'numberfieldexd',
                        fieldLabel: Lang.stanalarmowy + Lang.jednostka_text,
                        allowBlank: false,
                        decimalPrecision: 3,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        minValue: 0.001,
                        maxValue: 9999999,
                        validator: Ext.Function.bind(function (v) {
                            var p1 = Ext.getCmp(this.cid + "pojemnosc").getValue();
                            var p2 = Ext.getCmp(this.cid + "stanalarmowy").getValue();
                            var p3 = Ext.getCmp(this.cid + "stanblokady").getValue();
                            Ext.getCmp(this.cid + "stanblokady").validate();
                            if (p1 < p2)
                                return Lang.pojemnosc_mniejszy_stanalarmowy_invalid;
                            return true;
                        }, this, [])
                    }, {
                        id: this.cid + "stanblokady",
                        name: "stanblokady",
                        xtype: 'numberfieldexd',
                        fieldLabel: Lang.stanblokady + Lang.jednostka_text,
                        allowBlank: false,
                        decimalPrecision: 3,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        minValue: 0.001,
                        maxValue: 9999999,
                        validator: Ext.Function.bind(function (v) {
                            var p1 = Ext.getCmp(this.cid + "pojemnosc").getValue();
                            var p2 = Ext.getCmp(this.cid + "stanalarmowy").getValue();
                            var p3 = Ext.getCmp(this.cid + "stanblokady").getValue();
                            if (p2 < p3)
                                return Lang.stanalarmowy_mniejszy_stanbloady_invalid;
                            return true;
                        }, this, [])
                    }, {
                        id: this.cid + "opis",
                        name: "opis",
                        xtype: 'textareaexd',
                        height: 120,
                        fieldLabel: Lang.opis,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }]
                }]
            }]
        }, {
            title: Lang.zaawansowane.capitalise(),
            xtype: 'form',
            id: this.cid + 'formpanel2',
            bodyStyle: 'padding:10px 10px 10px 10px',
            frame: true,
            scrollable: true,
            autoWidth: true,
            defaults: {
                anchor: "0"
            },
            items: [{
                anchor: "0",
                layout: 'column',
                scrollable: true,
                border: false,
                items: [{
                    columnWidth: 1,
                    border: false,
                    layout: 'anchor',
                    defaults: {
                        anchor: "-10",
                        labelWidth: 210,
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: [{
                        xtype: 'radiogroupexd',
                        id: this.cid + "magazynoff",
                        name: "magazynoff",
                        baseCls: 'x-radio-column',
                        fieldLabel: Lang.magazynowa_kontrola_stanu_zbiornika,
                        columns: 2,
                        vertical: false,
                        items: [{
                            boxLabel: Lang.wlaczone.capitalise(),
                            inputValue: false,
                            value: true
                        }, {
                            boxLabel: Lang.wylaczone.capitalise(),
                            inputValue: true
                        }],
                        hidden: Const.SKPFLAG
                    }, {
                        xtype: 'radiogroupexd',
                        id: this.cid + "kompensacjaon",
                        name: "kompensacjaon",
                        baseCls: 'x-radio-column',
                        fieldLabel: Lang.kompensacja_temperaturowa,
                        columns: 2,
                        vertical: false,
                        items: [{
                            boxLabel: Lang.tak.capitalise(),
                            inputValue: true
                        }, {
                            boxLabel: Lang.nie.capitalise(),
                            inputValue: false,
                            value: true
                        }],
                        hidden: Const.SKPFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                    }, {
                        xtype: 'radiogroupexd',
                        id: this.cid + "gestoscwymagana",
                        name: "gestoscwymagana",
                        baseCls: 'x-radio-column',
                        fieldLabel: Lang.gestosc_wymagana,
                        columns: 2,
                        vertical: false,
                        items: [{
                            boxLabel: Lang.tak.capitalise(),
                            inputValue: true
                        }, {
                            boxLabel: Lang.nie.capitalise(),
                            inputValue: false,
                            value: true
                        }],
                        hidden: Const.SKPFLAG
                    }, {
                        xtype: 'radiogroupexd',
                        id: this.cid + "zaladunekopison",
                        name: "zaladunekopison",
                        baseCls: 'x-radio-column',
                        fieldLabel: Lang.opis_zaladunku,
                        columns: 2,
                        vertical: false,
                        items: [{
                            boxLabel: Lang.tak.capitalise(),
                            inputValue: true
                        }, {
                            boxLabel: Lang.nie.capitalise(),
                            inputValue: false,
                            value: true
                        }],
                        hidden: Const.SKPFLAG
                    }, {
                        xtype: 'radiogroupexd',
                        id: this.cid + "activeinbateria",
                        name: "activeinbateria",
                        baseCls: 'x-radio-column',
                        fieldLabel: Lang.aktywny_w_baterii,
                        columns: 2,
                        vertical: false,
                        items: [{
                            boxLabel: Lang.tak.capitalise(),
                            inputValue: true
                        }, {
                            boxLabel: Lang.nie.capitalise(),
                            inputValue: false,
                            value: true
                        }],
                        hidden: true
                    }, {
                        id: this.cid + "vmintodsp",
                        name: "vmintodsp",
                        xtype: 'numberfieldexd',
                        fieldLabel: Lang.min_ilosc_do_wyswietlenia + Lang.jednostka_text,
                        allowBlank: false,
                        decimalPrecision: 3,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        minValue: 0.000,
                        maxValue: 9999999,
                        hidden: Const.SKPFLAG
                    }]
                }]
            }]
        }];
        if (Const.ENTERPRISEFLAG && !Const.CLOUDFLAG) {
            tabs.push({
                title: Lang.firmy.capitalise(),
                disabled: isValidOperatorFirmaLimit(),
                id: this.cid + "formpanel3",
                hidden: false,
                xtype: "panel",
                layout: "fit",
                frame: true,
                height: 400,
                autoWidth: true,
                labelAlign: "top",
                bodyStyle: 'padding:10px 10px 10px 10px',
                items: [{
                    xtype: 'panel',
                    frame: false,
                    border: false,
                    autoWidth: true,
                    layout: 'hbox',
                    layoutConfig: {
                        align: "stretch"
                    },
                    items: [new Ext.ux.grid.GridPanelExd({
                        store: new Ext.data.Store({
                            autoLoad: false,
                            proxy: new Ext.data.MemoryProxy({}),
                            reader: new Ext.data.JsonReader({
                                id: "id",
                                rootProperty: ''
                            }, [{
                                name: 'nazwa',
                                mapping: 'nazwa'
                            }])
                        }),
                        columns: [{
                            header: Lang.nazwa,
                            flex: 1,
                            width: 145,
                            sortable: true,
                            dataIndex: 'nazwa'
                        }],
                        title: Lang.dostepne.capitalise(),
                        height: 390,
                        flex: 1,
                        frame: true,
                        border: true,
                        id: this.cid + "firmas_from",
                        stateId: null,
                        stateful: false,
                        stripeRows: true,
                        enableHdMenu: true,
                        selModel: {
                            selType: 'rowmodel',
                            mode: 'SINGLE'
                        },
                        listeners: {
                            scope: this,
                            rowdblclick: function () {
                                var grid = Ext.getCmp(this.cid + "firmas_from");
                                var grid2 = Ext.getCmp(this.cid + "firmas_to");
                                var r = grid.getSelR();
                                if (r == null || r.items.length == 0)
                                    return;
                                var selectedItem = r.items[0];
                                grid.store.remove(selectedItem);
                                grid2.store.add(selectedItem);
                            }
                        },
                        getSelR: function () {
                            var gridd = this;
                            if (!gridd.getSelectionModel().hasSelection()) {
                                return null;
                            } else {
                                if (gridd.getSelectionModel().getSelected() != null) {
                                    return gridd.getSelectionModel().getSelected();
                                }
                            }
                            return null;
                        }
                    }), {
                        width: 55,
                        xtype: 'panel',
                        frame: false,
                        border: false,
                        bodyStyle: 'padding:5px 5px 5px 5px',
                        height: 390,
                        layout: {
                            type: 'vbox',
                            pack: "center",
                            align: "stretch"
                        },
                        items: [{
                            xtype: 'button',
                            text: ">",
                            width: 50,
                            scope: this,
                            style: "margin-bottom:5px;",
                            handler: function () {
                                var grid = Ext.getCmp(this.cid + "firmas_from");
                                var grid2 = Ext.getCmp(this.cid + "firmas_to");
                                var r = grid.getSelR();
                                if (r == null || r.items.length == 0)
                                    return;
                                var selectedItem = r.items[0];
                                grid.store.remove(selectedItem);
                                grid2.store.add(selectedItem);
                            }
                        }, {
                            xtype: 'button',
                            text: ">>",
                            width: 50,
                            scope: this,
                            style: "margin-bottom:5px;",
                            handler: function () {
                                var grid = Ext.getCmp(this.cid + "firmas_from");
                                var grid2 = Ext.getCmp(this.cid + "firmas_to");
                                grid2.store.add(grid.store.getRange());
                                grid.store.removeAll();
                            }
                        }, {
                            xtype: 'button',
                            text: "<",
                            width: 50,
                            scope: this,
                            style: "margin-bottom:5px;",
                            handler: function () {
                                var grid = Ext.getCmp(this.cid + "firmas_from");
                                var grid2 = Ext.getCmp(this.cid + "firmas_to");
                                var r = grid2.getSelR();
                                if (r == null || r.items.length == 0)
                                    return;
                                var selectedItem = r.items[0];
                                grid2.store.remove(selectedItem);
                                grid.store.add(selectedItem);
                            }
                        }, {
                            xtype: 'button',
                            text: "<<",
                            width: 50,
                            scope: this,
                            style: "margin-bottom:5px;",
                            handler: function () {
                                var grid = Ext.getCmp(this.cid + "firmas_from");
                                var grid2 = Ext.getCmp(this.cid + "firmas_to");
                                grid.store.add(grid2.store.getRange());
                                grid2.store.removeAll();
                            }
                        }]
                    }, new Ext.ux.grid.GridPanelExd({
                        store: new Ext.data.Store({
                            autoLoad: false,
                            proxy: new Ext.data.MemoryProxy({}),
                            reader: new Ext.data.JsonReader({
                                id: "id",
                                rootProperty: ''
                            }, [{
                                name: 'nazwa',
                                mapping: 'nazwa'
                            }])
                        }),
                        columns: [{
                            header: Lang.nazwa,
                            flex: 1,
                            width: 145,
                            sortable: true,
                            dataIndex: 'nazwa'
                        }],
                        title: Lang.wybrane.capitalise(),
                        height: 390,
                        flex: 1,
                        frame: true,
                        border: true,
                        id: this.cid + "firmas_to",
                        stateId: null,
                        stateful: false,
                        stripeRows: true,
                        enableHdMenu: true,
                        selModel: {
                            selType: 'rowmodel',
                            mode: 'SINGLE'
                        },
                        listeners: {
                            scope: this,
                            rowdblclick: function () {
                                var grid = Ext.getCmp(this.cid + "firmas_from");
                                var grid2 = Ext.getCmp(this.cid + "firmas_to");
                                var r = grid2.getSelR();
                                if (r == null || r.items.length == 0)
                                    return;
                                var selectedItem = r.items[0];
                                grid2.store.remove(selectedItem);
                                grid.store.add(selectedItem);
                            }
                        },
                        getSelR: function () {
                            var gridd = this;
                            if (!gridd.getSelectionModel().hasSelection()) {
                                return null;
                            } else {
                                if (gridd.getSelectionModel().getSelected() != null) {
                                    return gridd.getSelectionModel().getSelected();
                                }
                            }
                            return null;
                        }
                    })]
                }]
            });
        }
        this.items = [{
            xtype: 'tabpanel',
            autoWidth: true,
            height: 350,
            border: false,
            activeTab: 0,
            items: tabs
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid, klonowanie) {
        if (!validPrivilegesOperatorComplex("access.manage.zbiorniki")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.klonowanie = klonowanie || false;
        this.obiektid = obiektid;
        this.obiekt = null;
        sendAjaxDataJsonRpcL("rest/firmy/getList", {showOnlySupported: true}, this, function (data) {
            if (data.status) {
                data = data.data;
                var listFnData0 = data;
                sendAjaxDataJsonRpcL("rest/zbiorniki/getObjectForEdit", {
                    id: this.obiektid
                }, this, function (data) {
                    if (data.status) {
                        this.obiekt = data.data;
                        sendAjaxDataJsonRpcL("rest/plyny/getShortList", {
                            idinclude: this.obiekt != null ? this.obiekt.idplyn : 0
                        }, this, function (data) {
                            if (data.status) {
                                var listFnData = data.data;
                                this.show();
                                this.center();
                                this.setKeyMap({
                                    'ESC': {
                                        scope: this,
                                        handler: this.closeOp
                                    }
                                });
                                Ext.getCmp(this.cid + "idplyn").store.loadData(listFnData);
                                this.listFnData0 = listFnData0;
                                if (this.obiekt != null) {
                                    if (this.klonowanie) {
                                        this.obiekt.nazwa += (this.obiektid + 1);
                                    }
                                    if (Const.ENTERPRISEFLAG) {
                                        var obss = generateDiffCollection(listFnData0, this.obiekt.firmas, "id");
                                        var grid = Ext.getCmp(this.cid + "firmas_from");
                                        var grid2 = Ext.getCmp(this.cid + "firmas_to");
                                        grid.store.loadData(obss.restls);
                                        grid2.store.loadData(obss.containsls);
                                    }
                                    Ext.getCmp(this.cid + "nazwa").setValue(this.obiekt.nazwa);
                                    Ext.getCmp(this.cid + "opis").setValue(this.obiekt.opis);
                                    Ext.getCmp(this.cid + "eiid").setValue(this.obiekt.eiid);
                                    Ext.getCmp(this.cid + "magazynoff").setValue2(this.obiekt.magazynoff);
                                    Ext.getCmp(this.cid + "kompensacjaon").setValue2(this.obiekt.kompensacjaon);
                                    Ext.getCmp(this.cid + "gestoscwymagana").setValue2(this.obiekt.gestoscwymagana);
                                    Ext.getCmp(this.cid + "pojemnosc").setValue(this.obiekt.pojemnosc);
                                    Ext.getCmp(this.cid + "vmintodsp").setValue(this.obiekt.vmintodsp);
                                    Ext.getCmp(this.cid + "stanalarmowy").setValue(this.obiekt.stanalarmowy);
                                    Ext.getCmp(this.cid + "stanblokady").setValue(this.obiekt.stanblokady);
                                    Ext.getCmp(this.cid + "idplyn").setValue2(this.obiekt.idplyn);
                                    Ext.getCmp(this.cid + "zaladunekopison").setValue2(this.obiekt.zaladunekopison);
                                    if (!Const.OPTIMALFLAG && !Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG) {
                                        if (this.obiekt.trybbateria) {
                                            Ext.getCmp(this.cid + "activeinbateria").setHidden(false);
                                            Ext.getCmp(this.cid + "activeinbateria").setValue2(this.obiekt.activeinbateria);
                                            Ext.getCmp(this.cid + "activeinbateria").setDisabled(this.obiekt.activeinbateria);
                                        }
                                    }
                                    if (this.klonowanie) {
                                        this.obiektid = 0;
                                        this.obiekt = null;
                                    }
                                } else {
                                    if (Const.ENTERPRISEFLAG) {
                                        var grid = Ext.getCmp(this.cid + "firmas_from");
                                        var grid2 = Ext.getCmp(this.cid + "firmas_to");
                                        grid.store.loadData(listFnData0);
                                        grid2.store.loadData([]);
                                    }
                                    Ext.getCmp(this.cid + "magazynoff").setValue2(false);
                                    Ext.getCmp(this.cid + "magazynoff").clearInvalid();
                                    Ext.getCmp(this.cid + "kompensacjaon").setValue2(false);
                                    Ext.getCmp(this.cid + "kompensacjaon").clearInvalid();
                                    Ext.getCmp(this.cid + "gestoscwymagana").setValue2(false);
                                    Ext.getCmp(this.cid + "gestoscwymagana").clearInvalid();
                                    Ext.getCmp(this.cid + "nazwa").setValue(null);
                                    Ext.getCmp(this.cid + "nazwa").clearInvalid();
                                    Ext.getCmp(this.cid + "opis").setValue(null);
                                    Ext.getCmp(this.cid + "opis").clearInvalid();
                                    Ext.getCmp(this.cid + "eiid").setValue(null);
                                    Ext.getCmp(this.cid + "eiid").clearInvalid();
                                    Ext.getCmp(this.cid + "vmintodsp").setValue(0);
                                    Ext.getCmp(this.cid + "vmintodsp").clearInvalid();
                                    Ext.getCmp(this.cid + "pojemnosc").setValue(null);
                                    Ext.getCmp(this.cid + "pojemnosc").clearInvalid();
                                    Ext.getCmp(this.cid + "stanalarmowy").setValue(null);
                                    Ext.getCmp(this.cid + "stanalarmowy").clearInvalid();
                                    Ext.getCmp(this.cid + "stanblokady").setValue(null);
                                    Ext.getCmp(this.cid + "stanblokady").clearInvalid();
                                    Ext.getCmp(this.cid + "idplyn").setValue2(null);
                                    Ext.getCmp(this.cid + "idplyn").clearInvalid();
                                    Ext.getCmp(this.cid + "activeinbateria").setValue2(false);
                                    Ext.getCmp(this.cid + "zaladunekopison").setValue2(false);
                                    Ext.getCmp(this.cid + "zaladunekopison").clearInvalid();
                                }
                            } else {
                                Ext.MessageBox.alert(Lang.error, data.error);
                            }
                        });
                    } else {
                        Ext.MessageBox.alert(Lang.error, data.error);
                    }
                });
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        var canWrite = false;
        var fp1 = Ext.getCmp(this.cid + "formpanel1");
        if (fp1.form.isValid() && Ext.getCmp(this.cid + 'formpanel2').form.isValid()) {
            canWrite = true;
        }
        if (canWrite) {
            var firmas = [];
            if (Const.ENTERPRISEFLAG) {
                var grid = Ext.getCmp(this.cid + "firmas_from");
                var grid2 = Ext.getCmp(this.cid + "firmas_to");
                firmas = grid2.store.collect("id");
            } else {
                for (var i = 0; i < this.listFnData0.length; i++) {
                    firmas.push(this.listFnData0[i].id);
                }
            }
            sendAjaxDataJsonRpcL("rest/zbiorniki/saveObject", {
                id: this.obiektid,
                nazwa: Ext.getCmp(this.cid + "nazwa").getValue(),
                opis: Ext.getCmp(this.cid + "opis").getValue(),
                eiid: Ext.getCmp(this.cid + "eiid").getValue(),
                active: true,
                magazynoff: Ext.getCmp(this.cid + "magazynoff").getValue2(),
                kompensacjaon: Ext.getCmp(this.cid + "kompensacjaon").getValue2(),
                gestoscwymagana: Ext.getCmp(this.cid + "gestoscwymagana").getValue2(),
                zaladunekopison: Ext.getCmp(this.cid + "zaladunekopison").getValue2(),
                vmintodsp: Ext.getCmp(this.cid + "vmintodsp").getValue(),
                pojemnosc: Ext.getCmp(this.cid + "pojemnosc").getValue(),
                stanalarmowy: Ext.getCmp(this.cid + "stanalarmowy").getValue(),
                stanblokady: Ext.getCmp(this.cid + "stanblokady").getValue(),
                idplyn: Ext.getCmp(this.cid + "idplyn").getValue(),
                tankowanieoff: false,
                optlockversion: this.obiekt == null ? null : this.obiekt.optlockversion,
                firmas: firmas,
                activeinbateria: Ext.getCmp(this.cid + "activeinbateria").getValue2()
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    this.obiektid = data.id;
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
                if (this.callbackF)
                    this.callbackF();
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});
