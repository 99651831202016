Ext.define('Ext.ux.window.WindowExdMobile', {
    override: 'Ext.ux.window.WindowExd',
    initComponent: function () {
        this.callParent(arguments);
        if (this.isMobileDevice) {
            this.draggable = false;
            this.modal = false;
        }
    },
    fitWindowHeight: function () {
        this.callParent(arguments);
        if (Ext.os.is.Android) {
            const viewPort = Ext.getCmp('main-viewport');
            if (viewPort.getHeight() > this.getHeight()) {
                viewPort.setMaxHeight(this.getHeight());
            } else {
                viewPort.setMaxHeight(null);
                this.focus();
            }
        }
    },
    close: function () {
        this.callParent(arguments);
        if (Ext.os.is.Android) {
            const viewPort = Ext.getCmp('main-viewport');
            viewPort.setMaxHeight(null);
        }
    }
});