if (Ext.theme.Material) {
    Ext.define('Ext.theme.material.form.field.Text', {
        override: 'Ext.form.field.Text',
        labelSeparator: '',
        initComponent: function () {
            this.callParent();
            this.on("change", function (field, value) {
                var vs = value != null && value !== "";
                if (field.el) {
                    field.el.toggleCls('not-empty', vs || field.emptyText);
                }
            }, this);
            this.on("render", function (ths, width, height, eOpts) {
                var value = ths.getValue();
                var vs = value != null && value !== "";
                if ((vs || ths.emptyText) && ths.el) {
                    ths.el.addCls('not-empty');
                }
            }, this);
        }
    });

    Ext.onReady(function () {
        Ext.getBody().addCls("x-alt-Material");
    });
}