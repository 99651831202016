Ext.define('WindowImportZdarzenFromFile', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.import_zdarzen_z_pliku,
    cls: 'a-WindowImportZdarzenFromFile',
    width: 500,
    padding: 10,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    scrollable: true,
    rachunek: null,
    initComponent: function () {
        this.items = [{
            xtype: 'panel',
            layout: 'fit',
            items: [{
                itemId: 'import-form',
                xtype: 'form',
                defaults: {
                    anchor: '0',
                    labelWidth: 80
                },
                items: [{
                    itemId: 'file-to-import',
                    xtype: 'filefieldexd',
                    name: 'file',
                    allowBlank: false,
                    buttonText: Lang.wybierz_file,
                    msgTarget: 'under'
                }]
            }],
            bbar: ['->', {
                text: Lang.importuj,
                iconCls: 'send-icon',
                iconAlign: 'right',
                listeners: {
                    scope: this,
                    click: this.importuj
                }
            }]
        }];
        this.callParent(arguments);
    },
    showW: function () {
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.close
            }
        });
    },
    close: function () {
        this.callParent(arguments);
    },
    importuj: function () {
        let form = this.down('#import-form');
        if (form.isValid()) {
            this.mask(Lang.ladowanie);
            sendAjaxFormJsonRpcL('rest/importtankfromfile/importZdarzenFromFile', {}, form.getForm(), this, function (data) {
                if (!data.status) {
                    this.unmask();
                    Ext.MessageBox.alert(Lang.error, data.msg);
                } else {
                    this.close();
                    let windowImportFromFileStatus = Ext.create('WindowImportZdarzenFromFileStatus');
                    windowImportFromFileStatus.showW();
                }
            }, 0);
        }
    },
    onProgress: function (czytnik, value) {
        var progressBar = Ext.getCmp(this.cid + "progresspastylka");
        progressBar.updateProgress(value / 100, value + "%", true);
    }
});