Ext.define('WindowCfgProgramFlashDoTask', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.wybor_urzadzen_i_zaprogramowanie.capitalise(),
    width: 550,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    initComponent: function (config) {
        this.cid = Ext.id();
        this.config = config || {};
        Ext.apply(this, config);
        this.items = [{
            id: this.cid + "formpanel",
            xtype: 'form',
            frame: true,
            border: false,
            autoWidth: true,
            autoHeight: true,
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0"
            },
            items: [{
                id: this.cid + "nazwa",
                name: "nazwa",
                xtype: 'textfieldexd',
                fieldLabel: Lang.nazwa,
                allowBlank: true,
                disabled: true,
                cls: "x-item-disabled-sec"
            }, {
                id: this.cid + "flashnoevent",
                name: "flashnoevent",
                xtype: 'checkboxexd',
                boxLabel: Lang.flashowanie_bez_zdarzen
            }, {
                xtype: 'label',
                forId: 'addwarninfo',
                style: 'font: bold 13px arial, tahoma, verdana, sans-serif',
                text: this.addwarninfo
            }, {
                xtype: 'panel',
                height: 200,
                frame: false,
                border: false,
                layout: 'hbox',
                layoutConfig: {
                    align: "stretch"
                },
                items: [{
                    xtype: "grid",
                    height: 200,
                    id: this.cid + "grid",
                    store: new Ext.data.Store({
                        autoLoad: false,
                        proxy: new Ext.data.MemoryProxy(),
                        reader: new Ext.data.JsonReader({
                            id: "id",
                            rootProperty: ''
                        }, [{
                            name: 'nazwa',
                            mapping: 'nazwa'
                        }, {
                            name: 'version',
                            mapping: 'version'
                        }, {
                            name: 'devid',
                            mapping: 'devid'
                        }])
                    }),
                    columns: [{
                        header: Lang.nazwa,
                        flex: 2,
                        sortable: true,
                        dataIndex: 'nazwa'
                    }, {
                        header: Lang.typ,
                        flex: 2,
                        sortable: true,
                        dataIndex: 'devid'
                    }, {
                        header: Lang.wersja,
                        flex: 2,
                        sortable: true,
                        dataIndex: 'version'
                    }],
                    flex: 1,
                    frame: true,
                    border: true,
                    stateId: null,
                    stateful: false,
                    stripeRows: true,
                    enableHdMenu: true,
                    selModel: {
                        selType: 'rowmodel',
                        mode: 'SINGLE'
                    },
                    getSelR: function () {
                        if (!this.getSelectionModel().hasSelection()) {
                            return null;
                        } else {
                            if (this.getSelectionModel().getSelected() != null) {
                                return this.getSelectionModel().getSelected();
                            }
                        }
                        return null;
                    },
                    listeners: {
                        scope: this,
                        rowdblclick: function () {
                            var grid = Ext.getCmp(this.cid + "grid");
                            var grid2 = Ext.getCmp(this.cid + "grid2");
                            var r = grid.getSelR();
                            if (r == null || r.items.length == 0)
                                return;
                            var selectedItem = r.items[0];
                            grid.store.remove(selectedItem);
                            grid2.store.add(selectedItem);
                        }
                    }
                }, {
                    width: 50,
                    xtype: 'panel',
                    frame: false,
                    border: false,
                    layout: 'vbox',
                    layoutConfig: {
                        pack: "center",
                        align: "stretch"
                    },
                    items: [{
                        xtype: 'button',
                        text: ">",
                        width: 50,
                        scope: this,
                        handler: function () {
                            var grid = Ext.getCmp(this.cid + "grid");
                            var grid2 = Ext.getCmp(this.cid + "grid2");
                            var r = grid.getSelR();
                            if (r == null || r.items.length == 0)
                                return;
                            var selectedItem = r.items[0];
                            grid.store.remove(selectedItem);
                            grid2.store.add(selectedItem);
                        }
                    }, {
                        xtype: 'button',
                        text: ">>",
                        width: 50,
                        scope: this,
                        handler: function () {
                            var grid = Ext.getCmp(this.cid + "grid");
                            var grid2 = Ext.getCmp(this.cid + "grid2");
                            grid2.store.add(grid.store.getRange());
                            grid.store.removeAll();
                        }
                    }, {
                        xtype: 'button',
                        text: "<",
                        width: 50,
                        scope: this,
                        handler: function () {
                            var grid = Ext.getCmp(this.cid + "grid");
                            var grid2 = Ext.getCmp(this.cid + "grid2");
                            var r = grid2.getSelR();
                            if (r == null || r.items.length == 0)
                                return;
                            var selectedItem = r.items[0];
                            grid2.store.remove(selectedItem);
                            grid.store.add(selectedItem);
                        }
                    }, {
                        xtype: 'button',
                        text: "<<",
                        width: 50,
                        scope: this,
                        handler: function () {
                            var grid = Ext.getCmp(this.cid + "grid");
                            var grid2 = Ext.getCmp(this.cid + "grid2");
                            grid.store.add(grid2.store.getRange());
                            grid2.store.removeAll();
                        }
                    }]
                }, {
                    xtype: "grid",
                    height: 200,
                    id: this.cid + "grid2",
                    store: new Ext.data.Store({
                        autoLoad: false,
                        proxy: new Ext.data.MemoryProxy(),
                        reader: new Ext.data.JsonReader({
                            id: "id",
                            rootProperty: ''
                        }, [{
                            name: 'nazwa',
                            mapping: 'nazwa'
                        }, {
                            name: 'version',
                            mapping: 'version'
                        }, {
                            name: 'devid',
                            mapping: 'devid'
                        }])
                    }),
                    columns: [{
                        header: Lang.nazwa,
                        flex: 2,
                        sortable: true,
                        dataIndex: 'nazwa'
                    }, {
                        header: Lang.typ,
                        flex: 2,
                        sortable: true,
                        dataIndex: 'devid'
                    }, {
                        header: Lang.wersja,
                        flex: 2,
                        sortable: true,
                        dataIndex: 'version'
                    }],
                    flex: 1,
                    frame: true,
                    border: true,
                    stateId: null,
                    stateful: false,
                    stripeRows: true,
                    enableHdMenu: true,
                    selModel: {
                        selType: 'rowmodel',
                        mode: 'SINGLE'
                    },
                    getSelR: function () {
                        if (!this.getSelectionModel().hasSelection()) {
                            return null;
                        } else {
                            if (this.getSelectionModel().getSelected() != null) {
                                return this.getSelectionModel().getSelected();
                            }
                        }
                        return null;
                    },
                    listeners: {
                        scope: this,
                        rowdblclick: function () {
                            var grid = Ext.getCmp(this.cid + "grid");
                            var grid2 = Ext.getCmp(this.cid + "grid2");
                            var r = grid2.getSelR();
                            if (r == null || r.items.length == 0)
                                return;
                            var selectedItem = r.items[0];
                            grid2.store.remove(selectedItem);
                            grid.store.add(selectedItem);
                        }
                    }
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.id + "save",
            text: Lang.wykonaj,
            handler: this.saveOp,
            scope: this
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid, klonowanie) {
        sendAjaxDataJsonRpcL("rest/flash/getObject", {
            id: this.obiektid
        }, this, function (data) {
            if (data.status) {
                sendAjaxDataJsonRpcL("rest/flash/getDeviceForFlashList", {
                    typeo: this.typeo
                }, this, function (devicesList) {
                    if (devicesList.status) {
                        devicesList = devicesList.data;
                        data = data.data;
                        this.show();
                        this.center();
                        this.setKeyMap({
                            'ESC': {
                                scope: this,
                                handler: this.closeOp
                            }
                        });
                        this.obiekt = data;
                        Ext.getCmp(this.cid + "nazwa").setValue(this.obiekt.nazwa);
                        var grid = Ext.getCmp(this.cid + "grid");
                        var grid2 = Ext.getCmp(this.cid + "grid2");
                        var devicesListList2 = [];
                        for (var ii = 0; ii < devicesList.length; ii++) {
                            var oo = devicesList[ii];
                            var founded = this.obiekt.devids.indexOf(oo.devid) >= 0;
                            if (founded) {
                                devicesListList2.push(oo);
                            }
                        }
                        grid.store.loadData(devicesListList2);
                    } else {
                        Ext.MessageBox.alert(Lang.error, devicesList.msg);
                    }
                });
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        var grid = Ext.getCmp(this.cid + "grid");
        var grid2 = Ext.getCmp(this.cid + "grid2");
        var idsSTs = grid2.store.collect("id");
        sendAjaxDataJsonRpcL("rest/flash/saveObjectDoTask", {
            id: this.obiektid,
            objectids: idsSTs,
            typeo: this.typeo,
            flashnoevent: Ext.getCmp(this.cid + "flashnoevent").getValue()
        }, this, function (data) {
            if (data.status) {
                data = data.data;
                this.close();
                this.fireEvent("wclose");
            } else {
                Ext.MessageBox.alert(Lang.error, data.msg);
            }
        });
    },
    closeOp: function () {
        this.close();
        this.fireEvent("wclose");
    }
});
