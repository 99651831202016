Ext.define('WindowBackupRestore', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.odtworz_kopie_zapasowa.capitalise(),
    width: 350,
    scrollable: true,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    scrollable: true,
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                scrollable: true,
                defaults: {
                    labelWidth: 100
                },
                items: [{
                    id: this.cid + "whoImie",
                    name: "whoImie",
                    xtype: 'displayexd',
                    fieldLabel: Lang.imie
                }, {
                    id: this.cid + "whoNazwisko",
                    name: "whoNazwisko",
                    xtype: 'displayexd',
                    fieldLabel: Lang.nazwisko
                }, {
                    id: this.cid + "name",
                    name: "name",
                    xtype: 'displayexd',
                    fieldLabel: Lang.nazwa
                }, {
                    id: this.cid + "czasOperation",
                    name: "czasOperation",
                    xtype: 'displayexd',
                    fieldLabel: Lang.czas
                }, {
                    id: this.cid + "opis",
                    name: "opis",
                    xtype: 'textareaexd',
                    height: 120,
                    fieldLabel: Lang.opis
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex("access.manage.backup")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        sendAjaxDataJsonRpcL("rest/backup/getBackupRecord", {
            id: this.objectid
        }, this, function (data) {
            if (data.status) {
                data = data.data;
                Ext.getCmp(this.cid + "name").setValue(data.name);
                Ext.getCmp(this.cid + "whoImie").setValue(data.whoImie);
                Ext.getCmp(this.cid + "whoNazwisko").setValue(data.whoNazwisko);
                Ext.getCmp(this.cid + "czasOperation").setValue(data.czasOperation);
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        var canWrite = false;
        var msgw = Lang.dane_niepoprawne;
        if (Ext.getCmp(this.cid + "formpanel").form.isValid()) {
            canWrite = true;
        }
        if (canWrite) {
            Ext.getCmp(this.cid + 'save').setDisabled(true);
            sendAjaxDataJsonRpcL("rest/backup/doRestoreDatabase", {
                id: this.objectid,
                name: Ext.getCmp(this.cid + "name").getValue(),
                opis: Ext.getCmp(this.cid + "opis").getValue()
            }, this, function (data) {
                Ext.getCmp(this.cid + 'save').setDisabled(false);
                if (data.status) {
                    data = data.data;
                    // Ext.TaskManager.start(this.task);
                    window.location.reload();
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        } else {
            Ext.getCmp(this.cid + 'save').setDisabled(false);
            Ext.MessageBox.alert(Lang.error, msgw);
        }
    },
    closeOp: function () {
        this.close();
    }
});
