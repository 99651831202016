Ext.define('WindowExtraCfg', {
    extend: 'Ext.ux.window.WindowExd',
    accessPropertyManageName: "access.manage.syskonf",
    accessPropertyViewName: "access.view.syskonf",
    title: Lang.ustawienia_dodatkowe.capitalise(),
    width: 700,
    closable: false,
    collapsible: false,
    modal: true,
    scrollable: true,
    resizable: false,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            scrollable: WindowExdMobileDEVICEV,
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                scrollable: true,
                defaults: {
                    anchor: '0',
                    labelWidth: 250,
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    xtype: 'radiogroupexd',
                    id: this.cid + "costandloading",
                    name: "costandloading",
                    fieldLabel: Lang.param_cost_and_loadings,
                    columns: 2,
                    vertical: false,
                    hidden: Const.BASICFLAG || Const.STANDARDFLAG,
                    items: [{
                        boxLabel: Lang.tak,
                        inputValue: true
                    }, {
                        boxLabel: Lang.nie,
                        inputValue: false,
                        value: true
                    }]
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "mobileuidisabled",
                    name: "mobileuidisabled",
                    fieldLabel: Lang.param_wylacz_tryb_mobilny,
                    columns: 2,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.tak,
                        inputValue: true
                    }, {
                        boxLabel: Lang.nie,
                        inputValue: false,
                        value: true
                    }]
                }, {
                    id: this.cid + "startmaplocalization",
                    name: "startmaplocalization",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.param_start_map_localization,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    hidden: Const.BASICFLAG || Const.STANDARDFLAG
                }, {
                    id: this.cid + "zaladunekCRZSTimeIntervalMin",
                    name: "zaladunekCRZSTimeIntervalMin",
                    xtype: 'numberfieldexd',
                    minValue: 1,
                    fieldLabel: Lang.param_zaldunek_compute_real_zb_state_interval + "[min]",
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    hidden: Const.BASICFLAG || Const.STANDARDFLAG
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            disabled: !validPrivilegesOperatorComplex(this.accessPropertyManageName),
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function () {
        if (!validPrivilegesOperatorComplex(this.accessPropertyViewName)) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.obiekt = null;
        sendAjaxDataJsonRpcL("rest/systemkonf/readExtraParams", {}, this, function (data) {
            if (data.status) {
                data = data.data;
                this.obiekt = data;
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                Ext.getCmp(this.cid + "costandloading").setValue2(false);
                Ext.getCmp(this.cid + "mobileuidisabled").setValue2(false);
                Ext.getCmp(this.cid + "startmaplocalization").clearInvalid();
                Ext.getCmp(this.cid + "startmaplocalization").setValue(null);
                Ext.getCmp(this.cid + "zaladunekCRZSTimeIntervalMin").clearInvalid();
                Ext.getCmp(this.cid + "zaladunekCRZSTimeIntervalMin").setValue(null);
                if (this.obiekt != null) {
                    Ext.getCmp(this.cid + "costandloading").setValue2(this.obiekt.enableCostsAndLoadingsProcessing);
                    Ext.getCmp(this.cid + "mobileuidisabled").setValue2(this.obiekt.mobileUiDisabled);
                    Ext.getCmp(this.cid + "startmaplocalization").setValue(this.obiekt.startMapLocalization);
                    Ext.getCmp(this.cid + "zaladunekCRZSTimeIntervalMin").setValue(this.obiekt.zaladunekComputeRealZbiornikStateTimeIntervalMin);
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        if (!validPrivilegesOperatorComplex(this.accessPropertyManageName)) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        var canWrite = false;
        if (Ext.getCmp(this.cid + 'formpanel').isValid()) {
            canWrite = true;
        }
        if (canWrite) {
            sendAjaxDataJsonRpcL("rest/systemkonf/saveExtraParams", {
                enableCostsAndLoadingsProcessing: Ext.getCmp(this.cid + "costandloading").getValue2(),
                mobileUiDisabled: Ext.getCmp(this.cid + "mobileuidisabled").getValue2(),
                startMapLocalization: Ext.getCmp(this.cid + "startmaplocalization").getValue2(),
                zaladunekComputeRealZbiornikStateTimeIntervalMin: Ext.getCmp(this.cid + "zaladunekCRZSTimeIntervalMin").getValue2()
            }, this, function (data) {
                if (data.status) {
                    Ext.MessageBox.alert(Lang.ok, Lang.zmiany_wejda_w_zucie_po_restarcie);
                    this.hide();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                    return;
                }
                if (this.callbackF) {
                    this.callbackF();
                }
            }, 0);
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});
