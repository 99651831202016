Ext.define('WindowZbiornikiHMap', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.mapka.capitalise(),
    width: 600,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    statics: {
        visalElemModCls: function (object) {
            if (object.stanzbiornikareal && object.stanzbiornikareal != object.stanzbiornika) {
                if (object.stanzbiornikareal < object.stanblokady)
                    return "low";
                if (object.stanzbiornikareal < object.stanalarmowy)
                    return "medium";
                return "high";
            } else {
                if (object.stanzbiornika < object.stanblokady)
                    return "low";
                if (object.stanzbiornika < object.stanalarmowy)
                    return "medium";
                return "high";
            }
        }
    },
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            width: 600,
            height: 700,
            border: false,
            frame: false,
            layout: 'border',
            items: [{
                region: 'north',
                id: this.id + "form1",
                xtype: "form",
                border: true,
                frame: true,
                height: 40,
                defaults: {
                    anchor: "0"
                },
                items: [{
                    xtype: 'panel',
                    layout: 'hbox',
                    fieldLabel: Lang.szukaj,
                    isFormField: false,
                    border: false,
                    frame: false,
                    items: [{
                        flex: 1,
                        id: this.id + "fszukaj",
                        name: "fszukaj",
                        xtype: 'textfield',
                        margin: "0 5 0 0",
                        isFormField: true,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        xtype: 'button',
                        width: 80,
                        id: this.id + "bszukaj",
                        text: ">>",
                        handler: this.searchOp,
                        scope: this
                    }]
                }]
            }, {
                region: 'center',
                id: this.id + 'panelcenter',
                xtype: 'panel',
                layout: 'fit',
                border: false,
                frame: false,
                items: [{
                    xtype: 'box',
                    id: this.id + "boxImage",
                    autoEl: {
                        tag: 'div',
                        cls: "position:relative"
                    }
                }]
            }, {
                region: 'east',
                id: this.id + "panelzbiornikigird",
                xtype: "panel",
                layout: 'fit',
                border: true,
                frame: true,
                width: 150,
                defaults: {
                    anchor: "0"
                },
                items: [new Ext.ux.grid.GridPanelExd({
                    store: new Ext.data.Store({
                        autoLoad: false,
                        proxy: new Ext.data.MemoryProxy({}),
                        reader: new Ext.data.JsonReader({}, [{
                            name: 'id',
                            mapping: 'id'
                        }, {
                            name: 'nazwa',
                            mapping: 'nazwa'
                        }, {
                            name: 'renderstate',
                            mapping: 'renderstate'
                        }, {
                            name: 'object',
                            mapping: "object",
                            type: "auto"
                        }])
                    }),
                    columns: [{
                        header: Lang.nazwa,
                        width: 150,
                        sortable: false,
                        dataIndex: 'nazwa',
                        renderer: this.gridRendererCol
                    }],
                    frame: false,
                    border: false,
                    id: this.id + "zbiornikigird",
                    stateId: null,
                    stateful: false,
                    stripeRows: true,
                    enableHdMenu: false,
                    selModel: {
                        selType: 'rowmodel',
                        mode: 'single'
                    },
                    listeners: {
                        scope: this,
                        rowdblclick: this.rowDbClickGrid,
                        rowclick: this.rowClickGrid
                    },
                    getSelectedRecord: function () {
                        if (!this.getSelectionModel().hasSelection()) {
                            return null;
                        } else {
                            if (this.getSelectionModel().getSelected() != null) {
                                return this.getSelectionModel().getSelected();
                            }
                        }
                        return null;
                    },
                    selectRecord: function (r) {
                        this.getSelectionModel().selectRecords([r], false);
                    },
                    clearSelecton: function () {
                        this.getSelectionModel().clearSelections();
                    }
                })]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.closeSaveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    createMapIcons: function () {
        this.mapUnselectedLowIcon = new H.map.Icon('<svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" height="29.801325" width="16.709005" id="svg12" version="1.1" viewBox="0 0 16.709005 29.801325" enable-background="new 0 0 56.068 100" style="cursor: pointer"> <metadata id="metadata18"> <rdf:RDF> <cc:Work rdf:about=""> <dc:format>image/svg+xml</dc:format> <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" /> <dc:title></dc:title> </cc:Work> </rdf:RDF> </metadata> <defs id="defs16" /> <path style="fill: #A93737; stroke-width: 0.29801324" id="path10" d="M 8.354503,0 C 3.740662,0 0,3.740662 0,8.354503 c 0,4.613841 8.354503,21.446821 8.354503,21.446821 0,0 8.354503,-16.832682 8.354503,-21.446821 C 16.709006,3.740364 12.968642,0 8.354503,0 Z m 0,12.062682 c -2.047649,0 -3.707881,-1.66053 -3.707881,-3.707881 0,-2.047351 1.660232,-3.707881 3.707881,-3.707881 2.047649,0 3.707881,1.659934 3.707881,3.707881 0,2.047947 -1.659934,3.707881 -3.707881,3.707881 z" /> </svg>');
        this.mapUnselectedMediumIcon = new H.map.Icon('<svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" height="29.801325" width="16.709005" id="svg12" version="1.1" viewBox="0 0 16.709005 29.801325" enable-background="new 0 0 56.068 100" style="cursor: pointer"> <metadata id="metadata18"> <rdf:RDF> <cc:Work rdf:about=""> <dc:format>image/svg+xml</dc:format> <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" /> <dc:title></dc:title> </cc:Work> </rdf:RDF> </metadata> <defs id="defs16" /> <path style="fill: #936726; stroke-width: 0.29801324" id="path10" d="M 8.354503,0 C 3.740662,0 0,3.740662 0,8.354503 c 0,4.613841 8.354503,21.446821 8.354503,21.446821 0,0 8.354503,-16.832682 8.354503,-21.446821 C 16.709006,3.740364 12.968642,0 8.354503,0 Z m 0,12.062682 c -2.047649,0 -3.707881,-1.66053 -3.707881,-3.707881 0,-2.047351 1.660232,-3.707881 3.707881,-3.707881 2.047649,0 3.707881,1.659934 3.707881,3.707881 0,2.047947 -1.659934,3.707881 -3.707881,3.707881 z" /> </svg>');
        this.mapUnselectedHighIcon = new H.map.Icon('<svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" height="29.801325" width="16.709005" id="svg12" version="1.1" viewBox="0 0 16.709005 29.801325" enable-background="new 0 0 56.068 100" style="cursor: pointer"> <metadata id="metadata18"> <rdf:RDF> <cc:Work rdf:about=""> <dc:format>image/svg+xml</dc:format> <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" /> <dc:title></dc:title> </cc:Work> </rdf:RDF> </metadata> <defs id="defs16" /> <path style="fill: #09884E; stroke-width: 0.29801324" id="path10" d="M 8.354503,0 C 3.740662,0 0,3.740662 0,8.354503 c 0,4.613841 8.354503,21.446821 8.354503,21.446821 0,0 8.354503,-16.832682 8.354503,-21.446821 C 16.709006,3.740364 12.968642,0 8.354503,0 Z m 0,12.062682 c -2.047649,0 -3.707881,-1.66053 -3.707881,-3.707881 0,-2.047351 1.660232,-3.707881 3.707881,-3.707881 2.047649,0 3.707881,1.659934 3.707881,3.707881 0,2.047947 -1.659934,3.707881 -3.707881,3.707881 z" /> </svg>');
        this.mapSelectedLowIcon = new H.map.Icon('<svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" height="29.801325" width="16.709005" id="svg12" version="1.1" viewBox="0 0 16.709005 29.801325" enable-background="new 0 0 56.068 100" style="cursor: pointer"> <metadata id="metadata18"> <rdf:RDF> <cc:Work rdf:about=""> <dc:format>image/svg+xml</dc:format> <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" /> <dc:title></dc:title> </cc:Work> </rdf:RDF> </metadata> <defs id="defs16" /> <path style="fill: #C13F3F; stroke-width: 0.29801324" id="path10" d="M 8.354503,0 C 3.740662,0 0,3.740662 0,8.354503 c 0,4.613841 8.354503,21.446821 8.354503,21.446821 0,0 8.354503,-16.832682 8.354503,-21.446821 C 16.709006,3.740364 12.968642,0 8.354503,0 Z m 0,12.062682 c -2.047649,0 -3.707881,-1.66053 -3.707881,-3.707881 0,-2.047351 1.660232,-3.707881 3.707881,-3.707881 2.047649,0 3.707881,1.659934 3.707881,3.707881 0,2.047947 -1.659934,3.707881 -3.707881,3.707881 z" /> </svg>');
        this.mapSelectedMediumIcon = new H.map.Icon('<svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" height="29.801325" width="16.709005" id="svg12" version="1.1" viewBox="0 0 16.709005 29.801325" enable-background="new 0 0 56.068 100" style="cursor: pointer"> <metadata id="metadata18"> <rdf:RDF> <cc:Work rdf:about=""> <dc:format>image/svg+xml</dc:format> <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" /> <dc:title></dc:title> </cc:Work> </rdf:RDF> </metadata> <defs id="defs16" /> <path style="fill: #AA762C; stroke-width: 0.29801324" id="path10" d="M 8.354503,0 C 3.740662,0 0,3.740662 0,8.354503 c 0,4.613841 8.354503,21.446821 8.354503,21.446821 0,0 8.354503,-16.832682 8.354503,-21.446821 C 16.709006,3.740364 12.968642,0 8.354503,0 Z m 0,12.062682 c -2.047649,0 -3.707881,-1.66053 -3.707881,-3.707881 0,-2.047351 1.660232,-3.707881 3.707881,-3.707881 2.047649,0 3.707881,1.659934 3.707881,3.707881 0,2.047947 -1.659934,3.707881 -3.707881,3.707881 z" /> </svg>');
        this.mapSelectedHighIcon = new H.map.Icon('<svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" height="29.801325" width="16.709005" id="svg12" version="1.1" viewBox="0 0 16.709005 29.801325" enable-background="new 0 0 56.068 100" style="cursor: pointer"> <metadata id="metadata18"> <rdf:RDF> <cc:Work rdf:about=""> <dc:format>image/svg+xml</dc:format> <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" /> <dc:title></dc:title> </cc:Work> </rdf:RDF> </metadata> <defs id="defs16" /> <path style="fill: #0BA05A; stroke-width: 0.29801324" id="path10" d="M 8.354503,0 C 3.740662,0 0,3.740662 0,8.354503 c 0,4.613841 8.354503,21.446821 8.354503,21.446821 0,0 8.354503,-16.832682 8.354503,-21.446821 C 16.709006,3.740364 12.968642,0 8.354503,0 Z m 0,12.062682 c -2.047649,0 -3.707881,-1.66053 -3.707881,-3.707881 0,-2.047351 1.660232,-3.707881 3.707881,-3.707881 2.047649,0 3.707881,1.659934 3.707881,3.707881 0,2.047947 -1.659934,3.707881 -3.707881,3.707881 z" /> </svg>');
    },
    getIconZbiornikState: function (mode, cobject) {
        var icon = null;
        if (mode == "u") {
            var statem = WindowZbiornikiHMap.visalElemModCls(cobject);
            if (statem == "low") {
                icon = this.mapUnselectedLowIcon;
            } else if (statem == "medium") {
                icon = this.mapUnselectedMediumIcon;
            } else if (statem == "high") {
                icon = this.mapUnselectedHighIcon;
            }
        } else if (mode == "s") {
            var statem = WindowZbiornikiHMap.visalElemModCls(cobject);
            if (statem == "low") {
                icon = this.mapSelectedLowIcon;
            } else if (statem == "medium") {
                icon = this.mapSelectedMediumIcon;
            } else if (statem == "high") {
                icon = this.mapSelectedHighIcon;
            }
        }
        return icon;
    },
    initializedHereMap: function () {
        this.geocoder = AppMapPlatform.getGeocodingService();
        this.resolverAddrToPoint(this.fszukaj.getValue(), function (point) {
            var myOptions = {
                zoom: 8,
                center: point
            };
            var defaultLayers = AppMapPlatform.createDefaultLayers();
            this.map = new H.Map(this.boxImage.el.dom, defaultLayers.normal.map, myOptions);
            var mapEvents = new H.mapevents.MapEvents(this.map);
            var behavior = new H.mapevents.Behavior(mapEvents);
            this.mapUi = H.ui.UI.createDefault(this.map, defaultLayers);
            this.initializeHereMapUI();
            this.map.addEventListener('pointermove', function (event) {
                if (event.target instanceof H.map.Marker) {
                    this.map.getViewPort().element.style.cursor = 'pointer';
                } else {
                    this.map.getViewPort().element.style.cursor = 'auto';
                }
            }, false, this);
            this.createMapIcons();
            this.loadXYElemsPos();
            this.map.addEventListener('contextmenu', this.mapClickRightEvent, true, this);
        }, function (results, status) {
            Ext.MessageBox.alert(Lang.error, 'Code:' + status);
            this.close();
        }, this);
    },
    initializeHereMapUI: function () {
        this.mapUi.getControl('mapsettings').setDisabled(true);
        this.mapUi.getControl('mapsettings').setVisibility(false);
        this.mapUi.getControl('zoom').setAlignment('top-left');
        this.mapUi.getControl('scalebar').setVisibility(false);
        this.mapUi.getControl('scalebar').setDisabled(true);
    },
    gridRendererCol: function (value, metaData, record, rowIndex, colIndex, store) {
        var rs = record.get("renderstate");
        if (rs == null) {
            metaData.style = metaData.style + "color:red;";
        } else if (rs == "R") {
            metaData.style = metaData.style + "color:green;";
        }
        return value;
    },
    loadXYElemsPos: function () {
        var str = Ext.getCmp(this.id + "zbiornikigird").store;
        for (var i = 0; i < str.getCount(); i++) {
            var r = str.getAt(i);
            var currentRow = r;
            var currentObject = r.get("object");
            var dx = currentObject.x;
            var dy = currentObject.y;
            if (dx > 0 && dy > 0) {
                currentRow.set("renderstate", "R");
                currentObject["renderstate"] = "R";
                currentObject.marker = this.createMarker({
                    lat: dx,
                    lng: dy
                }, currentObject.nazwa, currentRow, currentObject);
                this.map.addObject(currentObject.marker);
                currentObject.marker.addEventListener('tap', this.markecrSelectClick, false, this);
            }
        }
    },
    resolverAddrToPoint: function (addr, functionCallBackOk, functionCallBackFail, scope) {
        var fresolvercallback = function (result, functionCallBackOk, scope) {
            if (result && result.Response.View.length > 0) {
                var location = result.Response.View[0].Result[0];
                functionCallBackOk.call(scope, {
                    lat: location.Location.DisplayPosition.Latitude,
                    lng: location.Location.DisplayPosition.Longitude
                });
            } else {
                Ext.MessageBox.alert(Lang.error);
            }
        };
        this.geocoder.geocode({
            'searchText': addr,
            maxresults: 1
        }, Ext.Function.bind(fresolvercallback, null, [functionCallBackOk, scope], true), function (e) {
            Ext.MessageBox.show(Lang.error, e);
        });
    },
    mapClickRightEvent: function (mousee) {
        this.taskContextMenu = new Ext.menu.Menu();
        this.taskContextMenu.add([{
            iconAlign: 'left',
            iconCls: "iconEdit",
            text: Lang.ustaw_tutaj.capitalise(),
            handler: Ext.Function.bind(this.moveMarkerNewClick, this, [mousee.target.screenToGeo(mousee.viewportX, mousee.viewportY)], false),
            scope: this
        }]);
        this.taskContextMenu.showAt([mousee.originalEvent.x, mousee.originalEvent.y]);
    },
    moveMarkerNewClick: function (latLng) {
        if (this.currentObject != null) {
            if (this.currentObject.marker == null) {
                this.currentObject.marker = this.createMarker(latLng, this.currentObject.nazwa, this.currentRow, this.currentObject);
                this.currentRow.set("renderstate", "R");
                this.currentObject["renderstate"] = "R";
                this.map.addObject(this.currentObject.marker);
                this.currentObject.marker.addEventListener('tap', this.markecrSelectClick, false, this);
            } else {
                this.currentObject.marker.setPosition(latLng);
            }
        }
    },
    createMarker: function (latLng, title, row, object) {
        var marker = new H.map.Marker(latLng, {
            icon: this.getIconZbiornikState("u", object)
        });
        marker.setData({
            title: title,
            row: row,
            object: object
        });
        return marker;
    },
    markecrSelectClick: function (event) {
        if (this.currentObject != null && this.currentObject.marker != null)
            this.currentObject.marker.setIcon(this.getIconZbiornikState("u", this.currentObject));
        var bubble = new H.ui.InfoBubble(event.target.getPosition(), {
            content: event.target.getData().title
        });
        this.mapUi.addBubble(bubble);
        this.currentRow = event.target.getData().row;
        this.currentObject = event.target.getData().object;
        this.cgrid.getSelectionModel().select([this.currentRow]);
        if (this.currentObject != null && this.currentObject.marker != null)
            this.currentObject.marker.setIcon(this.getIconZbiornikState("s", this.currentObject));
    },
    rowClickGrid: function () {
        var selectedRow = this.cgrid.getSelection()[0];
        if (selectedRow == null) {
            if (this.currentObject != null && this.currentObject.marker != null)
                this.currentObject.marker.setIcon(this.getIconZbiornikState("u", this.currentObject));
            this.currentRow = null;
            this.currentObject = null;
        } else {
            if (this.currentObject != null && this.currentObject.marker != null)
                this.currentObject.marker.setIcon(this.getIconZbiornikState("u", this.currentObject));
            this.currentRow = selectedRow;
            this.currentObject = selectedRow.get("object");
            if (this.currentObject != null && this.currentObject.marker != null)
                this.currentObject.marker.setIcon(this.getIconZbiornikState("s", this.currentObject));
        }
    },
    rowDbClickGrid: function () {
        var selectedRow = this.cgrid.getSelection()[0];
        if (selectedRow == null) {
            this.currentRow = null;
            this.currentObject = null;
        } else {
            this.currentRow = selectedRow;
            this.currentObject = selectedRow.get("object");
            if (this.currentObject.marker != null)
                this.map.setCenter(this.currentObject.marker.getPosition());
        }
    },
    showW: function () {
        sendAjaxDataJsonRpcL("rest/zbiornikigmap/getZbiornikListForGMapState", null, this, function (data) {
            if (data.status) {
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                this.zbiornikidatas = data.data;
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                this.fszukaj = Ext.getCmp(this.id + "fszukaj");
                this.fszukaj.setValue(Const.STARTMAPLOCALIZATION);
                this.boxImage = Ext.getCmp(this.id + "boxImage");
                for (var i = 0; i < this.zbiornikidatas.length; i++) {
                    this.zbiornikidatas[i]["object"] = this.zbiornikidatas[i];
                    this.zbiornikidatas[i]["renderstate"] = null;
                }
                this.cgrid = Ext.getCmp(this.id + "zbiornikigird");
                this.cgrid.store.loadData(this.zbiornikidatas);
                this.currentRow = null;
                this.currentObject = null;
                loadHereMapLoader(this.initializedHereMap, this);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    searchOp: function () {
        this.resolverAddrToPoint(this.fszukaj.getValue(), function (point) {
            this.map.setCenter(point);
        }, function (results, status) {
            Ext.MessageBox.alert(Lang.error, 'Code:' + status);
        }, this);
    },
    closeSaveOp: function () {
        var canWrite = true;
        if (canWrite) {
            var mapidx = {};
            var mapidy = {};
            for (var i = 0; i < this.zbiornikidatas.length; i++) {
                var o = this.zbiornikidatas[i];
                if (o.marker != null) {
                    var mp = o.marker.getPosition();
                    mapidx[o.id] = mp.lat;
                    mapidy[o.id] = mp.lng;
                } else {
                    mapidx[o.id] = 0;
                    mapidy[o.id] = 0;
                }
            }
            sendAjaxDataJsonRpcL("rest/zbiornikigmap/saveGMapState", {
                mapidgmapx: mapidx,
                mapidgmapy: mapidy
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    this.fireEvent("closeok", this);
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});

