Ext.define('WindowLimitEditAdd', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.limit.capitalise(),
    width: 600,
    closable: false,
    collapsible: false,
    modal: true,
    scrollable: true,
    resizable: false,
    layout: 'fit',
    changeSelectrozszerzenie_wewnetrzne: function () {
        Ext.getCmp(this.cid + "adres").setDisabled(Ext.getCmp(this.cid + "rozszerzenie_wewnetrzne").getValue2());
    },
    initComponent: function () {
        this.monthszeroStore = [];
        for (var i = 0; i < 12; i++) {
            this.monthszeroStore.push([i, LangComp.months[i]]);
        }
        this.daymonthszeroStore = [];
        for (var i = 0; i < 31; i++) {
            this.daymonthszeroStore.push([i + 1, i + 1]);
        }
        this.daymonthszeroStore.push([Const.TIME_DAY_MONTH_LAST, Lang.ostatni_dzien_miesiaca]);
        this.cid = Ext.id();
        this.items = [{
            xtype: 'tabpanel',
            autoWidth: true,
            height: 420,
            border: false,
            activeTab: 0,
            items: [{
                title: Lang.ogolne.capitalise(),
                id: this.cid + "formpanel1",
                xtype: "form",
                frame: false,
                border: false,
                autoWidth: true,
                autoHeight: true,
                scrollable: true,
                bodyStyle: 'padding:10px 10px 10px 10px',
                labelWidth: 100,
                defaults: {
                    anchor: "0",
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    id: this.cid + 'limitnotice',
                    xtype: 'displayfield',
                    value: Lang.limity_wylacznie_online,
                    fieldStyle: 'color: red; text-align: center;',
                    hidden: !Const.STANDARDFLAG
                }, {
                    id: this.cid + 'idplyn',
                    name: "idplyn",
                    xtype: 'comboexd',
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    valueField: 'id',
                    displayField: 'nazwa',
                    store: new Ext.data.JsonStore({
                        id: "id",
                        root: '',
                        fields: [{
                            name: 'id',
                            mapping: 'id'
                        }, {
                            name: 'nazwa',
                            mapping: 'nazwa'
                        }]
                    }),
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    fieldLabel: Lang.plyn
                }, {
                    id: this.cid + "wartosc",
                    name: "wartosc",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.wartosc + Lang.jednostka_text,
                    allowBlank: false,
                    decimalPrecision: 3,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 0,
                    maxValue: 9999999
                }, {
                    name: "typ",
                    id: this.cid + 'typ',
                    xtype: 'comboexd',
                    store: [[Const.LIMIT_TYPE_NIECYKLICZNY, Lang.nieodnawialny], [Const.LIMIT_TYPE_DZIENNY, Lang.dzienny], [Const.LIMIT_TYPE_TYGODNIOWY, Lang.tygodniowy], [Const.LIMIT_TYPE_MIESIECZNY, Lang.miesieczny], [Const.LIMIT_TYPE_ROCZNY, Lang.roczny]],
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    fieldLabel: Lang.typ_limitu,
                    listeners: {
                        select: this.monitorSSDD,
                        scope: this
                    }
                }, {
                    id: this.cid + "hourszero",
                    name: "hourszero",
                    xtype: 'datefieldexdmod',
                    fieldLabel: Lang.godzina,
                    format: "H:i:s",
                    allowBlank: false,
                    hideTrigger: true,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    name: "weekdayszero",
                    id: this.cid + 'weekdayszero',
                    xtype: 'comboexd',
                    store: [[Const.TIME_WEEK_DAY_NIEDZIELA, Lang.niedziela], [Const.TIME_WEEK_DAY_PONIEDZIALEK, Lang.poniedzialek], [Const.TIME_WEEK_DAY_WTOREK, Lang.wtorek], [Const.TIME_WEEK_DAY_SRODA, Lang.sroda], [Const.TIME_WEEK_DAY_CZWARTEK, Lang.czwartek], [Const.TIME_WEEK_DAY_PIATEK, Lang.piatek], [Const.TIME_WEEK_DAY_SOBOTA, Lang.sobota]],
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    fieldLabel: Lang.dzien_tygodnia
                }, {
                    name: "daymonthszero",
                    id: this.cid + 'daymonthszero',
                    xtype: 'comboexd',
                    store: this.daymonthszeroStore,
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    fieldLabel: Lang.dzien_miesiaca
                }, {
                    name: "monthszero",
                    id: this.cid + 'monthszero',
                    xtype: 'comboexd',
                    store: this.monthszeroStore,
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    fieldLabel: Lang.miesiac
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.closeSaveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    monitorSSDD: function () {
        Ext.getCmp(this.cid + "wartosc").setDisabled(false);
        Ext.getCmp(this.cid + "hourszero").setDisabled(false);
        Ext.getCmp(this.cid + "typ").setDisabled(false);
        Ext.getCmp(this.cid + "idplyn").setDisabled(false);
        if (Ext.getCmp(this.cid + 'typ').getValue() == Const.LIMIT_TYPE_NIECYKLICZNY) {
            Ext.getCmp(this.cid + "hourszero").setDisabled(true);
            Ext.getCmp(this.cid + 'weekdayszero').setDisabled(true);
            Ext.getCmp(this.cid + 'daymonthszero').setDisabled(true);
            Ext.getCmp(this.cid + 'monthszero').setDisabled(true);
        }
        if (Ext.getCmp(this.cid + 'typ').getValue() == Const.LIMIT_TYPE_DZIENNY) {
            Ext.getCmp(this.cid + "hourszero").setDisabled(false);
            Ext.getCmp(this.cid + 'weekdayszero').setDisabled(true);
            Ext.getCmp(this.cid + 'daymonthszero').setDisabled(true);
            Ext.getCmp(this.cid + 'monthszero').setDisabled(true);
        }
        if (Ext.getCmp(this.cid + 'typ').getValue() == Const.LIMIT_TYPE_TYGODNIOWY) {
            Ext.getCmp(this.cid + "hourszero").setDisabled(false);
            Ext.getCmp(this.cid + 'weekdayszero').setDisabled(false);
            Ext.getCmp(this.cid + 'daymonthszero').setDisabled(true);
            Ext.getCmp(this.cid + 'monthszero').setDisabled(true);
        }
        if (Ext.getCmp(this.cid + 'typ').getValue() == Const.LIMIT_TYPE_MIESIECZNY) {
            Ext.getCmp(this.cid + "hourszero").setDisabled(false);
            Ext.getCmp(this.cid + 'weekdayszero').setDisabled(true);
            Ext.getCmp(this.cid + 'daymonthszero').setDisabled(false);
            Ext.getCmp(this.cid + 'monthszero').setDisabled(true);
        }
        if (Ext.getCmp(this.cid + 'typ').getValue() == Const.LIMIT_TYPE_ROCZNY) {
            Ext.getCmp(this.cid + "hourszero").setDisabled(false);
            Ext.getCmp(this.cid + 'weekdayszero').setDisabled(true);
            Ext.getCmp(this.cid + 'daymonthszero').setDisabled(false);
            Ext.getCmp(this.cid + 'monthszero').setDisabled(false);
        }
    },
    showW: function () {
        if (Const.STANDARDFLAG) {
            var store = Ext.getCmp(this.cid + "typ").getStore();
            store.removeAt(0);
            store.removeAt(store.getCount() - 1);
        }
        sendAjaxDataJsonRpcL("rest/plyny/getShortList", {
            idinclude: this.obiekt != null ? this.obiekt.idplyn : 0
        }, this, function (data) {
            if (data.status) {
                var listFnData = data.data;
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                Ext.getCmp(this.cid + "idplyn").store.loadData(listFnData);
                this.obiekt = this.obiekt || {
                    id: 0,
                    wartosc: 0,
                    hourszero: Ext.Date.parse("2000-01-01 00:00:00", Date.patterns.ISO8601Long),
                    typ: Const.LIMIT_TYPE_DZIENNY,
                    weekdayszero: Const.TIME_WEEK_DAY_NIEDZIELA,
                    daymonthszero: 1,
                    monthszero: Const.TIME_MONTH_STYCZEN,
                    idplyn: null
                };
                if (this.obiekt.hourszero == null) {
                    this.obiekt.hourszero = Ext.Date.parse("2000-01-01 00:00:00", Date.patterns.ISO8601Long);
                }
                if (this.obiekt.weekdayszero == null) {
                    this.obiekt.weekdayszero = Const.TIME_WEEK_DAY_NIEDZIELA;
                }
                if (this.obiekt.daymonthszero == null) {
                    this.obiekt.daymonthszero = 1;
                }
                if (this.obiekt.monthszero == null) {
                    this.obiekt.monthszero = Const.TIME_MONTH_STYCZEN;
                }
                Ext.getCmp(this.cid + "idplyn").setValue2(this.obiekt.idplyn);
                Ext.getCmp(this.cid + "idplyn").clearInvalid();
                Ext.getCmp(this.cid + "wartosc").setValue(this.obiekt.wartosc);
                Ext.getCmp(this.cid + "wartosc").clearInvalid();
                Ext.getCmp(this.cid + "hourszero").setValue(this.obiekt.hourszero);
                Ext.getCmp(this.cid + "hourszero").clearInvalid();
                Ext.getCmp(this.cid + "typ").setValue2(this.obiekt.typ);
                Ext.getCmp(this.cid + "typ").clearInvalid();
                Ext.getCmp(this.cid + "weekdayszero").setValue2(this.obiekt.weekdayszero);
                Ext.getCmp(this.cid + "weekdayszero").clearInvalid();
                Ext.getCmp(this.cid + "daymonthszero").setValue2(this.obiekt.daymonthszero);
                Ext.getCmp(this.cid + "daymonthszero").clearInvalid();
                Ext.getCmp(this.cid + "monthszero").setValue2(this.obiekt.monthszero);
                Ext.getCmp(this.cid + "monthszero").clearInvalid();
                this.monitorSSDD();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    closeSaveOp: function () {
        var canWrite = false;
        var formvalid = true;
        formvalid &= Ext.getCmp(this.cid + "wartosc").isValid();
        formvalid &= Ext.getCmp(this.cid + "hourszero").isValid();
        formvalid &= Ext.getCmp(this.cid + "typ").isValid();
        formvalid &= Ext.getCmp(this.cid + "idplyn").isValid();
        formvalid &= Ext.getCmp(this.cid + "weekdayszero").isValid();
        formvalid &= Ext.getCmp(this.cid + "daymonthszero").isValid();
        formvalid &= Ext.getCmp(this.cid + "monthszero").isValid();
        if (formvalid) {
            canWrite = true;
        }
        if (canWrite) {
            Ext.apply(this.obiekt, {
                idplyn: Ext.getCmp(this.cid + "idplyn").getValue2(),
                plyn: Ext.getCmp(this.cid + "idplyn").getRow().get("nazwa"),
                wartosc: Ext.getCmp(this.cid + "wartosc").getValue(),
                hourszero: Ext.getCmp(this.cid + "hourszero").getValue2(),
                typ: Ext.getCmp(this.cid + "typ").getValue2(),
                weekdayszero: Ext.getCmp(this.cid + "weekdayszero").getValue2(),
                daymonthszero: Ext.getCmp(this.cid + "daymonthszero").getValue2(),
                monthszero: Ext.getCmp(this.cid + "monthszero").getValue2()
            });
            this.fireEvent("closeok");
            this.closeOp();
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});

