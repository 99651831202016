Ext.define('RPivot', {
    id: Ext.id(),
    init: function (config, container) {
        this.initialConfig = config;
        Ext.apply(this, config);
        this.container = container;
        this.container.add({
            id: this.id + "main",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: {
                type: 'vbox',
                align: 'stretch'
            },
            items: [{
                id: this.id + "rpivotctrla",
                xtype: 'panel',
                layout: {
                    type: 'hbox',
                    align: 'stretch'
                },
                frame: false,
                border: false,
                height: '55%',
                items: [{
                    flex: 1,
                    id: this.id + "chart",
                    xtype: 'panel',
                    frame: false,
                    border: false,
                    layout: 'fit',
                    html: ""
                }, {
                    width: 200,
                    id: this.id + "legend",
                    xtype: 'panel',
                    frame: false,
                    border: false,
                    layout: 'fit',
                    items: {
                        xtype: 'dataview',
                        id: this.id + "legendview",
                        multiSelect: false,
                        singleSelect: false,
                        scrollable: true,
                        store: new Ext.data.JsonStore({
                            fields: [{
                                name: 'color',
                                mapping: 'color'
                            }, {
                                name: 'text',
                                mapping: 'text'
                            }]
                        }),
                        tpl: '<tpl for="."><div class="x-legend-list-item"><div class="x-legend-list-item-color" style="background-color:{color}">&nbsp;</div><div class="x-legend-list-item-text">{text}</div></div></tpl>',
                        itemSelector: ".x-legend-list-item",
                        selectedItemCls: "x-legend-list-item-selected"
                    }
                }]
            }, {
                flex: 1,
                height: '45%',
                bodyStyle: "padding-top:5px",
                id: this.id + "ctrl",
                xtype: 'panel',
                frame: false,
                border: false,
                scrollable: true,
                layout: 'fit',
                html: ""
            }]
        });
        this.cubeCtrl = Ext.getCmp(this.id + "ctrl");
        this.chartCtrl = Ext.getCmp(this.id + "chart");
        this.legendCtrl = Ext.getCmp(this.id + "legend");
        this.legendviewCtrl = Ext.getCmp(this.id + "legendview");
        this.container.on("resize", this.updateReszie, this);
    },
    show: function (rows, cols, values, data) {
        this.data = data;
        this.colsMeta = cols;
        this.rowsMeta = rows;
        this.valuesMeta = values;
        this.cubeGroupCols = data.col;
        this.cubeGroupRows = data.row;
        this.gridcube = data.cube;
        this.buildTableHtml();
    },
    beforeGenerate: function () {
        this.cubeCtrl.removeAll(true);
        this.cubeCtrl.body.update("");
        this.chartCtrl.removeAll(true);
        this.chartCtrl.body.update("");
        this.legendviewCtrl.store.removeAll();
    },
    retriveVEX: function () {
        var viewColsFlat = [];
        var viewRowsFlat = [];
        var expandedColsFlat = [];
        var expandedRowsFlat = [];
        var walkGroupTreeMakeflat = function (group, faltV, flatE) {
            if (group.isView)
                faltV.push(group.value);
            if (!group.isLeaf) {
                if (group.isExpanded) {
                    flatE.push(group.value);
                    for (var elem in group.elems) {
                        walkGroupTreeMakeflat(group.elems[elem], faltV, flatE);
                    }
                }
            }
        };
        if (this.cubeGroupCols && this.cubeGroupRows) {
            walkGroupTreeMakeflat(this.cubeGroupCols, viewColsFlat, expandedColsFlat);
            walkGroupTreeMakeflat(this.cubeGroupRows, viewRowsFlat, expandedRowsFlat);
        }
        return {
            valuesViewCol: viewColsFlat,
            valuesViewRow: viewRowsFlat,
            valuesExpandCol: expandedColsFlat,
            valuesExpandRow: expandedRowsFlat
        };
    },
    updateReszie: function () {
        //if (this.cubeElC)
        //    this.cubeElC.setWidth(this.cubeCtrl.body.getWidth());
        if (this.cubeElC && this.cubeElC.first())
            this.cubeElC.setHeight(this.cubeElC.first().getHeight() + 20);
        this.generateChart();
    },
    updateTableGridDim: function () {
        //if (this.cubeElC)
        //    this.cubeElC.setWidth(this.cubeCtrl.body.getWidth());
        if (this.cubeElC && this.cubeElC.first())
            this.cubeElC.setHeight(this.cubeElC.first().getHeight() + 20);
    },
    buildTableHtml: function () {
        this.cubeCtrl.removeAll(true);
        this.cubeCtrl.body.update("");
        this.cubeElC = this.cubeCtrl.body.createChild({
            cls: "x-rpivot-cube-table-container"
        });
        var gridcubeGroupColsFlat2 = [];
        var gridcubeGroupRowsFlat2 = [];
        var gridcubeGroupColsFlat3 = [];
        var gridcubeGroupRowsFlat3 = [];
        for (var i = 0; i < (this.colsMeta.length + 1); i++) {
            gridcubeGroupColsFlat3[i] = [];
        }
        for (var i = 0; i < (this.rowsMeta.length + 1); i++) {
            gridcubeGroupRowsFlat3[i] = [];
        }

        function compareValues(a, b) {
            if (a.value < b.value)
                return -1;
            if (a.value > b.value)
                return 1;
            return 0;
        }

        var getSortedElems = function (elems) {
            var ee = [];
            for (var elem in elems)
                ee.push(elems[elem]);
            ee.sort(compareValues);
            return ee;
        };
        var walkGroupTreeMakeflat = function (group, flat, flat2, parentGroup) {
            group.parentGroup = parentGroup;
            if (group.elems.length != undefined)
                group.elems = {};
            if (group.isLeaf) {
                flat.push(group);
                flat2[group.level].push(group);
                group.currentElemsCount = 0;
                return 1;
            } else {
                flat.push(group);
                flat2[group.level].push(group);
                var sumc = 0;
                var aelems = getSortedElems(group.elems);
                for (var aelemsi = 0; aelemsi < aelems.length; aelemsi++) {
                    sumc += walkGroupTreeMakeflat(aelems[aelemsi], flat, flat2, group);
                }
                if (group.isView) {
                    if (!group.isExpanded) {
                        sumc = 1;
                    }
                } else {
                    sumc = 0;
                }
                group.currentElemsCount = sumc;
                return sumc;
            }
        };
        walkGroupTreeMakeflat(this.cubeGroupCols, gridcubeGroupColsFlat2, gridcubeGroupColsFlat3, null);
        walkGroupTreeMakeflat(this.cubeGroupRows, gridcubeGroupRowsFlat2, gridcubeGroupRowsFlat3, null);
        this.colListAll = [];
        this.rowListAll = [];
        for (var i = 1; i < gridcubeGroupColsFlat2.length; i++) {
            this.colListAll.push(gridcubeGroupColsFlat2[i]);
        }
        this.colListAll.push(gridcubeGroupColsFlat2[0]);
        for (var i = 1; i < gridcubeGroupRowsFlat2.length; i++) {
            this.rowListAll.push(gridcubeGroupRowsFlat2[i]);
        }
        this.rowListAll.push(gridcubeGroupRowsFlat2[0]);
        Ext.apply(this.gridcube, {
            makePair: function (r, c) {
                return (r.value ? r.value : r) + ":" + (c.value ? c.value : c);
            },
            get: function (r, c) {
                return this[this.makePair(r, c)];
            },
            set: function (r, c, v) {
                this[this.makePair(r, c)] = v;
            }
        });
        for (var icol = 0; icol < gridcubeGroupColsFlat2.length; icol++) {
            for (var irow = 0; irow < gridcubeGroupRowsFlat2.length; irow++) {
                this.gridcube.set(gridcubeGroupRowsFlat2[irow], gridcubeGroupColsFlat2[icol], {
                    row: gridcubeGroupRowsFlat2[irow],
                    col: gridcubeGroupColsFlat2[icol],
                    value: this.gridcube.get(gridcubeGroupRowsFlat2[irow], gridcubeGroupColsFlat2[icol]),
                    checkView: function () {
                        var vcol = false;
                        var vrow = false;
                        if (this.col.isLeaf || this.col.level == 0) {
                            vcol = this.col.isView;
                        } else if (this.col.isExpanded) {
                            vcol = false;
                        } else {
                            vcol = this.col.isView;
                        }
                        if (this.row.isLeaf || this.row.level == 0) {
                            vrow = this.row.isView;
                        } else if (this.row.isExpanded) {
                            vrow = false;
                        } else {
                            vrow = this.row.isView;
                        }
                        return vrow && vcol;
                    }
                });
            }
        }
        this.valuesMeta2 = {};
        this.valuesMetaP = [];
        Ext.each(this.valuesMeta, function (v, i, o) {
            if (!this.valuesMeta2[v.plyn])
                this.valuesMeta2[v.plyn] = [];
            this.valuesMeta2[v.plyn].push(v.typeFunction);
            this.valuesMetaP.push(v.plyn);
        }, this);
        this.valuesMetaP = Ext.Array.unique(this.valuesMetaP);
        var buf = [];
        var buildTD = function (cls, html, id, rowspan, colspan, display, isValue, addST) {
            addST = addST || "";
            var buf = [];
            buf.push('<td class="' + cls + '" id="' + id + '" rowspan=' + rowspan + ' colspan=' + colspan + ' ' + (!display ? 'style="display:none"' : '') + " " + addST + '>');
            buf.push('<span>');
            buf.push(html);
            buf.push('</span>');
            buf.push('</td>');
            return buf.join("");
        };
        var tableId = Ext.id();
        buf.push('<table class="x-rpivot-cube-table" id="' + tableId + '">');
        if (this.colsMeta.length > 0) {
            for (var i = 0; i < this.colsMeta.length; i++) {
                var level = i + 1;
                var levelelmls = gridcubeGroupColsFlat3[level];
                buf.push('<tr class="x-rpivot-cube-table-row-header">');
                if (i == 0) {
                    buf.push(buildTD("x-rpivot-cube-table-td-cross", "", "", this.colsMeta.length + 2, this.rowsMeta.length, true, false));
                }
                for (var j = 0; j < levelelmls.length; j++) {
                    var elem = levelelmls[j];
                    elem.tdIDs = [];
                    if (!elem.isLeaf) {
                        var rowspan = this.colsMeta.length - i;
                        elem.tdID1 = Ext.id();
                        elem.tdID2 = Ext.id();
                        buf.push(buildTD("x-rpivot-cube-table-td-group1", elem.label, elem.tdID1, rowspan, 1 - 1 + this.valuesMeta.length, !elem.isExpanded && elem.isView, false));
                        buf.push(buildTD("x-rpivot-cube-table-td-group2", elem.label, elem.tdID2, 1, elem.currentElemsCount * this.valuesMeta.length, elem.isExpanded && elem.isView, false));
                    } else {
                        elem.tdID = Ext.id();
                        buf.push(buildTD("x-rpivot-cube-table-td-child", elem.label, elem.tdID, 1, 1 - 1 + this.valuesMeta.length, elem.isView, false));
                    }
                }
                if (i == 0) {
                    var elem = gridcubeGroupColsFlat2[0];
                    elem.tdIDs = [];
                    elem.tdID = "";
                    elem.tdID1 = "";
                    elem.tdID2 = "";
                    buf.push(buildTD("x-rpivot-cube-table-td-summary", Lang.suma, "", this.colsMeta.length, 1 - 1 + this.valuesMeta.length, true, false));
                }
                buf.push('</tr>');
            }
        } else {
            buf.push('<tr class="x-rpivot-cube-table-row-header">');
            buf.push(buildTD("x-rpivot-cube-table-td-cross", "", "", 1 + 2, this.rowsMeta.length, true, false));
            var elem = gridcubeGroupColsFlat2[0];
            elem.tdIDs = [];
            elem.tdID = "";
            elem.tdID1 = "";
            elem.tdID2 = "";
            buf.push(buildTD("x-rpivot-cube-table-td-summary", Lang.suma, "", 1, 1 - 1 + this.valuesMeta.length, true, false));
            buf.push('</tr>');
        }
        buf.push('<tr class="x-rpivot-cube-table-row-header">');
        Ext.each(this.colListAll, function (elem, elemi, elemo) {
            Ext.each(this.valuesMetaP, function (v, i, a) {
                var tid = Ext.id();
                elem.tdIDs.push(tid);
                var viewS = false;
                if (elem.isLeaf || elem.level == 0) {
                    viewS = elem.isView;
                } else {
                    viewS = !elem.isExpanded && elem.isView;
                }
                buf.push(buildTD("x-rpivot-cube-table-td-podsum", v, tid, 1, this.valuesMeta2[v].length, viewS, false));
            }, this);
        }, this);
        buf.push('</tr>');
        buf.push('<tr class="x-rpivot-cube-table-row-header">');
        Ext.each(this.colListAll, function (elem, elemi, elemo) {
            Ext.each(this.valuesMeta, function (v, i, a) {
                var tid = Ext.id();
                elem.tdIDs.push(tid);
                var txt = "";
                if (v.typeFunction == 0)
                    txt = Lang.tankowanie;
                else if (v.typeFunction == 1)
                    txt = Lang.zuzycie100;
                else if (v.typeFunction == 2)
                    txt = Lang.dystans;
                else if (v.typeFunction == 3)
                    txt = Lang.zuzycie1h;
                else if (v.typeFunction == 4)
                    txt = Lang.czaspracy;
                var viewS = false;
                if (elem.isLeaf || elem.level == 0) {
                    viewS = elem.isView;
                } else {
                    viewS = !elem.isExpanded && elem.isView;
                }
                buf.push(buildTD("x-rpivot-cube-table-td-podsum", txt, tid, 1, 1, viewS, false));
            }, this);
        }, this);
        buf.push('</tr>');
        var recursiveGenerateRow = function (group, buf, rowsMeta, gridcube, beginTr, colListAll, rowListAll) {
            if (group.level == 0) {
                var aelems = getSortedElems(group.elems);
                for (var aelemsi = 0; aelemsi < aelems.length; aelemsi++) {
                    recursiveGenerateRow(aelems[aelemsi], buf, rowsMeta, gridcube, false, colListAll, rowListAll);
                }
                if (!beginTr) {
                    buf.push('<tr class="x-rpivot-cube-table-row">');
                }
                group.tdID = "";
                group.tdID1 = "";
                group.tdID2 = "";
                buf.push(buildTD("x-rpivot-cube-table-td-summary", Lang.suma, "", 1, rowsMeta.length, true, false));
                for (var j = 0; j < colListAll.length; j++) {
                    var col = colListAll[j];
                    var vals = gridcube.get(group.value, col.value);
                    vals.tdIDs = [];
                    if (vals.col.level == 0) {
                        Ext.each(vals.value.values, function (v, i, a) {
                            var tid = Ext.id();
                            vals.tdIDs.push(tid);
                            var vv = v == null ? "" : Ext.isNumber(v) ? Ext.Number.roundToPrecision(v, 3) : v;
                            var vv1 = vals.value.values2[i];
                            vv1 = false;
                            var vv2 = vals.value.values3[i];
                            var vv3 = vals.value.values4[i];
                            var modcls = "";
                            if (vv1 || vv2)
                                modcls += "x-rpivot-cube-table-td-data-blad";
                            var tplqt = new Ext.Template(Lang.norma_zuzycia_tips);
                            var qt = tplqt.apply({
                                normav: vv3
                            });
                            var modcls2 = 'ext:qtip="' + (vv3 == null ? "" : qt) + '"';
                            buf.push(buildTD("x-rpivot-cube-table-td-summary-data " + modcls, vv, tid, 1, 1, vals.checkView(), true, modcls2));
                        });
                    } else {
                        Ext.each(vals.value.values, function (v, i, a) {
                            var tid = Ext.id();
                            vals.tdIDs.push(tid);
                            var vv = v == null ? "" : Ext.isNumber(v) ? Ext.Number.roundToPrecision(v, 3) : v;
                            var vv1 = vals.value.values2[i];
                            vv1 = false;
                            var vv2 = vals.value.values3[i];
                            var vv3 = vals.value.values4[i];
                            var modcls = "";
                            if (vv1 || vv2)
                                modcls += "x-rpivot-cube-table-td-data-blad";
                            var tplqt = new Ext.Template(Lang.norma_zuzycia_tips);
                            var qt = tplqt.apply({
                                normav: vv3
                            });
                            var modcls2 = 'ext:qtip="' + (vv3 == null ? "" : qt) + '"';
                            buf.push(buildTD("x-rpivot-cube-table-td-summary-data " + modcls, vv, tid, 1, 1, vals.checkView(), true, modcls2));
                        });
                    }
                }
                buf.push('</tr>');
                return;
            }
            if (group.isLeaf) {
                if (!beginTr) {
                    buf.push('<tr class="x-rpivot-cube-table-row">');
                }
                group.tdID = "";
                group.tdID1 = "";
                group.tdID2 = "";
                group.tdID = Ext.id();
                buf.push(buildTD("x-rpivot-cube-table-td-child", group.label, group.tdID, 1, 1, group.isView, false));
                for (var j = 0; j < colListAll.length; j++) {
                    var col = colListAll[j];
                    var vals = gridcube.get(group.value, col.value);
                    vals.tdIDs = [];
                    if (vals.col.level == 0) {
                        Ext.each(vals.value.values, function (v, i, a) {
                            var tid = Ext.id();
                            vals.tdIDs.push(tid);
                            var vv = v == null ? "" : Ext.isNumber(v) ? Ext.Number.roundToPrecision(v, 3) : v;
                            var vv1 = vals.value.values2[i];
                            vv1 = false;
                            var vv2 = vals.value.values3[i];
                            var vv3 = vals.value.values4[i];
                            var modcls = "";
                            if (vv1 || vv2)
                                modcls += "x-rpivot-cube-table-td-data-blad";
                            var tplqt = new Ext.Template(Lang.norma_zuzycia_tips);
                            var qt = tplqt.apply({
                                normav: vv3
                            });
                            var modcls2 = 'ext:qtip="' + (vv3 == null ? "" : qt) + '"';
                            buf.push(buildTD("x-rpivot-cube-table-td-summary-data " + modcls, vv, tid, 1, 1, vals.checkView(), true, modcls2));
                        });
                    } else {
                        Ext.each(vals.value.values, function (v, i, a) {
                            var tid = Ext.id();
                            vals.tdIDs.push(tid);
                            var vv = v == null ? "" : Ext.isNumber(v) ? Ext.Number.roundToPrecision(v, 3) : v;
                            var vv1 = vals.value.values2[i];
                            vv1 = false;
                            var vv2 = vals.value.values3[i];
                            var vv3 = vals.value.values4[i];
                            var modcls = "";
                            if (vv1 || vv2)
                                modcls += "x-rpivot-cube-table-td-data-blad";
                            var tplqt = new Ext.Template(Lang.norma_zuzycia_tips);
                            var qt = tplqt.apply({
                                normav: vv3
                            });
                            var modcls2 = 'ext:qtip="' + (vv3 == null ? "" : qt) + '"';
                            buf.push(buildTD("x-rpivot-cube-table-td-data " + modcls, vv, tid, 1, 1, vals.checkView(), true, modcls2));
                        });
                    }
                }
                buf.push('</tr>');
            } else {
                if (!beginTr) {
                    buf.push('<tr class="x-rpivot-cube-table-row">');
                }
                var colspan = rowsMeta.length - (group.level - 1);
                group.tdID = "";
                group.tdID1 = "";
                group.tdID2 = "";
                group.tdID1 = Ext.id();
                buf.push(buildTD("x-rpivot-cube-table-td-group1", group.label, group.tdID1, 1, colspan, !group.isExpanded && group.isView, false));
                for (var j = 0; j < colListAll.length; j++) {
                    var col = colListAll[j];
                    var vals = gridcube.get(group.value, col.value);
                    vals.tdIDs = [];
                    if (vals.col.level == 0) {
                        Ext.each(vals.value.values, function (v, i, a) {
                            var tid = Ext.id();
                            vals.tdIDs.push(tid);
                            var vv = v == null ? "" : Ext.isNumber(v) ? Ext.Number.roundToPrecision(v, 3) : v;
                            var vv1 = vals.value.values2[i];
                            vv1 = false;
                            var vv2 = vals.value.values3[i];
                            var vv3 = vals.value.values4[i];
                            var modcls = "";
                            if (vv1 || vv2)
                                modcls += "x-rpivot-cube-table-td-data-blad";
                            var tplqt = new Ext.Template(Lang.norma_zuzycia_tips);
                            var qt = tplqt.apply({
                                normav: vv3
                            });
                            var modcls2 = 'ext:qtip="' + (vv3 == null ? "" : qt) + '"';
                            buf.push(buildTD("x-rpivot-cube-table-td-summary-data " + modcls, vv, tid, 1, 1, vals.checkView(), true, modcls2));
                        });
                    } else {
                        Ext.each(vals.value.values, function (v, i, a) {
                            var tid = Ext.id();
                            vals.tdIDs.push(tid);
                            var vv = v == null ? "" : Ext.isNumber(v) ? Ext.Number.roundToPrecision(v, 3) : v;
                            var vv1 = vals.value.values2[i];
                            vv1 = false;
                            var vv2 = vals.value.values3[i];
                            var vv3 = vals.value.values4[i];
                            var modcls = "";
                            if (vv1 || vv2)
                                modcls += "x-rpivot-cube-table-td-data-blad";
                            var tplqt = new Ext.Template(Lang.norma_zuzycia_tips);
                            var qt = tplqt.apply({
                                normav: vv3
                            });
                            var modcls2 = 'ext:qtip="' + (vv3 == null ? "" : qt) + '"';
                            buf.push(buildTD("x-rpivot-cube-table-td-data " + modcls, vv, tid, 1, 1, vals.checkView(), true, modcls2));
                        });
                    }
                }
                buf.push('</tr>');
                buf.push('<tr class="x-rpivot-cube-table-row">');
                beginTr = true;
                group.tdID2 = Ext.id();
                buf.push(buildTD("x-rpivot-cube-table-td-group2", group.label, group.tdID2, group.elemCount - 1, 1, group.isExpanded && group.isView, false));
                var aelems = getSortedElems(group.elems);
                for (var aelemsi = 0; aelemsi < aelems.length; aelemsi++) {
                    recursiveGenerateRow(aelems[aelemsi], buf, rowsMeta, gridcube, beginTr, colListAll, rowListAll);
                    beginTr = false;
                }
            }
        };
        recursiveGenerateRow(this.cubeGroupRows, buf, this.rowsMeta, this.gridcube, false, this.colListAll, this.rowListAll);
        buf.push('</table>');
        this.cubeElC.update(buf.join(""));
        for (var icol = 0; icol < gridcubeGroupColsFlat2.length; icol++) {
            for (var irow = 0; irow < gridcubeGroupRowsFlat2.length; irow++) {
                var el = this.gridcube.get(gridcubeGroupRowsFlat2[irow].value, gridcubeGroupColsFlat2[icol].value);
                el.tdEls = [];
                Ext.each(el.tdIDs, function (v) {
                    var tte = Ext.get(v);
                    tte.setVisibilityMode(Ext.Element.DISPLAY);
                    el.tdEls.push(tte);
                });
            }
        }
        var valuesMeta = this.valuesMeta;
        var walkGroupTreeMakeflatCol = function (group, colListAll, rowListAll, reportcube, generateChart, scopeObject) {
            group.tdEls = [];
            Ext.each(group.tdIDs, function (v) {
                var tte = Ext.get(v);
                tte.setVisibilityMode(Ext.Element.DISPLAY);
                group.tdEls.push(tte);
            });
            group.tdEl = Ext.get(group.tdID);
            group.tdEl1 = Ext.get(group.tdID1);
            group.tdEl2 = Ext.get(group.tdID2);
            if (group.tdEl != null) {
                group.tdEl.setVisibilityMode(Ext.Element.DISPLAY);
            }
            if (group.tdEl1 != null) {
                group.tdEl1.setVisibilityMode(Ext.Element.DISPLAY);
            }
            if (group.tdEl2 != null) {
                group.tdEl2.setVisibilityMode(Ext.Element.DISPLAY);
            }
            if (group.level != 0 && !group.isLeaf) {
                group.clickMouse1 = function (e) {
                    this.setStateExpand(true);
                };
                group.clickMouse2 = function (e) {
                    this.setStateExpand(false);
                };
                group.tdEl1.on("click", group.clickMouse1, group);
                group.tdEl2.on("click", group.clickMouse2, group);
            }
            if (group.level != 0 && !group.isLeaf) {
                group.eventMsgFromChild = function (child, oldCurrentElemCount, newCurrentElemCount) {
                    var oldelcl = this.currentElemsCount;
                    this.currentElemsCount = this.currentElemsCount - oldCurrentElemCount + newCurrentElemCount;
                    if (this.level >= 1) {
                        this.tdEl2.dom.colSpan = this.currentElemsCount * valuesMeta.length;
                    }
                    this.sendMsgToParrent(oldelcl, this.currentElemsCount);
                };
                group.sendMsgToParrent = function (oldCurrentElemCount, newCurrentElemCount) {
                    if (this.parentGroup != null && this.parentGroup.level != 0) {
                        this.parentGroup.eventMsgFromChild(this, oldCurrentElemCount, newCurrentElemCount);
                    }
                };
                group.calculateChilds = function () {
                    if (!group.isExpanded) {
                        return 1;
                    }
                    var sum = 0;
                    for (var elem in this.elems) {
                        if (this.elems[elem].isLeaf) {
                            sum += 1;
                        } else {
                            sum += this.elems[elem].calculateChilds();
                        }
                    }
                    return sum;
                };
            }
            if (group.level != 0 && !group.isLeaf) {
                group.refreshView = function (newstateview) {
                    this.isView = newstateview;
                    if (this.isExpanded) {
                        this.tdEl1.setVisible(false);
                        Ext.each(this.tdEls, function (v) {
                            v.setVisible(false);
                        }, this);
                        this.tdEl2.setVisible(this.isView);
                        for (var elem in this.elems) {
                            if (this.elems[elem].isLeaf) {
                                this.elems[elem].isView = this.isView;
                                this.elems[elem].tdEl.setVisible(this.elems[elem].isView);
                                Ext.each(this.elems[elem].tdEls, function (v) {
                                    v.setVisible(this.isView);
                                }, this.elems[elem]);
                                for (var i = 0; i < rowListAll.length; i++) {
                                    var row = rowListAll[i].value;
                                    var col = this.elems[elem].value;
                                    var td = reportcube.get(row, col);
                                    Ext.each(td.tdEls, function (v) {
                                        v.setVisible(td.checkView());
                                    });
                                }
                            } else {
                                this.elems[elem].refreshView(this.isView);
                            }
                        }
                    } else {
                        this.tdEl1.setVisible(this.isView);
                        Ext.each(this.tdEls, function (v) {
                            v.setVisible(this.isView);
                        }, this);
                        for (var i = 0; i < rowListAll.length; i++) {
                            var row = rowListAll[i].value;
                            var col = this.value;
                            var td = reportcube.get(row, col);
                            Ext.each(td.tdEls, function (v) {
                                v.setVisible(td.checkView());
                            });
                        }
                    }
                };
            }
            if (group.level != 0 && !group.isLeaf) {
                group.setStateExpand = function (newstateexpand) {
                    this.oldisExpanded = this.isExpanded;
                    this.isExpanded = newstateexpand;
                    if (this.isExpanded) {
                        this.currentElemsCount = this.calculateChilds();
                        if (this.level >= 1) {
                            this.tdEl2.dom.colSpan = this.currentElemsCount * valuesMeta.length;
                        }
                    }
                    var oldCurrentElemCount = (this.oldisExpanded ? this.currentElemsCount : 1);
                    var newCurrentElemCount = (this.isExpanded ? this.currentElemsCount : 1);
                    this.sendMsgToParrent(oldCurrentElemCount, newCurrentElemCount);
                    if (this.isExpanded) {
                        this.tdEl1.setVisible(false);
                        Ext.each(this.tdEls, function (v) {
                            v.setVisible(false);
                        }, this);
                        for (var i = 0; i < rowListAll.length; i++) {
                            var row = rowListAll[i].value;
                            var col = this.value;
                            var td = reportcube.get(row, col);
                            Ext.each(td.tdEls, function (v) {
                                v.setVisible(td.checkView());
                            });
                        }
                        this.tdEl2.setVisible(true);
                        for (var elem in this.elems) {
                            if (this.elems[elem].isLeaf) {
                                this.elems[elem].isView = true;
                                this.elems[elem].tdEl.setVisible(this.elems[elem].isView);
                                Ext.each(this.elems[elem].tdEls, function (v) {
                                    v.setVisible(this.isView);
                                }, this.elems[elem]);
                                for (var i = 0; i < rowListAll.length; i++) {
                                    var row = rowListAll[i].value;
                                    var col = this.elems[elem].value;
                                    var td = reportcube.get(row, col);
                                    Ext.each(td.tdEls, function (v) {
                                        v.setVisible(td.checkView());
                                    });
                                }
                            } else {
                                this.elems[elem].refreshView(true);
                            }
                        }
                    } else {
                        this.tdEl1.setVisible(true);
                        Ext.each(this.tdEls, function (v) {
                            v.setVisible(true);
                        }, this);
                        for (var i = 0; i < rowListAll.length; i++) {
                            var row = rowListAll[i].value;
                            var col = this.value;
                            var td = reportcube.get(row, col);
                            Ext.each(td.tdEls, function (v) {
                                v.setVisible(td.checkView());
                            });
                        }
                        this.tdEl2.setVisible(false);
                        for (var elem in this.elems) {
                            if (this.elems[elem].isLeaf) {
                                this.elems[elem].isView = false;
                                this.elems[elem].tdEl.setVisible(this.elems[elem].isView);
                                Ext.each(this.elems[elem].tdEls, function (v) {
                                    v.setVisible(this.isView);
                                }, this.elems[elem]);
                                for (var i = 0; i < rowListAll.length; i++) {
                                    var row = rowListAll[i].value;
                                    var col = this.elems[elem].value;
                                    var td = reportcube.get(row, col);
                                    Ext.each(td.tdEls, function (v) {
                                        v.setVisible(td.checkView());
                                    });
                                }
                            } else {
                                this.elems[elem].refreshView(false);
                            }
                        }
                    }
                    scopeObject.updateTableGridDim();
                    generateChart();
                };
            }
            if (!group.isLeaf) {
                for (var elem in group.elems) {
                    walkGroupTreeMakeflatCol(group.elems[elem], colListAll, rowListAll, reportcube, generateChart, scopeObject);
                }
            }
        };
        var walkGroupTreeMakeflatRow = function (group, colListAll, rowListAll, reportcube, generateChart, scopeObject) {
            group.tdEl = Ext.get(group.tdID);
            group.tdEl1 = Ext.get(group.tdID1);
            group.tdEl2 = Ext.get(group.tdID2);
            if (group.tdEl != null) {
                group.tdEl.setVisibilityMode(Ext.Element.DISPLAY);
            }
            if (group.tdEl1 != null) {
                group.tdEl1.setVisibilityMode(Ext.Element.DISPLAY);
            }
            if (group.tdEl2 != null) {
                group.tdEl2.setVisibilityMode(Ext.Element.DISPLAY);
            }
            if (group.level != 0 && !group.isLeaf) {
                group.clickMouse1 = function (e) {
                    this.setStateExpand(true);
                };
                group.clickMouse2 = function (e) {
                    this.setStateExpand(false);
                };
                group.tdEl1.on("click", group.clickMouse1, group);
                group.tdEl2.on("click", group.clickMouse2, group);
            }
            if (group.level != 0 && !group.isLeaf) {
                group.refreshView = function (newstateview) {
                    this.isView = newstateview;
                    if (this.isExpanded) {
                        this.tdEl1.setVisible(false);
                        this.tdEl2.setVisible(this.isView);
                        for (var elem in this.elems) {
                            if (this.elems[elem].isLeaf) {
                                this.elems[elem].isView = this.isView;
                                this.elems[elem].tdEl.setVisible(this.elems[elem].isView);
                                for (var i = 0; i < colListAll.length; i++) {
                                    var row = this.elems[elem].value;
                                    var col = colListAll[i].value;
                                    var td = reportcube.get(row, col);
                                    Ext.each(td.tdEls, function (v) {
                                        v.setVisible(td.checkView());
                                    });
                                }
                            } else {
                                this.elems[elem].refreshView(this.isView);
                            }
                        }
                    } else {
                        this.tdEl1.setVisible(this.isView);
                        for (var i = 0; i < colListAll.length; i++) {
                            var row = this.value;
                            var col = colListAll[i].value;
                            var td = reportcube.get(row, col);
                            Ext.each(td.tdEls, function (v) {
                                v.setVisible(td.checkView());
                            });
                        }
                    }
                };
            }
            if (group.level != 0 && !group.isLeaf) {
                group.setStateExpand = function (newstateexpand) {
                    this.isExpanded = newstateexpand;
                    if (this.isExpanded) {
                        this.tdEl1.setVisible(false);
                        for (var i = 0; i < colListAll.length; i++) {
                            var row = this.value;
                            var col = colListAll[i].value;
                            var td = reportcube.get(row, col);
                            Ext.each(td.tdEls, function (v) {
                                v.setVisible(td.checkView());
                            });
                        }
                        this.tdEl2.setVisible(true);
                        for (var elem in this.elems) {
                            if (this.elems[elem].isLeaf) {
                                this.elems[elem].isView = true;
                                this.elems[elem].tdEl.setVisible(this.elems[elem].isView);
                                for (var i = 0; i < colListAll.length; i++) {
                                    var row = this.elems[elem].value;
                                    var col = colListAll[i].value;
                                    var td = reportcube.get(row, col);
                                    Ext.each(td.tdEls, function (v) {
                                        v.setVisible(td.checkView());
                                    });
                                }
                            } else {
                                this.elems[elem].refreshView(true);
                            }
                        }
                    } else {
                        this.tdEl1.setVisible(true);
                        for (var i = 0; i < colListAll.length; i++) {
                            var row = this.value;
                            var col = colListAll[i].value;
                            var td = reportcube.get(row, col);
                            Ext.each(td.tdEls, function (v) {
                                v.setVisible(td.checkView());
                            });
                        }
                        this.tdEl2.setVisible(false);
                        for (var elem in this.elems) {
                            if (this.elems[elem].isLeaf) {
                                this.elems[elem].isView = false;
                                this.elems[elem].tdEl.setVisible(this.elems[elem].isView);
                                for (var i = 0; i < colListAll.length; i++) {
                                    var row = this.elems[elem].value;
                                    var col = colListAll[i].value;
                                    var td = reportcube.get(row, col);
                                    Ext.each(td.tdEls, function (v) {
                                        v.setVisible(td.checkView());
                                    });
                                }
                            } else {
                                this.elems[elem].refreshView(false);
                            }
                        }
                    }
                    scopeObject.updateTableGridDim();
                    generateChart();
                };
            }
            if (!group.isLeaf) {
                for (var elem in group.elems) {
                    walkGroupTreeMakeflatRow(group.elems[elem], colListAll, rowListAll, reportcube, generateChart, scopeObject);
                }
            }
        };
        walkGroupTreeMakeflatCol(this.cubeGroupCols, this.colListAll, this.rowListAll, this.gridcube, Ext.Function.bind(this.generateChart, this, [], false), this);
        walkGroupTreeMakeflatRow(this.cubeGroupRows, this.colListAll, this.rowListAll, this.gridcube, Ext.Function.bind(this.generateChart, this, [], false), this);
        this.updateTableGridDim();
        this.generateChart();
    },
    generateChart: function () {
        if (this.colListAll == null || this.rowListAll == null) {
            return;
        }
        var records = [];
        var lsCurCol = [];
        var lsCurRow = [];
        for (var i = 0; i < this.colListAll.length; i++) {
            var el = this.colListAll[i];
            var visible = false;
            if (!el.isView || el.level == 0) {
                visible = false;
            } else if (el.isLeaf) {
                visible = true;
            } else if (el.isExpanded) {
                visible = false;
            } else {
                visible = true;
            }
            if (visible) {
                lsCurCol.push(el);
            }
        }
        for (var i = 0; i < this.rowListAll.length; i++) {
            var el = this.rowListAll[i];
            var visible = false;
            if (!el.isView || el.level == 0) {
                visible = false;
            } else if (el.isLeaf) {
                visible = true;
            } else if (el.isExpanded) {
                visible = false;
            } else {
                visible = true;
            }
            if (visible) {
                lsCurRow.push(el);
            }
        }

        function getLabelforPath(group) {
            var path = [];
            while (group != null && group.level != 0) {
                path.push(group.label);
                group = group.parentGroup;
            }
            path.reverse();
            return path.join(", ");
        }

        var valuesMeta = this.valuesMeta;
        if (lsCurCol.length > 0 && lsCurRow.length > 0) {
            for (var irow = 0; irow < lsCurRow.length; irow++) {
                for (var icol = 0; icol < lsCurCol.length; icol++) {
                    var sylab = getLabelforPath(lsCurCol[icol]);
                    var sylab2 = getLabelforPath(lsCurRow[irow]);
                    var el = this.gridcube.get(lsCurRow[irow].value, lsCurCol[icol].value);
                    Ext.each(el.value.values, function (v, ii) {
                        var plyn = valuesMeta[ii].plyn;
                        var typeFunction = valuesMeta[ii].typeFunction;
                        var typeFunctions = "";
                        if (typeFunction == 0)
                            typeFunctions = Lang.tankowanie;
                        else if (typeFunction == 1)
                            typeFunctions = Lang.zuzycie100;
                        else if (typeFunction == 2)
                            typeFunctions = Lang.dystans;
                        else if (typeFunction == 3)
                            typeFunctions = Lang.zuzycie1h;
                        else if (typeFunction == 4)
                            typeFunctions = Lang.czaspracy;
                        records.push({
                            value: el.value.values[ii],
                            seria: sylab + "(" + plyn + "-" + typeFunctions + ")",
                            domain: sylab2
                        });
                    });
                }
            }
        } else if (lsCurCol.length > 0) {
            for (var icol = 0; icol < lsCurCol.length; icol++) {
                var sylab = getLabelforPath(lsCurCol[icol]);
                var el = this.gridcube.get(this.cubeGroupRows.value, lsCurCol[icol].value);
                Ext.each(el.value.values, function (v, ii) {
                    var plyn = valuesMeta[ii].plyn;
                    var typeFunction = valuesMeta[ii].typeFunction;
                    var typeFunctions = "";
                    if (typeFunction == 0)
                        typeFunctions = Lang.tankowanie;
                    else if (typeFunction == 1)
                        typeFunctions = Lang.zuzycie100;
                    else if (typeFunction == 2)
                        typeFunctions = Lang.dystans;
                    else if (typeFunction == 3)
                        typeFunctions = Lang.zuzycie1h;
                    else if (typeFunction == 4)
                        typeFunctions = Lang.czaspracy;
                    records.push({
                        value: el.value.values[ii],
                        seria: plyn + "-" + typeFunctions,
                        domain: sylab
                    });
                });
            }
        } else if (lsCurRow.length > 0) {
            for (var irow = 0; irow < lsCurRow.length; irow++) {
                var sylab = getLabelforPath(lsCurRow[irow]);
                var el = this.gridcube.get(lsCurRow[irow].value, this.cubeGroupCols.value);
                Ext.each(el.value.values, function (v, ii) {
                    var plyn = valuesMeta[ii].plyn;
                    var typeFunction = valuesMeta[ii].typeFunction;
                    var typeFunctions = "";
                    if (typeFunction == 0)
                        typeFunctions = Lang.tankowanie;
                    else if (typeFunction == 1)
                        typeFunctions = Lang.zuzycie100;
                    else if (typeFunction == 2)
                        typeFunctions = Lang.dystans;
                    else if (typeFunction == 3)
                        typeFunctions = Lang.zuzycie1h;
                    else if (typeFunction == 4)
                        typeFunctions = Lang.czaspracy;
                    records.push({
                        value: el.value.values[ii],
                        seria: plyn + "-" + typeFunctions,
                        domain: sylab
                    });
                });
            }
        } else {
            var sylab = Lang.suma;
            var el = this.gridcube.get(this.cubeGroupRows.value, this.cubeGroupCols.value);
            Ext.each(el.value.values, function (v, ii) {
                var plyn = valuesMeta[ii].plyn;
                var typeFunction = valuesMeta[ii].typeFunction;
                var typeFunctions = "";
                if (typeFunction == 0)
                    typeFunctions = Lang.tankowanie;
                else if (typeFunction == 1)
                    typeFunctions = Lang.zuzycie100;
                else if (typeFunction == 2)
                    typeFunctions = Lang.dystans;
                else if (typeFunction == 3)
                    typeFunctions = Lang.zuzycie1h;
                else if (typeFunction == 4)
                    typeFunctions = Lang.czaspracy;
                records.push({
                    value: el.value.values[ii],
                    seria: plyn + "-" + typeFunctions,
                    domain: sylab
                });
            });
        }
        new Ext.data.JsonStore({
            fields: [{
                name: 'value',
                mapping: 'value'
            }, {
                name: 'text',
                mapping: 'text'
            }]
        });
        this.chartCtrl.removeAll(true);
        this.chartCtrl.body.update("");
        this.cubeElChartC = this.chartCtrl.body.createChild({
            style: "border:1px solid black"
        });
        var chartCtrl = this.chartCtrl;
        var cubeElChartC = this.cubeElChartC;
        var legendviewCtrl = this.legendviewCtrl;
        sendAjaxDataJsonRpcL("rest/reportpivot/createChartObject", {
            records: Ext.encode(records)
        }, this, function (data) {
            if (data.status) {
                var data = data.data;
                var tid = data.id;
                var legend = data.legend;
                if (chartCtrl.body != null) {
                    var width = chartCtrl.body.getBox(true, false).width - 10;
                    var height = chartCtrl.body.getBox(true, false).height - 10;
                    this.chartId = tid;
                    cubeElChartC.createChild({
                        tag: "img",
                        src: "rest/reportpivot/getChartObject?width=" + width + "&height=" + height + "&rnd=" + Math.random() + "&id=" + tid
                    });
                    var legendls = [];
                    Ext.iterate(legend, function (k, v, o) {
                        legendls.push({
                            color: v,
                            text: k
                        });
                    });
                    legendls.sort(function (a, b) {
                        return a.text > b.text;
                    });
                    legendviewCtrl.store.loadData(legendls);
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });

    },
    drukuj: function () {
        var cubeCtrl = Ext.getCmp(this.id + "ctrl");
        var chartCtrl = Ext.getCmp(this.id + "chart");
        var legendCtrl = Ext.getCmp(this.id + "legend");
        var legendviewCtrl = Ext.getCmp(this.id + "legendview");
        var eel = Ext.getCmp(this.id + "main");
        var cubeElC = this.cubeElC;
        var cubeElChartC = this.cubeElChartC;
        var legendviewCtrl = this.legendviewCtrl.el;
        var width = 650;
        var height = chartCtrl.getHeight();
        var tid = this.chartId;
        if (!cubeElC) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_danych);
            return;
        }
        var generator = window.open('', 'SMDP Printer', '');
        generator.document.write('<html><head><title>SMDP</title>');
        generator.document.write('<link rel="stylesheet" href="resource/css/styleprint.css">');
        generator.document.write('</head><body>');
        var tablee = Ext.DomHelper.markup([{
            tag: "table",
            cn: [{
                tag: "tr",
                cn: [{
                    tag: "td",
                    style: "vertical-align:top;border:1px solid black",
                    cn: [{
                        tag: "img",
                        src: "rest/reportpivot/getChartObject?width=" + width + "&height=" + height + "&rnd=" + Math.random() + "&id=" + tid
                    }]
                }, {
                    tag: "td",
                    style: "vertical-align:top",
                    cn: [legendviewCtrl.dom.innerHTML]
                }]
            }]
        }, cubeElC.dom.innerHTML]);
        generator.document.write(tablee);
        generator.document.write('</body></html>');
        generator.document.close();
        Ext.Function.defer(function () {
            generator.print();
        }, 100);
    },
    getAsHtml: function () {
        var cubeCtrl = Ext.getCmp(this.id + "ctrl");
        var chartCtrl = Ext.getCmp(this.id + "chart");
        var legendCtrl = Ext.getCmp(this.id + "legend");
        var legendviewCtrl = Ext.getCmp(this.id + "legendview");
        var eel = Ext.getCmp(this.id + "main");
        var cubeElC = this.cubeElC;
        var cubeElChartC = this.cubeElChartC;
        var legendviewCtrl = this.legendviewCtrl.el;
        var width = 650;
        var height = chartCtrl.getHeight();
        var tid = this.chartId;
        if (!cubeElC) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_danych);
            return null;
        }
        var html = '<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd"><html><head><meta http-equiv="Content-Type" content="text/html; charset=utf-8"><title>SMDP</title>';
        html += '<style>{content:file=etc/css/stylerpivotexcel.css}</style>';
        html += '</head><body>';
        html += cubeElC.dom.innerHTML;
        html += '</body></html>';
        return html;
    }
});
