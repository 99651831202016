Ext.define('ViewZaladunekLlimity', {
    extend: 'Ext.panel.Panel',
    grid: null,
    gridpage: null,
    windowObject: null,
    id: "zaladunkilimity",
    taskContextMenu: null,
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyViewName: "access.view.zaladunkilimity"
    },
    accessPropertyName: "access.manage.zaladunkilimity",
    accessPropertyViewNameLimitOnly: "access.view.stany_limit_only",
    cat: "t",
    text: Lang.zaladunki_limity,
    buttonIconCls: "iconZaladunekLimity",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                scope: this,
                handler: this.dodajObiekt,
                disabled: !validPrivilegesOperatorComplex(this.accessPropertyName + "/add")
            }, {
                id: this.id + "bedit",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEdit",
                text: Lang.edytuj.capitalise(),
                scope: this,
                handler: this.edytujObiekt,
                disabled: !validPrivilegesOperatorComplex(this.accessPropertyName + "/update")
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconPDF",
                text: Lang.pdf.capitalise(),
                scope: this,
                handler: this.getDownloadLsPdf
            }, {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEXCEL",
                text: Lang.excel.capitalise(),
                scope: this,
                handler: this.getDownloadLsExcel
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }, {
                xtype: "box",
                id: this.id + "kontekstfirmybox",
                cls: "kontekstfirmy-box"
            }, {
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                style: 'display:block;margin-right:5px;',
                cls: "kontekstfirmy-text"
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
        if (this.grid && this.grid.rendered)
            this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        var kontekstfirmybox = Ext.getCmp(this.id + "kontekstfirmybox");
        if (AppFIRMACTX != null && !Const.AGGREGATEDCORE) {
            kontekstfirmybox.update("<div class='appSetColourRedSplit'></div>");
            kontekstfirmy.setText(Lang.pracujesz_w_kontekscie + AppFIRMACTX.nazwa);
        } else {
            kontekstfirmybox.update("");
            kontekstfirmy.setText("");
        }
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            var onwer = this.grid.ownerCt;
            Ext.suspendLayouts();
            onwer.remove(this.grid);
            this.grid.destroy();
            this.taskContextMenu.destroy();
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
            if (this.grid && this.grid.rendered)
                this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
    },
    onDestroy: function () {
        this.taskContextMenu.destroy();
        this.callParent(arguments);
    },
    buildGrid: function () {
        var store = Ext.create('Ext.data.Store', {
            autoLoad: false,
            remoteSort: true,
            remoteFilter: true,
            pageSize: 50,
            fields: [{
                name: 'czas',
                mapping: 'czas',
                type: 'date'
            }, {
                name: 'ile',
                mapping: 'ile',
                type: 'float'
            }, {
                name: 'firma',
                mapping: 'firma'
            }, {
                name: 'plyn',
                mapping: 'plyn'
            }, {
                name: "stanlimitu",
                mapping: "stanlimitu"
            }, {

                name: "przedprzydzialem",
                mapping: "przedprzydzialem"
            }, {
                name: "userimienazwisko",
                mapping: "userimienazwisko"
            }, "opis"],
            proxy: {
                type: "ajax",
                url: "rest/zaladuneklimity/getList",
                actionMethods: {
                    read: 'POST'
                },
                reader: {
                    type: 'json',
                    rootProperty: 'data',
                    totalProperty: 'total',
                    id: 'id'
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            sorters: {
                property: "czas",
                direction: "DESC"
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        var cols = [{
            header: Lang.czas,
            flex: 3,
            sortable: true,
            dataIndex: 'czas',
            renderer: Ext.util.Format.dateRenderer(),
            filter: {
                type: 'date'
            },
            filterable: true
        }, {
            header: Lang.firma,
            flex: 2,
            sortable: true,
            dataIndex: 'firma',
            filter: {
                type: 'string'
            },
            hidden: false,
            filterable: true
        }, {
            header: Lang.plyn,
            flex: 2,
            sortable: true,
            dataIndex: 'plyn',
            filter: {
                type: 'string'
            },
            hidden: false,
            filterable: true
        }, {
            header: Lang.opis,
            flex: 2,
            sortable: true,
            dataIndex: 'opis',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.wartosc_przydzialu.capitalise(),
            flex: 1,
            sortable: true,
            dataIndex: 'ile',
            renderer: function (v) {
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            filterable: true
        }, {
            header: Lang.stan_po_przydziale,
            flex: 1,
            sortable: true,
            dataIndex: 'stanlimitu',
            renderer: function (v) {
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            filterable: true
        }, {
            header: Lang.stan_przed_przydzialem,
            flex: 1,
            sortable: true,
            dataIndex: 'przedprzydzialem',
            renderer: function (v) {
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            filterable: true
        }, {
            header: Lang.operator,
            flex: 2,
            sortable: true,
            dataIndex: 'userimienazwisko',
            filter: {
                type: 'string'
            },
            filterable: true
        }];
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true
            },
            store: store,
            columns: cols,
            frame: false,
            border: false,
            stateId: "grid-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            },
            bbar: {
                xtype: 'pagingtoolbarexd',
                displayInfo: true,
                pagingStore: store
            }
        });
        this.grid.addListener("rowdblclick", function () {
            if (validPrivilegesOperatorComplex(this.accessPropertyName + "/update"))
                this.edytujObiekt();
        }, this);
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.store.addListener("load", function () {
            updateCzasRefreshSerwer();
        }, this);
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.taskContextMenu = new Ext.menu.Menu();
        this.taskContextMenu.add([{
            iconAlign: 'left',
            iconCls: "iconNew",
            text: Lang.nowy.capitalise(),
            scope: this,
            handler: this.dodajObiekt,
            disabled: !validPrivilegesOperatorComplex(this.accessPropertyName + "/add")
        }, {
            id: this.id + "bedit2",
            iconAlign: 'left',
            iconCls: "iconEdit",
            text: Lang.edytuj.capitalise(),
            scope: this,
            handler: this.edytujObiekt,
            disabled: !validPrivilegesOperatorComplex(this.accessPropertyName + "/update")
        }, "-", {
            iconAlign: 'left',
            iconCls: "iconPDF",
            text: Lang.pdf.capitalise(),
            scope: this,
            handler: this.getDownloadLsPdf
        }, {
            iconAlign: 'left',
            iconCls: "iconEXCEL",
            text: Lang.excel.capitalise(),
            scope: this,
            handler: this.getDownloadLsExcel
        }, "-", {
            iconAlign: 'left',
            iconCls: "iconRefresh",
            text: Lang.odswiez.capitalise(),
            scope: this,
            handler: this.refreshDatastore
        }]);
        this.grid.addListener("rowcontextmenu", function (grid, record, tr, rowIndex, e) {
            this.rowContext = grid.getStore().getAt(rowIndex);
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
        this.taskContextMenu.addListener("hide", function () {
            this.rowContext = null;
        }, this);
        this.grid.addListener("containercontextmenu", function (grid, e) {
            this.rowContext = null;
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
    },
    manageSelect: function () {
        var bedit = Ext.getCmp(this.id + "bedit");
        var bedit2 = Ext.getCmp(this.id + "bedit2");
        if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelection() == null || this.grid.getSelectionModel().getSelection().length == 0) {
            bedit.setDisabled(true);
        } else {
            var row = this.grid.getSelectionModel().getSelection()[0];
            bedit.setDisabled(!validPrivilegesOperatorComplex(this.accessPropertyName + "/update"));
        }
        if (this.rowContext == null) {
            bedit2.setDisabled(true);
        } else {
            bedit2.setDisabled(!validPrivilegesOperatorComplex(this.accessPropertyName + "/update"));
        }
    },
    refreshDatastore: function () {
        this.grid.store.load();
    },
    getDownloadLsPdf: function () {
        var defd = this.grid.getDefGridQueryCSF();
        postFormAsync("rest/zaladuneklimity/downloadExportPdfOtherList", {
            format: "pdf",
            name: Lang.zaladunki_limity,
            filters: defd.filters,
            sort: defd.sorters,
            defd: Ext.encode(defd)
        }, null);
    },
    getDownloadLsExcel: function () {
        var defd = this.grid.getDefGridQueryCSF();
        postFormAsync("rest/zaladuneklimity/downloadExportPdfOtherList", {
            format: "excel",
            name: Lang.zaladunki_limity,
            filters: defd.filters,
            sort: defd.sorters,
            defd: Ext.encode(defd)
        }, null);
    },
    dodajObiekt: function () {
        var windowObject = Ext.create('WindowZaladunekLimity', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(0);
    },
    edytujObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        var windowObject = Ext.create('WindowZaladunekLimity', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(id);
    }
});
