Ext.define('WindowOperator', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.operator.capitalise(),
    width: 950,
    height: 785,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: 'fit',
    initComponent: function (config) {
        this.cid = Ext.id();
        let formpanel = {
            title: Lang.ogolne.capitalise(),
            id: this.cid + "formpanel",
            xtype: 'form',
            frame: false,
            border: false,
            region: 'center',
            height: 550,
            scrollable: WindowExdMobileDEVICEV,
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0"
            },
            items: [{
                anchor: "0",
                layout: WindowExdMobileDEVICEV ? 'fit' : {
                    type: 'table',
                    columns: 2,
                    tableAttrs: {
                        style: {
                            width: '97%'
                        }
                    },
                    tdAttrs: {
                        valign: 'top'
                    }
                },
                frame: false,
                scrollable: WindowExdMobileDEVICEV,
                border: false,
                items: [{
                    // columnWidth : .5,
                    layout: 'anchor',
                    frame: false,
                    border: false,
                    autoWidth: true,
                    scrollable: WindowExdMobileDEVICEV,
                    labelWidth: 80,
                    defaults: {
                        anchor: "0",
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: [{
                        id: this.cid + "imie",
                        name: "imie",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.imie,
                        allowBlank: false,
                        maxLength: 16,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        id: this.cid + "nazwisko",
                        name: "nazwisko",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.nazwisko,
                        allowBlank: false,
                        maxLength: 16,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        id: this.cid + "eiid",
                        name: "eiid",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.eiid,
                        hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        id: this.cid + 'role',
                        xtype: 'comboexd',
                        store: new Ext.data.ArrayStore({
                            data: [[Const.OPERATOR_P_SERWIS, Lang.serwis], [Const.OPERATOR_P_ADMIN, Lang.administrator], [Const.OPERATOR_P_OPERATOR, Lang.operator], [Const.OPERATOR_P_RAPORT, Lang.raport], [Const.OPERATOR_P_DOSTAWCA, Lang.dostawca], [Const.OPERATOR_P_CLOUD, Lang.cloud], [Const.OPERATOR_P_CUSTOM, Lang.custom_permissions]],
                            fields: ["value", "text"]
                        }),
                        displayField: "text",
                        valueField: "value",
                        triggerAction: 'all',
                        resizable: false,
                        mode: "local",
                        editable: false,
                        fieldLabel: Lang.rola,
                        listeners: {
                            scope: this,
                            change: this.changeSelectRole
                        },
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        id: this.cid + 'dostawca',
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.dostawca,
                        hidden: !Const.ENABLELOADINGPROVIDER || AppFIRMACTX != null,
                        editable: AppOPERATOR.dostawca == null,
                        allowBlank: true
                    }, {
                        anchor: "0",
                        xtype: 'radiogroupexd',
                        id: this.cid + "active",
                        name: "active",
                        baseCls: 'x-radio-column',
                        fieldLabel: Lang.active,
                        columns: 2,
                        vertical: false,
                        items: [{
                            boxLabel: Lang.tak.capitalise(),
                            inputValue: true,
                            value: true
                        }, {
                            boxLabel: Lang.nie.capitalise(),
                            inputValue: false
                        }]
                    }, {
                        xtype: 'identyfikatorpanel',
                        fieldLabel: Lang.identyfikator,
                        labelAlign: 'left',
                        id: this.cid + "identpanel"
                    }]
                }]
            }]
        };
        let loginfields = [{
            id: this.cid + "login",
            name: "login",
            xtype: 'textfieldexd',
            fieldLabel: Const.CLOUDFLAG ? Lang.email : Lang.login,
            allowBlank: false,
            blankText: Lang.pole_nie_moze_byc_puste,
            regex: Const.CLOUDFLAG ? /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ : null,
            invalidText: Const.CLOUDFLAG ? Lang.niepoprawny_email : null
        }, {
            id: this.cid + "pass",
            name: "pass",
            xtype: 'textfieldexd',
            inputType: 'password',
            fieldLabel: Lang.haslo
        }, {
            id: this.cid + "pass2",
            name: "pass2",
            xtype: 'textfieldexd',
            inputType: 'password',
            fieldLabel: Lang.powtorz_haslo
        }];
        let opis = {
            anchor: "0",
            fieldLabel: Lang.opis,
            isFormField: true,
            id: this.cid + "opis",
            name: "opis",
            xtype: 'textareaexd'
        };
        let panelstanczytnika = {
            id: this.cid + "panelstanczytnika",
            xtype: "panelstanczytnikaexd",
            hidden: WindowExdMobileDEVICEV
        };
        if (WindowExdMobileDEVICEV)
            formpanel.items[0].items[0].items = [
                ...loginfields,
                ...formpanel.items[0].items[0].items,
                panelstanczytnika,
                opis
            ];
        else {
            formpanel.items[0].items.push({
                // columnWidth : .5,
                layout: 'anchor',
                frame: false,
                border: false,
                // bodyStyle : 'padding-left:10px;',
                labelWidth: 80,
                autoWidth: true,
                defaults: {
                    anchor: "0"
                },
                items: [...loginfields]
            });
            formpanel.items.push({
                anchor: "0",
                layout: 'column',
                frame: false,
                border: false,
                isFormField: false,
                items: [{
                    columnWidth: 0.6,
                    layout: 'anchor',
                    frame: false,
                    border: false,
                    isFormField: false,
                    height: 150,
                    style: "margin-top:10px",
                    defaults: {
                        anchor: "0"
                    },
                    items: [opis]
                }, {
                    columnWidth: 0.4,
                    xtype: 'container',
                    layout: 'fit',
                    style: 'padding-left: 15px',
                    cls: "x-ident-main-border2",
                    height: 150,
                    id: this.cid + "identpanelct",
                    items: [panelstanczytnika]
                }]
            });
        }
        this.items = [{
            xtype: 'tabpanel',
            autoWidth: true,
            border: false,
            deferredRender: false,
            anchor: '0',
            activeTab: 0,
            scrollable: true,
            items: [formpanel, {
                title: Lang.custom_permissions.capitalise(),
                frame: false,
                border: false,
                hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                anchor: "0",
                layout: {
                    type: 'vbox',
                    align: 'stretch'
                },
                height: 550,
                items: [{
                    xtype: 'grid',
                    tbar: [{
                        text: Lang.zaznacz_wszystko,
                        listeners: {
                            scope: this,
                            click: function () {
                                var editgridpanelstore = Ext.getCmp(this.cid + "privilegesgrid").getStore();
                                for (var i = 0; i < editgridpanelstore.getCount(); i++) {
                                    var r = editgridpanelstore.getAt(i);
                                    if (r.get("id") != "access.view.stany_limit_only") {
                                        if (r.get("allowchange")) {
                                            r.set("value", true);
                                        }
                                    }
                                }
                                editgridpanelstore.commitChanges();
                            }
                        }
                    }, {
                        text: Lang.odznacz_wszystko,
                        listeners: {
                            scope: this,
                            click: function () {
                                var editgridpanelstore = Ext.getCmp(this.cid + "privilegesgrid").getStore();
                                for (var i = 0; i < editgridpanelstore.getCount(); i++) {
                                    var r = editgridpanelstore.getAt(i);
                                    if (r.get("allowchange")) {
                                        r.set("value", false);
                                    }
                                }
                                editgridpanelstore.commitChanges();
                            }
                        }
                    }],
                    store: new Ext.data.Store({
                        autoLoad: false,
                        proxy: new Ext.data.MemoryProxy({}),
                        reader: new Ext.data.JsonReader({
                            id: "id",
                            rootProperty: ''
                        }, [{
                            name: 'id',
                            mapping: 'id'
                        }, {
                            name: 'nazwa',
                            mapping: 'nazwa'
                        }, {
                            name: 'value',
                            mapping: 'value'
                        }, {
                            name: 'allowchange',
                            mapping: 'allowchange'
                        }]),
                        sorters: [{
                            property: "nazwa",
                            direction: "ASC"
                        }]
                    }),
                    columns: [{
                        header: Lang.nazwa,
                        flex: 3,
                        sortable: true,
                        dataIndex: 'nazwa',
                        editable: false,
                        renderer: function (value, metaData, record, rowIndex, colIndex, store) {
                            if (!record.get("allowchange"))
                                metaData.css += " x-rlist-view-disable-drag";
                            return value;
                        }
                    }, {
                        xtype: 'checkcolumn',
                        header: Lang.przydzial,
                        dataIndex: 'value',
                        flex: 1,
                        overCls: 'x6-grid-checkcolumn-over',
                        listeners: {
                            scope: this,
                            checkchange: function () {
                                var store = Ext.getCmp(this.cid + "privilegesgrid").store;
                                store.commitChanges();
                            },
                            beforecheckchange: function (component, rowIndex, checked, record, event, eventOptions) {
                                if (!record.get('allowchange')) {
                                    return false;
                                }
                            }
                        }
                    }],
                    cls: "x-grid-own-edit-prop",
                    clicksToEdit: 1,
                    frame: true,
                    border: true,
                    id: this.cid + "privilegesgrid",
                    disabled: true,
                    flex: 1,
                    stateId: null,
                    scrollable: true,
                    stateful: false,
                    stripeRows: true,
                    enableHdMenu: false,
                    enableDragDrop: true,
                    ddGroup: this.cid + "groupColSelectedView",
                    ddText: '...',
                    selModel: {
                        selType: 'rowmodel',
                        mode: 'single'
                    },
                    listeners: {
                        scope: this
                    },
                    plugins: {
                        cellediting: {
                            clicksToEdit: 1
                        }
                    },
                    hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                }, {
                    id: this.cid + 'poziom',
                    xtype: 'comboexd',
                    store: new Ext.data.ArrayStore({
                        data: [[Const.OPERATOR_P_SERWIS, Lang.serwis], [Const.OPERATOR_P_ADMIN, Lang.administrator], [Const.OPERATOR_P_OPERATOR, Lang.operator], [Const.OPERATOR_P_SKP, Lang.operator_skp], [Const.OPERATOR_P_RAPORT, Lang.raport], [Const.OPERATOR_P_DOSTAWCA, Lang.dostawca], [Const.OPERATOR_P_NOACCESS, Lang.brak_dostepu]],
                        fields: ["value", "text"]
                    }),
                    displayField: "text",
                    valueField: "value",
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    frame: false,
                    border: false,
                    style: 'padding:10px 10px 10px 10px',
                    fieldLabel: Lang.poziom_w_sterowniku,
                    hidden: Const.SKPFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || AppFIRMACTX != null
                }]
            }, {
                title: Lang.zbiorniki.capitalise(),
                frame: false,
                border: false,
                autoWidth: true,
                scrollable: true,
                autoHeight: true,
                layout: 'fit',
                id: this.cid + 'zbiornikitab',
                height: 550,
                hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                items: [Ext.create('OperatorZbiornikiUprawnieniaDefCmp', {
                    bodyStyle: 'padding:10px 10px 10px 10px',
                    id: this.cid + 'zbiornikiuprawnienia',
                    scrollable: WindowExdMobileDEVICEV
                })]
            }, {
                title: Lang.powiadomienia_email,
                frame: false,
                border: false,
                autoWidth: true,
                scrollable: true,
                autoHeight: true,
                autoDestroy: false,
                layout: 'fit',
                id: this.cid + 'emailtab',
                height: 550,
                hidden: true,
                disabled: true,
                items: [Ext.create('PanelOperatorEmailConfig', {
                    bodyStyle: 'padding:10px 10px 10px 10px',
                    id: this.cid + 'emailconfig'
                })]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex("access.manage.operatorzy")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.obiektid = obiektid;
        this.obiekt = null;
        this.parentOp = null;
        sendAjaxDataJsonRpcL("rest/operators/getObjectForEdit", {
            id: AppOPERATOR.id
        }, this, function (paretntOpData) {
            if (paretntOpData.status) {
                this.parentOp = paretntOpData.data;
                sendAjaxDataJsonRpcL("rest/operators/getObjectForEdit", {
                    id: this.obiektid
                }, this, function (data) {
                    if (data.status) {
                        this.obiekt = data.data;
                        this.show();
                        this.center();
                        this.setKeyMap({
                            'ESC': {
                                scope: this,
                                handler: this.closeOp
                            }
                        });
                        var firma = null;
                        if (this.obiekt && this.obiekt.firma) {
                            firma = this.obiekt.firma;
                        }
                        sendAjaxDataJsonRpcL("rest/zbiorniki/getList", {
                            firma: firma
                        }, this, function (zbdata) {
                            if (zbdata.status) {
                                var zbiorniks = [];
                                if (this.obiekt) {
                                    zbiorniks = this.obiekt.zbiorniks;
                                }
                                this.zbiorniksSelected = zbiorniks;
                                this.zbiornikData = zbdata.data;
                                var zbiornikiuprawnienia = Ext.getCmp(this.cid + 'zbiornikiuprawnienia');
                                if (this.parentOp.zbiorniks && this.parentOp.zbiorniks.length > 0) {
                                    zbiornikiuprawnienia.loadData(this.zbiornikData, this.zbiorniksSelected, true);
                                } else {
                                    zbiornikiuprawnienia.loadData(this.zbiornikData, this.zbiorniksSelected);
                                }
                                if (this.obiekt && this.obiekt.notificationConfig) {
                                    Ext.getCmp(this.cid + "emailconfig").loadNotificationConfig(this.obiekt.notificationConfig, this.zbiornikData);
                                } else {
                                    Ext.getCmp(this.cid + "emailconfig").loadZbiornikData(this.zbiornikData, []);
                                }
                            } else {
                                Ext.MessageBox.alert(Lang.error, zbdata.error);
                            }
                        });
                        Ext.getCmp(this.cid + "imie").setValue(null);
                        Ext.getCmp(this.cid + "imie").clearInvalid();
                        Ext.getCmp(this.cid + "nazwisko").setValue(null);
                        Ext.getCmp(this.cid + "nazwisko").clearInvalid();
                        Ext.getCmp(this.cid + "eiid").setValue(null);
                        Ext.getCmp(this.cid + "eiid").clearInvalid();
                        Ext.getCmp(this.cid + "login").setValue(null);
                        Ext.getCmp(this.cid + "login").clearInvalid();
                        Ext.getCmp(this.cid + "pass").setValue(null);
                        Ext.getCmp(this.cid + "pass").clearInvalid();
                        Ext.getCmp(this.cid + "pass2").setValue(null);
                        Ext.getCmp(this.cid + "pass2").clearInvalid();
                        Ext.getCmp(this.cid + "active").setValue2(true);
                        Ext.getCmp(this.cid + "active").clearInvalid();
                        Ext.getCmp(this.cid + "opis").setValue(null);
                        Ext.getCmp(this.cid + "opis").clearInvalid();
                        var store = Ext.getCmp(this.cid + "role").getStore();
                        var recordsToDelete = Ext.Array.filter(store.getData().items, function (item, index) {
                            return item.data.value < AppOPERATOR.role;
                        });
                        store.remove(recordsToDelete);
                        if (!AppOPERATORROLA == Const.OPERATOR_P_SERWIS) {
                            this.removeRolesForOperatorWithFirm([Const.OPERATOR_P_SERWIS]);
                        }
                        if (Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG) {
                            this.removeRolesForOperatorWithFirm([Const.OPERATOR_P_CUSTOM]);
                        }
                        if (Const.ENTERPRISEFLAG && (AppFIRMACTX != null || (this.obiekt && this.obiekt.firma != null))) {
                            this.removeRolesForOperatorWithFirm([Const.OPERATOR_P_SERWIS, Const.OPERATOR_P_ADMIN, Const.OPERATOR_P_DOSTAWCA]);
                        }
                        if (!Const.CLOUDFLAG) {
                            this.removeRolesForOperatorWithFirm([Const.OPERATOR_P_CLOUD]);
                        }
                        if (Const.STARTERFLAG) {
                            this.removeRolesForOperatorWithFirm([Const.OPERATOR_P_DOSTAWCA, Const.OPERATOR_P_CLOUD, Const.OPERATOR_P_RAPORT, Const.OPERATOR_P_OPERATOR]);
                        }
                        if (this.obiekt != null) {
                            Ext.getCmp(this.cid + "imie").setValue(this.obiekt.imie);
                            Ext.getCmp(this.cid + "nazwisko").setValue(this.obiekt.nazwisko);
                            Ext.getCmp(this.cid + "eiid").setValue(this.obiekt.eiid);
                            Ext.getCmp(this.cid + "login").setValue(this.obiekt.login);
                            Ext.getCmp(this.cid + "pass").setValue(this.obiekt.pass);
                            Ext.getCmp(this.cid + "pass2").setValue(this.obiekt.pass2);
                            Ext.getCmp(this.cid + "active").setValue2(this.obiekt.active);
                            Ext.getCmp(this.cid + "opis").setValue(this.obiekt.opis);
                            Ext.getCmp(this.cid + "poziom").setValue2(this.obiekt.poziom);
                            Ext.getCmp(this.cid + "role").setValue2(this.obiekt.role);
                            Ext.getCmp(this.cid + "dostawca").setValue(this.obiekt.dostawca)
                            if (Const.ENTERPRISEFLAG) {
                                Ext.getCmp(this.cid + "poziom").setHidden(this.obiekt.firma != null);
                            }
                            this.changeSelectRole();
                            this.identPanel = Ext.getCmp(this.cid + "identpanel");
                            this.identPanel.init({
                                obiektid: obiektid,
                                objectt: "operator",
                                object: this.obiekt,
                                readerPanel: Ext.getCmp(this.cid + 'panelstanczytnika')
                            });
                            if (this.obiekt.notificationParams) {
                                Ext.getCmp(this.cid + "emailconfig").loadNotificationParams(this.obiekt.notificationParams);
                            }
                            if (AppOPERATOR.id == this.obiekt.id || AppOPERATORROLA == Const.OPERATOR_P_SERWIS) {
                                Ext.getCmp(this.cid + "emailtab").tab.show();
                                Ext.getCmp(this.cid + "emailtab").enable();
                            }
                        } else {
                            Ext.getCmp(this.cid + "poziom").setValue2(Const.OPERATOR_P_NOACCESS);
                            Ext.getCmp(this.cid + "role").setValue2(Const.OPERATOR_P_RAPORT);
                            this.changeSelectRole();
                            this.identPanel = Ext.getCmp(this.cid + "identpanel");
                            this.identPanel.init({
                                obiektid: obiektid,
                                objectt: "operator",
                                readerPanel: Ext.getCmp(this.cid + 'panelstanczytnika')
                            });
                        }
                        var privileges = {};
                        if (this.obiekt != null) {
                            privileges = this.obiekt.privileges;
                        }
                        var limPrivFirma = (Const.ENTERPRISEFLAG && ((this.obiekt && this.obiekt.firma) || AppFIRMACTX != null)) && !Const.CLOUDFLAG;
                        sendAjaxDataJsonRpcL("rest/systemreq/getDefaultPriviligiesComplex", {
                            limitPrivilegesFirma: limPrivFirma
                        }, this, function (defPrivData) {
                            if (defPrivData.status && defPrivData.data != null) {
                                var prvMapAll = defPrivData.data;
                                var prvMapLangAll = this.getValidPrivilegesOperatorComplexMapLang(defPrivData.data);
                                Ext.applyIf(privileges, prvMapAll);
                                var privilegesmaplist = [];
                                Ext.iterate(privileges, function (k, v, o) {
                                    if (prvMapAll[k] !== undefined && validPrivilegesOperatorComplex(k)) {
                                        if (k.indexOf("limit_only") > -1 && Const.ENTERPRISEFLAG && ((this.obiekt && !this.obiekt.firma) || (!this.obiekt && AppFIRMACTX == null)))
                                            return;
                                        if (k.indexOf("dziennik") > -1 && Const.ENTERPRISEFLAG && (AppFIRMACTX != null || (this.obiekt && this.obiekt.firma)))
                                            return;
                                        if (k.indexOf("plyn") > -1 && Const.ENTERPRISEFLAG && (AppFIRMACTX != null || (this.obiekt && this.obiekt.firma)))
                                            return;
                                        var dv = privileges[k];
                                        var al = validPrivilegesOperatorComplex(k);
                                        if (k.indexOf("limit_only") > -1) {
                                            if (validPrivilegesOperatorComplex(k) && (Const.ENTERPRISEFLAG && isValidOperatorFirmaRaw())) {
                                                dv = true;
                                                al = false;
                                            }
                                        }
                                        privilegesmaplist.push({
                                            id: k,
                                            nazwa: prvMapLangAll[k] == null ? k : prvMapLangAll[k],
                                            value: dv,
                                            allowchange: al
                                        });
                                    }
                                }, this);
                                var privilegesgrid = Ext.getCmp(this.cid + "privilegesgrid");
                                privilegesgrid.store.loadData(privilegesmaplist);
                            } else {
                                Ext.MessageBox.alert(Lang.error, zbdata.error);
                            }
                        });
                        var title = Lang.operator.capitalise();
                        if (AppFIRMACTX != null)
                            title += " (" + AppFIRMACTX.nazwa + ")";
                        this.setTitle(title);
                    } else {
                        Ext.MessageBox.alert(Lang.error, data.error);
                    }
                });
            }
        });
    },
    saveOp: function () {
        var canWrite = false;
        if (Ext.getCmp(this.cid + "imie").isValid()) {
            if (Ext.getCmp(this.cid + "nazwisko").isValid()) {
                if (Ext.getCmp(this.cid + "login").isValid()) {
                    canWrite = true;
                }
            }
        }
        if (Ext.getCmp(this.cid + "pass").getValue().trim() != "") {
            if (Ext.getCmp(this.cid + "pass").getValue() != Ext.getCmp(this.cid + "pass2").getValue()) {
                canWrite = false;
                Ext.MessageBox.alert(Lang.error, Lang.puste_haslo_lub_bledna_weryfikacja);
                Ext.getCmp(this.cid + "pass").markInvalid(Lang.puste_haslo_lub_bledna_weryfikacja);
                Ext.getCmp(this.cid + "pass2").markInvalid(Lang.puste_haslo_lub_bledna_weryfikacja);
                return;
            }
        } else if (this.obiekt == null) {
            canWrite = false;
            Ext.MessageBox.alert(Lang.error, Lang.puste_haslo_lub_bledna_weryfikacja);
            Ext.getCmp(this.cid + "pass").markInvalid(Lang.puste_haslo_lub_bledna_weryfikacja);
            Ext.getCmp(this.cid + "pass2").markInvalid(Lang.puste_haslo_lub_bledna_weryfikacja);
            return;
        }
        var identUserOdbiorcaPojazdError = this.identPanel.isValidOtherErrorIf();
        if (identUserOdbiorcaPojazdError != null) {
            Ext.MessageBox.alert(Lang.error, identUserOdbiorcaPojazdError);
            return;
        }
        var vii = this.identPanel.getIdentData();
        if (vii && vii.identyfikator == "")
            vii.identyfikator = null;
        if (Ext.getCmp(this.cid + 'role').getSelection() == null) {
            canWrite = false;
            Ext.getCmp(this.cid + "role").markInvalid(Lang.pole_nie_moze_byc_puste);
        }
        var emailconfig = Ext.getCmp(this.cid + "emailconfig").generateNotificationConfig();
        if (typeof (emailconfig) == "string") {
            Ext.MessageBox.alert(Lang.error, emailconfig);
            canWrite = false;
        }
        if (!Ext.getCmp(this.cid + 'zbiornikiuprawnienia').validateZbiorniks()) {
            Ext.MessageBox.alert(Lang.error, Lang.wybierz_przynajmniej_jeden_zbiornik);
            return;
        }
        if (canWrite) {
            var privilegesmap = {};
            var privilegesgrid = Ext.getCmp(this.cid + "privilegesgrid");
            var sprivilegesgrid = privilegesgrid.store;
            for (var i = 0; i < sprivilegesgrid.getCount(); i++) {
                var row = sprivilegesgrid.getAt(i);
                if (validPrivilegesOperatorComplex(row.id)) {
                    privilegesmap[row.id] = row.get("value");
                } else {
                    privilegesmap[row.id] = this.obiekt != null ? this.obiekt.privileges[row.id] : false;
                }
            }
            var zbiorniks = Ext.getCmp(this.cid + 'zbiornikiuprawnienia').getSelectedZbiorniks();
            var login = Ext.getCmp(this.cid + "login").getValue().trim();
            sendAjaxDataJsonRpcL("rest/operators/saveObject", {
                id: this.obiektid,
                imie: Ext.getCmp(this.cid + "imie").getValue(),
                nazwisko: Ext.getCmp(this.cid + "nazwisko").getValue(),
                eiid: Ext.getCmp(this.cid + "eiid").getValue(),
                login: login,
                pass: Ext.getCmp(this.cid + "pass").getValue().trim() == "" ? null : Ext.getCmp(this.cid + "pass").getValue().trim(),
                active: Ext.getCmp(this.cid + "active").getValue2(),
                poziom: Ext.getCmp(this.cid + "poziom").getValue(),
                role: Ext.getCmp(this.cid + 'role').getValue(),
                opis: Ext.getCmp(this.cid + "opis").getValue(),
                dostawca: Ext.getCmp(this.cid + "dostawca").getValue().trim() == "" ? null : Ext.getCmp(this.cid + "dostawca").getValue().trim(),
                identyfikator: vii.identyfikator,
                identtype: vii.identtype,
                pin: vii.pin,
                zbiorniks: zbiorniks,
                data: {
                    privileges: privilegesmap
                },
                emailconfig: this.obiekt == null ? null : emailconfig,
                optlockversion: this.obiekt == null ? null : this.obiekt.optlockversion
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    this.obiektid = data.id;
                    this.identPanel.close();
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                    if (data.error == Lang.identyfikator_uzywany) {
                        this.identPanel.markIdAsUsed();
                    }
                    return;
                }
                if (this.callbackF)
                    this.callbackF();
            }, 0);
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    changeSelectRole: function () {
        var vv = Ext.getCmp(this.cid + 'role').getValue();
        Ext.getCmp(this.cid + "privilegesgrid").setDisabled(vv != Const.OPERATOR_P_CUSTOM);
        if (vv == Const.OPERATOR_P_SERWIS) {
            Ext.getCmp(this.cid + 'zbiornikitab').tab.hide();
        } else if (!Const.BASICFLAG && !Const.OPTIMALFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG) {
            Ext.getCmp(this.cid + 'zbiornikitab').tab.show();
        }
        Ext.getCmp(this.cid + "zbiornikiuprawnienia").setDisabled(vv == Const.OPERATOR_P_SERWIS);
        Ext.getCmp(this.cid + "poziom").setDisabled(vv != Const.OPERATOR_P_CUSTOM);
    },
    removeRolesForOperatorWithFirm: function (rolesToDelete) {
        if (rolesToDelete == null || rolesToDelete.length == 0) {
            return;
        }
        var store = Ext.getCmp(this.cid + "role").getStore();
        var recordsToDelete = Ext.Array.filter(store.getData().items, function (item, index) {
            var del = false;
            rolesToDelete.forEach(function (roleToDelete) {
                if (roleToDelete == item.data.value) {
                    del = true;
                }
            })
            return del;
        });
        store.remove(recordsToDelete);
    },
    getValidPrivilegesOperatorComplexMapLang: function (privmap) {
        var privlang = {};
        Ext.iterate(privmap, function (k, v, o) {
            var langkey = k.replace(/\./g, "_");
            privlang[k] = Lang[langkey];
        });
        return privlang;
    },
    closeOp: function () {
        this.identPanel.close();
        this.close();
    }
});
