Ext.ariaWarn = Ext.emptyFn;
Ext.data.reader.Reader.prototype.messageProperty = "msg";
window._HandleStandardExceptionCToServer_AJAX_URL = null;
window._HandleRedirectDispatcherURL = "redirector";
window._HandleEventObservable = new Ext.util.Observable({});
window._HandleLangCommunicationError = "Internal Error";
window._HandleLangError = "Error";
window._HandleEventByWindowOnError = true;
window._HandleStandardExceptionAlert = true;
window._HandleStandardExceptionReloadPage = false;
window._HandleStandardExceptionAlertOnError = true;
window._HandleStandardExceptionReloadPageOnError = false;

if (_HandleEventByWindowOnError) {
    Ext.Error.handle = function (err) {
        if (err.sourceClass == "Ext.JSON" && err.sourceClass == "decode") {
            return true;
        }
        if (err.msg.indexOf("You're trying to decode an invalid JSON String") >= 0) {
            return true;
        }
        if (err.msg.indexOf("A Sorter requires either a property or a sorterFn") >= 0) {
            return true;
        }
        var msg = err.sourceClass + "/" + err.msg;
        var url = window.location + "/" + err.sourceClass;
        var line = err.sourceClass;
        if (console && console.log) {
            console.log("Internal error. Please reload this page. " + "Error: " + msg + "\nUrl: " + url + "\nLine #: " + line);
        }
        if (_HandleStandardExceptionAlertOnError) {
            alert("Internal error. Please reload this page. " + "Error: " + msg + "\nUrl: " + url + "\nLine #: " + line);
        }
        handleStandardExceptionCToServer(msg, url, line);
        if (_HandleStandardExceptionReloadPageOnError) {
            window.location.reload();
        }
        return true;
    };
    window.onerror = function (msg, url, line) {
        if (console && console.log) {
            console.log("Internal error. Please reload this page: " + "Error: " + msg + "\nUrl: " + url + "\nLine #: " + line);
        }
        if (_HandleStandardExceptionAlertOnError) {
            alert("Internal error. Please reload this page. " + "Error: " + msg + "\nUrl: " + url + "\nLine #: " + line);
        }
        handleStandardExceptionCToServer(msg, url, line);
        if (_HandleStandardExceptionReloadPageOnError) {
            window.location.reload();
        }
        return false;
    };
}

window.handleStandardExceptionC = (callbackOrE, scope, defaultvalue) => {
    if (Ext.isFunction(callbackOrE)) {
        try {
            var retv = callbackOrE.call(scope);
            return retv;
        } catch (exp) {
            var etxt = exp + ":" + printStackTrace({
                e: exp
            });
            if (console && console.exception) {
                console.exception(exp);
            } else if (console && console.log) {
                console.log("Internal error: " + etxt);
            }
            if (_HandleStandardExceptionAlert) {
                alert("Internal error. Please reload this page. " + "Error: " + etxt);
            }
            handleStandardExceptionCToServer(etxt, window.location, "UNKNOWN");
            if (_HandleStandardExceptionReloadPage) {
                window.location.reload();
            }
            return defaultvalue;
        }
    } else {
        var etxt = callbackOrE + ":" + printStackTrace({
            e: callbackOrE
        });
        if (console && console.exception) {
            console.exception(callbackOrE);
        } else if (console && console.log) {
            console.log("Internal error: " + etxt);
        }
        if (_HandleStandardExceptionAlert) {
            alert("Internal error. Please reload this page. " + "Error: " + etxt);
        }
        handleStandardExceptionCToServer(etxt, window.location, "UNKNOWN");
        if (_HandleStandardExceptionReloadPage) {
            window.location.reload();
        }
    }
}

window.handleStandardExceptionCToServer = (msg, url, line) => {
    var params = {};
    params.msg = msg;
    params.url = url.toString();
    params.line = line;
    params.uurl = window.location.toString();
    if (_HandleStandardExceptionCToServer_AJAX_URL) {
        sendAjaxDataJsonRpcL(_HandleStandardExceptionCToServer_AJAX_URL, params, this, function (data) {
        }, null);
    }
}

window.handleRedirectionSignWerror = () => {
    window.location = _HandleRedirectDispatcherURL;
}

window.handleCommonCallBackAjaxRPCError = function (data) {
    if (Ext.isArray(data)) {
        var errora = "";
        for (var i = 0; i < data.length; i++) {
            errora += data.error + "; ";
        }
        Ext.MessageBox.alert(_HandleLangError, errora);
    } else if (Ext.isObject(data)) {
        Ext.MessageBox.alert(_HandleLangError, data.error);
    } else {
        Ext.MessageBox.alert(_HandleLangError, data);
    }
};

window.handlGridLoadStoreCheckJsonRpcL = (s, records, successful, eOpts) => {
    if (!successful) {
        if (eOpts.aborted) {
            var data = {
                data: null,
                status: true,
                success: true,
                resign: false,
                security: false,
                error: "OK",
                msg: "OK"
            };
            return {
                s: data.status,
                data: data,
                operation: eOpts,
                eOpts: eOpts
            };
        }
        var rsp = null;
        if (eOpts._response) {
            rsp = eOpts._response;
        }
        if (eOpts.error && eOpts.error.response) {
            rsp = eOpts.error.response;
        }
        return handlGridLoadExceptionJsonRpcL(rsp, rsp, eOpts);
    } else {
        var data = {
            data: null,
            status: true,
            success: true,
            resign: false,
            security: false,
            error: "OK",
            msg: "OK"
        };
        return {
            s: data.status,
            data: data,
            operation: eOpts,
            eOpts: eOpts
        };
    }
}

window.handlGridLoadExceptionJsonRpcL = (response, operation, eOpts) => {
    var ocallarguments = arguments;
    if (operation.status == 200) {
        var data = {
            data: null,
            status: false,
            success: false,
            resign: false,
            security: false,
            error: null,
            msg: null
        };
        try {
            data.data = Ext.decode(operation.responseText);
        } catch (exception) {
            data.status = false;
            data.success = false;
            data.error = exception + ": " + operation.responseText;
            data.msg = exception + ": " + operation.responseText;
        }
        if (!data.status && data.resign) {
            handleRedirectionSignWerror(this, null, ocallarguments, arguments);
        }
        return {
            s: data.status,
            data: data,
            operation: operation,
            eOpts: eOpts
        };
    } else {
        var data = {
            data: null,
            status: false,
            success: false,
            resign: false,
            security: false,
            error: null,
            msg: null
        };
        if (response.status == 0) {
            data.error = _HandleLangCommunicationError;
            data.msg = _HandleLangCommunicationError;
        } else {
            var errorMsg = null;
            try {
                errorMsg = Ext.decode(operation.responseText);
                if (errorMsg && errorMsg.message) {
                    errorMsg = errorMsg.message;
                }
                if (errorMsg && errorMsg.error) {
                    errorMsg = errorMsg.error;
                }
                if (errorMsg && errorMsg.msg) {
                    errorMsg = errorMsg.msg;
                }
            } catch (exception) {
                errorMsg = exception + ": " + operation.responseText;
            }
            if (!errorMsg) {
                data.error = (operation.statusText == "" ? _HandleLangCommunicationError : operation.statusText) + (operation.status == 0 ? "" : (": " + operation.status));
                data.msg = (operation.statusText == "" ? _HandleLangCommunicationError : operation.statusText) + (operation.status == 0 ? "" : (": " + operation.status));
            } else {
                data.error = errorMsg;
                data.msg = errorMsg;
            }
            if (operation.responseJson && operation.responseJson != "" && data.error.includes("undefined")) {
                data.error = operation.responseJson;
                data.msg = operation.responseJson;
            }
            if (operation.status == 401) {
                data.resign = true;
                data.security = true;
            }
        }
        if (!data.status && data.resign) {
            handleRedirectionSignWerror(this, null, ocallarguments, arguments);
        }
        return {
            s: data.status,
            data: data,
            operation: operation,
            eOpts: eOpts
        };
    }
}

window.sendAjaxDataJsonRpcL = function (url, params, scope, callbackFn, callbackError) {
    var ocallarguments = arguments;
    if (callbackError == null)
        callbackError = handleCommonCallBackAjaxRPCError;
    if (callbackError == 0)
        callbackError = null;
    scope = scope || window;
    url = url + (url.indexOf("?") >= 0 ? "&rnd=" : "?rnd=") + Math.random();
    if (params && params.aop) {
        url = url + "&aop=" + params.aop;
    }
    var method = "POST";
    if (!params) {
        params = {};
        method = "GET";
    }
    var autoredirect = true;
    if (params && params._CancelAutoRedirect) {
        autoredirect = false;
    }
    var reqp = {
        url: url,
        jsonData: mapNullValuesEncodeOrDeleteAndMergeData(params).data,
        method: method,
        disableCache: true,
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        callback: function (opts, success, response) {
            if (success) {
                if (callbackFn == null) {
                    return;
                }
                var data = {
                    data: null,
                    status: true,
                    success: true,
                    resign: false,
                    security: false,
                    error: null,
                    msg: null
                };
                try {
                    data.data = Ext.decode(response.responseText);
                } catch (exception) {
                    data.status = false;
                    data.success = false;
                    data.error = exception + ": " + response.responseText;
                    data.msg = exception + ": " + response.responseText;
                }
                if (!data.success && callbackError)
                    handleStandardExceptionC(function () {
                        callbackError.call(scope, data, data.data, data.success, response.responseText);
                    }, null, null);
                else
                    handleStandardExceptionC(function () {
                        callbackFn.call(scope, data, data.data, data.success, response.responseText);
                    }, null, null);
            } else {
                var data = {
                    data: null,
                    status: false,
                    success: false,
                    resign: false,
                    security: false
                };
                if (response.status == 0) {
                    data.error = _HandleLangCommunicationError;
                    data.msg = _HandleLangCommunicationError;
                } else {
                    var errorMsg = null;
                    try {
                        errorMsg = Ext.decode(response.responseText);
                        if (errorMsg && errorMsg.message) {
                            errorMsg = errorMsg.message;
                        }
                        if (errorMsg && errorMsg.error) {
                            errorMsg = errorMsg.error;
                        }
                        if (errorMsg && errorMsg.msg) {
                            errorMsg = errorMsg.msg;
                        }
                    } catch (exception) {
                        errorMsg = exception + ": " + response.responseText;
                    }
                    if (!errorMsg) {
                        data.error = (response.statusText == "" ? _HandleLangCommunicationError : response.statusText) + (response.status == 0 ? "" : (": " + response.status));
                        data.msg = (response.statusText == "" ? _HandleLangCommunicationError : response.statusText) + (response.status == 0 ? "" : (": " + response.status));
                    } else {
                        data.error = errorMsg;
                        data.msg = errorMsg;
                    }
                    if (response.responseJson && response.responseJson != "" && data.error.includes("undefined")) {
                        data.error = response.responseJson;
                        data.msg = response.responseJson;
                    }
                    if (response.status == 401) {
                        data.resign = true;
                        data.security = true;
                    }
                }
                if (autoredirect && !data.status && data.resign) {
                    handleRedirectionSignWerror(scope || this, "sendAjaxDataJsonRpcL", ocallarguments, arguments);
                    return;
                }
                if (!data.success && callbackError)
                    handleStandardExceptionC(function () {
                        callbackError.call(scope, data, data.data, data.success, response.responseText);
                    }, null, null);
                else if (callbackFn != null)
                    handleStandardExceptionC(function () {
                        callbackFn.call(scope, data, data.data, data.success, response.responseText);
                    }, null, null);
            }
        }
    };
    Ext.Ajax.request(reqp);
}

/*
    Po stronie serwera oprócz kodu trzeba zwrócić to co niżej by poszło albo w formacie application/json
        response.setStatus(200);
        response.setContentType("text/html");
        response.getWriter().println("<html><body>\n{\"success\":true}\n</body></html>");
        lub
        return ResponseEntity.ok(Map.of("success", true));
 */
window.sendAjaxFormJsonRpcL = (url, params, formobj, scope, callbackFn, callbackError, alertErrors) => {
    var ocallarguments = arguments;
    if (callbackError == null)
        callbackError = handleCommonCallBackAjaxRPCError;
    if (callbackError == 0)
        callbackError = null;
    scope = scope || window;
    url = url + (url.indexOf("?") >= 0 ? "&rnd=" : "?rnd=") + Math.random();
    if (params && params.aop) {
        url = url + "&aop=" + params.aop;
    } else if (!params) {
        params = {};
    }
    formobj.doAction(formobj.standardSubmit ? 'standardsubmit' : formobj.api ? 'directsubmit' : 'submit', {
        url: url,
        params: mapNullValuesEncodeOrDeleteAndMergeData(params),
        clientValidation: false,
        success: function (form, action) {
            var success = true;
            if (success) {
                if (callbackFn == null) {
                    return;
                }
                var data = {
                    data: null,
                    status: true,
                    success: true,
                    resign: false,
                    security: false,
                    error: null,
                    msg: null
                };
                try {
                    data.data = Ext.decode(action.response.responseText);
                } catch (exception) {
                    data.status = false;
                    data.success = false;
                    data.error = exception + ": " + action.response.responseText;
                    data.msg = exception + ": " + action.response.responseText;
                }
                if (!data.success && callbackError)
                    handleStandardExceptionC(function () {
                        callbackError.call(scope, data, data.data, data.success, action.response.responseText);
                    }, null, null);
                else
                    handleStandardExceptionC(function () {
                        callbackFn.call(scope, data, data.data, data.success, action.response.responseText);
                    }, null, null);
            } else {
                var data = {
                    data: null,
                    status: false,
                    success: false,
                    resign: false,
                    security: false
                };
                if (action.response.status == 0) {
                    data.error = _HandleLangCommunicationError;
                    data.msg = _HandleLangCommunicationError;
                } else {
                    var errorMsg = null;
                    try {
                        errorMsg = Ext.decode(action.response.responseText);
                        if (errorMsg && errorMsg.message) {
                            errorMsg = errorMsg.message;
                        }
                        if (errorMsg && errorMsg.error) {
                            errorMsg = errorMsg.error;
                        }
                        if (errorMsg && errorMsg.msg) {
                            errorMsg = errorMsg.msg;
                        }
                    } catch (exception) {
                        errorMsg = exception + ": " + action.response.responseText;
                    }
                    if (!errorMsg) {
                        data.error = (action.response.statusText == "" ? _HandleLangCommunicationError : action.response.statusText) + (action.response.status == 0 ? "" : (": " + action.response.status));
                        data.msg = (action.response.statusText == "" ? _HandleLangCommunicationError : action.response.statusText) + (action.response.status == 0 ? "" : (": " + action.response.status));
                    } else {
                        data.error = errorMsg;
                        data.msg = errorMsg;
                    }
                    if (action.response.responseJson && action.response.responseJson != "" && data.error.includes("undefined")) {
                        data.error = action.response.responseJson;
                        data.msg = action.response.responseJson;
                    }
                    if (action.response.status == 401) {
                        data.resign = true;
                        data.security = true;
                    }
                }
                if (!data.status && data.resign) {
                    handleRedirectionSignWerror(scope || this, "sendAjaxDataJsonRpcL", ocallarguments, arguments);
                    return;
                }
                if (!data.success && callbackError)
                    handleStandardExceptionC(function () {
                        callbackError.call(scope, data, data.data, data.success, action.response.responseText);
                    }, null, null);
                else if (callbackFn != null)
                    handleStandardExceptionC(function () {
                        callbackFn.call(scope, data, data.data, data.success, action.response.responseText);
                    }, null, null);
            }
        },
        failure: function (form, action) {
            switch (action.failureType) {
                case Ext.form.action.Action.CLIENT_INVALID:
                    if (callbackFn != null) {
                        var data = {
                            data: null,
                            status: false,
                            success: false,
                            resign: false,
                            security: false,
                            error: "Ext.form.action.Action.CLIENT_INVALID",
                            msg: "Ext.form.action.Action.CLIENT_INVALID"
                        };
                        if (callbackError != null)
                            handleStandardExceptionC(function () {
                                callbackError.call(scope, data, data.data, false, null);
                            }, null, null);
                        else if (callbackFn != null)
                            handleStandardExceptionC(function () {
                                callbackFn.call(scope, data, data.data, false, null);
                            }, null, null);
                    }
                    break;
                case Ext.form.action.Action.CONNECT_FAILURE:
                    if (callbackFn != null) {
                        var data = {
                            data: null,
                            status: false,
                            success: false,
                            resign: false,
                            security: false,
                            error: "Ext.form.action.Action.CONNECT_FAILURE",
                            msg: "Ext.form.action.Action.CONNECT_FAILURE"
                        };
                        if (callbackError != null)
                            handleStandardExceptionC(function () {
                                callbackError.call(scope, data, data.data, false, null);
                            }, null, null);
                        else if (callbackFn != null)
                            handleStandardExceptionC(function () {
                                callbackFn.call(scope, data, data.data, false, null);
                            }, null, null);
                    }
                    break;
                case Ext.form.action.Action.SERVER_INVALID:
                    if (!Ext.isObject(action.result)) {
                        var data = {
                            data: null,
                            status: false,
                            success: false,
                            resign: false,
                            security: false,
                            error: action.response.responseText,
                            msg: action.response.responseText
                        };
                        if (callbackError != null)
                            handleStandardExceptionC(function () {
                                callbackError.call(scope, data, data.data, false, action.response.responseText);
                            }, null, null);
                        else if (callbackFn != null)
                            handleStandardExceptionC(function () {
                                callbackFn.call(scope, data, data.data, false, action.response.responseText);
                            }, null, null);
                    } else {
                        var data = {
                            data: null,
                            status: false,
                            success: false,
                            resign: false,
                            security: false,
                            error: action.response.responseText,
                            msg: action.response.responseText
                        };
                        try {
                            data = Ext.decode(action.response.responseText);
                        } catch (exception) {
                            success = false;
                            data.status = false;
                            data.success = false;
                            data.error = exception + ": " + action.response.responseText;
                            data.msg = exception + ": " + action.response.responseText;
                        }
                        if (callbackError != null)
                            handleStandardExceptionC(function () {
                                callbackError.call(scope, data, data.data, false, action.response.responseText);
                            }, null, null);
                        else if (callbackFn != null)
                            handleStandardExceptionC(function () {
                                callbackFn.call(scope, data, data.data, false, action.response.responseText);
                            }, null, null);
                    }
                    break;
            }
        }
    });
}

window.performAjaxLPromise = (url, params) => {
    return new Promise(function (resolve, reject) {
        sendAjaxDataJsonRpcL(url, params, null, function (data, datad, status) {
            resolve(datad, data, status);
        }, function (data, datad, status) {
            reject(data);
        });
    });
}

window.sendAjaxDataJsonRpcLGroup = (urla, paramsa, scopeg, callbackFng, callbackErrorg, alertErrorsg) => {
    var requestpa = new Array(urla.length);
    var dataa = new Array(urla.length);
    var datada = new Array(urla.length);
    var statusa = new Array(urla.length);
    var responseTexta = new Array(urla.length);
    var callCounter = 0;
    var callbackFunction = function (data, datad, status, responseText, index) {
        dataa[index] = data;
        datada[index] = datad;
        statusa[index] = status;
        responseTexta[index] = responseText;
        callCounter++;
        if (callCounter == urla.length) {
            var allStatusaOK = true;
            for (var i = 0; i < urla.length; i++) {
                if (!statusa[i]) {
                    allStatusaOK = false;
                }
            }
            if (allStatusaOK) {
                handleStandardExceptionC(function () {
                    callbackFng.call(scopeg, allStatusaOK, dataa, datada, statusa, responseTexta);
                }, null, null);
            } else {
                if (callbackErrorg == null) {
                    callbackErrorg = handleCommonCallBackAjaxRPCError;
                } else if (callbackErrorg == 0) {
                    callbackErrorg = null;
                }
                if (callbackErrorg) {
                    callbackErrorg.call(scopeg, allStatusaOK, dataa, datada, statusa, responseTexta);
                } else {
                    handleStandardExceptionC(function () {
                        callbackFng.call(scopeg, allStatusaOK, dataa, datada, statusa, responseTexta);
                    }, null, null);
                }
            }
        }
    };
    for (var i = 0; i < urla.length; i++) {
        var callbackFunction2 = Ext.Function.bind(callbackFunction, null, [i], true);
        sendAjaxDataJsonRpcL(urla[i], paramsa[i], null, callbackFunction2, 0, alertErrorsg);
    }
}