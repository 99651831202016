Ext.define('PanelWizardGroupChoose', {
    extend: 'Ext.panel.Panel',
    initComponent: function () {
        this.cid = Ext.id();
        Ext.apply(this, {
            layout: "fit",
            frame: false,
            title: Lang.rplgrupowanie,
            border: false,
            autoWidth: false,
            height: 457,
            autoHeight: false,
            items: [{
                xtype: 'panel',
                frame: false,
                border: false,
                autoWidth: false,
                autoHeight: false,
                layout: 'hbox',
                layoutConfig: {
                    align: "stretch"
                },
                items: [new Ext.ux.grid.GridPanelExd({
                    store: new Ext.data.Store({
                        autoLoad: false,
                        proxy: new Ext.data.MemoryProxy({}),
                        reader: new Ext.data.JsonReader({
                            id: "id",
                            rootProperty: ''
                        }, [{
                            name: 'nazwa',
                            mapping: 'nazwa'
                        }])
                    }),
                    columns: [{
                        header: Lang.nazwa,
                        flex: 1,
                        sortable: true,
                        dataIndex: 'nazwa',
                        sortable: false
                    }],
                    flex: 1,
                    title: Lang.dostepne,
                    frame: true,
                    border: true,
                    id: this.cid + "firmas_from",
                    height: 415,
                    stateId: null,
                    stateful: false,
                    stripeRows: true,
                    enableHdMenu: false,
                    selModel: {
                        selType: 'rowmodel',
                        mode: 'single'
                    },
                    listeners: {
                        scope: this,
                        rowdblclick: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            var r = grid.getSelR();
                            if (r == null)
                                return;
                            grid.store.remove(r);
                            grid2.store.add(r);
                        }
                    },
                    getSelR: function () {
                        var gridd = this;
                        if (!gridd.getSelectionModel().hasSelection()) {
                            return null;
                        } else {
                            if (gridd.getSelection() != null && gridd.getSelection().length > 0) {
                                return gridd.getSelection()[0];
                            }
                        }
                        return null;
                    }
                }), {
                    width: 50,
                    height: 415,
                    xtype: 'panel',
                    frame: false,
                    border: false,
                    layout: {
                        type: 'vbox',
                        pack: "center",
                        align: "stretch"
                    },
                    items: [{
                        xtype: 'button',
                        text: ">",
                        width: 50,
                        scope: this,
                        handler: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            var r = grid.getSelR();
                            if (r == null)
                                return;
                            grid.store.remove(r);
                            grid2.store.add(r);
                        }
                    }, {
                        xtype: 'button',
                        text: ">>",
                        width: 50,
                        scope: this,
                        handler: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            grid2.store.add(grid.store.getRange());
                            grid.store.removeAll();
                        }
                    }, {
                        xtype: 'button',
                        text: "<",
                        width: 50,
                        scope: this,
                        handler: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            var r = grid2.getSelR();
                            if (r == null)
                                return;
                            grid2.store.remove(r);
                            grid.store.add(r);
                        }
                    }, {
                        xtype: 'button',
                        text: "<<",
                        width: 50,
                        scope: this,
                        handler: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            grid.store.add(grid2.store.getRange());
                            grid2.store.removeAll();
                        }
                    }]
                }, new Ext.ux.grid.GridPanelExd({
                    store: new Ext.data.Store({
                        autoLoad: false,
                        proxy: new Ext.data.MemoryProxy({}),
                        reader: new Ext.data.JsonReader({
                            id: "id",
                            rootProperty: ''
                        }, [{
                            name: 'nazwa',
                            mapping: 'nazwa'
                        }])
                    }),
                    columns: [{
                        header: Lang.nazwa,
                        flex: 1,
                        sortable: true,
                        dataIndex: 'nazwa',
                        sortable: false
                    }],
                    flex: 1,
                    title: Lang.wybrane,
                    height: 415,
                    frame: true,
                    border: true,
                    id: this.cid + "firmas_to",
                    stateId: null,
                    stateful: false,
                    stripeRows: true,
                    enableHdMenu: false,
                    enableDragDrop: true,
                    ddGroup: this.cid + "groupColSelected",
                    ddText: '...',
                    selModel: {
                        selType: 'rowmodel',
                        mode: 'single'
                    },
                    viewConfig: {
                        plugins: {
                            gridviewdragdrop: {
                                ddGroup: this.cid + "groupColSelectedGroupChoose",
                                enableDrop: true,
                                enableDrag: true,
                                dropZone: {
                                    notifyOver: function (dd, e, data) {
                                        return this.dropAllowed;
                                    },
                                    notifyDrop: function (dd, e, data) {
                                        var grid = this.view.up("grid");
                                        var sm = grid.getSelectionModel();
                                        var rows = sm.getSelection();
                                        var node = this.view.findTargetByEvent(e);
                                        var cindex = this.view.indexOf(node);
                                        if (sm.hasSelection()) {
                                            for (i = 0; i < rows.length; i++) {
                                                grid.store.remove(grid.store.getById(rows[i].id));
                                                grid.store.insert(cindex, rows[i]);
                                            }
                                            sm.select(rows);
                                        }
                                    }
                                }
                            }
                        }
                    },
                    listeners: {
                        scope: this,
                        rowdblclick: function () {
                            var grid = Ext.getCmp(this.cid + "firmas_from");
                            var grid2 = Ext.getCmp(this.cid + "firmas_to");
                            var r = grid2.getSelR();
                            if (r == null)
                                return;
                            grid2.store.remove(r);
                            grid.store.add(r);
                        }
                    },
                    getSelR: function () {
                        var gridd = this;
                        if (!gridd.getSelectionModel().hasSelection()) {
                            return null;
                        } else {
                            if (gridd.getSelection() != null && gridd.getSelection().length > 0) {
                                return gridd.getSelection()[0];
                            }
                        }
                        return null;
                    }
                })]
            }]
        });
        this.callParent(arguments);
    },
    callAfterShow: function () {
        var langmap = this.datad.mainCtrl.getKoolumnNazwaLangMap();
        var kolumnyyD = [];
        kolumnyyD.push({
            id: "czas",
            nazwa: langmap["czas"]
        });
        if (Const.ENTERPRISEFLAG)
            kolumnyyD.push({
                id: "firma",
                nazwa: langmap["firma"]
            });
        kolumnyyD.push({
            id: "plyn",
            nazwa: langmap["plyn"]
        });
        kolumnyyD.push({
            id: "zbiornik",
            nazwa: langmap["zbiornik"]
        });
        kolumnyyD.push({
            id: "typ_tankowania",
            nazwa: langmap["typ_tankowania"]
        });
        if (this.datad.mainCtrl.repFirma == null) {
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorca",
                    nazwa: langmap["odbiorca"]
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRW",
                    nazwa: langmap["pojazdTRW"]
                });
        } else {
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorca",
                    nazwa: langmap["odbiorca"]
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRW",
                    nazwa: langmap["pojazdTRW"]
                });
        }
        if (this.datad.mainCtrl.repFirma == null) {
            if (!Const.SKPFLAG)
                if (!Const.OPTIMALFLAG)
                    if (!Const.BASICFLAG && !Const.STARTERFLAG)
                        if (!Const.STANDARDFLAG)
                            kolumnyyD.push({
                                id: "pojazdTRWRok",
                                nazwa: langmap["pojazdTRWRok"]
                            });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWIE",
                    nazwa: langmap["pojazdTRWIE"]
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorcaEIID",
                    nazwa: langmap["odbiorcaEIID"]
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWEIID",
                    nazwa: langmap["pojazdTRWEIID"]
                });
        } else {
            if (!Const.SKPFLAG)
                if (!Const.OPTIMALFLAG)
                    if (!Const.BASICFLAG && !Const.STARTERFLAG)
                        if (!Const.STANDARDFLAG)
                            kolumnyyD.push({
                                id: "pojazdTRWRok",
                                nazwa: langmap["pojazdTRWRok"]
                            });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWIE",
                    nazwa: langmap["pojazdTRWIE"]
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorcaEIID",
                    nazwa: langmap["odbiorcaEIID"]
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWEIID",
                    nazwa: langmap["pojazdTRWEIID"]
                });
        }
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "zdopis",
                nazwa: langmap["zdopis"]
            });
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "sterownik",
                nazwa: langmap["sterownik"]
            });
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "rozszerzeniester",
                nazwa: langmap["rozszerzeniester"]
            });
        kolumnyyD.push({
            id: "operator",
            nazwa: langmap["operator"]
        });
        kolumnyyD.push({
            id: "dostawca",
            nazwa: langmap["dostawca"]
        });

        kolumnyyD.push({
            id: "plynEIID",
            nazwa: langmap["plynEIID"]
        });
        kolumnyyD.push({
            id: "grupa_odbiorca",
            nazwa: langmap["grupa_odbiorca"]
        });
        kolumnyyD.push({
            id: "grupa_pojazd",
            nazwa: langmap["grupa_pojazd"]
        });
        var obssource = generateDiffCollection(kolumnyyD, this.datad.mainCtrl.repDef.columnsInReport, "id");
        obssource = obssource.containsls;
        var grid = Ext.getCmp(this.cid + "firmas_from");
        var grid2 = Ext.getCmp(this.cid + "firmas_to");
        obssource = generateCollectionOrder(obssource, this.datad.mainCtrl.repDef.columnsInReportGroup, "id");
        var obss = generateDiffCollection(obssource, this.datad.mainCtrl.repDef.columnsInReportGroup, "id");
        grid.store.loadData(obss.restls);
        grid2.store.loadData(obss.containsls);
    },
    callAfterHide: function () {
        if (this.callAfterShowCalled) {
            var okrestyp = Ext.getCmp(this.cid + "okrestyp");
            var okresod = Ext.getCmp(this.cid + "okresod");
            var okresdo = Ext.getCmp(this.cid + "okresdo");
            this.datad.mainCtrl.repDef.typeCzas = okrestyp.getValue();
            this.datad.mainCtrl.repDef.fromDate = okresod.getValue2();
            this.datad.mainCtrl.repDef.toDate = okresdo.getValue2();
        }
        return null;
    },
    reParseReportValidate: function () {
    },
    validateShowLess: function () {
        return this.validateShow();
    },
    validateShow: function () {
        var grid = Ext.getCmp(this.cid + "firmas_from");
        var grid2 = Ext.getCmp(this.cid + "firmas_to");
        var colsNext = grid2.store.collect("id");
        this.datad.mainCtrl.repDef.columnsInReportGroup = colsNext;
        return null;
    },
    setData: function (datad) {
        this.datad = datad;
    }
});
