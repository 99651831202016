Ext.define('ViewReportList', {
    extend: 'Ext.panel.Panel',
    id: "reportlist",
    currentId: null,
    currentDef: null,
    currentNazwa: null,
    currentIdFirma: null,
    currentIdFirmaNazwa: null,
    currentIdFirma2: null,
    currentIdFirmaNazwa2: null,
    currentIdFirma3: null,
    currentIdFirmaNazwa3: null,
    statics: {
        accessPropertyViewName: "access.view.rplist"
    },
    cat: "p",
    text: Lang.raport_stanadardowy,
    buttonIconCls: "iconListRap",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            items: [{
                id: this.id + "rapls",
                xtype: 'comboexd',
                store: new Ext.data.JsonStore({
                    id: this.id,
                    fields: [{
                        name: 'id',
                        mapping: 'id'
                    }, {
                        name: 'nazwa',
                        mapping: 'nazwa'
                    }]
                }),
                valueField: 'id',
                displayField: 'nazwa',
                width: '20%',
                triggerAction: 'all',
                resizable: false,
                mode: "local",
                editable: false
            }, {
                style: "margin-left:2px",
                id: this.id + "bnowy",
                xtype: "button",
                text: Lang.nowy.capitalise()
            }, {
                id: this.id + "busun",
                xtype: "button",
                text: Lang.usun.capitalise()
            }, "-", {
                id: this.id + "brwizard",
                xtype: "button",
                text: Lang.kreator.capitalise()
            }, {
                id: this.id + "brefresh",
                xtype: "button",
                text: Lang.odswiez.capitalise()
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconPDF",
                id: this.id + "brppdf",
                text: Lang.pdf.capitalise()
            }, {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEXCEL",
                id: this.id + "brpexcel",
                text: Lang.excel.capitalise()
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [{
                xtype: 'panel',
                id: this.id + "mainctrl",
                bodyCls: 'x-reportpanel-body',
                frame: false,
                border: false,
                layout: 'fit',
                items: []
            }]
        }];
        this.callParent(arguments);
    },
    remove: function () {
        this.callParent(arguments);
    },
    hide: function () {
        var panelRPLView = Ext.getCmp(this.id + "panelRPLView");
        panelRPLView.stopRunningShow();
        this.callParent(arguments);
    },
    show: function () {
        var panelRPLView = Ext.getCmp(this.id + "panelRPLView");
        if (panelRPLView) {
            panelRPLView.startRunningShow();
        }
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
        var bnowy = Ext.getCmp(this.id + "bnowy");
        var busun = Ext.getCmp(this.id + "busun");
        var brefresh = Ext.getCmp(this.id + "brefresh");
        var brppdf = Ext.getCmp(this.id + "brppdf");
        var brpexcel = Ext.getCmp(this.id + "brpexcel");
        var rapls = Ext.getCmp(this.id + "rapls");
        rapls.on("select", this.reloadSelectRp, this);
        bnowy.on("click", this.newReport2, this);
        busun.on("click", this.usunReport, this);
        brefresh.on("click", this.refreshReport, this);
        brppdf.on("click", this.startGenReportPdf, this);
        brpexcel.on("click", this.startGenReportXls, this);
        var brwizard = Ext.getCmp(this.id + "brwizard");
        brwizard.on("click", this.changeBrwizard, this);
        var rapls = Ext.getCmp(this.id + "rapls");
        var mainctrl = Ext.getCmp(this.id + "mainctrl");
        mainctrl.add(Ext.create('PanelReportListViewer', {
            id: this.id + "panelRPLView"
        }));
        var panelRPLView = Ext.getCmp(this.id + "panelRPLView");
        panelRPLView.on("beforeload", this.beforeLoadPanelView, this);
        panelRPLView.on("loaded", this.afterLoadedPanelView, this);
        panelRPLView.on("loadederror", this.afterLoadedErrorPanelView, this);
        this.newReport();
    },
    reloadRpLs: function () {
        var rapls = Ext.getCmp(this.id + "rapls");
        sendAjaxDataJsonRpcL("rest/reportlist/getList", null, null, function (data) {
            if (data.status) {
                var data = data.data;
                rapls.store.loadData(data);
                rapls.setValue(rapls.getValue());
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    reloadSelectRp: function () {
        this.loadReport();
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.currentIdFirma = AppFIRMACTX == null ? null : AppFIRMACTX.id;
        this.currentIdFirmaNazwa = AppFIRMACTX == null ? null : AppFIRMACTX.nazwa;
        this.currentIdFirma2 = !isValidOperatorFirmaLimit() ? null : AppOPERATORFIRMA.id;
        this.currentIdFirmaNazwa2 = !isValidOperatorFirmaLimit() ? null : AppOPERATORFIRMA.nazwa;
        this.reloadRpLs();
        this.clearWorkspace();
        if (this.currentId) {
            this.loadReport();
        }
    },
    newReport: function () {
        var rapls = Ext.getCmp(this.id + "rapls");
        rapls.setValue(null);
        this.currentId = null;
        this.currentDef = null;
        this.currentNazwa = null;
        this.currentIdFirma = AppFIRMACTX == null ? null : AppFIRMACTX.id;
        this.currentIdFirmaNazwa = AppFIRMACTX == null ? null : AppFIRMACTX.nazwa;
        this.currentIdFirma2 = !isValidOperatorFirmaLimit() ? null : AppOPERATORFIRMA.id;
        this.currentIdFirmaNazwa2 = !isValidOperatorFirmaLimit() ? null : AppOPERATORFIRMA.nazwa;
        this.currentIdFirma3 = null;
        this.currentIdFirmaNazwa3 = null;
        this.reloadRpLs();
        this.clearWorkspace();
    },
    newReport2: function () {
        var rapls = Ext.getCmp(this.id + "rapls");
        rapls.setValue(null);
        this.currentId = null;
        this.currentDef = null;
        this.currentNazwa = null;
        this.currentIdFirma = AppFIRMACTX == null ? null : AppFIRMACTX.id;
        this.currentIdFirmaNazwa = AppFIRMACTX == null ? null : AppFIRMACTX.nazwa;
        this.currentIdFirma2 = !isValidOperatorFirmaLimit() ? null : AppOPERATORFIRMA.id;
        this.currentIdFirmaNazwa2 = !isValidOperatorFirmaLimit() ? null : AppOPERATORFIRMA.nazwa;
        this.currentIdFirma3 = null;
        this.currentIdFirmaNazwa3 = null;
        this.reloadRpLs();
        this.clearWorkspace();
        this.changeBrwizard();
    },
    changeBrwizard: function () {
        var w = Ext.create('WindowReportLsWizard', {
            repId: this.currentId,
            repDef: this.currentDef,
            repNazwa: this.currentNazwa,
            repFirma: this.currentIdFirma3 == null ? this.currentIdFirma : this.currentIdFirma3,
            repFirmaNazwa: this.currentIdFirmaNazwa3 == null ? this.currentIdFirmaNazwa : this.currentIdFirmaNazwa3
        });
        w.show();
        w.on("wclosesuccess", function () {
            var repid = w.getReportId();
            var nazwa = w.getReportNazwa();
            var repDef = w.getReportDef();
            this.currentId = repid;
            this.currentDef = repDef;
            this.currentNazwa = nazwa;
            sendAjaxDataJsonRpcL("rest/reportlist/saveObject", {
                id: repid,
                nazwa: nazwa,
                zawartosc: Ext.encode(repDef),
                idfirma: this.currentIdFirma3 == null ? this.currentIdFirma : this.currentIdFirma3
            }, this, function (data) {
                if (data.status) {
                    var data = data.data;
                    this.currentId = data;
                    this.reloadRpLs();
                    var rapls = Ext.getCmp(this.id + "rapls");
                    rapls.setValue(data);
                    this.startGenReport();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        }, this);
        w.on("wclose", function () {
        });
    },
    usunReport: function () {
        var rapls = Ext.getCmp(this.id + "rapls");
        if (rapls.getValue2() == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        }
        sendAjaxDataJsonRpcL("rest/reportlist/deleteObject", {
            id: rapls.getValue2()
        }, this, function (data) {
            if (data.status) {
                var data = data.data;
                this.newReport();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    loadReport: function () {
        var rapls = Ext.getCmp(this.id + "rapls");
        if (rapls.getValue2() > 0) {
            sendAjaxDataJsonRpcL("rest/reportlist/loadDef", {
                id: rapls.getValue2()
            }, this, function (data) {
                if (data.status) {
                    var data = data.data;
                    if (data != null) {
                        this.currentId = rapls.getValue2();
                        this.currentDef = data.zawartosc;
                        this.currentNazwa = data.nazwa;
                        this.currentIdFirma = data.idfirma;
                        this.currentIdFirmaNazwa = data.firmanazwa;
                        this.currentIdFirma2 = data.idfirmacreator;
                        this.currentIdFirmaNazwa2 = data.firmacreatornazwa;
                        this.currentIdFirma3 = data.idfirma;
                        this.currentIdFirmaNazwa3 = data.firmanazwa;
                        this.startGenReport();
                    }
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        }
    },
    refreshReport: function () {
        this.startGenReport();
    },
    clearWorkspace: function () {
        var panelRPLView = Ext.getCmp(this.id + "panelRPLView");
        panelRPLView.newReportRPL();
    },
    beforeLoadPanelView: function () {
        var brefresh = Ext.getCmp(this.id + "brefresh");
        var brppdf = Ext.getCmp(this.id + "brppdf");
        var brpexcel = Ext.getCmp(this.id + "brpexcel");
        var brwizard = Ext.getCmp(this.id + "brwizard");
        brefresh.setDisabled(true);
        brppdf.setDisabled(true);
        brpexcel.setDisabled(true);
        brwizard.setDisabled(true);
    },
    afterLoadedPanelView: function () {
        var brefresh = Ext.getCmp(this.id + "brefresh");
        var brppdf = Ext.getCmp(this.id + "brppdf");
        var brpexcel = Ext.getCmp(this.id + "brpexcel");
        var brwizard = Ext.getCmp(this.id + "brwizard");
        brefresh.setDisabled(this.currentDef == null);
        brppdf.setDisabled(false);
        brpexcel.setDisabled(false);
        brwizard.setDisabled(false);
    },
    afterLoadedErrorPanelView: function () {
        var brefresh = Ext.getCmp(this.id + "brefresh");
        var brppdf = Ext.getCmp(this.id + "brppdf");
        var brpexcel = Ext.getCmp(this.id + "brpexcel");
        var brwizard = Ext.getCmp(this.id + "brwizard");
        brefresh.setDisabled(this.currentDef == null);
        brppdf.setDisabled(true);
        brpexcel.setDisabled(true);
        brwizard.setDisabled(false);
    },
    startGenReport: function () {
        var panelRPLView = Ext.getCmp(this.id + "panelRPLView");
        panelRPLView.startReportGen(this.currentDef, this.currentNazwa, AppFIRMACTX == null ? this.currentIdFirma : AppFIRMACTX.id);
    },
    startGenReportPdf: function () {
        var panelRPLView = Ext.getCmp(this.id + "panelRPLView");
        panelRPLView.initReportGetPdf();
    },
    startGenReportXls: function () {
        var panelRPLView = Ext.getCmp(this.id + "panelRPLView");
        panelRPLView.initReportGetXls();
    }
});
