Ext.define('ViewFirmasMobile', {
    extend: 'Ext.panel.Panel',
    grid: null,
    gridpage: null,
    windowObject: null,
    id: "firmas",
    plynList: null,
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    // showOnly: false,
    statics: {
        accessPropertyName: "access.manage.firmy",
        accessPropertyViewName: "access.view.firmy"
    },
    cat: "k",
    text: Lang.firmy,
    buttonIconCls: "iconFirma",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            itemId: 'kontekstfirmy-container',
            xtype: 'container',
            region: 'north',
            hidden: !(AppFIRMACTX != null),
            style: 'background-color: white',
            items: [{
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                cls: "kontekstfirmy-text kontekstfirmy-text-high kontekstfirmy-text-mobile"
            }]
        }, {
            region: "north",
            xtype: 'toolbar',
            scrollable: true,
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                scope: this,
                handler: this.dodajObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewFirmas.accessPropertyName + "/add"),
                cls: 'x-btn-mobile'
            }, {
                id: this.id + "bedit",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEdit",
                scope: this,
                text: Lang.edytuj.capitalise(),
                handler: this.edytujObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewFirmas.accessPropertyName + "/update"),
                cls: 'x-btn-mobile'
            }, {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }, {
                xtype: "checkbox",
                id: this.id + "showonlysupported",
                boxLabel: Lang.pokazuj_tylko_obslugiwane,
                stateId: "showonlysupported-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
                stateful: true,
                stateEvents: ["change"],
                hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.OPTIMALFLAG || Const.STARTERFLAG,
                getState: function () {
                    return {
                        value: this.getValue()
                    };
                },
                applyState: function (state) {
                    if (state) {
                        this.setValue(state.value);
                    }
                },
                listeners: {
                    scope: this,
                    change: this.refreshDatastore
                }
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
    },
    extractIdAndName: function (data) {
        var plynArray = [];
        for (var i = 0; i < data.length; i++) {
            var plyn = {
                id: data[i].id,
                nazwa: data[i].nazwa
            };
            plynArray.push(plyn);
        }
        return plynArray;
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    onDestroy: function () {
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        if (AppFIRMACTX != null) {
            kontekstfirmy.setText(AppFIRMACTX.nazwa);
        } else {
            kontekstfirmy.setText("");
        }
        this.down('#kontekstfirmy-container').setHidden(!(AppFIRMACTX != null));
        sendAjaxDataJsonRpcL("rest/plyny/getList", null, this, function (data) {
            if (data.status) {
                var newPlynList = this.extractIdAndName(data.data);
                if (Ext.JSON.encode(this.plynList) != Ext.JSON.encode(newPlynList)) {
                    this.plynList = newPlynList;
                    this.rebuildGrid = true;
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
            if (this.rebuildGrid) {
                this.rebuildGrid = false;
                var onwer = this.grid.ownerCt;
                Ext.suspendLayouts();
                onwer.remove(this.grid);
                this.grid.destroy();
                this.buildGrid();
                onwer.add(this.grid);
                Ext.resumeLayouts(true);
            }
            this.refreshDatastore();
        });
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
        this.manageSelect();
    },
    scrollToView: function (id) {
        this.grid.selectRowScroll("id", this.id);
    },
    buildGrid: function () {
        var columnsArray = [{
            header: "ID",
            width: 145,
            sortable: true,
            dataIndex: 'id',
            filter: {
                type: 'numeric'
            },
            filterable: true,
            hidden: true
        }, {
            header: Lang.nazwa,
            flex: 2,
            sortable: true,
            dataIndex: 'nazwa',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["nazwa"], true),
            filterable: true
        }];
        var recordType = [{
            name: 'id',
            mapping: 'id'
        }, {
            name: 'nazwa',
            mapping: 'nazwa'
        }, {
            name: 'opis',
            mapping: 'opis'
        }];
        let plynListString = '';
        if (this.plynList != null) {
            this.plynList.forEach(plyn => {
                columnsArray.push({
                    header: Lang.pozostaly_przydzial + " [ " + plyn.nazwa + " ]",
                    flex: 2,
                    sortable: true,
                    filter: {
                        type: 'numeric'
                    },
                    filterable: true,
                    dataIndex: "plyn_" + plyn.id
                });
                recordType.push({
                    name: "plyn_" + plyn.id,
                    mapping: "plyn_" + plyn.id
                });
                plynListString += `
                    <tpl if="${'plyn_' + plyn.id} != null">
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.pozostaly_przydzial + " [ " + plyn.nazwa + " ]"}:
                                <span class="mobile-rowexp-value"> {${'plyn_' + plyn.id}}</span>
                            </span>
                        </div>
                    </tpl>`
            });
        }
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true,
                rowexpander: {
                    rowBodyTpl: new Ext.XTemplate(
                        `<div class="mobile-rowexp-wrapper">
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.nazwa}:
                                <span class="mobile-rowexp-value"> {nazwa}</span>
                            </span>
                        </div>
                    
                        <tpl if="this.notEmptyAfterTrim(opis)">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.opis}:
                                    <span class="mobile-rowexp-value"> {opis}</span>
                                </span>
                            </div>
                        </tpl>
                    
                        ${plynListString}
                    </div>`,
                        {
                            notEmptyAfterTrim
                        }
                    )
                }
            },
            store: new Ext.data.Store({
                proxy: new Ext.data.MemoryProxy(),
                autoLoad: false,
                model: Ext.define(null, {
                    extend: 'Ext.data.Model',
                    idProperty: 'id',
                    fields: recordType
                }),
                reader: new Ext.data.ArrayReader()
            }),
            columns: columnsArray,
            frame: false,
            border: false,
            forceFit: true,
            scrollable: 'y',
            stateId: "this.grid-" + this.id + "-mobile",
            stateful: false,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            }
        });
        this.grid.addListener("rowdblclick", function () {
            if (validPrivilegesOperatorComplex(ViewFirmas.accessPropertyName + "/update"))
                this.edytujObiekt();
        }, this);
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
    },
    manageSelect: function () {
        if (this.showState) {
            var bedit = Ext.getCmp(this.id + "bedit");
            if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelection() == null || this.grid.getSelectionModel().getSelection().length == 0) {
                bedit.setDisabled(true);
            } else {
                bedit.setDisabled(!validPrivilegesOperatorComplex(ViewFirmas.accessPropertyName + "/update"));
            }
        }
    },
    refreshDatastore: function () {
        updateCzasRefreshSerwer();
        var showOnlySupportedParam = Ext.getCmp(this.id + "showonlysupported").getValue();
        if (this.grid && this.grid.rendered)
            this.grid.mask();
        sendAjaxDataJsonRpcL("rest/firmy/getListWithPlynState", {showOnlySupported: showOnlySupportedParam}, this, function (data) {
            if (this.grid && this.grid.rendered)
                this.grid.unmask();
            if (data.status) {
                this.grid.store.loadData(data.data);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    refreshDatastoreAfterEdit: function () {
        updateCzasRefreshSerwer();
        var showOnlySupportedParam = Ext.getCmp(this.id + "showonlysupported").getValue();
        if (this.grid && this.grid.rendered)
            this.grid.mask();
        sendAjaxDataJsonRpcL("rest/firmy/getListWithPlynState", {showOnlySupported: showOnlySupportedParam}, this, function (data) {
            if (this.grid && this.grid.rendered)
                this.grid.unmask();
            if (data.status) {
                this.grid.store.loadData(data.data);
                updateFirmaListContext();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    dodajObiekt: function () {
        windowObject = Ext.create('WindowFirma', {
            callbackF: Ext.Function.bind(this.refreshDatastoreAfterEdit, this)
        });
        windowObject.showW(0);
    },
    edytujObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        windowObject = Ext.create('WindowFirma', {
            callbackF: Ext.Function.bind(this.refreshDatastoreAfterEdit, this)
        });
        windowObject.showW(id);
    },
    usunObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        Ext.MessageBox.show({
            title: Lang.usuwanie,
            message: Lang.czy_napewno_usunac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/firmy/deleteObject", {
                        id: id
                    }, this, function (data) {
                        if (data.status) {
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                        this.refreshDatastoreAfterEdit();
                    });
                }
            }
        });
    }
});
