Ext.define('LimitPanelStanyCp', {
    extend: 'Ext.panel.Panel',
    layout: {
        type: 'vbox',
        align: 'stretch'
    },
    initComponent: function () {
        Ext.apply(this, {
            items: [{
                xtype: 'gridpanelexd',
                flex: 1,
                store: new Ext.data.Store({
                    autoLoad: false,
                    sorters: [{
                        property: "plyn",
                        direction: "ASC"
                    }],
                    proxy: new Ext.data.MemoryProxy({}),
                    reader: new Ext.data.JsonReader({}, [{
                        name: 'id',
                        mapping: 'id'
                    }, {
                        name: 'plyn',
                        mapping: 'plyn'
                    }, {
                        name: 'wartosc',
                        mapping: 'wartosc'
                    }, {
                        name: 'wartoscleft',
                        mapping: 'wartoscleft'
                    }])
                }),
                columns: [{
                    header: Lang.plyn,
                    flex: 2,
                    sortable: true,
                    dataIndex: 'plyn'
                }, {
                    header: Lang.wartosc,
                    flex: 2,
                    sortable: true,
                    dataIndex: 'wartosc',
                    renderer: function (v) {
                        return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                    },
                    resizable: false
                }, {
                    header: Lang.pozostalo,
                    flex: 2,
                    sortable: true,
                    dataIndex: 'wartoscleft',
                    renderer: function (v) {
                        return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                    },
                    resizable: false
                }],
                frame: true,
                border: true,
                id: this.id + "limitygrid",
                stateId: null,
                stateful: false,
                stripeRows: true,
                enableHdMenu: false,
                selModel: {
                    selType: 'rowmodel',
                    mode: 'single',
                    allowDeselect: false
                },
                listeners: {
                    scope: this
                }
            }]
        });
        this.callParent();
    },
    updateData: function () {
        var grid = Ext.getCmp(this.id + "limitygrid");
        if (this.grid && this.grid.rendered)
            this.grid.mask();
        sendAjaxDataJsonRpcL("rest/firmy/getObjectForInfoZbiorcza", {
            id: AppOPERATORFIRMA.id
        }, this, function (data) {
            if (this.grid && this.grid.rendered)
                this.grid.mask();
            if (data.status) {
                data = data.data;
                var obiekt = data;
                var limitydatas = obiekt.limitydatas;
                this.limitydatas = limitydatas;
                var grid = Ext.getCmp(this.id + "limitygrid");
                grid.store.loadData(this.limitydatas);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    }
});
