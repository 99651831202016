Ext.define('WindowAwaryjne', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.tankowanie_awaryjne.capitalise(),
    width: 350,
    closable: false,
    collapsible: false,
    scrollable: true,
    modal: true,
    resizable: false,
    layout: 'fit',
    initComponent: function (config) {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'fit',
            items: [{
                id: this.cid + "formpanel",
                xtype: 'form',
                frame: true,
                border: false,
                autoWidth: true,
                autoHeight: true,
                scrollable: true,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0",
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                fileUpload: true,
                items: [{
                    id: this.cid + "kod",
                    name: "kod",
                    xtype: 'textfield',
                    fieldLabel: Lang.kod,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    regex: new RegExp("^[0-9]{6,6}$"),
                    regexText: Lang.kod_reczny_musi_miec_n_cyfr,
                    cls: "x-item-disabled-sec"
                }, {
                    id: this.cid + "opis",
                    name: "opis",
                    xtype: 'textarea',
                    height: 120,
                    fieldLabel: Lang.opis
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex("access.manage.tawaryjne")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.obiektid = obiektid;
        this.obiekt = null;
        sendAjaxDataJsonRpcLGroup(["rest/awaryjne/getObjectForEdit", "rest/systemkonf/obtainAutoGeneratedTankowanieKodRecznyAwaryjne"], [{
            id: this.obiektid
        }, {}], this, function (gstatus, data, datad, status) {
            if (gstatus) {
                this.obiekt = datad[0];
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                if (this.obiekt != null) {
                    Ext.getCmp(this.cid + "kod").setValue(datad[0].kod);
                    Ext.getCmp(this.cid + "kod").setDisabled(true);
                    Ext.getCmp(this.cid + "opis").setValue(datad[0].opis);
                } else {
                    Ext.getCmp(this.cid + "kod").setValue(datad[1]);
                    Ext.getCmp(this.cid + "kod").clearInvalid();
                    Ext.getCmp(this.cid + "kod").setDisabled(true);
                    Ext.getCmp(this.cid + "opis").setValue(null);
                }
                var title = Lang.tankowanie_awaryjne.capitalise();
                if (AppFIRMACTX != null)
                    title += " (" + AppFIRMACTX.nazwa + ")";
                this.setTitle(title);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        var canWrite = false;
        if (Ext.getCmp(this.cid + "formpanel").form.isValid()) {
            canWrite = true;
        }
        if (canWrite) {
            sendAjaxDataJsonRpcL("rest/awaryjne/saveObject", {
                id: this.obiektid,
                kod: Ext.getCmp(this.cid + "kod").getValue(),
                opis: Ext.getCmp(this.cid + "opis").getValue(),
                optlockversion: this.obiekt == null ? null : this.obiekt.optlockversion
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    this.obiektid = data.id;
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
                if (this.callbackF)
                    this.callbackF();
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});
