Ext.define('WindowAgreements', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.zgody.capitalise(),
    width: 600,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    autoHeight: true,
    scrollable: true,
    bodyStyle: 'padding:10px 10px 10px 10px',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            scrollable: true,
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                items: [{
                    xtype: "checkboxexd",
                    id: this.cid + "processingaccepted1",
                    boxLabel: new Ext.Template(Lang.wyrazam_zgode_na_regulamin).apply({
                        cloud: Const.CLOUDFLAG ? "_cloud" : "",
                        lang: resolveHtmlDocumentLang()
                    }),
                    hidden: false,
                    listeners: {
                        change: function () {
                            this.reloadAcceptFlags()
                        },
                        scope: this
                    }
                }, {
                    xtype: "checkboxexd",
                    id: this.cid + "processingaccepted2",
                    boxLabel: new Ext.Template(Lang.wyrazam_zgode_na_przetwarzanie).apply({
                        cloud: Const.CLOUDFLAG ? "_cloud" : "",
                        lang: resolveHtmlDocumentLang()
                    }),
                    hidden: false,
                    listeners: {
                        change: function () {
                            this.reloadAcceptFlags()
                        },
                        scope: this
                    }
                }, {
                    xtype: "checkboxexd",
                    id: this.cid + "processingaccepted3",
                    boxLabel: Lang.wyrazam_zgode_na_przekazywanie,
                    hidden: false,
                    listeners: {
                        change: function () {
                            this.reloadAcceptFlags()
                        },
                        scope: this
                    }
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.dalej,
            scope: this,
            disabled: true,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    reloadAcceptFlags: function () {
        var accept1 = Ext.getCmp(this.cid + "processingaccepted1").getValue();
        var accept2 = Ext.getCmp(this.cid + "processingaccepted2").getValue();
        var accept3 = Ext.getCmp(this.cid + "processingaccepted3").getValue();
        Ext.getCmp(this.cid + "save").setDisabled(!accept1 || !accept2 || !accept3);
    },
    showW: function () {
        this.show();
    },
    closeOp: function () {
        this.close();
        performAjaxLPromise('rest/firmy-cloud/markAgreementAsAccepted').then(() => {
        }).catch(error => {
            handleCommonCallBackAjaxRPCError(error);
        }).finally(() => {
        });
    }
});

