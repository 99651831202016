Ext.define('PanelSkpPomiaryAnaliza', {
    extend: 'Ext.container.Container',
    alias: "widget.panelskppomiaryanaliza",
    layout: {
        type: "vbox",
        align: "stretch"
    },
    scrollable: true,
    dataForStore: [],
    initComponent: function () {
        this.cid = Ext.id();
        var store = Ext.create('Ext.data.Store', {
            type: 'store',
            model: Ext.define(null, {
                extend: 'Ext.data.Model',
                fields: ["sxcat", "min", "max", "avg"]
            }),
            proxy: {
                type: 'memory',
                reader: {
                    type: "json"
                }
            }
        });
        var topPanel = {
            xtype: "toolbar",
            docked: 'top',
            layout: "hbox",
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                padding: 10,
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.onReportReload
            }, {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: 'iconPDF',
                padding: 10,
                text: Lang.pdf.capitalise(),
                scope: this,
                handler: this.downloadPDF
            }, '-', {
                id: this.cid + "idzbiornik",
                xtype: 'combobox',
                fieldLabel: Lang.zbiornik,
                valueField: 'id',
                displayField: 'nazwa',
                padding: 7,
                queryMode: 'local',
                store: new Ext.data.JsonStore({
                    sorters: [Ext.bind(customLocaleSort, this, ["nazwa"], true)],
                    fields: ['id', 'nazwa'],
                    proxy: {
                        type: 'memory',
                        reader: {
                            type: "json"
                        }
                    }
                }),
                allowBlank: false,
                editable: false
            }]
        };
        var periodSelector = {
            xtype: "container",
            layout: {
                type: 'hbox',
                align: 'center'
            },
            items: [{
                xtype: "panelreportingreporttypeselect",
                id: this.cid + "reporttype",
                flex: 3,
                listeners: {
                    scope: this,
                    selectrow: this.onChangeReportType
                }
            }, {
                xtype: "panelreportingczasselect",
                id: this.cid + "datemanipulationt",
                height: 44,
                flex: 1,
                stateValue: "day",
                hideNowTypeSelect: true,
                hideDayGranulate: false,
                plugins: 'responsive',
                responsiveConfig: {
                    'width < 1280': {
                        compactView: true
                    },
                    'width > 1280': {
                        compactView: false
                    }
                }
            }]
        };
        var grid = {
            xtype: 'grid',
            id: this.cid + "grid",
            store: store,
            flex: 1,
            minHeight: 200,
            emptyText: Lang.brak_danych,
            columns: [{
                header: Lang.czas,
                flex: 1,
                dataIndex: 'sxcat',
                sortable: false
            }, {
                header: Lang.min,
                flex: 1,
                dataIndex: 'min',
                sortable: false
            }, {
                header: Lang.max,
                flex: 1,
                dataIndex: 'max',
                sortable: false
            }, {
                header: Lang.avg,
                flex: 1,
                dataIndex: 'avg',
                xtype: 'numbercolumn',
                format: '0.0',
                sortable: false
            }]
        };
        var chart = this.getChart();
        chart.store = store;
        var hiddenPdfDiv = {
            xtype: 'box',
            hidden: true,
            height: 720,
            width: 1280,
            html: '<div id="test"></div>',
            hideMode: 'offsets'
        };
        this.items = [topPanel, periodSelector, chart, grid, hiddenPdfDiv];
        this.callParent(arguments);
    },
    show: function () {
        if (Ext.os.deviceType != "Desktop") {
            var chartComponent = Ext.getCmp(this.cid + 'chart');
            var mouseMoveY = null;
            chartComponent.body.on("swipe", function (event, node, options, eOptions) {
                var deltaY = event.distance;
                if (event.direction == "down")
                    deltaY = deltaY * -1;
                this.scrollBy(0, deltaY, true);
            }, this);
            chartComponent.body.on("mousemove", function (event, node, options, eOptions) {
                var deltaY = 0;
                if (mouseMoveY == null) {
                    mouseMoveY = event.parentEvent.clientY;
                } else if (mouseMoveY != event.parentEvent.clientY) {
                    deltaY = mouseMoveY - event.parentEvent.clientY;
                }
                this.scrollBy(0, deltaY, true);
            }, this);
        }
        this.callParent(arguments);
    },
    loadZbiornikiCombo: function () {
        var zbiornik = Ext.getCmp(this.cid + "idzbiornik");
        sendAjaxDataJsonRpcL("rest/zbiorniki/getShortList", null, this, function (data) {
            if (data.status) {
                zbiornik.clearValue();
                zbiornik.store.loadData(data.data);
                this.show();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    closeOp: function () {
        this.close();
    },
    onReportReload: function () {
        var zbiornik = Ext.getCmp(this.cid + "idzbiornik");
        if (!zbiornik.isValid()) {
            Ext.MessageBox.alert(Lang.error, Lang.pole_nie_moze_byc_puste);
            return;
        }
        var aggregationSeed = this.getSeed();
        var period = this.getPeriod();
        if (this.rendered)
            this.mask(Lang.wczytywanie_danych, 'loading-indicator');
        sendAjaxDataJsonRpcL("rest/skppom/getAggregated", {
            datefrom: period.dateLocalFrom,
            dateto: period.dateLocalTo,
            aggrseed: aggregationSeed,
            idzbiornik: zbiornik.getValue()
        }, this, function (data) {
            if (data.status) {
                var dataForStore = data.data;
                var grid = Ext.getCmp(this.cid + "grid");
                var chart = Ext.getCmp(this.cid + "chart");
                grid.store.loadData(dataForStore);
                chart.store.loadData(dataForStore);
                if (this.rendered)
                    this.unmask();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    downloadPDF: function () {
        var zbiornik = Ext.getCmp(this.cid + "idzbiornik");
        if (!zbiornik.isValid()) {
            Ext.MessageBox.alert(Lang.error, Lang.pole_nie_moze_byc_puste);
            return;
        }
        if (Ext.getCmp(this.cid + "chart").getStore().count() <= 0) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_danych);
            return;
        }
        var series = Ext.getCmp(this.cid + "chart").getSeries();
        var aggregationSeed = this.getSeed();
        var period = this.getPeriod();
        var chart = Ext.create('Ext.chart.CartesianChart', {
            xtype: 'cartesian',
            legend: {
                type: 'sprite',
                docked: 'right'
            },
            height: 720,
            width: 1280,
            store: Ext.getCmp(this.cid + "chart").getStore(),
            captions: {
                title: Lang.poziom_plynu_w_zbiorniku
            },
            //"Wyciszenie ostrzeżenia w konsoli"
            downloadServerUrl: 'http://svg.sencha.io',
            axes: [{
                type: 'numeric',
                fields: ['min', 'max', 'avg'],
                position: 'left',
                grid: true
            }, {
                type: 'category',
                position: 'bottom',
                grid: true,
                label: {
                    rotate: {
                        degrees: -45
                    }
                }
            }],
            series: [{
                type: 'line',
                title: Lang.min,
                xField: 'sxcat',
                yField: 'min',
                style: {
                    lineWidth: 2
                },
                marker: {
                    radius: 4,
                    lineWidth: 2
                },
                hidden: series[0].getHidden()
            }, {
                type: 'line',
                title: Lang.max,
                xField: 'sxcat',
                yField: 'max',
                style: {
                    lineWidth: 2
                },
                marker: {
                    radius: 4,
                    lineWidth: 2
                },
                hidden: series[1].getHidden()
            }, {
                type: 'line',
                title: Lang.avg,
                xField: 'sxcat',
                yField: 'avg',
                style: {
                    lineWidth: 2
                },
                marker: {
                    radius: 4,
                    lineWidth: 2
                },
                hidden: series[2].getHidden()
            }]
        });
        chart.on('redraw', function () {
            postFormAsync("rest/skppom/downloadExportAggregated", {
                datefrom: period.dateLocalFrom,
                dateto: period.dateLocalTo,
                aggrseed: aggregationSeed,
                idzbiornik: zbiornik.getValue(),
                image: chart.getImage().data
            }, null);
            Ext.defer(function () {
                chart.destroy();
            }, 1000);
        });
        chart.render(Ext.get("test"));
    },
    getChart: function () {
        return {
            xtype: 'cartesian',
            scrollable: true,
            id: this.cid + "chart",
            height: 500,
            legend: {
                type: 'sprite',
                docked: 'right'
            },
            plugins: {
                chartitemevents: {
                    moveEvents: true
                }
            },
            captions: {
                title: Lang.poziom_plynu_w_zbiorniku
            },
            //"Wyciszenie ostrzeżenia w konsoli"
            downloadServerUrl: 'http://svg.sencha.io',
            axes: [{
                type: 'numeric',
                fields: ['min', 'max', 'avg'],
                position: 'left',
                grid: true
            }, {
                type: 'category',
                position: 'bottom',
                grid: true,
                label: {
                    rotate: {
                        degrees: -45
                    }
                }
            }],
            series: [{
                type: 'line',
                title: Lang.min,
                xField: 'sxcat',
                yField: 'min',
                style: {
                    lineWidth: 2
                },
                marker: {
                    radius: 4,
                    lineWidth: 2
                }
            }, {
                type: 'line',
                title: Lang.max,
                xField: 'sxcat',
                yField: 'max',
                style: {
                    lineWidth: 2
                },
                marker: {
                    radius: 4,
                    lineWidth: 2
                }
            }, {
                type: 'line',
                title: Lang.avg,
                xField: 'sxcat',
                yField: 'avg',
                style: {
                    lineWidth: 2
                },
                marker: {
                    radius: 4,
                    lineWidth: 2
                }
            }]
        };
    },
    getPeriod: function () {
        var period = Ext.getCmp(this.cid + "datemanipulationt").getValue();
        var reportType = Ext.getCmp(this.cid + "reporttype").getValue();
        if (reportType == "monthweek" || reportType == "yearweek") {// get whole weeks
            var m = moment(period.dateLocalFrom);
            var yearBeforeChange = Ext.Date.format(m.toDate(), "Y");
            m.isoWeekday(1);
            var yearAfterChange = Ext.Date.format(m.toDate(), "Y");
            if (reportType == "yearweek" && yearBeforeChange != yearAfterChange) {
                m.add(1, 'weeks');
            }
            period.dateLocalFrom = Ext.Date.format(m.toDate(), Date.patterns.ISO8601Short + " 00:00:00");
            var m = moment(period.dateLocalTo);
            m.isoWeekday(7);
            period.dateLocalTo = Ext.Date.format(m.toDate(), Date.patterns.ISO8601Short + " 23:59:59");
        }
        return period;
    },
    getSeed: function () {
        var reportType = Ext.getCmp(this.cid + "reporttype").getValue();
        var seed = "";
        if (reportType == "daily") {
            seed = "hour";
        }
        if (reportType == "weekday") {
            seed = "day";
        }
        if (reportType == "monthday") {
            seed = "day";
        }
        if (reportType == "monthweek") {
            seed = "week";
        }
        if (reportType == "yearmonth") {
            seed = "month";
        }
        if (reportType == "yearweek") {
            seed = "week";
        }
        return seed;
    },
    onChangeReportType: function () {
        var creporttype = Ext.getCmp(this.cid + "reporttype");
        var rp = creporttype.getValue();
        var stateV = null;
        if (rp == "daily") {
            stateV = "day";
        }
        if (rp == "weekday") {
            stateV = "week";
        }
        if (rp == "monthday") {
            stateV = "month";
        }
        if (rp == "monthweek") {
            stateV = "month";
        }
        if (rp == "yearmonth") {
            stateV = "year";
        }
        if (rp == "yearweek") {
            stateV = "year";
        }
        var cdatemanipulationt = Ext.getCmp(this.cid + "datemanipulationt");
        cdatemanipulationt.changeStateValue(stateV);
    },
    clearAll: function () {
        var grid = Ext.getCmp(this.cid + "grid");
        var chart = Ext.getCmp(this.cid + "chart");
        Ext.suspendLayouts();
        chart.destroy();
        grid.store.remove(grid.store.getData());
        grid.store.load();
        var newChart = this.getChart();
        newChart.store = grid.store;
        this.insert(2, newChart);
        Ext.resumeLayouts(true);
    }
});
