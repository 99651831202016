Ext.define('ViewDziennik', {
    extend: 'Ext.panel.Panel',
    grid: null,
    gridpage: null,
    id: "dziennik",
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyViewName: "access.view.dziennik"
    },
    cat: "p",
    text: Lang.dziennik,
    buttonIconCls: "iconDziennik",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }, {
                xtype: "box",
                id: this.id + "kontekstfirmybox",
                cls: "kontekstfirmy-box"
            }, {
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                style: 'display:block;margin-right:5px',
                cls: "kontekstfirmy-text"
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
        if (this.grid && this.grid.rendered)
            this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        var kontekstfirmybox = Ext.getCmp(this.id + "kontekstfirmybox");
        if (AppFIRMACTX != null && !Const.AGGREGATEDCORE) {
            kontekstfirmybox.update("<div class='appSetColourRedSplit'></div>");
            kontekstfirmy.setText(Lang.pracujesz_w_kontekscie + AppFIRMACTX.nazwa);
        } else {
            kontekstfirmybox.update("");
            kontekstfirmy.setText("");
        }
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            var onwer = this.grid.ownerCt;
            Ext.suspendLayouts();
            onwer.remove(this.grid);
            this.grid.destroy();
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
            if (this.grid && this.grid.rendered)
                this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
    },
    buildGrid: function (datals) {
        var store = Ext.create('Ext.data.Store', {
            autoLoad: false,
            remoteSort: true,
            remoteFilter: true,
            pageSize: 50,
            fields: [{
                name: 'czas',
                mapping: 'czas',
                type: 'date'
            }, {
                name: 'msg',
                mapping: 'msg'
            }, {
                name: 'urzadzenie',
                mapping: 'urzadzenie'
            }, {
                name: 'module',
                mapping: 'module'
            }, {
                name: 'context',
                mapping: 'context'
            }, {
                name: 'addinfo',
                mapping: 'addinfo'
            }],
            proxy: {
                type: "ajax",
                url: "rest/dziennik/getList",
                filterParam: "filters",
                actionMethods: {
                    read: "POST"
                },
                reader: {
                    type: 'json',
                    id: 'id',
                    rootProperty: 'data',
                    totalProperty: 'total'
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            sorters: {
                property: 'czas',
                direction: 'DESC'
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true
            },
            store: store,
            columns: [{
                header: Lang.urzadzenie,
                flex: 1,
                sortable: true,
                dataIndex: 'urzadzenie',
                filter: {
                    type: 'string'
                },
                filterable: true
            }, {
                header: Lang.komunikat,
                flex: 2,
                sortable: true,
                dataIndex: 'msg',
                filter: {
                    type: 'string'
                },
                filterable: true
            }, {
                header: Lang.addInfo,
                flex: 1,
                sortable: true,
                dataIndex: 'addinfo',
                filter: {
                    type: 'string'
                },
                filterable: true
            }, {
                header: Lang.czas,
                flex: 3,
                sortable: true,
                dataIndex: 'czas',
                renderer: Ext.util.Format.dateRenderer(),
                filter: {
                    type: 'date'
                },
                filterable: true
            }],
            frame: false,
            border: false,
            stateId: "grid-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            },
            bbar: {
                xtype: 'pagingtoolbarexd',
                displayInfo: true,
                pagingStore: store
            }
        });
        this.grid.addListener("render", function () {
        });
        this.grid.store.addListener("load", function () {
            updateCzasRefreshSerwer();
        });
        this.grid.addListener("rowcontextmenu", function (grid, rowi, e) {
            e.preventDefault();
        });
        this.grid.addListener("containercontextmenu", function (grid, e) {
            e.preventDefault();
        });
    },
    refreshDatastore: function () {
        this.grid.store.load();
    }
});
