Ext.define('WindowNefRaport', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.parametry_raportu_nef,
    width: 450,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: 'fit',
    statics: {
        accessPropertyViewName: "access.view.raportysrodowiskowe"
    },
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            scrollable: true,
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    labelWidth: 100,
                    anchor: "0",
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    xtype: 'radiogroupexd',
                    id: this.cid + "type",
                    name: "type",
                    baseCls: 'x-radio-column',
                    fieldLabel: Lang.w_oparciu_o,
                    columns: 2,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.zaladunki.capitalise(),
                        inputValue: true,
                        value: true
                    }, {
                        boxLabel: Lang.tankowania.capitalise(),
                        inputValue: false
                    }],
                    listeners: {
                        scope: this,
                        change: function (radiogroup, newValue) {
                            Ext.getCmp(this.id + "groupbypojazdy").setHidden(newValue);
                            Ext.getCmp(this.id + "groupbypojazdy").setDisabled(false);
                            Ext.getCmp(this.id + "groupbypojazdy").setValue(false);
                            Ext.getCmp(this.id + "allzbfromcontext").setHidden(newValue);
                            Ext.getCmp(this.id + "allzbfromcontext").setValue(false);
                            if (newValue) {
                                Ext.getCmp(this.cid + 'idzbiornik').setDisabled(false);
                            }
                        }
                    }
                }, {
                    id: this.id + "allzbfromcontext",
                    xtype: "checkboxexd",
                    hidden: true,
                    style: "margin-left: 10px",
                    boxLabel: Lang.wszystkie_z_danej_firmy,
                    itemCls: "operatorcurrentchange-small-top-margin",
                    disabled: AppFIRMACTX == null,
                    listeners: {
                        scope: this,
                        change: function (el, newValue, oldValue, options) {
                            Ext.getCmp(this.cid + 'idzbiornik').setDisabled(newValue);
                        }
                    }
                }, {
                    id: this.id + "groupbypojazdy",
                    xtype: "checkboxexd",
                    hidden: true,
                    style: "margin-left: 10px",
                    boxLabel: Lang.grupowanie_pojazdow,
                    itemCls: "operatorcurrentchange-small-top-margin",
                    disabled: AppFIRMACTX == null
                }, {
                    id: this.cid + 'idzbiornik',
                    xtype: 'comboexd',
                    valueField: 'id',
                    displayField: 'nazwa',
                    store: new Ext.data.JsonStore({
                        id: "id",
                        rootProperty: '',
                        sorters: [{
                            property: "nazwa",
                            direction: "ASC"
                        }],
                        fields: [{
                            name: 'id',
                            mapping: 'id'
                        }]
                    }),
                    triggerAction: 'all',
                    resizable: false,
                    editable: false,
                    mode: "local",
                    fieldLabel: Lang.zbiornik,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    listeners: {
                        scope: this,
                        select: function (c, r, i) {
                            this.validPrivilegesAndZbiornik(r);
                        }
                    },
                    cls: "x-item-disabled-sec"
                }, {
                    id: this.cid + "czasFrom",
                    name: "czasFrom",
                    xtype: 'datefieldexdmod',
                    fieldLabel: Lang.dod,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "czasTo",
                    name: "czasTo",
                    xtype: 'datefieldexdmoddo',
                    fieldLabel: Lang.ddo,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'generate',
            text: Lang.generuj,
            scope: this,
            handler: this.generate
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function () {
        this.obiekt = null;
        sendAjaxDataJsonRpcL("rest/zbiorniki/getList?onlyEnvironmental=true", {
            idinclude: this.obiekt != null ? this.obiekt.idzbiornik : 0
        }, this, function (data) {
            if (data.status) {
                var listFnData = data.data;
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                Ext.getCmp(this.cid + 'idzbiornik').store.loadData(listFnData);
                Ext.getCmp(this.cid + 'idzbiornik').setValue2Default(null);
                if (Ext.getCmp(this.cid + 'idzbiornik').isRow()) {
                    var row = Ext.getCmp(this.cid + 'idzbiornik').getRow();
                    this.validPrivilegesAndZbiornik(row);
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    generate: function () {
        if (!Ext.getCmp(this.cid + 'formpanel').form.isValid()) {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        var fromDate = new Date(Ext.getCmp(this.cid + "czasFrom").getValue());
        var toDate = new Date(Ext.getCmp(this.cid + "czasTo").getValue());
        if (fromDate > toDate) {
            Ext.MessageBox.alert(Lang.error, Lang.zakres_od_nie_moze_byc_wiekszy_od_zakres_do);
            return;
        }
        var isByZaladunki = Ext.getCmp(this.cid + "type").getValue2();
        var data = {
            zbiornikId: Ext.getCmp(this.cid + "idzbiornik").getValue(),
            byTankowania: !isByZaladunki,
            byZaladunki: isByZaladunki,
            allFromCtx: Ext.getCmp(this.id + "allzbfromcontext").getValue(),
            groupByPojazdy: Ext.getCmp(this.id + "groupbypojazdy").getValue(),
            from: Ext.getCmp(this.cid + "czasFrom").getValue(),
            to: Ext.getCmp(this.cid + "czasTo").getValue()
        }
        performAjaxLPromise("rest/nef/validateCreateReportParams", data)
            .then(() => {
                postFormAsync("rest/nef/prepareReport", data, null);
                this.fireEvent("wclose");
                this.closeOp();
            })
            .catch(e => {
                handleCommonCallBackAjaxRPCError(e);
            })
            .finally(() => {
                this.unmask();
            });
    },
    validPrivilegesAndZbiornik: function (zbiornik) {
        var preventByZal = (AppOPERATORFIRMA != null && zbiornik.get('firmas').length > 1) || !validPrivilegesOperatorComplex("access.view.zaladunki");
        var preventByTank = !validPrivilegesOperatorComplex("access.view.tankowania");
        if (preventByZal && preventByTank) {
            Ext.getCmp(this.cid + "generate").setDisabled(true);
            Ext.getCmp(this.cid + "type").setDisabled(true);
        } else {
            Ext.getCmp(this.cid + "generate").setDisabled(false);
            if (preventByZal) {
                Ext.getCmp(this.cid + "type").setValue(false);
                Ext.getCmp(this.cid + "type").setDisabled(true);
            } else if (preventByTank) {
                Ext.getCmp(this.cid + "type").setValue(true);
                Ext.getCmp(this.cid + "type").setDisabled(true);
            } else {
                Ext.getCmp(this.cid + "type").setDisabled(false);
            }
        }
    },
    closeOp: function () {
        this.close();
    }
});
