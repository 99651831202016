Ext.define('WindowImportZdarzenFromFileStatus', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.import_zdarzen_z_pliku_status_operaci,
    cls: 'a-WindowImportZdarzenFromFile',
    width: 500,
    height: 400,
    padding: 10,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    scrollable: true,
    rachunek: null,
    initComponent: function () {
        this.items = [{
            xtype: 'panel',
            layout: 'vbox',
            items: [{
                itemId: 'import-form',
                xtype: 'form',
                defaults: {
                    anchor: '0',
                    labelWidth: 80
                },
                items: [{
                    id: this.id + "status",
                    xtype: 'label',
                    style: 'display:block;margin-top:20px;margin-bottom:20px;font-size:large;font-weight: bold;',
                    cls: "status-text"
                }]
            }, {
                flex: 1,
                xtype: "panel",
                itemId: "errros",
                layout: {type: "vbox", align: "stretch"}
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'cancel',
            text: Lang.zamknij,
            scope: this,
            handler: this.close
        }];
        this.callParent(arguments);
    },
    showW: function () {
        Ext.MessageBox.show({
            msg: Lang.wykonywanie_operacji,
            progressText: '...',
            width: 200,
            wait: {
                interval: 200
            }
        });
        this.task = {
            run: this.taskfuncInterval,
            scope: this,
            interval: 5000
        };
        Ext.TaskManager.start(this.task);
    },
    close: function () {
        Ext.TaskManager.stop(this.task, true);
        this.callParent(arguments);
    },
    taskfuncInterval: function () {
        sendAjaxDataJsonRpcL("rest/importtankfromfile/importTankFromFileProccessing", null, this, function (data) {
            if (data.status) {
                var dto = data.data;
                if (dto.done) {
                    this.showStatus(dto);
                }
            }
        });
    },
    showStatus: function (dto) {
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
        Ext.TaskManager.stop(this.task, true);
        Ext.getCmp(this.id + "status").setText(!dto.errors ? Lang.zakonczono : Lang.zakonczono_z_bledami)
        Ext.MessageBox.hide();
        if (dto.errors) {
            var berrors = [];
            dto.errors.forEach(x => {
                berrors.push({xtype: "box", html: x});
            })
            this.down("#errros").add(berrors);
        }
        sendAjaxDataJsonRpcL("rest/importtankfromfile/removeImportTankFromFileProcess", null, this);
    }
});