Ext.define('WindowDBImport', {
    extend: 'Ext.ux.window.WindowExd',
    statics: {
        accessPropertyName: "access.manage.dbimport",
        accessViewProperty: "access.view.dbimport"
    },
    title: Lang.import_z_starej_linii,
    width: 400,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    scrollable: true,
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 5px 10px 10px',
                scrollable: true,
                defaults: {
                    labelWidth: 150,
                    anchor: "0"
                },
                items: [{
                    id: this.cid + "hostname",
                    name: "hostname",
                    xtype: 'textfieldexd',
                    value: "192.168.76.101",
                    fieldLabel: Lang.nazwa_serwera,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "port",
                    name: "port",
                    xtype: 'numberfieldexd',
                    value: 3306,
                    fieldLabel: Lang.port_serwera,
                    regex: new RegExp("^[1-9][0-9]*$"),
                    regexText: Lang.dane_niepoprawne,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "user",
                    name: "user",
                    xtype: 'textfieldexd',
                    value: "root",
                    fieldLabel: Lang.login,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "pass",
                    name: "pass",
                    xtype: 'textfieldexd',
                    value: "root",
                    fieldLabel: Lang.haslo,
                    inputType: 'password',
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "checkboxgroup",
                    xtype: "checkboxgroup",
                    fieldLabel: Lang.dane_do_importu,
                    columns: 1,
                    vertical: true,
                    msgTarget: 'under',
                    items: [{
                        boxLabel: Lang.pojazdy,
                        id: this.cid + "importpojazdy",
                        checked: true,
                        name: "importdata",
                        inputValue: 'pojazdy'
                    }, {
                        boxLabel: Lang.odbiorcy,
                        name: "importdata",
                        id: this.cid + "importodbiorcy",
                        checked: true,
                        inputValue: 'odbiorcy'
                    }, {
                        boxLabel: Lang.tankowania,
                        name: "importdata",
                        id: this.cid + "importtankowania",
                        checked: true,
                        inputValue: 'tankowania'
                    }]
                }, {
                    id: this.cid + "trwaoczekiwanie",
                    xtype: 'displayfield',
                    value: Lang.trwa_oczekiwanie,
                    width: '100%',
                    style: 'text-align: center',
                    hidden: true
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.wykonaj,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function () {
        if (!validPrivilegesOperatorComplex(WindowDBImport.accessViewProperty)) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
    },
    saveOp: function () {
        if (!validPrivilegesOperatorComplex(WindowDBImport.accessPropertyName)) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        if (!Ext.getCmp(this.cid + 'formpanel').form.isValid()) {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        if (Ext.getCmp(this.cid + "checkboxgroup").getChecked().length == 0) {
            Ext.getCmp(this.cid + "checkboxgroup").markInvalid(Lang.dane_niepoprawne);
            return;
        }
        Ext.getCmp(this.cid + "trwaoczekiwanie").setHidden(false);
        Ext.getCmp(this.cid + 'save').setDisabled(true);
        Ext.getCmp(this.cid + 'cancel').setDisabled(true);
        var checkproccess = () => {
            performAjaxLPromise("rest/systemreq/importFromDatabaseProccessing").then(d => {
                if (d) {
                    Ext.getCmp(this.cid + "trwaoczekiwanie").setHidden(true);
                    Ext.getCmp(this.cid + 'save').setDisabled(false);
                    Ext.getCmp(this.cid + 'cancel').setDisabled(false);
                    this.closeOp();
                } else {
                    Ext.defer(checkproccess, 5000, this);
                }
            }).catch(e => {
                Ext.getCmp(this.cid + "trwaoczekiwanie").setHidden(true);
                Ext.getCmp(this.cid + 'save').setDisabled(false);
                Ext.getCmp(this.cid + 'cancel').setDisabled(false);
                Ext.MessageBox.alert(Lang.error, e);
            }).finally(() => {
            });
        };
        sendAjaxDataJsonRpcL("rest/systemreq/importFromDatabase", {
            hostname: Ext.getCmp(this.cid + "hostname").getValue(),
            port: Ext.getCmp(this.cid + "port").getValue(),
            user: Ext.getCmp(this.cid + "user").getValue(),
            pass: Ext.getCmp(this.cid + "pass").getValue(),
            importpojazdy: Ext.getCmp(this.cid + "importpojazdy").getValue(),
            importodbiorcy: Ext.getCmp(this.cid + "importodbiorcy").getValue(),
            importtankowania: Ext.getCmp(this.cid + "importtankowania").getValue()
        }, this, function (data) {
            if (data.status) {
                Ext.defer(checkproccess, 5000, this);
            } else {
                Ext.getCmp(this.cid + "trwaoczekiwanie").setHidden(true);
                Ext.getCmp(this.cid + 'save').setDisabled(false);
                Ext.getCmp(this.cid + 'cancel').setDisabled(false);
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        }, 0);
    },
    closeOp: function () {
        if (!Ext.getCmp(this.cid + 'cancel').isDisabled()) {
            this.close();
        }
    }
});

